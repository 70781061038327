<template>
  <fieldset ref="container" v-if="hasSufficientData">
    <legend>
      <slot name="title" />
    </legend>
    <div v-for="field in fields" v-bind:key="field.id" class="payroll-field">
      <form-field-variable
        :field="field"
        v-model="field.value"
        :name="field.internal_id"
        :errors="errors"
        :disabled="disableFields"
        :required="field.required"
      />
    </div>
    <p class="help-block pb-4" v-if="disableFields">
      These fields are disabled because they are mapped to other fields.
      Visit the <a :href="integrationPath">Integrations page</a> to change their mapping.
    </p>
  </fieldset>
</template>
<script>
  import FormFieldVariable from 'components/common/FormFieldVariable'

  export default {
    name: 'payroll-group-fieldset',

    components: {
      FormFieldVariable
    },

    computed: {
      hasSufficientData() {
        return this.fields && this.fields.length
      },

      integrationPath() {
        return this.$routes.integration_path({ id: 'adp' })
      }
    },

    props: {
      errors: {
        type: Object,
        default: () => {}
      },
      fields: {
        type: Array,
        required: true
      },
      disableFields: {
        type: Boolean,
        required: false,
        default: false
      }
    }
  }
</script>
