<template>
  <div>
    <PageHeader
      title="Usage Reports"
      lead="Available to download for 30 days"
    />
    <div class="panel profile">
      <Spinner v-if="isLoading" />
      <div v-else-if="hasUsageReports" ref="usage-reports-content">
        <ul class="list-group">
          <li
            class="list-group-item"
            v-for="(downloadRequest, index) in downloadRequests"
            :key="index"
          >
            <DownloadRequest
              :downloadRequest="downloadRequest"
              @download="handleDownload"
            />
          </li>
        </ul>
      </div>
      <div v-else ref="default-content" class="text-center">
        <em>You currently do not have any usage reports available.</em>
      </div>
    </div>
    <DownloadSlideoutPanel
      :downloadRequest="selectedDownloadRequest"
    />
  </div>
</template>
<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  import { SET_PAGE_CONTEXT_KEYS } from 'vuex/mutation_types'
  import DownloadRequest from 'components/admin/downloads/DownloadRequest'
  import DownloadSlideoutPanel from 'components/admin/downloads/DownloadSlideoutPanel'
  import Spinner from 'components/common/Spinner'
  import PageHeader from 'components/common/PageHeader'
  import Util from 'lib/util'

  export default {
    name: 'the-superadmin-usage-reports-root',
    components: {
      DownloadRequest,
      DownloadSlideoutPanel,
      PageHeader,
      Spinner
    },
    computed: {
      ...mapGetters({
        downloadRequests: 'usage_reports/downloadRequests'
      }),
      hasUsageReports () {
        return this.downloadRequests && this.downloadRequests.length
      }
    },
    created () {
      this.fetchData()
    },
    data () {
      return {
        isLoading: false,
        selectedDownloadRequest: null
      }
    },
    methods: {
      ...mapMutations({
        setPageContextKeys: SET_PAGE_CONTEXT_KEYS,
      }),
      ...mapActions({
        fetchUsageReports: 'usage_reports/fetchUsageReports'
      }),
      handleDownload (downloadRequest) {
        this.selectedDownloadRequest = downloadRequest
        this.setPageContextKeys({ show_download_slideout_panel: downloadRequest.id })
      },
      fetchData () {
        this.isLoading = true
        this.fetchUsageReports()
          .catch((xhr) => {
            Util.genericAjaxError(this.$locale.t('errors.ajax.fetch', 'usage reports'), xhr)
          }).finally(() => {
            this.isLoading = false
          })
      }
    }
  }
</script>
