var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wizard" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-2 hidden-xs" }, [_vm._t("steps")], 2),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-md-10" },
        [
          _vm._t("messages"),
          _vm._v(" "),
          _c(
            "WbCard",
            [
              _vm.loading
                ? _c("Spinner", { ref: "loader" })
                : _c(
                    "div",
                    { ref: "body", staticClass: "wizard-body" },
                    [
                      _vm._t("body"),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "wizard-footer" },
                        [
                          _c(
                            "WbButton",
                            {
                              ref: "previousButton",
                              staticClass: "previous",
                              attrs: { context: "info", size: "lg" },
                              on: { click: _vm.handlePrevious },
                            },
                            [_vm._v("\n              Previous\n            ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "WbButton",
                            {
                              ref: "nextButton",
                              staticClass: "next",
                              attrs: { context: "primary", size: "lg" },
                              on: { click: _vm.handleNext },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.nextLabel) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
            ],
            1
          ),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }