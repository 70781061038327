<template>
  <div>
    <bulk-update-employee-groups-container
      :action="action"
      ref="add"
      @hidden="cleanup"
      @success="handleSuccess"
    />
  </div>
</template>

<script>
  import { mapActions, mapMutations } from 'vuex'
  import * as mutate from 'vuex/mutation_types'
  import BulkUpdateEmployeeGroupsContainer from 'components/employees/bulk_update_employee_groups/BulkUpdateEmployeeGroupsContainer'
  import CollectionViewStore from 'vuex/modules/collection_view_store'
  import Locale from 'lib/locale'
  import Util from 'lib/util'

  // Vuex CollectionViewStore module name, stores metadata about collection (selected, filters, etc)
  const VUEX_COLLECTION_VIEW_NAME = 'collection_view_change_employee_groups'

  export default {
    name: 'bulk-update-employee-groups-orphan-container',

    components: {
      BulkUpdateEmployeeGroupsContainer
    },

    props: {
      action: {
        type: String,
        required: true
      },
      employeeIds: {
        type: Array,
        required: true
      },
    },

    data() {
      return {
        collectionViewName: VUEX_COLLECTION_VIEW_NAME
      }
    },

    methods: {
      handleSuccess() {
        location.reload()
      },

      cleanup() {
        this.$emit('clear-modal-data')
        this.$destroy()
      },

      ...mapActions({
        employeesBulkShow: 'employees/bulkShow',
        groupsFetchAll: 'groups/fetchAll',
        permissionsBulkAuthorize: 'permissions/bulkAuthorize',
      }),

      ...mapMutations({
        setPageContextKeys: mutate.SET_PAGE_CONTEXT_KEYS,
      })
    },

    created() {
      if(!this.$store._modulesNamespaceMap[`${this.collectionViewName}/`]) {
        this.$store.registerModule(this.collectionViewName, CollectionViewStore)
      }
    },

    beforeDestroy() {
      // Clear selected BEFORE we unregister the dynamically created module
      this.$store.dispatch(`${this.collectionViewName}/reset`)
      this.$store.unregisterModule(this.collectionViewName)
    },

    mounted() {
      const loaded = {
        employees: false,
        permissions: false,
        groups: false
      }

      const launchWhenFinished = () => {
        if(!loaded.employees || !loaded.permissions || !loaded.groups) { return }
        setTimeout(() => {
          this.setPageContextKeys({
            bulk_action_alert: true,
            collectionViewName: this.collectionViewName
          })
        })
      }

      this.employeesBulkShow({
        employeeIds: this.employeeIds
      }).then((employees) => {
        this.$store.dispatch(`${this.collectionViewName}/selectAll`, employees.map((e) => e.id))
        loaded.employees = true
        launchWhenFinished()
      }).catch((xhr) => {
        Util.genericAjaxError(Locale.t('errors.ajax.fetch', 'users'), xhr)
      })

      this.groupsFetchAll().then(() => {
        loaded.groups = true
        launchWhenFinished()
      }).catch((xhr) => {
        Util.genericAjaxError(Locale.t('errors.ajax.fetch', 'groups'), xhr)
      })

      this.permissionsBulkAuthorize({
        resourceType: 'employee_group_memberships',
        resourceIds: this.employeeIds,
        actions: ['manage']
      }).then(() => {
        loaded.permissions = true
        launchWhenFinished()
      }).catch((xhr) => {
        Util.genericAjaxError(Locale.t('errors.ajax.fetch', 'permissions'), xhr)
      })
    }

  }
</script>
