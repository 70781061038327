var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.managedPolicy
      ? _c("p", { staticClass: "breathe" }, [
          _c("i", { staticClass: "fa fa-info text-info fa-fw" }),
          _vm._v(
            "\n    Groups selected: " +
              _vm._s(_vm.groupNames.join(", ")) +
              "\n  "
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("h4", [
      _vm._v(
        "\n    Add each form you wish to customize. For any forms you don't add, we'll use the permissions you set on All other forms.\n  "
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "breathe" },
      [_c("DocumentSelect", { on: { select: _vm.add } })],
      1
    ),
    _vm._v(" "),
    _c("table", { staticClass: "table" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "tbody",
        { staticClass: "rule-table" },
        _vm._l(_vm.groupedPermissions, function (documentPermissions) {
          return _c("PermissionsCustomizeDocumentRow", {
            key: documentPermissions.key,
            attrs: {
              "permission-set": _vm.groupPermissionSet,
              "document-id": documentPermissions.key,
              permissions: documentPermissions.permissions,
            },
            on: { remove: _vm.remove },
          })
        }),
        1
      ),
    ]),
    _vm._v(" "),
    _vm._m(1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { width: "35%" } }, [_vm._v("Field")]),
        _vm._v(" "),
        _c("th", { attrs: { width: "20%" } }, [_vm._v("View & Download")]),
        _vm._v(" "),
        _c("th", { attrs: { width: "40%" } }, [_vm._v("Other Actions")]),
        _vm._v(" "),
        _c("th", { attrs: { width: "5%" } }, [_vm._v("Remove")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("i", { staticClass: "fa fa-info text-info fa-fw" }),
      _vm._v(
        "\n    You must give View access before you can configure any Other Actions.\n  "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }