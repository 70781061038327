/*
 * THE BRIDGE - Instantiate modules explicitly so that unmodularized code can refer to them.
 */

//**********************************************************************************************************************
// ES6 Module Imports
//**********************************************************************************************************************
import _App_Constants from 'lib/constants'
import _App_Collections from 'collections'
import _App_Models from 'models'
import _App_Views from 'views'

// Util
import _App_Util from 'lib/util'
import _App_Util_Animations from 'lib/util/animations'
import _App_Util_DataTables from 'lib/util/datatables'
import _App_Util_Dialogs from 'lib/util/dialogs'
import _App_Util_Email from 'lib/util/email'
import _App_Util_Masks from 'lib/util/masks'
import _App_Util_Url from 'lib/util/url'

// Libs
import _App_ApiOperation from 'lib/api_operation'
import _App_Routes from 'lib/routes'
import _App_PersistedPageState from 'lib/persisted_page_state'
import _App_PubSub from 'lib/pub_sub'
import { MainContainerRegion, SubheaderRegion } from 'lib/app_regions'
import _DropzoneOperator from 'lib/dropzone_operator'
require('lib/validators/wb_remote')


// Model concerns
import _App_Models_Concerns_HALAPIConcern from 'models/concerns/hal_api_concern'
import _App_Models_Concerns_UDFValuesStoreConcern from 'models/concerns/udf_values_store_concern'

// Models
import _App_Models_AdminUser from 'models/admin_user'
import _App_Models_DocumentSubmission from 'models/document_submission'
import _App_Models_EmployeeGroupMembership from 'models/employee_group_membership'
import _App_Models_EmployeeGroup from 'models/employee_group'
import _App_Models_Employee from 'models/employee'
import _App_Models_Employment from 'models/employment'
import _App_Models_EmployerReviewForm from 'models/i9/employer_review_form'
import _App_Models_Filter from 'models/filter'
import _App_Models_I9DocumentationChoices from 'models/document_submissions/i9_documentation_choices'
import _App_Models_I9Submission from 'models/document_submissions/i9_submission'
import _App_Models_Report from 'models/report'
import _App_Models_RepresentableField from 'models/representable_field'

// Collections
import _App_Collections_AdminUserCollection from 'collections/admin_user_collection'
import _App_Collections_DocumentCollection from 'collections/document_collection'
import _App_Collections_DocumentSubmissionCollection from 'collections/document_submission_collection'
import _App_Collections_EmployeeCollection from 'collections/employee_collection'
import _App_Collections_EmployeeGroupCollection from 'collections/employee_group_collection'
import _App_Collections_RepresentableFieldCollection from 'collections/representable_field_collection'
import _App_Collections_SupplementalFiles from 'collections/supplemental_files_collection'

// Views
import _App_Views_Account_EditLayout from 'views/account/edit_layout'
import _App_Views_AdminUsers_FormLayout from 'views/admin_users/form_layout'
import _App_Views_AdminUsers_IndexLayout from 'views/admin_users/index_layout'
import _App_Views_Api_ApiSettingsView from 'views/api/api_settings_view'
import _App_Views_Common_AddressFieldsItemView from 'views/common/forms/address_fields_item_view'
import _App_Views_Common_ImageEditorView from 'views/common/image_editor_view'
import _App_Views_Common_RepresentableFieldSelectView from 'views/common/representable_field_select_view'
import _App_Views_Common_SignaturePadView from 'views/common/forms/signature_pad_view'
import _App_Views_CustomFields_IndexLayout from 'views/custom_fields/index_layout'
import _App_Views_Deadlines_FormLayout from 'views/deadlines/form_layout'
import _App_Views_DocumentSubmissions_EditInternalFieldsAsyncModalView from 'views/document_submissions/edit_internal_fields_async_modal_view'
import _App_Views_DocumentSubmissions_InboxLayout from 'views/document_submissions/inbox_layout'
import _App_Views_DocumentSubmissions_W9FormLayout from 'views/document_submissions/w9_form_layout'
import _App_Views_Documents_FormLayout from 'views/documents/form_layout'
import _App_Views_Documents_FieldsIndexLayout from 'views/documents/fields_index_layout'
import _App_Views_Documents_IndexLayout from 'views/documents/index_layout'
import _App_Views_Documents_ShowLayout from 'views/documents/show_layout'
import _App_Views_EmployeesShowLayout from 'views/employees/show_layout'
import _App_Views_EmploymentIndexLayout from 'views/employees/employee_seasons/employment_index_layout'
import _App_Views_EmployerReviewLayoutView from 'views/i9/employer_review_layout_view'
import _App_Views_I9FormLayoutView from 'views/document_submissions/i9/form_layout_view'
import _App_Views_I9SupplementBLayoutView from 'views/document_submissions/i9/supplement_b/form_layout_view'
import _App_Views_Kiosk_EmployeeSelectionLayout from 'views/kiosk/employee_selection_layout_view'
import _App_Views_Kiosk_Passkey_View from 'views/kiosk/async_kiosk_passkey_view'
import _App_Views_Kiosk_Kiosk_Navbar_View from 'views/kiosk/kiosk_navbar_view'
import _App_Views_Kiosk_EditEmployeeLayout from 'views/kiosk/edit_employee_layout'
import _App_Views_Kiosk_FinishedLayout from 'views/kiosk/finished_layout'
import _App_Views_PrerenderedDocumentSubmissionView from 'views/document_submissions/prerendered_document_submission_view'
import _App_Views_PrerenderedTableCollectionView from 'views/common/prerendered_table_collection_view.js'
import _App_Views_PrerenderedItemView from 'views/common/prerendered_item_view.js'
import _App_Views_PrerenderedTableRowItemView from 'views/common/prerendered_table_row_item_view.js'
import _App_Views_ReorderLayout from 'views/documents/reorder_layout'
import _App_Views_ReportsToolbarView from 'views/reports/table_toolbar_view'
import _App_Views_Reports_BuilderLayout from 'views/reports/builder_layout'
import _App_Views_Reports_IndexLayout from 'views/reports/index_layout'
import _App_Views_Superadmin_AnnouncementsIndexLayout from 'views/superadmin/announcements/announcements_index_layout_view'
import _App_Views_Superadmin_NewAccountView from 'views/superadmin/accounts/new_account_view'
import _App_Views_Superadmin_AccountIndexLayout from 'views/superadmin/accounts/index_layout'
import _App_Views_SupplementalFilesLayoutView from 'views/supplemental_files/index_layout_view'
import _App_Views_UdfFormLayoutView from 'views/user_defineable_fields/udf_form_layout_view'
import _App_Views_UserImport_ColumnMatchLayout from 'views/user_imports/columns/column_match_layout'
import _App_Views_UserImport_EmployeeValidationLayout from 'views/user_imports/validation/employee_validation_layout'
import _App_Views_UserImport_ImportLayout from 'views/user_imports/import/import_layout'
import _App_Views_UserImport_IndexLayout from 'views/user_imports/index_layout'
import _App_Views_UserImport_NewUserImportLayout from 'views/user_imports/new/new_user_import_layout'
import _App_Views_Webhooks_IndexLayout from 'views/webhooks/index_layout'
import _App_Views_W4SubmissionFormView from 'views/document_submissions/w4_submission_form_view'

// Behaviors
import _App_Behaviors_BulkActions from 'behaviors/bulk_actions'
import _App_Behaviors_CustomFieldsManners from 'behaviors/custom_fields_manners'
import _App_Behaviors_PrerenderedModalView from 'behaviors/prerendered_modal_view'
import _App_Behaviors_RehireBulkAction from 'behaviors/rehire_bulk_action'
import _App_Behaviors_RailsRemoteFormCallbacks from 'behaviors/rails_remote_form_callbacks'
import _App_Behaviors_TableRowClickable from 'behaviors/table_row_clickable'
import _App_Behaviors_TableRowSelectable from 'behaviors/table_row_selectable'

// Sectors
import _BaseSector from 'lib/base_sector'
import _App_Sectors_AdminPolicyGenerator from 'sectors/admin_policy_generator_sector'
import _App_Sectors_AdminUserImport from 'sectors/admin_user_import_sector'
import _App_Sectors_AdminUsers from 'sectors/admin_users_sector'
import _App_Sectors_DocumentSubmissions from 'sectors/document_submissions_sector'
import _App_Sectors_Documents from 'sectors/documents_sector'
import _App_Sectors_EmployeeGroups from 'sectors/employee_groups_sector'
import _App_Sectors_Employees from 'sectors/employees_sector'
import _App_Sectors_Kiosk from 'sectors/kiosk_sector'
import _App_Sectors_Reports from 'sectors/reports_sector'
import _App_Sectors_UserImports from 'sectors/user_imports_sector'
import _App_Sectors_Webhooks from 'sectors/webhooks_sector'

// Misc
import _App_Init_KioskCommonInit from 'lib/kiosk_common_init'

// Marionette Extensions
import _MarionetteExt_PrerenderedSupport from 'marionette_extensions/prerendered_support'
import _MarionetteExt_2450SerializeData from 'marionette_extensions/2450_serializeData'

// Vue Util
import { pureOrphanVue } from 'lib/vue/pureOrphanVue'


//**********************************************************************************************************************
// ES6 Modules Assignments
//**********************************************************************************************************************
window.App = new Backbone.Marionette.Application()
App.Constants = _App_Constants
App.Collections = _App_Collections
App.Models = _App_Models
App.Views = _App_Views
App.Behaviors = {}

// Util
App.Util = _App_Util
App.Util.Animations = _App_Util_Animations
App.Util.DataTables = _App_Util_DataTables
App.Util.Dialogs = _App_Util_Dialogs
App.Util.Email = _App_Util_Email
App.Util.Masks = _App_Util_Masks
App.Util.Url = _App_Util_Url

// Libs
App.ApiOperation = _App_ApiOperation
App.Routes = _App_Routes
App.PersistedPageState = _App_PersistedPageState
App.PubSub = _App_PubSub

// Model concerns
App.Models.Concerns.HALAPIConcern = _App_Models_Concerns_HALAPIConcern
App.Models.Concerns.UDFValuesStoreConcern = _App_Models_Concerns_UDFValuesStoreConcern

// Models
App.Models.AdminUser = _App_Models_AdminUser
App.Models.DocumentSubmission = _App_Models_DocumentSubmission
App.Models.EmployeeGroupMembership = _App_Models_EmployeeGroupMembership
App.Models.EmployeeGroup = _App_Models_EmployeeGroup
App.Models.EmployerReviewForm = _App_Models_EmployerReviewForm
App.Models.Employee = _App_Models_Employee
App.Models.Employment = _App_Models_Employment
App.Models.Filter = _App_Models_Filter
App.Models.I9Submission = _App_Models_I9Submission
App.Models.I9DocumentationChoices = _App_Models_I9DocumentationChoices
App.Models.Report = _App_Models_Report
App.Models.RepresentableField = _App_Models_RepresentableField

// Collections
App.Collections.AdminUserCollection = _App_Collections_AdminUserCollection
App.Collections.DocumentCollection = _App_Collections_DocumentCollection
App.Collections.DocumentSubmissionCollection = _App_Collections_DocumentSubmissionCollection
App.Collections.EmployeeCollection = _App_Collections_EmployeeCollection
App.Collections.EmployeeGroupCollection = _App_Collections_EmployeeGroupCollection
App.Collections.RepresentableFieldCollection = _App_Collections_RepresentableFieldCollection
App.Collections.SupplementalFiles = _App_Collections_SupplementalFiles

// Views
App.PrerenderedTableCollectionView = _App_Views_PrerenderedTableCollectionView
App.PrerenderedItemView = _App_Views_PrerenderedItemView
App.PrerenderedTableRowItemView = _App_Views_PrerenderedTableRowItemView
App.Views.Account.EditLayoutView = _App_Views_Account_EditLayout
App.Views.AdminUsers.FormLayout = _App_Views_AdminUsers_FormLayout
App.Views.AdminUsers.IndexLayout = _App_Views_AdminUsers_IndexLayout
App.Views.Api.ApiSettingsView = _App_Views_Api_ApiSettingsView
App.Views.Common.AddressFieldsItemView = _App_Views_Common_AddressFieldsItemView
App.Views.Common.ImageEditorView = _App_Views_Common_ImageEditorView
App.Views.Common.RepresentableFieldSelectView = _App_Views_Common_RepresentableFieldSelectView
App.Views.Common.SignaturePadView = _App_Views_Common_SignaturePadView
App.Views.CustomFields.IndexLayout = _App_Views_CustomFields_IndexLayout
App.Views.Deadlines.FormLayout = _App_Views_Deadlines_FormLayout
App.Views.DocumentSubmissions.EditInternalFieldsAsyncModalView = _App_Views_DocumentSubmissions_EditInternalFieldsAsyncModalView
App.Views.DocumentSubmissions.InboxLayout = _App_Views_DocumentSubmissions_InboxLayout
App.Views.DocumentSubmissions.W9FormLayout = _App_Views_DocumentSubmissions_W9FormLayout
App.Views.Documents.IndexLayout = _App_Views_Documents_IndexLayout
App.Views.Documents.ShowLayout = _App_Views_Documents_ShowLayout
App.Views.Documents.FormLayout = _App_Views_Documents_FormLayout
App.Views.Documents.FieldsIndexLayout = _App_Views_Documents_FieldsIndexLayout
App.Views.EmployerReviewLayoutView = _App_Views_EmployerReviewLayoutView
App.Views.Employees.ShowLayout = _App_Views_EmployeesShowLayout
App.Views.Employees.EmploymentIndexLayout = _App_Views_EmploymentIndexLayout
App.Views.I9FormLayoutView = _App_Views_I9FormLayoutView
App.Views.I9SupplementBLayoutView = _App_Views_I9SupplementBLayoutView
App.Views.KioskEmployeeSelectionLayoutView = _App_Views_Kiosk_EmployeeSelectionLayout
App.Views.Kiosk.AsyncKioskPasskeyView = _App_Views_Kiosk_Passkey_View
App.Views.Kiosk.KioskNavbarView = _App_Views_Kiosk_Kiosk_Navbar_View
App.Views.Kiosk.EditEmployeeLayout = _App_Views_Kiosk_EditEmployeeLayout
App.Views.Kiosk.FinishedLayout = _App_Views_Kiosk_FinishedLayout
// App.Views.PolicyGeneratorWizardLayoutView = _App_Views_PolicyGeneratorWizardLayoutView;
// App.Views.PolicySummaryView = _App_Views_PolicySummaryView;
App.Views.PrerenderedDocumentSubmissionView = _App_Views_PrerenderedDocumentSubmissionView
App.Views.ReorderLayout = _App_Views_ReorderLayout
App.Views.ReportsToolbarView = _App_Views_ReportsToolbarView
App.Views.Reports.BuilderLayout = _App_Views_Reports_BuilderLayout
App.Views.Reports.IndexLayout = _App_Views_Reports_IndexLayout
App.Views.Superadmin.AnnouncementsIndexLayout = _App_Views_Superadmin_AnnouncementsIndexLayout
App.Views.Superadmin.AccountsIndexLayout = _App_Views_Superadmin_AccountIndexLayout
App.Views.Superadmin.NewAccountView = _App_Views_Superadmin_NewAccountView
App.Views.SupplementalFilesLayoutView = _App_Views_SupplementalFilesLayoutView
App.Views.UdfFormLayoutView = _App_Views_UdfFormLayoutView
App.Views.UserImports.ColumnMatchLayout = _App_Views_UserImport_ColumnMatchLayout
App.Views.UserImports.EmployeeValidationLayout = _App_Views_UserImport_EmployeeValidationLayout
App.Views.UserImports.ImportLayout = _App_Views_UserImport_ImportLayout
App.Views.UserImports.IndexLayout = _App_Views_UserImport_IndexLayout
App.Views.UserImports.NewUserImportLayout = _App_Views_UserImport_NewUserImportLayout
App.Views.Webhooks.IndexLayout = _App_Views_Webhooks_IndexLayout
App.Views.W4SubmissionFormView = _App_Views_W4SubmissionFormView

// Behaviors actually have to be "installed" (see behaviorsLookup below). They are referenced by their
// name in the views' `behaviors: {}` blocks, but those items need not be `import`ed in the View class file.
App.Behaviors.BulkActions = _App_Behaviors_BulkActions
App.Behaviors.CustomFieldsManners = _App_Behaviors_CustomFieldsManners
App.Behaviors.PrerenderedModalView = _App_Behaviors_PrerenderedModalView
App.Behaviors.RehireBulkAction = _App_Behaviors_RehireBulkAction
App.Behaviors.RailsRemoteFormCallbacks = _App_Behaviors_RailsRemoteFormCallbacks
App.Behaviors.TableRowClickable = _App_Behaviors_TableRowClickable
App.Behaviors.TableRowSelectable = _App_Behaviors_TableRowSelectable

// Install behaviors to Marionette. Since this is a function, unmodularized behaviors can still be added later.
Marionette.Behaviors.behaviorsLookup = function() {
  return App.Behaviors
}


// --------------------------------------------
// FIXME: Modularize everything below...
// --------------------------------------------
App.Name = App.Constants.APP_NAME
App.Entities = App.module("Entities") // FIXME: once all entities are moved to Models, this can be removed

App.globalVent = new Backbone.Wreqr.EventAggregator()
App.reqres = new Backbone.Wreqr.RequestResponse()


// Marionette Extensions
Marionette.View.prototype._removeElement = _MarionetteExt_PrerenderedSupport
_MarionetteExt_2450SerializeData()

// Application Regions
App.addRegions({
  subheaderRegion: SubheaderRegion,
  mainRegion: MainContainerRegion,
})

_DropzoneOperator($, window)

App.module('KioskCommonInit', _App_Init_KioskCommonInit)

// Add a sector module
// Always adds BaseSector module, plus optional additional sector module
const addSectorModule = function(name, sector) {
  App.module(name, _BaseSector)
  if(sector) { App.module(name, sector) }
}
addSectorModule('Account', null)
addSectorModule('AdminDashboard', null)
addSectorModule('AdminPolicyGenerator', _App_Sectors_AdminPolicyGenerator)
addSectorModule('AdminUserImport', _App_Sectors_AdminUserImport)
addSectorModule('AdminUsers', _App_Sectors_AdminUsers)
addSectorModule('CustomFields', null)
addSectorModule('DocumentSubmissions', _App_Sectors_DocumentSubmissions)
addSectorModule('Documents', _App_Sectors_Documents)
addSectorModule('EmployeeGroups', _App_Sectors_EmployeeGroups)
addSectorModule('Employees', _App_Sectors_Employees)
addSectorModule('Kiosk', _App_Sectors_Kiosk)
addSectorModule('Reports', _App_Sectors_Reports)
addSectorModule('Seasons', null)
addSectorModule('Settings', null)
addSectorModule('Superadmin', null)
addSectorModule('UserImports', _App_Sectors_UserImports)
addSectorModule('Webhooks', _App_Sectors_Webhooks)

App.startSector = function(sector) {
  sector.start()
  App.currentSector = sector
  App.globalVent.trigger('sector.init')
}

App.navigate = function(route, options){
  options || (options = {})
  Backbone.history.navigate(route, options)
}

App.getCurrentRoute = function() {
  return Backbone.history.fragment
}

// HACK: Ensure some idempotency on the JS that runs within the page
App.pageInit = function(callbackFn) {
  // REVIEW: what is the suffix namespace?
  $(document).off('ready.suffix page:load.suffix').on('ready.suffix page:load.suffix', function() {
    callbackFn.call()
    $(document).off('ready.suffix page:load.suffix')
  })
}

App.postInit = function(callbackFn) {
  App.globalVent.on('sector.init', function() {
    callbackFn.call()
  })
}

// HACK: Use page:fetch because Turbolinks does not call page:before-change when moving FORWARD in the browser. Weird.
$(document).on('page:fetch', function() {
  Marionette.pageIsChanging = true

  if (App.currentSector) {
    App.currentSector.stop()
  }

  if (App.searchFormView) {
    App.searchFormView.destroy()
  }
})

$(document).on('page:change', function() {
  Marionette.pageIsChanging = false
})

// HACK: Making turbolinks + Backbone work together; in the event that Backbone pushes a URL to the browser state,
// when it is popped back out, Turbolinks does not know how to process it natively. This helper callback checks
// for those 'pages' and forces Turbolinks.visit on them to hit them up again.
// Potential problems: A popstate page w/o state.turbolinks that we don't want to visit... dunno?
// I think this already breaks down if a '#' is used somewhere
window.addEventListener('popstate', function(event) {
  const state = event.state

  if(state && state.pop_handled_locally){
    return
  }

  if (state != null && (!state.turbolinks || (state.turbolinks && !state.position))) {
    return Turbolinks.visit(event.target.location.href)
  }
}, false)



// HACKFIX: Find a better home for this.
import EmployeeSearch from 'components/employees/EmployeeSearch'
$(document).on('page:change', () => {
  const navbarSearchSelector = 'component#vue-employee-search'
  if ($(navbarSearchSelector).length) {
    pureOrphanVue(EmployeeSearch, navbarSearchSelector, {
      props: {
        value: null, // Unused, handled through callback
        selectCallback: (event) => Turbolinks.visit(`/staff/${event.data.id}`)
      }
    })
  }
})

// HACKFIX: Find a better home for this.
// New Employee navbar button
$(document).on('page:change', () => {
  $('.js-new-employee').click((e) => {
    e.preventDefault()
    App.globalVent.trigger('employees:new')
  })
})
