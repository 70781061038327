<template>
  <WbWorkflowStep
    :stepNumber="stepNumber"
    title="Confirm Location"
  >
    <template>
      <AlertMessage
        class="mt-3"
        variant="warning"
        :message="alertMessage"
        ref="qrCodeAlert"
      />
      <slot name="instructions" />
      <QrcodeVue
        class="text-center"
        style="padding: 50px 0;"
        :value="qrCodePayload"
        :size="200"
        level="L"
        ref="qrCode"
      />
      <WbButton
        @click="handleScanComplete"
      >
        Scan Complete
      </WbButton>
    </template>
  </WbWorkflowStep>
</template>
<script>
  import AlertMessage from 'components/common/AlertMessage'
  import QrcodeVue from 'qrcode.vue'
  import WbButton from 'components/common/WbButton'
  import WbWorkflowStep from 'components/common/WbWorkflowStep'

  export default {
    components: {
      AlertMessage,
      QrcodeVue,
      WbButton,
      WbWorkflowStep,
    },
    props: {
      alertMessage: {
        type: String,
      },
      stepNumber: {
        type: Number,
      },
      qrCodePayload: {
        type: String,
        required: true,
      },
    },
    methods: {
      handleScanComplete () {
        window.location.reload()
      },
    },
  }
</script>
