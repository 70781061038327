var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ul", { staticClass: "nav navbar-nav navbar-right" }, [
    _c("li", { staticClass: "dropdown" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-link navbar-btn",
          attrs: { "data-toggle": "dropdown" },
        },
        [
          _c("WbIcon", {
            staticClass: "fa-fw",
            attrs: { type: "user-circle" },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "block-translate" }, [
            _vm._v(_vm._s(_vm.userFirstName)),
          ]),
          _vm._v(" "),
          _c("WbIcon", { staticClass: "fa-fw", attrs: { type: "caret-down" } }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "dropdown-menu dropdown-menu-pointy" },
        [
          _c("li", { staticClass: "dropdown-header" }, [
            _vm._v("\n        Signed in as\n        "),
            _c("strong", { staticClass: "block-translate" }, [
              _vm._v(_vm._s(_vm.currentUser.name)),
            ]),
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "dropdown-header block-translate" }, [
            _vm._v("\n        " + _vm._s(_vm.currentUser.email) + "\n      "),
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "divider" }),
          _vm._v(" "),
          _vm.profileItem
            ? _c(
                "SecondaryNavItem",
                { ref: "profileItem", attrs: { item: _vm.profileItem } },
                [
                  _c("WbIcon", {
                    staticClass: "fa-fw",
                    attrs: { slot: "icon", type: "user" },
                    slot: "icon",
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("li", { staticClass: "divider" }),
          _vm._v(" "),
          _vm.switchAccountItem
            ? _c(
                "SecondaryNavItem",
                {
                  ref: "switchAccountItem",
                  attrs: { item: _vm.switchAccountItem },
                },
                [
                  _c("WbIcon", {
                    staticClass: "fa-fw",
                    attrs: { slot: "icon", type: "exchange" },
                    slot: "icon",
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("li", [_c("AppSignoutLink")], 1),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }