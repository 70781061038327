var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isLoading
      ? _c("div", { staticClass: "row column-divider" }, [_c("Spinner")], 1)
      : _c(
          "div",
          { ref: "everifyDocumentPreview", staticClass: "row column-divider" },
          [
            _c(
              "div",
              { class: _vm.showEverifyPhoto ? "col-lg-6" : "col-lg-12" },
              [
                _c("EverifyCasePhoto", {
                  attrs: {
                    header: "WorkBright I-9 Documentation",
                    photoFrontUrl: _vm.photoFrontUrl,
                    photoBackUrl: _vm.photoBackUrl,
                    shouldShowBack: true,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.showEverifyPhoto
              ? _c(
                  "div",
                  { staticClass: "col-lg-6" },
                  [
                    _c("EverifyCasePhoto", {
                      attrs: {
                        header: "E-Verify Documentation",
                        photoFrontUrl: _vm.everifyPhotoUrl,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }