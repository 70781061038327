var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.shouldShow
    ? _c("div", [
        _c(
          "div",
          {
            ref: "panel",
            staticClass: "slideout-panel-modal modal right fade",
            class: _vm.sizeClass,
            attrs: { role: "dialog", tabindex: "-1" },
          },
          [
            _c("div", { staticClass: "modal-dialog" }, [
              _c("div", { staticClass: "modal-content" }, [
                _c("div", { staticClass: "panel panel-snapshot" }, [
                  _c(
                    "div",
                    { staticClass: "panel-heading" },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "close",
                          attrs: { "aria-label": "Close", type: "button" },
                          on: { click: _vm.cancel },
                        },
                        [
                          _c("span", { attrs: { "aria-hidden": "true" } }, [
                            _vm._v("×"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.title
                        ? _c("h3", { staticClass: "modal-title" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.title) +
                                "\n            "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.title ? _vm._t("title") : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "panel-body" }, [
                    _c(
                      "div",
                      { staticClass: "slideout-content-region" },
                      [_vm._t("default")],
                      2
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }