var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("FormFieldSsn", {
        ref: "ssnField",
        attrs: {
          errors: _vm.formErrors,
          label: "SSN",
          name: _vm.inputName,
          orientation: "horizontal",
          required: _vm.ssnFieldRequired,
          value: _vm.ssnFieldValue,
          disabled: _vm.ssnFieldDisabled,
        },
        on: { input: _vm.handleSsnFieldInput },
      }),
      _vm._v(" "),
      _vm.required
        ? _c(
            "div",
            [
              _c("input", {
                attrs: {
                  value: "false",
                  type: "hidden",
                  name: _vm.appliedForSsnInputName,
                },
              }),
              _vm._v(" "),
              _c("FormFieldCheckbox", {
                ref: "appliedForSsnField",
                attrs: {
                  label: _vm.$locale.t(
                    "staff.profile.applied_for_ssn.checkbox.label"
                  ),
                  helpPopoverText: _vm.$locale.t(
                    "staff.profile.applied_for_ssn.checkbox.help_text"
                  ),
                  name: _vm.appliedForSsnInputName,
                  hint: _vm.$locale.t(
                    "staff.profile.applied_for_ssn.help_block"
                  ),
                },
                on: { input: _vm.handleCheckboxInput },
                model: {
                  value: _vm.localAppliedForSsn,
                  callback: function ($$v) {
                    _vm.localAppliedForSsn = $$v
                  },
                  expression: "localAppliedForSsn",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }