var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.hasValidStatus
        ? _c(
            "span",
            {
              ref: "label",
              staticClass: "label label-inline",
              class: _vm.classNames,
              attrs: { role: "button" },
              on: { click: _vm.handleClick },
            },
            [
              !!_vm.icon
                ? _c("WbIcon", {
                    staticClass: "mr-1",
                    attrs: { type: _vm.icon },
                  })
                : _vm._e(),
              _vm._v("\n    " + _vm._s(_vm.label) + "\n  "),
            ],
            1
          )
        : _c("span", [_vm._v("\n    Unknown Verification Status\n  ")]),
      _vm._v(" "),
      _c("DocumentVerificationSlideout", {
        attrs: { submission: _vm.submission },
        on: { update: _vm.handleUpdate },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }