var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ul", { staticClass: "nav navbar-nav navbar-left" }, [
    _c(
      "li",
      {
        staticClass: "announcements active",
        attrs: { title: _vm.$locale.t("navbar.announcements") },
      },
      [
        _c("a", { attrs: { href: _vm.$routes.announcements_path } }, [
          _c("span", [_vm._v(_vm._s(_vm.$locale.t("navbar.announcements")))]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }