import AccountListTableCollectionView from 'views/superadmin/accounts/account_list_table_collection_view'
import AccountsCollection from 'collections/accounts_collection'

export default Marionette.LayoutView.extend({
  template: false,
  initialize: function() {
    this.$tableEl = this.$el.find('table#accounts')
  },

  onBeforeRender: function() {
    if (this.$tableEl.length > 0) {
      this.initializeDataTable()

      // .dt-toolbar doesn't exist until after datatable is initialized, so do it now:
      this.addRegion("toolbarRegion", ".dt-toolbar")
      const toolbarView = new Marionette.ItemView({ template: "#accounts-toolbar-tmpl" })
      this.toolbarRegion.show(toolbarView)
      toolbarView.prerendered = true // after showing, turn on prerendered so it doesn't get removed from the view
    }
  },

  onBeforeDestroy: function() {
    if (App.Superadmin.listTableCollectionView) {
      App.Superadmin.listTableCollectionView.destroy()
      delete App.Superadmin.listTableCollectionView
    }
  },

  initializeDataTable: function() {
    this.$tableEl.DataTable({
      retrieve: true,
      destroy: true,
      sorting: [1, 'asc'],
      columns: [
        /* 0 Checkbox */ null,
        /* 1 Name */ null,
        /* 2 Type */ null,
        /* 3 Subdomain */ null,
        /* 4 Permissions Feature */ null,
        /* 5 Upsells Enabled */ null,
        /* 6 Embedded Mode */ null,
        /* 7 San Diego Mode */ null,
        /* 8 E-Verify Feature Mode */ null,
        /* 9 Remote I-9 Verification */ null,
        /* 10 MFA Required for Admins */ null,
        /* 11 MFA Required for Staff */ null,
        /* 12 Single Sign-On (SSO) */ null,
        /* 13 ADP Integration */ null,
        /* 14 Remote I-9 SMS Feature */ null,
        /* 15 Onboarding SMS Feature */ null,
        /* 16 Inbox Pagination */ null,
        /* 16 I-9 Inbox Pagination */ null,
        /* 17 Created */ { type: 'date' }
      ],
    })

    App.Superadmin.listTableCollectionView = new AccountListTableCollectionView()
    App.Superadmin.listTableCollectionView.attachToTable(this.$tableEl, AccountsCollection, function() {
      return {
        "id": this.data("id"),
      }
    })
  },
})
