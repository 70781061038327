import { render, staticRenderFns } from "./SmsUpsellWidget.vue?vue&type=template&id=f87dfe2c&"
var script = {}


/* normalize component */
import normalizer from "!../../../../../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/workbright/shared/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f87dfe2c')) {
      api.createRecord('f87dfe2c', component.options)
    } else {
      api.reload('f87dfe2c', component.options)
    }
    module.hot.accept("./SmsUpsellWidget.vue?vue&type=template&id=f87dfe2c&", function () {
      api.rerender('f87dfe2c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/assets/javascripts/src/components/admin/dashboard/widgets/SmsUpsellWidget.vue"
export default component.exports