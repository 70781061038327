export default Marionette.ItemView.extend({
  behaviors: {
    BulkActions: {}
  },

  initialize: function(options) {
    this.collectionView = options.collectionView
  },
  
  onRender: function() {
    $.runInitializers(this.$el) // Ladda button
  },

  onBeforeDestroy: function() {
    this.stopListening(this.collectionView)
  },
})
