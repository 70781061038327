import DocumentSubmissionsCollection from 'collections/document_submission_collection'
import InboxTableCollectionView from 'views/document_submissions/inbox_table_collection_view'

export default Marionette.LayoutView.extend({
  template: false,
  regions: {
    submissionViewerRegion: "#submission-viewer",
  },

  ui: {
    inboxDataTableWrapper: "#inbox-table_wrapper",
    paginationFooter: ".rails-basic-pagination",
    initialLoadingView: "#initial-loading-view",
  },

  initialize: function(options) {
    this.$tableEl = this.$el.find('#inbox-table')
  },

  onBeforeRender: function() {
    if (this.$tableEl.length > 0) {
      this.inboxCollectionView = new InboxTableCollectionView()

      var collection = new DocumentSubmissionsCollection(gon.inbox, { parse: true })
      this.inboxCollectionView.attachToTableWithCollection(this.$tableEl, collection)

      this.$tableEl.DataTable({
        retrieve: true,
        destroy: true,
        sorting: [], // don't do initial sort so that Ruby sort order matches DT row order
        info: !gon.inboxPaginationEnabled,
        paginate: !gon.inboxPaginationEnabled,
        displayLength: gon.inboxPaginationEnabled ? false : 25,
        lengthChange: false,
        columns: [
          /* 0 Indicator */ { width: "12px" },
          /* 1 Employee Name */ { width: "250px", orderData: [6, 5] },
          /* 2 Form Name */ { },
          /* 3 Type */ { width: "55px", orderData: 8 },
          /* 4 Received */ { width: "100px", orderData: 7 },
          /* 5 DATA - First Name */ null,
          /* 6 DATA - Last Name */ null,
          /* 7 DATA - Received Date */ { type: "date" },
          /* 8 DATA - Type */ null,
          /* 9 DATA - ID */ null,
        ],
        language: {
          emptyTable: '<h4 class="text-center">You\'re all done!</h4><div class="text-center">There are no pending submissions to review.</div>',
        }
      })

      this.addRegion("pageHeaderRegion", ".dt-toolbar")
      var headerView = new Marionette.ItemView({ template: "#submissions-toolbar-tmpl" })
      this.pageHeaderRegion.show(headerView)
      headerView.prerendered = true // we can close the view, but don't remove from DOM
    }
  },

  onBeforeDestroy: function() {
    this.inboxCollectionView.destroy()
    delete this.inboxCollectionView
  }
})
