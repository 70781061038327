<template>
  <div class="form-group">
    <label class="small" ref="label" v-if="!!label">
      {{ label }}
    </label>
    <div class="input-group">
      <span class="input-group-addon">
        <i class="fa fa-search" />
      </span>
      <input
        ref="input"
        class='form-control'
        type='search'
        :value="value"
        @input="handleInput"
        :placeholder="placeholder"
        :autofocus="autofocus"
      />
    </div>
  </div>
</template>

<script>
  import Constants from 'lib/constants'
  import { throttle } from 'underscore'

  export default {
    name: 'wb-search-input',
    props: {
      label: {
        type: String,
        default: 'Search'
      },

      placeholder: {
        type: String,
        default: 'Search'
      },

      autofocus: {
        type: Boolean,
        default: true,
      },

      value: {
        required: true
      },

      throttle: {
        type: Boolean,
        default: false
      }
    },

    created() {
      this.handleInput = 
        this.throttle ? throttle(this.handleInput, Constants.DEFAULT_REQUEST_THROTTLE) :
        this.handleInput
    },

    methods: {
      handleInput(e) {
        this.$emit('input', e.target.value)
      }
    }
  }
</script>
