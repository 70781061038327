<template>
  <list-item
    :collectionViewName="collectionViewName"
    :item="employeeId"
    :selectable="true"
    :disabled="disabled"
    :secondaryActions="false"
    @click="handleFullItemClick"
  >
    <div class="wb-list__content">
      <staff-list-progress-indicator
        :onboardingSummary="onboardingSummary"
      />
      <div class="staff-list-name wb-list__column-content-20">
        <div class="wb-list__title mr-auto my-0">
          <span v-if="!isActive" v-tooltip="employmentStatus">
            <strong class="name inactive-staff">{{ employeeFullName }}</strong>
          </span>
          <span v-else>
            <strong class="name">{{ employeeFullName }}</strong>
          </span>
          <TooltipIcon
            v-if="undeliveredEmail"
            class="pl-2 text-danger"
            icon="envelope"
            :message="undeliveredEmail"
            ref="undeliveredEmail"
          />
          <TooltipIcon
            v-if="hasValidSmsStatus"
            class="pl-2 text-danger"
            icon="phone"
            :message="smsStatus"
            ref="smsStatus"
          />
        </div>
        <div class="wb-list__description">
          <div v-if="email">
            <div class="name">{{ email }}</div>
          </div>
          <div v-else-if="email === null">
          </div>
          <div v-else>
            <blocked-content-icon />
          </div>
          <span class="mr-1" v-if="phone">
            {{ phone }}  &centerdot;
          </span>
          <div v-else-if="phone === null">
          </div>
          <div v-else>
            <blocked-content-icon />
          </div>
          <span class="staff-list-groups">
            <a @click="handleGroupsClick">
              <span v-if="groupCount">
                {{ pluralizedGroupCount }}
              </span>
              <span v-if="!groupCount">
                No Groups
              </span>
            </a>
          </span>
        </div>
      </div>
      <div class="staff-list-employment wb-list__column-content-10">
        <div class="wb-list__column-info">
          <div>
            Hire date:
            <span class="float-right">
              <wb-simple-date-time
                v-if="hireDate"
                class="ml-2"
                :value="hireDate"
                invalidMessage="--"
              />
              <blocked-content-icon
                v-else
              />
            </span>
          </div>
          <div>
            Start date:
            <span class="float-right">
              <wb-simple-date-time
                v-if="startDate"
                class="ml-2"
                :value="startDate"
                invalidMessage="--"
              />
              <blocked-content-icon
                v-else
              />
            </span>
          </div>
          <div>
            Notification start date:
            <span class="float-right">
              <wb-simple-date-time
                v-if="notificationDate"
                class="ml-2"
                :value="notificationDate"
                invalidMessage="--"
              />
              <blocked-content-icon
                v-else
              />
            </span>
          </div>
          <div>
            Last signed in date:
            <span class="float-right">
              <wb-simple-date-time
                v-if="endDate"
                class="ml-2"
                :value="lastLogin"
                invalidMessage="--"
              />
              <blocked-content-icon
                v-else
              />
            </span>
          </div>
        </div>
      </div>
      <div class="wb-list__column-content-50" style="flex-grow: 1" @click="handleProgressBarAreaClick">
        <div class="wb-list__content">
          <wb-breakdown-chart class="staff-list-collapse-40"
            v-if="statusCounts"
            :legendData="legendData"
            :chartData="statusCounts"
            :listViewPath="employeeFormsPath()"
            :includeLegend="false"
            modelNameHumanized="assigned form"
            @section-click="handleNonInternalFormProgressSectionClick"
          />
          <wb-breakdown-chart class="staff-list-collapse-40"
            v-if="internalDocsStatusCounts"
            :legendData="legendData"
            :chartData="internalDocsStatusCounts"
            :listViewPath="employeeFormsPath()"
            :includeLegend="false"
            modelNameHumanized="internal form"
            @section-click="handleInternalFormProgressSectionClick"
          />
        </div>
      </div>
    </div>
  </list-item>
</template>

<script>
  import BlockedContentIcon from 'components/common/BlockedContentIcon'
  import { BREAKDOWN_VARIANTS } from 'components/admin/dashboard/BreakdownChartConstants'
  import DropdownListItem from 'components/common/DropdownListItem'
  import EmployeeSmsStatusMixin from 'components/mixins/employee/EmployeeSmsStatusMixin'
  import ListItem from 'components/common/ListItem'
  import Locale from 'lib/locale'
  import PopoverIcon from 'components/common/PopoverIcon'
  import StaffListProgressIndicator from 'components/admin/staff_list/StaffListProgressIndicator'
  import StringUtil from 'lib/util/string'
  import TooltipIcon from 'components/common/TooltipIcon'
  import WbBreakdownChart from 'components/common/WbBreakdownChart'
  import WbSimpleDateTime from 'components/common/WbSimpleDateTime'
  import { mapGetters } from 'vuex'


  export default {
    name: 'staff-list-employee-item',
    components: {
      ListItem,
      DropdownListItem,
      TooltipIcon,
      WbSimpleDateTime,
      WbBreakdownChart,
      StaffListProgressIndicator,
      BlockedContentIcon,
      PopoverIcon
    },

    props: {
      employee: {
        type: Object,
        required: true
      },

      collectionViewName: {
        type: String,
        required: false
      },

      disabled: {
        type: Boolean,
        required: false,
        default: false
      }
    },

    data() {
      return {
        chartTooltipWarning: "We can't show you these forms at this time.",
        legendData: [
          {
            legend_key: 'accepted',
            message: 'accepted',
            variant: BREAKDOWN_VARIANTS.COMPLETE
          },
          {
            legend_key: 'employee_review',
            message: 'in admin review',
            variant: BREAKDOWN_VARIANTS.IN_PROGRESS
          },
          {
            legend_key: 'pending',
            message: 'pending',
            variant: BREAKDOWN_VARIANTS.IN_PROGRESS
          },
          {
            legend_key: 'rejected',
            message: 'rejected',
            variant: BREAKDOWN_VARIANTS.NEEDS_IMMEDIATE_ATTENTION
          },
          {
            legend_key: 'missing',
            message: 'missing',
            variant: BREAKDOWN_VARIANTS.NEEDS_ATTENTION
          },
        ]
      }
    },

    computed: {
      employeeId() {
        return this.employee.id
      },

      employeeFullName() {
        return this.$employee.fullName(this.employee)
      },

      employmentStatus() {
        return this.employee.employment_status
      },

      isActive() {
        return this.employmentStatus == 'active'
      },

      employeePath() {
        return this.$routes.employee_path({ id: this.employeeId }) || void(0)
      },

      onboardingProgress() {
        return this.employee.onboarding_progress || {}
      },

      // The logic that we're using for email and phone is this:
      // - if there is no email (email is null), don't display anything
      // - if the email is not permitted (API returns {'_wb_blocked': true}), display the "Restricted" text
      // - if the email exists and is permitted, display it
      email() {
        if (this.employee.email === null)
          return null
        else
          return this.permittedField(this.employee.email)
      },

      phone() {
        if (this.employee.primary_phone === null)
          return null
        else
          return this.permittedField(this.employee.primary_phone)
      },

      pluralizedGroupCount() {
        return StringUtil.pluralize(this.groupCount, 'Group')
      },

      onboardingSummary() {
        return this.onboardingProgress.summary || {}
      },

      undeliveredEmail() {
        return this.employee.email_bounced && Locale.t('errors.email.emailBounced')
      },

      // Return the statuses in onboardingSummary but remove the "total" key
      statusCounts() {
        const { total, ...assignmentStatusCounts } = this.onboardingSummary
        return assignmentStatusCounts
      },

      internalProgress() {
        return this.employee.internal_docs_progress || {}
      },

      // Same as onboardingSummary but for internal documents
      internalDocsSummary() {
        return this.internalProgress.summary || {}
      },

      // Return the statuses in internalDocsSummary but remove the "total" key
      internalDocsStatusCounts() {
        const { total, ...statusCounts } = this.internalDocsSummary
        return statusCounts
      },

      // The logic that we're using for the employment dates is this:
      // - if there is no date (value is null), return a string which will cause the wb-simple-date-time to
      //   render invalidMessage text
      // - if the date is not permitted (API returns {'_wb_blocked': true}), display the "Restricted" text
      // - if the date exists and is permitted, display it
      hireDate() {
        if (this.employee.hire_date === null)
          return '--'
        else
          return this.permittedField(this.employee.hire_date)
      },

      startDate() {
        if (this.employee.start_date === null)
          return '--'
        else
          return this.permittedField(this.employee.start_date)
      },

      endDate() {
        if (this.employee.end_date === null)
          return '--'
        else
          return this.permittedField(this.employee.end_date)
      },

      notificationDate() {
        if (this.employee.notification_start_date === null)
          return '--'
        else
          return this.permittedField(this.employee.notification_start_date)
      },

      lastLogin() {
        if (this.employee.current_sign_in_at === null)
          return '--'
        else
          return this.permittedField(this.employee.current_sign_in_at)
      },

      groupIds() {
        return this.employee.group_ids || []
      },

      groupCount() {
        return this.groupIds.length
      },
      ...mapGetters({
        currentAccount: 'account/current'
      })
    },

    methods: {
      handleFullItemClick() {
        Turbolinks.visit(this.employeePath)
      },

      employeeFormsPath() {
        return this.$routes.employee_assignments_path({ employee_id: this.employee.id })
      },

      permittedField(val) {
        if (typeof val === 'object' && val._wb_blocked)
          // user is not permitted to see this date field
          return false
        else
          return val
      },

      handleGroupsClick(e) {
        e.preventDefault()
        e.stopPropagation()
        this.emitShowSlideoutEvent({
          slideoutView: "groups"
        })
      },

      handleNonInternalFormProgressSectionClick(section) {
        this.emitShowSlideoutEvent({
          slideoutView: "forms",
          formsView: "non-internal",
          section
        })
      },

      handleInternalFormProgressSectionClick(section) {
        this.emitShowSlideoutEvent({
          slideoutView: "forms",
          formsView: "internal",
          section
        })
      },

      emitShowSlideoutEvent(additionalPayload) {
        this.$emit('wb-staff-page-show-slideout', {
          employeeID: this.employeeId,
          ...additionalPayload
        })
      },

      handleProgressBarAreaClick(e) {
        e.preventDefault()
        e.stopPropagation()

        // no op - this is just to prevent accidentally going to the full employee profile if you miss the target area
      }
    },

    mixins: [
      EmployeeSmsStatusMixin
    ]
  }
</script>
