var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ul", { staticClass: "nav navbar-nav navbar-right" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("li", { ref: "add-staff-member" }, [
      _c(
        "div",
        { staticClass: "btn-group btn-group-primary navbar-quick-add" },
        [
          _c(
            "a",
            {
              staticClass: "js-new-employee btn btn-sm btn-primary",
              attrs: { href: _vm.$routes.new_employee_path },
            },
            [_vm._v("\n        Add Staff Member\n      ")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-primary dropdown-toggle",
              attrs: {
                "data-toggle": "dropdown",
                "aria-label": "More Options",
              },
            },
            [
              _c("WbIcon", {
                staticClass: "fa-fw right",
                attrs: { type: "caret-down" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("ul", { staticClass: "dropdown-menu" }, [
            _c("li", [
              _c("a", { attrs: { href: _vm.$routes.new_user_import_path } }, [
                _vm._v("Import Staff"),
              ]),
            ]),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("li", { staticClass: "dropdown" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-link navbar-btn",
          attrs: { "data-toggle": "dropdown" },
        },
        [
          _c("WbIcon", {
            staticClass: "fa-fw",
            attrs: { type: "user-circle" },
          }),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.userFirstName))]),
          _vm._v(" "),
          _c("WbIcon", { staticClass: "fa-fw", attrs: { type: "caret-down" } }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "dropdown-menu dropdown-menu-pointy" },
        [
          _c("li", { staticClass: "dropdown-header" }, [
            _vm._v("\n        Signed in as\n        "),
            _c("strong", [_vm._v(_vm._s(_vm.currentUser.name))]),
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "dropdown-header" }, [
            _vm._v("\n        " + _vm._s(_vm.currentUser.email) + "\n      "),
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "divider" }),
          _vm._v(" "),
          _vm.profileItem
            ? _c(
                "SecondaryNavItem",
                { ref: "profileItem", attrs: { item: _vm.profileItem } },
                [
                  _c("WbIcon", {
                    staticClass: "fa-fw",
                    attrs: { slot: "icon", type: "user" },
                    slot: "icon",
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.downloadsItem
            ? _c(
                "SecondaryNavItem",
                { ref: "downloadsItem", attrs: { item: _vm.downloadsItem } },
                [
                  _c("WbIcon", {
                    staticClass: "fa-fw",
                    attrs: { slot: "icon", type: "download" },
                    slot: "icon",
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.kioskItem
            ? [
                _c("li", { staticClass: "divider" }),
                _vm._v(" "),
                _c(
                  "SecondaryNavItem",
                  { ref: "kioskItem", attrs: { item: _vm.kioskItem } },
                  [
                    _c("WbIcon", {
                      staticClass: "fa-fw",
                      attrs: {
                        slot: "icon",
                        type: "kiosk",
                        iconSet: "a4s-icon",
                      },
                      slot: "icon",
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("li", { staticClass: "divider" }),
          _vm._v(" "),
          _vm.helpItem
            ? _c(
                "SecondaryNavItem",
                {
                  ref: "helpItem",
                  attrs: { item: _vm.helpItem, target: "_blank" },
                },
                [
                  _c("WbIcon", {
                    staticClass: "fa-fw",
                    attrs: { slot: "icon", type: "question-circle" },
                    slot: "icon",
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.announcementsItem
            ? _c(
                "SecondaryNavItem",
                {
                  ref: "announcementsItem",
                  attrs: { item: _vm.announcementsItem },
                },
                [
                  _c("WbIcon", {
                    staticClass: "fa-fw",
                    attrs: { slot: "icon", type: "bullhorn" },
                    slot: "icon",
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("li", { staticClass: "divider" }),
          _vm._v(" "),
          _vm.switchAccountItem
            ? _c(
                "SecondaryNavItem",
                {
                  ref: "switchAccountItem",
                  attrs: { item: _vm.switchAccountItem },
                },
                [
                  _c("WbIcon", {
                    staticClass: "fa-fw",
                    attrs: { slot: "icon", type: "exchange" },
                    slot: "icon",
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("li", [_c("AppSignoutLink")], 1),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("div", {
        staticClass: "hidden",
        attrs: { id: "access-expiration-banner" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }