var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("PageHeader", { attrs: { title: _vm.header } }),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-lg-9" },
          [
            _c("WebhookForm", {
              attrs: {
                webhook: _vm.webhook,
                isEdit: _vm.isEdit,
                systemEventsOptions: _vm.systemEventsOptions,
                documentsForAccount: _vm.documentsForAccount,
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }