var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("dl", { staticClass: "dl", class: _vm.classNames }, [
    _c("dt", [_vm._t("term")], 2),
    _vm._v(" "),
    _c("dd", [_vm._t("definition")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }