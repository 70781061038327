var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AlertMessage", {
    attrs: {
      title: "Warning: E-Verify Demo Enabled",
      message:
        "This account has been linked to the E-Verify Web Services Demo Portal. All cases listed are for testing purposes only and do not represent valid E-Verify results.",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }