import Permission from 'models/policy_generator/permission'
import TemplatedPermissions from 'models/policy_generator/templated_permissions'


// Pre-built Baseline permission sets that can be applied quickly to fit the use case of most users
export default function(){
  TemplatedPermissions.call(this)
  
  this.templates = [
    {
      name: 'no_access',
      description: 'No access (and hide from the Staff page).',
      permissions: []
    },
    {
      name: 'view_basic',
      description: 'Read-only access to basic profile info (Name, Email, Address, Phone), but no access to forms.',
      permissions: [
        { target_id: 'a4s_employee_email',    target_type: 'RepresentableField',  action: 'view',   grant: true },
        { target_id: 'a4s_employee_address',  target_type: 'RepresentableField',  action: 'view',   grant: true },
        { target_id: 'a4s_employee_phone',    target_type: 'RepresentableField',  action: 'view',   grant: true },
        { target_id: null,                    target_type: 'RepresentableField',  action: 'view',   grant: false },
        { target_id: null,                    target_type: 'RepresentableField',  action: 'update', grant: false },
        { target_id: null,                    target_type: 'Employee',            action: 'view',   grant: true },
        { target_id: null,                    target_type: 'SupplementalFile',    action: 'view',   grant: false },
        { target_id: null,                    target_type: 'SupplementalFile',    action: 'manage', grant: false },
      ]
    },
    {
      name: 'view_full_profile',
      description: 'Read-only access to all profile info (including custom fields), but no access to forms.',
      permissions: [
        { target_id: null, target_type: 'RepresentableField',  action: 'view',   grant: true },
        { target_id: null, target_type: 'RepresentableField',  action: 'update', grant: false },
        { target_id: null, target_type: 'Employee',            action: 'view',   grant: true },
        { target_id: null, target_type: 'SupplementalFile',    action: 'view',   grant: false },
        { target_id: null, target_type: 'SupplementalFile',    action: 'manage', grant: false },
      ]
    },
    {
      name: 'view_all',
      description: 'Read-only access to all profile info and form submissions.',
      permissions: [
        { target_id: null, target_type: 'RepresentableField',  action: 'view',            grant: true },
        { target_id: null, target_type: 'RepresentableField',  action: 'update',          grant: false },
        { target_id: null, target_type: 'Document',            action: 'view_submission', grant: true },
        { target_id: null, target_type: 'Employee',            action: 'view',            grant: true },
        { target_id: null, target_type: 'SupplementalFile',    action: 'view',            grant: true },
        { target_id: null, target_type: 'SupplementalFile',    action: 'manage',          grant: false },
      ]
    }
  ]
}
