var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !this.isCurrentSubmission
    ? _c("div", { staticClass: "pull-right historical-submission-alert" }, [
        _c(
          "div",
          { staticClass: "alert alert-warning", attrs: { role: "alert" } },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "mt-3" }, [
              _c(
                "a",
                {
                  staticClass: "btn btn-info js-open-historical-submissions",
                  attrs: { href: "javascript:void(0)" },
                },
                [_vm._v("\n        View all submissions\n      ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { ref: "tooltipWrapper", staticClass: "tooltip-wrapper" },
                [
                  _c(
                    "ladda-button",
                    {
                      ref: "setAsCurrentSubmissionBtn",
                      attrs: { disabled: _vm.disabled },
                      on: { click: _vm.handleSetAsCurrent },
                    },
                    [_vm._v("\n          Set as Current Submission\n        ")]
                  ),
                ],
                1
              ),
            ]),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("i", { staticClass: "a4s-icon a4s-icon-archived-file" }),
      _vm._v("\n      You are viewing a historical submission.\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }