var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("default"),
      _vm._v(" "),
      _c("WbButton", {
        staticClass: "mt-4",
        attrs: { isLoading: _vm.isLoading, label: _vm.buttonText },
        on: { click: _vm.handleClickSubmitLocation },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }