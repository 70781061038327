var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "dashboard-header" },
      [
        _c("h2", { staticClass: "dashboard-title" }, [
          _vm._v("\n      Dashboard\n    "),
        ]),
        _vm._v(" "),
        _c(
          "LaddaButton",
          { ref: "refreshAllBtn", on: { click: _vm.refreshAll } },
          [
            _c("i", { staticClass: "fa fa-refresh" }),
            _vm._v("\n      Refresh All\n    "),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-lg-5" },
        [
          _c("AlertsContainer", { ref: "alerts" }),
          _vm._v(" "),
          _c("I9ReverifyContainer"),
          _vm._v(" "),
          _vm.shouldShowSmsUpsellWidget
            ? _c("SmsUpsellWidget", { ref: "smsUpsellWidget" })
            : _vm._e(),
          _vm._v(" "),
          _vm.shouldShowADPUpsellWidget
            ? _c("AdpUpsellWidget", { ref: "adpUpsellWidget" })
            : _vm._e(),
          _vm._v(" "),
          _vm.shouldShowEverifyUpsellWidget
            ? _c("EverifyUpsellWidget", { ref: "everifyUpsellWidget" })
            : _vm._e(),
          _vm._v(" "),
          _vm.shouldShowRI9UpsellWidget
            ? _c("RI9UpsellWidget", { ref: "rI9UpsellWidget" })
            : _vm._e(),
          _vm._v(" "),
          _vm.shouldShowDocumentVerificationUpsellWidget
            ? _c("DocumentVerificationUpsellWidget", {
                ref: "documentVerificationUpsellWidget",
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-lg-7" },
        [
          _vm.currentAccount.everifyFeature
            ? _c("EverifySummaryContainer", { ref: "everifySummary" })
            : _vm._e(),
          _vm._v(" "),
          _c("FormI9SummaryContainer", { ref: "formI9Summary" }),
          _vm._v(" "),
          _c("FormsSummaryContainer", { ref: "formsSummary" }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }