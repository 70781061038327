import EmployeeHelper from 'lib/employee'

// Access information about the current account
function install(Vue, _options) {
  Vue.prototype.$employee = {
    fullName: EmployeeHelper.fullName,
    fullNameWithMiddleInitial: EmployeeHelper.fullNameWithMiddleInitial,
    employeeAddress: EmployeeHelper.employeeAddress,
    employeeAddressWithAptNum: EmployeeHelper.employeeAddressWithAptNum
  }
}

export default install
