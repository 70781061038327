var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Panel", {
    staticClass: "scan-and-upload-panel",
    attrs: { variant: "snapshot" },
    scopedSlots: _vm._u([
      {
        key: "heading",
        fn: function () {
          return [
            _c("div", { staticClass: "panel-title" }, [
              _c("h3", [_vm._v("Action Required")]),
              _vm._v(" "),
              _c("h4", [_vm._v("Scan and Upload")]),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "col-centered" },
              [
                _c("p", { staticClass: "pt-1" }, [
                  _vm._v(
                    "\n        E-Verify requires photos of the staff member's documentation. Click\n        the button to send the photos to E-Verify.\n\n        If the photos are invalid or poor quality, they may need to be updated\n        by the staff member.\n\n        We will send photos of the US Passport that was uploaded for this Form I-9.\n      "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mb-3" },
                  [
                    _c("WbButton", {
                      ref: "scanAndUploadButton",
                      attrs: {
                        label: "Send photos to E-Verify",
                        isLoading: _vm.isLoading,
                        disabled: !_vm.canScanAndUpload,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submit()
                        },
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("EverifyCaseDocumentPreview", {
                  staticClass: "mb-3",
                  attrs: { caseId: _vm.caseId, showEverifyPhoto: false },
                }),
                _vm._v(" "),
                _c("EverifyCaseCloseForm", {
                  attrs: { everifyCase: _vm.everifyCase },
                  on: { closeTimeout: _vm.closeModal },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }