var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeader", { attrs: { title: "ADP® Workforce Now" } }),
      _vm._v(" "),
      _vm.isEnabled
        ? _c(
            "div",
            { ref: "enabled", staticClass: "panel gray-bg" },
            [
              _vm.hasIntegration
                ? _c(
                    "a",
                    { ref: "viewLink", attrs: { href: _vm.settingsPath } },
                    [_vm._v("\n      View/Update your ADP® Integration\n    ")]
                  )
                : _c(
                    "LaddaButton",
                    {
                      ref: "createButton",
                      staticClass: "btn btn-primary",
                      on: { click: _vm.createAdpIntegration },
                    },
                    [_vm._v("\n      Start your ADP® Integration\n    ")]
                  ),
            ],
            1
          )
        : _c("div", { ref: "disabled" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-7" }, [
                _c("h3", { staticClass: "feature-list-section-title" }, [
                  _vm._v("What is ADP® Workforce Now?"),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n          Create employees and send W4 and payroll data to ADP Workforce Now by integrating your WorkBright account. This integration is billed and contracted with your ADP account. By submitting your email and clicking “Add ADP to Account” you are simply notifying WorkBright to enable the integration and will be instructed with how to purchase the integration from the ADP Marketplace.\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("h3", { staticClass: "feature-list-section-title" }, [
                  _vm._v("What does the integration include?"),
                ]),
                _vm._v(" "),
                _c("ul", [
                  _c("li", { staticClass: "feature-list-item" }, [
                    _c(
                      "div",
                      { staticClass: "feature-list-item__icon" },
                      [
                        _c("WbIcon", {
                          attrs: { iconSet: "a4s-icon", type: "hiring" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "feature-list-item__message" }, [
                      _vm._v(
                        "\n              Send demographic data from the individual WorkBright profile to the staff profile in ADP.\n            "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "feature-list-item" }, [
                    _c(
                      "div",
                      { staticClass: "feature-list-item__icon" },
                      [
                        _c("WbIcon", {
                          attrs: { iconSet: "a4s-icon", type: "webinar" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "feature-list-item__message" }, [
                      _vm._v(
                        "\n              Single Sign On (SSO) to your ADP Workforce Now account from WorkBright.\n            "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "feature-list-item" }, [
                    _c(
                      "div",
                      { staticClass: "feature-list-item__icon" },
                      [
                        _c("WbIcon", {
                          attrs: { iconSet: "a4s-icon", type: "quality" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "feature-list-item__message" }, [
                      _vm._v(
                        "\n              Send Federal W4 data to the staff profile in ADP\n            "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "feature-list-item" }, [
                    _c(
                      "div",
                      { staticClass: "feature-list-item__icon" },
                      [
                        _c("WbIcon", {
                          attrs: {
                            iconSet: "a4s-icon",
                            type: "time-management",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "feature-list-item__message" }, [
                      _vm._v(
                        "\n              Send payroll data including pay rate, type, frequency and direct deposit info to the staff profile in ADP.\n            "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "feature-list-item" }, [
                    _c(
                      "div",
                      { staticClass: "feature-list-item__icon" },
                      [
                        _c("WbIcon", {
                          attrs: { iconSet: "a4s-icon", type: "brief" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "feature-list-item__message" }, [
                      _vm._v(
                        "\n              Select onboarding templates and company codes associated with your ADP account and select in WorkBright wizard to sync data.\n            "
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _c("h3", { staticClass: "feature-list-section-title" }, [
                  _vm._v("\n          Pricing & Getting Started\n        "),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n          Use the form to the right to have WorkBright enable the ADP® Workforce Now integration on your account. From there, you will receive notifications of how to purchase from ADP marketplace and configure on your account.\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("h4", { staticClass: "mt-3 d-inline mr-1" }, [
                  _vm._v("ADP Pricing"),
                ]),
                _vm._v(
                  "(billed and added to your ADP Workforce Now account)\n        "
                ),
                _vm._m(1),
                _vm._v(" "),
                _vm._m(2),
                _vm._v(" "),
                _vm._m(3),
              ]),
              _vm._v(" "),
              _vm._m(4),
            ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mt-3" }, [
      _c(
        "a",
        {
          attrs: {
            href: "https://workbright.com/why-us/flexible-integration-options/ADP/",
            target: "_blank",
          },
        },
        [_vm._v("Click here")]
      ),
      _vm._v(
        " to learn more about the exact fields that transfer and watch a recorded demonstration of how it works here.\n        "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("dl", { staticClass: "mt-3" }, [
      _c("dt", [_vm._v("0-500 Employees:")]),
      _vm._v(" "),
      _c("dd", [_vm._v("$59/mo or $469/year (30% savings)")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("dl", [
      _c("dt", [_vm._v("501-1000 Employees:")]),
      _vm._v(" "),
      _c("dd", [_vm._v("$159/mo or $1,336/year (30% savings)")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("dl", [
      _c("dt", [_vm._v("1000+ Employees:")]),
      _vm._v(" "),
      _c("dd", [_vm._v("$249/mo or $2,092/year (30% savings)")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-5" }, [
      _c("div", { staticClass: "panel gray-bg" }, [
        _c("iframe", {
          attrs: {
            src: "https://workbrightsupport.com/adp-add-on-request-form/?test",
            height: "400",
            frameborder: "0",
            width: "100%",
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }