import ReportsController from 'controllers/reports_controller'

export default function(Sector, App, Backbone, Marionette, $, _) {
  Sector.addInitializer(function() {
    Sector.controller = new ReportsController()

    Sector.listenTo(App.vent, 'report:created', function(report) {
      App.navigate("/" + report.id)
    })

    Sector.listenTo(App.vent, 'report:delete', function(id) {
      Sector.controller.deleteReport(id)
    })
  })
}
