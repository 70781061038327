export default Backbone.Model.extend({

  // Initialize object
  //
  // model: warnings data object
  // options: id: UserImport ID
  initialize: function(model, options){
    this.userImportId = options.id
    this.set('warnings', [])
  },
  url: function(){
    return '/user_imports/' + this.userImportId + '/warnings'
  }
})
