// This concern DRYs up some of the duplication for form fields
export default {
  props: {
    label: {
      type: String,
      required: false
    },

    required: {
      type: Boolean,
      default: false
    },

    errors: {
      type: Object,
      required: false
    },

    name: {
      type: String,
      required: false
    },

    placeholder: {
      type: String,
      required: false,
      default: null
    },

    hint: {
      type: String,
      required: false
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false
    },

    orientation: {
      type: String,
      required: false,
      default: 'vertical',
      validator: function (value) {
        return ['vertical', 'horizontal'].includes(value)
      }
    },

    // Including this prop will display a small question mark icon next to the label with popover messaging
    helpPopoverText: {
      type: String,
      required: false
    },

    autoFocus: {
      type: Boolean,
      default: false
    },
  },

  computed: {
    // Returns boolean whether or not the orientation is horizontal or not
    horizontal() {
      return this.orientation == 'horizontal'
    },

    placeholderValue() {
      return this.disabled ? null : this.placeholder
    }
  },

  mounted() {
    if(this.autoFocus && this.$refs.input) {
      this.$refs.input.focus()
    }
  },
}
