var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wb-card wb-card--upsell" }, [
    _c("div", { staticClass: "wb-card__title" }, [
      _vm._v(
        "\n    Activate today: Enhanced I-9 photo capture, type matching, & advanced document verification\n  "
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "wb-card__body mt-3" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "btn btn-primary mt-2",
          attrs: { href: _vm.documentVerificationsAccountsUrl },
        },
        [
          _vm._v(
            "\n      Learn More about Enhanced Document Verification\n    "
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _vm._v(
          "\n        Guided photo capture + OCR: Ensures staff take clear, well-framed photos of supporting documents, automatically scanning and transcribing details into Section 2.\n      "
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "\n        Document type matching: Confirms that uploaded documents align with the selected List A, B, or C work authorization type.\n      "
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "\n        (Premium only) Comprehensive document verification: Includes work authorization validation, original document checks, and fraud detection for enhanced compliance.\n      "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }