// Constants that represent the possible statuses of E-Verify cases
export const FILTER_EVERIFY_STATUSES = [
  { value: 'internal_error', label: 'Internal Error' },
  { value: 'submission_error', label: 'Submission Error' },
  { value: 'draft', label: 'Draft' },
  { value: 'unconfirmed_data', label: 'Unconfirmed Data' },
  { value: 'photo_match', label: 'Photo Match Required' },
  { value: 'scan_and_upload', label: 'Scan and Upload' },
  { value: 'manual_review', label: 'Manual Review' },
  { value: 'pending_referral', label: 'Pending Referral (TNC)' },
  { value: 'referred', label: 'Referred (TNC)' },
  { value: 'queued', label: 'Queued' },
  { value: 'employment_authorized', label: 'Employment Authorized' },
  { value: 'final_nonconfirmation', label: 'Final NonConfirmation' },
  { value: 'close_case_and_resubmit', label: 'Close Case and Resubmit' },
  { value: 'closed_authorized', label: 'Closed - Authorized' },
  { value: 'closed_nonconfirmation', label: 'Closed - Not Authorized' },
  { value: 'closed_other', label: 'Closed - Other' }
]

export const EVERIFY_STATUSES = Object.freeze({
  CLOSED_AUTHORIZED: 'closed_authorized',
  CLOSED_NONCONFIRMATION: 'closed_nonconfirmation',
  CLOSED_OTHER: 'closed_other',
  MANUALLY_CLOSED: 'manually_closed',
  PENDING_REFERRAL: 'pending_referral',
  PENDING_REFERRAL_BAD_DATA: 'pending_referral_bad_data',
  ALL_CLOSED_STATES: ['closed_authorized', 'closed_nonconfirmation', 'closed_other', 'manually_closed'],

  // CLOSABLE_STATES includes all the states except 'internal_error' and the states in ALL_CLOSED_STATES. This list
  // should match the list of closable cases in EverifyCaseStateMachine.
  CLOSABLE_STATES: [
    'created', 'unsubmitted_receipt', 'submitted', 'submission_error', 'draft',
    'unconfirmed_data', 'photo_match', 'scan_and_upload', 'manual_review', 'pending_referral',
    'referred', 'queued', 'employment_authorized', 'final_nonconfirmation', 'close_case_and_resubmit',
    'pending_referral_bad_data'
  ],

  // RENDER_ERROR_STATES includes all the states that we want to display errors. Basically, any case that is open and
  // processing. We don't want to display errors on closed cases because the errors can be confusing to customers.
  RENDER_ERROR_STATES: [
    'submission_error', 'draft', 'unconfirmed_data', 'photo_match', 'scan_and_upload', 'manual_review',
    'pending_referral', 'referred', 'queued', 'employment_authorized', 'final_nonconfirmation', 'close_case_and_resubmit',
    'pending_referral_bad_data'
  ],

  // ACTION_REQUIRED_STATES includes all the states that require an admin to take action. We use an extended slideout
  // to render these cases in the UI.
  ACTION_REQUIRED_STATES: [
    'photo_match',
    'referred',
    'scan_and_upload',
    'unconfirmed_data',
    'pending_referral',
    'pending_referral_bad_data',
    'final_nonconfirmation',
    'close_case_and_resubmit'
  ],

  ACTION_REQUIRED_ERRORS: [
    'missing_duplicate_continue_reason'
  ],

  // Filters
  FILTER_PROCESSING: 'filter-processing',
  FILTER_REQUIRES_ATTENTION: 'filter-requires-attention',
  ALL: FILTER_EVERIFY_STATUSES.map(status => status.value)
})

export const FEATURE_FLAGS_BY_STATUS = Object.freeze({
  unconfirmed_data: 'everifyUnconfirmedDataEnabled',
  scan_and_upload: 'everifyScanAndUploadEnabled',
  close_case_and_resubmit: 'everifyCloseCaseAndResubmitEnabled',
  final_nonconfirmation: 'everifyFinalNonconfirmationEnabled',
  pending_referral: 'everifyTNCEnabled',
  referred: 'everifyReferredEnabled'
})
