<template>
  <span>
    <span class="label label-primary" v-if="isCurrentSubmission" ref="label">
      Current Submission
    </span>
  </span>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'document-submission-current-submission-badge',
    props: {
      submissionId: {
        type: Number,
        required: true,
      },
    },
    computed: {
      isCurrentSubmission() {
        return !!this.assignment
      },

      submission() {
        return this.getSubmission(this.submissionId)
      },

      assignment() {
        if(!this.submission) {
          return
        }
        const assignments = this.assignmentFindBy({ 'current_submission_id': this.submission.id })
        return assignments[0]
      },

      ...mapGetters({
        getSubmission: 'document_submissions/get',
        assignmentFindBy: 'document_assignments/findBy',
      }),
    },
  }
</script>
