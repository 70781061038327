var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "tooltip",
          rawName: "v-tooltip.html",
          value: _vm.tooltipTitle,
          expression: "tooltipTitle",
          modifiers: { html: true },
        },
      ],
      staticClass: "staff-list-item-indicator",
      class: _vm.baseClass,
    },
    [_c("i", { staticClass: "fa fa-fw status-icon" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }