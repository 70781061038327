<template>
  <div>
    <a class="btn btn-default btn-sm" :href="employeeSupplementalFilesPath">
      <i class="fa fa-level-up fa-rotate-270" />
      Back to Files
    </a>
    <hr />
    <h3 class="page">
      {{ supplementalFileName }}
      <small>
        <a :href="employeePath">{{ employeeFullName }}</a>
      </small>
    </h3>
    <small>
      Uploaded
      <wb-simple-date-time :value="supplementalFileCreatedAt" include-timeago=true />
    </small>
    <hr />
  </div>
</template>

<script>
  import WbSimpleDateTime from 'components/common/WbSimpleDateTime'
  import { mapGetters } from 'vuex'

  export default {
    name: 'the-supplemental-files-show-root',
    components: {
      WbSimpleDateTime
    },
    computed: {
      supplementalFileName() {
        return this.pageContext.supplemental_file_name
      },

      supplementalFileCreatedAt() {
        return this.pageContext.supplemental_file_uploaded_at
      },

      employeePath() {
        return this.$routes.employee_path({ id: this.pageContext.employee_id })
      },

      employeeFullName() {
        return this.pageContext.employee_full_name
      },

      employeeSupplementalFilesPath() {
        return this.$routes.employee_supplemental_files_path({ employee_id: this.pageContext.employee_id })
      },

      ...mapGetters({
        pageContext: 'pageContext'
      }),
    }
  }
</script>
