import Vue from 'vue'
import Animations from 'lib/util/animations'

export default Vue.directive('highlight-on-change', {
  update: (el, binding) => {
    if (typeof binding.oldValue == 'object' || binding.oldValue != binding.value) {
      Animations.temporaryHighlight($(el))
    }
  }
})
