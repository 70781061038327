var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("BackButton", {
        ref: "backButton",
        attrs: { label: "Back to Dashboard", url: _vm.dashboardPath },
      }),
      _vm._v(" "),
      _c("PageHeader", {
        ref: "PageHeader",
        attrs: { title: "Staff Action Display (Tentative Non-Confirmation)" },
      }),
      _vm._v(" "),
      _vm.isReferred
        ? _c("EverifyTncReferredViewer", {
            ref: "everifyTncReferredViewer",
            attrs: { url: _vm.rdcUrl },
          })
        : _vm.isPending
        ? _c("div", { staticClass: "pb-4" }, [
            _c("h3", [_vm._v("What is E-Verify")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(_vm._s(_vm.$locale.t("everify.staff.tnc.whatIsEverify"))),
            ]),
            _vm._v(" "),
            _c("h3", [_vm._v("Instructions")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(_vm._s(_vm.$locale.t("everify.staff.tnc.instructions"))),
            ]),
            _vm._v(" "),
            _c("div", [
              _c("img", {
                staticClass: "img-responsive m-auto",
                attrs: {
                  src: _vm.$routes.Images.everify_tnc_header,
                  alt: "Everify Tentative Non-Confirmation Process",
                },
              }),
            ]),
            _vm._v(" "),
            _c("h3", [_vm._v("Further Information")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n      For more information about E-Verify and the Tentative Non-Confirmation process, please\n      "
              ),
              _c(
                "a",
                {
                  attrs: {
                    href: "https://www.e-verify.gov/employees/tentative-nonconfirmation-mismatch-overview",
                  },
                },
                [_vm._v("\n        click here.\n      ")]
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v("\n      If you have any other questions,\n      "),
              this.administratorEmail
                ? _c("span", { ref: "administratorEmail" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href:
                            "mailto:" +
                            this.administratorEmail +
                            "?Subject=Question about my E-Verify case",
                        },
                      },
                      [
                        _vm._v(
                          "\n          contact your E-Verify Program Administrator.\n        "
                        ),
                      ]
                    ),
                  ])
                : _c("span", { ref: "noAdministratorEmail" }, [
                    _vm._v(
                      "\n        contact your company's HR representative.\n      "
                    ),
                  ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row pt-4" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-md-7" },
          [_c("FileViewer", { attrs: { fileUrl: _vm.everifyCase.fan_url } })],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-xs-12 col-md-5" },
          [
            _vm.isPending
              ? _c("EverifyTncReviewPanel", {
                  ref: "everifyTncReviewPanel",
                  attrs: {
                    "everify-case": _vm.everifyCase,
                    options: _vm.tncReviewOptions,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }