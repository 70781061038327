import { getRoute } from 'lib/routes'
import { ajax } from 'jquery'
import { PolicyUnmarshaller } from 'models/policy_generator/policy_unmarshaller'

const state = () => ({
  collection: [],
  pagination: null,
})

const getters = {
  collection: state => state.collection,
  pagination: state => state.pagination,
}

const mutations = {
  SET_COLLECTION: (state, policies = []) => {
    state.collection = policies
  },
  SET_PAGINATION: (state, pagination) => {
    state.pagination = pagination
  },
}

const actions = {
  fetch: ({ commit }, { page }) => {
    return new Promise((resolve, reject) => {
      ajax({
        method: 'GET',
        url: getRoute('managed_access_policy_templates_list_path'),
        data: {
          page
        }
      })
        .then(({ items, pagination }) => {
          const policies = items?.map(policy => {
            const { permissionSets } = new PolicyUnmarshaller(policy.permission_sets)
            return { ...policy, permission_sets: permissionSets }
          })
          commit('SET_COLLECTION', policies)
          commit('SET_PAGINATION', pagination)
          resolve()
        })
        .fail(xhr => reject(xhr))
    })
  },
  create: ({ }, { data }) => {
    return new Promise((resolve, reject) => {
      ajax({
        method: 'POST',
        url: getRoute('managed_access_policy_templates_path'),
        data: data,
        dataType: 'json',
        contentType: 'application/json'
      })
        .then((data, status, xhr) => resolve({data, status, xhr}))
        .fail(xhr => reject(xhr))
    })
  },
  update: ({ }, {id, data}) => {
    return new Promise((resolve, reject) => {
      ajax({
        method: 'PATCH',
        url: getRoute('managed_access_policy_template_path', {id}),
        data: data,
        dataType: 'json',
        contentType: 'application/json'
      })
        .then((data, status, xhr) => resolve({data, status, xhr}))
        .fail(xhr => reject(xhr))
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
