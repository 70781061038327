<template>
  <button
    ref="button"
    :class="['btn', buttonTypeClass]"
    :disabled="disabled"
    type="button"
    @click="handleClick"
    :data-spinner-color="dataSpinnerColor"
  >
    <span class="ladda-label">
      <slot />
    </span>
  </button>
</template>
<script>
  import { BrandColors } from 'lib/brand'

  const bootstrapButtonClasses = ["btn-primary", "btn-default", "btn-secondary", "btn-info", "btn-danger", "btn-link", "btn-success"]
  const coloredButtonClasses = ["btn-primary", "btn-danger", "btn-success"]

  export default {
    name: 'ladda-button',
    props: {
      loading: {
        type: Boolean,
        required: false,
        default: false
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false
      },

      buttonTypeClass: {
        type: String,
        required: false,
        default: "btn-primary",
        validator: value => {
          return bootstrapButtonClasses.includes(value)
        }
      }
    },
    data () {
      return {
        ladda: null,
      }
    },
    computed: {
      dataSpinnerColor() {
        // For colored backgrounds, use white spinner
        return coloredButtonClasses.includes(this.buttonTypeClass) ? BrandColors.white : BrandColors.lightBlack
      }
    },
    methods: {
      handleClick(e) {
        this.start() // TODO: Remove once we get rid of stop/start
        this.$emit('click', e)
      },
      stop() {
        // Deprecated, do not use this. Instead set the state of the component to "loading=false"
        this.ladda.stop()
      },
      start() {
        // Deprecated, do not use this. Instead set the state of the component to "loading=true"
        this.ladda.start()
      },
    },
    mounted() {
      this.ladda = Ladda.create(this.$refs.button)
      if(this.loading) { this.ladda.start() };
      if(this.disabled) { this.ladda.disable() };
    },
    watch: {
      loading: function(loading) {
        loading ? this.start() : this.stop()
      },
      disabled: function(disabled) {
        if (disabled) {
          this.ladda.disable()
        } else {
          this.ladda.enable()
        }
      }
    },
    beforeDestroy: function() {
      this.ladda.remove()
      delete this.ladda
    }
  }
</script>
