var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.totalCount
    ? _c("div", [
        _vm.listViewPath
          ? _c(
              "a",
              {
                attrs: { href: _vm.listViewPath },
                on: { click: _vm.stopListViewPathPropagation },
              },
              [_vm._v("\n    " + _vm._s(_vm.pluralizedCount) + "\n  ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "wb-breakdown-chart" },
          _vm._l(_vm.validLegendData, function (section) {
            return _c("WbBreakdownChartSection", {
              key: section.legend_key,
              attrs: {
                legend_key: section.legend_key,
                width: _vm.sectionWidth(section.legend_key),
                variant: section.variant,
                tooltipMsg: _vm.sectionTitle(section),
              },
              on: { click: _vm.handleSectionClick },
            })
          }),
          1
        ),
        _vm._v(" "),
        _vm.includeLegend
          ? _c(
              "div",
              { staticClass: "wb-breakdown-chart__legend" },
              _vm._l(_vm.validLegendData, function (section) {
                return _c(
                  "div",
                  {
                    key: section.legend_key,
                    staticClass: "wb-breakdown-chart__legend__row",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "wb-breakdown-chart__legend__row__indicator",
                      },
                      [
                        _c("WbDotIndicator", {
                          attrs: { variant: section.variant },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip.top",
                            value: !_vm.sectionPath(section)
                              ? _vm.tooltipMsg
                              : "",
                            expression:
                              "!sectionPath(section) ? tooltipMsg : ''",
                            modifiers: { top: true },
                          },
                        ],
                        staticClass: "wb-breakdown-chart__legend__row__content",
                        class: { disabled: !_vm.sectionPath(section) },
                        attrs: {
                          href: _vm.sectionPath(section)
                            ? section.path
                            : "javascript:void(0)",
                        },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm.getPluralizedSectionCount(
                                _vm.sectionCount(section.legend_key),
                                _vm.modelNameHumanized
                              )
                            ) +
                            " " +
                            _vm._s(section.message) +
                            "\n      "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "wb-breakdown-chart__legend__row__percentage",
                        class:
                          "wb-breakdown-chart__legend__row__percentage--" +
                          section.variant,
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm.sectionWidthReadable(section.legend_key)
                            ) +
                            "\n      "
                        ),
                      ]
                    ),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
      ])
    : _c("div", [
        _c("div", { staticClass: "wb-list__subtitle" }, [
          _vm._v(
            "\n    No " + _vm._s(_vm.modelNameHumanized) + "s to display\n  "
          ),
        ]),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }