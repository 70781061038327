var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "FormFieldRadio",
        {
          ref: "radio",
          attrs: {
            label: _vm.label,
            options: _vm.options,
            errors: _vm.errors,
            orientation: _vm.orientation,
          },
          on: { input: _vm.updateSelection },
          model: {
            value: _vm.value.selection,
            callback: function ($$v) {
              _vm.$set(_vm.value, "selection", $$v)
            },
            expression: "value.selection",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "pt-2 pl-3",
              attrs: { slot: "select-date" },
              slot: "select-date",
            },
            [
              _c("FormFieldDate", {
                attrs: { minDate: _vm.minDate },
                model: {
                  value: _vm.value.date,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "date", $$v)
                  },
                  expression: "value.date",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }