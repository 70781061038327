var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "list-container" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-lg-2" },
        [
          _c("h3", [_vm._v("Staff List")]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _vm.groupsLoaded
            ? _c("StaffListFilterContainer", {
                attrs: {
                  collectionViewName: _vm.collectionViewName,
                  groupsOptions: _vm.allGroupsOptions,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-lg-10" }, [
        _c(
          "div",
          { staticClass: "wb-list staff-list-container" },
          [
            _c(
              "div",
              [
                _c("StaffListSlideout", {
                  attrs: {
                    watchPageContextVariable: "show_staff_list_slideout",
                    employee: _vm.currentEmployee,
                    initialSlideoutView: _vm.slideoutView,
                    initialFormsView: _vm.slideoutFormsView,
                    initialFormsStatusView: _vm.slideoutFormsStatusView,
                    groups: _vm.currentEmployeeData.groups,
                    documentAssignments:
                      _vm.currentEmployeeData.document_assignments,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "WbListHeader",
              {
                attrs: {
                  filteredRowIds: _vm.filteredRowIds,
                  collectionViewName: _vm.collectionViewName,
                  searchPlaceholder: "Search Staff...",
                  selectable: true,
                  disabled: _vm.selectAllDisabled,
                },
              },
              [
                _c(
                  "template",
                  { slot: "bulkActions" },
                  [
                    _c("BulkActions", {
                      attrs: {
                        selectedEmployees: _vm.selectedEmployees,
                        canDownload: _vm.canDownload,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "template",
                  { slot: "staticActions" },
                  [
                    _vm.anyEmployees
                      ? _c("WbListPaginationFooter", {
                          attrs: {
                            previousPage: _vm.pagination.previousPage,
                            nextPage: _vm.pagination.nextPage,
                            lastPage: _vm.pagination.lastPage,
                            totalItems: _vm.pagination.totalItems,
                            indexStart: _vm.pagination.indexStart,
                            indexEnd: _vm.pagination.indexEnd,
                            collectionViewName: _vm.collectionViewName,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "template",
                  { slot: "selectAll" },
                  [
                    _vm.pagination.totalItems > 0
                      ? _c("WbAllYall", {
                          attrs: { totalItemCount: _vm.pagination.totalItems },
                          on: {
                            "selected-all-yall": _vm.selectedAllYall,
                            "undo-all-yall": _vm.undoAllYall,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "v-wait",
              { attrs: { for: _vm.loadingName } },
              [
                _c("Spinner", {
                  attrs: { slot: "waiting", showLongWaitMessage: true },
                  slot: "waiting",
                }),
                _vm._v(" "),
                !_vm.anyEmployees
                  ? _c(
                      "p",
                      {
                        ref: "noStaffMessage",
                        staticClass: "p-4 m-4 text-center",
                      },
                      [
                        _vm._v(
                          "\n            No matching staff members\n          "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "ul",
                  { staticClass: "list-group" },
                  _vm._l(_vm.employees, function (employee) {
                    return _c("StaffListEmployeeItem", {
                      key: employee.id,
                      attrs: {
                        employee: employee,
                        collectionViewName: _vm.collectionViewName,
                        disabled: _vm.disableListItems,
                      },
                      on: { "wb-staff-page-show-slideout": _vm.showSlideout },
                    })
                  }),
                  1
                ),
                _vm._v(" "),
                _vm.showFooterPagination
                  ? _c("WbListPaginationFooter", {
                      attrs: {
                        previousPage: _vm.pagination.previousPage,
                        nextPage: _vm.pagination.nextPage,
                        lastPage: _vm.pagination.lastPage,
                        totalItems: _vm.pagination.totalItems,
                        indexStart: _vm.pagination.indexStart,
                        indexEnd: _vm.pagination.indexEnd,
                        collectionViewName: _vm.collectionViewName,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }