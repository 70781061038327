var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "table",
      { staticClass: "table table-responsive info-table panel-info-table" },
      [
        _c(
          "tbody",
          [
            _c("profile-row", {
              attrs: { label: "Type", value: _vm.employee.employee_type_label },
            }),
            _vm._v(" "),
            _c("profile-row", {
              attrs: { label: "Name", value: _vm.employee.full_name },
            }),
            _vm._v(" "),
            _c(
              "profile-row",
              { attrs: { label: "Email", value: _vm.employee.email } },
              [
                _c("a", { attrs: { href: "mailto:" + _vm.employee.email } }, [
                  _vm._v(_vm._s(_vm.employee.email)),
                ]),
                _vm._v(" "),
                _vm.undeliveredEmail
                  ? _c("TooltipIcon", {
                      staticClass: "text-danger pl-2",
                      attrs: {
                        icon: "envelope",
                        message: _vm.undeliveredEmail,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "profile-row",
              { attrs: { label: "Address", value: _vm.employee.address_html } },
              [
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.employee.address_html) },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "profile-row",
              { attrs: { label: "Phone", value: _vm.employee.phone } },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.employee.phone) + "\n        "
                ),
                _vm.hasValidSmsStatus
                  ? _c("TooltipIcon", {
                      ref: "smsStatus",
                      staticClass: "pl-2 text-danger",
                      attrs: { icon: "phone", message: _vm.smsStatus },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c("profile-row", {
              attrs: { label: "Gender", value: _vm.employee.gender_label },
            }),
            _vm._v(" "),
            _c(
              "profile-row",
              { attrs: { label: "Birthdate", value: _vm.employee.birthdate } },
              [_vm._v("\n        " + _vm._s(_vm.birthdate) + "\n      ")]
            ),
            _vm._v(" "),
            _c("profile-row", {
              attrs: { label: "Age", value: _vm.employee.age },
            }),
            _vm._v(" "),
            _c(
              "profile-row",
              { attrs: { label: "SSN", value: _vm.employee.ssn } },
              [
                _vm.employee.ssn
                  ? _c("span", [_vm._v(_vm._s(_vm.ssnMask))])
                  : _vm._e(),
              ]
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }