<template>
  <div class="nav-affix-bottom nav-localize">
    <LocaleToggle :localizeKey="localizeKey" />
  </div>
</template>
<script>
  import LocaleToggle from 'components/localization/LocaleToggle'

  export default {
    name: 'the-locale-toggle-root',
    components: {
      LocaleToggle
    },
    props: {
      localizeKey: {
        type: String,
        required: true
      }
    },
  }
</script>
