var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("hr"),
      _vm._v(" "),
      _c("h3", [_vm._v("Remote Countersign Settings")]),
      _vm._v(" "),
      _c("small", [
        _vm._v(
          '"Remote I-9 Reverification Active", "Allow Family Members to Countersign", "Minimum Age to\n    Countersign", and "Allow Staff to Initiate I-9 Resubmission" only apply if "Remote I-9 Verification Active" is checked'
        ),
      ]),
      _vm._v(" "),
      _c("FormFieldCheckbox", {
        attrs: {
          label: "Remote I-9 Verification Active",
          name: "remote_countersign_active",
          errors: _vm.errors,
          helpPopoverText:
            "If checked, remote verification will be enabled. This setting must be checked in order to apply the other I-9 Remote Verification settings.",
        },
        model: {
          value: _vm.formData.remote_countersign_active,
          callback: function ($$v) {
            _vm.$set(_vm.formData, "remote_countersign_active", $$v)
          },
          expression: "formData.remote_countersign_active",
        },
      }),
      _vm._v(" "),
      _c("FormFieldCheckbox", {
        attrs: {
          label: "Remote I-9 Reverification Active",
          name: "remote_reverification_active",
          errors: _vm.errors,
        },
        model: {
          value: _vm.formData.remote_reverification_active,
          callback: function ($$v) {
            _vm.$set(_vm.formData, "remote_reverification_active", $$v)
          },
          expression: "formData.remote_reverification_active",
        },
      }),
      _vm._v(" "),
      _c("FormFieldCheckbox", {
        attrs: {
          label: "Allow Family Members to Countersign",
          name: "allow_family_to_countersign",
          errors: _vm.errors,
          helpPopoverText:
            "If checked, family members of an employee are permitted to perform remote I-9 verification.",
        },
        model: {
          value: _vm.formData.allow_family_to_countersign,
          callback: function ($$v) {
            _vm.$set(_vm.formData, "allow_family_to_countersign", $$v)
          },
          expression: "formData.allow_family_to_countersign",
        },
      }),
      _vm._v(" "),
      _c("FormFieldCheckbox", {
        attrs: {
          label: "Allow Staff to Initiate I-9 Resubmission",
          name: "allow_staff_to_initiate_resubmission",
          errors: _vm.errors,
          hint: _vm.staffInitiateResubmissionHintText,
        },
        model: {
          value: _vm.formData.allow_staff_to_initiate_resubmission,
          callback: function ($$v) {
            _vm.$set(_vm.formData, "allow_staff_to_initiate_resubmission", $$v)
          },
          expression: "formData.allow_staff_to_initiate_resubmission",
        },
      }),
      _vm._v(" "),
      _c("FormFieldNumber", {
        attrs: {
          label: "Minimum Age to Countersign",
          name: "minimum_age_to_countersign",
          errors: _vm.errors,
          helpPopoverText:
            "If present, this value is the minimum age a person must be in order to perform remote I-9 verification.",
          orientation: "horizontal",
        },
        model: {
          value: _vm.formData.minimum_age_to_countersign,
          callback: function ($$v) {
            _vm.$set(_vm.formData, "minimum_age_to_countersign", $$v)
          },
          expression: "formData.minimum_age_to_countersign",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }