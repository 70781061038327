<template>
  <AlertMessage variant="danger" class="mt-3">
    <h4>Close Case</h4>
    <div
      v-if="hasClosureReasons"
      ref="closeUi"
    >
      <p
        v-html="instructions"
        v-if="instructions"
        ref="instructions"
      />
      <div>
        <FormFieldDropdown
          name="close_case_reason"
          ref="closeCaseReason"
          v-model="close_case_reason.case_closure_reason_code"
          label="Close Case Reason"
          :options="caseCloseReasonOptions"
          :errors="errors"
          placeholder="Reason"
          class="d-inline-block"
        />
      </div>
      <div v-if="otherReasonSelected">
        <FormFieldTextArea
          name="other_reason"
          ref="otherReason"
          label="Other Reason"
          type="text"
          v-model="close_case_reason.other_reason_description"
          :autoFocus="true"
          :errors="errors"
          placeholder="Reason for case closure"
          class="d-inline-block"
        />
      </div>
      <WbButton
        context="default"
        class="mt-2"
        ref="closeCaseButton"
        label="Close Case"
        :isLoading="isCloseCaseLoading"
        @click="handleCloseCase"
      />
    </div>
    <p
      v-else
      ref="cantCloseMessage"
    >
      <em>Case cannot be manually closed at this time.</em>
    </p>
  </AlertMessage>
</template>

<script>
  import FormFieldDropdown from 'components/common/FormFieldDropdown'
  import FormFieldTextArea from 'components/common/FormFieldTextArea'
  import AlertMessage from 'components/common/AlertMessage'
  import { mapActions } from 'vuex'
  import Util from 'lib/util'
  import WbButton from 'components/common/WbButton'

  const OTHER_CODES = ['OTHER', 'OTHER_FREE_TEXT']

  export default {
    name: "everify-case-close-form",

    components: {
      AlertMessage,
      FormFieldDropdown,
      FormFieldTextArea,
      WbButton,
    },

    props: {
      everifyCase: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        close_case_reason: {
          case_closure_reason_code: null,
          other_reason_description: null
        },
        isCloseCaseLoading: false,
        shouldShowErrors: false,
        xhrErrors: {}
      }
    },

    computed: {
      caseCloseReasonOptions() {
        const reasons = [
          {
            label: 'Please Choose...',
            value: null
          }
        ]
        this.everifyCase.closure_reasons?.forEach(ccr => {
          reasons.push ({
            label: ccr.name,
            value: ccr.code
          })
        })

        return reasons
      },

      closeCaseReasonErrors() {
        let errors = {}
        if(!this.close_case_reason.case_closure_reason_code) {
          errors = {
            "close_case_reason": [
              "Please select a reason for closing this case."
            ]
          }
        }
        return  errors
      },

      hasClosureReasons () {
        return this.everifyCase.closure_reasons && this.everifyCase.closure_reasons.length
      },

      errors() {
        if(this.shouldShowErrors){
          return {...this.closeCaseReasonErrors, ...this.otherReasonErrors, ...this.xhrErrors}
        }
        else {
          return {}
        }
      },

      instructions() {
        return this.$locale.t(`everify.close_case_instructions.${this.everifyCase.case_status}`)
      },

      isValid() {
        return Object.keys(this.errors).length === 0
      },

      otherReasonErrors() {
        let errors = {}
        if (this.otherReasonSelected) {
          if(!this.close_case_reason.other_reason_description) {
            errors = {
              "other_reason": [
                "Please enter a reason for closing this case."
              ]
            }
          }
        }
        return errors
      },

      otherReasonSelected() {
        return OTHER_CODES.includes(this.close_case_reason.case_closure_reason_code)
      },
    },

    watch: {
      close_case_reason() {
        this.shouldShowErrors = false
      },
      xhrErrors() {
        this.shouldShowErrors = true
      }
    },

    methods: {
      handleCloseCase() {
        this.shouldShowErrors = true
        if(this.isValid){
          this.isCloseCaseLoading = true
          // E-Verify Web Services rejects if we send this when not needed
          if(!this.otherReasonSelected){
            delete this.close_case_reason.other_reason_description
          }
          this.closeCase({
            caseId: this.everifyCase.id,
            closureReason: this.close_case_reason
          }).catch((xhr) => {
            this.isCloseCaseLoading = false
            this.handleErrors(xhr)
          })
        }
      },

      handleErrors(xhr) {
        if (xhr.responseJSON && xhr.responseJSON.errors) {
          this.xhrErrors = xhr.responseJSON.errors
        } else {
          Util.genericAjaxError("An error occurred.", xhr)
        }
      },

      ...mapActions({
        closeCase: 'everify_cases/closeCase'
      }),
    }
  }
</script>
