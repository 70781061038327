<template>
  <div>
    <WbModal
      ref="modal"
      :title="modalTitle"
      :watchPageContextVariable="collectionViewName"
      :locked="loading"
      @modalHidden="handleModalHidden"
    >
      <v-wait :for="loadingName">
        <Spinner :showLongWaitMessage="true" slot="waiting" />
        <p class="breathe" ref="download-label">
          Download files for {{ pluralizedStaffMembers }}
        </p>
        <div class="row py-4" :class="{'has-error': hasMissingFormsError }" ref="formRow">
          <div class="col-xs-3">
            <h4 class="mt-2 pull-right">
              <div class="required-field control-label">
                Forms
              </div>
            </h4>
          </div>
          <div class="col-xs-9">
            <WbMultiselect
              v-if="documentOptions && documentOptions.length"
              @input="values => setSelectedDocuments(values)"
              :selectOptions="documentOptions"
              :enableSearchField="true"
              :selectAll="true"
              :selectAllText="selectAllText"
              ref="documentSelect"
            />
            <div class="alert alert-warning" v-else ref="noDocSelect">{{ emptyDocumentsMsg }}</div>
            <small class="help-block" v-if="hasMissingFormsError" ref="missingFormError">
              {{ missingFormsError }}
            </small>
          </div>
        </div>
        <div class="row breathe">
          <div class="col-xs-3">
            <h4 class="mt-2 pull-right">
              Options
            </h4>
          </div>
          <div class="col-xs-9">
            <FormFieldRadio
              class="m-0"
              v-model="formData.includePreviousSubmissions"
              ref="previousSubmissions"
              name="includePreviousSubmissions"
              :options="previousSubmissions.options"
              :label="previousSubmissions.label"
            />
            <hr />
            <FormFieldRadio
              class="m-0"
              v-model="formData.acceptedOnly"
              ref="acceptedSubmissions"
              name="acceptedOnly"
              :options="acceptedSubmissions.options"
              :label="acceptedSubmissions.label"
            />
            <hr />
            <div
              class="input-wrapper checkbox"
              v-if="viewSupplementalFilesPermitted"
              ref="supplimentalFilesInput"
            >
              <label>
                <input type="checkbox" v-model="formData.supplementalFiles" />
                Include staff supplemental files
              </label>
            </div>

            <div class="input-wrapper checkbox">
              <label>
                <input type="checkbox" v-model="formData.deactivationNotes" />
                Include staff deactivation notes
              </label>
            </div>
          </div>
        </div>

        <div class="row breathe" :class="{'has-error': hasDescriptionError }">
          <div class="col-xs-3">
            <h4 class="mt-2 pull-right control-label">
              Description
            </h4>
          </div>
          <div class="col-xs-9">
            <FormFieldInput
              v-model="formData.description"
              :errors="formErrors"
              name="description"
              :hint="descriptionHint"
              type="text"
              @input="validateDescriptionLength"
            />
          </div>
        </div>

        <div>
          <h4>What Happens Next?</h4>
          <p>
            We will generate a .zip file containing the form submissions (as PDFs) of the selected forms
            for the selected staff. This will include uploaded photos, such as certifications and licenses.
          </p>
          <p>
            Downloads can take a few minutes or several hours depending on the size of your request and
            the volume of current requests. Once the download is complete, you will receive an email and
            be able to download it from <a :href="$routes.admin_downloads_path">your downloads page</a>.
            Your downloads page is accessible from the navigation helper that opens when you click your
            name in the top right corner.
          </p>
        </div>
      </v-wait>

      <div slot='footer'>
        <button class="btn btn-default" :disabled="loading" @click="hide">
          Cancel
        </button>
        <LaddaButton ref="submitButton" :disabled="isButtonDisabled" @click="submit">
          Request Download
        </LaddaButton>
      </div>
    </WbModal>
  </div>
</template>

<script>
  import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
  import * as mutate from 'vuex/mutation_types'
  import Constants from 'lib/constants'
  import Locale from 'lib/locale'
  import Util from 'lib/util'
  import WbModal from 'components/common/WbModal'
  import WbMultiselect from 'components/common/WbMultiselect'
  import FormFieldRadio from 'components/common/FormFieldRadio'
  import FormFieldInput from 'components/common/FormFieldInput'
  import LaddaButton from 'components/common/LaddaButton'
  import Spinner from 'components/common/Spinner'
  import StringUtil from 'lib/util/string'

  export default {
    name: 'bulk-downloads-container',

    components: {
      WbModal,
      WbMultiselect,
      FormFieldRadio,
      FormFieldInput,
      LaddaButton,
      Spinner,
    },

    props: {
      collectionViewName: {
        type: String,
        required: true
      },
      employeeIds: {
        type: Array,
        required: true
      },
    },

    data() {
      return {
        loadingName: "bulk_downloads_loading",
        modalTitle: "Download Form Submissions",
        formErrors: {
          selectedForms: [],
          description: []
        },
        formData: {
          employeeIds: [],
          documentIds: [],
          includePreviousSubmissions: false,
          acceptedOnly: true,
          deactivationNotes: false,
          supplementalFiles: false,
          description: "",
        },
        selectAllText: "Select All Forms",
        descriptionHint: "Notes about the download for your future reference",
        previousSubmissions: {
          label: "",
          options: [
            { label: "Include only most recent form submission", value: false },
            { label: "Include all previous submissions", value: true }
          ],
        },
        acceptedSubmissions: {
          label: '',
          options: [
            { label: "Include accepted submissions only", value: true },
            { label: "Include every submission status (rejected, pending, etc.)", value: false }
          ],
        },
      }
    },

    computed: {
      emptyDocumentsMsg() {
        if (this.permittedDocs && this.permittedDocs.length == 0) {
          return Locale.t('employees.bulkDownload.alerts.noDocumentsPermitted')
        }

        return Locale.t('employees.bulkDownload.alerts.emptyDocuments')
      },

      permittedDocs() {
        return this.documents &&
          this.permissions &&
          this.permissions.documents &&
          Object.values(this.documents).filter(doc => this.permissions.documents[doc.id] && this.permissions.documents[doc.id].view_submission)
      },

      pluralizedStaffMembers() {
        return StringUtil.pluralize(this.employeeIds.length, 'staff member')
      },

      viewSupplementalFilesPermitted() {
        return this.permissions &&
          this.permissions.supplemental_files &&
          this.permissions.supplemental_files.view
      },

      documentIds() {
        return Object.values(this.documents).map(d => d.id)
      },

      // The dropdown options for the list of forms
      documentOptions() {
        return this.permittedDocs && Object.values(this.permittedDocs).map(doc => {
          return { value: doc.id, label: doc.name }
        })
      },

      missingFormsError() {
        return this.formErrors.selectedForms[0]
      },

      hasMissingFormsError() {
        return this.formErrors.selectedForms && !!(this.formErrors.selectedForms.length)
      },

      hasDescriptionError() {
        return this.formErrors.description && !!(this.formErrors.description.length)
      },

      hasError() {
        return this.hasMissingFormsError || this.hasDescriptionError
      },

      loading() {
        return this.$wait.is(this.loadingName)
      },

      isButtonDisabled() {
        return this.loading || this.hasError
      },

      ...mapGetters({
        pageContext: 'pageContext',
      }),

      ...mapState({
        documents: state => state['documents'].collection,
        permissions: state => state['permissions']
      }),
    },

    created() {
      this.fetchData()
    },

    methods: {
      async fetchData () {
        this.setLoading(true)

        await this.fetchAllDocumentsPaginated()
        await this.fetchSupplementalFilePerms()
        await this.fetchDocumentPerms()

        this.setLoading(false)
      },

      handleModalHidden() {
        // Reset the data to allow dynamic reuse
        Object.assign(this.$data, this.$options.data())

        // Indicate the modal has closed
        this.$emit('hidden')
      },

      hide() {
        this.$refs.modal.hide()
      },

      setSelectedDocuments(documentIds) {
        this.formErrors.selectedForms = [] // Clear validation error
        this.formData.documentIds = documentIds
      },

      validateDescriptionLength(value) {
        let descriptionError = []
        const maxLength = Constants.DESCRIPTION_MAX_LENGTH
        if (value.length > maxLength)
        {descriptionError = [Locale.t('employees.bulkDownload.errors.description', maxLength)]}

        this.formErrors.description = descriptionError
      },

      submit() {
        this.setLoading(true)

        // Add employeeIds to param object
        this.formData.employeeIds = this.employeeIds

        // Display validation error if no forms were selected
        if (!this.formData.documentIds.length) {
          this.formErrors.selectedForms = [Locale.t('employees.bulkDownload.errors.documentIds')]
          this.$refs.submitButton.stop()
          this.setLoading(false)
          return null
        }

        this.bulkDownloadRequest({
          data: this.formData
        }).then(() => {
          this.setLoading(false)
          Util.showFlashNotice(Locale.t('employees.bulkDownload.success', this.employeeIds.length))
          this.$nextTick(() => {
            this.hide()
          })
        }).catch((xhr) => {
          Util.genericAjaxError(Locale.t('employees.bulkDownload.fail'), xhr)
          this.$refs.submitButton.stop()
        })
      },

      async fetchDocumentPerms() {
        if(this.documentIds && this.documentIds.length) {
          await this.permissionsBulkAuthorize({
            resourceType: 'documents',
            resourceIds: this.documentIds,
            actions: ['view_submission']
          })
        }
      },

      async fetchSupplementalFilePerms() {
        return this.permissionsBulkAuthorize({
          resourceType: 'supplemental_files',
          actions: ['view']
        })
      },

      setLoading (isLoading) {
        if (isLoading) {
          this.$wait.start(this.loadingName)
        } else {
          this.$wait.end(this.loadingName)
        }
      },

      ...mapActions({
        bulkDownloadRequest: 'employees/bulkDownload',
        fetchAllDocumentsPaginated: 'documents/fetchAllPaginated',
        permissionsBulkAuthorize: 'permissions/bulkAuthorize',
      }),

      ...mapMutations({
        setPageContextKeys: mutate.SET_PAGE_CONTEXT_KEYS,
      }),
    },
  }
</script>


