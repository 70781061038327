import EmployeeView from 'views/user_imports/validation/employee_view'

export default Marionette.CompositeView.extend({
  template: '#template-employee-table-view',
  childView: EmployeeView,
  childViewContainer: '#list-region',
  tagName: 'table',
  className: 'dt-sanitized',
  initialize: function(options){
    this.showErrors = (typeof(options.showErrors) != 'undefined') ? options.showErrors : true
  },

  // Apply classes after render
  onRender: function(){
    this.appendHeaderRow()

    this.$el.addClass('display')
    this.$el.addClass('cell-border')
    this.$el.addClass('employee-table')
  },

  // Setup Datatable, Popovers
  onShow: function(){
    var table = this.$el.dataTable({
      scrollX: true,
      autoWidth: false,
      ordering: false,
      searching: false,
      scrollY: 300,
      scrollCollapse: true,
      info: false,
      rowCallback: function(row, data, index) {
        App.Util.DataTables.singleLineData(row, data)
      },
    })

    var fixedColumnCount = this.showErrors ? 2 : 1
    new $.fn.dataTable.FixedColumns(table, {leftColumns: fixedColumnCount}) // fix two leftmost columns

    // Resize table with window
    $(window).resize(function(){
      table.fnAdjustColumnSizing()
    })

    $('[data-toggle="popover"]').popover()
  },

  // Append a row of column headers to the table
  appendHeaderRow: function(){
    var headerStr = ''

    // Row #
    headerStr += '<th>Row</th>'

    // Error Count
    if(this.showErrors)
      headerStr += '<th class=\'dt-head-nowrap\'># Errors</th>'

    // Column Labels
    _.each(this.modelFieldNames(), function(name){
      headerStr += '<th>' + name + '</th>'
    })

    var thead = this.$el.find('thead')
    thead.append('<tr>' + headerStr + '</tr>')
  },

  // List of field labels for column headers
  modelFieldNames: function(){
    var firstEmployee = this.collection.at(0)
    if(firstEmployee){
      return firstEmployee.fieldLabels()
    }
    return []
  }
})
