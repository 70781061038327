<template>
  <div>
    <PageHeader :title="pageTitle" />
    <WbCard
      v-if="formMode"
      ref="form"
      class="form-horizontal"
    >
      <div class="form-group required">
        <FormLabel
          label="Admin Name"
          :required="true"
          orientation="horizontal"
        />
        <div class="col-sm-7">
          <div class="input-sequence">
            <FormFieldInput
              ref="firstName"
              placeholder="First"
              name="first_name"
              :errors="errors"
              v-model="formData.first_name"
            />
            <FormFieldInput
              ref="middleName"
              placeholder="Middle"
              v-model="formData.middle_name"
            />
            <FormFieldInput
              ref="lastName"
              placeholder="Last"
              name="last_name"
              :errors="errors"
              v-model="formData.last_name"
            />
          </div>
        </div>
      </div>
      <FormFieldInput
        ref="email"
        placeholder="Email"
        label="Admin Email"
        v-model="formData.email"
        name="email"
        :errors="errors"
        orientation="horizontal"
        required
      />
      <AdminUserPolicyFields
        v-if="permissionsFeature"
        :accessPolicies="accessPolicies"
        :groups="groups"
        :accessPolicy="formData.accessPolicy"
        :scope="formData.scope"
        :errors="errors"
        @accessPolicySelected="handleAccessPolicySelected"
        @scopeSelected="handleScopeSelected"
      />
      <div
        v-if="customAccess.selected"
        class="form-group"
      >
        <FormField
          label="Permissions"
          name="permissions"
          orientation="horizontal"
          :errors="errors"
          :required="true"
        >
          <div class="pt-1">
            <span id="policy-msg" v-if="customAccess.valid">
              &#10003; Permissions Created
            </span>
            <WbButton @click="switchToWizardMode">
              {{ customAccess.valid ? "Modify" : "Create permissions" }}
            </WbButton>
          </div>
        </FormField>
      </div>
      <div class="row">
        <div class="col-sm-9 col-sm-offset-3 mt-3">
          <WbButton
            ref="saveButton"
            label="Save"
            @click="submit"
            :isLoading="isSaveButtonLoading"
          />
          <WbButton
            ref="cancelButton"
            context="default"
            label="Cancel"
            @click="cancel"
          />
        </div>
      </div>
    </WbCard>
    <CustomAccessPolicyWizard
      v-if="wizardMode"
      ref="wizard"
      :policy="customAccess.permissions"
      :name="fullName"
      :backButtonEnabled="false"
      @prewizardBack="handleWizardBack"
      @done="handleDoneWizard"
    />
  </div>
</template>
<script>
  import PageHeader from 'components/common/PageHeader'
  import FormLabel from 'components/common/FormLabel'
  import FormField from 'components/common/FormField'
  import FormFieldSelect from 'components/common/FormFieldSelect'
  import FormFieldRadio from 'components/common/FormFieldRadio'
  import FormFieldInput from 'components/common/FormFieldInput'
  import WbButton from 'components/common/WbButton'
  import WbCard from 'components/common/WbCard'
  import WbMultiselect from 'components/common/WbMultiselect'
  import WbIcon from 'components/common/WbIcon'
  import WbListPaginationFooter from 'components/common/WbListPaginationFooter'
  import { mapActions, mapGetters } from 'vuex'
  import Util from 'lib/util'
  import AdminUserPolicyFields from './AdminUserPolicyFields.vue'

  import Policy from "models/policy_generator/policy"
  import { PolicyUnmarshaller } from 'models/policy_generator/policy_unmarshaller'
  import CustomAccessPolicyWizard from "components/admin/custom_access_policies/CustomAccessPolicyWizard"

  export default {
    name: 'admin-user-form-container',

    components: {
      PageHeader,
      FormLabel,
      FormField,
      FormFieldSelect,
      FormFieldInput,
      FormFieldRadio,
      WbButton,
      WbMultiselect,
      WbCard,
      WbIcon,
      WbListPaginationFooter,
      AdminUserPolicyFields,
      CustomAccessPolicyWizard
    },

    props: {
      isEdit: {
        type: Boolean,
        required: true
      },
      permissionsFeature: {
        type: Boolean,
        required: true
      },
      accessPolicies: {
        type: Array,
        required: true
      },
      accessPolicy: {
        required: false
      },
      employeeProfile: {
        type: Object,
        required: false
      },
      user: {
        type: Object,
        required: false
      },
      groups: {
        type: Array,
        default() {
          return []
        }
      },
      scope: {
        type: Array,
        default() {
          return []
        }
      },
      permissions: {
        type: Array,
        default: () => ([])
      }
    },

    data() {
      return {
        formData: {
          ...this.user,
          ...this.employeeProfile,
          accessPolicy: this.accessPolicy,
          scope: this.scope?.map((s) => parseInt(s)) || []
        },
        errors: {},
        mode: "form",
        customAccess: {
          selected: null,
          valid: null,
          permissions: null
        },
        isSaveButtonLoading: false
      }
    },

    computed: {
      pageTitle() {
        return this.isEdit ?
          `Edit ${this.fullName}` :
          'New Admin User'
      },
      fullName() {
        return `${this.formData.first_name} ${this.formData.last_name}`
      },
      requestData() {
        return {
          admin_user: {
            first_name: this.formData.first_name || null,
            last_name: this.formData.last_name || null,
            middle_name: this.formData.middle_name || null,
            email: this.formData.email || null,
            access_policy: this.formData.accessPolicy || null,
            scope: this.formData.scope || [],
            policy: this.policyRequestData
          }
        }
      },

      policyRequestData() {
        return this.formData.accessPolicy === "custom_access" ? JSON.stringify(this.customAccess.permissions.toJSON()) : null
      },

      formMode() {
        return this.mode === 'form'
      },

      canSwitchToWizardMode() {
        return (
          Boolean(this.formData.first_name)
          && Boolean(this.formData.email)
          && this.formData.scope.length > 0
        )
      },

      wizardMode() {
        return this.mode === 'wizard'
      },
      ...mapGetters({
        currentAccount: 'account/current'
      })
    },

    created() {
      this.initPermissions()
      this.handleCustomAccess()
    },

    methods: {
      ...mapActions({
        createAdminUser: 'admin_users/create',
        updateAdminUser: 'admin_users/update'
      }),

      initPermissions() {
        if(this.permissions && this.permissions.length > 0){
          this.customAccess.permissions = new PolicyUnmarshaller(this.permissions)
        } else {
          this.customAccess.permissions = new Policy()
        }
      },

      submit() {
        this.clearErrors()

        if(!this.localValidate()){ // If not valid
          return
        }

        if(this.isEdit) {
          this.submitUpdate()
        } else {
          this.submitCreate()
        }
      },

      async submitUpdate() {
        try {
          this.isSaveButtonLoading = true
          const responseData = await this.updateAdminUser({id: this.user.id, data: this.requestData})
          Util.showFlashNotice(responseData.msg)
        } catch(xhr) {
          if (xhr.responseJSON && xhr.responseJSON) {
            this.errors = xhr.responseJSON.errors || {}
          } else {
            Util.genericAjaxError('An error occurred trying to update this admin user', xhr)
          }
        } finally {
          this.isSaveButtonLoading = false
        }
      },

      async submitCreate() {
        try {
          this.isSaveButtonLoading = true
          const responseData = await this.createAdminUser({data: this.requestData})
          Util.navigateAndShowFlashNotice(this.$routes.admin_users_path, responseData.msg)
        } catch(xhr) {
          if (xhr.responseJSON && xhr.responseJSON.errors) {
            this.errors = xhr.responseJSON.errors || {}
          } else {
            Util.genericAjaxError('An error occurred trying to create this admin user', xhr)
          }
        } finally {
          this.isSaveButtonLoading = false
        }
      },

      handleScopeSelected(scope) {
        this.formData.scope = scope
        this.handleCustomAccess()
      },

      handleAccessPolicySelected(accessPolicy) {
        this.formData.accessPolicy = accessPolicy
        this.handleCustomAccess()
      },

      handleCustomAccess() {
        if(this.formData.accessPolicy === "custom_access"){
          this.customAccess.selected = true
          this.customAccess.permissions.setScope(this.formData.scope)
          this.customAccess.valid = this.customAccess.permissions.valid()
        } else {
          this.customAccess.selected = false
        }
      },

      switchToWizardMode() {
        if(this.canSwitchToWizardMode){
          this.mode = 'wizard'
        } else {
          Util.showFlashNotice("Please fill in all admin information before creating the permissions.")
        }
      },

      switchToFormMode() {
        this.mode = 'form'
      },

      handleDoneWizard() {
        this.switchToFormMode()
        this.clearErrors()
        this.handleCustomAccess()
      },

      handleWizardBack() {
        this.initPermissions()
        this.handleCustomAccess()
        this.switchToFormMode()
      },

      localValidate() {
        if(this.formData.accessPolicy === "custom_access"){
          if(!this.customAccess.permissions.valid()){ // If not valid
            this.errors.permissions = ["This is required"]
          }
        }

        return Object.keys(this.errors).length === 0
      },

      cancel() {
        window.location.href = this.$routes.admin_users_path
      },
      clearErrors() {
        this.errors = {}
      }
    },
  }
</script>
