var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isValidFileType
    ? _c(
        "div",
        [
          _vm.allowDownload
            ? _c("Toolbar", { ref: "download", attrs: { reverse: "" } }, [
                _c(
                  "a",
                  {
                    staticClass: "btn btn-default",
                    attrs: {
                      target: "_self",
                      download: "",
                      href: _vm.downloadPath,
                    },
                  },
                  [
                    _c("WbIcon", {
                      attrs: { iconSet: "fa", type: "download" },
                    }),
                    _vm._v(
                      "\n      Download " + _vm._s(_vm.downloadType) + "\n    "
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isImage
            ? _c("div", [
                _c("img", { attrs: { src: _vm.fileUrl, width: "100%" } }),
              ])
            : _c("div", { staticClass: "pdfjs-wrapper" }, [
                _c("iframe", {
                  staticClass: "doc-viewer",
                  attrs: { src: _vm.pdfViewerUrl, width: "100%" },
                }),
              ]),
        ],
        1
      )
    : _c("div", { ref: "noPreview" }, [
        _vm._v("\n  No Preview Available for this File Type\n"),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }