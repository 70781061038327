var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "wb-modal",
    {
      ref: "modal",
      attrs: {
        id: "bulk-reset-assignments-modal",
        title: "Request New Submissions",
        watchPageContextVariable: "bulkResetAssignments",
        locked: _vm.loading,
      },
      on: {
        modalHidden: function ($event) {
          return _vm.$emit("hidden")
        },
      },
    },
    [
      _c(
        "v-wait",
        { attrs: { for: _vm.loadingName } },
        [
          _c("spinner", {
            attrs: { slot: "waiting", showLongWaitMessage: true },
            slot: "waiting",
          }),
          _vm._v(" "),
          _c(_vm.currentStepComponentName, {
            ref: "currentStep",
            tag: "component",
            attrs: {
              "nav-direction": _vm.navDirection,
              employeeIds: _vm.employeeIds,
              collectionViewName: _vm.collectionViewName,
            },
            on: { skip: _vm.skipStep, updateData: _vm.handleStepUpdateData },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-xs-4 text-left" },
            [
              _c("wb-button", {
                attrs: {
                  label: "Cancel",
                  context: "default",
                  disabled: _vm.loading,
                },
                on: { click: _vm.hide },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col-xs-4" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-xs-4 text-right" },
            [
              _c("wb-button", {
                attrs: {
                  label: _vm.nextLabel,
                  context: "primary",
                  disabled: _vm.loading,
                },
                on: { click: _vm.nextStep },
              }),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }