var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "wb-list__checkbox",
      on: { click: _vm.handleSelectAllClick },
    },
    [
      _c("input", {
        ref: "bulkSelect",
        attrs: { type: "checkbox", disabled: _vm.disabled },
        domProps: {
          checked: _vm.allSelected,
          indeterminate: this.someSelected,
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }