var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AdminUserFormContainer", {
    attrs: {
      isEdit: true,
      permissionsFeature: _vm.permissionsFeature,
      accessPolicies: _vm.accessPolicies,
      groups: _vm.groups,
      user: _vm.user,
      scope: _vm.scope,
      accessPolicy: _vm.accessPolicy,
      employeeProfile: _vm.employeeProfile,
      permissions: _vm.permissions,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }