<template>
  <Panel
    class="final-nonconfirmation-panel"
    variant="snapshot"
  >
    <template v-slot:heading>
      <div class="panel-title">
        <h3>Action Required</h3>
        <h4>{{ $locale.t('everify.status.final_nonconfirmation.title') }}</h4>
      </div>
    </template>
    <template v-slot:body>
      <p>
        {{ instructions }}
      </p>
      <EverifyCaseCloseForm
        :everifyCase="everifyCase"
        @closeTimeout="closeModal"
      />
    </template>
  </Panel>
</template>

<script>
  import Panel from 'components/common/Panel'
  import EverifyCaseCloseForm from 'components/everify/cases/EverifyCaseCloseForm'

  export default {
    name: 'final-nonconfirmation-panel',
    components: {
      Panel,
      EverifyCaseCloseForm
    },

    props: {
      everifyCase: {
        type: Object,
        required: true
      }
    },

    computed: {
      instructions() {
        return this.$locale.t('everify.status.final_nonconfirmation.instructions')
      }
    },

    methods: {
      closeModal() {
        this.$emit('close')
      }
    },
  }
</script>
