var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", [_vm._v("Set up permissions for " + _vm._s(_vm.firstName))]),
      _vm._v(" "),
      _c("permissions-wizard", {
        ref: "wizard",
        attrs: { policy: _vm.policy, firstName: _vm.firstName },
        on: { done: _vm.done },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }