<template>
  <AdminUserFormContainer
    :isEdit="false"
    :permissionsFeature="permissionsFeature"
    :accessPolicies="accessPolicies"
    :groups="groups"
  />
</template>
<script>
  import AdminUserFormContainer from 'components/admin/admin_users/AdminUserFormContainer'

  export default {
    name: 'the-new-admin-user-root',
    components: {
      AdminUserFormContainer
    },
    props: {
      permissionsFeature: {
        type: Boolean,
        required: true
      },
      accessPolicies: {
        type: Array,
        required: true
      },
      groups: {
        type: Array,
        default() {
          return []
        }
      }
    }
  }
</script>
