var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.display
    ? _c("div", { staticClass: "table-group-fields" }, [
        _c("table", { staticClass: "table table-bordered table-condensed" }, [
          _c(
            "tbody",
            [
              _vm._m(0),
              _vm._v(" "),
              _vm._l(_vm.permissions, function (permission, index) {
                return _c(
                  "tr",
                  { key: index },
                  [
                    _c("td", [
                      _vm._v(
                        "\n          " +
                          _vm._s(permission.actionName()) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("grant-cell", {
                      key: index,
                      attrs: {
                        action: permission.action,
                        permissions: [permission],
                        "target-id": permission.target_id,
                      },
                    }),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", { attrs: { width: "70%" } }, [
        _vm._v("\n          Action\n        "),
      ]),
      _vm._v(" "),
      _c("th", { attrs: { width: "30%" } }, [
        _vm._v("\n          Approved\n        "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }