var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.canLocalize
    ? _c("ul", { ref: "nav", staticClass: "nav nav-pills" }, [
        _c("li", { ref: "en", class: { active: _vm.language == "en" } }, [
          _c(
            "a",
            {
              attrs: { context: "link", role: "button" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.setLanguage("en")
                },
              },
            },
            [_vm._v("\n      English\n    ")]
          ),
        ]),
        _vm._v(" "),
        _c("li", { ref: "es", class: { active: _vm.language == "es" } }, [
          _c(
            "a",
            {
              attrs: { context: "link", role: "button" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.setLanguage("es")
                },
              },
            },
            [_vm._v("\n      Español\n    ")]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }