var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.customFieldsInternal.length > 0
      ? _c(
          "table",
          {
            ref: "internalFields",
            staticClass: "table table-responsive info-table panel-info-table",
          },
          [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "tbody",
              { ref: "internalFieldRows" },
              _vm._l(_vm.customFieldsInternal, function (field, index) {
                return _c("profile-row", {
                  key: index,
                  attrs: { label: field.label, value: field.value_decorated },
                })
              }),
              1
            ),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.customFieldsNotInternal.length > 0
      ? _c(
          "table",
          {
            ref: "otherFields",
            staticClass: "table table-responsive info-table panel-info-table",
          },
          [
            _vm._m(1),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.customFieldsNotInternal, function (field, index) {
                return _c("profile-row", {
                  key: index,
                  attrs: { label: field.label, value: field.value_decorated },
                })
              }),
              1
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", { staticClass: "info-sub-header" }, [
        _c("th", [_vm._v("Internal Fields")]),
        _vm._v(" "),
        _c("th", { staticClass: "text-right" }, [
          _c("small", [_vm._v("(hidden from staff member)")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", { staticClass: "info-sub-header" }, [
        _c("th", [_vm._v("Other Fields")]),
        _vm._v(" "),
        _c("th", { staticClass: "text-right" }, [
          _c("small", [_vm._v("(completed by staff member)")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }