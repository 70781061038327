var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "wb-modal",
        {
          ref: "modal",
          attrs: {
            title: _vm.modal.titleText,
            watchPageContextVariable: "shouldShowResetFieldMappingModal",
            locked: _vm.$wait.is("shouldShowResetFieldMappingModal"),
          },
        },
        [
          _c("span", { domProps: { innerHTML: _vm._s(_vm.modal.message) } }),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  attrs: { disabled: _vm.modal.shouldCancelButtonDisable },
                  on: { click: _vm.hideModal },
                },
                [_vm._v("\n        Cancel\n      ")]
              ),
              _vm._v(" "),
              _c(
                "ladda-button",
                { ref: "submitButton", on: { click: _vm.submit } },
                [_vm._v("\n        Update\n      ")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        { staticClass: "btn btn-primary", on: { click: _vm.openModal } },
        [_vm._v("\n    " + _vm._s(_vm.updateButtonText) + "\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }