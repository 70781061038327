class PermissionActionGroupCollection {
  constructor(permissions) {
    this.permissions = permissions
  }

  groups() {
    const grouped = {}
    this.permissions.forEach((permission) => {
      grouped[permission.target_id] = grouped[permission.target_id] || []
      grouped[permission.target_id].push(permission)
    })

    let groupedArr = []
    Object.keys(grouped).forEach((key) => {
      groupedArr.push({
        key: key == 'null' ? null : key,
        permissions: grouped[key],
        target_id: grouped[key][0].target_id
      })
    })

    return groupedArr.sort((a, b) => {
      if(a.key == null) { return 1 }
      if(b.key == null) { return -1 }
      if(a.key < b.key) {
        return -1
      }
      return 1
    })
  }
}

export default PermissionActionGroupCollection
