var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._v(" "),
      _vm._l(_vm.pageContext.ssoConfigs, function (ssoConfig, index) {
        return _c(
          "div",
          { key: index, staticClass: "sso-config-forms" },
          [
            _c("sso-saml-config-form-container", {
              attrs: {
                ssoConfig: ssoConfig,
                ssoProviderNames: _vm.pageContext.ssoProviderNames,
              },
            }),
          ],
          1
        )
      }),
      _vm._v(" "),
      _c("sso-service-provider-settings-container", {
        attrs: { ssoPaths: _vm.pageContext.ssoPaths },
      }),
      _vm._v(" "),
      _c("sso-scim-provisioning-container", {
        attrs: {
          scimPath: _vm.pageContext.ssoPaths.scim,
          scimToken: _vm.pageContext.scimToken,
        },
      }),
      _vm._v(" "),
      _vm._m(1),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sso-header" }, [
      _c("h2", [_vm._v("\n      Single Sign-On (SSO)\n    ")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n      Single Sign On (SSO) allows you to log into many websites, such as WorkBright, using one set of login credentials.\n    "
        ),
      ]),
      _vm._v(" "),
      _c("hr"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sso-footer pt-3" }, [
      _c("i", [
        _vm._v(
          "\n      Currently, we only support SAML authentication but we're always interested in supporting our customers' needs. If your company uses a different auth type, please ask us about it.\n    "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }