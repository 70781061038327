var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "td",
    {
      staticClass: "text-center",
      class: _vm.cellClass,
      attrs: { width: _vm.width },
    },
    [
      !_vm.grantNA
        ? _c("i", { staticClass: "fa", class: _vm.iconClass })
        : _vm._e(),
      _vm._v(" "),
      _vm.grantNA ? _c("span", [_vm._v("\n    N/A\n  ")]) : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }