var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeader", {
        attrs: {
          title: "Clone Documents",
          lead: "Clone documents from one account to another.",
          variant: "bordered",
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-horizontal" },
        [
          _c("FormAlerts", {
            attrs: {
              success: _vm.success,
              errors: _vm.formErrors,
              msgSuccess: _vm.$locale.t("documents.clone.success"),
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "input-wrapper medium-field" },
            [
              _c(
                "FormField",
                {
                  staticClass: "form-field-source-account",
                  attrs: {
                    orientation: "horizontal",
                    required: true,
                    label: "Source Account",
                    errors: _vm.formErrors,
                    name: "source_tenant",
                  },
                },
                [
                  _c("WbMultiselect", {
                    attrs: {
                      selectOptions: _vm.accountsSelectFormatted,
                      enableSearchField: true,
                      multiple: false,
                      placeholder: "",
                    },
                    model: {
                      value: _vm.sourceTenant,
                      callback: function ($$v) {
                        _vm.sourceTenant = $$v
                      },
                      expression: "sourceTenant",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "FormField",
                {
                  staticClass: "form-field-source-document",
                  attrs: {
                    orientation: "horizontal",
                    required: true,
                    label: "Source Document(s)",
                    errors: _vm.formErrors,
                    name: "source_document_ids",
                  },
                },
                [
                  _vm.sourceDocuments
                    ? _c(
                        "div",
                        { ref: "documentSelect" },
                        [
                          _c("WbMultiselect", {
                            attrs: {
                              selectOptions: _vm.sourceDocumentsSelectFormatted,
                              enableSearchField: true,
                              multiple: true,
                              selectAll: true,
                              selectAllText: "Select All Forms",
                              disabled: _vm.sourceDocuments.length == 0,
                              placeholder:
                                _vm.sourceDocuments.length == 0
                                  ? "No documents to clone"
                                  : "Select one or more documents",
                            },
                            model: {
                              value: _vm.sourceDocumentIds,
                              callback: function ($$v) {
                                _vm.sourceDocumentIds = $$v
                              },
                              expression: "sourceDocumentIds",
                            },
                          }),
                        ],
                        1
                      )
                    : _c("label", { staticClass: "control-label" }, [
                        _vm._v("––"),
                      ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "FormField",
                {
                  staticClass: "form-field-destination-tenant",
                  attrs: {
                    orientation: "horizontal",
                    required: true,
                    label: "Destination Account",
                    errors: _vm.formErrors,
                    name: "destination_tenant",
                  },
                },
                [
                  _c("WbMultiselect", {
                    attrs: {
                      selectOptions: _vm.accountsSelectFormatted,
                      enableSearchField: true,
                      multiple: false,
                      placeholder: "",
                    },
                    model: {
                      value: _vm.destinationTenant,
                      callback: function ($$v) {
                        _vm.destinationTenant = $$v
                      },
                      expression: "destinationTenant",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "FormField",
                {
                  staticClass: "form-field-employee-groups",
                  attrs: {
                    orientation: "horizontal",
                    label: "Employee Groups",
                    errors: _vm.formErrors,
                    name: "group_ids",
                  },
                },
                [
                  _vm.destinationEmployeeGroups
                    ? _c(
                        "div",
                        { ref: "destinationEmployeeSelect" },
                        [
                          _c("WbMultiselect", {
                            attrs: {
                              selectOptions:
                                _vm.destinationEmployeeGroupsFormatted,
                              value: _vm.destinationGroupsSelected,
                              placeholder: "",
                              enableSearchField: true,
                            },
                            on: {
                              input: function (values) {
                                return _vm.setSelectedGroups(values)
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _c("label", { staticClass: "control-label" }, [
                        _vm._v("––"),
                      ]),
                ]
              ),
              _vm._v(" "),
              _c("FormFieldCheckbox", {
                staticClass: "form-field-internal-only",
                attrs: {
                  label: "Force Internal Only",
                  errors: _vm.formErrors,
                  name: "force_internal",
                },
                model: {
                  value: _vm.forceInternal,
                  callback: function ($$v) {
                    _vm.forceInternal = $$v
                  },
                  expression: "forceInternal",
                },
              }),
              _vm._v(" "),
              _c(
                "Toolbar",
                [
                  _c(
                    "WbButton",
                    {
                      ref: "cloneButton",
                      attrs: { isLoading: _vm.isCloning },
                      on: { click: _vm.clone },
                    },
                    [_vm._v("\n          Clone\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }