var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _vm.isCurrentSubmission
      ? _c("span", { ref: "label", staticClass: "label label-primary" }, [
          _vm._v("\n    Current Submission\n  "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }