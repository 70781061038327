import AsyncItemView from 'views/lib/async_itemview'
import constants from 'lib/constants'

/* This acts like a regular AsyncItemView except that the wrapper element that is created has some attributes related to modals,
/* and we show the modal immediately once the remote content is loaded.
 * Users can bind to events:
 *  before:modal:show – to set up anything prior modal display
 *  modal:show – to set up anything after modal has been displayed
 *
 * Bind to standard bs.modal events for anything else.
 */

export default AsyncItemView.extend({
  attributes: {
    class: 'modal fade',
    tabindex: '-1',
  },

  modalOptions: {
    backdrop: 'static',
    keyboard: false,
    show: true,     /* NOTE: The modal will be shown immediately after it is initialized with .modal() */
  },

  /**
   * @param {object} options – any options, including optional `modalOptions`
   */
  initialize: function(options) {
    // Override modaloptions if it was provided in options
    this.modalOptions = $.extend(this.modalOptions, options.modalOptions)

    // We internally handle the remote-load _success event so that we can render and show the modal. We do, however, proxy the
    // caller's _success callback function, just in case the caller needs to bind high-level events tied to the View.
    if (this.getOption('_success')) {
      this._successProxy = this.getOption('_success')
    }
    options._success = this.renderAndShowModal
    this.options = options

    AsyncItemView.prototype.initialize.call(this, options)
  },

  hideModal: function() {
    this.$el.modal('hide')
  },

  renderAndShowModal: function() {
    // Call the proxy callback first, if provided
    if (this._successProxy)
      this._successProxy.call(this)

    this.triggerMethod('before:modal:show', this)

    // We initialize our element and immediately attach it to the DOM before calling modal. That way, the `bs.show.modal` event
    // can be bubbled up to $(document) properly.
    this.render()
    this.$el.appendTo($('body'))

    this.$el.modal(this.modalOptions)

    this.$el.one("hidden.bs.modal", $.proxy(this.bsModalHidden, this))

    // Set this so that future dom:refresh events get handled correctly (Marionette's DOMRefreshHandler needs _isShown
    // and _isRendered both to be true)
    this._isShown = true

    this.triggerMethod('modal:show', this)
    this.initHideEvents()
  },

  initHideEvents: function() {
    this.$el.on('keydown', $.proxy(this.onKeyDown, this))
    this.$el.find('[data-dismiss="modal"]').on('keypress', $.proxy(this.onCancelKeydown, this))
  },

  onKeyDown: function(e) {
    if (e.keyCode == constants.KeyCode.KEY_ESCAPE) {
      e.preventDefault()
      e.stopPropagation()
      this.hideModal()
      return false
    }
  },

  onCancelKeydown: function(e) { // Enable keyboard (enter key) for A11y
    if (constants.KeyCode.KEY_RETURN) {
      e.preventDefault()
      e.stopPropagation()
      this.hideModal()
      return false
    }
  },

  replaceModalContent: function(html) {
    this.$el.html(html)
    this.render() // should trigger dom:refresh, which will re-bind all events and UI, as well as trigger shown.a4s.async
    this.$el.modal('handleUpdate')   // SEE: http://getbootstrap.com/javascript/#.modal('handleupdate')
  },

  // after CSS transitions are complete, remove the modal from the DOM and destroy self
  bsModalHidden: function() {
    if(this.getOption('modalClose')) this.getOption('modalClose')() // Modal close callback, if present

    if (this.getOption('destroyOnHide') === false)
      return

    this.destroy()
  },

})
