var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "WbWorkflowStep",
    { attrs: { stepNumber: 1, title: "Confirm Location" } },
    [
      _c(
        "WbGeolocation",
        {
          attrs: { buttonText: "Share My Location" },
          on: {
            wbGeolocationSuccess: _vm.handleGeolocationSuccess,
            wbGeolocationError: _vm.handleGeolocationError,
          },
        },
        [
          _c("p", [
            _vm._v(
              "\n      To complete this form, you will need to select a person to review your documents. This person must:\n    "
            ),
          ]),
          _vm._v(" "),
          _c("ul", [
            _c("li", [
              _vm._v(
                "Be at least " +
                  _vm._s(_vm.minimumAgeToCountersign) +
                  " years of age"
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v("Have access to a separate camera-enabled smartphone"),
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v("Be able to log in to an internet-connected device"),
            ]),
            _vm._v(" "),
            _c("li", [_vm._v("Be able to meet In person with you")]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "Be willing to sign that they have reviewed your document(s) and that they appear genuine and to relate to you."
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "UNDER PENALTY OF PERJURY. YOU CANNOT SIGN YOUR OWN FORM I-9 SECTION 2."
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "When you are ready, click the button to share your location."
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }