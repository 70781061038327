var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { class: [_vm.canClick ? "" : "disabled"] }, [
    _c(
      "a",
      {
        ref: "link",
        attrs: { href: _vm.hrefOrVoid },
        on: {
          click: function () {
            if (_vm.canClick) {
              _vm.$emit("click")
            }
          },
        },
      },
      [
        _vm._v("\n    " + _vm._s(_vm.title) + "\n    "),
        _vm.hasSlotContent ? _vm._t("default") : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }