// Display a field name (label) and a text value. This is not really a form because there it does not accept input
// values but it can be used for displaying text fields within a form.

<template>
  <form-field
    :label="label"
    orientation="horizontal"
  >
    <div class="form-control-static block-translate">
      <strong>
        {{ value }}
      </strong>
    </div>
  </form-field>
</template>

<script>
  import FormField from 'components/common/FormField'
  import FormFieldConcern from 'mixins/FormFieldConcern'

  export default {
    name: 'form-field-read-only-text',

    mixins: [FormFieldConcern],
    components: {
      FormField,
    },

    props: {
      value: {
        type: String,
        required: true
      }
    },
  }
</script>
