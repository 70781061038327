var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", [_vm._v("Promote Staff to Admin")]),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-lg-9" }, [
            _c(
              "div",
              { staticClass: "panel gray-bg" },
              [
                _c("transition", { attrs: { name: "vue-fade" } }, [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.modeForm,
                          expression: "!modeForm",
                        },
                      ],
                    },
                    [
                      _vm._v("\n              Promoting\n              "),
                      _c("strong", [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.firstName) +
                            "\n              "
                        ),
                      ]),
                      _vm._v(
                        "\n              to administrator.\n              "
                      ),
                      _c(
                        "a",
                        {
                          attrs: { href: "javascript:void(0)" },
                          on: { click: _vm.policyBack },
                        },
                        [_c("i", { staticClass: "fa fa-times-circle-o" })]
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("transition", { attrs: { name: "vue-fade" } }, [
                  _c(
                    "form",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.modeForm,
                          expression: "modeForm",
                        },
                      ],
                      staticClass: "form-horizontal",
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.modePolicy,
                              expression: "!modePolicy",
                            },
                          ],
                        },
                        [
                          _c(
                            "form-field",
                            {
                              attrs: {
                                label: "Staff members",
                                required: true,
                                errors: _vm.errors,
                                name: "employee_id",
                                hint: "Select the staff member you want to promote.",
                                orientation: "horizontal",
                              },
                            },
                            [
                              _c("employee-search", {
                                model: {
                                  value: _vm.employee,
                                  callback: function ($$v) {
                                    _vm.employee = $$v
                                  },
                                  expression: "employee",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "transition",
                            { attrs: { name: "vue-fade" } },
                            [
                              _c("form-field-radio", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.employee && _vm.permissionsEnabled,
                                    expression:
                                      "employee && permissionsEnabled",
                                  },
                                ],
                                ref: "adminAccess",
                                attrs: {
                                  options: _vm.adminAccessOptions,
                                  label: "Admin Access",
                                  required: true,
                                  errors: _vm.errors,
                                  name: "access_role",
                                  orientation: "horizontal",
                                },
                                model: {
                                  value: _vm.access_role,
                                  callback: function ($$v) {
                                    _vm.access_role = $$v
                                  },
                                  expression: "access_role",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "transition",
                            { attrs: { name: "vue-fade" } },
                            [
                              _c(
                                "form-field",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.policyRequired &&
                                        !_vm.modePolicy &&
                                        _vm.employee,
                                      expression:
                                        "policyRequired && !modePolicy && employee",
                                    },
                                  ],
                                  attrs: {
                                    label: "Policy",
                                    required: true,
                                    errors: _vm.errors,
                                    name: "policy",
                                    hint: "A policy describes administrator permissions.",
                                    orientation: "horizontal",
                                  },
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "btn btn-secondary",
                                      attrs: { href: "javascript:void(0)" },
                                      on: { click: _vm.editPolicy },
                                    },
                                    [
                                      _vm.policySet
                                        ? _c("span", [
                                            _c("i", {
                                              staticClass: "fa fa-plus-circle",
                                            }),
                                            _vm._v(
                                              "\n                        Create a new Permissions Policy\n                      "
                                            ),
                                          ])
                                        : _c("span", [
                                            _c("i", {
                                              staticClass: "fa fa-edit",
                                            }),
                                            _vm._v(
                                              "\n                        Modify Policy\n                      "
                                            ),
                                          ]),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("transition", { attrs: { name: "vue-fade" } }, [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.modePolicy,
                                    expression: "!modePolicy",
                                  },
                                ],
                                staticClass: "form-group",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-sm-offset-4 col-md-offset-3 col-lg-offset-3 col-sm-8 col-md-9",
                                  },
                                  [
                                    _c(
                                      "ladda-button",
                                      {
                                        ref: "submit",
                                        attrs: { disabled: !_vm.canSubmit },
                                        on: { click: _vm.submit },
                                      },
                                      [
                                        _vm._v(
                                          "\n                        Submit\n                      "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "vue-fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.modePolicy,
                expression: "modePolicy",
              },
            ],
          },
          [
            _vm.modePolicy
              ? _c("permissions-wizard", {
                  ref: "wizard",
                  attrs: {
                    firstName: _vm.firstName,
                    policy: _vm.policy,
                    backButtonEnabled: false,
                  },
                  on: {
                    done: _vm.policyFinished,
                    prewizardBack: _vm.policyBack,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }