<template>
  <div class="mt-4">
    <p v-if="!groups.length">
      This staff member has no assigned groups. Visit their profile to add groups.
    </p>
    <div class="list-group" v-if="groups.length">
      <div class="list-group-item" v-for="group in groups" :key="group.id">
        {{group.name}}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'staff-list-slideout-groups-container',

    props: {
      groups: {
        type: Array,
        required: true
      },
    }
  }
</script>
