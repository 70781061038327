var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("RadioToggle", {
        class: _vm.radioToggleClass,
        attrs: {
          title: "",
          disabled: _vm.disabled,
          options: _vm.options,
          value: _vm.radioToggleValue,
        },
        on: { selected: _vm.changeSelection },
      }),
      _vm._v(" "),
      _c("input", {
        attrs: { name: _vm.name, type: "hidden" },
        domProps: { value: _vm.radioToggleValue },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }