import PrerenderedItemView from 'src/views/common/prerendered_item_view'
import Routes from 'lib/routes'
import DataTables from 'lib/util/datatables'

export default PrerenderedItemView.extend({
  tagName: 'tr',

  events: {
    'click a': 'linkClick',
    'click button[data-toggle="dropdown"]': 'dropdownBtnClick'
  },

  ui: {},

  // Because our tables often do row highlighting or other actions on TD/cell click events, if we embed links or
  // buttons inside of the table cell, they often get consumed by these TD/cell click handlers. So, we do some
  // Backbone magic here to capture link events IFF they have an href and pass them directly to Turbolinks,
  // stopping propagation altogether.
  linkClick: function(e) {
    // NOTE: we intentionally compare the $.attr('href') because that will give us the EXACT href as specified in the
    // code. Some browsers convert an href="#" to: href="FULL_URL#" when passed into the event
    if (e.currentTarget.href && e.currentTarget.href.length > 0 &&
        $(e.currentTarget).attr('href') != "javascript:void(0)" &&
        $(e.currentTarget).attr('href') != "#")
    {
      // Meta key allows us to capture cmd+click (i.e. open in new tab) while still passing to turbolinks for regular
      // clicks. badass.
      if (e.metaKey === true) {
        e.stopPropagation()
      } else {
        e.preventDefault()
        e.stopPropagation()
        Turbolinks.visit(e.currentTarget.href)
      }
    }
  },

  // Don't let the dropdown-button toggle event propagate up to the TD
  dropdownBtnClick: function(e) {
    e.preventDefault()
    e.stopPropagation()
    $(e.currentTarget).dropdown('toggle')
  },

  initialize: function() {
    if (this.model) {
      this.listenTo(this.model, "destroy", function() {
        this.triggerMethod('model:destroyed', this, this.model)
      })
    }
  }
})
