export default {
  Account: {},
  AdminDashboard: {},
  AdminUsers: {},
  Api: {},
  Common: {},
  CustomFields: {},
  Deadlines: {},
  DocumentSubmissions: {},
  Documents: {},
  Employees: {},
  Kiosk: {},
  Reports: {},
  Superadmin: {},
  UserImports: {},
  Webhooks: {}
}
