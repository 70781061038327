var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", [
    _c(
      "a",
      { attrs: { href: _vm.item.href, target: _vm.target } },
      [_vm._t("icon"), _vm._v("\n    " + _vm._s(_vm.item.label) + "\n  ")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }