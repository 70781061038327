import { render, staticRenderFns } from "./SortOrder.vue?vue&type=template&id=8af764d8&"
import script from "./SortOrder.vue?vue&type=script&lang=js&"
export * from "./SortOrder.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/workbright/shared/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8af764d8')) {
      api.createRecord('8af764d8', component.options)
    } else {
      api.reload('8af764d8', component.options)
    }
    module.hot.accept("./SortOrder.vue?vue&type=template&id=8af764d8&", function () {
      api.rerender('8af764d8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/assets/javascripts/src/components/common/collection_view_filters/SortOrder.vue"
export default component.exports