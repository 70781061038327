var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeader", { attrs: { title: "Upload Your Documentation" } }),
      _vm._v(" "),
      _vm.hasErrors && !_vm.isPersonaVerifiable(_vm.currentAttachment)
        ? _c("AlertMessage", {
            ref: "errorMessage",
            attrs: { message: _vm.errorMessage },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isPersonaVerifiable(_vm.currentAttachment)
        ? _c("PersonaUploadDocumentation", {
            key: _vm.personaUploadDocumentationKey,
            ref: "personaUploadDocumentation",
            attrs: {
              document: _vm.getDocument(_vm.currentAttachment),
              errors: _vm.errors,
              documentationKey: _vm.getDocumentationKey(_vm.currentAttachment),
              attachmentAttributes: _vm.getAttachmentAttributes(
                _vm.currentAttachment
              ),
              currentEmployeeId: _vm.currentEmployeeId,
              personaSettings: _vm.personaSettings,
            },
            on: {
              update: function (formData) {
                _vm.updateData(_vm.currentAttachment, formData)
              },
              verificationCreated: _vm.handleVerificationCreated,
              verificationReceived: function (formData) {
                return _vm.handleVerificationReceived(
                  _vm.currentAttachment,
                  formData
                )
              },
            },
          })
        : _c("UploadDocumentation", {
            key: _vm.uploadDocumentationKey,
            ref: "uploadDocumentation",
            attrs: {
              document: _vm.getDocument(_vm.currentAttachment),
              errors: _vm.errors,
              attachmentAttributes: _vm.getAttachmentAttributes(
                _vm.currentAttachment
              ),
              currentAccount: _vm.currentAccount,
            },
            on: {
              update: function (formData) {
                _vm.updateData(_vm.currentAttachment, formData)
              },
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }