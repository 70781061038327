<template>
  <div class="integrated-signature-pad">
    <h3>Electronic Signature</h3>
    <hr />
    <FormFieldInput
      class="mx-0"
      :required="true"
      :label="signatureNameInstructions"
      :errors="errors"
      :name="nameField.name"
      type="text"
      v-model="value.signature_name"
      v-if="nameField"
      :autofocus="nameField.autofocus ? true : false"
    />
    <slot name="subHeader" ref="subHeader"/>
    <div class="form-group mx-0 required"  :class="{'has-error' : hasError }">
      <div>
        <FormLabel
          :label="drawSignature"
          :name="this.signatureInputName"
          :required="true"
        />
        <button
          class="btn btn-sm btn-default btn-clear-signature"
          type="button"
          @click="clearSignature"
        >
          Clear
        </button>
      </div>
      <div class="signature-pad mt-0">
        <div class="signature-pad-body">
          <canvas class="form-control" ref="signatureCanvas"/>
          <div class="signature-pad-footer">
            Use your mouse (or your finger on mobile devices) to draw your signature.
          </div>
        </div>
      </div>
      <small class="help-block" ref="error-message" v-if="hasError">{{ errorMessage }}</small>
    </div>
  </div>
</template>

<script>
  import FormFieldInput from 'components/common/FormFieldInput'
  import FormLabel from 'components/common/FormLabel'
  import Locale from 'lib/locale'
  import SignaturePad from 'signature_pad'

  const SIGNATURE_PAD_PEN_COLOR = 'blue'

  export default {
    name: 'signature-pad',

    components: {
      FormFieldInput,
      FormLabel
    },

    props: {
      // For v-model support
      // Keys must include signature_name and signature
      value: {
        type: Object,
        required: true
      },

      // Keys must include autofocus, name
      nameField: {
        type: Object,
        required: false,
      },

      // Object representing
      errors: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },

    data () {
      return {
        drawSignature: Locale.t('signature_pad.instructions.drawSignature'),
        signatureInputName: 'signature_data_uri',
        signatureNameInstructions: Locale.t('signature_pad.instructions.printFullName')
      }
    },

    computed: {
      hasError() {
        return this.errors && this.errors[this.signatureInputName] && this.errors[this.signatureInputName].length > 0
      },
      errorMessage() {
        if(!this.hasError) { return null };
        return this.errors[this.signatureInputName].join(' ')
      }
    },

    created() {
      window.addEventListener("resize", this.debounceResizeCanvas)
    },

    mounted() {
      this.signaturePad = new SignaturePad(this.$refs.signatureCanvas, {
        penColor: SIGNATURE_PAD_PEN_COLOR,
        onEnd: this.onEnd,
      })

      this.$nextTick(() => {
        this.resizeCanvas()
        this.loadExistingSignature()
      })
    },

    destroyed() {
      window.removeEventListener("resize", this.debounceResizeCanvas)
    },

    methods: {
      // Scales <canvas> element based on screen pixel density
      resizeCanvas() {
        const ratio =  window.devicePixelRatio || 1
        const canvasNode = this.$refs.signatureCanvas

        canvasNode.width = canvasNode.offsetWidth * ratio
        canvasNode.height = canvasNode.offsetHeight * ratio
        canvasNode.getContext("2d").scale(ratio, ratio)
      },

      debounceResizeCanvas: _.debounce(
        function() {
          this.resizeCanvas()
          this.loadExistingSignature()
        },
        200
      ),

      clearSignature() {
        this.signaturePad.clear()
        this.resizeCanvas()

        // Emit new value for v-model
        this.$emit('input', {
          signature: '',
          signature_name: this.value.signature_name
        })
      },

      onEnd() {
        this.retrieveSignature()
      },

      // Retrieves latest signature from <canvas> element and updates v-model with String representing signature
      retrieveSignature() {
        let imageData = this.signaturePad.isEmpty() ? null : this.signaturePad.toDataURL()

        // Corner case on some browsers which still return incomplete data
        if (imageData == "data:,") {
          imageData = null
        }
        // Emit new value for v-model
        this.$emit('input', {
          signature: imageData,
          signature_name: this.value.signature_name
        })
      },

      // If the component is loaded with a v-model, we should load it on the <canvas>
      loadExistingSignature() {
        // We have an existing signature we need to load
        if (this.value.signature.length) {
          this.signaturePad.fromDataURL(this.value.signature)
        }
      }
    }
  }
</script>
