export default class FilterOpView extends Backbone.Marionette.ItemView.extend({
  template: '#filter-op-template',
  tagName: 'select',
  className: 'filter-op form-control',

  modelEvents: {
    'change:field': 'fieldChanged',
  },

  bindings: {
    ':el': 'op'
  },

  templateHelpers: {
    getAvailableOpsAsOptionsForSelect() {
      var serializedModel = this

      // If we don't have a model, there are no filter ops to render so quit early.
      if (!serializedModel.field) {
        return
      }

      var html = ""
      _(serializedModel.field.get('available_filter_ops')).each(function(labelValuePair) {
        html += '<option value="' + labelValuePair[1] + '">' + labelValuePair[0] + '</option>'
      })
      return html
    }
  },
}) {
  onRender() {
    // Initialize stickit here instead of initialize() because template <options> won't be rendered until now (in the case of
    // <select> nodes).
    this.stickit()

    var field = this.model.get('field')

    // Only show ourselves if we have an actual field with some filter ops
    this.$el.toggle(!!field && !!field.get('available_filter_ops'))
  }

  fieldChanged() {
    // Re-render ourselves, which will pick up the new <option> nodes from the new field
    this.render()
  }
}
