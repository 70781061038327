var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "slideout-panel",
    { attrs: { watchPageContextVariable: "show_audit_log_submission_id" } },
    [
      _vm.isLoading
        ? _c("Spinner", { ref: "loader" })
        : [
            _vm.submissionId
              ? _c(
                  "h3",
                  {
                    staticClass: "modal-title",
                    attrs: { slot: "title" },
                    slot: "title",
                  },
                  [
                    _vm._v("\n      Audit Events\n      "),
                    _c("br"),
                    _vm._v(" "),
                    _c("small", [
                      _vm._v(
                        "\n        " + _vm._s(_vm.documentName) + "\n        "
                      ),
                      _c("br"),
                      _vm._v(
                        "\n        " + _vm._s(_vm.employeeName) + "\n      "
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.hasSubmission
              ? _c("div", { staticClass: "alert alert-warning" }, [
                  _vm._v("\n      No current submission\n    "),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "submission-audit-log-list" }, [
              _c(
                "ul",
                { staticClass: "list-group wb-list" },
                _vm._l(_vm.auditLog, function (event) {
                  return _c("SubmissionAuditLogItem", {
                    key: event.id,
                    attrs: { event: event },
                  })
                }),
                1
              ),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }