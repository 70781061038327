var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", [
        _c("label", { staticClass: "small" }, [
          _vm._v("\n      " + _vm._s(_vm.filter.title) + "\n    "),
        ]),
        _vm._v(" "),
        _vm.filter.help_text
          ? _c("span", [
              _c(
                "a",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip.html",
                      value: _vm.filter.help_text,
                      expression: "filter.help_text",
                      modifiers: { html: true },
                    },
                  ],
                  staticClass: "help-popover ml-1",
                  attrs: { href: "javascript:void(0)" },
                },
                [_c("i", { staticClass: "fa fa-exclamation-circle" })]
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.filterToggle
        ? _c("radio-toggle", {
            staticClass: "mb-1",
            attrs: {
              title: _vm.filterToggle.title,
              value: _vm.filterToggleValue || _vm.filterToggle.defaultValue,
              options: _vm.filterToggle.options,
              disabled: _vm.toggleDisabled,
              helpText: _vm.filterToggle.helpText,
              buttonSize: "btn-sm",
            },
            on: {
              selected: function (selection) {
                return _vm.setFilterToggle(selection)
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("WbMultiselect", {
        attrs: {
          value: _vm.valueWithDefault,
          selectOptions: _vm.filter.options,
          enableSearchField: _vm.filter.enableSearchField,
        },
        on: {
          input: function (values) {
            return _vm.setFilterValue(values)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }