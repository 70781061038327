var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    directives: [
      {
        name: "tooltip",
        rawName: "v-tooltip.top",
        value: _vm.tooltipMsg,
        expression: "tooltipMsg",
        modifiers: { top: true },
      },
    ],
    staticClass: "wb-breakdown-chart__section",
    class: "wb-breakdown-chart__section--" + this.variant,
    style: _vm.sectionStyle,
    on: { click: _vm.handleSectionClick },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }