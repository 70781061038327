var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "p-2" },
    _vm._l(_vm.documentationSet, function (document, index) {
      return _c(
        "div",
        { key: index },
        [
          _c("h5", { ref: "documentTitle" + index, refInFor: true }, [
            _vm._v(_vm._s(document.document_title)),
          ]),
          _vm._v(" "),
          _c("FileViewer", {
            ref: "supportingDocumentationFileViewer" + index,
            refInFor: true,
            attrs: { fileUrl: document.file_front_url },
          }),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _vm.hasDocumentBack(document)
            ? _c("FileViewer", {
                ref: "supportingDocumentationFileViewerBack" + index,
                refInFor: true,
                attrs: { fileUrl: document.file_back_url },
              })
            : _vm._e(),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }