var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "form-group",
      class: { "has-error": _vm.hasError, required: _vm.required },
    },
    [
      _vm.label
        ? _c("FormLabel", {
            attrs: {
              label: _vm.label,
              name: _vm.name,
              required: _vm.required,
              orientation: _vm.orientation,
              helpPopoverText: _vm.helpPopoverText,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { class: { "col-sm-7 col-md-7": _vm.horizontal } },
        [
          _vm._t("default"),
          _vm._v(" "),
          _vm.hint
            ? _c("small", { staticClass: "help-block" }, [
                _vm._v(_vm._s(_vm.hint)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.hasError
            ? _c("small", { staticClass: "help-block" }, [
                _vm._v(_vm._s(_vm.errorMessage)),
              ])
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }