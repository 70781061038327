var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "SlideoutPanelExtended",
        {
          attrs: {
            watchPageContextVariable: _vm.showSlideoutPanelExtended,
            size: "xl",
          },
        },
        [
          _vm.currentId && _vm.extendedComponent
            ? _c(_vm.extendedComponent, {
                tag: "component",
                attrs: {
                  slot: "extended-content",
                  everifyCase: _vm.everifyCase,
                  caseEmployee: _vm.caseEmployee,
                  allowDownload: _vm.allowDownload,
                },
                on: { close: _vm.closePanel },
                slot: "extended-content",
              })
            : _vm._e(),
          _vm._v(" "),
          _vm._t("primary-title", [
            _vm.currentId
              ? _c("EverifyCasePanelTitle", {
                  attrs: {
                    slot: "primary-title",
                    employeeName: _vm.employeeFullName,
                  },
                  slot: "primary-title",
                })
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("template", { slot: "primary-content" }, [
            _c(
              "div",
              { staticClass: "slideout-content-region" },
              [
                _vm.currentId
                  ? _c("EverifyCasePanelContent", {
                      attrs: {
                        currentId: _vm.currentId,
                        everifyCase: _vm.everifyCase,
                        caseEmployee: _vm.caseEmployee,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "SlideoutPanel",
        { attrs: { watchPageContextVariable: _vm.showSlideoutPanel } },
        [
          _vm.currentId
            ? _c("EverifyCasePanelTitle", {
                attrs: { slot: "title", employeeName: _vm.employeeFullName },
                slot: "title",
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.currentId
            ? _c("EverifyCasePanelContent", {
                attrs: {
                  currentId: _vm.currentId,
                  everifyCase: _vm.everifyCase,
                  caseEmployee: _vm.caseEmployee,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }