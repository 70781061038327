var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Panel", {
    attrs: { variant: "snapshot" },
    scopedSlots: _vm._u([
      {
        key: "heading",
        fn: function () {
          return [
            _c("div", { staticClass: "panel-title" }, [
              _c("h3", [_vm._v("Action Required")]),
              _vm._v(" "),
              _c("h4", [_vm._v("Duplicate Case Alert")]),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c("p", { staticClass: "alert alert-warning" }, [
              _vm._v(
                "A duplicate continue reason must be provided to continue creating this case."
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n      A Duplicate Cases Found alert appears for a case that contains information found in one or more cases created within the last 365 days under the same employer account. There may be valid reasons for to create a duplicate case, such as if the previous case contains incorrect information. Review the situation and decide whether to continue with the present case.\n    "
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n      To continue this case, select a reason below:\n    "
              ),
            ]),
            _vm._v(" "),
            _c("I9DuplicateReasonForm", {
              attrs: { everifyCase: _vm.everifyCase },
              on: { update: _vm.handleUpdate },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }