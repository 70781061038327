var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DashboardWidget",
    {
      attrs: {
        widgetTitle: "Alerts",
        errorMsg: _vm.errorMsg,
        lastUpdated: _vm.lastUpdated,
      },
      on: {
        refresh: function ($event) {
          return _vm.fetch(true)
        },
      },
    },
    [
      _c(
        "v-wait",
        { attrs: { for: _vm.source } },
        [
          _c("Spinner", {
            attrs: { slot: "waiting", showLongWaitMessage: true },
            slot: "waiting",
          }),
          _vm._v(" "),
          !_vm.hasUrgentAlerts && !_vm.hasNonUrgentAlerts
            ? _c("p", [
                _vm._v(
                  "\n      Great work, there are no alerts that need your attention.\n    "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.hasUrgentAlerts
            ? [
                _c(
                  "p",
                  {
                    staticClass:
                      "wb-card__section-title wb-card__section-title--danger",
                  },
                  [
                    _c("i", { staticClass: "fa fa-exclamation-circle mr-2" }),
                    _vm._v("\n        Needs Immediate Attention\n      "),
                  ]
                ),
                _vm._v(" "),
                _vm.urgentAlerts.everify_account
                  ? _c("DashboardWidgetAlertItem", {
                      attrs: {
                        alertsMap: _vm.alertsContext,
                        countsData: _vm.urgentAlerts.everify_account,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.urgentAlerts.i9
                  ? _c("DashboardWidgetAlertItem", {
                      attrs: {
                        alertsMap: _vm.alertsContext,
                        countsData: _vm.urgentAlerts.i9,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.urgentAlerts.everify
                  ? _c("DashboardWidgetAlertItem", {
                      attrs: {
                        alertsMap: _vm.alertsContext,
                        countsData: _vm.urgentAlerts.everify,
                      },
                    })
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.hasNonUrgentAlerts
            ? [
                _c(
                  "p",
                  {
                    staticClass:
                      "wb-card__section-title wb-card__section-title--warning",
                  },
                  [
                    _c("i", { staticClass: "fa fa-exclamation-circle mr-2" }),
                    _vm._v("\n        Needs Attention\n      "),
                  ]
                ),
                _vm._v(" "),
                _vm.nonUrgentAlerts.everify
                  ? _c("DashboardWidgetAlertItem", {
                      attrs: {
                        alertsMap: _vm.alertsContext,
                        countsData: _vm.nonUrgentAlerts.everify,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.nonUrgentAlerts.i9
                  ? _c("DashboardWidgetAlertItem", {
                      ref: "nonUrgentI9AlertItems",
                      attrs: {
                        alertsMap: _vm.alertsContext,
                        countsData: _vm.nonUrgentAlerts.i9,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.nonUrgentAlerts.inbox
                  ? _c("DashboardWidgetAlertItem", {
                      attrs: {
                        alertsMap: _vm.alertsContext,
                        countsData: _vm.nonUrgentAlerts.inbox,
                      },
                    })
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }