var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form-field",
    {
      attrs: {
        label: _vm.label,
        name: _vm.name,
        orientation: _vm.orientation,
        required: _vm.required,
        errors: _vm.errors,
        hint: _vm.hint,
      },
    },
    [
      _c(
        "select",
        {
          staticClass: "form-control",
          attrs: { disabled: _vm.disabled },
          domProps: { value: _vm.value },
          on: {
            change: function ($event) {
              return _vm.$emit("input", $event.target.value)
            },
          },
        },
        _vm._l(_vm.options, function (option, index) {
          return _c(
            "option",
            { key: index, domProps: { value: option.value } },
            [_vm._v("\n      " + _vm._s(option.label) + "\n    ")]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }