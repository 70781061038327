var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c("TooltipIcon", { attrs: { icon: _vm.icon, message: _vm.name } }),
      _vm._v(" "),
      _vm.shouldShowType
        ? _c("span", { ref: "type" }, [
            _vm._v("\n    " + _vm._s(_vm.type) + "\n  "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }