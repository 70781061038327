<template>
  <SlideoutPanelExtended
    ref="slideout"
    size="xl"
    :watchPageContextVariable="slideoutKey"
    @modalShow="handleModalShow"
  >
    <template slot="extended-content">
      <SupportingDocumentation :documentationSet="attachments" />
    </template>
    <slot name="primary-title">
      <div class="panel-title" slot="primary-title">
        <h3>Document Verification</h3>
      </div>
    </slot>
    <template slot="primary-content">
      <Spinner
        ref="loader"
        v-if="isLoading"
      />
      <template
        v-else-if="hasSufficientData"
      >
        <div
          v-if="canApproveDecline"
          class="mb-2"
          ref="approveDeclineButtons"
        >
          <AlertMessage
            :message="canApproveMessage"
            ref="canApproveMessage"
            variant="warning"
          />
          <WbButton
            context="success"
            label="Approve"
            @click="handleApproveDecline(true)"
          />
          <WbButton
            context="danger"
            label="Decline"
            @click="handleApproveDecline(false)"
          />
        </div>
        <AlertMessage
          v-else
          :message="checkMessage"
          ref="checkMessage"
          variant="warning"
        />
        <InquiryAuditLog
          v-for="(attachment, index) in attachments"
          :key="index"
          :inquiry="attachment.document_verification"
          :documentName="attachment.document_title"
        />
        <DocumentVerificationLog v-if="isVerified" :submission="submission"/>
      </template>
      <AlertMessage
        v-else
        message="Unable to retrieve verification data"
        ref="noDataMessage"
      />
    </template>
  </SlideoutPanelExtended>
</template>
<script>
  import AlertMessage from 'components/common/AlertMessage'
  import InquiryAuditLog from 'components/document_submissions/i9/InquiryAuditLog'
  import SupportingDocumentation from 'components/document_submissions/i9/SupportingDocumentation'
  import SlideoutPanelExtended from 'components/common/SlideoutPanelExtended'
  import Spinner from 'components/common/Spinner'
  import Util from 'lib/util'
  import WbButton from 'components/common/WbButton'
  import { fetchSubmissionAttachments } from 'services/admin/documentSubmissionsService'
  import { resolveDocumentVerification } from 'services/admin/documentVerificationService'
  import DocumentVerificationLog from 'components/document_submissions/DocumentVerificationLog'
  import { VERIFIED_STATUSES } from 'decorators/document_verification_decorator'
  import { mapActions } from 'vuex'

  export default {
    components: {
      AlertMessage,
      InquiryAuditLog,
      SupportingDocumentation,
      SlideoutPanelExtended,
      Spinner,
      WbButton,
      DocumentVerificationLog,
    },

    props: {
      submission: {
        type: Object,
        required: true,
      },
    },

    emits: [
      'update',
    ],

    data () {
      return {
        isLoading: false,
        attachments: [],
      }
    },

    computed: {
      submissionId () {
        return this.submission.id
      },
      hasSufficientData () {
        return this.attachments.length > 0
      },
      slideoutKey() {
        return `documentVerificationSlideout-${this.submissionId}`
      },
      canApproveDecline() {
        return this.attachments.filter(attachment => attachment.document_verification.status === 'failed').length > 0
      },
      checkMessage () {
        return `It is up to you, as the Employer of Record to make the ultimate decisions about identity and/or work authorization documents presented by this employee. You can review which checks were performed on the documents provided by clicking the drop down 'Show Checks' below the document(s).`
      },
      canApproveMessage () {
        return `It is up to you, as the Employer of Record to make the decision to accept any document(s) provided.
            Any checks that did not pass may or may not be an indication of fraud, mistake, or misrepresentation.
            Please review any associated checks that did not pass and select 'Approve' or 'Decline' on this document based on your review of the documentation.
            If selecting 'Decline', it is at your discretion whether to proceed with employment,
            request a new submission or terminate employment in consultation with your own internal counsel and the <a target="_blank" href="https://www.uscis.gov/i-9-central/form-i-9-resources/handbook-for-employers-m-274">USCIS Handbook for Employers</a>.`
      },
      isVerified () {
        return this.attachments.some(attachment => VERIFIED_STATUSES.includes(attachment.document_verification.status))
      },
    },

    methods: {
      ...mapActions({
        updateAssignmentSubmission: 'i9_inbox_assignments/updateAssignmentSubmission',
      }),
      fetchData () {
        this.isLoading = true
        fetchSubmissionAttachments(this.submissionId)
          .then(response => {
            this.attachments = response.verified_attachments
          })
          .catch(() => {
            this.attachments = []
          })
          .finally(() => {
            this.isLoading = false
          })
      },
      handleModalShow() {
        if (!this.hasSufficientData) {
          this.fetchData()
        }
      },
      handleApproveDecline (isApproved) {
        this.isLoading = true

        resolveDocumentVerification(this.submissionId, isApproved)
          .then(({ submission, verified_attachments }) => {
            this.updateAssignmentSubmission(submission)
            this.attachments = verified_attachments || []

            this.$emit('update', this.submissionId)
          })
          .catch(xhr => {
            Util.genericAjaxError('An error occurred', xhr)
          })
          .finally(() => {
            this.isLoading = false
          })
      },
    },
  }
</script>
