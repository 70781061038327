// Enhance a jQuery XHR object with some utility methods that are relevant for our app. NOTE that this is a function
// NOT a class, so do not use with `new`.
//
// xhr - (jQuery XMLHttpRequest) the original XHR object
// settings - (Object) when called from a jQuery Deferred/Promise callback (fail, done, etc.), it may be useful to
//            provide the `settings` that the callback supplies. These settings will be merged into a `settings` object
//            on the XHR. These settings are normally not accessible from the actual XHR.
//
// Returns - (jQuery XMLHttpRequest) Decorated XHR
export default function WBRequestXHRDecorator(xhr, settings) {
  return Object.assign(xhr, {
    settings: settings,

    // Gets all of the flash messages that were passed along via response headers
    //
    // Returns - Object
    getFlashMessages() {
      return $.parseJSON(this.getResponseHeader('X-Flash-Messages'))
    },

    // Gets a specific flash message from the response headers
    //
    // Arguments
    //    messageType - String, the Flash key you want, e.g. alert, notice, etc.
    //
    // Returns - String, or null if there are no flash messages or messages of the requested type
    getFlashMessage(messageType) {
      const messages = this.getFlashMessages()
      if (!messages) {
        return null
      }

      return messages[messageType]
    },

    // Did the request time out?
    //
    // Returns - Boolean
    isTimeout() {
      return (this.status === 0 && this.statusText === 'timeout')
    },

    // Was the request aborted?
    //
    // Returns - Boolean
    isAborted() {
      return (this.status === 0 && this.statusText === 'abort')
    },

    // Was there a network error?
    //
    // Returns - Boolean
    isNetworkError() {
      return (this.status === 0 && this.statusText === 'error')
    },

    // A hash of useful metadata about the XHR request. This is useful in debugging.
    //
    // Returns - Object
    getRequestMetadata() {
      return {
        status: this.status,
        statusText: this.statusText,
        headers: this.getAllResponseHeaders(),
        readyState: this.readyState,
        responseText: this.responseText,
        settings: this.settings
      }
    }
  })
}
