import AsyncItemViewActiveList from 'lib/async_item_view_active_list'

/**
 * AsyncItemView simply loads the remote content into $el and triggers success callbacks. It does NOT, however, render/show
 * the view in any way. Event delegation is set up AFTER the view is rendered, so users of AsyncItemView need to either
 * place the view in a region and .show() it, or .render() it manually.
 *
 * NOTE: Objects which extend AsyncItemView and do their own rendering (e.g. AsyncModalItemView) should also set _isShown=true
 * so that DOM refresh events are handled properly if the view is ever re-rendered.
 *
 * Optional Properties:
 * asyncItemViewUniquenessKey: Defining a unique key string on a subclass will prevent this view from being displayed
 *                             more than once (e.g. a modal that opens every time you click a button)
 */
export default Marionette.ItemView.extend({
  template: false,

  // FIXME: Support option autoShow: false
  initialize: function(options) {
    // Determine if we're allowed to show this view. If the asyncItemViewUniquenessKey key is defined and locked,
    // it can only be shown once.
    if(AsyncItemViewActiveList.lockIfAvailable(this)) {
      this._getRemoteView()
      this.on('destroy', function(){ AsyncItemViewActiveList.unlock(this) }) // Cleanup, unlock when destroyed
    } else {
      // Not allowed to show this view (double click?). Destroy it immediately.
      setTimeout(() => { this.destroy() })
    }
  },

  _getRemoteView: function() {
    var preloadURL = this.getOption('_url')
    if (!preloadURL || preloadURL.length === 0) {
      throw new Error("Preload URL not specified")
    }

    $.ajax($.extend({
      url: preloadURL,
      type: 'GET',
      dataType: 'html',
    }, this.getOption('ajaxOptions')))
    .done((responseText, textStatus, xhr) => {
      this.$el.html(responseText)

      this.listenTo(this, 'dom:refresh', this._handleDOMRefresh)

      var successFunc = this.getOption('_success')
      if (successFunc) {
        successFunc.call(this)
      }
    })
    .fail(xhr => {
      this.destroy()
      var errorFunc = this.getOption('_error')
      if (errorFunc) {
        errorFunc(xhr)
      }
    })
    .always(() => {
      if (this.getOption('always')) {
        this.getOption('always')() // Callback when modal load is complete, success or failure
      }
    })
  },

  // When the AsyncItemView is finally shown in a region, trigger the event which kicks off JS initializers
  onShow: function() {
    this.$el.trigger('shown.a4s.async')
  },

  // If DOM:refresh is ever called, we need to re-bind UI, events, triggers, and so on.
  _handleDOMRefresh: function() {
    this.undelegateEvents()
    this.unbindUIElements()
    this.bindUIElements()
    this.delegateEvents()

    // Presumably the content is changing, fire this on behalf of our subclasses
    this.$el.trigger('shown.a4s.async')
  },
})
