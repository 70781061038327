<template>
  <div>
    <FormFieldSelect
      class="policy-select"
      ref="accessPolicy"
      label="Permission Policy"
      placeholder="Select permission policy"
      :required="true"
      :options="accessPolicyOptions"
      :value="formData.accessPolicies"
      v-model="formData.accessPolicy"
      @input="handleAccessPolicySelected"
      name="access_policy"
      :errors="errors"
      orientation="horizontal"
    />
    <div class="form-group required groups-select" v-if="fullAdminNotSelected">
      <FormLabel
        label="Groups"
        :required="true"
        orientation="horizontal"
        :errors="errors"
        name="scope"
      />
      <div class="col-sm-7" :class="{ 'has-error': scopeHasError }">
        <WbMultiselect
          :selectOptions="groupOptions"
          :enableSearchField="true"
          :selectAll="true"
          :selectAllText="selectAllText"
          :value="formData.scope"
          v-model="formData.scope"
          @input="handleScopeSelected"
          name="scope"
          :errors="errors"
          ref="groups"
        />
        <small v-if="scopeHasError" class="help-block">{{ scopeErrorMessage }}</small>
      </div>
    </div>
  </div>
</template>
<script>
  import FormLabel from 'components/common/FormLabel'
  import FormFieldSelect from 'components/common/FormFieldSelect'
  import WbMultiselect from 'components/common/WbMultiselect'

  export default {
    name: 'admin-user-policy-fields',
    components: {
      FormLabel,
      FormFieldSelect,
      WbMultiselect
    },
    props: {
      accessPolicies: {
        type: Array,
        required: true
      },
      accessPolicy: {
        required: false
      },
      groups: {
        type: Array,
        default() {
          return []
        }
      },
      scope: {
        type: Array,
        default() {
          return []
        }
      },
      errors: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        formData: {}
      }
    },
    computed: {
      selectAllText() {
        return 'Select All Groups'
      },
      fullAdminNotSelected() {
        return this.formData.accessPolicy !== 'full_access'
      },
      accessPolicyOptions() {
        return this.accessPolicies.map(([label, value]) => ({ label, value }))
      },
      groupOptions() {
        return this.groups.map((group) => ({ label: group.name, value: group.id }))
      },
      scopeErrorMessage() {
        return 'This field is required.'
      },
      scopeHasError() {
        return typeof this.errors.scope !== 'undefined'
      }
    },
    created() {
      this.formData = { accessPolicy: this.accessPolicy, scope: this.scope?.map((s) => parseInt(s)) || [] }
    },
    methods: {
      handleAccessPolicySelected(accessPolicy) {
        this.$emit('accessPolicySelected', accessPolicy)
      },
      handleScopeSelected(scope) {
        this.$emit('scopeSelected', scope)
      }
    }
  }
</script>
