var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isPermitted
    ? _c(
        "button",
        {
          class: _vm.classes,
          attrs: { disabled: _vm.isDisabled },
          on: { click: _vm.click },
        },
        [
          _vm._t("prepend"),
          _vm._v(" "),
          _vm.label
            ? _c("span", { domProps: { innerHTML: _vm._s(_vm.label) } })
            : _vm._t("default"),
          _vm._v(" "),
          _vm.tooltip
            ? _c("span", { ref: "tooltip", class: _vm.tooltipClasses }, [
                _vm._v("\n    " + _vm._s(_vm.tooltip) + "\n  "),
              ])
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }