export default Marionette.LayoutView.extend({
  template: false,
  ui: { 
    reviewNowBtn: '.js-review-now',
    startNewBtn: '.js-start-new',
  },

  triggers: {
    'click @ui.startNewBtn': 'startNew'
  },

  onStartNew: function() {
    // Just go back to Employee Selection page (passkey reqd)
    App.globalVent.trigger('kiosk:admin:command', App.Util.getPageRootPath())
  }
})
