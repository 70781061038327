var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group", class: _vm.name }, [
    _c(
      "div",
      { ref: "label", staticClass: "control-label col-sm-4 col-md-3 col-lg-3" },
      [_vm._v("\n    " + _vm._s(_vm.label) + " (optional):\n  ")]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "form-control-static col-sm-8 col-md-9 col-lg-9 block-translate",
      },
      [
        _c("strong", { ref: "displayValue", staticClass: "info" }, [
          _vm._v("\n      " + _vm._s(_vm.displayValue) + "\n    "),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            ref: "excludeButton",
            staticClass: "btn btn-xs btn-default btn-text-primary ml-1",
            class: _vm.name,
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.toggleField($event)
              },
            },
          },
          [_vm._v("\n      " + _vm._s(_vm.buttonText) + "\n    ")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }