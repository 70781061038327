<!-- // Pagination buttons to scroll through pages:  1-10 of 99  |  FIRST < > LAST -->

<template>
  <div class="pagination-footer">
    <div class="pagination-status">
      <strong>
        {{ paginationStatus }}
      </strong>
    </div>
    <div class="pagination-button">
      <a
        class="underline"
        v-bind:class="{'disabled': !previousPage}"
        @click.stop.prevent="getPageItems(1, !previousPage)"
      >
        FIRST
      </a>
    </div>
    <div class="pagination-button previous">
      <a
        v-bind:class="{'disabled': !previousPage}"
        @click.stop.prevent="getPageItems(previousPage, !previousPage)"
      >
        <i class="fa fa-chevron-left last-page" />
      </a>
    </div>
    <div class="pagination-button forward">
      <a
        v-bind:class="{'disabled': !nextPage}"
        @click.stop.prevent="getPageItems(nextPage, !nextPage)"
      >
        <i class="fa fa-chevron-right next-page" />
      </a>
    </div>
    <div class="pagination-button">
      <a
        class="underline"
        v-bind:class="{'disabled': !nextPage}"
        @click.stop.prevent="getPageItems(lastPage, !nextPage)"
      >
        LAST
      </a>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'wb-list-pagination-footer',
    props: {
      previousPage: {
        type: Number,
        required: false
      },

      nextPage: {
        type: Number,
        required: false
      },

      lastPage: {
        type: Number,
        required: false
      },

      totalItems: {
        type: Number,
        required: false
      },

      indexStart: {
        type: Number,
        required: false
      },

      indexEnd: {
        type: Number,
        required: false
      },

      collectionViewName: {
        type: String,
        required: false
      }
    },

    computed: {
      paginationStatus() {
        if (this.indexStart == this.indexEnd) {
          return `${this.indexStart} of ${this.totalItems}`
        } else {
          return `${this.indexStart}-${this.indexEnd} of ${this.totalItems}`
        }
      },
      shouldDispatch () {
        return !!this.collectionViewName
      }
    },

    methods: {
      getPageItems(pageNum, disabled) {
        if (!disabled) {
          if (this.shouldDispatch) {
            this.$store.dispatch(`${this.collectionViewName}/setPageNumber`, { page: pageNum })
          }
          this.$emit('pageChange', pageNum)
        }
      }
    }
  }

</script>
