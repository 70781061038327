var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showStart
        ? _c(
            "div",
            { ref: "start" },
            [
              _c("p", [_vm._v(_vm._s(_vm.employeeFirstName) + ",")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n      Next choose someone to review (in person) the work authorization documents(s) you uploaded and then sign your Form I-9.\n    "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v("\n      Ask someone who is "),
                _c("strong", [
                  _vm._v(
                    "age " +
                      _vm._s(
                        this.pageContext.i9RemoteReverify.minimumAgeToReverify
                      ) +
                      " or older"
                  ),
                ]),
                _vm._v(
                  " and can be physically with you to review your document(s). It is "
                ),
                _c("b", [
                  _vm._v("okay to choose a family member or relative."),
                ]),
                _vm._v(
                  " They must have their own smartphone with internet access and a web browser.\n    "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n      When they are with you, with their smartphone, and you have your documents(s) in hand, click below to begin.\n    "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$locale.t(
                        "remoteCountersign.reverify.perjuryStatement"
                      )
                    ) +
                    "\n    "
                ),
              ]),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("a", { attrs: { href: _vm.staffDashboardLinkPath } }, [
                _vm._v("\n      I'll do this later\n    "),
              ]),
              _vm._v(" "),
              _c("WbButton", {
                staticClass: "ml-1",
                attrs: { label: "Begin Now" },
                on: { click: _vm.handleStart },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showSubmitGeoLocation
        ? _c(
            "div",
            { ref: "shareLocation" },
            [
              _c(
                "wb-geolocation",
                {
                  ref: "geolocation",
                  attrs: { buttonText: "Share My Location" },
                  on: {
                    wbGeolocationSuccess: _vm.handleGeolocationSuccess,
                    wbGeolocationError: _vm.handleGeolocationError,
                  },
                },
                [
                  _c("h4", [_vm._v("Step 1.")]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\n        To complete this form, you will need to select a person to review your documents. This person must:\n        "
                    ),
                    _c("ul", [
                      _c("li", [
                        _vm._v(
                          "Be at least " +
                            _vm._s(
                              this.pageContext.i9RemoteReverify
                                .minimumAgeToReverify
                            ) +
                            " years of age"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _vm._v(
                          "Have access to a separate camera-enabled smartphone"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _vm._v(
                          "Be able to log in to an internet-connected device"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("li", [_vm._v("Be able to meet In person with you")]),
                      _vm._v(" "),
                      _c("li", [
                        _vm._v(
                          "Be willing to sign that they have reviewed your document(s) and that they appear genuine and to relate to you. "
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$locale.t(
                            "remoteCountersign.reverify.perjuryStatement"
                          )
                        ) +
                        "\n      "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "When you are ready, click the button to share your location."
                    ),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showSubmitAuthRepPhone
        ? _c("div", { ref: "chooseAuthRep" }, [
            _c("h4", [_vm._v("Step 2.")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.$locale.t(
                      "remoteCountersign.reverify.employee_choose_auth_rep"
                    )
                  ) +
                  "\n    "
              ),
            ]),
            _vm._v(" "),
            _c(
              "form",
              { on: { submit: _vm.handleSubmitForm } },
              [
                _c("form-field-input", {
                  attrs: {
                    placeholder: "555-555-5555",
                    required: true,
                    label: "Document Reviewer Cell Number",
                    errors: _vm.formErrors,
                    name: "auth_rep_phone",
                  },
                  model: {
                    value: _vm.formData.auth_rep_phone,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "auth_rep_phone", $$v)
                    },
                    expression: "formData.auth_rep_phone",
                  },
                }),
                _vm._v(" "),
                _c("wb-button", {
                  ref: "submitAuthRepPhoneButton",
                  staticClass: "mt-4",
                  attrs: {
                    label: "Send Text Message",
                    isLoading: _vm.isSubmitAuthRepPhoneButtonLoading,
                  },
                  on: { click: _vm.handleClickSubmitAuthRepPhone },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showScanQrCode
        ? [
            _vm.employeeScansQrCodeEnabled
              ? _c(
                  "ScanQrCode",
                  {
                    ref: "scanQrCode",
                    attrs: {
                      actor: "employee",
                      stepNumber: 3,
                      alertMessage: _vm.qrCodeAlertMessage,
                      qrCodeTarget: _vm.qrCodePayload,
                      remoteId: _vm.remoteReverifyId,
                      action: "reverify",
                    },
                  },
                  [
                    _c(
                      "div",
                      { attrs: { slot: "instructions" }, slot: "instructions" },
                      [
                        _c("p", [
                          _vm._v(
                            "\n          In order to verify you are in the same location as your reviewer, we must use another method.\n          To begin, please have your reviewer visit the link they were sent via text message.\n          "
                          ),
                          _c("em", [
                            _vm._v(
                              "\n            They may have already done this and are now being shown a QR code on their device.\n          "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            '\n          Next, click the "Begin QR Code Scan" button below.\n          The view from your camera should appear.\n          Now, scan the QR code on your reviewer\'s device.\n        '
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "text-em" }, [
                          _c("em", [
                            _vm._v(
                              "\n            If your camera view does not appear, don't worry!\n            Simply open your device's camera app or QR code scanner app, and scan the QR code.\n          "
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                )
              : _c(
                  "ShowQrCode",
                  {
                    ref: "showQrCode",
                    attrs: {
                      actor: "employee",
                      stepNumber: 3,
                      alertMessage: _vm.qrCodeAlertMessage,
                      qrCodePayload: _vm.qrCodePayload,
                    },
                  },
                  [
                    _c(
                      "div",
                      { attrs: { slot: "instructions" }, slot: "instructions" },
                      [
                        _c("p", [
                          _vm._v(
                            '\n          Have your reviewer select "Begin QR Code Scan" to open their camera. Allow them to scan the QR code that is displayed below. If they do not have a device with access to a camera, select choose a different person and start over at the bottom of this screen.\n        '
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.showWaitingOnAuthRep
        ? _c("div", { ref: "waitingAuthRep" }, [
            _c("p", [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.$locale.t(
                      "remoteCountersign.reverify.employee_waiting_on_auth_rep"
                    )
                  ) +
                  "\n    "
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showReset
        ? _c("div", [
            _c("hr"),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n      If your authorized representative has yet to receive their text, you may use your mobile device's text app to manually nudge them by clicking the button below.\n    "
              ),
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "btn btn-default",
                attrs: { href: _vm.smsAppTarget },
                on: { click: _vm.handleClickNudgeAuthRep },
              },
              [_vm._v("Send Text Message")]
            ),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c(
              "a",
              {
                ref: "authRepReset",
                attrs: { href: "#reset" },
                on: { click: _vm.handleClickReset },
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$locale.t("remoteCountersign.reverify.employee.reset")
                    ) +
                    "\n    "
                ),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showReverifyAccepted
        ? _c("div", { ref: "reverifyAccepted" }, [
            _c("p", [
              _vm._v(
                _vm._s(
                  _vm.$locale.t(
                    "remoteCountersign.reverify.reverify_accepted_employee"
                  )
                )
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.$locale.t("remoteCountersign.reverify.closeWindow")
                  ) +
                  " or "
              ),
              _c("a", { attrs: { href: _vm.rootPath } }, [
                _vm._v(
                  _vm._s(_vm.$locale.t("remoteCountersign.return_dashboard"))
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showReverifyRejected
        ? _c("div", { ref: "reverifyRejected" }, [
            _c("p", [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.$locale.t(
                      "remoteCountersign.reverify.reverify_rejected"
                    )
                  ) +
                  "\n    "
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.$locale.t("remoteCountersign.reverify.closeWindow")
                  ) +
                  " or "
              ),
              _c("a", { attrs: { href: _vm.staffDashboardLinkPath } }, [
                _vm._v(
                  _vm._s(_vm.$locale.t("remoteCountersign.return_dashboard"))
                ),
              ]),
            ]),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }