import NewEmployeeModalView from 'views/employees/new_employee_modal_view'
import Locale from 'lib/locale'
import Routes from 'lib/routes'
import Util from 'lib/util'
import EmployeeSeasonFormModalView from 'views/employees/employee_seasons/employee_season_form_modal_view'
import RehireWizardModalView from 'views/employees/rehire_wizard_modal_view'

export default Marionette.Controller.extend({

  /* Show the New Employee dialog */
  newEmployee: function(options) {
    options = $.extend({
      ajaxOptions: {
        wbGenericFailureMsg: "Sorry, we couldn't open the New Employee screen.",
      },
      _url: App.Models.Employee.prototype.urlRoot + '/new',
      _success: function() {
        // Bind callback for when modal is closed
        this.$el.on('hidden.bs.modal', function() {
          App.navigate("")
        })
      },
      _error: function() {
        App.navigate("")
      },

      // Stop the "New Employee" button ladda if present
      always: function(){
        const ladda = $('.new-resource-btn').data('ladda')
        if(ladda){ ladda.stop() }
      }.bind(this)
    }, options)

    new NewEmployeeModalView(options)
  },

  /* Show the Rehire Wizard dialog */
  rehireWizard: function(model) {
    new RehireWizardModalView({
      ajaxOptions: {
        wbGenericFailureMsg: "Sorry, we couldn't initiate the Rehire process.",
      },
      model: model,
      _url: App.Models.Employee.prototype.urlRoot + '/' + model.id + '/rehire', // -> /staff/STAFF_ID/rehire_wizard
      _success: function() {
        // Bind callback for when modal is closed
        this.$el.on('hidden.bs.modal', function() {
          App.navigate("")
        })
      },
      _error: function() {
        App.navigate("")
      },

      // Stop the "Rehire Employee" button ladda if present
      always: function(){
        const ladda = $('.rehire-wizard-btn').data('ladda')
        if(ladda){ ladda.stop() }
      }.bind(this)
    })
  },

  // BACKBONE ROUTE: GET /staff/:employee_id/employment/edit
  editActiveSeasonEmployment: function() {
    // If the user is not actively employed, there is nothing to edit
    if (gon.activeSeasonEmploymentModel && gon.activeSeasonEmploymentModel.id) {
      this.editEmployeeSeason(gon.activeSeasonEmploymentModel)
    } else {
      App.navigate("/employment")
    }
  },

  editEmployment: function(model) {
    new EmployeeSeasonFormModalView({
      ajaxOptions: {
        wbGenericFailureMsg: "Sorry, we couldn't open the Edit window.",
      },
      model: model,
      _url: App.Util.getPageRootPath() + '/seasons/' + model.id + '/edit',
      _success: function() {
        if (model.get('active_season')) {
          this.$el.on('hidden.bs.modal', function() {
            App.navigate("/employment")
          })
        }
      }
    })

  },

  deleteEmployee: function(model) {
    const successStr = Locale.t('employees.destroy.success', model.get('full_name'))

    $.ajax({
      url: Routes.Api.employee_path({ id: model.get('id') }),
      method: 'DELETE',
      wbGenericFailureMsg: Locale.t('employees.destroy.fail')
    }).done(() => {
      Util.navigateAndShowFlashNotice(Routes.employees_path, successStr)
    })

  },

  deleteSupplementalFile: function (model) {
    const childView = this.tableCollectionView.collection.get(model.id)
    this.tableCollectionView.removeChildView(childView)
  },
})
