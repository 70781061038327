var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.radioToggleOptions
    ? _c("RadioToggle", {
        ref: "toggle",
        attrs: {
          title: "Verification:",
          value: _vm.status,
          options: _vm.radioToggleOptions,
        },
        on: { selected: _vm.handleFilterChange },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }