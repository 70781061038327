<template>
  <RadioToggle
    class="d-inline-block"
    :value="radioValue"
    :options="toggleOptions"
    :disabled="isLoading"
    @selected="handleChange"
  />
</template>
<script>
  import RadioToggle from 'components/common/RadioToggle'
  import Util from 'lib/util'
  import { mapActions, mapGetters } from 'vuex'

  export const SETTING_OPTIONS = {
    countersign: {
      key: 'i9_remote_countersign_authorized',
      display: 'Verification'
    },
    reverification: {
      key: 'i9_remote_reverification_authorized',
      display: 'Reverification'
    }
  }
  export default {
    components: {
      RadioToggle
    },
    props: {
      employmentId: {
        type: String,
        required: true
      },
      setting: {
        type: String,
        required: true,
        validator: value => {
          return Object.keys(SETTING_OPTIONS).includes(value)
        }
      }
    },
    data () {
      return {
        isLoading: false,
        toggleOptions: [
          { label: 'On', value: '1' },
          { label: 'Off', value: '0' },
        ]
      }
    },
    computed: {
      ...mapGetters({
        employmentGetter: 'employments/get'
      }),
      employment () {
        return this.employmentGetter(this.employmentId)
      },
      radioValue () {
        return this.isToggleOn ? '1' : '0'
      },
      isToggleOn () {
        return this.employment.employee_document_options[this.settingKey]
      },
      settingKey () {
        return SETTING_OPTIONS[this.setting].key
      },
      settingDisplay () {
        return SETTING_OPTIONS[this.setting].display
      }
    },
    methods: {
      ...mapActions({
        updateEmployment: 'employments/update'
      }),
      handleChange (value) {
        this.saveUpdate(value)
      },
      async saveUpdate (value) {
        this.isLoading = true
        return this.updateEmployment({
          employment: this.employment,
          data: {
            employee_document_options: {
              [`${this.settingKey}`]: value
            }
          }
        }).then(() => {
          Util.showFlashNotice('Employment settings have been updated.')
        }).catch(xhr => {
          Util.ajaxErrorDialog(`An error accurred updating the Remote ${this.settingDisplay} preference.`, xhr)
        }).finally(() => {
          this.isLoading = false
        })
      }
    }
  }
</script>
