var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Panel", {
    staticClass: "unconfirmed-data-panel",
    attrs: { variant: "snapshot" },
    scopedSlots: _vm._u([
      {
        key: "heading",
        fn: function () {
          return [
            _c("div", { staticClass: "panel-title" }, [
              _c("h3", [_vm._v("Action Required")]),
              _vm._v(" "),
              _c("h4", [_vm._v("Unconfirmed Data")]),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _vm.emptyFields
              ? _c("div", [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.$locale.t(
                          "everify.status.unconfirmed_data.sync_error"
                        )
                      ) +
                      "\n    "
                  ),
                ])
              : _c(
                  "div",
                  [
                    _c(
                      "p",
                      { staticClass: "pb-4" },
                      [
                        _c(
                          "AlertMessage",
                          {
                            ref: "warningUpdate",
                            attrs: { variant: "warning" },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$locale.t(
                                    "everify.status.unconfirmed_data.warning_update"
                                  )
                                ) +
                                "\n        "
                            ),
                          ]
                        ),
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm.$locale.t(
                                "everify.status.unconfirmed_data.instructions"
                              )
                            ) +
                            "\n      "
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.fields, function (field, index) {
                      return _c("div", { key: index, staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-xs-12 col-sm-8" },
                          [
                            _c(_vm.fieldComponent(field.type), {
                              ref: field.name + "_field",
                              refInFor: true,
                              tag: "component",
                              attrs: {
                                name: field.name,
                                label: field.label + ":",
                                placeholder: field.placeholder,
                                mask: field.mask,
                                options: field.options,
                                errors: _vm.errors,
                                fieldWidth: "",
                              },
                              model: {
                                value: field.value,
                                callback: function ($$v) {
                                  _vm.$set(field, "value", $$v)
                                },
                                expression: "field.value",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    }),
                    _vm._v(" "),
                    _c("WbButton", {
                      ref: "confirmButton",
                      attrs: {
                        label: "Confirm and Send to E-Verify",
                        isLoading: _vm.isLoading,
                      },
                      on: { click: _vm.submit },
                    }),
                    _vm._v(" "),
                    _c("EverifyCaseCloseForm", {
                      attrs: { everifyCase: _vm.everifyCase },
                      on: { closeTimeout: _vm.closeModal },
                    }),
                  ],
                  2
                ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }