var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-sm-4 mb-2" }, [
      _c("div", { staticClass: "d-flex align-items-center" }, [
        _c("i", {
          ref: "status-icon",
          staticClass: "fa pr-3",
          class: _vm.isComplete
            ? "fa-check text-success"
            : "fa-clock-o text-muted",
        }),
        _vm._v(" "),
        _c("div", [
          _c("strong", [_vm._v(_vm._s(_vm.downloadRequest.name))]),
          _vm._v(" "),
          _c("div", { ref: "download-text" }, [
            _vm._v(_vm._s(_vm.completeText)),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-sm-4" }, [
      _vm.downloadRequest.description
        ? _c("p", [_vm._v(_vm._s(_vm.downloadRequest.description))])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { ref: "status", staticClass: "col-sm-4 text-right" }, [
      _c(
        "button",
        {
          ref: "download",
          staticClass: "btn btn-link",
          on: { click: _vm.handleDownloadClick },
        },
        [_vm._v("\n      View download details\n    ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }