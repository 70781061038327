var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      ref: "button",
      class: ["btn", _vm.buttonTypeClass],
      attrs: {
        disabled: _vm.disabled,
        type: "button",
        "data-spinner-color": _vm.dataSpinnerColor,
      },
      on: { click: _vm.handleClick },
    },
    [_c("span", { staticClass: "ladda-label" }, [_vm._t("default")], 2)]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }