<template>
  <div class="col-xs-12 col-md-10 col-lg-9 col-centered">
    <form class="form-horizontal simple_form w4-form fv-form fv-form-bootstrap">
      <FormAlerts :success="success" :errors="formErrors" :msgSuccess="successMessage" />

      <div class="panel gray-bg">
        <h3>Step 1: Enter Personal Information</h3>
        <div>
          <FormFieldInput
            orientation="horizontal"
            :required="true"
            label="First Name &amp; Middle Initial"
            :errors="formErrors"
            name="first_name_and_initial"
            type="text"
            v-model="formData.firstNameAndInitial"
          />

          <FormFieldInput
            orientation="horizontal"
            :required="true"
            label="Last Name"
            :errors="formErrors"
            name="last_name"
            type="text"
            v-model="formData.lastName"
          />

          <FormFieldCheckbox
            label="Check here if your name differs from that shown on your social security card and contact SSA at 800-772-1213 to ensure you get credit for your earnings."
            name="last_name_differs_from_ss_card"
            v-model="formData.nameDifferentOnSsCard"
            value="false"
          />

          <FormFieldInput
            orientation="horizontal"
            :required="true"
            label="Address"
            :errors="formErrors"
            name="street"
            type="text"
            v-model="formData.addressStreet"
          />

          <FormFieldInput
            orientation="horizontal"
            :required="false"
            label=" "
            :errors="formErrors"
            name="apt"
            type="text"
            v-model="formData.addressApartment"
          />

          <FormFieldInput
            orientation="horizontal"
            :required="true"
            label="City"
            :errors="formErrors"
            name="city"
            type="text"
            v-model="formData.addressCity"
          />

          <FormFieldSelect
            orientation="horizontal"
            :required="true"
            label="State"
            :errors="formErrors"
            name="state"
            :options="states"
            v-model="formData.addressState"
          />

          <FormFieldInput
            orientation="horizontal"
            :required="true"
            label="Zip Code"
            :errors="formErrors"
            name="zip"
            type="text"
            v-model="formData.addressZip"
          />

          <FormFieldSsn
            orientation="horizontal"
            :required="true"
            label="Social Security #"
            :errors="formErrors"
            name="ssn"
            type="text"
            v-model="formData.ssn"
          />

          <FormFieldRadio
            label="Filing Status"
            :required="true"
            :options="radioOptionsFilingStatus"
            v-model="formData.filingStatus"
            :errors="formErrors"
            name='filing_status'
            :hint="formData.filingStatusHint"
            orientation="horizontal"
          />
        </div>
      </div>

      <div class="panel gray-bg">
        <h3>Exempt Status:</h3>
        <p>
          <strong>
            Do not fill out this field unless you intend to claim exemption.
            If you claim exemption no taxes will be withheld
            and you will be responsible for any tax owed at the end of the year.
            If you do not wish to claim exemption please leave this field blank and continue to Step 2.
          </strong>
        </p>
        <p>
          I claim exemption from withholding for 2024,
          and I certify that I meet <strong>both</strong>
          of the following conditions for exemption.
        </p>

        <div class="pb-3">
          <ul>
            <li>
              Last year I had a right to refund of <strong>all</strong>
              federal tax withheld because I had <strong>no</strong>
              tax liability, <strong>and</strong>
            </li>
            <li>
              This year I expect a refund of <strong>all</strong>
              federal income tax withheld because I expect to have <strong>no</strong>
              tax liability
            </li>
          </ul>

          <FormFieldInput
            orientation="horizontal"
            :required="false"
            label="If you meet both conditions, write the word EXEMPT in all capital letters here:"
            :errors="formErrors"
            name="exempt_status"
            type="text"
            v-model="exemptStatus"
          />

          <div class="alert alert-info" v-if="isExempt" ref="skipToStepFiveAlert">
            <strong>Please skip to Step 5.</strong>
          </div>
        </div>
      </div>

      <div class="panel gray-bg" v-if="!isExempt" ref="stepTwo">
        <h3>Step 2: Multiple Jobs or Spouse Works</h3>
        <div>
          <FormFieldRadio
            :label="numberOfJobsLabel"
            :required="false"
            :options="radioOptionsNumberOfJobs"
            v-model="formData.numberOfJobs"
            :errors="formErrors"
            name='number_of_jobs'
            orientation="horizontal"
            ref="numberOfJobsInput"
          />

          <div class="alert alert-info" v-if="jobCount === 1">
            Please continue to Step 3.
          </div>

          <FormFieldRadio
            v-if="jobCount === 2"
            label="Is the pay similar for both jobs?"
            :required="false"
            :options="radioOptionsYesNo"
            v-model="formData.twoJobsSimilarPay"
            :errors="formErrors"
            name='two_jobs_similar_pay'
            orientation="horizontal"
          />

          <MultipleJobsWorksheet
            v-if="twoJobsSimilarPayValue === 0 || jobCount === 3"
            :jobs="jobCount"
            :status="filingStatus"
            :errors="formErrors"
            name='multiple_jobs_worksheet'
            @updateFromMjw="handleMjwChange"
            ref="multipleJobsWorksheet"
          />

          <div
            class="alert alert-info"
            v-if="twoJobsSimilarPayValue === 1"
            ref="continueToStep3Message"
          >
            Please continue to Step 3.
          </div>

          <div
            class="alert alert-info"
            v-if="jobCount === 4"
            ref="irsWithholdingEstimatorMessage"
          >
            Please use the
            <a :href="irsTaxWithholdingEstimatorUrl" target="_blank">
              IRS Tax Withholding Estimator
            </a>
            to calculate your withholding. Once finished enter the results from
            the IRS Tax Withholding Estimator into the corresponding steps here.
          </div>
        </div>
      </div>

      <div class="panel gray-bg" v-if="!isExempt" ref="stepThree">
        <h3>Step 3: Claim Dependents</h3>
        <div>
          <p>
            If your income will be $200,000 or less ($400,000 or less if married filing jointly):
          </p>

          <div class="form-group">
            <FormFieldNumber
              ref="qualifyingChildrenInput"
              orientation="horizontal"
              :required="false"
              label="How many qualifying children under age 17 do you have?"
              :errors="formErrors"
              name="qualifying_children"
              min="0"
              v-model="formData.qualifyingChildren"
            />

            <FormFieldNumber
              ref="otherDependentsInput"
              orientation="horizontal"
              :required="false"
              label="How many other dependents do you have?"
              :errors="formErrors"
              name="other_dependents"
              min="0"
              v-model="formData.otherDependents"
            />

            <FormFieldCurrency
              ref="otherTaxCreditsInput"
              orientation="horizontal"
              :required="false"
              label="Other tax credits for dependents"
              :errors="formErrors"
              name="other_tax_credits"
              min="0"
              v-model="formData.otherTaxCredits"
            />

            <FormFieldCurrency
              orientation="horizontal"
              :required="false"
              :errors="formErrors"
              label="Total withholding for dependents"
              name="total_dependents_withholding"
              type="text"
              :value="totalDependentsWithholding"
              disabled="true"
              hint="This field is calculated using the numbers of dependents entered above and cannot be edited."
            />
          </div>
        </div>
      </div>

      <div class="panel gray-bg" v-if="!isExempt" ref="stepFour">
        <h3>Step 4 (optional): Other Adjustments</h3>
        <div>
          <FormFieldCurrency
            orientation="horizontal"
            :required="false"
            label="(a) Other income"
            :errors="formErrors"
            name="other_income"
            v-model="formData.otherIncome"
            min="0"
            helpPopoverText="If you want tax withheld for other income you expect this year that won't
                            have withholding, enter the amount of other income here. This may include interest,
                            dividends, and retirement income. You should not include income from any jobs."
          />
          <hr />
          <div
            class="alert alert-info"
            v-if="!showDeductionsCalculator"
            ref="deductionsCalculatorInfo"
          >
            If you expect to claim deductions other than the standard deduction
            and want to reduce your withholding, please use the Deductions worksheet.
            <div class="mt-2">
              <WbButton
                class="btn-sm"
                @click="toggleDeductionsCalculator"
                v-if="!showDeductionsCalculator"
              >
                Show Deductions Worksheet
              </WbButton>
            </div>
          </div>

          <div
            class="well pb-2 clearfix"
            v-if="showDeductionsCalculator"
            ref="deductionsCalculator"
          >
            <h4>Deductions Worksheet</h4>
            <p>
              If you expect to claim deductions other than the standard deduction
              and want to reduce your withholding, please use the Deductions worksheet below.
            </p>

            <FormFieldCurrency
              orientation="horizontal"
              :required="false"
              label="Estimate of your 2024 itemized deductions"
              :errors="formErrors"
              name="deductions_calculator_itemized"
              min="0"
              v-model="formData.deductionsCalculatorItemized"
              hint="From Schedule A (Form 1040 or 1040-SR). Such deductions may include qualifying home mortgage interest, charitable contributions, state and local taxes (up to $10,000), and medical expenses in excess of 7.5% of your income."
            />

            <FormFieldCurrency
              orientation="horizontal"
              :required="false"
              label="Estimate of student loan interest, deductible IRA contributions, other"
              :errors="formErrors"
              name="deductions_calculator_other"
              min="0"
              v-model="formData.deductionsCalculatorOther"
              hint="Enter an estimate of your student loan interest, deductible IRA contributions, and certain other adjustments from Schedule 1 (Form 1040 or 1040-SR). See Pub. 505 for more information"
            />
          </div>

          <FormFieldCurrency
            orientation="horizontal"
            :required="false"
            label="(b) Deductions"
            :errors="formErrors"
            name="deductions"
            type="number"
            min="0"
            v-model="formData.deductions"
            helpPopoverText="If you expect to claim deductions other than the standard deduction
                            and want to reduce your withholding, use the Deductions Calculator below."
            hint="This field may have been calculated using the Deductions Calculator but can be adjusted."
          />

          <FormFieldCurrency
            orientation="horizontal"
            :required="false"
            label="(c) Extra withholding"
            :errors="formErrors"
            name="extra_withholding"
            min="0"
            v-model="formData.extraWithholding"
            helpPopoverText="If you used the Multiple Jobs Worksheet in Step 2, the result is automatically entered here.
                            Otherwise, enter any additional tax you want withheld each pay period."
            hint="This field is calculated using the Multiple Jobs Worksheet in Step 2, please adjust as necessary."
          />
        </div>
      </div>

      <div class="panel gray-bg">
        <h3>Step 5:</h3>
        <SignaturePad v-model="formData.signature" :errors="formErrors" />
      </div>

      <FormAlerts
        :success="success"
        :errors="formErrors"
        :msgSuccess="successMessage"
      />
      <div class="text-right">
        <WbButton
          ref="submitButton"
          @click="handleSubmit"
          :isLoading="isSubmitting"
        >
          Submit
        </WbButton>
      </div>
    </form>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Util from 'lib/util'
  import Locale from 'lib/locale'
  import FormAlerts from 'components/common/FormAlerts'
  import FormFieldInput from 'components/common/FormFieldInput'
  import FormFieldCheckbox from 'components/common/FormFieldCheckbox'
  import FormFieldSelect from 'components/common/FormFieldSelect'
  import FormFieldRadio from 'components/common/FormFieldRadio'
  import FormFieldNumber from 'components/common/FormFieldNumber'
  import FormFieldCurrency from 'components/common/FormFieldCurrency'
  import FormFieldSsn from 'components/common/FormFieldSsn'
  import SignaturePad from 'components/common/SignaturePad'
  import MultipleJobsWorksheet from 'components/document_submissions/built_in/MultipleJobsWorksheet'
  import WbButton from 'components/common/WbButton'
  import { ajax } from 'jquery'
  import SystemEvents from 'lib/system_events'
  import EmployeeW4SubmittedEvent from 'models/system_events/employee_w4_submitted_event'
  import { getRoute } from 'lib/routes'

  // These are the limits as specified on page 3 in step 4 (deductions worksheet) of the W4
  export const DEDUCTION_LIMITS = {
    single_or_married_filing_separately: 14600,
    married_filing_jointly: 29200,
    head_of_household: 21900,
  }

  export default {
    name: 'the-w4-root',

    components: {
      FormFieldInput,
      FormFieldCheckbox,
      FormFieldSelect,
      FormFieldRadio,
      FormFieldNumber,
      FormFieldCurrency,
      FormFieldSsn,
      SignaturePad,
      MultipleJobsWorksheet,
      FormAlerts,
      WbButton,
    },

    data() {
      return {
        success: false,
        isSubmitting: false,
        formData: {
          firstNameAndInitial: '',
          lastName: '',
          nameDifferentOnSsCard: false,
          addressStreet: '',
          addressApartment: '',
          addressCity: '',
          addressState: '',
          addressZip: '',
          ssn: '',
          filingStatus: '',
          filingStatusHint: Locale.t('documents.w4.hints.filing_status'),
          numberOfJobs: null,
          twoJobsSimilarPay: null,
          qualifyingChildren: '',
          otherDependents: '',
          otherTaxCredits: '',
          otherIncome: '',
          deductions: '',
          deductionsCalculatorItemized: '',
          deductionsCalculatorOther: '',
          mjwLine1: '',
          mjwLine2a: '',
          mjwLine2b: '',
          mjwLine2c: '',
          job1Income: '',
          job2Income: '',
          job3Income: '',
          payFrequency: '',
          extraWithholding: '',
          exemptStatus: '',
          signature: {
            signature: '',
            signature_name: '',
          },
        },
        formErrors: {},
        radioOptionsFilingStatus: [
          { label: "Single or Married filing separately", value: 'single_or_married_filing_separately' },
          { label: "Married filing jointly or qualifying widow(er)", value: 'married_filing_jointly' },
          { label: "Head of household", value: 'head_of_household' },
        ],
        radioOptionsNumberOfJobs: [
          { label: "One", value: '1' },
          { label: "Two", value: '2' },
          { label: "Three", value: '3' },
          { label: "More than three", value: '4' },
        ],
        radioOptionsYesNo: [
          { label: "Yes", value: '1' },
          { label: "No", value: '0' },
        ],
        showDeductionsCalculator: false,
      }
    },

    computed: {
      deductions () {
        return this.isExempt ?
          null : 
          this.calculateDeductions(
            this.formData.filingStatus,
            parseFloat(this.formData.deductionsCalculatorItemized),
            parseFloat(this.formData.deductionsCalculatorOther) || 0,
          )
      },

      employee() {
        return this.pageContext.employee
      },

      exemptStatus: {
        get () {
          return String(this.formData.exemptStatus).toUpperCase()
        },
        set (val) {
          this.formData.exemptStatus = val
        },
      },

      filingStatus() {
        return this.formData.filingStatus
      },

      states() {
        const states = this.pageContext.us_states
        const stateNames = []

        for (let index=0; index < this.pageContext.us_states.length; index++) {
          stateNames.push({
            value: states[index][1],
            label: states[index][0],
          })
        }

        return stateNames
      },

      isExempt () {
        return this.checkExemptStatus(this.exemptStatus)
      },

      irsTaxWithholdingEstimatorUrl () {
        return getRoute('w4_irs_tax_withholding_estimator_url')
      },

      jobCount() {
        return Number(this.formData.numberOfJobs) || 0
      },

      numberOfJobsLabel () {
        return this.formData.filingStatus === 'married_filing_jointly' ?
          'How many jobs do you and your spouse have?' :
          'How many jobs do you have?'
      },

      twoJobsSimilarPayValue () {
        return this.getTwoJobsSimilarPayValue(this.jobCount, this.formData.twoJobsSimilarPay)
      },

      successMessage () {
        return Locale.t('document_submissions.create.success', 'W4 (2024)')
      },

      totalDependentsWithholding() {
        return this.isExempt ?
          null : 
          this.getTotalDependentsWithholding(
            this.formData.qualifyingChildren,
            this.formData.otherDependents,
            this.formData.otherTaxCredits,
          )
      },

      ...mapGetters({
        pageContext: 'pageContext',
      }),
    },

    watch: {
      deductions () {
        // Any Updates to formData values that affect the computed should update the formData value
        // However, the user may manually enter a different value - it will just get overwritten if they change one the above values
        this.formData.deductions = this.deductions
      },
      jobCount () {
        this.clearExtraWithholding()
      },
    },

    created() {
      const middleInitial = this.employee.middle_name && this.employee.middle_name.charAt(0)
      this.formData.firstNameAndInitial = `${this.employee.first_name}${middleInitial ? (` ${  middleInitial}`) : ''}`
      this.formData.lastName = this.employee.last_name
      this.formData.addressStreet = this.employee.address.street
      this.formData.addressApartment = this.employee.address.apt
      this.formData.addressCity = this.employee.address.city
      this.formData.addressState = this.employee.address.state
      this.formData.addressZip = this.employee.address.zip
      this.formData.ssn = this.employee.ssn
      this.formData.signature.signature_name = `${this.employee.first_name} ${this.employee.last_name}`
    },

    methods: {
      handleSubmit() {
        this.success = false
        this.formErrors = {}
        this.isSubmitting = true

        const w4FormData = {
          w4_submission: {
            first_name_and_initial: this.formData.firstNameAndInitial,
            last_name: this.formData.lastName,
            last_name_differs_from_ss_card: this.formData.nameDifferentOnSsCard,
            address: {
              street: this.formData.addressStreet,
              apt: this.formData.addressApartment,
              city: this.formData.addressCity,
              state: this.formData.addressState,
              zip: this.formData.addressZip,
            },
            ssn: this.formData.ssn,
            filing_status: this.formData.filingStatus,
            number_of_jobs: this.formData.numberOfJobs,
            two_jobs_similar_pay: this.formData.twoJobsSimilarPay,
            qualifying_children: this.formData.qualifyingChildren,
            other_dependents: this.formData.otherDependents,
            total_dependents_withholding: this.totalDependentsWithholding,
            other_tax_credits: this.formData.otherTaxCredits,
            other_income: this.formData.otherIncome,
            deductions: this.formData.deductions,
            deductions_calculator_itemized: this.formData.deductionsCalculatorItemized,
            deductions_calculator_other: this.formData.deductionsCalculatorOther,
            extra_withholding: this.formData.extraWithholding,
            exempt_status: this.exemptStatus,
            multiple_jobs_worksheet: {
              mjw_line_1: this.formData.mjwLine1,
              mjw_line_2a: this.formData.mjwLine2a,
              mjw_line_2b: this.formData.mjwLine2b,
              mjw_line_2c: this.formData.mjwLine2c,
              pay_frequency: this.formData.payFrequency,
              job_1_income: this.formData.job1Income,
              job_2_income: this.formData.job2Income,
              job_3_income: this.formData.job3Income,
            },
            signature_data_uri: this.formData.signature.signature,
          },
        }

        ajax({
          url: getRoute('staff_w4_submission_path'),
          method: 'POST',
          data: w4FormData,
          wbGenericFailureMsg: Locale.t("document_submissions.create.fail"),
          progressBar: false,
        }).done(({ redirect_location }) => {
          this.success = true

          Util.navigateAndCall(redirect_location, function() {
            const systemEvent = new EmployeeW4SubmittedEvent()

            Util.showFlashNotice(this.successMessage)
            SystemEvents.notify(systemEvent.payload())
          }.bind(this))

        }).fail(xhr => {
          if (xhr && xhr.responseJSON) {
            this.formErrors = xhr.responseJSON.errors
            if (this.formErrors.address) { this.addAddressErrors() }
          }
        }).always(() => {
          this.isSubmitting = false
        })
      },

      addAddressErrors() {
        const addressComponents = {
          addressStreet: 'street',
          addressCity: 'city',
          addressState: 'state',
          addressZip: 'zip',
        }

        const addressKeys = Object.keys(addressComponents)

        for (let index = 0; index < addressKeys.length; index++) {
          if (this.formData[addressKeys[index]].length == 0) {
            this.formErrors[addressComponents[addressKeys[index]]] = ["This field is required."]
          }
        }
      },

      clearExtraWithholding() {
        const mjwValues = this.formData.mjwLine1 != '' || this.formData.mjwLine2a != ''

        // if jobs 1 or 4 & there is a MJW value, clear extra_wihholding and all mjw values
        if ((this.jobCount === 1 || this.jobCount === 4) && mjwValues == true) {
          this.formData.extraWithholding = ''
          this.formData.mjwLine1 = ''
          this.formData.mjwLine2a = ''
          this.formData.mjwLine2b = ''
          this.formData.mjwLine2c = ''
          this.formData.payFrequency = ''
        }
      },

      getTwoJobsSimilarPayValue(jobCount, twoJobsSimilarPay) {
        if (jobCount === 2) {
          if (twoJobsSimilarPay === '1') {
            return 1
          }
          if (twoJobsSimilarPay === '0') {
            return 0
          }
        }
        return null
      },

      getTotalDependentsWithholding(qualifyingChildren, otherDependents, otherTaxCredits) {
        const childrenCreditsMult = qualifyingChildren * 2000
        const otherDependentsCreditsMult = otherDependents * 500
        otherTaxCredits = Number(otherTaxCredits) || 0

        return childrenCreditsMult + otherDependentsCreditsMult + otherTaxCredits
      },

      calculateDeductions(filingStatus, itemized, other = 0) {
        if (!filingStatus) { return }

        const statusDeduction = DEDUCTION_LIMITS[filingStatus] || 0
        let lineThree = 0

        if (itemized >= statusDeduction) {
          lineThree = itemized - statusDeduction
        }

        return lineThree + other
      },

      getMJWLine2c(mjw2a, mjw2b) {
        return mjw2a + mjw2b
      },

      checkExemptStatus (status) {
        return String(status).toUpperCase() === 'EXEMPT'
      },

      handleMjwChange(ew, mjw1, mjw2a, mjw2b, pay, job1, job2, job3) {
        this.formData.extraWithholding = ew
        this.formData.mjwLine1 = mjw1
        this.formData.mjwLine2a = mjw2a
        this.formData.mjwLine2b = mjw2b
        this.formData.mjwLine2c = this.getMJWLine2c(mjw2a, mjw2b)
        this.formData.payFrequency = pay
        this.formData.job1Income = job1
        this.formData.job2Income = job2
        this.formData.job3Income = job3
      },

      toggleDeductionsCalculator () {
        this.showDeductionsCalculator = !this.showDeductionsCalculator
      },
    },
  }
</script>
