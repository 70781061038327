var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.hasAssignments
        ? [
            _c(
              "table",
              { ref: "assignments", staticClass: "table table-striped" },
              [
                _c("thead", [
                  _c("tr", [
                    _c("th", [
                      _vm.hasSelectableAssignments
                        ? _c(
                            "div",
                            {
                              ref: "assignmentMultiSelect",
                              staticClass: "row-selectors btn-group",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "btn btn-default btn-xs dropdown-toggle",
                                  attrs: {
                                    "data-toggle": "dropdown",
                                    "aria-expanded": "false",
                                  },
                                },
                                [
                                  _c("WbIcon", {
                                    staticClass: "right",
                                    attrs: { type: "chevron-down" },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "ul",
                                {
                                  staticClass: "dropdown-menu",
                                  attrs: { role: "menu" },
                                },
                                [
                                  _c("li", { staticClass: "dropdown-header" }, [
                                    _vm._v("Select:"),
                                  ]),
                                  _vm._v(" "),
                                  _c("li", [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: "javascript:void(0)",
                                          "data-value": "all",
                                        },
                                        on: { click: _vm.handleSelection },
                                      },
                                      [_vm._v("All")]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("li", [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: "javascript:void(0)",
                                          "data-value": "none",
                                        },
                                        on: { click: _vm.handleSelection },
                                      },
                                      [_vm._v("None")]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("li", [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: "javascript:void(0)",
                                          "data-value": "ready-for-section-2",
                                        },
                                        on: { click: _vm.handleSelection },
                                      },
                                      [_vm._v("Ready for Section 2")]
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        staticClass: "sortable",
                        class: _vm.getSortClassName("name"),
                        attrs: { role: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.handleSortAction("name")
                          },
                        },
                      },
                      [_vm._v("\n            Name\n          ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        staticClass: "sortable",
                        class: _vm.getSortClassName("received"),
                        attrs: { role: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.handleSortAction("received")
                          },
                        },
                      },
                      [_vm._v("\n            Received\n          ")]
                    ),
                    _vm._v(" "),
                    _c("th", [_vm._v("Link")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("\n            Status\n          ")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Expiration Date")]),
                    _vm._v(" "),
                    _vm.shouldShowVerificationStatus
                      ? _c("th", { ref: "verificationStatus" }, [
                          _vm._v(
                            "\n            Verification Status\n          "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._m(0),
                    _vm._v(" "),
                    _vm.hasRestartableAssignments
                      ? _c("th", { ref: "additionalActions" }, [
                          _c("span", { staticClass: "sr-only" }, [
                            _vm._v("Additional Actions"),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.assignments, function (assignment) {
                    return _c("I9InboxAssignmentItem", {
                      key: assignment.id,
                      attrs: {
                        assignment: assignment,
                        shouldShowAdditionalActions:
                          _vm.hasRestartableAssignments,
                        shouldShowVerificationStatus:
                          _vm.shouldShowVerificationStatus,
                      },
                    })
                  }),
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c("SubmissionAuditLogContainer"),
          ]
        : _c("div", { ref: "noAssignments", staticClass: "alert alert-info" }, [
            _vm._v("\n    No I-9 Forms\n  "),
          ]),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", [
      _c("span", { staticClass: "sr-only" }, [_vm._v("Audit Log")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }