var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group form-group-vertical clearfix" }, [
    _c("hr"),
    _vm._v(" "),
    _c("div", { staticClass: "d-flex align-items-center" }, [
      _c("h3", { ref: "header", staticClass: "mr-3" }, [
        _vm._v("\n      Preparer/Translator\n      "),
        _vm.shouldShowIndex
          ? _c("span", { ref: "headerIndex" }, [
              _vm._v("\n        " + _vm._s(_vm.humanizedIndex) + "\n      "),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.shouldShowRemoveLink
        ? _c(
            "button",
            {
              ref: "preparer-remove-link",
              staticClass: "btn btn-link btn-decoration-none",
              on: { click: _vm.handleRemovePreparer },
            },
            [
              _c(
                "span",
                { staticClass: "text-danger" },
                [
                  _c("WbIcon", { attrs: { type: "trash" } }),
                  _vm._v("\n        Remove\n      "),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("p", [
      _vm._v(_vm._s(_vm.$locale.t("staff.i9.preparerTranslator.description"))),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "container-fluid" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-3" },
          [
            _c("FormFieldInput", {
              ref: "first_name",
              staticClass: "pr-2",
              attrs: {
                label: "First Name",
                required: true,
                errors: _vm.displayedErrors,
                name: "first_name",
                type: "text",
              },
              on: { input: _vm.handleFormUpdate },
              model: {
                value: _vm.localData.first_name,
                callback: function ($$v) {
                  _vm.$set(_vm.localData, "first_name", $$v)
                },
                expression: "localData.first_name",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-2" },
          [
            _c("FormFieldInput", {
              ref: "middle_initial",
              staticClass: "pr-2",
              attrs: {
                label: "Middle Initial",
                required: false,
                errors: _vm.displayedErrors,
                name: "middle_initial",
                type: "text",
              },
              model: {
                value: _vm.localData.middle_initial,
                callback: function ($$v) {
                  _vm.$set(_vm.localData, "middle_initial", $$v)
                },
                expression: "localData.middle_initial",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-4" },
          [
            _c("FormFieldInput", {
              ref: "last_name",
              staticClass: "w-30",
              attrs: {
                label: "Last Name",
                required: true,
                errors: _vm.displayedErrors,
                name: "last_name",
                type: "text",
              },
              model: {
                value: _vm.localData.last_name,
                callback: function ($$v) {
                  _vm.$set(_vm.localData, "last_name", $$v)
                },
                expression: "localData.last_name",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-7" },
          [
            _c("FormFieldInput", {
              ref: "address",
              attrs: {
                label: "Address",
                required: true,
                errors: _vm.displayedErrors,
                name: "address",
                type: "text",
              },
              model: {
                value: _vm.localData.address,
                callback: function ($$v) {
                  _vm.$set(_vm.localData, "address", $$v)
                },
                expression: "localData.address",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-3" },
          [
            _c("FormFieldInput", {
              ref: "city",
              staticClass: "pr-2",
              attrs: {
                label: "City or Town",
                required: true,
                errors: _vm.displayedErrors,
                name: "city",
                type: "text",
              },
              model: {
                value: _vm.localData.city,
                callback: function ($$v) {
                  _vm.$set(_vm.localData, "city", $$v)
                },
                expression: "localData.city",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-2" },
          [
            _c("FormFieldSelect", {
              ref: "state",
              staticClass: "pr-2",
              attrs: {
                label: "State",
                required: true,
                errors: _vm.displayedErrors,
                options: _vm.usStates,
                fieldWidth: "",
                name: "state",
              },
              model: {
                value: _vm.localData.state,
                callback: function ($$v) {
                  _vm.$set(_vm.localData, "state", $$v)
                },
                expression: "localData.state",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-2" },
          [
            _c("FormFieldInput", {
              ref: "zipCode",
              attrs: {
                label: "Zip Code",
                required: true,
                errors: _vm.displayedErrors,
                name: "zip",
                type: "text",
              },
              model: {
                value: _vm.localData.zip,
                callback: function ($$v) {
                  _vm.$set(_vm.localData, "zip", $$v)
                },
                expression: "localData.zip",
              },
            }),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "callout-legal" }, [
      _c("p", [_vm._v(_vm._s(_vm.perjuryStatement))]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { name: "signature" } },
      [
        _c("SignaturePad", {
          ref: "signaturePad",
          attrs: {
            value: Object.assign({}, _vm.localData.signature, {
              signature_name: this.fullName,
            }),
            nameField: _vm.preparer.signature_name_field,
            errors: _vm.displayedErrors,
          },
          on: { input: _vm.handleSignatureUpdate },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }