var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pb-4" }, [
    _c("h3", [_vm._v("Referred")]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c("p", [
      _vm._v(
        "\n    You must initiate contact with DHS or SSA by the date indicated in your "
      ),
      _c("a", { attrs: { href: _vm.url, target: "_self", download: "" } }, [
        _vm._v("Referral Date Confirmation (RDC) Notice"),
      ]),
      _vm._v(".\n  "),
    ]),
    _vm._v(" "),
    _c("p", [
      _vm._v(
        "\n    Below you will find the Further Action Notice (FAN) that outlines the data discrepancy.\n  "
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("\n    Your status with E-Verify is now "),
      _c("em", [_vm._v("Referred")]),
      _vm._v(
        ", meaning you have chosen to work with DHS or SSA to resolve any mismatches between the data on your Form I-9 and the data fields found at E-Verify. \n  "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }