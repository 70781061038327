var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.accepted
    ? _c("blockquote", { ref: "rejectionReasonBlockquote" }, [
        !_vm.rejectionReason && !this.rejectionReasons.length
          ? _c("i", { ref: "noReasonGiven", staticClass: "text-muted" }, [
              _vm._v("No reason\n    given."),
            ])
          : _c("span", { ref: "rejectionReasonsDetails" }, [
              _vm._v("\n    " + _vm._s(_vm.rejectionReasonsDetails) + "\n  "),
            ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }