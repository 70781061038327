var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.managedPolicy
      ? _c("p", { staticClass: "breathe" }, [
          _c("i", { staticClass: "fa fa-info text-info fa-fw" }),
          _vm._v(
            "\n    Groups selected: " +
              _vm._s(_vm.groupNames.join(", ")) +
              "\n  "
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("h4", [_vm._v(_vm._s(_vm.stepHeader))]),
    _vm._v(" "),
    _c("form", { staticClass: "form-horizontal" }, [
      _c(
        "div",
        { staticClass: "form-group" },
        _vm._l(_vm.templatedPermissions.templates, function (template, index) {
          return _c("div", { key: index, staticClass: "col-xs-12" }, [
            _c("div", { staticClass: "radio" }, [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectedTemplate,
                      expression: "selectedTemplate",
                    },
                  ],
                  attrs: { type: "radio" },
                  domProps: {
                    value: template.name,
                    checked: _vm._q(_vm.selectedTemplate, template.name),
                  },
                  on: {
                    change: function ($event) {
                      _vm.selectedTemplate = template.name
                    },
                  },
                }),
                _vm._v(
                  "\n            " +
                    _vm._s(template.description) +
                    "\n          "
                ),
              ]),
            ]),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }