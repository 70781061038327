<template>
  <div class="row">
    <div class="col-lg-10">
      <WbPanel title="SCIM Provisioning Settings" :formResponse="formResponse">
        <p>
          Once configured, this optional feature automatically creates employees in WorkBright that exist in your IdP.
          Additionally, this feature automatically revokes access to WorkBright users that are removed from your IdP.
        </p>
        <form class="form-horizontal pt-4">
          <FormField label="User Provisioning" orientation="horizontal">
            <Toggle :value="isActive" @toggleChanged="statusChanged" />
          </FormField>
          <div v-if="isActive" ref="settings">
            <FormFieldReadOnlyText
              label="SCIM url"
              :value="scimPath"
            />
            <FormFieldReadOnlyText
              label="Bearer Token"
              :value="currentToken"
            />
            <FormField label=" " orientation="horizontal">
              <a
                class="btn btn-default"
                href="#"
                @click="activate"
                ref="activateButton"
              >
                Generate New Token
              </a>
            </FormField>
          </div>
        </form>
        <br />
        <i>
          Activate SCIM provisioning and then copy the settings into your Identity Provider application.
        </i>
      </WbPanel>
    </div>
  </div>
</template>

<script>
  import WbPanel from 'components/common/WbPanel'
  import FormFieldReadOnlyText from 'components/common/FormFieldReadOnlyText'
  import FormField from 'components/common/FormField'
  import Toggle from 'components/common/Toggle'
  import Routes from 'lib/routes'
  import Locale from 'lib/locale'
  import Util from 'lib/util'
  import { ajax } from 'jquery'

  export default {
    name: 'sso-scim-provisioning-container',

    components: {
      WbPanel,
      FormFieldReadOnlyText,
      FormField,
      Toggle,
    },

    props: {
      scimPath: {
        type: String,
        required: true,
      },

      scimToken: {
        type: String,
        required: false,
      },
    },

    data() {
      return {
        currentToken: this.scimToken,
        formResponse: {
          success: false,
          errors: {},
          msgSuccess: '',
        },
      }
    },

    computed: {
      isActive() {
        return !!this.currentToken
      },
    },

    methods: {
      // Activate SCIM by generating a bearer token.
      // This method is also used to regenerate (replace) a new token.
      activate(e) {
        if (e) { e.preventDefault() } // prevent auto-scrolling to top of page
        this.formResponse.success = false
        this.formResponse.errors = {}

        const action = this.isActive ? 'regenerate_token' : 'activate'

        ajax({
          method: 'POST',
          url: Routes.create_scim_bearer_token_path,
          wbGenericFailureMsg: Locale.t(`sso_config.scim_settings.${action}.fail`),
        }).success( (json) => {
          this.currentToken = json.scim_token
          this.formResponse.msgSuccess = Locale.t(`sso_config.scim_settings.${action}.success`)
          this.formResponse.success = true
        }).error( (xhr) => {
          Util.genericAjaxError("An error occurred while activating SCIM provisioning.", xhr)
        })
      },

      // Deactivate SCIM by deleting bearer token.
      deactivate() {
        ajax({
          method: 'DELETE',
          url: Routes.delete_scim_bearer_token_path,
          wbGenericFailureMsg: Locale.t('sso_config.scim_settings.deactivate.fail'),
        }).success(() => {
          this.currentToken = null
          this.formResponse.msgSuccess = Locale.t(`sso_config.scim_settings.deactivate.success`)
          this.formResponse.success = true
        }).error( (xhr) => {
          Util.genericAjaxError("An error occurred while deleting the SCIM bearer token.", xhr)
        })
      },

      statusChanged() {
        if (this.isActive) {
          this.deactivate()
        } else {
          this.activate()
        }
      },
    },
  }
</script>
