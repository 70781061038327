var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeader", { attrs: { title: _vm.pageTitle } }),
      _vm._v(" "),
      _vm.formMode
        ? _c(
            "WbCard",
            { ref: "form", staticClass: "form-horizontal" },
            [
              _c(
                "div",
                { staticClass: "form-group required" },
                [
                  _c("FormLabel", {
                    attrs: {
                      label: "Admin Name",
                      required: true,
                      orientation: "horizontal",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-7" }, [
                    _c(
                      "div",
                      { staticClass: "input-sequence" },
                      [
                        _c("FormFieldInput", {
                          ref: "firstName",
                          attrs: {
                            placeholder: "First",
                            name: "first_name",
                            errors: _vm.errors,
                          },
                          model: {
                            value: _vm.formData.first_name,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "first_name", $$v)
                            },
                            expression: "formData.first_name",
                          },
                        }),
                        _vm._v(" "),
                        _c("FormFieldInput", {
                          ref: "middleName",
                          attrs: { placeholder: "Middle" },
                          model: {
                            value: _vm.formData.middle_name,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "middle_name", $$v)
                            },
                            expression: "formData.middle_name",
                          },
                        }),
                        _vm._v(" "),
                        _c("FormFieldInput", {
                          ref: "lastName",
                          attrs: {
                            placeholder: "Last",
                            name: "last_name",
                            errors: _vm.errors,
                          },
                          model: {
                            value: _vm.formData.last_name,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "last_name", $$v)
                            },
                            expression: "formData.last_name",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("FormFieldInput", {
                ref: "email",
                attrs: {
                  placeholder: "Email",
                  label: "Admin Email",
                  name: "email",
                  errors: _vm.errors,
                  orientation: "horizontal",
                  required: "",
                },
                model: {
                  value: _vm.formData.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "email", $$v)
                  },
                  expression: "formData.email",
                },
              }),
              _vm._v(" "),
              _vm.permissionsFeature
                ? _c("AdminUserPolicyFields", {
                    attrs: {
                      accessPolicies: _vm.accessPolicies,
                      groups: _vm.groups,
                      accessPolicy: _vm.formData.accessPolicy,
                      scope: _vm.formData.scope,
                      errors: _vm.errors,
                    },
                    on: {
                      accessPolicySelected: _vm.handleAccessPolicySelected,
                      scopeSelected: _vm.handleScopeSelected,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.customAccess.selected
                ? _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "FormField",
                        {
                          attrs: {
                            label: "Permissions",
                            name: "permissions",
                            orientation: "horizontal",
                            errors: _vm.errors,
                            required: true,
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "pt-1" },
                            [
                              _vm.customAccess.valid
                                ? _c("span", { attrs: { id: "policy-msg" } }, [
                                    _vm._v(
                                      "\n            ✓ Permissions Created\n          "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "WbButton",
                                { on: { click: _vm.switchToWizardMode } },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.customAccess.valid
                                          ? "Modify"
                                          : "Create permissions"
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-sm-9 col-sm-offset-3 mt-3" },
                  [
                    _c("WbButton", {
                      ref: "saveButton",
                      attrs: {
                        label: "Save",
                        isLoading: _vm.isSaveButtonLoading,
                      },
                      on: { click: _vm.submit },
                    }),
                    _vm._v(" "),
                    _c("WbButton", {
                      ref: "cancelButton",
                      attrs: { context: "default", label: "Cancel" },
                      on: { click: _vm.cancel },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.wizardMode
        ? _c("CustomAccessPolicyWizard", {
            ref: "wizard",
            attrs: {
              policy: _vm.customAccess.permissions,
              name: _vm.fullName,
              backButtonEnabled: false,
            },
            on: {
              prewizardBack: _vm.handleWizardBack,
              done: _vm.handleDoneWizard,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }