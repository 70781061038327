<template>
  <div>
    <PageHeader
      title="Feature Flags"
      lead="Manage account-level feature flag settings"
    />
    <Spinner v-if="isLoading" ref="loader" />
    <template v-else>
      <div
        v-if="hasFlags"
        class="form-horizontal"
        ref="form"
      >
        <FormFieldCheckbox
          v-for="({ flag, enabled }, index) in featureFlags"
          :disabled="updatingFlags[flag]"
          :hint="getFlagDescription(flag)"
          :key="index"
          :name="flag"
          :label="getFlagLabel(flag)"
          :value="enabled"
          @input="isEnabled => handleFlagInput(flag, isEnabled)"
        />
      </div>
      <AlertMessage
        v-else
        message="Unable to load early access flag information"
        ref="noFlagsAlert"
      />
    </template>
  </div>

</template>
<script>
  import AlertMessage from 'components/common/AlertMessage'
  import FormFieldCheckbox from 'components/common/FormFieldCheckbox'
  import PageHeader from 'components/common/PageHeader'
  import Spinner from 'components/common/Spinner'
  import Util from 'lib/util'
  import FEATURE_FLAG_CONFIG from 'active_feature/feature_flags.yml' // eslint-disable-line import/extensions

  import {
    fetchFeatureFlags,
    patchFeatureFlag,
  } from 'services/superadmin/featureFlagsService'

  export default {
    components: {
      AlertMessage,
      FormFieldCheckbox,
      PageHeader,
      Spinner,
    },
    props: {
      accountId: {
        type: [String, Number],
        required: true,
      },
    },
    data () {
      return {
        featureFlags: null,
        isLoading: false,
        updatingFlags: {},
      }
    },
    computed: {
      hasFlags () {
        return this.featureFlags && this.featureFlags.length > 0
      },
    },
    created () {
      this.fetchData()
    },
    methods: {
      fetchData () {
        this.isLoading = true

        fetchFeatureFlags(this.accountId)
          .then(response => {
            this.featureFlags = response
          })
          .always(() => {
            this.isLoading = false
          })
      },

      getFlagDescription (flag) {
        return FEATURE_FLAG_CONFIG.flags[flag]?.description
      },

      getFlagLabel (flag) {
        return FEATURE_FLAG_CONFIG.flags[flag]?.label
      },

      handleFlagInput (flag, isChecked) {
        this.updatingFlags[flag] = true

        patchFeatureFlag(this.accountId, flag, isChecked)
          .then(response => {
            Util.showFlashNotice('Feature flag updated')
            this.featureFlags = response
          })
          .fail(response => {
            console.error(response.error())
          })
          .always(() => {
            delete this.updatingFlags[flag]
          })
      },
    },
  }
</script>
