/*
*
* Represents a modal for Superadmins to tell us why they are accessing a specific account
*
*/

<template>
  <WbModal
    ref="modal"
    :watchPageContextVariable="context"
    title="Account Access"
  >
    <div class="m-4">
      <form role="form">
        <div class="breathe">{{ prompt }}</div>
        <FormFieldRadio
          ref='radio'
          :options="options"          
          :required="true"
          name="reason"
          :errors="formErrors"
          v-model="radioValue"
        />
        <hr />
        <FormFieldTextArea
          v-model="textAreaValue"
          ref='additionalNotes'
          name="additional_note"
          label="Ticket number, link, or other sources"
          placeholder="Support ticket number or link, any other important details to log."
          required
          :errors="formErrors"
        />
      </form>
    </div>
    <div slot="footer">
      <WbButton
        context="default"
        @click="hideModal"
      >
        Cancel
      </WbButton>
      <WbButton
        :isLoading="processingRequest"
        @click="gainAccess"
      >
        {{ submitBtnLabel }}
      </WbButton>
    </div>
  </WbModal>
</template>

<script>
  import WbModal from 'components/common/WbModal'
  import FormFieldRadio from 'components/common/FormFieldRadio'
  import FormFieldTextArea from 'components/common/FormFieldTextArea'
  import WbButton from 'components/common/WbButton'
  import { mapGetters } from 'vuex'
  import { ajax } from 'jquery'

  export default {
    name: 'account-access-reason',
    components: {
      WbModal,
      WbButton,
      FormFieldRadio,
      FormFieldTextArea
    },

    data() {
      return {
        processingRequest: false,
        context: 'access_for_account',
        formErrors: {},
        radioValue: 'general_support',
        textAreaValue: '',
        options: [
          { label: "General Support (e.g. support question/issue)", value: "general_support"},
          { label: "Implementation Initial", value: "implementation_initial" },
          { label: "Implementation Follow Up (e.g. updating forms)", value: "implementation_follow_up" },
          { label: "Account Research (e.g. finding onboarded count)", value: "account_research" },
          { label: "I need full-time access to this Account (e.g. form cloning accounts)", value: 'full_time_admin'},
          { label: "Other", value: "other" }
        ]
      }
    },

    computed: {
      prompt() {
        if (this.pageContext.access_for_account) {
          return `Why do you need access to ${this.pageContext.access_for_account.name}?`
        }
      },

      submitBtnLabel() {
        return this.radioValue == 'full_time_admin' ? 'Access Indefinitely' : 'Access for 7 days'
      },

      ...mapGetters({
        pageContext: 'pageContext'
      }),
    },

    methods: {
      hideModal() {
        this.$refs.modal.hide()
      },

      gainAccess() {
        this.processingRequest = true
        // API requires 'other' and a reason, though Radio usage applies
        // the 'other' value directly as the field value
        const reason = this.$refs.radio.otherSelected ? 'other' : this.radioValue
        const reasonOther = this.$refs.radio.otherSelected ? this.radioValue : null

        ajax({
          type: 'POST',
          url: this.$routes.account_access_index_path,
          data: {
            account_id: this.pageContext.access_for_account.id,
            reason: reason,
            reason_other: reasonOther,
            additional_note: this.textAreaValue
          },
          progressBar: false,
          wbGenericFailureMsg: this.$locale.t('documents.clone.document_fetch_fail'),
        }).done(() => {
          // Redirect to accessed subdomain, Turbolinks.visit won't work for redirect to another subdomain
          window.location = this.$routes.root_url({subdomain: this.pageContext.access_for_account.subdomain })
        }).fail(xhr => {
          this.processingRequest = false

          if (xhr.responseJSON) {
            this.formErrors = xhr.responseJSON['errors']
          }
        })
      },
    },
  }
</script>
