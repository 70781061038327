var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", { attrs: { "data-id": _vm.submissionId } }, [
    _c("td", [
      _vm.isSelectable
        ? _c("div", { ref: "checkbox", staticClass: "a4s-checkbox" }, [
            _c("input", {
              attrs: {
                type: "checkbox",
                id: "row_select_id" + this.submissionId,
              },
              domProps: {
                value: this.submissionId,
                checked: _vm.assignment.selected,
              },
              on: {
                change: function ($event) {
                  return _vm.toggleSubmissionSelectedById({
                    id: _vm.submissionId,
                  })
                },
              },
            }),
            _vm._v(" "),
            _c("label", {
              attrs: { for: "row_select_id" + this.submissionId },
            }),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("td", { ref: "fullName" }, [_vm._v(_vm._s(_vm.fullName))]),
    _vm._v(" "),
    _c(
      "td",
      [
        _c("WbSimpleDateTime", {
          attrs: {
            value: _vm.submission.created_at,
            invalidMessage: _vm.defaultValue,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("td", [
      _vm.submissionId
        ? _c(
            "a",
            {
              staticClass: "btn btn-default btn-sm",
              attrs: { href: _vm.submissionPath, target: "_blank" },
            },
            [
              _c("WbIcon", { attrs: { type: "file-text-o" } }),
              _vm._v("\n      View Form\n    "),
            ],
            1
          )
        : _c("div", [_vm._v(_vm._s(_vm.defaultValue))]),
    ]),
    _vm._v(" "),
    _c(
      "td",
      { ref: "status" },
      [
        _c("I9InboxAssignmentStatus", {
          attrs: { assignment: _vm.assignment },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "td",
      [
        _c("WbSimpleDateTime", {
          attrs: {
            value: _vm.submission.reverify_on,
            invalidMessage: _vm.defaultValue,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _vm.shouldShowVerificationStatus
      ? _c(
          "td",
          { ref: "verificationStatus" },
          [
            _vm.verificationStatus
              ? _c("DocumentVerificationBadge", {
                  attrs: {
                    status: _vm.verificationStatus,
                    submission: _vm.assignment.submission,
                  },
                })
              : [_vm._v("\n      --\n    ")],
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _c("td", { ref: "auditLogs" }, [
      _vm.hasAuditLogs
        ? _c(
            "button",
            {
              ref: "auditLogsButton",
              staticClass: "btn btn-link pl-0",
              on: { click: _vm.handleAuditClick },
            },
            [_vm._v("\n      View audit events\n    ")]
          )
        : _c("p", [_vm._v("\n      " + _vm._s(_vm.defaultValue) + "\n    ")]),
    ]),
    _vm._v(" "),
    _vm.shouldShowAdditionalActions
      ? _c(
          "td",
          { ref: "additionalItemsCell", staticClass: "text-right" },
          [
            _vm.canRestartRemoteI9
              ? _c("WbButton", {
                  ref: "restartRI9",
                  staticClass: "btn-sm btn-restart-i9",
                  attrs: {
                    isLoading: _vm.isRestartingI9,
                    label: "Restart Remote I-9",
                    title: "Restart Remote I-9 Verification",
                  },
                  on: { click: _vm.restartRemoteI9 },
                })
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }