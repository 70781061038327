var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pagination-footer" }, [
    _c("div", { staticClass: "pagination-status" }, [
      _c("strong", [
        _vm._v("\n      " + _vm._s(_vm.paginationStatus) + "\n    "),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "pagination-button" }, [
      _c(
        "a",
        {
          staticClass: "underline",
          class: { disabled: !_vm.previousPage },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.getPageItems(1, !_vm.previousPage)
            },
          },
        },
        [_vm._v("\n      FIRST\n    ")]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "pagination-button previous" }, [
      _c(
        "a",
        {
          class: { disabled: !_vm.previousPage },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.getPageItems(_vm.previousPage, !_vm.previousPage)
            },
          },
        },
        [_c("i", { staticClass: "fa fa-chevron-left last-page" })]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "pagination-button forward" }, [
      _c(
        "a",
        {
          class: { disabled: !_vm.nextPage },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.getPageItems(_vm.nextPage, !_vm.nextPage)
            },
          },
        },
        [_c("i", { staticClass: "fa fa-chevron-right next-page" })]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "pagination-button" }, [
      _c(
        "a",
        {
          staticClass: "underline",
          class: { disabled: !_vm.nextPage },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.getPageItems(_vm.lastPage, !_vm.nextPage)
            },
          },
        },
        [_vm._v("\n      LAST\n    ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }