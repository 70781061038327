<template>
  <div>
    <bulk-update-custom-fields-container
      :collectionViewName="collectionViewName"
      :employeeIds="employeeIds"
      @hidden="cleanup"
      @success="handleSuccess"
    />
  </div>
</template>

<script>
  import { mapActions, mapMutations } from 'vuex'
  import * as mutate from 'vuex/mutation_types'
  import BulkUpdateCustomFieldsContainer from 'components/employees/bulk_update_custom_fields/BulkUpdateCustomFieldsContainer'
  import CollectionViewStore from 'vuex/modules/collection_view_store'
  import Util from 'lib/util'

  export default {
    name: 'bulk-update-custom-fields-orphan-container',

    components: {
      BulkUpdateCustomFieldsContainer
    },

    props: {
      employeeIds: {
        type: Array,
        required: true
      },
    },

    data() {
      return {
        collectionViewName: 'collection_view_update_employee_custom_fields'
      }
    },

    methods: {
      handleSuccess() {
        location.reload()
      },

      cleanup() {
        this.$emit('clear-modal-data')
        this.$destroy()
      },

      ...mapActions({
        employeesBulkShow: 'employees/bulkShow',
        representableFieldsFetch: 'representable_fields_tree/fetch'
      }),

      ...mapMutations({
        setPageContextKeys: mutate.SET_PAGE_CONTEXT_KEYS,
      })
    },

    created() {
      if(!this.$store._modulesNamespaceMap[`${this.collectionViewName}/`]) {
        this.$store.registerModule(this.collectionViewName, CollectionViewStore)
      }
    },

    beforeDestroy() {
      // Clear selected BEFORE we unregister the dynamically created module
      this.$store.dispatch(`${this.collectionViewName}/reset`)
      this.$store.unregisterModule(this.collectionViewName)
    },

    mounted() {
      const promiseEmployees = this.employeesBulkShow({
        employeeIds: this.employeeIds
      }).then((employees) => {
        this.$store.dispatch(`${this.collectionViewName}/selectAll`, employees.map((e) => e.id))
      })
      const promiseCustomFields = this.representableFieldsFetch([])

      Promise.all([
        promiseEmployees,
        promiseCustomFields
      ]).then(() => {
        const keys = {}
        keys[this.collectionViewName] = true
        this.setPageContextKeys(keys)
      }).catch((xhr) => {
        Util.genericAjaxError(this.$locale.t('errors.ajax.generic'), xhr)
      })
    }
  }
</script>
