var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("form-field-date", {
        attrs: {
          name: "hire_date",
          label: "Hire Date",
          errors: _vm.errors,
          orientation: _vm.orientation,
          placeholder: _vm.placeholder,
          helpPopoverText: this.$locale.t("employments.help_text.hire_date"),
        },
        model: {
          value: _vm.values.hire_date,
          callback: function ($$v) {
            _vm.$set(_vm.values, "hire_date", $$v)
          },
          expression: "values.hire_date",
        },
      }),
      _vm._v(" "),
      _c("form-field-date", {
        attrs: {
          name: "start_date",
          label: "Start Date",
          errors: _vm.errors,
          orientation: _vm.orientation,
          placeholder: _vm.placeholder,
          helpPopoverText: this.$locale.t("employments.help_text.start_date"),
        },
        model: {
          value: _vm.values.start_date,
          callback: function ($$v) {
            _vm.$set(_vm.values, "start_date", $$v)
          },
          expression: "values.start_date",
        },
      }),
      _vm._v(" "),
      _c("form-field-date", {
        attrs: {
          name: "end_date",
          label: "End Date",
          errors: _vm.errors,
          orientation: _vm.orientation,
          placeholder: _vm.placeholder,
          helpPopoverText: this.$locale.t("employments.help_text.end_date"),
        },
        model: {
          value: _vm.values.end_date,
          callback: function ($$v) {
            _vm.$set(_vm.values, "end_date", $$v)
          },
          expression: "values.end_date",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }