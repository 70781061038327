var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isDownloadable
      ? _c("div", [
          _c(
            "a",
            {
              ref: "download-link",
              attrs: { href: _vm.downloadPart.download_url, target: "_blank" },
            },
            [
              _vm._v("\n      Download part " + _vm._s(_vm.label) + "\n      "),
              _c("i", { staticClass: "fa fa-download pl-1" }),
            ]
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("small", { staticClass: "text-muted" }, [
            _vm._v("Expires " + _vm._s(_vm.expiresDisplayDate)),
          ]),
        ])
      : _c("span", { ref: "status" }, [
          _vm._v("\n    Download part " + _vm._s(_vm.label) + "\n    "),
          _vm.iconClassNames
            ? _c("i", { staticClass: "fa ml-1", class: _vm.iconClassNames })
            : _vm._e(),
          _vm._v(" "),
          _c("em", { staticClass: "text-muted pl-1" }, [
            _vm._v(_vm._s(_vm.getStatusText(_vm.status))),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }