import ToolbarView from 'views/admin_users/admin_users_toolbar_view'
import AdminListTableCollectionView from 'views/admin_users/admin_list_table_collection_view'

export default Marionette.LayoutView.extend({
  template: false,
  ui: {
    newAdminEmployeeBtn: ".new-admin-employee-btn",
  },

  initialize: function() {
    this.$tableEl = this.$("#admin-users-table")
    this.$pendingTableEl = this.$("#pending_admin-users-table")
  },

  onRender: function() {
    this.attachToListTable()

    this.addRegion("toolbarRegion", ".new-admin-user")
    const toolbarView = new ToolbarView()
    this.toolbarRegion.show(toolbarView)
  },

  attachToListTable: function() {
    this.$tableEl.DataTable({
      retrieve: true,
      destroy: true,
      sorting: [0, 'asc'],
      columns: [
        /* 0 Name */ null,
        /* 1 Employee File */ null,
        /* 2 Email */ null,
        /* 3 Access Level */ null,
        /* 4 Actions */ { className: 'action-col' },
      ],
      language: {
        emptyTable: '<div class="text-center"><h4>No admin users have been created.</h4>' +
                    '<p><a href="' + App.Util.getPageRootPath() + '/new">Click here</a> to create an Admin User.</p></div>',
      }
    })

    this.$pendingTableEl.DataTable({
      retrieve: true,
      destroy: true,
      sorting: [0, 'asc'],
      searching: false,
      columns: [
        /* 0 Name */ null,
        /* 1 Email */ null,
        /* 2 Created From */ null,
        /* 3 Actions */ { className: 'action-col' },
      ],
      language: {
        emptyTable: '<div class="text-center"><h4>No pending admin users exist.</h4></div>',
      }
    })

    App.AdminUsers.listTableCollectionView = new AdminListTableCollectionView()
    App.AdminUsers.listTableCollectionView.attachToTable(this.$tableEl, App.Collections.AdminUserCollection, function() {
      return {
        "id": this.data("id"),
      }
    })

    App.AdminUsers.listTableCollectionViewPending = new AdminListTableCollectionView()
    App.AdminUsers.listTableCollectionViewPending.attachToTable(this.$pendingTableEl, App.Collections.AdminUserCollection, function() {
      return {
        "id": this.data("id"),
      }
    })
  },

  onBeforeDestroy: function() {
    Marionette.TemplateCache.clear("#admin-users-toolbar-tmpl")

    if (App.AdminUsers.listTableCollectionView) {
      App.AdminUsers.listTableCollectionView.destroy()
      delete App.AdminUsers.listTableCollectionView
    }

    if (App.AdminUsers.listTableCollectionViewPending) {
      App.AdminUsers.listTableCollectionViewPending.destroy()
      delete App.AdminUsers.listTableCollectionViewPending
    }
  },
})
