var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h4", [_vm._v("What will be the name of the new policy?")]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-6 row" },
        [
          _c("FormFieldInput", {
            ref: "policyName",
            staticClass: "form-group-tight give-room-above",
            attrs: {
              label: "Policy Name",
              orientation: "horizontal",
              type: "text",
              name: "name",
              hint: "Policy names cannot be changed after they are created.",
              helpPopoverText:
                "Permission policies determine what access an Admin User has to employee data fields, forms submissions, and admin actions they are allowed to take within WorkBright. Permission policies apply to groups. Groups are assigned per Admin User and determine what Staff Members the Admin User will have access to.",
              errors: _vm.localPolicy.errors,
              disabled: _vm.isEditing,
            },
            model: {
              value: _vm.localPolicy.name,
              callback: function ($$v) {
                _vm.$set(_vm.localPolicy, "name", $$v)
              },
              expression: "localPolicy.name",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }