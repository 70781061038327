<template>
  <div class="filters">
    <div class="my-2">
      <h4 class="text-strong d-inline">
        Sort By
      </h4>
      <span v-if="filter.help_text">
        <a
          class="help-popover ml-1"
          v-tooltip.html="filter.help_text"
          href="javascript:void(0)"
        >
          <i class="fa fa-info-circle" />
        </a>
      </span>
    </div>
    <div v-if="filter.options.length > 2">
      <form-field-dropdown
        v-model="value"
        :label="filter.title"
        :options="filter.options"
      />
    </div>
    <div v-else>
      <radio-toggle
        :title="filter.title"
        :value="value || filter.defaultValue"
        :options="filter.options"
        buttonSize="btn-sm"
        @selected="(selection) => { setSorting(selection) }"
      />
    </div>
  </div>
</template>

<script>
  import FormFieldDropdown from 'components/common/FormFieldDropdown'
  import RadioToggle from 'components/common/RadioToggle'

  export default {
    name: "sort-order",
    components: {
      FormFieldDropdown,
      RadioToggle,
    },

    props: {
      // Filter
      // {
      //   filter_key: (required) String
      //   title: (required) String
      //   ... other required properties vary based on filter type, commonly
      // }
      filter: {
        type: Object,
        required: true
      },

      // Vuex CollectionViewStore module name
      collectionViewName: {
        type: String,
        required: true
      }
    },

    computed: {
      value: {
        get() {
          return this.$store.getters[`${this.collectionViewName}/sortOrder`]
        },
        set(value) {
          this.$store.dispatch(`${this.collectionViewName}/setSortOrder`, { sortOrder: value })
        }
      }
    },
    methods: {
      setSorting(value) {
        this.$store.dispatch(`${this.collectionViewName}/setSortOrder`, { sortOrder: value })
      }
    }
  }
</script>

