var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "integrated-signature-pad" },
    [
      _c("h3", [_vm._v("Electronic Signature")]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _vm.nameField
        ? _c("FormFieldInput", {
            staticClass: "mx-0",
            attrs: {
              required: true,
              label: _vm.signatureNameInstructions,
              errors: _vm.errors,
              name: _vm.nameField.name,
              type: "text",
              autofocus: _vm.nameField.autofocus ? true : false,
            },
            model: {
              value: _vm.value.signature_name,
              callback: function ($$v) {
                _vm.$set(_vm.value, "signature_name", $$v)
              },
              expression: "value.signature_name",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._t("subHeader"),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "form-group mx-0 required",
          class: { "has-error": _vm.hasError },
        },
        [
          _c(
            "div",
            [
              _c("FormLabel", {
                attrs: {
                  label: _vm.drawSignature,
                  name: this.signatureInputName,
                  required: true,
                },
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-default btn-clear-signature",
                  attrs: { type: "button" },
                  on: { click: _vm.clearSignature },
                },
                [_vm._v("\n        Clear\n      ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "signature-pad mt-0" }, [
            _c("div", { staticClass: "signature-pad-body" }, [
              _c("canvas", {
                ref: "signatureCanvas",
                staticClass: "form-control",
              }),
              _vm._v(" "),
              _c("div", { staticClass: "signature-pad-footer" }, [
                _vm._v(
                  "\n          Use your mouse (or your finger on mobile devices) to draw your signature.\n        "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm.hasError
            ? _c("small", { ref: "error-message", staticClass: "help-block" }, [
                _vm._v(_vm._s(_vm.errorMessage)),
              ])
            : _vm._e(),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }