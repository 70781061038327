var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("bulk-update-custom-fields-container", {
        attrs: {
          collectionViewName: _vm.collectionViewName,
          employeeIds: _vm.employeeIds,
        },
        on: { hidden: _vm.cleanup, success: _vm.handleSuccess },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }