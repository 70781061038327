var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wb-list-header" }, [
    _c(
      "div",
      {
        ref: "wbContentRow",
        staticClass: "wb-list__row",
        class: { "select-all-active": _vm.anySelected },
      },
      [
        _vm.filteredRowIds && _vm.filteredRowIds.length > 0 && _vm.selectable
          ? _c(
              "div",
              { staticClass: "wb-list__column-selector" },
              [
                _c("select-all", {
                  attrs: {
                    disabled: _vm.disabled,
                    collectionViewName: _vm.collectionViewName,
                    items: _vm.filteredRowIds,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "wb-header-actions-row" }, [
          _vm.anySelected
            ? _c(
                "div",
                { staticClass: "wb-header-actions-column bulk-action" },
                [_vm._t("bulkActions")],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.anySelected
            ? _c(
                "div",
                { staticClass: "wb-header-actions-column static-action" },
                [_vm._t("staticActions")],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.allSelected
            ? _c(
                "div",
                { staticClass: "wb-header-actions-column select-all" },
                [_vm._t("selectAll")],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.anySelected
            ? _c(
                "div",
                { staticClass: "wb-header-actions-column total-selected" },
                [
                  _vm.anySelected
                    ? _c("span", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.selectionCount) +
                            " Selected\n        "
                        ),
                      ])
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.search && !_vm.anySelected
            ? _c(
                "div",
                { staticClass: "wb-header-actions-column search-action" },
                [
                  _c("search-filter", {
                    ref: "searchInput",
                    staticClass: "mr-0",
                    attrs: {
                      placeholder: _vm.searchPlaceholder,
                      collectionViewName: _vm.collectionViewName,
                      filter: { filter_key: "search" },
                      label: "",
                    },
                    on: { "search-input": _vm.handleSearchEntry },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }