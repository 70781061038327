import DownloadRequest from 'models/downloads/DownloadRequest'
import DownloadPart from 'models/downloads/DownloadPart'
import routes from 'lib/routes'
import { ajax } from 'jquery'

const state = () => {
  return {
    downloadRequests: [],
    downloadPartsByRequestId: {}
  }
}

const getters = {
  downloadRequests: state => state.downloadRequests,
  downloadPartsByRequestId: state => state.downloadPartsByRequestId
}

const mutations = {
  setDownloadRequests: (state, downloadRequests) => {
    state.downloadRequests = downloadRequests
  },
  setDownloadParts: (state, downloadParts) => {
    state.downloadPartsByRequestId = { ...downloadParts } // Assign to new object for reactive updates
  },
  setDownloadPartsForId: (state, { downloadParts, id}) => {
    state.downloadPartsByRequestId[id] = downloadParts
    state.downloadPartsByRequestId = { ...state.downloadPartsByRequestId } // Assign to new object for reactive updates
  }
}

const actions = {
  fetchDownloadPartsForId ({ commit }, requestId) {
    return new Promise((resolve, reject) => {
      ajax({
        url: routes.Api.download_request_path({ id: requestId }),
        method: 'GET',
        progressBar: true
      }).success(data => {
        let downloads = []
        if (data && data.length) {
          downloads = data.map(download => new DownloadPart(download))
        }
        commit('setDownloadPartsForId', {
          id: requestId,
          downloadParts: downloads
        })
        resolve()
      }).error(() => {
        commit('setDownloadPartsForId', {
          id: requestId,
          downloadParts: []
        })
        reject()
      })
    })
  },
  fetchDownloadRequests ({ commit }) {
    return new Promise((resolve, reject) => {
      ajax({
        url: routes.Api.download_requests_path,
        method: 'GET',
        progressBar: true,
      }).success(data => {
        let requests = []
        if (data && data.length) {
          requests = data.map(request => new DownloadRequest(request))
        }
        commit('setDownloadRequests', requests)
        resolve()
      }).error(() => {
        commit('setDownloadRequests', [])
        reject()
      })
    })
  }
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
}
