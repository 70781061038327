import Routes from 'lib/routes'
import Constants from 'lib/constants'
import UserImportPageHeader from 'components/user_imports/UserImportPageHeader'
import { pureOrphanVue } from 'lib/vue/pureOrphanVue'

// View indicating that employee record validation is in progress or has failed
export default Marionette.LayoutView.extend({
  template: '#layout-admin-users-validating-employees',

  ui: {
    validationInProgress: '.validation-in-progress-msg',
    validationFailed: '.validation-failed-msg'
  },

  templateHelpers: () => {
    return {
      allImportsPath: Routes.user_imports_path,
      supportUrl: Constants.SUPPORT_URL
    }
  },

  onRender: () => {
    pureOrphanVue(UserImportPageHeader, "component.vue-user-import-header", {
      props: {
        wizardStep: 'columns'
      },
    })
  },

  // Hides validation in progress messaging and shows failure messaging
  validationFailure() {
    $(this.ui.validationInProgress).toggleClass('hidden')
    $(this.ui.validationFailed).toggleClass('hidden')
  },
})
