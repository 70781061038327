<template>
  <nav class="navbar navbar-main" role="navigation">
    <div class="container-fluid">
      <AppNavbarHeader />
      <div class="collapse navbar-collapse navbar-main-collapse">
        <form
          class="navbar-form navbar-left search-form"
          role="search"
          v-if="shouldShowEmployeeSearch"
        >
          <EmployeeSearch
            @input="handleEmployeeSearchInput"
          />
        </form>
        <AppNavbarNav
          v-if="shouldShowPrimaryNav"
          :items="navItems"
        />
        <NavbarAnnouncements v-if="shouldShowAnnouncements" />
        <component
          v-if="secondaryNavComponent"
          :is="secondaryNavComponent"
          :currentUser="currentUser"
          :items="dropdownItems"
          ref="secondaryNav"
        />
      </div>
    </div>
  </nav>
</template>
<script>
  import AdminNavbarNav from 'components/navbar/AdminNavbarNav'
  import AppNavbarHeader from 'components/navbar/AppNavbarHeader'
  import AppNavbarNav from 'components/navbar/AppNavbarNav'
  import AuthenticatedNavbarNav from 'components/navbar/AuthenticatedNavbarNav'
  import EmployeeSearch from 'components/employees/EmployeeSearch'
  import KioskNavbarNav from 'components/navbar/KioskNavbarNav'
  import NavbarAnnouncements from 'components/navbar/NavbarAnnouncements'
  import StaffNavbarNav from 'components/navbar/StaffNavbarNav'
  import { mapGetters } from 'vuex'

  export default {
    name: 'the-app-navbar-root',
    components: {
      AdminNavbarNav,
      AppNavbarHeader,
      AuthenticatedNavbarNav,
      AppNavbarNav,
      EmployeeSearch,
      KioskNavbarNav,
      NavbarAnnouncements,
      StaffNavbarNav
    },
    computed: {
      isAdminMode () {
        return this.type === 'admin'
      },
      isAuthenticated () {
        return this.currentUser && this.currentUser.id !== undefined
      },
      isExceptionMode () {
        return this.type === 'exception'
      },
      isKioskMode () {
        return this.type === 'kiosk'
      },
      isPublicTenant () {
        return !this.currentAccount || !this.currentAccount.subdomain || this.currentAccount.subdomain === 'public'
      },
      navItems () {
        return this.settings?.nav_items
      },
      dropdownItems () {
        return this.settings?.dropdown_items
      },
      secondaryNavComponent () {
        let comp = null

        if (this.isAuthenticated) {
          if (this.isKioskMode) {
            comp = KioskNavbarNav
          } else if (this.isPublicTenant || this.isExceptionMode) {
            comp = AuthenticatedNavbarNav
          } else if (this.isAdminMode) {
            comp = AdminNavbarNav
          } else {
            comp = StaffNavbarNav
          }
        }

        return comp
      },
      shouldShowEmployeeSearch () {
        return this.isAdminMode && !this.isPublicTenant
      },
      shouldShowPrimaryNav () {
        return !this.isPublicTenant && this.navItems && this.navItems.length
      },
      type () {
        return this.settings?.type
      },

      ...mapGetters({
        currentAccount: 'account/current',
        currentUser: 'user/current'
      })
    },
    methods: {
      handleEmployeeSearchInput ({ id }) {
        this.$nextTick(() => {
          Turbolinks.visit(this.$routes.employee_path({ id: id }))
        })
      }
    },
    props: {
      shouldShowAnnouncements: {
        type: Boolean
      },
      settings: {
        type: Object
      }
    }
  }
</script>
