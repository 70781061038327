import EmbeddedService from './embedded_service'

const SystemEvents = {
  /**
   *
   * Takes a systemEvent and sends it to the
   * EmbeddedService, which notifies the parent
   * using the PostMessage API
   *
   * @param {string} systemEvent
   */
  notify: function (systemEvent) {
    EmbeddedService.postMessage(systemEvent)
  },

  /**
   * Processes system_events sent to gon, if any, so each
   * of them can be notified to embedded parent
   */
  process: function () {
    window.gon.system_events && window.gon.system_events.forEach((systemEvent) => {
      this.notify(systemEvent)
    })
  }
}

export default SystemEvents
