var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "dashboard-widget",
    {
      attrs: {
        widgetTitle: _vm.title,
        errorMsg: _vm.errorMsg,
        lastUpdated: _vm.lastUpdated,
      },
      on: {
        refresh: function ($event) {
          return _vm.fetch(true)
        },
      },
    },
    [
      _c(
        "v-wait",
        { attrs: { for: _vm.source } },
        [
          _c("spinner", {
            attrs: { slot: "waiting", showLongWaitMessage: true },
            slot: "waiting",
          }),
          _vm._v(" "),
          _c("p", { staticClass: "wb-card__section-title" }, [
            _vm._v("\n      Sign-in activity\n    "),
          ]),
          _vm._v(" "),
          !_vm.errorMsg.length &&
          _vm.chartData &&
          _vm.chartData.session_progress
            ? _c("wb-breakdown-chart", {
                attrs: {
                  legendData: _vm.legendData.session_progress,
                  chartData: _vm.chartData.session_progress,
                  listViewPath: _vm.activeStaffPath,
                  modelNameHumanized: _vm.modelName,
                  tooltipWarning: _vm.chartTooltipWarning,
                },
                on: { "section-click": _vm.handleSessionProgressSectionClick },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("p", { staticClass: "wb-card__section-title" }, [
            _vm._v("\n      Required Forms\n    "),
          ]),
          _vm._v(" "),
          !_vm.errorMsg.length && _vm.chartData && _vm.chartData.form_progress
            ? _c("wb-breakdown-chart", {
                attrs: {
                  legendData:
                    _vm.legendData.form_progress.required_non_internal,
                  chartData: _vm.chartData.form_progress.required_non_internal,
                  listViewPath: _vm.activeStaffRequiredFormsPath,
                  modelNameHumanized: _vm.modelName,
                  tooltipWarning: _vm.chartTooltipWarning,
                },
                on: {
                  "section-click":
                    _vm.handleRequiredNonInternalFormProgressSectionClick,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("p", { staticClass: "wb-card__section-title" }, [
            _vm._v("\n      Required Internal Forms\n    "),
          ]),
          _vm._v(" "),
          !_vm.errorMsg.length && _vm.chartData && _vm.chartData.form_progress
            ? _c("wb-breakdown-chart", {
                attrs: {
                  legendData: _vm.legendData.form_progress.required_internal,
                  chartData: _vm.chartData.form_progress.required_internal,
                  listViewPath: _vm.activeStaffPath,
                  modelNameHumanized: _vm.modelName,
                  tooltipWarning: _vm.chartTooltipWarning,
                },
                on: {
                  "section-click":
                    _vm.handleRequiredInternalFormProgressSectionClick,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }