<template>
  <div>
    <div v-if="isDownloadable">
      <a
        :href="downloadPart.download_url"
        target="_blank"
        ref="download-link"
      >
        Download part {{ label }}
        <i class="fa fa-download pl-1" />
      </a>
      <br />
      <small class="text-muted">Expires {{ expiresDisplayDate }}</small>
    </div>
    <span v-else ref="status">
      Download part {{ label }}
      <i class="fa ml-1" v-if="iconClassNames" :class="iconClassNames" />
      <em class="text-muted pl-1">{{ getStatusText(status) }}</em>
    </span>
  </div>
</template>
<script>
  import DateTime from 'lib/util/datetime'
  const CLASS_NAMES_BY_STATUS = {
    pending: 'fa-clock-o text-muted',
    processing: 'fa-refresh text-info',
    completed: 'fa-check text-success',
    expired: 'fa-exclamation-triangle text-muted',
    no_files_to_export: '' 
  }
  const STATUS_TEXT_BY_CODE = {
    pending: 'Queued',
    processing: 'Processing',
    completed: 'Complete',
    expired: 'Expired',
    no_files_to_export: 'No files to download' 
  }
  export default {
    computed: {
      expiresDisplayDate () {
        return DateTime.formatDateTime(this.downloadPart.expires_at)
      },
      iconClassNames () {
        return this.getIconClassNameFromStatus(this.status)
      },
      isDownloadable () {
        return this.status === 'completed' && this.downloadPart.download_url
      },
      label () {
        return this.index + 1
      },
      status () {
        return this.downloadPart.status
      }
    },
    methods: {
      getIconClassNameFromStatus (status) {
        return CLASS_NAMES_BY_STATUS[status] || ''
      },
      getStatusText (status) {
        return STATUS_TEXT_BY_CODE[status] || ''
      }
    },
    props: {
      downloadPart: {
        type: Object,
        required: true
      },
      index: {
        type: Number,
        required: true
      }
    }
  }
</script>
