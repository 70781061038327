var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "nav navbar-nav navbar-left navbar-app" },
    _vm._l(_vm.items, function (item, index) {
      return _c("li", { key: index, class: { active: item.active } }, [
        _c("a", { attrs: { href: item.href } }, [_vm._v(_vm._s(item.label))]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }