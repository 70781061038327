<template>
  <Panel
    variant="snapshot"
    class="scan-and-upload-panel"
  >
    <template v-slot:heading>
      <div class="panel-title">
        <h3>Action Required</h3>
        <h4>Scan and Upload</h4>
      </div>
    </template>
    <template v-slot:body>
      <div class="col-centered">
        <p class="pt-1">
          E-Verify requires photos of the staff member's documentation. Click
          the button to send the photos to E-Verify.

          If the photos are invalid or poor quality, they may need to be updated
          by the staff member.

          We will send photos of the US Passport that was uploaded for this Form I-9.
        </p>
        <div class="mb-3">
          <WbButton
            ref="scanAndUploadButton"
            label="Send photos to E-Verify"
            :isLoading="isLoading"
            :disabled="!canScanAndUpload"
            @click="submit()"
          />
        </div>
        <EverifyCaseDocumentPreview
          :caseId="caseId"
          :showEverifyPhoto="false"
          class="mb-3"
        />
        <EverifyCaseCloseForm
          :everifyCase="everifyCase"
          @closeTimeout="closeModal"
        />
      </div>
    </template>
  </Panel>
</template>

<script>
  import { mapActions } from 'vuex'
  import EverifyCaseDocumentPreview from 'components/everify/cases/EverifyCaseDocumentPreview'
  import EverifyCaseCloseForm from 'components/everify/cases/EverifyCaseCloseForm'
  import FormFieldRadio from 'components/common/FormFieldRadio'
  import Panel from 'components/common/Panel'
  import Util from 'lib/util'
  import WbButton from 'components/common/WbButton'

  export default {
    name: 'scan-and-upload-panel',
    components: {
      EverifyCaseCloseForm,
      EverifyCaseDocumentPreview,
      FormFieldRadio,
      Panel,
      WbButton,
    },

    props: {
      everifyCase: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        label: '',
        errors: {},
        mainDocumentName: '',
        canScanAndUpload: true,
        isLoading: false
      }
    },

    computed: {
      caseId () {
        return this.everifyCase.id
      }
    },

    beforeDestroy() {
      this.endLoading()
      this.enableScanAndUpload()
    },

    methods: {
      submit() {
        this.startLoading()
        this.disableScanAndUpload()

        this.submitDocuments({
          caseId: this.caseId
        }).then(() => {
          this.endLoading()
          this.closeModal()
          Util.showFlashNotice("Request is processing, case will automatically update when the request has finished.")
        }).catch((xhr) => {
          Util.ajaxErrorDialog("An error occurred while sending a request to E-Verify.", xhr)
          this.endLoading()
          this.enableScanAndUpload()
        })
      },

      disableScanAndUpload() {
        this.canScanAndUpload = false
      },

      enableScanAndUpload() {
        this.canScanAndUpload = true
      },

      startLoading() {
        this.isLoading = true
      },

      endLoading() {
        this.isLoading = false
      },

      closeModal() {
        this.$emit('close')
      },

      ...mapActions({
        submitDocuments: 'everify_cases/submitDocuments'
      })
    },
  }
</script>
