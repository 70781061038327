var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "nav nav-tabs mb-3" },
    _vm._l(_vm.filters, function (f, index) {
      return _c(
        "li",
        { key: index, class: { active: f.value === _vm.filter } },
        [
          _c(
            "a",
            {
              on: {
                click: function ($event) {
                  return _vm.handleSelection(f.value)
                },
              },
            },
            [_vm._v("\n      " + _vm._s(f.label) + "\n    ")]
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }