import WebhookListTableCollectionView from 'views/webhooks/webhook_list_table_collection_view'
import WebhooksCollection from 'collections/webhooks_collection'

export default Marionette.LayoutView.extend({
  template: false,
  initialize: function () {
    this.$tableEl = $("#webhooks-table")
  },

  onBeforeRender: function () {
    if (this.$tableEl) {
      this.attachToListTable()

      this.addRegion("toolbarRegion", ".dt-toolbar")
      var toolbarView = new Marionette.ItemView({ template: "#webhooks-toolbar-tmpl" })
      this.toolbarRegion.show(toolbarView)
    }
  },

  attachToListTable: function () {
    this.$tableEl.DataTable({
      retrieve: true,
      destroy: true,
      sorting: [1],
      columns: [
        /* 1 Name */ null,
        /* 2 URL */ null,
        /* 3 Events */ null,
        /* 5 Actions */ null,
      ],
      language: {
        emptyTable: '<div class="text-center"><h4>No webhooks have been created.</h4>' +
                    '<p><a href="' + App.Util.getPageRootPath() + '/new">Click here</a> to create a Webhook.</p></div>',
      }
    })

    App.Webhooks.listTableCollectionView = new WebhookListTableCollectionView()
    App.Webhooks.listTableCollectionView.attachToTable(this.$tableEl, WebhooksCollection, function() {
      return {
        "id": this.data("id"),
      }
    })
  },

  onBeforeDestroy: function() {
    if (App.Webhooks.listTableCollectionView) {
      App.Webhooks.listTableCollectionView.destroy()
      delete App.Webhooks.listTableCollectionView
    }
  },
})
