<template>
  <div>
    <p v-if="!managedPolicy" class="breathe">
      <i class="fa fa-info text-info fa-fw" />
      Groups selected: {{groupNames.join(', ')}}
    </p>
    <h4>{{ stepHeader }}</h4>
    <div class="form-horizontal">
      <div class="form-group clearfix" v-for="(permission, index) in generalPermissions" :key="index">
        <permissions-toggle-wide :permission="permission" :label="true" v-on:permissionChanged="permissionChanged" />
      </div>
    </div>
  </div>
</template>

<script>
  import BaseStep from 'components/permissions/steps/BaseStep'
  import GeneralPermissions from 'models/policy_generator/general_permissions'
  import PermissionsToggleWide from 'components/permissions/common/PermissionsToggleWide'

  export default {
    name: 'step-employee-management',

    components: {
      PermissionsToggleWide
    },

    extends: BaseStep,

    props: {
      managedPolicy: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      generalPermissions() {
        const gp = new GeneralPermissions()

        return this.groupPermissionSet.findOrCreatePermissionsFromDefinitions(
          gp.GROUP_DEFINITIONS
        )
      },

      groupPermissionSet() {
        return this.policy.groupPermissionSet()
      },

      stepHeader() {
        let headerText = null

        if(this.managedPolicy){
          headerText = this.$locale.t("permissions.employee_management_step.managed.stepHeader")
        }else{
          headerText = this.$locale.t("permissions.employee_management_step.stepHeader")
        }

        return headerText
      }
    },

    methods: {
      permissionChanged(permission, grant) {
        this.groupPermissionSet.togglePermission(permission, grant)
      },

    }
  }
</script>
