var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "WbModal",
    {
      ref: "modal",
      attrs: { title: "Delete Admin", watchPageContextVariable: _vm.modalKey },
    },
    [
      _vm._t("default", [
        _c("p", [
          _vm._v(
            "\n      This will remove this person from your WorkBright account entirely. Are you sure this is what you want to do?\n    "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "WbButton",
            {
              attrs: { context: "default", disabled: false },
              on: { click: _vm.hideModal },
            },
            [_vm._v("\n      Cancel\n    ")]
          ),
          _vm._v(" "),
          _c("WbButton", {
            attrs: {
              context: "danger",
              label: "Delete Admin",
              isLoading: _vm.isLoading,
            },
            on: { click: _vm.handleDelete },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }