<template>
  <header :class="headerClassName">
    <h2>{{ title }}</h2>
    <h4 v-if="lead">{{ lead }}</h4>
  </header>
</template>
<script>
  export default {
    computed: {
      headerClassName () {
        const values = ['page-header']

        if (this.variant) {
          values.push(`page-header-${this.variant}`)
        }
        return values
      }
    },
    props: {
      title: {
        type: String,
        required: true
      },
      lead: {
        type: String
      },
      variant: {
        type: String,
        validator: value => {
          return ['bordered'].includes(value)
        }
      }
    }
  }
</script>
