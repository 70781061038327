var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "WbWorkflowStep",
    { attrs: { stepNumber: _vm.stepNumber, title: "Confirm Location" } },
    [
      [
        _c("AlertMessage", {
          ref: "qrCodeAlert",
          staticClass: "mt-3",
          attrs: { variant: "warning", message: _vm.alertMessage },
        }),
        _vm._v(" "),
        _vm._t("instructions"),
        _vm._v(" "),
        _c("QrcodeVue", {
          ref: "qrCode",
          staticClass: "text-center",
          staticStyle: { padding: "50px 0" },
          attrs: { value: _vm.qrCodePayload, size: 200, level: "L" },
        }),
        _vm._v(" "),
        _c("WbButton", { on: { click: _vm.handleScanComplete } }, [
          _vm._v("\n      Scan Complete\n    "),
        ]),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }