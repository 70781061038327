import Vue from 'vue'
import * as types from './mutation_types'

export default {
  // Sets the entire page context to `payload`
  //
  // payload [Object] - the new page context
  [types.SET_PAGE_CONTEXT](state, payload) {
    state.pageContext = payload
  },

  // Clears the entire page context
  [types.CLEAR_PAGE_CONTEXT](state) {
    state.pageContext = {}
  },

  // Sets one or more individual keys on the pageContext without affecting other keys
  //
  // payload [Object] - the individual key + value pairs to set on the page context
  [types.SET_PAGE_CONTEXT_KEYS](state, payload) {
    for (let key of Object.keys(payload)) {
      Vue.set(state.pageContext, key, payload[key])
    }
  },

  // Deletes a single page context key
  //
  // key [String] - the name of the key to delete from the pageContext
  [types.DELETE_PAGE_CONTEXT_KEY](state, key) {
    Vue.delete(state.pageContext, key)
  }
}
