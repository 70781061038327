var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mt-4" }, [
    !_vm.groups.length
      ? _c("p", [
          _vm._v(
            "\n    This staff member has no assigned groups. Visit their profile to add groups.\n  "
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.groups.length
      ? _c(
          "div",
          { staticClass: "list-group" },
          _vm._l(_vm.groups, function (group) {
            return _c(
              "div",
              { key: group.id, staticClass: "list-group-item" },
              [_vm._v("\n      " + _vm._s(group.name) + "\n    ")]
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }