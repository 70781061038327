<template>
  <tr>
    <td>{{ fieldName }}</td>
    <td>
      <permissions-toggle :permission="viewPermission" :label="false" v-on:permissionChanged="permissionChanged" />
    </td>
    <td>
      <div v-for="(permission, index) in otherPermissions" :key="index">
        <permissions-toggle v-if="viewPermission.grant" :permission="permission" :label="true" v-on:permissionChanged="permissionChanged" />
      </div>
    </td>
    <td class="text-center">
      <i class="fa fa-times-circle delete-rule" v-if="viewPermission.target_id" @click="remove" />
    </td>
  </tr>
</template>

<script>
  import PermissionsToggle from 'components/permissions/common/PermissionsToggle'

  export default {
    name: 'permissions-customize-field-row',
    components: {
      PermissionsToggle
    },
    props: {
      permissionSet: {
        type: Object,
        required: true
      },
      fieldKey: {
        required: true
      },
      permissions: {
        type: Array,
        required: true
      }
    },
    computed: {
      fieldName() {
        return this.permissions[0].targetName(this.$store)
      },

      viewPermission() {
        return this.permissions.find((p) => p.action == 'view')
      },

      otherPermissions() {
        return this.permissions.filter((p) => p.action != 'view')
      }
    },
    methods: {
      permissionChanged(permission, grant) {
        this.permissionSet.togglePermission(permission, grant)
      },

      remove() {
        this.$emit('remove', this.fieldKey)
      }
    }
  }
</script>
