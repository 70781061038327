var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasValidFields
    ? _c(
        "div",
        { ref: "container" },
        [
          _c("update-payroll-modal", {
            attrs: {
              employeeId: _vm.employeeId,
              internalFields: _vm.internalFields,
              staffFields: _vm.staffFields,
              disableInternalFields: _vm.adpInternalFieldsMapped,
              disableStaffFields: _vm.adpStaffFieldsMapped,
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "panel panel-simple" },
            [
              _vm.hasSyncError
                ? _c("AlertMessage", {
                    ref: "syncError",
                    staticClass: "mb-0",
                    attrs: {
                      title: "Sync Error",
                      message: _vm.$locale.t("integrations.adp.sync.syncError"),
                      variant: "warning",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "panel-heading with-action-buttons" }, [
                _c("h3", { staticClass: "panel-title" }, [_vm._v("Payroll")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "float-right d-flex" },
                  [
                    _c("wb-button", {
                      staticClass: "pr-2",
                      attrs: {
                        label: "Edit",
                        context: "default",
                        permissionModel: "employee_seasons",
                        permissionRecordId: _vm.employment.id,
                        permissionAction: "manage",
                      },
                      on: { click: _vm.openModal },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "prepend",
                            fn: function () {
                              return [_c("i", { staticClass: "fa fa-edit" })]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        2158434990
                      ),
                    }),
                    _vm._v(" "),
                    _vm.adpIntegrationActive
                      ? _c("AdpIntegrationControls", {
                          ref: "adpControls",
                          staticClass: "pl-2",
                          attrs: { employeeId: _vm.employeeId },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "PayrollFieldsGroup",
                { attrs: { fields: _vm.internalFields } },
                [
                  _c("template", { slot: "caption" }, [
                    _vm._v("\n        Internal Fields "),
                    _c("small", [_vm._v("(hidden from staff)")]),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "PayrollFieldsGroup",
                { attrs: { fields: _vm.staffFields } },
                [
                  _c("template", { slot: "caption" }, [
                    _vm._v("\n        Staff Fields  "),
                    _c("small", [_vm._v("(completed by staff)")]),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }