var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-7 col-sm-offset-4 col-md-offset-3" }, [
        _c("p", { ref: "instructions", staticClass: "help-block" }, [
          _vm._v(
            _vm._s(
              _vm.$locale.t(
                "standard_fields.options_instructions",
                _vm.mappedFieldName,
                _vm.standardFieldName
              )
            )
          ),
        ]),
        _vm._v(" "),
        !_vm.isValid && _vm.shouldShowErrors
          ? _c(
              "p",
              { ref: "mapperFeedback", staticClass: "help-block error" },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$locale.t("standard_fields.select_value")) +
                    "\n      "
                ),
              ]
            )
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      _vm._l(_vm.mappedFieldOptions, function (option, index) {
        return _c("FormFieldSelect", {
          key: index,
          class: {
            "has-error":
              _vm.errorsByOption[option.value] && _vm.shouldShowErrors,
          },
          attrs: {
            errors: _vm.errorsByOption[option.value],
            label: option.label,
            name: option.value,
            options: _vm.standardFieldOptions,
            value: _vm.getMappedOption(option.value),
            orientation: "horizontal",
          },
          on: {
            input: function ($event) {
              return _vm.handleOptionChange(option.value, $event)
            },
          },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }