var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("bulk-actions-dropdown", {
        attrs: { canDownload: _vm.canDownload },
        on: { "show-bulk-action": _vm.showBulkActionModal },
      }),
      _vm._v(" "),
      _vm.statusChangeAction
        ? _c("bulk-activate-orphan-container", {
            attrs: {
              action: _vm.statusChangeAction,
              employeeIds: _vm.selectedEmployees,
            },
            on: { "clear-modal-data": _vm.clearModalData },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showUpdateEmployment
        ? _c("bulk-update-employments-orphan-container", {
            attrs: { employeeIds: _vm.selectedEmployees },
            on: { "clear-modal-data": _vm.clearModalData },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showUpdateCustomFields
        ? _c("bulk-update-custom-fields-orphan-container", {
            attrs: { employeeIds: _vm.selectedEmployees },
            on: { "clear-modal-data": _vm.clearModalData },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showDownloadSubmissions
        ? _c("bulk-downloads-orphan-container", {
            attrs: { employeeIds: _vm.selectedEmployees },
            on: { "clear-modal-data": _vm.clearModalData },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showResetAssignments
        ? _c("bulk-reset-assignments-orphan-container", {
            attrs: { employeeIds: _vm.selectedEmployees },
            on: { "clear-modal-data": _vm.clearModalData },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.employeeGroupsAction
        ? _c("bulk-update-employee-groups-orphan-container", {
            attrs: {
              action: _vm.employeeGroupsAction,
              employeeIds: _vm.selectedEmployees,
            },
            on: { "clear-modal-data": _vm.clearModalData },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }