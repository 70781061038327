<template>
  <section>
    <PageHeader
      :title="header"
    />
    <div class="row">
      <div class="col-lg-9">
        <WebhookForm
          :webhook="webhook"
          :isEdit="isEdit"
          :systemEventsOptions="systemEventsOptions"
          :documentsForAccount="documentsForAccount"
        />
      </div>
    </div>
  </section>
</template>

<script>
  import WebhookForm from 'components/webhooks/WebhookForm'
  import PageHeader from 'components/common/PageHeader'

  export default {
    name: 'TheWebhookFormRoot',
    components: {
      PageHeader,
      WebhookForm
    },
    props: {
      webhook: Object,
      systemEventsOptions: Array,
      documentsForAccount: Array,
    },
    computed: {
      isEdit() {
        return !!this.webhook
      },
      header () {
        const webhookName = this.webhook?.name || 'Webhook'
        return this.isEdit ? `Edit ${webhookName}` : 'Create a new webhook endpoint'
      }
    }
  }
</script>
