import { getRoute } from 'lib/routes'

const ACCEPTABLE_FILE_TYPES = ['.pdf','.jpg','.jpeg','.png']

const extensionOnly = (fileName) => fileName.match(/^\.[A-z]+$/)

const renameFileHandler = ({ name = '' }) =>  extensionOnly(name) ? `file_${Date.now()}${name}` : name

export default {
  fileTypeErrorMsg: 'Sorry, you can only upload PDF files or images (PNG, JPG, GIF, etc) at this time.<br/><br/> If you are attempting to upload a Word document, please choose File -> Save As and save it as a PDF instead.',
  options: {
    timeout: 4 * 60 * 1000,
    url: getRoute("External.aws_dropzone_url"),
    addRemoveLinks: false,
    zoneType: 'single',
    maxFiles: 1,
    maxFilesize: 20,
    dictInvalidFileType: 'file_type',
    dictFileTooBig: 'file_too_big',
    thumbnailWidth: 150,
    thumbnailHeight: 150,
    previewsContainer: null,
    acceptedFiles: ACCEPTABLE_FILE_TYPES.join(','),
    renameFile: renameFileHandler
  }
}
