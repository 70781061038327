<template>
  <div class="wb-multiselect wb-multiselect-search">
    <Multiselect
      v-model="localValue"
      :internal-search="false"
      label="label"
      track-by="value"
      :loading="isLoading"
      :placeholder="placeholder"
      :searchable="true"
      :options="selectOptions"
      :preserveSearch="true"
      @search-change="throttleSetResults"
      @input="handleInput"
    >
      <template slot="option" slot-scope="props">
        <div>
          <div class="text-strong">{{ props.option.label }}</div>
          <div class="small">{{ props.option.desc }}</div>
        </div>
      </template>
      <span slot="noResult">No results found</span>
      <span slot="noOptions">Please enter 3 or more characters</span>
    </Multiselect>
  </div>
</template>
<script>
  import Constants from 'lib/constants'
  import Multiselect from 'vue-multiselect'
  import { ajax } from 'jquery'
  import { throttle } from 'underscore'
  const MIN_QUERY_LENGTH = 3

  export default {
    name: 'wb-search',
    components: {
      Multiselect
    },

    props: {
      placeholder: {
        type: String,
        required: false
      },

      route: {
        type: String,
        required: true
      },

      // Takes Response from server and returns array of matches
      // in format { label: '', value: '' }
      responseProcessor: {
        type: Function,
        required: true
      },

      value: {
        type: [Number, String]
      },
    },

    created () {
      this.setLocalValue()
    },

    data() {
      return {
        resultsByQuery: {},
        localValue: null,
        isLoading: null,
        selectOptions: []
      }
    },

    methods: {
      handleInput (option) {
        const value = option && option.value
        this.$emit('input', value)
      },

      throttleSetResults: throttle(function(query) {
        this.setResults(query)
      }, Constants.DEFAULT_REQUEST_THROTTLE),

      setResults(query) {
        if (query.length >= MIN_QUERY_LENGTH) {
          if (this.resultsByQuery[query.toLowerCase()]) {
            this.selectOptions = this.resultsByQuery[query.toLowerCase()]
          } else {
            this.fetchResults(query)
          }
        }
      },
      fetchResults(query) {
        this.isLoading = true

        if (this._xhr) {
          this._xhr.abort()
        }

        this._xhr = ajax({
          url: this.route,
          dataType: 'json',
          progressBar: false,
          data: {
            query
          }
        })

        this._xhr.then(response => {
          this.processResponse(response, query)
        }).fail(() => {
          this.selectOptions = []
        }).always(() => {
          this.isLoading = false
        })
      },
      processResponse (response, query) {
        const options = this.responseProcessor(response)
        this.resultsByQuery[query.toLowerCase()] = options
        this.selectOptions = options
      },

      setLocalValue () {
        this.localValue = this.selectOptions.filter(option => {
          return this.value === option.value
        })
      }
    },
    watch: {
      value () {
        this.setLocalValue()
      }
    }
  }
</script>
