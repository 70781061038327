/*
 * Container for displaying historical submissions in a right-hand slideout.
 *
 * Required Vuex Modules:
 *   documents
 *   document_assignments
 *   document_submissions
 *   pageContext
 *
 * To display: set page context key 'show_historical_submissions_assignment_id' with assignment id
 *
 */
<template>
  <slideout-panel watchPageContextVariable="show_historical_submissions_assignment_id">
    <h3 class="modal-title" v-if="currentAssignmentId && document" slot="title">
      <span ref="documentName">{{ document.name }}</span>
      <br />
      <small ref="employeeName">{{ pageContext.employee.name }}</small>
    </h3>
    <v-wait :visible="isLoading">
      <spinner slot="waiting" />
      <div class="alert alert-warning" v-if="!hasCurrentSubmission">No current submission</div>
      <historical-submissions-list
        :has-indicators="hasCurrentSubmission"
        :items="submissions"
        :visible-submission-id="visibleSubmissionId"
        v-if="currentAssignmentId"
        @setAsCurrent="handleSetAsCurrent"
      />
    </v-wait>
  </slideout-panel>
</template>

<script>
  import Spinner from 'components/common/Spinner'
  import SlideoutPanel from 'components/common/SlideoutPanel'
  import HistoricalSubmissionsList from 'components/employees/document_assignments/HistoricalSubmissionsList'

  import { mapGetters, mapActions, mapMutations } from 'vuex'
  import * as mutate from 'vuex/mutation_types'
  import Locale from 'lib/locale'
  import Util from 'lib/util'

  export default {
    name: 'historical-submissions-container',
    components: {
      SlideoutPanel,
      Spinner,
      HistoricalSubmissionsList
    },
    computed: {
      submissions() {
        if (!this.assignment) {
          return []
        }

        // Get submission IDs for historical DocumentSubmissions
        const ids = this.assignment.historical_submission_ids

        // Append ID for current DocumentSubmissions, if present
        if(ids && this.assignment.current_submission_id) {
          ids.push(this.assignment.current_submission_id)
        }

        return ids ? this.getSubmissions(ids) : []
      },

      assignment() {
        return this.getAssignment(this.currentAssignmentId)
      },

      document() {
        if (!this.assignment) {
          return null
        }

        return this.getDocument(this.assignment.document_id)
      },

      currentAssignmentId() {
        return this.pageContext.show_historical_submissions_assignment_id
      },

      visibleSubmissionId() {
        return this.pageContext.visible_submission_id
      },

      // Whether or not this assignment has a current_submission_id
      //
      // Returns - Boolean
      hasCurrentSubmission() {
        if (!this.assignment) {
          return false
        }

        return (this.assignment.current_submission_id != null)
      },

      ...mapGetters({
        getAssignment: 'document_assignments/get',
        getDocument: 'documents/get',
        getSubmissions: 'document_submissions/getBatch',
        getSubmission: 'document_submissions/get',
        pageContext: 'pageContext'
      })
    },

    data () {
      return {
        isLoading: false
      }
    },

    methods: {
      handleSetAsCurrent(submission) {
        this.isLoading = true

        this.setAsCurrent({
          document_assignment: this.assignment,
          new_current_submission: submission,
        }).then(() => {
          Util.showFlashNotice(Locale.t('assignments.setAsCurrent.success', this.document.name))
        }).catch(() => {
          // Generic error modal displayed automatically.
        }).finally(() => {
          this.isLoading = false
        })
      },

      async fetchSubmissions () {
        this.isLoading = true

        const promises = [
          this.fetchHistoricalSubmissions(this.assignment) // Fetch historical submissions
        ]

        // Fetch the current submission if necessary and not already stored
        if(this.assignment && this.assignment.current_submission_id && !this.getSubmission(this.assignment.current_submission_id)) {
          promises.push(
            this.fetchSubmission(this.assignment.current_submission_id)
          )
        }

        await Promise.all(promises) // wait until everything has loaded
          .catch(() => {
            // Error! Close the slideout
            this.deletePageContextKey('show_historical_submissions_assignment_id')
          })
          .finally(() => {
            this.isLoading = false
          })
      },

      ...mapMutations({
        deletePageContextKey: mutate.DELETE_PAGE_CONTEXT_KEY
      }),

      ...mapActions({
        fetchHistoricalSubmissions: 'document_assignments/fetchHistoricalSubmissions',
        fetchSubmission: 'document_submissions/fetchSubmission',
        setAsCurrent: 'document_assignments/setAsCurrent'
      })
    },

    watch: {
      assignment (assignment, oldAssignment) {
        if(assignment && assignment !== oldAssignment) {
          this.fetchSubmissions()
        }
      }
    }
  }
</script>
