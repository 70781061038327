export default Marionette.Behavior.extend({
  defaults: {
    enabled: true,      // if disabled, row toggling will be disabled but checkbox will still be visible.
    visible: true,      // if false, checkbox will be hidden and row toggling will automatically be disabled.
  },

  events: {
    "change @ui.toggleCheckbox": "toggleCheckboxChanged",
  },

  ui: {
    checkboxWrapper: ".a4s-checkbox",
    toggleCheckbox: "input[type='checkbox']",
  },

  initialize: function() {
    this.listenTo(this.view, 'row:selectMe', this.selectRow)
    this.listenTo(this.view, 'row:unselectMe', this.unselectRow)
  },

  onRender: function() {
    this.ui.checkboxWrapper.toggleClass('hidden', !this.isVisible())
  },

  toggleCheckboxChanged: function(e) {
    e.stopPropagation()
    this.toggleRow()
  },

  selectRow: function() {
    if (this.isEnabled()) {
      App.Util.DataTables.toggleRowChecked(this.$el, true)
      this.view.triggerMethod('row:selection:changed', true)
    }
  },

  unselectRow: function() {
    if (this.isEnabled()) {
      App.Util.DataTables.toggleRowChecked(this.$el, false)
      this.view.triggerMethod('row:selection:changed', false)
    }
  },

  toggleRow: function() {
    if (this.isEnabled()) {
      App.Util.DataTables.toggleRowChecked(this.$el)
      this.view.triggerMethod('row:selection:changed', this.ui.toggleCheckbox.is(':checked'))
    }
  },

  onBeforeDestroy: function() {
    this.stopListening(this.view)
  },

  isEnabled: function() {
    if (!this.isVisible()) {
      return false
    }

    var enabledVal = this.getOption('enabled')
    if (_.isFunction(enabledVal)) {
      enabledVal = enabledVal.call(this.view)
    }

    return enabledVal
  },

  isVisible: function() {
    var visibleVal = this.getOption('visible')
    if (_.isFunction(visibleVal)) {
      visibleVal = visibleVal.call(this.view)
    }

    return visibleVal
  }
})
