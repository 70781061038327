var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [
      _c("label", { staticClass: "small" }, [
        _vm._v("\n      " + _vm._s(_vm.filter.title) + "\n    "),
      ]),
      _vm._v(" "),
      _vm.filter.help_text
        ? _c("span", [
            _c(
              "a",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip.html",
                    value: _vm.filter.help_text,
                    expression: "filter.help_text",
                    modifiers: { html: true },
                  },
                ],
                staticClass: "help-popover ml-1",
                attrs: { href: "javascript:void(0)" },
              },
              [_c("i", { staticClass: "fa fa-exclamation-circle" })]
            ),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.value,
            expression: "value",
          },
        ],
        staticClass: "form-control",
        on: {
          change: function ($event) {
            var $$selectedVal = Array.prototype.filter
              .call($event.target.options, function (o) {
                return o.selected
              })
              .map(function (o) {
                var val = "_value" in o ? o._value : o.value
                return val
              })
            _vm.value = $event.target.multiple
              ? $$selectedVal
              : $$selectedVal[0]
          },
        },
      },
      _vm._l(_vm.filter.options, function (option, index) {
        return _c("option", { key: index, domProps: { value: option.value } }, [
          _vm._v("\n      " + _vm._s(option.label) + "\n    "),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }