var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeader", { attrs: { title: "I-9 Forms", lead: _vm.headerLead } }),
      _vm._v(" "),
      _c("I9InboxFilterNav", {
        staticClass: "pt-2",
        attrs: { filter: _vm.filter },
        on: { change: _vm.handleFilterChange },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex flex-column flex-md-row" },
        [
          _c("I9InboxExpirationFilterToggle", {
            staticClass: "mb-3 mr-3",
            attrs: { filter: _vm.filter, expiration: _vm.expiration },
            on: { selected: _vm.handleExpirationFilterChange },
          }),
          _vm._v(" "),
          _vm.currentAccount.remoteCountersignOn
            ? _c("I9InboxStatusFilter", {
                staticClass: "mb-3 mr-3",
                attrs: { filter: _vm.filter, status: _vm.status },
                on: { selected: _vm.handleStatusFilterChange },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.currentAccount.documentVerificationEnabled
            ? _c("I9InboxDocumentVerificationFilter", {
                ref: "DocumentVerificationFilter",
                staticClass: "mb-3",
                attrs: {
                  filter: _vm.filter,
                  status: _vm.document_verification_status,
                },
                on: { selected: _vm.handleDocVerificationFilterChange },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("I9InboxEmployerInstructions", {
        attrs: {
          filter: _vm.filter,
          is_i9rc_on: _vm.currentAccount.remoteCountersignOn,
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "clearfix" },
        [
          _vm.shouldShowActionButtons
            ? _c("I9InboxActionButtons", {
                attrs: {
                  isLoading: _vm.isLoading,
                  selectionCount: _vm.selectionCount,
                },
                on: {
                  signSection2: _vm.signSection2,
                  editSection2: _vm.editSection2,
                  requestNewI9: _vm.requestNewI9,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("WbSearchInput", {
            ref: "search",
            staticClass: "pull-right",
            attrs: {
              label: null,
              placeholder: "Search",
              autofocus: true,
              value: _vm.query,
              throttle: true,
            },
            on: { input: _vm.handleSearchInput },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isLoading
        ? _c("Spinner", { ref: "loader" })
        : [
            _c("I9InboxAssignmentTable", {
              attrs: {
                assignments: _vm.assignments,
                sort: _vm.sort,
                shouldShowVerificationStatus: _vm.shouldShowVerificationStatus,
              },
              on: { sort: _vm.handleSortUpdate },
            }),
            _vm._v(" "),
            _vm.shouldShowPagination
              ? _c("WbListPaginationFooter", {
                  ref: "pagination",
                  attrs: {
                    previousPage: _vm.previousPage,
                    nextPage: _vm.nextPage,
                    lastPage: _vm.lastPage,
                    totalItems: _vm.totalItems,
                    indexStart: _vm.indexStart,
                    indexEnd: _vm.indexEnd,
                  },
                  on: { pageChange: _vm.handlePageChange },
                })
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }