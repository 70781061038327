var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "wb-modal",
        {
          ref: "modal",
          attrs: {
            watchPageContextVariable: "activate_employee_ids",
            title: "Activate Staff",
            locked: _vm.$wait.is("employees_change_status"),
          },
          on: { modalHidden: _vm.handleModalHidden },
        },
        [
          _c(
            "v-wait",
            { attrs: { for: "employees_change_status" } },
            [
              _c("spinner", {
                attrs: { slot: "waiting", showLongWaitMessage: true },
                slot: "waiting",
              }),
              _vm._v(" "),
              _c("p", { ref: "activationNotice" }, [
                _vm._v(
                  _vm._s(_vm.pluralizedStaffMembers) + " will be activated."
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group mt-4" }, [
                _c(
                  "label",
                  { staticClass: "mb-0", attrs: { for: "email-message" } },
                  [_c("strong", [_vm._v("Email message (optional)")])]
                ),
                _vm._v(" "),
                _c("p", { staticClass: "help-block" }, [
                  _vm._v(
                    "If you would like to enter an optional message to go out with the email, enter it here:"
                  ),
                ]),
                _vm._v(" "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.message,
                      expression: "message",
                    },
                  ],
                  ref: "emailMessageTextarea",
                  staticClass: "form-control w-100",
                  attrs: { id: "email-message", rows: "4", maxlength: "1000" },
                  domProps: { value: _vm.message },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.message = $event.target.value
                    },
                  },
                }),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "button",
                {
                  ref: "cancelButton",
                  staticClass: "btn btn-default",
                  attrs: { disabled: _vm.$wait.is("employees_change_status") },
                  on: { click: _vm.hide },
                },
                [_vm._v("\n        Cancel\n      ")]
              ),
              _vm._v(" "),
              _c(
                "ladda-button",
                { ref: "submitButton", on: { click: _vm.submit } },
                [_vm._v("\n        Submit\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("bulk-action-list-alert", {
        ref: "alert",
        attrs: {
          actionMethod: function (employee) {
            return employee.employment_status == "inactive"
          },
          permissionName: "change_status",
          permissionModule: "employees",
          collectionModule: "employees",
          actionErrorMessage:
            "These staff members <strong>cannot be activated</strong> because they are already active (or unemployed and require rehiring):",
          displayNameLookupFn: function (employee) {
            return employee.first_name + " " + employee.last_name
          },
          collectionViewName: this.collectionViewName,
        },
        on: {
          continue: _vm.openModal,
          cancelled: function ($event) {
            return _vm.$emit("hidden")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }