var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "navbar-header" }, [
    _c(
      "button",
      {
        staticClass: "navbar-toggle",
        attrs: {
          type: "button",
          "data-toggle": "collapse",
          "data-target": ".navbar-main-collapse",
        },
      },
      [
        _c("span", { staticClass: "sr-only" }, [_vm._v("Toggle navigation")]),
        _vm._v(" "),
        _c("WbIcon", { attrs: { type: "bars" } }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "a",
      {
        staticClass: "navbar-brand d-flex align-items-center",
        attrs: { href: _vm.$routes.staff_dashboard_path },
      },
      [
        _c("img", {
          staticClass: "logo",
          attrs: { src: _vm.$routes.Images.logo, alt: "Workbright Logo" },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }