var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form-field",
    {
      attrs: {
        label: _vm.label,
        name: _vm.name,
        helpPopoverText: _vm.helpPopoverText,
        orientation: _vm.orientation,
        required: _vm.required,
        errors: _vm.errors,
        hint: _vm.hint,
      },
    },
    [
      _c("div", { staticClass: "input-wrapper" }, [
        _c("div", { staticClass: "input-group" }, [
          _c("span", { staticClass: "input-group-addon" }, [
            _c("i", { staticClass: "fa fa-calendar" }),
          ]),
          _vm._v(" "),
          _c("div", { class: _vm.fieldWidth }, [
            _c("input", {
              ref: "input",
              staticClass: "form-control",
              attrs: {
                name: _vm.name,
                type: "text",
                placeholder: _vm.placeholder,
                autocomplete: "off",
              },
              domProps: { value: _vm.value },
              on: {
                change: function ($event) {
                  return _vm.$emit("input", $event.target.value)
                },
              },
            }),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }