var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "policy-generator" },
    [
      _c(
        "WbWizard",
        {
          attrs: { loading: _vm.isLoading, nextLabel: _vm.nextLabel },
          on: { next: _vm.nextStep, previous: _vm.handlePrevStep },
        },
        [
          _c(
            "template",
            { slot: "steps" },
            [
              _c(
                "WbOrderedList",
                _vm._l(_vm.stepList, function (step, index) {
                  return _c(
                    "WbOrderedListItem",
                    { key: index, attrs: { active: step.active } },
                    [_vm._v("\n          " + _vm._s(step.label) + "\n        ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "messages" },
            [
              _vm.shoudlShowGenericFormError
                ? _c("AlertMessage", {
                    attrs: { message: _vm.$locale.t("errors.form.generic") },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "body" },
            [
              _c(_vm.currentStepComponentName, {
                ref: "currentStep",
                tag: "component",
                attrs: {
                  policy: _vm.localPolicy,
                  managedPolicy: true,
                  isEditing: _vm.isEditing,
                },
                on: { skip: _vm.skipStep },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "WbDialog",
        {
          staticClass: "text-left",
          attrs: { title: "Confirm Update", open: _vm.isConfirming },
          on: { cancelled: _vm.cancelConfirm, confirmed: _vm.update },
        },
        [
          _c("p", [
            _vm._v(
              "This will alter the permissions of " +
                _vm._s(_vm.localPolicy.count) +
                " Admin Users. This change will be effective immediately. Are you sure you'd like to modify this policy?"
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }