<template>
  <form-field
    :label="label"
    :name="name"
    :helpPopoverText="helpPopoverText"
    :orientation="orientation"
    :errors="errors"
    :required="required"
    :hint="hint"
    :min="min"
  >
    <div :class="[{ 'input-group': addon || addonPre }, 'short-field']">
      <span class="input-group-addon" v-if="addonPre">
        {{ addonPre }}
      </span>
      <input class="form-control"
        type="number"
        :name="name"
        :id="name"
        :placeholder="placeholderValue"
        :value="value"
        :min="min"
        :disabled="disabled"
        @input="$emit('input', $event.target.value)"
      />
      <span class="input-group-addon" v-if="addon">
        {{ addon }}
      </span>
    </div>
  </form-field>
</template>

<script>
  import FormField from 'components/common/FormField'
  import FormFieldConcern from 'mixins/FormFieldConcern'

  export default {
    name: 'form-field-number',
    mixins: [FormFieldConcern],
    components: {
      FormField
    },
    props: {
      // Addon after
      addon: {
        type: String,
        required: false
      },
      // Addon before
      addonPre: {
        type: String,
        required: false
      },

      value: {
        type: [String, Number],
        required: false
      },

      min: {
        required: false
      },

      disabled: {
        required: false,
        default: false
      }
    }
  }
</script>
