<template>
  <div>
    <adp-integration-modal :employeeId="employeeId" />
    <wb-button
      @click="openModal"
      label="Sync to ADP® Workforce Now"
      context="primary"
    >
      <template v-slot:prepend>
        <i class="fa fa-upload"></i>
      </template>
    </wb-button>
  </div>
</template>

<script>
  import { mapMutations } from 'vuex'
  import { SET_PAGE_CONTEXT_KEYS } from 'vuex/mutation_types'
  import AdpIntegrationModal from 'components/employees/integrations/AdpIntegrationModal.vue'
  import WbButton from 'components/common/WbButton'

  export default {
    name: 'adp-integration-controls',
    components: {
      WbButton,
      AdpIntegrationModal
    },
    props: {
      employeeId: {
        requred: true,
        type: Number
      }
    },
    methods: {
      openModal() {
        this.setPageContextKeys({ show_adp_integration_modal: true })
      },

      ...mapMutations({
        setPageContextKeys: SET_PAGE_CONTEXT_KEYS
      })
    }
  }
</script>
