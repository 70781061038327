var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasSufficientData
    ? _c(
        "div",
        { ref: "panel", staticClass: "panel panel-default fields-panel" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "table",
            { staticClass: "table table-responsive panel-info-table" },
            [
              _c(
                "tbody",
                _vm._l(_vm.responses, function (item, index) {
                  return _c("tr", { key: index }, [
                    _c("th", { attrs: { scope: "row" } }, [
                      _vm._v(
                        "\n          " + _vm._s(item.label) + "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n          " + _vm._s(item.value) + "\n        "
                      ),
                    ]),
                  ])
                }),
                0
              ),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel-heading" }, [
      _c("h4", { staticClass: "panel-title" }, [_vm._v("Form Responses")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }