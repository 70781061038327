<template>
  <dl
    class="dl"
    :class="classNames"
  >
    <dt>
      <slot name="term" />
    </dt>
    <dd>
      <slot name="definition" />
    </dd>
  </dl>
</template>
<script>
  export default {
    props: {
      divided: {
        type: Boolean,
        default: false
      },
      inline: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      classNames () {
        const val = []

        if (this.divided) {
          val.push('dl-divided')
        }
        if (this.inline) {
          val.push('dl-inline')
        }

        return val
      }
    }
  }
</script>
