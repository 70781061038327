<template>
  <div>
    <RadioToggle
      title=""
      :class="radioToggleClass"
      :disabled="disabled"
      :options="options"
      :value="radioToggleValue"
      @selected="changeSelection"
    />
    <input
      :name="name"
      type="hidden"
      :value="radioToggleValue"
    />
  </div>
</template>

<script>
  import RadioToggle from "components/common/RadioToggle"
  import { mapGetters } from 'vuex'

  export default {
    name: "employee-notification-methods-toggle",
    components: { RadioToggle },
    props: {
      disabled: {
        type: Boolean
      },
      type: {
        type: String,
        required: true
      },
      name: {
        type: String,
        default: 'employee_notification_methods'
      },
      value: {
        required: true,
        type: Array,
        default: () => []
      }
    },

    computed: {
      radioToggleValue() {
        return this.value[0] || ""
      },
      radioToggleClass() {
        return `wb-${this.type}-employee-notification-methods-toggle`
      },
      fieldName() {
        return `${this.type}_notification_methods`
      },
      options() {
        const notificationTypes = this.currentAccount.notificationTypes || []
        return notificationTypes.map(([label, value]) => ({ label, value }))
      },
      successFlashNotice() {
        return this.$locale.t(`employees.${this.type}NotificationMethods.update.success`)
      },

      ...mapGetters({
        currentAccount: 'account/current'
      })
    },
    methods: {
      changeSelection(value) {
        if(this.isValueSelected(value)){
          this.update([])
        } else {
          this.update([value])
        }
      },
      isValueSelected(lookup_value) {
        return this.value.includes(lookup_value)
      },
      update(value) {
        this.$emit("input", value)
      }
    }
  }
</script>
