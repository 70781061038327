var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.internalDocuments.length
      ? _c("div", { ref: "internalForms" }, [
          _c("p", [
            _vm.employees.length > 1
              ? _c("span", { staticClass: "text-strong" }, [
                  _vm._v("Internal Forms:"),
                ])
              : _vm._e(),
            _vm._v(
              "\n      Administrators will be expected to upload new submissions for\n      "
            ),
            _c(
              "a",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip.bottom",
                    value: _vm.internalDocumentNames,
                    expression: "internalDocumentNames",
                    modifiers: { bottom: true },
                  },
                ],
                attrs: { href: "javascript:void(0)" },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.pluralizedInternalForms) +
                    ".\n      "
                ),
              ]
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.visibleDocuments.length && _vm.notifiedEmployees.length
      ? _c("p", { ref: "notifiedVisible" }, [
          _vm.employees.length > 1
            ? _c("span", { staticClass: "text-strong" }, [
                _vm._v("Will be notified:"),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.notifiedEmployees.length == 1
            ? _c("span", { ref: "notifiedEmployees" }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.notifiedEmployeeNames) + "\n    "
                ),
              ])
            : _c("span", [
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip.bottom",
                        value: _vm.notifiedEmployeeNames,
                        expression: "notifiedEmployeeNames",
                        modifiers: { bottom: true },
                      },
                    ],
                    ref: "notifiedCount",
                    attrs: { href: "javascript:void(0)" },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.pluralizedNotifiedStaffMembers) +
                        "\n      "
                    ),
                  ]
                ),
              ]),
          _vm._v(
            "\n    will receive a notification informing them that they need to complete\n    "
          ),
          _c(
            "a",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip.bottom",
                  value: _vm.visibleDocumentNames,
                  expression: "visibleDocumentNames",
                  modifiers: { bottom: true },
                },
              ],
              ref: "notifiedVisibleForms",
              attrs: { href: "javascript:void(0)" },
            },
            [_vm._v("\n      " + _vm._s(_vm.pluralizedForms) + ".\n    ")]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.visibleDocuments.length && _vm.unnotifiedEmployees.length
      ? _c("div", [
          _c("p", [
            _vm.employees.length > 1
              ? _c("span", { staticClass: "text-strong" }, [
                  _vm._v("Not notified now:"),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.unnotifiedEmployees.length == 1
              ? _c("span", { ref: "unnotifiedNames" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.unnotifiedEmployeeNames) +
                      "\n      "
                  ),
                ])
              : _c("span", { ref: "unnotifiedCount" }, [
                  _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.bottom",
                          value: _vm.unnotifiedEmployeeNames,
                          expression: "unnotifiedEmployeeNames",
                          modifiers: { bottom: true },
                        },
                      ],
                      attrs: { href: "javascript:void(0)" },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.pluralizedUnnotifiedStaffMembers) +
                          "\n        "
                      ),
                    ]
                  ),
                ]),
            _vm._v(
              "\n      will not be notified because they are not employed, or their Notification Start Date is blank or set in the future.\n    "
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.pendingAssignments.length > 0
      ? _c("p", { ref: "deleteWarning" }, [
          _c("i", { staticClass: "fa fa-exclamation-triangle text-danger" }),
          _vm._v(
            "\n    " + _vm._s(_vm.pluralizedPendingSubmissions) + "\n    "
          ),
          _c(
            "a",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip.html",
                  value:
                    "Pending submissions are deleted when new submissions are requested. If you would like to keep these, approve or reject them first.",
                  expression:
                    "'Pending submissions are deleted when new submissions are requested. If you would like to keep these, approve or reject them first.'",
                  modifiers: { html: true },
                },
              ],
              attrs: { href: "javascript:void(0)" },
            },
            [_vm._v("\n      will be deleted.\n    ")]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.visibleDocuments.length && _vm.notifiedEmployees.length
      ? _c("div", [
          _c("hr"),
          _vm._v(" "),
          _c("div", { staticClass: "form-group mt-4" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("p", { staticClass: "help-block" }, [
              _vm._v(
                "\n        If you would like to enter an optional message to go out with the email, enter it here:\n      "
              ),
            ]),
            _vm._v(" "),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.emailMessage,
                  expression: "emailMessage",
                },
              ],
              ref: "emailMessageTextarea",
              staticClass: "form-control w-100",
              attrs: {
                id: "email-message",
                rows: "4",
                maxlength: "1000",
                placeholder: "We'd like you to redo these forms because...",
              },
              domProps: { value: _vm.emailMessage },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.emailMessage = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c("p", { staticClass: "help-block" }, [
              _vm._v(
                "\n        Emails are delayed up to 15 minutes before sending.\n      "
              ),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "mb-0", attrs: { for: "email-message" } },
      [_c("strong", [_vm._v(" Email message (optional)")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }