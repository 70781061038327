var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ListItem",
    {
      directives: [
        {
          name: "highlight-on-change",
          rawName: "v-highlight-on-change",
          value: _vm.everifyCase,
          expression: "everifyCase",
        },
      ],
      attrs: {
        collectionViewName: _vm.collectionViewName,
        item: _vm.everifyCase.id,
      },
      on: { click: _vm.handleViewCaseClick },
    },
    [
      _c(
        "div",
        { staticClass: "wb-list__content" },
        [
          _c("EverifyCaseStatusIndicator", {
            attrs: { everifyCase: _vm.everifyCase, tooltip: true },
          }),
          _vm._v(" "),
          _vm.showDeleteCaseButton
            ? _c(
                "div",
                { staticClass: "mr-2" },
                [
                  _c("WbButton", {
                    attrs: {
                      context: "danger",
                      isLoading: _vm.isDeleteCaseButtonLoading,
                      label: "Delete Case",
                      size: "xs",
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.handleDeleteCaseClick($event)
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "wb-list__title mr-auto my-0" }, [
            _c("span", { staticClass: "name" }, [
              _vm._v("\n        " + _vm._s(_vm.employeeFullName) + "\n      "),
            ]),
            _vm._v(" "),
            _vm.caseClosed
              ? _c("span", { staticClass: "label label-default ml-2" }, [
                  _vm._v("\n        Closed\n      "),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "wb-list__subtitle mr-auto my-0" }, [
              _vm._v(
                "\n        Case ID: " +
                  _vm._s(_vm.everifyCase.case_id) +
                  "\n        "
              ),
              _vm.everifyCase.case_created_at
                ? _c(
                    "span",
                    [
                      _vm._v("\n          ⋅ Created:\n          "),
                      _c("WbSimpleDateTime", {
                        attrs: { value: _vm.everifyCase.case_created_at },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "wb-list__column-timestamp" },
            [
              _vm.caseClosed
                ? _c("WbSimpleDateTime", {
                    attrs: {
                      value: _vm.caseClosed,
                      "tooltip-options": _vm.caseClosedTooltip,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.caseClosed
                ? _c("em", { staticClass: "everify-case-open" }, [
                    _vm._v(
                      "\n        " + _vm._s(_vm.caseStatusTitle) + "\n      "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _vm.shouldShowDeadline
            ? _c(
                "dl",
                { ref: "deadline", staticClass: "wb-list__column-groups my-0" },
                [
                  _c("dt", [
                    _vm._v(
                      "\n        " + _vm._s(_vm.deadlineLabel) + "\n      "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("dd", [
                    _vm._v(
                      "\n        " + _vm._s(_vm.caseDeadline) + "\n      "
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "secondaryActionsMenuItems" },
        [
          !_vm.everifyFailed
            ? _c("DropdownListItem", {
                attrs: { title: "View Case" },
                on: { click: _vm.handleViewCaseClick },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("DropdownListItem", {
            attrs: {
              title: "View I-9",
              href: _vm.linkToI9,
              disabled: !_vm.everifyCase.submission_id,
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }