import PubSubUtil from 'lib/pub_sub_wrapped_action'
import Locale from 'lib/locale'
import Vue from 'vue'
import { ajax } from 'jquery'
import { getRoute } from 'lib/routes'

function state() {
  return {}
};

export const mutationTypes = {
  SET: 'SET',
  ERROR: 'ERROR',
  FETCHING: 'FETCHING'
}

const mutations = {
  [mutationTypes.SET](state, payload) {
    const source = payload.source
    Vue.set(state, source, payload.data)
  },

  [mutationTypes.ERROR](state, payload) {
    const source = payload.source
    Vue.set(state, source, { errorMsg: payload.errMsg })
  },

  [mutationTypes.FETCHING](state, payload) {
    const source = payload.source
    const data = state[source] || {}
    data.fetching = payload.fetching
    Vue.set(state, source, data)
  }
}

const getters = {
  getSource: (state) => (source) => {
    return state[source] || {}
  }
}

const actions = {
  fetch: ({state, commit}, { source, title, bypassCache }) => {
    // Already fetching for this source? Bail.
    if((state[source] || {})['fetching']) { return }

    commit(mutationTypes.FETCHING, { source, fetching: true })

    return PubSubUtil.pubSubWrappedAction(source, (channelName, reject) => {
      ajax({
        method: 'POST',
        url: getRoute('query_dashboard_widgets_path'),
        data: {
          source: source,
          bypass_cache: bypassCache || false,
          cache_duration: 84600,
          pubsub_channel: channelName
        },
      }).fail(xhr => reject(xhr))
    },
    (response, resolve, _reject) => {
      commit(mutationTypes.SET, {
        source: source,
        data: {
          chartData: response.widget_payload,
          lastUpdated: response.last_updated
        }
      })
      resolve()
      commit(mutationTypes.FETCHING, { source, fetching: false })
    }).catch(_xhr => {
      const errMsg = Locale.t('errors.ajax.fetch', title)
      commit(mutationTypes.ERROR, { source, errMsg })
      commit(mutationTypes.FETCHING, { source, fetching: false })
    })
  }
}

export default {
  namespaced: true,
  mutationTypes,
  state,
  actions,
  mutations,
  getters
}
