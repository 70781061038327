<template>
  <AlertMessage
    title="Warning: E-Verify Demo Enabled"
    message="This account has been linked to the E-Verify Web Services Demo Portal. All cases listed are for testing purposes only and do not represent valid E-Verify results."
  />
</template>

<script>
  import AlertMessage from 'components/common/AlertMessage'

  export default {
    name: 'everify-staging-alert-message',
    components: {
      AlertMessage
    }
  }
</script>
