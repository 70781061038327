import Animations from 'lib/util/animations.js'

export default Marionette.ItemView.extend({
  tagName: 'tr',
  className: function() {
    let classes = ''
    if(this.model.get('archived')){ classes += 'archived' }
    return classes
  },
  template: "#employee-group-tmpl",
  triggers: {
    'click .js-remove-employee-group-action': {
      event: "employee:group:delete",
      stopPropagation: false,
      preventDefault: true
    },
  },

  // FIXME: Behaviorize this
  _removeElement: function() {
    if (Marionette.pageIsChanging)
      return

    Animations.scale(this.$el, false, {
      callback: function() {
        this.$el.remove()
      }.bind(this)
    })
  },
})
