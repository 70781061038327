import DataTables from 'lib/util/datatables'

export default Backbone.Marionette.CollectionView.extend({
  ui: {
    rowSelectorAll: '.js-row-selector-all',
    rowSelectorNone: '.js-row-selector-none'
  },

  events: {
    'click @ui.rowSelectorAll': 'selectAllRows',
    'click @ui.rowSelectorNone': 'clearRowSelections',
  },

  constructor: function(options) {
    var options = _.extend({
      prerendered: true,
      template: false,
      emptyViewOptions: { message: 'No items to show', colspan: 99 },
    }, options)

    if (options.$tableEl) {
      this.el = options.$tableEl[0]
      this.$tableEl = this.$el = options.$tableEl
    }

    Marionette.CollectionView.prototype.constructor.call(this, options)
  },

  childViewOptions: function(model, index) {
    // ensure ItemView gets created bound to existing element in DOM
    var element = this.$el.find('tbody').children().get(index)
    return { el: element, itemIndex: index }
  },

  isDataTable: function() {
    if (this.$tableEl && this.$tableEl.length > 0)
      return $.fn.DataTable.fnIsDataTable(this.$tableEl.get(0))
    else
      return false
  },

  getDataTableObject: function() {
    if (this.isDataTable())
      return this.$tableEl.DataTable({ retrieve: true })
    else
      return null
  },

  getDataTableEl$: function() {
    if (this.isDataTable())
      return this.$tableEl.dataTable({ retrieve: true })
    else
      return null
  },

  getDataTableWrapperSelector: function() {
    return "#" + this.$tableEl[0].id + "_wrapper"
  },

  // NOTE: The default DataTables `dom` layout does not specify a middle header. To create a table with a middle header, use:
  //    dom: $.fn.dataTable.defaults._domWithMiddleHeader
  getDataTableHeaderMiddleSelector: function() {
    return this.getDataTableWrapperSelector() + " .dt-header-middle"
  },

  getDataTableToolbarSelector: function() {
    return this.getDataTableWrapperSelector() + " .dt-toolbar"
  },

  getDataTableHeaderRightSelector: function() {
    return this.getDataTableWrapperSelector() + " .dt-header-right"
  },

  beforeAttachToTable: function($tableEl) {
    this._ensureViewIsIntact()
    this.triggerMethod('before:render', this)

    // TODO: If $tableEl is passed in via constructor above, this is redundant; we could clean all of this up by refactoring
    // the users of PrerenderedTableCollectionView to always pass in the $tableEl in the constructor.
    if (!this.$tableEl) {
      this.el = $tableEl[0]
      this.$tableEl = this.$el = $tableEl
    }

    this._isShown = true
    this.isBuffering = false
  },

  afterAttachToTable: function() {
    this.checkEmpty()

    // In the prototype constructor, we listen for 'render' once and call _initialEvents, so it is safe to just trigger
    // the render method here.
    this.triggerMethod('render', this)
  },

  // Override for no-op since the entire collection is already pre-rendered (e.g. a table full of data rendered by the server)
  render: function() {
    return this
  },

  // With an existing `collection` of models, this attaches each model to its corresponding row in the `$tableEl`. This
  // differs from the other `attach...` methods by ensuring that both the model in the collection and the row have the same
  // ID. 
  attachToDataTable: function($tableEl, collection) {
    this.beforeAttachToTable($tableEl)
    
    var ItemView = this.getChildView()
    this.collection = collection
    $tableEl.find("tbody > tr").each(function(index, rowEl) {
      var model = this.collection.get($(rowEl).data('id'))
      this.addChild(model, ItemView, index)
    }.bind(this))

    this.afterAttachToTable()
  },

  // Given an already-built-up collection of objects, attach it to an already-rendered table (datatable or otherwise)
  attachToTableWithCollection: function($tableEl, collection) {
    this.beforeAttachToTable($tableEl)

    this.collection = collection
    var ItemView = this.getChildView()
    var self = this
    $tableEl.find("tbody > tr").each(function(index, tableRowEl) {
      var model = self.collection.at(index)
      self.addChild(model, ItemView, index)
    })

    this.afterAttachToTable()
  },

  // Shortcut method when the table element has been previously passed in via constructor
  attach: function(CollectionType, createModelFunc) {
    this.attachToTable(this.$tableEl, CollectionType, createModelFunc)
  },

  // If the collection should be derived via a callback function (e.g. if the data is simple enough to read from 'data' attributes),
  // use this method to attach this CollectionView to an existing pre-rendered table in the DOM (DataTable or otherwise)
  attachToTable: function($tableEl, CollectionType, createModelFunc) {
    this.beforeAttachToTable($tableEl)

    this.collection = new CollectionType()
    var ItemView = this.getChildView()
    var self = this
    $tableEl.find("tbody > tr").each(function(index, tableRowEl) {
      var item = createModelFunc.call($(tableRowEl))
      self.collection.add(item)
      var model = self.collection.at(index) // just added from line above!
      self.addChild(model, ItemView, index)
    })

    this.afterAttachToTable()
  },

  // Override so that we don't unnecessarily call internal.attachHtml() method when we're just processing the initial
  // prerendered collection; if, however, we add a non-prerendered view, we can safely append the HTML. In this way,
  // the 'prerendered' option can also be used as a bypass for custom appending code.
  // An additional special case has been added for '_needsRenderOnce'. See comments in childViewOptions().
  renderChildView: function(view, index) {
    view.render()

    if (!Marionette.getOption(view, 'prerendered')) {
      this.attachHtml(this, view, index)
    }

    // Now that it's been renderer & appended, delete these flags
    if (Marionette.getOption(view, '_needsRenderOnce')) {
      delete view.options._needsRenderOnce
      view.options.prerendered = true // puts it in the prerendered category so it won't get removed on.destroy()
    }

    return view
  },

  // Built-in functionality to fade out and remove the row using datatable.fnDeleteRow if it's a datatable, or
  // just a $el.remove if not.
  onChildviewModelDestroyed: function(view, model) {
    // Obviously, if its not a prerendered view – don't do this.
    if (!Marionette.getOption(view, 'prerendered'))
      return

    var self = this
    view.$el.fadeOut(function(){
      if (self.isDataTable()) {
        self.getDataTableObject().row(view.$el).remove().draw()
      } else {
        view.$el.remove()
      }
    })
  },

  // // REVIEW: Overriding this so that emptyViewOptions gets passed into the model, so custom messaging can be used. This may
  // // not be the cleanest way to get custom messaging into the empty view, so I'm flaggin this for potential review later on.
  // // Since it's not super-critical functionality to collectionviews, I'm not worried about it.
  // showEmptyView: function(){
  //   var emptyView = this.getEmptyView();
  //   if (emptyView && !this._showingEmptyView){
  //     this._showingEmptyView = true;
  //     var model = new Backbone.Model(Marionette.getOption(this, "emptyViewOptions"));
  //     this.addChild(model, emptyView, 0);
  //   }
  // },

  selectAllRows: function() {
    this.children.each(function(child){
      // Only select rows that are visible
      if (child.$el.is(":visible")) {
        child.trigger('row:selectMe')
      }
    })
  },

  clearRowSelections: function() {
    this.children.each(function(child){
      child.trigger('row:unselectMe')
    })
  },

  getSelectionCount: function() {
    return this.children.filter(function(child) {
        return DataTables.isRowChecked(child.$el)
      }).length
  },

  // RETURNS: models of selected rows in table
  getSelections: function() {
    return _(this.children.filter(function(child) {
        return DataTables.isRowChecked(child.$el)
      }).map(function(child) {
        return child.model
      }))
  },

  getSelectionIDs: function() {
    if (this.getSelectionCount() > 0)
      return this.getSelections().pluck('id')
    else
      return null
  },

  onChildviewRowSelectionChanged: function() {
    this.triggerMethod('rows:selection:changed')
  }
})
