var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "WbPanel",
    {
      ref: "featureRequestPanel",
      attrs: { title: _vm.title, id: "feature-request-form" },
    },
    [
      _c("p", { ref: "description" }, [_vm._v(_vm._s(_vm.description))]),
      _vm._v(" "),
      _c("FormFieldInput", {
        ref: "email",
        staticClass: "form-group-tight give-room-above",
        attrs: {
          label: "Email Address",
          errors: _vm.errors,
          name: "email",
          type: "text",
        },
        model: {
          value: _vm.formData.email,
          callback: function ($$v) {
            _vm.$set(_vm.formData, "email", $$v)
          },
          expression: "formData.email",
        },
      }),
      _vm._v(" "),
      _c("FormFieldCheckbox", {
        ref: "authorizedAdmin",
        attrs: {
          label:
            "By checking this box, I acknowledge I am authorized to make changes to my WorkBright account on behalf of my organization.",
          errors: _vm.errors,
          name: "authorizedAdmin",
          gridColumns: "col-xs-12",
        },
        on: { input: _vm.handleCheckbox },
        model: {
          value: _vm.formData.authorizedAdmin,
          callback: function ($$v) {
            _vm.$set(_vm.formData, "authorizedAdmin", $$v)
          },
          expression: "formData.authorizedAdmin",
        },
      }),
      _vm._v(" "),
      _c("WbButton", {
        ref: "submit",
        attrs: { label: "Submit Request" },
        on: { click: _vm.submitEmail },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }