var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(_vm.componentName, {
        ref: "field",
        tag: "component",
        attrs: {
          label: _vm.field.label,
          name: _vm.name,
          errors: _vm.errors,
          options: _vm.options,
          orientation: "horizontal",
          value: _vm.value,
          hint: _vm.field.description,
          placeholder: _vm.placeholderValue,
          disabled: _vm.disabled,
          required: _vm.required,
        },
        on: { input: _vm.handleChange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }