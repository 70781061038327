var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("WbNestedSelect", {
    attrs: {
      placeholder: _vm.label,
      resetOnSelect: true,
      tree: _vm.representableFieldsTree,
      value: null,
      valueKey: "field_key",
      variant: "button",
    },
    on: { input: _vm.handleInput },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }