var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "image-upload",
      class: { "has-error": _vm.error },
      on: {
        dragover: function ($event) {
          $event.preventDefault()
          return _vm.handleDragOver($event)
        },
        dragleave: function ($event) {
          $event.preventDefault()
          return _vm.handleDragLeave($event)
        },
        drop: function ($event) {
          $event.preventDefault()
          return _vm.handleDrop($event)
        },
      },
    },
    [
      _vm.label
        ? _c("FormLabel", {
            ref: "label",
            attrs: { for: "file", label: _vm.label, required: _vm.required },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isLoadingExistingImage
        ? _c("Spinner", { ref: "spinner" })
        : [
            _c("input", {
              ref: "file",
              staticClass: "d-none",
              attrs: {
                type: "file",
                name: "file",
                accept: _vm.acceptedFileTypes,
              },
              on: { change: _vm.handleFileChange },
            }),
            _vm._v(" "),
            !_vm.isUploading
              ? [
                  _vm.hasFile
                    ? [
                        _vm.fileIsImage
                          ? [
                              !!_vm.cropper
                                ? _c("Toolbar", { ref: "fileEditing" }, [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "WbButton",
                                          {
                                            ref: "crop",
                                            attrs: {
                                              context: "default",
                                              size: "sm",
                                              tooltip: "Crop",
                                              tooltipPosition: "top",
                                            },
                                            on: { click: _vm.startCrop },
                                          },
                                          [
                                            _c("WbIcon", {
                                              staticClass: "mr-0",
                                              attrs: { type: "crop" },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "WbButton",
                                          {
                                            ref: "rotateClockwise",
                                            attrs: {
                                              context: "default",
                                              size: "sm",
                                              tooltip: "Rotate Clockwise",
                                              tooltipPosition: "top",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.rotate(1)
                                              },
                                            },
                                          },
                                          [
                                            _c("WbIcon", {
                                              staticClass: "mr-0",
                                              attrs: { type: "repeat" },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "WbButton",
                                          {
                                            ref: "rotateCounterclockwise",
                                            attrs: {
                                              context: "default",
                                              size: "sm",
                                              tooltip:
                                                "Rotate Counterclockwise",
                                              tooltipPosition: "top",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.rotate(-1)
                                              },
                                            },
                                          },
                                          [
                                            _c("WbIcon", {
                                              staticClass: "mr-0",
                                              attrs: { type: "undo" },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.isCropping
                                      ? _c(
                                          "div",
                                          { ref: "cropControls" },
                                          [
                                            _c(
                                              "WbButton",
                                              {
                                                ref: "applyCrop",
                                                attrs: {
                                                  size: "sm",
                                                  context: "success",
                                                  tooltip: "Apply Crop",
                                                  tooltipPosition: "top",
                                                },
                                                on: { click: _vm.applyCrop },
                                              },
                                              [
                                                _c("WbIcon", {
                                                  staticClass: "mr-0",
                                                  attrs: { type: "check" },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "WbButton",
                                              {
                                                ref: "cancelCrop",
                                                attrs: {
                                                  size: "sm",
                                                  context: "danger",
                                                  tooltip: "Cancel Crop",
                                                  tooltipPosition: "top",
                                                },
                                                on: { click: _vm.cancelCrop },
                                              },
                                              [
                                                _c("WbIcon", {
                                                  staticClass: "mr-0",
                                                  attrs: { type: "close" },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("img", {
                                ref: "image",
                                staticClass: "img-responsive",
                                attrs: { src: _vm.fileUrl },
                              }),
                            ]
                          : _c("div", { staticClass: "thumbnail" }, [
                              _c("img", { attrs: { src: _vm.thumbnail } }),
                            ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mt-3" },
                          [
                            _c(
                              "WbButton",
                              {
                                ref: "removeFile",
                                attrs: {
                                  tooltip: "Remove",
                                  context: "danger",
                                  size: "sm",
                                },
                                on: { click: _vm.handleRemoveFile },
                              },
                              [
                                _c("WbIcon", {
                                  staticClass: "mr-0",
                                  attrs: { type: "close" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _c(
                        "div",
                        { staticClass: "image-upload-instructions" },
                        [
                          _vm.isDragging
                            ? _c(
                                "div",
                                {
                                  ref: "dragIndicator",
                                  staticClass: "drag-indicator",
                                },
                                [
                                  _vm._v(
                                    "\n          Release to drop file here.\n        "
                                  ),
                                ]
                              )
                            : _vm.isWebcamMode
                            ? _c("WebcamImageCapture", {
                                ref: "webcam",
                                on: {
                                  cancel: _vm.handleWebcamCancel,
                                  photoTaken: _vm.handleWebcamImage,
                                },
                              })
                            : _c(
                                "div",
                                {
                                  ref: "fileDropper",
                                  staticClass: "text-center",
                                },
                                [
                                  _vm._v(
                                    "\n          Drop file here or\n          "
                                  ),
                                  _c(
                                    "u",
                                    {
                                      ref: "fileOpener",
                                      staticClass: "underline",
                                      attrs: { role: "button" },
                                      on: { click: _vm.handleOpenFile },
                                    },
                                    [
                                      _vm._v(
                                        "\n            click here\n          "
                                      ),
                                    ]
                                  ),
                                  _vm._v("\n          to upload.\n          "),
                                  _vm.takePhotoOnDesktopEnabled
                                    ? _c(
                                        "div",
                                        { staticClass: "mt-3" },
                                        [
                                          _c(
                                            "WbButton",
                                            {
                                              ref: "cameraSelect",
                                              attrs: {
                                                context: "default",
                                                size: "sm",
                                              },
                                              on: {
                                                click: _vm.handleCameraSelect,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n              Capture a Photo\n              "
                                              ),
                                              _c("WbIcon", {
                                                staticClass: "pl-1",
                                                attrs: { type: "camera" },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              ),
                        ],
                        1
                      ),
                  _vm._v(" "),
                  _vm.help
                    ? _c("div", {
                        ref: "help",
                        staticClass: "help-block",
                        domProps: { innerHTML: _vm._s(_vm.help) },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.error
                    ? _c("div", {
                        staticClass: "help-block",
                        domProps: { innerHTML: _vm._s(_vm.error) },
                      })
                    : _vm._e(),
                ]
              : _vm._e(),
          ],
      _vm._v(" "),
      _c("div", { ref: "dropzone" }, [
        _vm.isUploading
          ? _c(
              "div",
              { staticClass: "uploading-instructions text-center" },
              [
                _c("div", [_vm._v(_vm._s(_vm.uploadInstructions))]),
                _vm._v(" "),
                _c("div", { staticClass: "dz-progress progress mt-2" }, [
                  _c(
                    "div",
                    {
                      staticClass: "progress-bar progress-bar-success",
                      style: { width: _vm.uploadProgress + "%" },
                      attrs: { role: "progressbar" },
                    },
                    [_c("span", { staticClass: "progress-msg" })]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "WbButton",
                  {
                    attrs: { context: "warning" },
                    on: { click: _vm.cancelUpload },
                  },
                  [
                    _c("WbIcon", { attrs: { "icon-set": "fa", type: "ban" } }),
                    _vm._v("\n        Cancel\n      "),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }