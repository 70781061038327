import DocumentSubmissionsController from 'controllers/document_submissions_controller'
import DocumentSubmissionsRouter from 'routers/document_submissions_router'

export default function(Sector, App) {

  Sector.addInitializer(function() {
    // For now, call the Inbox view the main controller. If we get more complicated here and need to have different
    // controllers, we will have to add a finalizer to close them as well.
    Sector.controller = new DocumentSubmissionsController()
    Sector.router = new DocumentSubmissionsRouter({ controller: Sector.controller })

    Sector.listenTo(App.vent, 'inbox:submission:show', function(model) {
      App.navigate("/" + model.id)
      Sector.controller.showAsync(model)
    })

    Sector.listenTo(App.vent, 'inbox:show', function() {
      App.navigate("/")
      Sector.controller.showInbox()
    })

    Sector.listenTo(App.vent, 'i9:sign', function(ids, isEdit, isSingle) {

      let urlParams = ids.map(function(i){return 'ids[]=' + i}).join('&')
      if (isEdit) {
        urlParams += '&edit=true'
      }

      if (isSingle){
        urlParams += '&single=true'
      }

      const url = App.Routes.onboarding_i9_review_path + '?' + urlParams

      Turbolinks.visit(url)
    })

    Sector.listenTo(App.vent, 'submission:edit:internal_fields', function(model) {
      Sector.controller.showEditInternalFieldsModalView(model)
    })
  })
}
