export default Backbone.Model.extend({
  initialize: function(){
    if(this.get('field_key') == 'ignored'){this.set('ignored', true)}
  },

  // Is this spreadsheet column not matched to a Field and not included in import
  //
  // Returns true if ignored
  isIgnored: function(){
    return !!this.get('ignored')
  },

  // Is this spreadsheet column matched to a Field
  isMatched: function(){
    return !!this.get('field_key') && !this.isIgnored()
  }

})
