import { render, staticRenderFns } from "./UserImportPageHeader.vue?vue&type=template&id=4dab415c&"
import script from "./UserImportPageHeader.vue?vue&type=script&lang=babel&"
export * from "./UserImportPageHeader.vue?vue&type=script&lang=babel&"


/* normalize component */
import normalizer from "!../../../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/workbright/shared/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4dab415c')) {
      api.createRecord('4dab415c', component.options)
    } else {
      api.reload('4dab415c', component.options)
    }
    module.hot.accept("./UserImportPageHeader.vue?vue&type=template&id=4dab415c&", function () {
      api.rerender('4dab415c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/assets/javascripts/src/components/user_imports/UserImportPageHeader.vue"
export default component.exports