var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("adp-integration-modal", { attrs: { employeeId: _vm.employeeId } }),
      _vm._v(" "),
      _c("wb-button", {
        attrs: { label: "Sync to ADP® Workforce Now", context: "primary" },
        on: { click: _vm.openModal },
        scopedSlots: _vm._u([
          {
            key: "prepend",
            fn: function () {
              return [_c("i", { staticClass: "fa fa-upload" })]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }