var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      ref: "supplementBButton",
      staticClass: "btn-supplement-b",
      attrs: { role: "button", href: "javascript:void(0)" },
      on: {
        click: function ($event) {
          return _vm.launchReverifyModal(_vm.submissionId)
        },
      },
    },
    [
      _c("WbIcon", { attrs: { iconSet: "a4s-icon", type: "signature-pen" } }),
      _vm._v("\n  Complete " + _vm._s(_vm.buttonText) + "\n"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }