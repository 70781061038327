var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Toolbar",
        [
          _vm.hasEmployeeData
            ? _c("ToolbarTitle", { ref: "EmployeeFullNameLink" }, [
                _c("a", { attrs: { href: _vm.employeePath } }, [
                  _vm._v(_vm._s(_vm.employeeFullName)),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "btn-sequence" },
            [
              _c(
                "a",
                {
                  staticClass: "btn btn-link",
                  attrs: { href: _vm.submissionDetailsPath },
                },
                [_vm._v("View Submission Details")]
              ),
              _vm._v(" "),
              _c("WbButton", {
                ref: "acceptBtn",
                attrs: {
                  label: "Accept",
                  context: "success",
                  isLoading: _vm.isAcceptingSubmission,
                },
                on: { click: _vm.accept },
              }),
              _vm._v(" "),
              _c("WbButton", {
                ref: "rejectBtn",
                attrs: {
                  label: "Reject",
                  context: "danger",
                  isLoading: _vm.isRejectingSubmission,
                },
                on: { click: _vm.showRejectReasonModal },
              }),
              _vm._v(" "),
              _c("WbButton", {
                ref: "skipBtn",
                attrs: { label: "Skip", context: "default" },
                on: { click: _vm.skip },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("RejectReasonModal", {
            ref: "rejectReasonModal",
            attrs: {
              employeeName: _vm.employeeFullName,
              submissionId: _vm.submission.id,
              documentId: _vm.submission.document_id,
              modalKey: "rejectionReasonModalKey",
              onSubmit: _vm.handleRejectReasonSubmit,
              adminRejectionReasonsEnabled:
                _vm.currentAccount.adminRejectionReasonsEnabled,
              i9RejectionHideAdditionalDetailsFieldEnabled:
                _vm.currentAccount.i9RejectionHideAdditionalDetailsFieldEnabled,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.shouldAlertStartDateMissing
        ? _c(
            "AlertMessage",
            {
              ref: "startDateMissingAlert",
              attrs: { variant: "warning", title: "Start Date Missing" },
            },
            [
              _c("div", [
                _vm._v("\n      Please visit the "),
                _c(
                  "a",
                  { attrs: { href: _vm.employmentPath, target: "_blank" } },
                  [_vm._v("Employment page")]
                ),
                _vm._v(
                  " to add the employee's start date to add the employee's start date\n    "
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.shouldAlertRemoteReverificationRequested
        ? _c(
            "AlertMessage",
            {
              ref: "remoteReverificationRequestedAlert",
              attrs: {
                variant: "warning",
                title: "Remote Reverification Requested",
              },
            },
            [
              _c("div", [
                _vm._v(
                  "\n      Instructions to complete Remote Reverification have been sent to staff\n    "
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm._v("\n    Submitted\n    "),
          _c("WbSimpleDateTime", {
            ref: "SubmissionDateTime",
            attrs: { value: _vm.submission.submitted_at },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.files, function (file, index) {
        return _c("FileViewer", {
          key: index,
          ref: "PDFFile",
          refInFor: true,
          staticClass: "mb-3",
          attrs: {
            allowDownload: _vm.allowDownload,
            fileUrl: _vm.getFileUrl(file),
          },
        })
      }),
      _vm._v(" "),
      _vm.hasDocumentationSet
        ? _c(
            "div",
            { ref: "SupportingDocuments" },
            [
              _c("h4", [_vm._v("Supporting Documentation")]),
              _vm._v(" "),
              _c("SupportingDocumentation", {
                attrs: { documentationSet: _vm.documentationSet },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasFormResponses
        ? _c("FormResponses", {
            ref: "formResponses",
            attrs: { responses: _vm.formResponses },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }