var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "tooltip",
          rawName: "v-tooltip.html",
          value: _vm.tooltip ? _vm.statusText : "",
          expression: "tooltip ? statusText : ''",
          modifiers: { html: true },
        },
      ],
      staticClass: "everify-case-status-indicator",
      class: _vm.iconClass,
    },
    [
      _c("WbIcon", { staticClass: "status-icon", attrs: { type: "fw" } }),
      _vm._v(" "),
      !_vm.tooltip
        ? _c("span", { ref: "statusText", staticClass: "everify-status" }, [
            _vm._v("\n    " + _vm._s(_vm.statusText) + "\n  "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }