var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("p", [
        _vm._v("\n    You are signed in as\n    "),
        _c("strong", [
          _vm._v(
            "\n      " + _vm._s(this.pageContext.currentUserEmail) + "\n    "
          ),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "label label-success" }, [
          _vm._v("\n      " + _vm._s(_vm.currentUserRole) + "\n    "),
        ]),
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("mfa-verification-container", {
        attrs: { mfaData: this.pageContext.mfaData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }