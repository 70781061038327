var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "filters" },
    [
      _c("h4", { staticClass: "text-strong" }, [
        _vm._v("\n    Filter\n    "),
        _vm.anyFiltersActive
          ? _c("small", { staticClass: "label label-success ml-1" }, [
              _vm._v("\n      ON\n    "),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm._l(_vm.filters, function (filter, index) {
        return _c(
          "div",
          { key: index, staticClass: "pb-2" },
          [
            filter.type == "select"
              ? _c("select-filter", {
                  attrs: {
                    collectionViewName: _vm.collectionViewName,
                    filter: filter,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            filter.type == "multiselect"
              ? _c("multi-select-filter", {
                  attrs: {
                    collectionViewName: _vm.collectionViewName,
                    filter: filter,
                  },
                })
              : _vm._e(),
          ],
          1
        )
      }),
      _vm._v(" "),
      _c("div", { staticClass: "mt-4" }, [
        _vm.anyFiltersActive
          ? _c(
              "button",
              {
                staticClass: "btn btn-sm btn-info cancel-action",
                on: { click: _vm.handleClickClearFilters },
              },
              [
                _c("i", { staticClass: "fa fa-times-circle" }),
                _vm._v("\n      Clear All Filters\n    "),
              ]
            )
          : _vm._e(),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }