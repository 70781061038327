var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "snapshot-body" }, [
    _c("div", { staticClass: "snapshot-row" }, [
      _vm.linkToI9
        ? _c(
            "a",
            {
              ref: "linkToI9",
              staticClass: "snapshot-link",
              attrs: { href: _vm.linkToI9, target: "_blank" },
            },
            [_vm._v("\n      " + _vm._s(_vm.i9LinkName) + "\n    ")]
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _vm.companyName
      ? _c("div", { ref: "companyName", staticClass: "snapshot-row" }, [
          _c("div", { staticClass: "snapshot-heading" }, [
            _vm._v("\n      Company Name:\n    "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "snapshot-info" }, [
            _vm._v("\n      " + _vm._s(_vm.companyName) + "\n    "),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "snapshot-row" }, [
      _c("div", { staticClass: "snapshot-heading" }, [
        _vm._v("\n      Case ID:\n    "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "snapshot-info" }, [
        _vm._v("\n      " + _vm._s(_vm.everifyCaseNumber) + "\n      "),
        _vm.duplicateCases.length
          ? _c("div", { staticClass: "mt-4" }, [
              _c("div", { staticClass: "panel panel-warning" }, [
                _c("div", { staticClass: "panel-heading" }, [
                  _vm._v(
                    "\n            We found other cases for " +
                      _vm._s(_vm.caseEmployee.first_name) +
                      ":\n          "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "panel-body" }, [
                  _c(
                    "ul",
                    { staticClass: "mt-2" },
                    _vm._l(_vm.duplicateCases, function (dupCase, i) {
                      return _c("li", { key: i, staticClass: "mb-2" }, [
                        _vm._v("\n                Case ID:\n                "),
                        dupCase.case_id
                          ? _c("span", [
                              _c("strong", [_vm._v(_vm._s(dupCase.case_id))]),
                            ])
                          : _c("span", [
                              _vm._v(
                                "\n                  None\n                "
                              ),
                            ]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(
                          "\n                Case Status:\n                "
                        ),
                        _c("strong", [
                          _vm._v(_vm._s(_vm.caseStatus(dupCase.case_status))),
                        ]),
                      ])
                    }),
                    0
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _vm.everifyCase.case_created_at
      ? _c("div", { staticClass: "snapshot-row" }, [
          _c("div", { staticClass: "snapshot-heading" }, [
            _vm._v("\n      Case Created:\n    "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "snapshot-info" },
            [
              _c("WbSimpleDateTime", {
                attrs: { value: _vm.everifyCase.case_created_at },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "snapshot-row" }, [
      _c("div", { staticClass: "snapshot-heading" }, [
        _vm._v("\n      Case Status:\n    "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "snapshot-info" },
        [
          _c("EverifyCaseStatusIndicator", {
            attrs: { everifyCase: _vm.everifyCase },
          }),
          _vm._v(" "),
          _vm.errors && _vm.showErrors
            ? _c(
                "div",
                { staticClass: "mt-4" },
                [
                  _c("p", { staticClass: "mt-4" }, [
                    _vm._v(
                      "\n          E-Verify reported the following errors for this case:\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.errors, function (error, index) {
                    return _c("div", { key: index }, [
                      _c("div", { staticClass: "panel panel-default mt-4" }, [
                        _c("div", { staticClass: "panel-body" }, [
                          _c("div", { staticClass: "kerning-sm small" }, [
                            _vm._v(
                              "\n                ERROR MESSAGE\n              "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "mt-2" }, [
                            _c("div", { staticClass: "error-txt" }, [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(error.message) +
                                  "\n                "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "mt-2" }, [
                            _c("strong", [
                              _vm._v(
                                "\n                  Submitted value: " +
                                  _vm._s(error.value) +
                                  "\n                "
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "panel-footer" }, [
                        _c("div", { staticClass: "kerning-sm small mb-2" }, [
                          _vm._v(
                            "\n              WHAT THIS MEANS\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.resubmissionError(error.attribute_name)
                          ? _c(
                              "div",
                              { ref: "resubmissionError", refInFor: true },
                              [
                                _c("span", [
                                  _vm._v(
                                    "\n                There is an error with the format of\n                "
                                  ),
                                  _c("strong", [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.resubmissionErrorName(
                                            error.attribute_name
                                          )
                                        ) +
                                        ".\n                "
                                    ),
                                  ]),
                                  _vm._v(
                                    "\n                Please have the staff member update it and resubmit Section 1 of the I-9.\n              "
                                  ),
                                ]),
                              ]
                            )
                          : _vm.updateDocumentationError(error.attribute_name)
                          ? _c(
                              "div",
                              { ref: "documentationError", refInFor: true },
                              [
                                _c("span", [
                                  _vm._v(
                                    "\n                There is an error with the format of\n                "
                                  ),
                                  _c("strong", [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.updateDocumentationErrorName(
                                            error.attribute_name
                                          )
                                        ) +
                                        ".\n                "
                                    ),
                                  ]),
                                  _vm._v(
                                    "\n                You can update this field in Section 2 to conform to the suggested format.\n              "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("br"),
                              ]
                            )
                          : _vm.missingDuplicateContinueReasonError(
                              error.attribute_name
                            )
                          ? _c(
                              "div",
                              {
                                ref: "missingDuplicateContinueReasonError",
                                refInFor: true,
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    "\n                This information has been found in one or more cases created within the last 365 days under this same employer account. To continue verifying the employment eligibility for the individual with this information, you must provide a reason for creating a duplicate case.\n              "
                                  ),
                                ]),
                              ]
                            )
                          : _vm.referToWorkBrightError(error.attribute_name)
                          ? _c(
                              "div",
                              { ref: "referToWBError", refInFor: true },
                              [
                                _c("span", [
                                  _vm._v(
                                    "\n                Please contact WorkBright Support to resolve\n              "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: "mailto:support@workbright.com",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                support@workbright.com\n              "
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _c("div", { ref: "otherError", refInFor: true }, [
                              _c("span", [
                                _vm._v(
                                  "\n                Please update Section 2 of this I-9 or ask the staff member to resubmit.\n              "
                                ),
                              ]),
                            ]),
                      ]),
                    ])
                  }),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _vm.caseDetails
      ? _c("div", { staticClass: "snapshot-row" }, [
          _c("div", { staticClass: "snapshot-heading" }, [
            _vm._v("\n      Case Details:\n    "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "snapshot-info" }, [
            _vm._v("\n      " + _vm._s(_vm.caseDetails) + "\n    "),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.shouldShowDeadline
      ? _c("div", { ref: "deadline", staticClass: "snapshot-row" }, [
          _c("div", { staticClass: "snapshot-heading" }, [
            _vm._v("\n      " + _vm._s(_vm.deadlineLabel) + "\n    "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "snapshot-info" }, [
            _vm._v("\n      " + _vm._s(_vm.caseDeadline) + "\n    "),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "snapshot-row" }, [
      _c("div", { staticClass: "snapshot-heading" }, [
        _vm._v("\n      Next Steps:\n    "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "snapshot-info" }, [
        _vm._v("\n      " + _vm._s(_vm.nextSteps) + "\n      "),
        _vm.resubmitI9Error
          ? _c("div", [
              _vm._v(
                "\n        We suggest you close this case, reject this form, and give the rejection reason(s) above to\n        " +
                  _vm._s(_vm.caseEmployee.first_name) +
                  "\n        for resubmitting Section 1 of the I-9.\n        "
              ),
              _c("br"),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btn-md mt-4",
                  attrs: { href: _vm.linkToI9, target: "_blank" },
                },
                [_vm._v("\n          " + _vm._s(_vm.i9LinkName) + "\n        ")]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.shouldShowEditSection2
          ? _c("div", [
              _vm._v(
                "\n        We suggest you edit the Section 2 documentation for this I-9 and resubmit Section 2\n        "
              ),
              _c("br"),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "button btn btn-md btn-primary px-2 mt-4",
                  attrs: { href: _vm.linkToUpdateI9Documentation },
                },
                [_vm._v("\n          View I-9 Documentation\n        ")]
              ),
            ])
          : _vm.visitEverify
          ? _c("div", [
              _c(
                "a",
                {
                  ref: "visitEverifyLink",
                  staticClass: "btn btn-primary px-4 mt-4",
                  attrs: {
                    href: _vm.$routes.External.everify_cases_url,
                    target: "_blank",
                  },
                },
                [_vm._v("\n          Visit E-Verify\n        ")]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.isDeleteable
          ? _c(
              "div",
              { staticClass: "mt-3" },
              [
                _c("WbButton", {
                  attrs: {
                    context: "danger",
                    label: "Delete Case",
                    isLoading: _vm.isDeletingCase,
                  },
                  on: { click: _vm.caseDeletion },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _vm.auditEvents
      ? _c("div", { staticClass: "snapshot-row" }, [
          _c("div", { staticClass: "snapshot-heading" }, [
            _vm._v("\n      Audit Events\n    "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { ref: "auditEvents", staticClass: "snapshot-info" },
            [
              _c(
                "button",
                {
                  ref: "auditEventsToggle",
                  staticClass: "btn btn-link pl-0",
                  on: {
                    click: function ($event) {
                      _vm.isShowingAuditEvents = !_vm.isShowingAuditEvents
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.auditEventsToggleText) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.isShowingAuditEvents
                ? _c("EverifyCaseAuditEvents", {
                    attrs: { auditEvents: _vm.auditEvents },
                  })
                : _vm._e(),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }