var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showHelpVideo
        ? _c("div", { ref: "helpVideo" }, [
            _c("p", [
              _vm._v(
                "\n      Please take a moment to watch the following video before you begin.\n    "
              ),
            ]),
            _vm._v(" "),
            _c("video", {
              staticClass: "video--responsive",
              attrs: {
                poster: this.$routes.Images.remote_i9_screen,
                controls: "controls",
                playsinline: "playsinline",
                preload: "none",
                width: "560",
                height: "315",
                src: this.$routes.Videos.remote_i9_authorized_rep,
              },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _vm.showNotReadyToStart
        ? _c("div", { ref: "notReady" }, [
            _c("p", [
              _vm._v("Sorry, this document is not ready to be countersigned."),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showSubmitGeoLocation
        ? _c(
            "div",
            { ref: "geolocation" },
            [
              _c(
                "wb-geolocation",
                {
                  on: {
                    wbGeolocationSuccess: _vm.handleGeolocationSuccess,
                    wbGeolocationError: _vm.handleGeolocationError,
                  },
                },
                [
                  _c("h4", [_vm._v("Step 1: Confirm Location")]),
                  _vm._v(" "),
                  _c("p", {
                    domProps: {
                      innerHTML: _vm._s(_vm.submitLocationInstructions),
                    },
                  }),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "If you are willing to proceed, click the Submit Your Location button."
                    ),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isQrStep
        ? [
            _vm.authRepQrCodeScanEnabled
              ? _c(
                  "ScanQrCode",
                  {
                    ref: "scanQrCode",
                    attrs: {
                      actor: "authorized_representative",
                      stepNumber: 1,
                      alertMessage: _vm.qrCodeAlertMessage,
                      qrCodeTarget: _vm.qrCodePayload,
                      remoteId: _vm.remoteCountersignId,
                      action: "countersign",
                    },
                  },
                  [
                    _c(
                      "div",
                      { attrs: { slot: "instructions" }, slot: "instructions" },
                      [
                        _c("p", [
                          _vm._v(
                            "\n          This process requires access to your camera. If you do not have access to a camera on this device, you can use a different device or " +
                              _vm._s(_vm.employee_full_name) +
                              " can choose a different I-9 reviewer.\n        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "\n          Select Begin QR Code Scan below to open your camera and scan the QR code that is being displayed on " +
                              _vm._s(_vm.employee_full_name) +
                              "’s device.\n        "
                          ),
                        ]),
                      ]
                    ),
                  ]
                )
              : _c(
                  "ShowQrCode",
                  {
                    ref: "showQrCode",
                    attrs: {
                      actor: "authorized_representative",
                      stepNumber: 1,
                      alertMessage: _vm.qrCodeAlertMessage,
                      qrCodePayload: _vm.qrCodePayload,
                    },
                  },
                  [
                    _c(
                      "div",
                      { attrs: { slot: "instructions" }, slot: "instructions" },
                      [
                        _c("p", [
                          _vm._v("\n          Please have "),
                          _c("span", { staticClass: "block-translate" }, [
                            _vm._v(_vm._s(_vm.employee_full_name)),
                          ]),
                          _vm._v(
                            " scan the following QR code to verify your mutual proximity.\n        "
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.showCertifyIdentity
        ? _c(
            "div",
            { ref: "certifyIdentity" },
            [
              _c("h4", { staticClass: "breathe" }, [
                _vm._v("Step 2: Certify Identity"),
              ]),
              _vm._v(" "),
              _c("FormFieldInput", {
                attrs: {
                  errors: _vm.formErrors,
                  label: "Full Name",
                  name: "auth_rep_name",
                  required: true,
                  type: "text",
                },
                model: {
                  value: _vm.formData.auth_rep_signature.signature_name,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.formData.auth_rep_signature,
                      "signature_name",
                      $$v
                    )
                  },
                  expression: "formData.auth_rep_signature.signature_name",
                },
              }),
              _vm._v(" "),
              _c(
                "FormFieldCheckbox",
                {
                  attrs: {
                    errors: _vm.formErrors,
                    gridColumns: "col-xs-12",
                    label: _vm.certificationLabel,
                    name: "auth_rep_certification",
                    required: true,
                  },
                  model: {
                    value: _vm.formData.auth_rep_certification,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "auth_rep_certification", $$v)
                    },
                    expression: "formData.auth_rep_certification",
                  },
                },
                [
                  _c("span", {
                    domProps: { innerHTML: _vm._s(_vm.certificationLabel) },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "ladda-button",
                {
                  ref: "continueButton",
                  on: { click: _vm.handleCertifyClick },
                },
                [_vm._v("\n      Continue\n    ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showReviewDocuments
        ? _c(
            "div",
            { ref: "reviewDocuments" },
            [
              _c("h4", [_vm._v("Step 3: Review Documents")]),
              _vm._v(" "),
              _c("ReviewDocumentSetContainer", {
                attrs: {
                  documentationSet: _vm.reviewableDocumentationSet,
                  employeeFullName: _vm.employee_full_name,
                  receiptInstructions: _vm.receiptInstructions,
                  reviewInstructions: _vm.reviewDocumentsInstructions,
                },
                on: {
                  wbDocumentationSetReviewStart: _vm.handleDocumentReviewStart,
                  wbDocumentationSetReviewReset: _vm.handleDocumentReviewReset,
                  wbDocumentationSetVerify: _vm.handleDocumentVerify,
                  wbDocumentationSetVerificationComplete:
                    _vm.handleVerificationComplete,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showSignaturePad
        ? _c(
            "div",
            { ref: "signaturePad" },
            [
              _c("hr"),
              _vm._v(" "),
              _c("p", [
                _vm._v("\n      Employee Name:\n      "),
                _c("strong", { staticClass: "block-translate" }, [
                  _vm._v(_vm._s(this.employee_full_name)),
                ]),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v("\n      List of Documents:\n      "),
                _c("strong", [_vm._v(_vm._s(_vm.documentSetSummary))]),
              ]),
              _vm._v(" "),
              _c("PerjuryStatement", {
                attrs: { statement: this.perjuryStatement },
              }),
              _vm._v(" "),
              _c(
                "form",
                { on: { submit: _vm.handleCountersignAccepted } },
                [
                  _c(
                    "SignaturePad",
                    {
                      attrs: { errors: _vm.formErrors },
                      model: {
                        value: _vm.formData.auth_rep_signature,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "auth_rep_signature", $$v)
                        },
                        expression: "formData.auth_rep_signature",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "breathe d-flex justify-content-between",
                          attrs: { slot: "subHeader" },
                          slot: "subHeader",
                        },
                        [
                          _c("div", [
                            _c("span", [_vm._v("Name:")]),
                            _vm._v(" "),
                            _c("strong", { staticClass: "block-translate" }, [
                              _vm._v(_vm._s(_vm.authRepName)),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              ref: "editIdentity",
                              staticClass: "btn btn-link pt-0 pr-0",
                              on: { click: _vm.handleEditIdentityClick },
                            },
                            [_vm._v("\n            Edit Identity\n          ")]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "ladda-button",
                    {
                      ref: "submitCountersignButton",
                      on: { click: _vm.handleCountersignAccepted },
                    },
                    [_vm._v("\n        Complete Verification\n      ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showFinishedWithoutCountersigning
        ? _c(
            "div",
            { ref: "finishedWOCountersign" },
            [
              _c("hr"),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n      By clicking Finish below, you are stating that the documents provided by "
                ),
                _c("span", { staticClass: "block-translate" }, [
                  _vm._v(_vm._s(this.employee_full_name)),
                ]),
                _vm._v(" do not match.\n    "),
              ]),
              _vm._v(" "),
              _c(
                "ladda-button",
                {
                  ref: "submitDoneButton",
                  on: { click: _vm.handleCountersignRejected },
                },
                [_vm._v("\n      Finish\n    ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showCountersignAccepted
        ? _c("div", { ref: "countersignAccepted" }, [
            _c("p", [
              _vm._v(
                _vm._s(_vm.$locale.t("remoteCountersign.verification_finished"))
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showCountersignRejected
        ? _c("div", { ref: "countersignRejected" }, [
            _c("p", [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.$locale.t("remoteCountersign.verification_rejected")
                  ) +
                  "\n    "
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showInstructions
        ? _c("div", { ref: "instructions" }, [
            _c("hr"),
            _vm._v(" "),
            _c(
              "a",
              {
                attrs: {
                  href: "https://www.uscis.gov/sites/default/files/files/form/i-9instr.pdf",
                  target: "_blank",
                },
              },
              [
                _vm._v(
                  "\n      Click here for detailed instructions on I-9 review\n    "
                ),
              ]
            ),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }