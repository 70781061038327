var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.shouldShow
    ? _c(
        "div",
        {
          ref: "panel",
          staticClass: "modal fade",
          attrs: { role: "dialog", tabindex: "-1" },
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-dialog",
              class: { "modal-lg": _vm.largeModal },
            },
            [
              _c("div", { staticClass: "modal-content" }, [
                _c(
                  "div",
                  { staticClass: "modal-header" },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "close",
                        attrs: { "aria-label": "Close", type: "button" },
                        on: { click: _vm.cancel },
                      },
                      [
                        _c("span", { attrs: { "aria-hidden": "true" } }, [
                          _vm._v("×"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.title
                      ? _c("h3", { staticClass: "modal-title" }, [
                          _vm._v(_vm._s(_vm.title)),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.title ? _vm._t("title") : _vm._e(),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "modal-body" },
                  [_vm._t("default")],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "modal-footer" },
                  [_vm._t("footer")],
                  2
                ),
              ]),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }