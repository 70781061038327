/* https://github.com/marionettejs/backbone.marionette/pull/2450
  * re issue https://github.com/marionettejs/backbone.marionette/issues/1476
 * Current Marionette version as of this writing: 2.4.2 (patch is targeted for `next`).
 *
 * This monkey-patch removes the dependency on `toJSON()` from `serializeData()`. This allows us to override toJSON()
 * for augmenting data for transport while still getting the full set of attributes in templates and template helpers.
 */

export default function() {
  $.extend(Marionette.CompositeView.prototype, {
    serializeData: function() {
      return this.serializeModel()
    }
  })

  $.extend(Marionette.ItemView.prototype, {
    serializeData: function() {
      var data = {}

      // If we have a model, we serialize that
      if (this.model) {
        data = this.serializeModel()

      } else if (this.collection) {
        // Otherwise, we serialize the collection,
        // making it available under the `items` property

        data = {
          items: this.serializeCollection()
        }
      }

      return data
    },

    serializeCollection: function() {
      if (!this.collection) { return {} }
      return _.pluck(this.collection.invoke('clone'), 'attributes')
    },
  })

  $.extend(Marionette.View.prototype, {
    serializeModel: function() {
      if (!this.model) { return {} }
      return _.clone(this.model.attributes)
    },
  })
}
