var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wb-card wb-card--upsell" }, [
    _c("div", { staticClass: "wb-card__title" }, [
      _vm._v("\n    ADP® Workforce Now is now available\n  "),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "wb-card__body mt-3" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "btn btn-primary mt-2",
          attrs: { href: _vm.$routes.integrations_path },
        },
        [_vm._v("\n      Learn more about ADP® Workforce Now\n    ")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _vm._v(
          "Create employees in ADP® Workforce Now and send demographic data from the staff member profile"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "Send Federal W4 information like tax withholding, filing status, deductions and more"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "Send payroll information including routing, account number, pay type and frequency"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }