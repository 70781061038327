var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dropdown" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "ul",
      {
        staticClass: "dropdown-menu",
        attrs: { "aria-labelledby": "dropdownBulkActionsMenu" },
      },
      [
        _c(
          "li",
          { staticClass: "dropdown-header js-bulk-employment-status-header" },
          [_vm._v("\n      Employment Status\n    ")]
        ),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            {
              staticClass: "js-bulk-activate-employees",
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function ($event) {
                  return _vm.$emit("show-bulk-action", "activate-staff")
                },
              },
            },
            [_vm._v("\n        Activate Staff\n      ")]
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            {
              staticClass: "js-bulk-deactivate-employees",
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function ($event) {
                  return _vm.$emit("show-bulk-action", "deactivate-staff")
                },
              },
            },
            [_vm._v("\n        Deactivate Staff\n      ")]
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            {
              staticClass: "js-bulk-update-employment-dates",
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function ($event) {
                  return _vm.$emit("show-bulk-action", "update-employment")
                },
              },
            },
            [_vm._v("\n        Update Employment Dates\n      ")]
          ),
        ]),
        _vm._v(" "),
        _c("li", {
          staticClass: "divider js-bulk-change-status-divider",
          attrs: { role: "separator" },
        }),
        _vm._v(" "),
        _c("li", { staticClass: "dropdown-header" }, [
          _vm._v("\n      Staff Data\n    "),
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            {
              staticClass: "js-bulk-update-custom-fields",
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function ($event) {
                  return _vm.$emit("show-bulk-action", "update-custom-fields")
                },
              },
            },
            [_vm._v("\n        Update Custom Fields\n      ")]
          ),
        ]),
        _vm._v(" "),
        _vm.canDownload
          ? _c("li", [
              _c(
                "a",
                {
                  staticClass: "js-bulk-download-submissions",
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit(
                        "show-bulk-action",
                        "download-submissions"
                      )
                    },
                  },
                },
                [_vm._v("\n        Download Form Submissions\n      ")]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("li", { staticClass: "divider", attrs: { role: "separator" } }),
        _vm._v(" "),
        _c("li", { staticClass: "dropdown-header" }, [
          _vm._v("\n      Sorting and Form Assignment\n    "),
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            {
              staticClass: "js-bulk-reset-assignments",
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function ($event) {
                  return _vm.$emit("show-bulk-action", "reset-assignments")
                },
              },
            },
            [_vm._v("\n        Request New Submissions\n      ")]
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            {
              staticClass: "js-bulk-add-employees-to-group",
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function ($event) {
                  return _vm.$emit("show-bulk-action", "add-to-group")
                },
              },
            },
            [_vm._v("\n        Add Staff to Group\n      ")]
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            {
              staticClass: "js-bulk-remove-employees-from-group",
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function ($event) {
                  return _vm.$emit("show-bulk-action", "remove-from-group")
                },
              },
            },
            [_vm._v("\n        Remove Staff from Group\n      ")]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "btn btn-primary dropdown-toggle",
        attrs: {
          type: "button",
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "true",
        },
      },
      [
        _vm._v("\n    Bulk Actions\n    "),
        _c("span", { staticClass: "ml-3 caret" }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }