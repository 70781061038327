var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("FeatureRequestForm", {
    ref: "featureRequestForm",
    attrs: {
      title: _vm.title,
      description: _vm.description,
      featureName: _vm.featureName,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }