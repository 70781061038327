export default Marionette.ItemView.extend({
  template: false,  // modal content is rendered via Rails and is actually part of the overall form that gets submitted
  prerendered: true,
  behaviors: {
    PrerenderedModalView: {},  // Modal shown on render and this View self-destructs when modal is hidden
  },
  ui: {
    irsWithholdingCalcLink : '.link-irs-withholding-calc',
    saveButton             : '.btn-save'
  },
  events: {
    'click @ui.irsWithholdingCalcLink' : 'handleWithholdingCalculatorLinkClick',
    'click @ui.saveButton'             : 'handleSaveButtonClick'
  },
  bindings: {
    // Inputs - some are user-editable; others are hidden to represent auto-computed values
    '#w4_submission_personal_allowances_worksheet_a'        : 'a',
    '#w4_submission_personal_allowances_worksheet_b'        : 'b',
    '#w4_submission_personal_allowances_worksheet_c'        : 'c',
    '#w4_submission_personal_allowances_worksheet_d'        : 'd',
    '#w4_submission_personal_allowances_worksheet_e'        : 'e',
    '#w4_submission_personal_allowances_worksheet_e_income' : 'e_income',
    '#w4_submission_personal_allowances_worksheet_e_number' : 'e_number',
    '#w4_submission_personal_allowances_worksheet_f'        : 'f',
    '#w4_submission_personal_allowances_worksheet_f_income' : 'f_income',
    '#w4_submission_personal_allowances_worksheet_f_number' : 'f_number',
    '#w4_submission_personal_allowances_worksheet_g'        : 'g',
    '#w4_submission_personal_allowances_worksheet_h'        : 'h',

    // Static text elements that contain auto-computed values
    '.w4_submission_personal_allowances_worksheet_a .form-control-static' : {
      observe: 'a',
      updateMethod: 'text'
    },
    '.w4_submission_personal_allowances_worksheet_e .form-control-static' : {
      observe: 'e',
      updateMethod: 'text'
    },
    '.w4_submission_personal_allowances_worksheet_f .form-control-static' : {
      observe: 'f',
      updateMethod: 'text'
    },
    '.w4_submission_personal_allowances_worksheet_h .form-control-static' : {
      observe: 'h',
      updateMethod: 'text'
    }
  },
  modelEvents: {
    'change': 'recalculate'
  },
  onBeforeRender() {
    this.stickit()

    // Save original values into a temp model. These values will be restored if the user cancels the worksheet
    this.originalWorksheetData = this.model.clone()
  },

  onRender() {
    this.$el.on('hide.bs.modal', this.handleBootstrapModalHide.bind(this))
  },

  onBeforeDestroy() {
    this.$el.off('hidden.bs.modal')
  },

  // Hide the modal when the user goes to IRS Withholding Calculator (thus presuming they will enter the value from the
  // calculator back into the W4 form)
  handleWithholdingCalculatorLinkClick() {
    this._hideModal()
  },

  // Callback when any field changes. Recalculates intermediate values E and F, and then sums everything to get H.
  recalculate(model) {
    // Child tax credit algorithm
    // If your total income will be less than $69,801 ($101,401 if married filing jointly), enter “4” for each eligible child.
    // If your total income will be from $69,801 to $175,550 ($101,401 to $339,000 if married filing jointly), enter “2” for each eligible child.
    // If your total income will be from $175,551 to $200,000 ($339,001 to $400,000 if married filing jointly), enter “1” for each eligible child.
    // If your total income will be higher than $200,000 ($400,000 if married filing jointly), enter “-0-”
    const childTaxCreditMultiplier = parseInt(model.get('e_income'))
    const childTaxCreditNumChildren = parseInt(model.get('e_number'))
    if (Number.isNaN(childTaxCreditMultiplier) || Number.isNaN(childTaxCreditNumChildren)) {
      model.set('e', null)
    } else {
      model.set('e', childTaxCreditMultiplier * childTaxCreditNumChildren)
    }

    // Credit for other dependents algorithm
    // If your total income will be less than $69,801 ($101,401 if married filing jointly), enter “1” for each eligible dependent.
    // If your total income will be from $69,801 to $175,550 ($101,401 to $339,000 if married filing jointly), enter “1” for every two dependents  (for example, “-0-” for one dependent, “1” if you have two or three dependents, and “2” if you have four dependents).
    // If your total income will be higher than $175,550 ($339,000 if married filing jointly), enter “-0-”
    const otherDependentsCreditMultiplier = parseFloat(model.get('f_income'))
    const otherDependentsCreditNumber = parseInt(model.get('f_number'))
    if (Number.isNaN(otherDependentsCreditMultiplier) || Number.isNaN(otherDependentsCreditNumber)) {
      model.set('f', null)
    } else {
      // The `floor` is here because the second income bracket correlates to a fractional value (0.5), and the algorithm
      // dictates rounding down
      model.set('f', Math.floor(otherDependentsCreditMultiplier * otherDependentsCreditNumber))
    }

    // H is simply the sum of A through G
    const operands = _.values(_.pick(model.attributes, 'a', 'b', 'c', 'd', 'e', 'f', 'g'))
    const h = operands.reduce((total, num) => total + (parseInt(num) || 0), 0)
    model.set('h', h)
  },

  handleSaveButtonClick() {
    // Set flag indicating we want to retain the worksheet values
    this.worksheetSaved = true

    this._hideModal()
  },

  // We use the modal hide event to determine the user's intent (cancel or save) because there are many ways the modal
  // can be 'canceled' (keyboard, UI elements, programmatically, etc.), but just one way the user affirms the 'save' -
  // through the Done button
  handleBootstrapModalHide() {
    if (this.worksheetSaved) {
      this.trigger('worksheet:saved', this.model)
    } else {
      // The falsyness of this flag indicates the user wants to discard any work they did on the worksheet.
      // By setting the current model to the original we cloned during init, stickit ends up effectively reverting all
      // the values for us
      this.model.set(this.originalWorksheetData.attributes)
      this.trigger('workheet:canceled')
    }
  },

  _hideModal() {
    this.$el.modal('hide')
  }
})
