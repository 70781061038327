<template>
  <div>
    <v-wait for="permissions_resources">
      <spinner slot="waiting" />

      <h2>Permissions for {{ firstName }}</h2>
      <permissions-summary :permission-sets="this.policy.permissionSets" />
    </v-wait>
  </div>
</template>

<script>
  import PermissionsSummary from './summary/PermissionsSummary'
  import Spinner from 'components/common/Spinner'

  import PolicySanitizer from 'models/policy_generator/policy_sanitizer'
  import Policy from 'models/policy_generator/policy'
  import { PolicyUnmarshaller } from 'models/policy_generator/policy_unmarshaller'

  import { mapState } from 'vuex'

  export default {
    name: 'the-admin-permissions-show-root',
    components: {
      PermissionsSummary,
      Spinner
    },

    data: () => {
      return {
        policy: new Policy(),
      }
    },

    computed: {
      firstName() {
        return this.pageContext.user.first_name
      },

      ...mapState([
        'pageContext'
      ]),
    },

    created() {
      if(this.pageContext.permission_sets) {
        const sanitizer = new PolicySanitizer(PolicyUnmarshaller(this.pageContext.permission_sets), this.$store)
        this.policy = sanitizer.sanitize()
      }
    }
  }
</script>

