var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "WbModal",
    {
      ref: "modal",
      attrs: { watchPageContextVariable: _vm.context, title: "Account Access" },
    },
    [
      _c("div", { staticClass: "m-4" }, [
        _c(
          "form",
          { attrs: { role: "form" } },
          [
            _c("div", { staticClass: "breathe" }, [_vm._v(_vm._s(_vm.prompt))]),
            _vm._v(" "),
            _c("FormFieldRadio", {
              ref: "radio",
              attrs: {
                options: _vm.options,
                required: true,
                name: "reason",
                errors: _vm.formErrors,
              },
              model: {
                value: _vm.radioValue,
                callback: function ($$v) {
                  _vm.radioValue = $$v
                },
                expression: "radioValue",
              },
            }),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("FormFieldTextArea", {
              ref: "additionalNotes",
              attrs: {
                name: "additional_note",
                label: "Ticket number, link, or other sources",
                placeholder:
                  "Support ticket number or link, any other important details to log.",
                required: "",
                errors: _vm.formErrors,
              },
              model: {
                value: _vm.textAreaValue,
                callback: function ($$v) {
                  _vm.textAreaValue = $$v
                },
                expression: "textAreaValue",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "WbButton",
            { attrs: { context: "default" }, on: { click: _vm.hideModal } },
            [_vm._v("\n      Cancel\n    ")]
          ),
          _vm._v(" "),
          _c(
            "WbButton",
            {
              attrs: { isLoading: _vm.processingRequest },
              on: { click: _vm.gainAccess },
            },
            [_vm._v("\n      " + _vm._s(_vm.submitBtnLabel) + "\n    ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }