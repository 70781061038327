<template>
  <WbMultiselect
    :enableSearchField="true"
    :multiple="false"
    placeholder="Add a Form"
    :resetOnSelect="true"
    :selectOptions="documentsOrdered"
    variant="button"
    @input="handleSelect"
  />
</template>

<script>
  import WbMultiselect from 'components/common/WbMultiselect'
  import { mapGetters, mapState } from 'vuex'

  export default {
    name: 'document-select',

    components: {
      WbMultiselect
    },

    computed: {
      ...mapGetters({
        getDocumentById: 'documents/get'
      }),
      ...mapState({
        documents: state => state['documents'].collection
      }),
      documentsOrdered() {
        return Object.values(this.documents)
          .map(doc => ({
            label: doc.name,
            value: doc.id
          }))
          .sort((a,b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))
      },
    },

    methods: {
      handleSelect(id) {
        const document = this.getDocumentById(id)
        this.$emit('select', document)
      }
    },
  }
</script>
