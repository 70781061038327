var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-lg-9" }, [
      _c("p", { staticClass: "give-room-above" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.$locale.t("integrations.adp.config.mappingOptions")) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "panel gray-bg basic pt-4 mt-0",
          on: { click: _vm.clearErrors },
        },
        [
          _c("h4", [
            _vm._v(
              _vm._s(
                _vm.$locale.t("integrations.adp.config.internalPayroll.title")
              )
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              _vm._s(
                _vm.$locale.t(
                  "integrations.adp.config.internalPayroll.description"
                )
              )
            ),
          ]),
          _vm._v(" "),
          _c("FormFieldRadio", {
            ref: "internalFieldOptions",
            attrs: {
              options: _vm.internalPayrollOptions,
              required: true,
              errors: _vm.errors,
              name: "payroll_internal_mapping",
              orientation: "vertical",
            },
            model: {
              value: _vm.formData.payroll_internal_mapping,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "payroll_internal_mapping", $$v)
              },
              expression: "formData.payroll_internal_mapping",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "panel gray-bg basic pt-4",
          on: { click: _vm.clearErrors },
        },
        [
          _c("h4", [
            _vm._v(
              _vm._s(
                _vm.$locale.t("integrations.adp.config.staffPayroll.title")
              )
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              _vm._s(
                _vm.$locale.t(
                  "integrations.adp.config.staffPayroll.description"
                )
              )
            ),
          ]),
          _vm._v(" "),
          _c("FormFieldRadio", {
            ref: "staffFieldOptions",
            attrs: {
              options: _vm.staffPayrollOptions,
              required: true,
              errors: _vm.errors,
              name: "payroll_staff_mapping",
              orientation: "vertical",
            },
            model: {
              value: _vm.formData.payroll_staff_mapping,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "payroll_staff_mapping", $$v)
              },
              expression: "formData.payroll_staff_mapping",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "text-right" },
        [
          _c("LaddaButton", { ref: "submit", on: { click: _vm.submit } }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$locale.t("common.buttons.saveAndContinue")) +
                "\n      "
            ),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }