import SheetColumnView from 'views/user_imports/columns/sheet_column_view'

export default Marionette.CollectionView.extend({
  childView: SheetColumnView,
  onRender: function(){
    this.appendHeaderRow()
  },
  appendHeaderRow: function(){
    var rowStr = '<li class=\'list-group-item list-group-header afix-header-row\'><div class=\'row\'>'
    rowStr += '<div class=\'col-md-4\'><strong>Your Spreadsheet</strong></div>'
    rowStr += '<div class=\'col-md-3\'></div>'
    rowStr += '<div class=\'col-md-5\'><strong>WorkBright Field<strong></div>'
    rowStr += '</div></li>'

    this.$el.prepend(rowStr)
  }
})
