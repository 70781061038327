// ApiOperation replaces the now-deprecated `App.Api` methods. This class abstracts the process of calling an API
// operation (aka making an Ajax request), parsing the response via the model, and setting new attributes on the model
// based on that parsed response.
//
// Why not use Backbone.sync or similar Backbone-provided methods?
//    While in theory you could use Model.save or Backbone.sync to achieve the same functionality, there is some
//    awkwardness that makes the experience feel unnatural:
//      - The default path that Backbone constructs is based on model.url()
//      - Backbone assumes you want to send up all attributes, which is often not the case. If you use `{patch: true}`
//        you can send up a subset of the data, but then only those attributes will be read from the server response.
//      - If you want to send arbitrary data not stored in the attribute, it gets cumbersome.
//
//    In short, you should feel fine to use Backbone-provided methods if the operation is a simple create, update,
//    or destroy and if the data you're sending up is based directly on model attributes. In any other case, it's
//    best to use this class or $.ajax directly.
//
// Why not use $.ajax directly?
//    No reason - feel free, but this class handles JSON stringification of data (in the request) and parsing/setting
//    model attributes (from the response). It also "signals" to other developers that the API operation is intended
//    to respond with an updated model JSON.
//
export default function(options) {
  const defaults = {
    model: null,
    path: null,
    httpMethod: 'POST',
    ajaxOptions: {
      dataType: 'json',
      contentType: 'application/json',
      processData: false
    },
  }

  this.options = _.extend({}, defaults, options)
  this.model = this.options.model
  this.ajaxOptions = _.extend({}, defaults.ajaxOptions, {
    url: this.options.path,
    method: this.options.httpMethod,
    data: JSON.stringify(this.options.data),
  }, this.options.ajaxOptions)

  return $.ajax(this.ajaxOptions)
          .done((responseText, textStatus, xhr) => {
                  if (this.model) {
                    const attributes = this.model.parse(xhr.responseJSON)
                    this.model.set(attributes)
                  }
                })
}
