var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row breathe shallow" }, [
    _vm.label
      ? _c("div", { staticClass: "col-xs-7 text-right action-label" }, [
          _c("strong", [_vm._v(_vm._s(_vm.actionName))]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "col-xs-5" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.permission.grant,
            expression: "permission.grant",
          },
        ],
        ref: "toggle",
        attrs: { type: "checkbox", "data-toggle": "toggle" },
        domProps: {
          checked: Array.isArray(_vm.permission.grant)
            ? _vm._i(_vm.permission.grant, null) > -1
            : _vm.permission.grant,
        },
        on: {
          change: function ($event) {
            var $$a = _vm.permission.grant,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && _vm.$set(_vm.permission, "grant", $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  _vm.$set(
                    _vm.permission,
                    "grant",
                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                  )
              }
            } else {
              _vm.$set(_vm.permission, "grant", $$c)
            }
          },
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }