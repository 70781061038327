<template>
  <div class="document-verification-details-table panel panel-default" ref="documentVerificationDetails">
    <div class="panel-heading">
      <h6 class="panel-title">Document Verification</h6>
    </div>
    <table class="table info-table panel-info-table">
      <tbody>
        <tr>
          <td ref="documentVerificationStatus">
            Document Verification Status
            <a
              class="help-popover"
              ref="popover"
              href="javascript:void(0)"
              :data-content="tooltipMessage"
              data-placement="auto top"
              role="button"
              tabIndex="0"
              data-toggle="popover"
              data-trigger="focus"
            >
              <i class="fa fa-question-circle" />
            </a>
          </td>
          <td>
            <DocumentVerificationBadge
              :status="status"
              :submission="submission"
              @update="handleUpdate"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import DocumentVerificationBadge from 'components/document_submissions/DocumentVerificationBadge'
  const POPOVER_LABEL = `<i class="fa fa-question-circle"></i> Document Verification`
  const POPOVER_TEXT = `Possible Options Are:
          <ul>
            <li>Verified - the document(s) were verified.</li>
            <li>Unable to Verify - the document(s) were not able to be verified, view audit events to see performed checks for more details.</li>
            <li>Approved by Admin - an admin reviewed the document(s) and has approved the inquiry.</li>
            <li>Declined by Admin - an admin reviewed the document(s) and has declined the inquiry.</li>
          </ul>
          <p>Click to view performed checks and/or take action.</p>`

  export default {
    components: {
      DocumentVerificationBadge,
    },
    props: {
      status: {
        type: String,
        required: true,
      },
      submission: {
        type: Object,
        required: true,
      },
    },
    computed: {
      tooltipMessage () {
        return POPOVER_TEXT
      },
    },
    mounted() {
      if (this.$refs.popover) {
        this.initializePopover()
      }
    },
    methods: {
      initializePopover () {
        $(this.$refs.popover).popover({
          html: true,
          title: () => `${POPOVER_LABEL}<i class="close fa fa-times"></i>`,
          container: 'body',
        })
      },
      handleUpdate () {
        window.location.reload()
      },
    },
  }
</script>
