var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.documentAssignments.length
      ? _c("h4", [_vm._v(" No " + _vm._s(_vm.title))])
      : _c(
          "div",
          _vm._l(_vm.documentAssignmentsStatuses, function (status, index) {
            return _c("div", { key: index }, [
              _c("h4", { staticClass: "mt-4" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.statusHeaderTitle(status)) +
                    " " +
                    _vm._s(_vm.title)
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "list-group" },
                _vm._l(
                  _vm.filteredDocumentAssignments(status),
                  function (assignment) {
                    return _c(
                      "div",
                      { key: assignment.id, staticClass: "list-group-item" },
                      [
                        status !== "missing" &&
                        _vm.checkAssignmentLink(assignment)
                          ? _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.getAssignmentRoute(assignment),
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(assignment.document_name) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(assignment.document_name) +
                                  "\n          "
                              ),
                            ]),
                      ]
                    )
                  }
                ),
                0
              ),
            ])
          }),
          0
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }