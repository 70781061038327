export default class I9StaffSupplementBItemForm extends Backbone.Model.extend({}){
  initialize(atts) {
    this.i9Attachment = atts.i9_attachment
  }

  toJSON() {
    return {
      doc_title: this.i9Attachment.get("document_title"),
      doc_number: this.i9Attachment.get("document_number"),
      exp_date: this.i9Attachment.get("expiration_date"),
      documentation_set: [{
        list_key: this.i9Attachment.get("list_key"),
        list_index: this.i9Attachment.get("list_index"),
        documentation_key: this.i9Attachment.get("documentation_key"),
        document_title: this.i9Attachment.get("document_title"),
        issuing_authority: this.i9Attachment.get("issuing_authority"),
        document_number: this.i9Attachment.get("document_number"),
        expiration_date: this.i9Attachment.get("expiration_date"),
        file_front_cache: this.i9Attachment.get("file_front_cache"),
        file_front_url: this.i9Attachment.get("file_front_url"),
        file_back_cache: this.i9Attachment.get("file_back_cache"),
        file_back_url: this.i9Attachment.get("file_back_url")
      }]
    }
  }
}
