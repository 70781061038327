var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "panel panel-primary" }, [
    _c("div", { staticClass: "panel-heading" }, [
      _vm._v("\n    " + _vm._s(_vm.heading) + "\n  "),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "panel-body" },
      [
        _c("action-group-collection-summary", {
          attrs: {
            "permission-set": _vm.permissionSet,
            name: "Fields",
            filter: "RepresentableField",
          },
        }),
        _vm._v(" "),
        _c("action-group-collection-summary", {
          attrs: {
            "permission-set": _vm.permissionSet,
            name: "Forms",
            filter: "Document",
          },
        }),
        _vm._v(" "),
        _c("misc-action-summary", {
          attrs: { "permission-set": _vm.permissionSet },
        }),
        _vm._v(" "),
        _vm.noPermissions
          ? _c("p", { staticClass: "text-center" }, [
              _c("em", [_vm._v("Not authorized to view or modify.")]),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }