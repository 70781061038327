var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h2", [_vm._v("Personal Information")]),
    _vm._v(" "),
    _c("p", [
      _vm._v(
        "\n    The following profile information will be entered into your Form I-9 as you have indicated. If any of this information is incorrect, please\n    "
      ),
      _vm.embedded
        ? _c("span", { ref: "embedded-instructions" }, [
            _vm._v("contact your employer to update it."),
          ])
        : _c("span", { ref: "instructions" }, [
            _c("strong", [
              _c("a", { attrs: { href: _vm.editProfilePath } }, [
                _vm._v("edit your profile"),
              ]),
            ]),
            _vm._v(" now and then return here when you are done."),
          ]),
    ]),
    _vm._v(" "),
    _c(
      "form",
      { staticClass: "simple_form form-horizontal" },
      [
        _c("FormFieldReadOnlyText", {
          attrs: { label: "Name:", value: _vm.employeeName },
        }),
        _vm._v(" "),
        _c("FormFieldReadOnlyText", {
          attrs: { label: "Address:", value: _vm.employeeAddress },
        }),
        _vm._v(" "),
        _c("FormFieldReadOnlyText", {
          attrs: { label: "Birthdate:", value: _vm.birthdate },
        }),
        _vm._v(" "),
        _vm.showNonExcludeableSsn
          ? _c("FormFieldReadOnlyText", {
              ref: "ssn",
              attrs: { label: "Social Security Number:", value: _vm.ssnValue },
            })
          : _c("FormFieldI9Excludeable", {
              ref: "ssn-excludeable",
              attrs: {
                label: "Social Security Number:",
                name: "ssn",
                isExcluded: _vm.isSsnExcluded,
                value: _vm.maskedSsn,
              },
              on: { excludeToggle: _vm.handleSsnExclusion },
            }),
        _vm._v(" "),
        _c("FormFieldI9Excludeable", {
          attrs: {
            label: "Email",
            name: "email",
            isExcluded: _vm.isEmailExcluded,
            value: _vm.employee.email,
          },
          on: { excludeToggle: _vm.handleEmailExclusion },
        }),
        _vm._v(" "),
        _c("FormFieldI9Excludeable", {
          attrs: {
            label: "Phone Number",
            name: "phone",
            isExcluded: _vm.isPhoneExcluded,
            value: _vm.employee.phone,
          },
          on: { excludeToggle: _vm.handlePhoneExclusion },
        }),
        _vm._v(" "),
        _c("FormFieldInput", {
          attrs: {
            helpPopoverText: _vm.helpText,
            label: "Other Last Names Used (if Any):",
            name: "i9_submission[other_names]",
            orientation: "horizontal",
            placeholder: "N/A",
            value: this.employee.other_names,
            type: "text",
          },
          on: { input: _vm.updateOtherNamesModelCallback },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }