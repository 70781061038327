import Constants from 'lib/constants'
import moment from 'moment-timezone'
import { isEmpty } from 'underscore'

const formatDateTime = function(value, format) {
  if (value === null || value === undefined || isEmpty(value)) {
    return null
  }

  return moment(value).format(format)
}

export default {
  // 03/30/2021
  formatDate(value) {
    return formatDateTime(value, Constants.DATE_FORMAT)
  },
  // 03/30/2021 11:35 AM
  formatDateTime(value) {
    return formatDateTime(value, Constants.DATE_TIME_FORMAT)
  },
  // Tue, Mar 30, 2021
  formatDateExt(value) {
    return moment.tz(value, this.timezoneIANA()).format(Constants.DATE_EXT_FORMAT)
  },
  // Tue, Mar 30, 2021 at 11:35 AM MDT
  formatDateTimeExt(value) {
    return moment.tz(value, this.timezoneIANA()).format(Constants.DATE_TIME_EXT_FORMAT)
  },

  // IANA timezone string (e.g. America/Denver)
  // Checks for timezone in gon, and falls back on a default value
  timezoneIANA() {
    if(gon && gon.timezone && gon.timezone.iana) {
      return gon.timezone.iana
    } else {
      return Constants.DEFAULT_IANA_TIMEZONE
    }
  },

  // today, within 1 day, within n days, 1 day ago, n days ago
  daysFromNow(date) {
    const now = moment()
    const then = moment(date)
    let value = '--'

    if (date && then.isValid()) {
      const diff = then.diff(now, 'days')
      if (diff === 0) {
        value = 'today'
      } else {
        value = then.from(now).replace('in ', 'within ')
      }
    }

    return value
  }
}
