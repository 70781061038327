var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("bulk-action-list-alert-details", {
    ref: "bulkActionListAlertDetails",
    attrs: {
      collectionModule: "employees",
      collectionViewName: _vm.collectionViewName,
      displayNameLookupFn: _vm.displayNameLookupFn,
      permissionModule: "employees",
      permissionName: _vm.permissionName,
      actionMethod: function (f) {
        return true
      },
      actionErrorMessage: "",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }