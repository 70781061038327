// Debugging turbolinks:
// $(document).on('page:before-change page:fetch page:receive page:change page:update page:load page:restore', function(event) {
//   console.log(event.type);
// });


// NO SUPPORT FOR TL CACHE – too difficult to manage the starting/stopping of modules when pushState is restored
// from an internal cache. Just fetch everything. We will at least get the TL benefit of not having to re-parse JS
// every time.
Turbolinks.pagesCached(0)

$(document).on('page:fetch',   function() { NProgress.start() })
$(document).on('page:change',  function() { NProgress.done() })
$(document).on('page:restore', function() { NProgress.remove() })

var __scrollThreshold
var __scrolled

// As of Turbolinks v2.0, 'page:ready' is fired even on DOMContentLoaded, so it is sufficient to capture just this event
$(document).on('page:change', function() {
  // The presence of these elements implies that the layout has a subheader and the messages here should be 'transferred'
  // to the subheader region and shown dynamically instead of rendered on the page via 'layouts/messages' partial
  if ($("#flash-error-tmpl").length > 0) {
    App.subheaderRegion.showAjaxFlash($("#flash-error-tmpl").html(), { 
      alertType: 'danger'
    })
  } else if ($("#flash-notice-tmpl").length > 0) {
    App.subheaderRegion.showAjaxFlash($("#flash-notice-tmpl").html(), { 
      fadeAfter: 7000
    })
  }

  if ($("header#subheader").length > 0) {
    __scrollThreshold = $("header#subheader").position().top + 1// - $("nav.navbar").height() + 5;
  } else {
    __scrollThreshold = $("nav.navbar").height() + 1
  }
  __scrolled = false
})

// Manage scrolling - either to the subheader position or to the end of the navbar.
$(window).scroll(function(){
  var scrollTop = $(window).scrollTop()
  if (!__scrolled && scrollTop >= __scrollThreshold) {
    $('body').addClass("scrolled")
    __scrolled = true
  } else if (__scrolled && scrollTop <= __scrollThreshold) {
    $('body').removeClass("scrolled")
    __scrolled = false
  }
})
