import Vue from 'vue'
import { ajax } from 'jquery'
import { getRoute } from 'lib/routes'
import { mutations as baseVuexMutations } from 'vuex/mixins/base_vuex_collection_module'

const state = () => ({
  collection: [],
  pagination: null,
})

const getters = {
  collection: state => state.collection,
  pagination: state => state.pagination,
}

const mutations = {
  ...baseVuexMutations,
  SET_COLLECTION: (state, assignments = []) => {
    state.collection = assignments
  },
  SET_PAGINATION: (state, pagination) => {
    state.pagination = pagination
  },
}
const actions = {
  fetchAssignments: ({ commit }, { filter, expiration, document_verification_status, status, page, query, sort }) => {
    return new Promise((resolve, reject) => {
      ajax({
        url: getRoute('onboarding_i9_assignments_path'),
        method: 'GET',
        data: {
          filter,
          expiration,
          document_verification_status,
          status,
          page,
          sort,
          query,
        },
      }).then(({ items, pagination }) => {
        commit('SET_COLLECTION', items)
        commit('SET_PAGINATION', pagination)
        resolve()
      }).fail(response => {
        commit('SET_COLLECTION', [])
        commit('SET_PAGINATION', null)
        reject(response)
      })
    })
  },
  bulkReset({ state }) {
    const assignments = [...state.collection]
    const assignmentsIds = assignments.filter(el => el.selected).map(el => el.id)

    return new Promise((resolve, reject) => {
      ajax({
        url: getRoute('Api.bulk_reset_document_assignments_path'),
        method: 'PATCH',
        data: {
          document_assignment_ids: assignmentsIds,
        },
      }).then(() => {
        resolve()
      }).fail(response => {
        reject(response)
      })
    })
  },
  unselectAllAssignments: ({ commit, state }) => {
    const assignments = [...state.collection]
    assignments.forEach(el => Vue.set(el, 'selected', false))
    commit('SET_COLLECTION', assignments)
  },
  toggleSubmissionSelectedById: ({ commit, state }, {id}) => {
    const assignments = [...state.collection]
    const index = assignments.findIndex(item => item.current_submission_id == id)
    Vue.set(assignments[index], 'selected', !assignments[index].selected)
    commit('SET_COLLECTION', assignments)
  },
  selectAllAssignmentsReady: ({ commit, state }) => {
    const assignments = [...state.collection]
    assignments
      .forEach(el => {
        if (el.submission.countersignable && el.submission.certify_status == 'uncertified') {
          Vue.set(el, 'selected', true)
        }
      })
    commit('SET_COLLECTION', assignments)
  },
  updateSubmissionData: ({ commit, state }, {id, key, data}) => {
    const assignments = [...state.collection]
    const index = assignments.findIndex(item => item.current_submission_id == id)
    const updatedSubmission = {...assignments[index].submission}
    updatedSubmission[key] = data
    Vue.set(assignments[index], 'submission', updatedSubmission)
    commit('SET_COLLECTION', assignments)
  },
  updateAssignmentSubmission: ({ commit, state }, submission) => {
    const collection = state.collection
    Object.values(collection).forEach(assignment => {
      if (assignment.submission?.id === submission.id) {
        assignment.submission = {
          ...assignment.submission,
          ...submission,
        }
        commit('SET_RECORD', assignment)
      }
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
