var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    this.mfaData.configuring
      ? _c(
          "div",
          [
            _c("MfaConfigContainer", {
              attrs: {
                usingAuthApp: this.usingAuthApp,
                provisioningUri: this.mfaData.provisioningUri,
              },
              on: {
                wbClickAuthAppConfig: _vm.handleClickAuthAppConfig,
                wbOtpSentViaSms: _vm.handleOtpSentViaSms,
              },
            }),
            _vm._v(" "),
            _vm.displaySubmit
              ? _c("div", [_c("hr"), _vm._v(" "), _vm._m(0)])
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.displaySubmit
      ? _c("div", [
          _c(
            "form",
            { on: { submit: _vm.onSubmitOtpForm } },
            [
              _c("form-field-input", {
                ref: "otp",
                attrs: {
                  name: "otp",
                  autocomplete: "off",
                  placeholder: "012345",
                  required: true,
                  label: this.otpLabel,
                  errors: _vm.formErrors,
                },
                model: {
                  value: _vm.formData.otp,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "otp", $$v)
                  },
                  expression: "formData.otp",
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c(
                    "ladda-button",
                    {
                      ref: "submitButton",
                      staticClass: "submit",
                      on: { click: _vm.handleSubmit },
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(this.submitText) + "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  this.showResendSms
                    ? _c("span", [
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-default ml-2",
                            attrs: { href: "#sms" },
                            on: { click: _vm.handleClickSmsResend },
                          },
                          [
                            _vm._v(
                              "\n            Resend code via text\n          "
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.showSMSOption
      ? _c("div", [
          _c("hr"),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn btn-default",
              attrs: { href: "#sms" },
              on: { click: _vm.handleClickSmsConfig },
            },
            [
              _vm._v(
                "\n      Click here if you prefer to configure MFA via Text Message.\n    "
              ),
            ]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.mfaData.configuring
      ? _c("div", [
          _vm.showLostDeviceMessage
            ? _c("div", [
                _c("hr"),
                _vm._v(" "),
                _c("p", [
                  _vm._v("\n        Lost Device? \n        "),
                  _c(
                    "a",
                    {
                      attrs: { href: "#lost" },
                      on: { click: _vm.handleClickLostDevice },
                    },
                    [
                      _vm._v(
                        "\n          Click here to authenticate with a backup code.\n        "
                      ),
                    ]
                  ),
                ]),
              ])
            : _c("div", [
                _c("hr"),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n        You have chosen to authenticate using one of your backup codes.  Please note that a backup code can only be used once. \n        "
                  ),
                  _c(
                    "a",
                    {
                      attrs: { href: "#found" },
                      on: { click: _vm.handleClickFoundDevice },
                    },
                    [
                      _vm._v(
                        "\n          Click here if you found your device.\n        "
                      ),
                    ]
                  ),
                ]),
              ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [
        _vm._v("Step 3: Enter the one-time code the app provides"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }