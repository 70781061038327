<template>
  <div class="form-group" :class="{'has-error' : hasError }">
    <div :class="gridColumns">
      <div :class="localWrapperClass">
        <label>
          <input
            type="checkbox"
            :name="name"
            :value="value"
            :checked="value"
            :disabled="disabled"
            @change="$emit('input', $event.target.checked)"
          />
          <slot>
            {{ label }}
          </slot>
          <a
            class="help-popover popover-link"
            ref="popover"
            v-if="helpPopoverText"
            href="javascript:void(0)"
            :data-content="helpPopoverText"
            :data-field-name="label"
            data-placement="auto top"
            role="button" tabindex="0"
            data-toggle="popover"
            data-trigger="focus"
          >
            <i class="fa fa-question-circle"/>
          </a>
        </label>
      </div>
      <small class="help-block" v-if="hasError">{{ errorMessage }}</small>
      <small class="help-block" v-if="hint">{{ hint }}</small>
    </div>
  </div>
</template>

<script>
  import FormField from 'components/common/FormField'

  export default {
    name: 'form-field-checkbox',
    mixins: [FormField],

    props: {
      value: {},
      gridColumns: {
        type: String,
        default: 'col-sm-offset-4 col-md-offset-3 col-sm-8 col-md-9'
      },
      wrapperClass: {
        type: Object,
        required: false,
        default: () => ({})
      }
    },

    computed: {
      localWrapperClass() {
        return {
          'input-wrapper': true,
          'checkbox': true,
          disabled: this.disabled,
          ...this.wrapperClass
        }
      }
    }
  }
</script>
