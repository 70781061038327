var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("hr"),
    _vm._v(" "),
    _c("h4", [_vm._v(_vm._s(_vm.title))]),
    _vm._v(" "),
    _vm.isReviewing
      ? _c(
          "div",
          { ref: "reviewPanel", staticClass: "panel gray-bg" },
          [
            _c("p", [
              _vm._v("\n      Document Number:\n      "),
              _c("strong", { staticClass: "block-translate" }, [
                _vm._v(" " + _vm._s(_vm.docInformation.documentNumber)),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v("\n      Issuing Authority:\n      "),
              _c("strong", [
                _vm._v(_vm._s(_vm.docInformation.issuingAuthority)),
              ]),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v("\n      Expiration Date:\n      "),
              _c("strong", [_vm._v(_vm._s(_vm.docInformation.expirationDate))]),
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "div",
                { staticClass: "mb-3 file-front-view-container" },
                [
                  _vm.frontUrl
                    ? _c("FileViewer", {
                        ref: "frontUrl",
                        attrs: { fileUrl: _vm.frontUrl },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb-3 file-back-view-container" },
                [
                  _vm.backUrl
                    ? _c("FileViewer", {
                        ref: "backUrl",
                        attrs: { fileUrl: _vm.backUrl },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "p",
              { ref: "receiptInfo" },
              [
                _vm._v(
                  "\n      What if a receipt for a document is shown?\n      "
                ),
                _c("PopoverIcon", {
                  attrs: {
                    title: "Document Receipts",
                    message: _vm.receiptInstructions,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.reviewInstructions
              ? _c("p", {
                  ref: "reviewInstructions",
                  domProps: { innerHTML: _vm._s(_vm.reviewInstructions) },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.buttonReviewOptions, function (fieldButtonProps, index) {
              return _c(
                "div",
                {
                  key: index,
                  ref: "rejectionReasons",
                  refInFor: true,
                  staticClass: "row mb-3",
                },
                [
                  _c("FormFieldButtonGroup", {
                    ref: fieldButtonProps.ref,
                    refInFor: true,
                    attrs: {
                      name: fieldButtonProps.name,
                      label: fieldButtonProps.label(_vm.employeeFullName),
                      title: "",
                      options: fieldButtonProps.buttonOptions,
                      required: true,
                      orientation: "horizontal",
                      value: fieldButtonProps.buttonSelectionValue,
                    },
                    on: {
                      selected: function (res) {
                        return _vm.handleFormButton(res, index)
                      },
                    },
                  }),
                ],
                1
              )
            }),
            _vm._v(" "),
            _c(
              "div",
              [
                _c(
                  "WbButton",
                  {
                    ref: "continueButton",
                    staticClass: "mr-3",
                    attrs: {
                      context: "primary",
                      disabled: !_vm.allFieldsAnswered,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleVerifyClick()
                      },
                    },
                  },
                  [_vm._v("\n        Continue\n      ")]
                ),
              ],
              1
            ),
          ],
          2
        )
      : _c("div", { ref: "reviewSummary", staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-sm-3" },
            [
              _c(
                "WbButton",
                {
                  ref: "reviewButton",
                  staticClass: "mr-3",
                  attrs: { context: _vm.isStarted ? "default" : "primary" },
                  on: { click: _vm.handleReviewClick },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.isStarted ? "Review Again" : "Start Review") +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isStarted
            ? _c("div", { staticClass: "col-sm-9" }, [
                _c(
                  "div",
                  {
                    ref: "matchAlert",
                    staticClass: "alert",
                    class: _vm.isMatch ? "alert-success" : "alert-warning",
                    attrs: { context: "default" },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.isMatch
                            ? "You verified that this document matched"
                            : "You indicated that this document did not match"
                        ) +
                        "\n      "
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }