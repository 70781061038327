<template>
  <div>
    <wb-modal
      :title="modal.titleText"
      ref="modal"
      watchPageContextVariable="shouldShowResetFieldMappingModal"
      :locked="$wait.is('shouldShowResetFieldMappingModal')"  
    >
      <span v-html="modal.message" />
      <div slot="footer">
        <button 
          class="btn btn-default" 
          @click="hideModal"
          :disabled="modal.shouldCancelButtonDisable"
        >
          Cancel
        </button>
        <ladda-button 
          ref="submitButton" 
          @click="submit"
        >
          Update
        </ladda-button>
      </div>
    </wb-modal>
    <button 
      class="btn btn-primary" 
      @click="openModal"
    >
      {{ updateButtonText }}
    </button>
  </div>
</template>

<script>
  import { mapMutations } from 'vuex'
  import { SET_PAGE_CONTEXT_KEYS } from 'vuex/mutation_types'
  import WbModal from "components/common/WbModal"
  import LaddaButton from "components/common/LaddaButton"

  import Util from 'lib/util'

  export default {
    name: "the-adp-payroll-update-field-mapping-root",

    components: {
      WbModal,
      LaddaButton,
    },

    props: {
      integration: {
        type: Object,
        required: true
      }
    },

    data () {
      return {
        errors: {},
        modal: {
          shouldCancelButtonDisable: false,
          titleText: this.$locale.t('integrations.adp.config.updateFieldMapping.modal.titleText'),
          message: this.$locale.t('integrations.adp.config.updateFieldMapping.modal.message')
        },
        updateButtonText: this.$locale.t('integrations.adp.config.updateFieldMapping.updateButtonText')
      }
    },

    methods: {
      openModal () {
        this.setPageContextKeys({shouldShowResetFieldMappingModal: true})
      },
      hideModal () {
        this.$refs.modal.hide()
      },
      submit () {
        this.modal.shouldCancelButtonDisable = true

        $.ajax({
          url: this.$routes.integration_reset_mapping_path({id: this.integration.id}),
          method: 'PATCH'
        }).done((_data, _status, xhr)=>{
          Util.navigateAndShowAjaxFlashNotice(this.$routes.integration_path({id: this.integration.id}), xhr)
        }).fail((xhr)=>{
          this.$refs.modal.hide()
          Util.genericAjaxError("An error occurred.", xhr)
        }).always(()=>{
          this.modal.shouldCancelButtonDisable = false
        })
      },
      ...mapMutations({
        setPageContextKeys: SET_PAGE_CONTEXT_KEYS
      })
    }
  }
</script>
