export default Marionette.ItemView.extend({
  template: '#template-import-confirm-view',
  ui: {
    startImportButton: '.btn-start-import'
  },
  modelEvents: {
    'change' : 'changed'
  },
  events: {
    'click @ui.startImportButton' : 'startImport'
  },
  onRender: function() {
    $.runInitializers(this.$el) // Ladda
  },
  changed: function(){
    this.render()
  },
  startImport: function(){
    App.vent.trigger('admin_user_import:show_import')
  }
})
