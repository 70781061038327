var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h2", { staticClass: "page" }, [
      _vm._v("Edit Form " + _vm._s(_vm.documentDisplayName)),
    ]),
    _vm._v(" "),
    _c("p", [_vm._v(_vm._s(_vm.editDocumentText))]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-horizontal" },
      [
        _c("FormFieldCheckbox", {
          attrs: { label: "Enabled", name: "enabled", errors: _vm.errors },
          model: {
            value: _vm.formData.enabled,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "enabled", $$v)
            },
            expression: "formData.enabled",
          },
        }),
        _vm._v(" "),
        _c("FormFieldCheckbox", {
          attrs: {
            label:
              "All submissions for this form MUST be reviewed in the inbox",
            name: "forceInbox",
            hint: _vm.forceInboxHintText,
            helpPopoverText: _vm.helpText,
            errors: _vm.errors,
          },
          model: {
            value: _vm.formData.force_inbox,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "force_inbox", $$v)
            },
            expression: "formData.force_inbox",
          },
        }),
        _vm._v(" "),
        _vm.showRemoteCountersignSettings
          ? _c("RemoteCountersignSettings", {
              ref: "remoteCountersignSettings",
              attrs: {
                initialData: _vm.remoteCountersignSettings,
                errors: _vm.errors,
              },
              on: { update: _vm.handleRemoteCountersignSettingsUpdate },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("Toolbar", [
          _c(
            "div",
            { staticClass: "btn-sequence" },
            [
              _c(
                "WbButton",
                {
                  ref: "submitBtn",
                  attrs: { isLoading: _vm.isLoading },
                  on: { click: _vm.handleSubmit },
                },
                [_vm._v("\n          Save\n        ")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  ref: "cancelBtn",
                  staticClass: "btn btn-default",
                  class: { disabled: _vm.isLoading },
                  attrs: { href: _vm.documentsPath },
                },
                [_vm._v("\n          Cancel\n        ")]
              ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }