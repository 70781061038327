<template>
  <a :href="$routes.logout_path" data-method="delete" rel="nofollow">
    <WbIcon type="sign-out" class="fa-fw" />
    Sign out
  </a>
</template>
<script>
  import WbIcon from 'components/common/WbIcon'

  export default {
    components: {
      WbIcon
    }
  }
</script>
