var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeader", { attrs: { title: "Promote Staff to Admin" } }),
      _vm._v(" "),
      _c(
        "WbCard",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isWizardMode,
              expression: "!isWizardMode",
            },
          ],
          staticClass: "form-horizontal",
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isSelectEmployeeMode || _vm.isFormMode,
                  expression: "isSelectEmployeeMode || isFormMode",
                },
              ],
            },
            [
              _c(
                "FormField",
                {
                  attrs: {
                    label: "Staff members",
                    required: true,
                    errors: _vm.errors,
                    name: "employee_id",
                    hint: "Select the staff member you want to promote.",
                    orientation: "horizontal",
                  },
                },
                [
                  _c("EmployeeSearch", {
                    ref: "employeeSearch",
                    model: {
                      value: _vm.employee,
                      callback: function ($$v) {
                        _vm.employee = $$v
                      },
                      expression: "employee",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isFormMode
            ? _c(
                "div",
                [
                  _c("AdminUserPolicyFields", {
                    ref: "policyFields",
                    attrs: {
                      accessPolicies: _vm.accessPolicies,
                      groups: _vm.groups,
                      accessPolicy: _vm.formData.accessPolicy,
                      scope: _vm.formData.scope,
                      errors: _vm.errors,
                    },
                    on: {
                      accessPolicySelected: _vm.handleAccessPolicySelected,
                      scopeSelected: _vm.handleScopeSelected,
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.shouldShowCustomPolicyPermissions,
                          expression: "shouldShowCustomPolicyPermissions",
                        },
                      ],
                      staticClass: "form-group",
                    },
                    [
                      _c(
                        "FormField",
                        {
                          attrs: {
                            label: "Permissions",
                            name: "permissions",
                            orientation: "horizontal",
                            errors: _vm.errors,
                            required: true,
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "pt-1" },
                            [
                              _vm.customAccess.valid
                                ? _c("span", { attrs: { id: "policy-msg" } }, [
                                    _vm._v(
                                      "\n              ✓ Permissions Created\n            "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "WbButton",
                                { on: { click: _vm.switchToWizardMode } },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.customAccess.valid
                                          ? "Modify"
                                          : "Create permissions"
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-sm-9 col-sm-offset-3 mt-3" },
                      [
                        _c("WbButton", {
                          ref: "saveButton",
                          attrs: {
                            label: "Save",
                            isLoading: _vm.isSaveButtonLoading,
                          },
                          on: { click: _vm.submit },
                        }),
                        _vm._v(" "),
                        _c("WbButton", {
                          ref: "cancelButton",
                          attrs: { context: "default", label: "Cancel" },
                          on: { click: _vm.cancel },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.isWizardMode
        ? _c("CustomAccessPolicyWizard", {
            ref: "wizard",
            attrs: {
              policy: _vm.customAccess.wizardPermissions,
              name: _vm.fullName,
              backButtonEnabled: false,
            },
            on: {
              prewizardBack: _vm.handleWizardBack,
              done: _vm.handleDoneWizard,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }