/**
 * Include CustomFieldsManners as a ItemView behavior to ensure that certain custom fields behave correctly;
 * e.g. Typing in the "Other" text field on a Radio group auto-selects the "Other" radio item, and others. 
 */
export default Marionette.Behavior.extend({
  events: {
    'change input[type="radio"]': 'clearOtherTextField',
    'keydown .radio-other-input': 'autoSelectOtherRadio',
  },

  // Clear Other Text Field value on every radio selection change
  clearOtherTextField: function(e) {
    const radioInput = $(e.currentTarget)
    radioInput.closest('.form-group').find('.radio-other-input').val('')
  },

  // Selects the Other radio button if user starts typing in the Other text field
  autoSelectOtherRadio: function(e) {
    const otherInputRadio = $(e.currentTarget).closest('.form-group').find("input[value=Other]")

    // Only click radio if not already selected so that this doesn't fire on every keydown
    if (!otherInputRadio.is(':checked')) {
      $(e.currentTarget).closest('.form-group').find("input[value=Other]").click()
    }
  }
})
