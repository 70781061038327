<template>
  <label
    v-if="label"
    :class="{ 'control-label col-sm-4 col-md-3 col-lg-3' : horizontal }"
    :for="name"
  >
    <abbr v-if="required" title="" data-original-title="required">
      *
    </abbr>
    <span v-html="label" />
    <a
      class="help-popover"
      ref="popover"
      v-if="helpPopoverText"
      href="javascript:void(0)"
      :data-content="helpPopoverText"
      data-placement="auto top"
      role="button"
      tabIndex="0"
      data-toggle="popover"
      data-trigger="focus"
    >
      <i class="fa fa-question-circle" />
    </a>
  </label>
</template>

<script>
  import FormFieldConcern from 'mixins/FormFieldConcern'
  export default {
    name: 'form-label',
    mixins: [FormFieldConcern],
    methods: {
      initializePopover () {
        $(this.$refs.popover).popover({
          html: true,
          title: () => `${this.label} <i class="close fa fa-times"></i>`,
          container: 'body'
        })
      }
    },
    mounted() {
      if (this.$refs.popover) {
        this.initializePopover()
      }
    }
  }

</script>
