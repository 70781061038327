var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "WbWorkflowStep",
    { attrs: { stepNumber: _vm.stepNumber, title: "Confirm Location" } },
    [
      [
        _c("AlertMessage", {
          ref: "qrCodeAlert",
          staticClass: "mt-3",
          attrs: { variant: "warning", message: _vm.alertMessage },
        }),
        _vm._v(" "),
        _vm._t("instructions"),
        _vm._v(" "),
        _vm.qrMatched === false
          ? _c("AlertMessage", {
              ref: "noMatchAlert",
              attrs: {
                message:
                  "Unfortunately, that scan did not match.  Please try again.",
                variant: "danger",
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.scanActive
          ? _c("WbQrCodeReader", {
              ref: "scanQrCode",
              on: { wbQrCodeFound: _vm.handleLocationAttempt },
            })
          : _c(
              "WbButton",
              {
                ref: "scanQrCodeButton",
                on: { click: _vm.handleClickScanQrCode },
              },
              [_vm._v("\n      Begin QR Code Scan\n    ")]
            ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }