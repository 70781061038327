var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showHelpVideo
        ? _c("div", { ref: "helpVideo" }, [
            _c("p", [
              _vm._v(
                "Please take a moment to watch the following video before you begin."
              ),
            ]),
            _vm._v(" "),
            _c("video", {
              staticClass: "video--responsive",
              attrs: {
                poster: this.$routes.Images.remote_i9_screen,
                src: this.$routes.Videos.remote_i9_authorized_rep,
                controls: "controls",
                playsinline: "playsinline",
                preload: "none",
                width: "560",
                height: "315",
              },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _vm.showNotReadyToStart
        ? _c("div", { ref: "notReady" }, [
            _c("p", [
              _vm._v("Sorry, this document is not ready for reverification."),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showSubmitGeoLocation
        ? _c(
            "div",
            { ref: "geolocation" },
            [
              _c(
                "wb-geolocation",
                {
                  on: {
                    wbGeolocationSuccess: _vm.handleGeolocationSuccess,
                    wbGeolocationError: _vm.handleGeolocationError,
                  },
                },
                [
                  _c("h4", [_vm._v("Step 1: Confirm Location")]),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(_vm.submitLocationInstructions))]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "If you are willing to proceed, click the Submit Your Location button."
                    ),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showQrCode
        ? [
            _vm.authRepQrCodeScanEnabled
              ? _c(
                  "ScanQrCode",
                  {
                    ref: "scanQrCode",
                    attrs: {
                      actor: "authorized_representative",
                      stepNumber: 1,
                      alertMessage: _vm.qrCodeAlertMessage,
                      qrCodeTarget: _vm.qrCodePayload,
                      remoteId: _vm.remoteReverifyId,
                      action: "reverify",
                    },
                  },
                  [
                    _c(
                      "div",
                      { attrs: { slot: "instructions" }, slot: "instructions" },
                      [
                        _c("p", [
                          _vm._v(
                            "\n          This process requires access to your camera. If you do not have access to a camera on this device, you can use a different device or " +
                              _vm._s(_vm.employeeFullName) +
                              " can choose a different reviewer.\n        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "\n          Select Begin QR Code Scan below to open your camera and scan the QR code that is being displayed on " +
                              _vm._s(_vm.employeeFullName) +
                              "’s device.\n        "
                          ),
                        ]),
                      ]
                    ),
                  ]
                )
              : _c(
                  "ShowQrCode",
                  {
                    ref: "showQrCode",
                    attrs: {
                      actor: "authorized_representative",
                      stepNumber: 1,
                      alertMessage: _vm.qrCodeAlertMessage,
                      qrCodePayload: _vm.qrCodePayload,
                    },
                  },
                  [
                    _c(
                      "div",
                      { attrs: { slot: "instructions" }, slot: "instructions" },
                      [
                        _c("p", [
                          _vm._v("\n          Please have "),
                          _c("span", { staticClass: "block-translate" }, [
                            _vm._v(_vm._s(_vm.employeeFullName)),
                          ]),
                          _vm._v(
                            " scan the following QR code to verify your mutual proximity.\n        "
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.showCertifyIdentity
        ? _c(
            "div",
            { ref: "certifyIdentity" },
            [
              _c("h4", { staticClass: "breathe" }, [
                _vm._v("Step 2: Certify Identity"),
              ]),
              _vm._v(" "),
              _c("form-field-input", {
                attrs: {
                  errors: _vm.formErrors,
                  label: "Full Name",
                  name: "auth_rep_name",
                  required: true,
                  type: "text",
                },
                model: {
                  value: _vm.formData.auth_rep_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "auth_rep_name", $$v)
                  },
                  expression: "formData.auth_rep_name",
                },
              }),
              _vm._v(" "),
              _c("FormFieldCheckbox", {
                attrs: {
                  errors: _vm.formErrors,
                  gridColumns: "col-xs-12",
                  label: _vm.certifyIdentityCheckboxLabel,
                  name: "auth_rep_identity_certification",
                  required: true,
                },
                model: {
                  value: _vm.formData.auth_rep_identity_certification,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.formData,
                      "auth_rep_identity_certification",
                      $$v
                    )
                  },
                  expression: "formData.auth_rep_identity_certification",
                },
              }),
              _vm._v(" "),
              _c("wb-button", {
                ref: "continueButton",
                attrs: {
                  label: "Continue",
                  isLoading: _vm.isCertifyIdentityButtonLoading,
                },
                on: { click: _vm.handleCertifyIdentityClick },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showReviewDocuments
        ? _c(
            "div",
            { ref: "reviewComponents" },
            [
              _c("h4", [_vm._v("Step 3: Review Documents")]),
              _vm._v(" "),
              _c("ReviewDocumentSetContainer", {
                attrs: {
                  documentationSet: _vm.reviewData.documentation_set,
                  employeeFullName: _vm.employeeFullName,
                  receiptInstructions: _vm.receiptInstructions,
                  reviewInstructions: _vm.reviewDocumentsInstructions,
                },
                on: {
                  wbDocumentationSetReviewStart: _vm.handleDocumentReviewStart,
                  wbDocumentationSetReviewReset: _vm.handleDocumentReviewReset,
                  wbDocumentationSetVerify: _vm.handleDocumentVerify,
                  wbDocumentationSetVerificationComplete:
                    _vm.handleReverificationComplete,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showSignaturePad
        ? _c(
            "div",
            { ref: "signaturePad" },
            [
              _c("hr"),
              _vm._v(" "),
              _c("p", [
                _vm._v("\n      Employee Name:\n      "),
                _c("strong", [_vm._v(_vm._s(this.employeeFullName))]),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v("\n      List of Documents:\n      "),
                _c("strong", [_vm._v(_vm._s(_vm.documentSetSummary))]),
              ]),
              _vm._v(" "),
              _c("PerjuryStatement", {
                attrs: { statement: this.perjuryStatement },
              }),
              _vm._v(" "),
              _c(
                "form",
                { on: { submit: _vm.handleReverifyAccepted } },
                [
                  _c(
                    "SignaturePad",
                    {
                      attrs: { errors: _vm.formErrors },
                      model: {
                        value: _vm.auth_rep_signature,
                        callback: function ($$v) {
                          _vm.auth_rep_signature = $$v
                        },
                        expression: "auth_rep_signature",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "breathe d-flex justify-content-between",
                          attrs: { slot: "subHeader" },
                          slot: "subHeader",
                        },
                        [
                          _c("div", [
                            _c("span", [_vm._v("Name:")]),
                            _vm._v(" "),
                            _c("strong", [_vm._v(_vm._s(_vm.authRepFullName))]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              ref: "editIdentity",
                              staticClass: "btn btn-link pt-0 pr-0",
                              on: { click: _vm.handleEditIdentityClick },
                            },
                            [_vm._v("\n            Edit Identity\n          ")]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("wb-button", {
                    ref: "submitReverifyButton",
                    attrs: {
                      label: "Complete Reverification",
                      isLoading: _vm.isReverifyButtonLoading,
                    },
                    on: { click: _vm.handleReverifyAccepted },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showFinishedWithoutReverifying
        ? _c(
            "div",
            { ref: "finishedWOReverify" },
            [
              _c("hr"),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$locale.t(
                        "remoteCountersign.reverify.finish_without_reverifying",
                        this.employeeFullName
                      )
                    ) +
                    "\n    "
                ),
              ]),
              _vm._v(" "),
              _c("wb-button", {
                ref: "submitDoneButton",
                attrs: { label: "Finish", isLoading: _vm.isDoneButtonLoading },
                on: { click: _vm.handleReverifyRejected },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showReverifyAccepted
        ? _c("div", { ref: "reverifyAccepted" }, [
            _c("p", [
              _vm._v(
                _vm._s(
                  _vm.$locale.t("remoteCountersign.reverify.reverify_accepted")
                )
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                _vm._s(_vm.$locale.t("remoteCountersign.reverify.closeWindow"))
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showReverifyRejected
        ? _c("div", { ref: "reverifyRejected" }, [
            _c("p", [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.$locale.t(
                      "remoteCountersign.reverify.reverify_rejected"
                    )
                  ) +
                  "\n    "
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                _vm._s(_vm.$locale.t("remoteCountersign.reverify.closeWindow"))
              ),
            ]),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }