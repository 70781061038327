var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c("WbIcon", {
        directives: [
          {
            name: "tooltip",
            rawName: "v-tooltip.bottom",
            value: _vm.message,
            expression: "message",
            modifiers: { bottom: true },
          },
        ],
        attrs: { type: _vm.icon },
      }),
      _vm._v(" "),
      _c("span", { staticClass: "sr-only" }, [_vm._v(_vm._s(_vm.message))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }