import Util from 'lib/util'
import Routes from 'lib/routes'

export default Marionette.LayoutView.extend({
  template: false,

  behaviors: {
    RailsRemoteFormCallbacks: {},
  },

  ui: {
    form: 'form',
    newKeyButton: '#create-new-token-btn',
    apiKeyEl: '#api-token',
    apiAccessToggleButton: "#api_settings_api_access",
    apiKeyFieldInput: '#api_settings_api_token',
    apiKeyFieldArea: '#api-key-area',
    label: '.control-label'
  },

  triggers: {
    'click @ui.newKeyButton': 'clickGenerateKeyButton',
    'change @ui.apiAccessToggleButton': 'changeApiAccessToggle',
    // Fixes label issue where form gets submitted when clicking a label
    'click @ui.label': {
      event: "",
      stopPropagation: true,
      preventDefault: true,
    },
  },

  onRender: function () {
    this.updateApiTokenView()
  },

  onChangeApiAccessToggle: function () {
    const toggleValue = this.ui.apiAccessToggleButton.prop('checked')

    this.updateApiTokenView()
    this.ui.form.submit()
  },

  onClickGenerateKeyButton: function () {
    // Prompt the user to confirm the change
    bootbox.confirm({
      title: "Are you sure?",
      message: 'Creating a new token will replace and de-activate your existing token. Replace your token?',
      className: 'modal-info',
      callback: this.confirmationCallback.bind(this),
    })
  },

  confirmationCallback: function (result) {
    if (!result)
      return

    // User has confirmed they want a new key
    this.generateNewKey()
  },

  generateNewKey: function (options) {
    const dataOptions = _.extend({}, options)

    $.ajax({
      url: Routes.create_new_key_api_settings_path,
      type: 'POST',
      data: dataOptions,
      wbGenericFailureMsg: "Sorry, we couldn't update your API settings."

    }).done((data, status, xhr) => {
      if (!data)
        return

      // Update the view with new token if api key returned
      this.replaceToken(data.token)
      Util.showAjaxFlashNotice(xhr)
    })
  },

  updateApiTokenView: function () {
    // Check the state of the button in order to display API key field area
    this.ui.apiKeyFieldArea.toggle($('.api_settings_api_access .btn.toggle.off').length === 0)
  },

  replaceToken: function (token) {
    this.ui.apiKeyEl.html(token)
  },

  onRemoteFormSuccess: function(e, data, status, xhr) {
    if (!data)
      return

    this.replaceToken(data.token)

    Util.showAjaxFlashNotice(xhr)
  },
})
