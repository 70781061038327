// I-9 Form Personal Information Step
import RemoteFormValidation from 'lib/remote_form_validation'
import Routes from 'lib/routes'
import FormsUtil from 'lib/util/forms'
import I9PersonalStepContainer from 'components/i9/I9PersonalStepContainer'
import { pureOrphanVue } from 'lib/vue/pureOrphanVue'

export default Backbone.Marionette.ItemView.extend({
  template: '#i9-form-step-personal-info-tmpl',
  ui: {
    form: 'form',
  },

  modelEvents: {
    "sync": "render",
  },

  onRender: function() {
    // Make copy of values as originals get lost when excluded
    if(!this.model.get('_ssn')) {
      this.model.set('_ssn', this.model.get('ssn'))
    }

    if(!this.model.get("_email")) {
      this.model.set('_email', this.model.get('email'))
    }

    if(!this.model.get("_phone")) {
      this.model.set('_phone', this.model.get('phone'))
    }

    setTimeout(() => {
      pureOrphanVue(I9PersonalStepContainer, ".vue-i9-personal-step-container", {
        props:{
          // gon.edit_profile_path is needed for kiosk mode, for more info:
          // app/controllers/kiosk/document_submissions_controller.rb
          editProfilePath: gon.edit_profile_path || Routes.edit_user_path,
          employee: this.model.attributes,
          everify: gon.current_account.everifyFeature,
          employeeAppliedForSsn: gon.employee.applied_for_ssn,
          embedded: gon.current_account.embedded,

          modelChangeCallback: (key, newVal) => {
            this.model.set(key, newVal)
          },

          updateOtherNamesModelCallback: (otherNames) => {
            this.setModel(otherNames)
          },
        }
      }, {
        removeElementOnDestroy: true
      }),
      // bind Vue form to Marionette UI
      this.bindUIElements()

      new RemoteFormValidation({
        resourceName: 'i9_submission',
        model: this.model,
        formEl: this.ui.form,
        validations: {
          'i9_submission[other_names]': { validators: { wbRemote: {} } }
        },
        successCallback: this.onRemoteValidateSuccess.bind(this),
        failedCallback: this.onRemoteValidateFailed.bind(this),
        errorCallback: this.onRemoteValidateError.bind(this),
        path: Routes.staff_i9_submission_path,
      })
    })
  },

  setModel: function(otherNames) {
    this.model.set({"other_names" : otherNames})
  },

  validate: function(validationCompleteCallback){
    this.validationCompleteCallback = validationCompleteCallback
    this.ui.form.submit()
  },

  onRemoteValidateSuccess: function() {
    FormsUtil.clearFormInvalidNotification(this.ui.form)
    this.validationCompleteCallback(true)
  },

  onRemoteValidateFailed: function() {
    FormsUtil.showFormInvalidNotification(this.ui.form)
    this.validationCompleteCallback(false)
  },

  onRemoteValidateError: function() {
    this.validationCompleteCallback(false)
  },
})
