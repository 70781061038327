import WizardStep from 'lib/wizard_step'
import DocumentationUploadView from 'views/document_submissions/i9/documentation_upload_view'

export default WizardStep.extend({
  view: DocumentationUploadView,
  subStepIdx: 0,

  // Total number of Required Document
  //
  // returns integer
  stepCount: function(){
    return this._view.model.documentationSet.length
  },

  // Is this step finished?
  //
  // boolean - true if the step is complete, false if not
  isFinished: function() {
    return this.subStepIdx >= (this.stepCount() - 1)
  },

  // Is this step at the start?
  //
  // boolean - true if the step is at the start, false if not
  isStarting: function(){
    return this.subStepIdx <= 0
  },

  // Advance to the next substep
  advance: function() {
    if(!this.isFinished()) {
      this.subStepIdx++
      this.updateView()
    }
  },

  // Retreat to the previous substep
  retreat: function() {
    if(!this.isStarting()) {
      this.subStepIdx--
      this.updateView()
    }
  },

  // Update the view with the current step action
  updateView: function(){
    const action = {index: (this.subStepIdx)}
    this._context.set('action', action)

    this._view.updateSubStep()
  },

  // Percentage of this step completed
  // Current substep divided by total number of substeps
  //
  // returns - integer 0 to 100
  completePercentage: function(){
    return parseInt((this.subStepIdx / this.stepCount()) * 100)
  },

  canRetreatToSubStep: function() {
    return this.options.canRetreatToSubStep !== undefined ? this.options.canRetreatToSubStep : true
  },

  // Clear out the view from this step
  releaseView: function(){
    if (this.options.canRetreatToSubStep === false) {
      this.subStepIdx = 0
      this._view.vueComponent.$destroy()
    }
    this._view = null
  },
})
