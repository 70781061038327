var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Panel", {
    attrs: { variant: "snapshot" },
    scopedSlots: _vm._u([
      {
        key: "heading",
        fn: function () {
          return [
            _c("div", { staticClass: "panel-title" }, [
              _c("h3", [_vm._v("Action Required")]),
              _vm._v(" "),
              _c("h4", [
                _vm._v(
                  _vm._s(_vm.$locale.t("everify.status.pending_referral.title"))
                ),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c("p", [_vm._v("\n      " + _vm._s(_vm.instructions) + "\n    ")]),
            _vm._v(" "),
            _c(
              "ul",
              _vm._l(_vm.options, function (option) {
                return _c("li", {
                  key: option,
                  domProps: { innerHTML: _vm._s(option) },
                })
              }),
              0
            ),
            _vm._v(" "),
            _c("FormFieldCheckbox", {
              attrs: {
                errors: _vm.formErrors,
                gridColumns: "col-xs-12",
                label: _vm.$locale.t(
                  "everify.status.pending_referral.employeeNotifiedCheckboxLabel"
                ),
                name: "employee_notified",
                required: true,
              },
              model: {
                value: _vm.formData.employeeNotified,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "employeeNotified", $$v)
                },
                expression: "formData.employeeNotified",
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mt-4" },
              [
                _c("WbButton", {
                  ref: "referButton",
                  attrs: {
                    label: "Refer Case",
                    isLoading: _vm.isReferCaseButtonLoading,
                  },
                  on: { click: _vm.submitReferCase },
                }),
                _vm._v(" "),
                _c("WbButton", {
                  ref: "noActionButton",
                  attrs: {
                    label: "No Action",
                    isLoading: _vm.isNoActionButtonLoading,
                    context: "danger",
                  },
                  on: { click: _vm.submitNoAction },
                }),
                _vm._v(" "),
                _vm.allowDownload
                  ? _c("span", { staticClass: "pull-right d-flex" }, [
                      _vm._v(
                        "\n        Download Further Action Notice:\n        "
                      ),
                      _c(
                        "a",
                        {
                          ref: "fanDownloadLink",
                          staticClass: "btn btn-link py-0 pl-0",
                          class: { disabled: _vm.shouldDisableFanLink },
                          attrs: {
                            href: _vm.everifyCase.fan_url,
                            download: "",
                            target: "_blank",
                          },
                        },
                        [_vm._v("\n          English\n        ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          ref: "fanSpanishDownloadLink",
                          staticClass: "btn btn-link py-0 pl-0",
                          class: { disabled: _vm.shouldDisableFanSpanishLink },
                          attrs: {
                            href: _vm.everifyCase.fan_url_spanish,
                            download: "",
                            target: "_blank",
                          },
                        },
                        [_vm._v("\n          Spanish\n        ")]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("EverifyCaseCloseForm", {
                  attrs: { everifyCase: _vm.everifyCase },
                  on: { closeTimeout: _vm.closeModal },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }