var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "workflow-step mb-3" }, [
    _c("h3", { ref: "header", staticClass: "h4" }, [
      _vm._v("Step " + _vm._s(_vm.stepNumber) + _vm._s(_vm.stepTitle)),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "workflow-step-body" }, [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }