import * as BaseCollectionModule from 'vuex/mixins/base_vuex_collection_module'
import { getRoute } from 'lib/routes'

function state() {
  return {
    collection: {}
  }
};

const getters = {
  ...BaseCollectionModule.getters,
  collection: state => state.collection
}

const mutationTypes = {
  ...BaseCollectionModule.mutationTypes
}

const mutations = {
  ...BaseCollectionModule.mutations
}

const actions = {
  // Fetch Associated Accounts
  //
  // nameQuery (String) - the search terms
  fetchAssociatedAccounts: ({ commit }, nameQuery) => {
    return new Promise((resolve, reject) => {
      $.ajax({
        method: 'GET',
        url: `${getRoute('tenant_select_search_path')}?query=${nameQuery}`,
        wbGenericFailureMsg: "Sorry, we couldn't load organizations.",
        progressBar: false,
      }).done(accountResults => {
        const acctIds = []
        for (const account of accountResults) {
          commit(mutationTypes.SET_RECORD, account)
          acctIds.push(account.id)
        }

        resolve(acctIds)
      }).fail(xhr => reject(xhr))
    })
  },

  ...BaseCollectionModule.actions
}


export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
