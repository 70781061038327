<template>
  <FormField
    :label="label"
    :name="name"
    :helpPopoverText="helpPopoverText"
    :orientation="orientation"
    :errors="errors"
    :required="required"
    :hint="hint"
  >
    <div class="input-wrapper" :class="fieldWidth">
      <WbMultiselect
        class="no-wrap"
        :allowEmpty="false"
        :selectOptions="options"
        :placeholder="placeholderValue"
        :value="value"
        @input="onChange"
        :name="name"
        :disabled="disabled"
        :enableSearchField="true"
        :multiple="false"
      />
    </div>
  </FormField>
</template>

<script>
  import FormField from 'components/common/FormField'
  import FormFieldConcern from 'mixins/FormFieldConcern'
  import WbMultiselect from 'components/common/WbMultiselect'

  export default {
    name: 'form-field-select',
    mixins: [FormFieldConcern],

    components: {
      FormField,
      WbMultiselect
    },

    props: {
      fieldWidth: {
        type: String,
        default: 'short-field',
        validator: value => {
          // See _field_lengths.css.scss
          return [
            '', // full-width of container
            'short-field',
            'medium-field',
            'long-field'
          ].includes(value)
        }
      },

      options: {
        type: Array,
        required: true
      },

      value: {}
    },

    methods: {
      onChange(value) {
        this.$emit('input', value)
      }
    }
  }
</script>
