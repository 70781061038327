<template>
  <div>
    <p>Please enter a new value for these {{employeeIds.length}} staff:</p>
    <form class="form-horizontal">
      <form-field-variable
        :field="field"
        v-model="value"
        :name="field.field_key"
        :errors="errors"
      />
    </form>
  </div>
</template>

<script>
  import FormFieldVariable from 'components/common/FormFieldVariable'

  export default {
    name: 'step-confirm',

    components: {
      FormFieldVariable
    },

    props: {
      field: {
        type: Object,
        required: true
      },

      employeeIds: {
        type: Array,
        required: true
      },

      errors: {
        type: Object,
        required: false
      }
    },

    data() {
      return {
        value: null
      }
    },

    methods: {
      valid() {
        return true
      },

      beforeAdvance() {
        this.$emit('updateData', { value: this.value })
      }
    }
  }
</script>
