var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-group", class: { "has-error": _vm.hasError } },
    [
      _c("div", { class: _vm.gridColumns }, [
        _c("div", { class: _vm.localWrapperClass }, [
          _c(
            "label",
            [
              _c("input", {
                attrs: {
                  type: "checkbox",
                  name: _vm.name,
                  disabled: _vm.disabled,
                },
                domProps: { value: _vm.value, checked: _vm.value },
                on: {
                  change: function ($event) {
                    return _vm.$emit("input", $event.target.checked)
                  },
                },
              }),
              _vm._v(" "),
              _vm._t("default", [
                _vm._v("\n          " + _vm._s(_vm.label) + "\n        "),
              ]),
              _vm._v(" "),
              _vm.helpPopoverText
                ? _c(
                    "a",
                    {
                      ref: "popover",
                      staticClass: "help-popover popover-link",
                      attrs: {
                        href: "javascript:void(0)",
                        "data-content": _vm.helpPopoverText,
                        "data-field-name": _vm.label,
                        "data-placement": "auto top",
                        role: "button",
                        tabindex: "0",
                        "data-toggle": "popover",
                        "data-trigger": "focus",
                      },
                    },
                    [_c("i", { staticClass: "fa fa-question-circle" })]
                  )
                : _vm._e(),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _vm.hasError
          ? _c("small", { staticClass: "help-block" }, [
              _vm._v(_vm._s(_vm.errorMessage)),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.hint
          ? _c("small", { staticClass: "help-block" }, [
              _vm._v(_vm._s(_vm.hint)),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }