// I-9 Upload Documentation
import TheUploadDocumentationRoot from 'components/document_submissions/i9/TheUploadDocumentationRoot'
import { pureOrphanVue } from 'lib/vue/pureOrphanVue'
import validateDocumentation from 'services/documentation_upload_validation_service'
import Locale from 'lib/locale'

export default Backbone.Marionette.ItemView.extend({
  template: '#i9-upload-documentation-tmpl',
  modelEvents: {
    sync: 'render',
  },

  initialize: function() {
    this.context = this.getOption('context')
    this.submitButton = this.context.get("submitButton")
    this.stepOptions = this.getOption('stepOptions')
    this.initializeSubStepIndex(this.stepOptions)
  },

  initializeSubStepIndex: function (options) {
    if (options.canRetreatToSubStep === false){
      this.context.set('action', {index: 0})
    }
  },

  onRender: function() {
    setTimeout(() => {
      this.renderVueComponent()
      this.updateSubStep()
    })
  },

  onDestroy: function() {
    this.submitButton.prop("disabled", false)
  },

  renderVueComponent() {
    this.vueComponent = pureOrphanVue(TheUploadDocumentationRoot, ".vue-i9-upload-documentation", {
      ref: "container",
      props: {
        documentVerificationEnabled: window.gon.current_account.documentVerificationEnabled,
        documentationSet: this.model.documentationSet.models,
        currentEmployeeId: window.gon.employee.id,
        personaSettings: this.context.attributes.personaSettings,
        handleUpdate: (model, data) => { this.setModel(model, data) }, // Update model with data entered in Vue component.
        verificationCreatedCallback: () => { this.verificationCreatedCallback() },
        verificationReceivedCallback: (model, data) => { this.verificationReceivedCallback(model, data) },
      },
    }, {
      removeElementOnDestroy: true,
    })
  },

  verificationCreatedCallback: function() {
    this.submitButton.prop("disabled", true)
  },

  verificationReceivedCallback: function(model, attributes) {
    model.set(attributes)
    this.submitButton.prop("disabled", false)
  },

  setModel: function(model, attributes) {
    model.set(attributes)
  },

  validate: function(validationCompleteCallback){
    this.validationCompleteCallback = validationCompleteCallback
    this.vueComponent.$refs.container.setErrors({})

    validateDocumentation({
      i9_attachment: this.model.documentationSet.models[this.subStepIdx].attributes,
      _validate_only: true,
    }).then(response => {
      this.onRemoteValidateSuccess(response)
    }).catch((xhr) => {
      this.onRemoteValidateFailed(xhr)
    })
  },

  onRemoteValidateSuccess(response) {
    if (response?.i9_attachment) {
      // This does populate these fields with the JSON response values
      this.model.documentationSet.models[this.subStepIdx].set("document_number", response.i9_attachment.document_number)
      this.model.documentationSet.models[this.subStepIdx].set("expiration_date", response.i9_attachment.expiration_date)
    }
    this.validationCompleteCallback(true)
  },

  onRemoteValidateFailed (response) {
    const errors = response.responseJSON?.errors || {}

    if (response.responseJSON?.display_hidden_fields) {
      this.model.documentationSet.models[this.subStepIdx].set("skip_document_verify", true)
      this.model.documentationSet.models[this.subStepIdx].set("show_ocr_fields_anyway", true)

      const doc = response.i9_attachment

      if (doc) {
        this.model.documentationSet.models[this.subStepIdx].set("document_number", doc.document_number)
        this.model.documentationSet.models[this.subStepIdx].set("expiration_date", doc.expiration_date)
      }

      errors.message = Locale.t('errors.form.ocr')
    }

    this.validationCompleteCallback(false)
    this.vueComponent.$refs.container.setErrors(errors)
  },

  updateSubStep: function() {
    const action = this.context.get('action')
    this.subStepIdx = action?.index || 0
    this.vueComponent.$refs.container.setDocumentIndex(this.subStepIdx)
  },
})
