var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("p", [
      _vm._v(
        "Please enter a new value for these " +
          _vm._s(_vm.employeeIds.length) +
          " staff:"
      ),
    ]),
    _vm._v(" "),
    _c(
      "form",
      { staticClass: "form-horizontal" },
      [
        _c("form-field-variable", {
          attrs: {
            field: _vm.field,
            name: _vm.field.field_key,
            errors: _vm.errors,
          },
          model: {
            value: _vm.value,
            callback: function ($$v) {
              _vm.value = $$v
            },
            expression: "value",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }