<template>
  <WbModal
    :title="title"
    watchPageContextVariable="i9_submission_id"
    :largeModal="true"
    @modalShown="onOpen"
    @modalHidden="onClose"
    ref="modal"
  >
    <slot>
      <p
        v-if="supplementBFull"
        class="alert alert-warning"
        ref="errorMsg"
      >
        {{sectionName}} for this Form I-9 has already been {{updatedText}}. If you need to make additional updates, please request the staff to submit a new Form I-9.
      </p>
      <div v-else>
        <div>
          <p>{{sectionName}} allows you to reverify the documentation of an Form I-9 submission when that documentation expires or a staff member is rehired. Select which options you would like below:</p>
          <div class="form-group mt-3">
            <div class="btn-group">
              <button
                class="btn btn-default"
                :class="{ 'active': isReverifying }"
                @click="isReverifying = !isReverifying"
              >
                Reverify documentation
              </button>
              <button
                class="btn btn-default"
                :class="{ 'active': isRehiring }"
                @click="isRehiring = !isRehiring"
              >
                Rehire staff
              </button>
              <button
                class="btn btn-default"
                :class="{ 'active': isNameChange }"
                @click="isNameChange = !isNameChange"
              >
                Change name
              </button>
            </div>
          </div>
          <AlertMessage
            v-if="!shouldShowForm && submittedWithoutOption"
            ref="no_option_selected"
            title="No Option Selected"
            message="Please select one or more options above to proceed"
            variant="danger" />
        </div>
        <div v-if="shouldShowForm" ref="form">
          <FormFieldDropdown
            v-if="isReverifying"
            name="reverify_doc_title"
            ref="reverifyDocumentTitle"
            orientation="vertical"
            v-model="formData.reverify_doc_title"
            label="Document Title"
            :required="true"
            :options="i9DocumentationChoicesOptions"
            :errors="errors"
            placeholder="Document Title"
          />

          <FormFieldInput
            v-if="isReverifying"
            name="reverify_doc_number"
            ref="reverifyDocumentNumber"
            orientation="vertical"
            label="Document Number"
            type="text"
            v-model="formData.reverify_doc_number"
            :required="true"
            :errors="errors"
            placeholder="9876543"
          />

          <FormFieldDate
            v-if="isReverifying"
            name="reverify_exp_date"
            ref="reverifyExpirationDate"
            orientation="vertical"
            label="Expiration Date"
            type="text"
            v-model="formData.reverify_exp_date"
            :required="true"
            :errors="errors"
          />

          <FormFieldCheckbox
            v-if="shouldShowAltProcedureCheckbox"
            name="reverify_used_alt_procedure"
            ref="reverifyUsedAltProcedure"
            label="Check this box if you used an alternative procedure authorized by DHS to examine documents."
            v-model="formData.reverify_used_alt_procedure"
            :errors="errors"
            gridColumns="col-xs-12"
          />

          <FormFieldDate
            v-if="isRehiring"
            name="rehire_date"
            ref="rehireDate"
            orientation="vertical"
            label="Rehire Date"
            type="text"
            v-model="formData.rehire_date"
            :required="true"
            :errors="errors"
          />

          <div class="row">
            <FormFieldInput
              v-if="isNameChange"
              name="new_first_name"
              ref="newFirstName"
              orientation="vertical"
              label="Updated First Name"
              type="text"
              v-model="formData.new_first_name"
              :required="true"
              :errors="errors"
              class="col-sm-8"
            />

            <FormFieldInput
              v-if="isNameChange"
              name="new_middle_initial"
              ref="newMiddleInitial"
              orientation="vertical"
              label="Updated Middle Initial"
              type="text"
              v-model="formData.new_middle_initial"
              :required="false"
              :errors="errors"
              class="col-sm-4"
            />
          </div>

          <FormFieldInput
            v-if="isNameChange"
            name="new_last_name"
            ref="newLastName"
            orientation="vertical"
            label="Updated Last Name"
            type="text"
            v-model="formData.new_last_name"
            :required="true"
            :errors="errors"
          />

          <FormFieldTextArea
            class="mb-3"
            ref="additionalInformation"
            orientation="vertical"
            label="Additional Information"
            type="text"
            :required="false"
            :errors="errors"
            v-model="formData.additional_info"
            placeholder="Notes for future reference"
          />
          <PerjuryStatement
            :statement="this.perjuryStatement"
          />
          <SignaturePad
            ref="signaturePad"
            :nameField="formData.adminSignatureNameField"
            v-model="formData.adminSignature"
            :errors="errors"
          />
        </div>
      </div>
    </slot>
    <div slot="footer">
      <button
        class="btn btn-default"
        @click="onCancelled"
        :disabled="false"
      >
        {{closeText}}
      </button>
      <WbButton
        v-if="!supplementBFull"
        ref="submitButton"
        @click="submit"
        label="Submit"
        :isLoading="isLoading"
      />
    </div>
  </WbModal>
</template>

<script>
  import AlertMessage from 'components/common/AlertMessage'
  import FileViewer from 'components/common/FileViewer'
  import FormFieldCheckbox from 'components/common/FormFieldCheckbox'
  import FormFieldDate from 'components/common/FormFieldDate'
  import FormFieldDropdown from 'components/common/FormFieldDropdown'
  import FormFieldInput from 'components/common/FormFieldInput'
  import FormFieldTextArea from 'components/common/FormFieldTextArea'
  import PerjuryStatement from 'components/common/PerjuryStatement'
  import SignaturePad from 'components/common/SignaturePad'
  import Util from 'lib/util'
  import WbModal from 'components/common/WbModal'
  import WbButton from 'components/common/WbButton'
  import OpenSupplementBModalMixin from 'components/mixins/admin/document_submissions/OpenSupplementBModalMixin'
  import { mapActions, mapGetters } from 'vuex'
  import SystemEvents from 'lib/system_events'
  import EmployeeSupplementBUpdatedEvent from 'models/system_events/employee_supplement_b_updated_event'
  import EmployeeSupplementBUpdateCancelledEvent from 'models/system_events/employee_supplement_b_update_cancelled_event'

  export default {
    name: 'supplement-b-modal',
    components: {
      AlertMessage,
      FileViewer,
      FormFieldCheckbox,
      FormFieldDate,
      FormFieldDropdown,
      FormFieldInput,
      FormFieldTextArea,
      PerjuryStatement,
      SignaturePad,
      WbModal,
      WbButton
    },

    mixins: [
      OpenSupplementBModalMixin
    ],

    props: {
      i9DocumentationChoices: {
        type: Array,
        required: true
      },
      documentSubmission: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        errors: {},
        isNameChange: false,
        isReverifying: false,
        isRehiring: false,
        isOpen: false,
        submittedWithoutOption: false,
        isLoading: false,
        formData: {
          reverify_doc_title: null,
          reverify_doc_number: null,
          reverify_exp_date: null,
          reverify_used_alt_procedure: null,
          new_first_name: null,
          new_middle_initial: null,
          new_last_name: null,
          adminSignature: {
            signature: '',
            signature_name: ''
          },
          adminSignatureNameField: {
            autofocus: false,
            name: 'employer_or_authrep_name'
          },
          additional_info: null,
          rehire_date: null
        }
      }
    },

    computed: {
      i9DocumentationChoicesOptions () {
        return this.i9DocumentationChoices.map((dc) => {
          return {
            label: dc.list_title,
            value: dc.list_title
          }
        })
      },

      shouldShowAltProcedureCheckbox () {
        return this.isReverifying && this.documentSubmission.supplement_b_supported
      },

      isShowingSignature () {
        return this.isOpen && this.shouldShowForm
      },

      perjuryStatement () {
        return this.$locale.t('document_submissions.supplementb.modal.perjuryStatement')
      },

      hasSelectedOption() {
        return this.isRehiring || this.isReverifying || this.isNameChange
      },

      shouldShowForm () {
        return this.hasSelectedOption
      },

      sectionName () {
        return this.documentSubmission.supplement_b_supported ? 'Supplement B' : 'Section 3'
      },

      title () {
        return this.$locale.t(
          'document_submissions.supplementb.modal.titleText',
          this.documentSubmission.employee_name,
          this.sectionName
        )
      },

      updatedText () {
        return this.documentSubmission.supplement_b_supported ? 'updated three times' : 'completed'
      },

      closeText () {
        return this.supplementBFull ? 'Close' : 'Cancel'
      },

      supplementBFull () {
        return this.documentSubmission.supplement_b_full
      },

      hasSupplementBHash () {
        const { hash } = new URL(window.location.href)
        return hash === '#supplement-b'
      },

      ...mapGetters({
        currentUser: 'user/current'
      })
    },

    watch: {
      isShowingSignature () {
        if(this.isShowingSignature) {
          this.$nextTick(() => {
            this.initializeSignaturePad()
          })
        }
      }
    },

    created() {
      // Pre-populate the signature name field with the admin's name
      this.formData.adminSignature.signature_name = this.currentUser.name
    },

    mounted () {
      if(this.hasSupplementBHash) {
        this.launchReverifyModal(this.documentSubmission.id)
      }
    },

    methods: {
      ...mapActions({
        fetchSupplementBFormResponse: 'document_submissions/fetchSupplementBFormResponse'
      }),
      submit() {
        this.submittedWithoutOption = false

        if(this.hasSelectedOption) {
          this.fetchFormResponse()
        } else {
          this.submittedWithoutOption = true
        }
      },

      async fetchFormResponse() {
        this.startLoading()
        try {
          await this.fetchSupplementBFormResponse({
            submissionId: this.documentSubmission.id,
            formOptions: {
              rehire: this.isRehiring,
              reverify: this.isReverifying,
              change_name: this.isNameChange
            },
            form: {
              reverify_doc_title: this.formData.reverify_doc_title,
              reverify_doc_number: this.formData.reverify_doc_number,
              reverify_exp_date: this.formData.reverify_exp_date,
              reverify_used_alt_procedure: this.formData.reverify_used_alt_procedure,
              new_first_name: this.formData.new_first_name,
              new_middle_initial: this.formData.new_middle_initial,
              new_last_name: this.formData.new_last_name,
              employer_or_authrep_name: this.formData.adminSignature.signature_name,
              employer_or_authrep_signature: this.formData.adminSignature.signature,
              additional_info: this.formData.additional_info,
              rehire_date: this.formData.rehire_date,
            }
          })

          Util.showFlashNotice(`${this.sectionName} form uploaded`)
          this.hideModal()
          this.notifySystemEvent(new EmployeeSupplementBUpdatedEvent())

        } catch (xhr) {
          if (xhr.responseJSON && xhr.responseJSON.errors) {
            this.errors = xhr.responseJSON.errors || {}
          } else {
            Util.genericAjaxError("An error occurred.", xhr)
          }
        } finally {
          this.submittedWithoutOption = false
          this.endLoading()
        }
      },

      startLoading() {
        this.isLoading = true
      },

      endLoading() {
        this.isLoading = false
      },

      hideModal () {
        this.$refs.modal.hide()
        this.submittedWithoutOption = false
        this.removeSupplementBHash()
      },

      initializeSignaturePad () {
        this.$refs.signaturePad.clearSignature()
        this.$refs.signaturePad.loadExistingSignature()
      },

      onClose() {
        this.isOpen = false
        this.submittedWithoutOption = false
        this.removeSupplementBHash()
      },

      onOpen() {
        this.isOpen = true
      },

      onCancelled() {
        this.hideModal()
        this.notifySystemEvent(new EmployeeSupplementBUpdateCancelledEvent())
      },

      removeSupplementBHash () {
        if(this.hasSupplementBHash) {
          window.location.hash = ''
        }
      },

      notifySystemEvent(systemEvent) {
        SystemEvents.notify(systemEvent.payload())
      },
    },
  }
</script>
