var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "alert alert-success" },
    [
      _c("FormFieldSelect", {
        ref: "reason",
        staticClass: "mt-3",
        attrs: {
          label: "Duplicate Continue Reason",
          fieldWidth: "long-field",
          name: "duplicate_continue_reason_code",
          placeholder: "Please select a reason...",
          errors: _vm.errors,
          options: _vm.options,
          value: _vm.value,
        },
        on: { input: _vm.handleChange },
      }),
      _vm._v(" "),
      _vm.isOtherReasonSelected
        ? _c("FormFieldTextArea", {
            ref: "otherReason",
            attrs: {
              name: "duplicate_continue_reason_other",
              placeholder: "Explanation",
              errors: _vm.errors,
            },
            model: {
              value: _vm.otherReason,
              callback: function ($$v) {
                _vm.otherReason = $$v
              },
              expression: "otherReason",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-3" },
        [
          _c(
            "WbButton",
            {
              ref: "saveBtn",
              attrs: { context: "success", isLoading: _vm.isLoading },
              on: { click: _vm.handleSave },
            },
            [_vm._v("\n      Continue Case\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }