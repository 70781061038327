import TheAdminDashboardRoot from 'components/admin/dashboard/TheAdminDashboardRoot'
import TheAdminDocumentSubmissionShowRoot from 'components/admin/document_submissions/TheAdminDocumentSubmissionShowRoot'
import TheAdminDownloadsRoot from 'components/admin/downloads/TheAdminDownloadsRoot'
import TheAdminPermissionsShowRoot from 'components/permissions/TheAdminPermissionsShowRoot'
import TheAdminPermissionsWizardRoot from 'components/permissions/TheAdminPermissionsWizardRoot'
import TheAdminPromoteRoot from 'components/admin/TheAdminPromoteRoot'
import TheAdminUsersRoot from 'components/admin/admin_users/TheAdminUsersRoot'
import TheAdpIntegrationRoot from 'components/admin/adp/TheAdpIntegrationRoot'
import TheAdpPayrollFormOptionsRoot from 'components/admin/integrations/TheAdpPayrollFormOptionsRoot'
import TheAdpPayrollUpdateFieldMappingRoot from 'components/admin/integrations/TheAdpPayrollUpdateFieldMappingRoot'
import TheAppNavbarRoot from 'components/navbar/TheAppNavbarRoot'
import TheCloneDocumentRoot from 'components/superadmin/TheCloneDocumentRoot'
import TheDocumentSubmissionsInboxRoot from 'components/document_submissions/TheDocumentSubmissionsInboxRoot'
import TheDocumentVerificationsRoot from 'components/admin/document_verifications/TheDocumentVerificationsRoot'
import TheEditAccountSettingsRoot from 'components/admin/account_settings/TheEditAccountSettingsRoot'
import TheEditAdminUserRoot from 'components/admin/admin_users/TheEditAdminUserRoot'
import TheEditBuiltInDocumentRoot from 'components/admin/documents/TheEditBuiltInDocumentRoot'
import TheEditCompanyProfileRoot from 'components/admin/TheEditCompanyProfileRoot'
import TheEmployeeProfileSsnRoot from 'components/employees/TheEmployeeProfileSsnRoot'
import TheEmployeesDocumentAssignmentsIndexRoot from 'components/employees/document_assignments/TheEmployeesDocumentAssignmentsIndexRoot'
import TheEverifyAccountsIndexRoot from 'components/everify/accounts/TheEverifyAccountsIndexRoot'
import TheEverifyCasesIndexRoot from 'components/everify/cases/TheEverifyCasesIndexRoot'
import TheEverifyTncViewerRoot from 'components/everify/staff/TheEverifyTncViewerRoot'
import TheFeatureFlagsIndexRoot from 'components/superadmin/feature_flags/TheFeatureFlagsIndexRoot'
import TheFeatureRequestRoot from 'components/admin/TheFeatureRequestRoot'
import TheI9InboxRoot from 'components/i9/employer_review/TheI9InboxRoot'
import TheI9RemoteCountersignAuthRepRoot from 'components/i9_remote_countersign/TheI9RemoteCountersignAuthRepRoot'
import TheI9RemoteCountersignEmployeeRoot from 'components/i9_remote_countersign/TheI9RemoteCountersignEmployeeRoot'
import TheI9RemoteReverifyAuthRepRoot from 'components/i9_remote_reverify/TheI9RemoteReverifyAuthRepRoot'
import TheI9RemoteReverifyEmployeeRoot from 'components/i9_remote_reverify/TheI9RemoteReverifyEmployeeRoot'
import TheLocaleToggleRoot from 'components/localization/TheLocaleToggleRoot'
import TheManagedAccessPolicyTemplatesEditRoot from 'components/admin/managed_access_policy_templates/TheManagedAccessPolicyTemplatesEditRoot'
import TheManagedAccessPolicyTemplatesNewRoot from 'components/admin/managed_access_policy_templates/TheManagedAccessPolicyTemplatesNewRoot'
import TheManagedAccessPolicyTemplatesRoot from 'components/admin/managed_access_policy_templates/TheManagedAccessPolicyTemplatesRoot'
import TheMfaRoot from 'components/mfa/TheMfaRoot'
import TheNewAccountRoot from 'components/superadmin/TheNewAccountRoot'
import TheNewAdminPromoteRoot from 'components/admin/TheNewAdminPromoteRoot'
import TheNewAdminUserRoot from 'components/admin/admin_users/TheNewAdminUserRoot'
import TheReassignFormsRoot from 'components/employees/reassignments/TheReassignFormsRoot'
import TheReclaimUserEmailRoot from 'components/superadmin/users/TheReclaimUserEmailRoot'
import TheSsoAccountsIndexRoot from 'components/sso/TheSsoAccountsIndexRoot'
import TheStaffListRoot from 'components/admin/staff_list/TheStaffListRoot'
import TheStandardFieldsIndexRoot from 'components/admin/standard_fields/TheStandardFieldsIndexRoot'
import TheSubmissionAuditLogRoot from 'components/document_submissions/TheSubmissionAuditLogRoot'
import TheSuperadminUsageReportsRoot from 'components/admin/downloads/TheSuperadminUsageReportsRoot'
import TheSupplementalFilesShowRoot from 'components/admin/supplemental_files/TheSupplementalFilesShowRoot'
import TheTenantSelectIndexRoot from 'components/TheTenantSelectIndexRoot'
import TheTextNotificationsRoot from 'components/admin/text_notifications/TheTextNotificationsRoot'
import TheUserImportsCompleteRoot from 'components/user_imports/TheUserImportsCompleteRoot'
import TheUserUpdatesNewRoot from 'components/user_updates/TheUserUpdatesNewRoot'
import TheW4Root from 'components/document_submissions/built_in/TheW4Root'
import TheWebhookFormRoot from 'components/webhooks/TheWebhookFormRoot'

export default {
  TheAdminDashboardRoot,
  TheAdminDocumentSubmissionShowRoot,
  TheAdminDownloadsRoot,
  TheAdminPermissionsShowRoot,
  TheAdminPermissionsWizardRoot,
  TheAdminPromoteRoot,
  TheAdminUsersRoot,
  TheAdpIntegrationRoot,
  TheAdpPayrollFormOptionsRoot,
  TheAdpPayrollUpdateFieldMappingRoot,
  TheAppNavbarRoot,
  TheCloneDocumentRoot,
  TheDocumentSubmissionsInboxRoot,
  TheDocumentVerificationsRoot,
  TheEditAccountSettingsRoot,
  TheEditAdminUserRoot,
  TheEditBuiltInDocumentRoot,
  TheEditCompanyProfileRoot,
  TheEmployeeProfileSsnRoot,
  TheEmployeesDocumentAssignmentsIndexRoot,
  TheEverifyAccountsIndexRoot,
  TheEverifyCasesIndexRoot,
  TheEverifyTncViewerRoot,
  TheFeatureFlagsIndexRoot,
  TheFeatureRequestRoot,
  TheI9InboxRoot,
  TheI9RemoteCountersignAuthRepRoot,
  TheI9RemoteCountersignEmployeeRoot,
  TheI9RemoteReverifyAuthRepRoot,
  TheI9RemoteReverifyEmployeeRoot,
  TheLocaleToggleRoot,
  TheManagedAccessPolicyTemplatesEditRoot,
  TheManagedAccessPolicyTemplatesNewRoot,
  TheManagedAccessPolicyTemplatesRoot,
  TheMfaRoot,
  TheNewAccountRoot,
  TheNewAdminPromoteRoot,
  TheNewAdminUserRoot,
  TheReassignFormsRoot,
  TheReclaimUserEmailRoot,
  TheSsoAccountsIndexRoot,
  TheStaffListRoot,
  TheStandardFieldsIndexRoot,
  TheSubmissionAuditLogRoot,
  TheSuperadminUsageReportsRoot,
  TheSupplementalFilesShowRoot,
  TheTenantSelectIndexRoot,
  TheTextNotificationsRoot,
  TheUserImportsCompleteRoot,
  TheUserUpdatesNewRoot,
  TheW4Root,
  TheWebhookFormRoot,
}
