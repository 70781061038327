var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showSpinner
        ? _c("spinner", {
            attrs: { showLongWaitMessage: true, longWaitMessageTimeout: 5000 },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("video", { ref: "video", attrs: { hidden: "hidden" } }),
      _vm._v(" "),
      _c("canvas", { ref: "canvas", attrs: { hidden: "hidden" } }),
      _vm._v(" "),
      _vm.showCameraAccessFailed
        ? _c("div", { staticClass: "alert alert-danger mt-4" }, [
            _vm._v("\n    Access to your camera failed.\n    "),
            _vm._m(0),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _c("strong", [_vm._v("\n          Option 1:\n        ")]),
        _vm._v(
          "\n        Check your settings and reload this page to try again.\n      "
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("strong", [_vm._v("\n          Option 2:\n        ")]),
        _vm._v(
          "\n        Open your camera app and use it to scan the QR code.\n      "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }