var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "wb-modal",
    {
      ref: "modal",
      attrs: {
        title: "Edit Payroll Data",
        watchPageContextVariable: "edit_payroll",
        locked: _vm.$wait.is("edit_payroll"),
      },
    },
    [
      _c(
        "form",
        { staticClass: "form-horizontal" },
        [
          _c(
            "PayrollGroupFieldset",
            {
              attrs: {
                fields: _vm.localInternalFields,
                errors: _vm.errors,
                disableFields: _vm.disableInternalFields,
              },
            },
            [
              _c("template", { slot: "title" }, [
                _vm._v("\n        Internal Fields "),
                _c("small", [_vm._v(" (hidden from staff)")]),
              ]),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "PayrollGroupFieldset",
            {
              attrs: {
                fields: _vm.localStaffFields,
                errors: _vm.errors,
                disableFields: _vm.disableStaffFields,
              },
            },
            [
              _c("template", { slot: "title" }, [
                _vm._v("\n        Staff Fields "),
                _c("small", [_vm._v(" (completed by staff)")]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "button",
            {
              staticClass: "btn btn-default",
              attrs: { disabled: _vm.$wait.is("edit_payroll") },
              on: { click: _vm.hide },
            },
            [_vm._v("Cancel")]
          ),
          _vm._v(" "),
          _c("ladda-button", { ref: "submit", on: { click: _vm.submit } }, [
            _vm._v("Submit"),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }