import UserPermissionSet from "./user_permission_set"
import { PolicyUnmarshaller } from 'models/policy_generator/policy_unmarshaller'

class ManagedAccessPolicy {
  constructor(atts = {}) {

    const defaults = {
      id: null,
      name: null,
      count: null,
      scopedPermissionSet: new UserPermissionSet("group"),
      unscopedPermissionSet: new UserPermissionSet("baseline"),
      otherUnscopedPermissionSet: new UserPermissionSet("other")
    }

    const settings = {...defaults, ...atts}

    this.id = settings.id
    this.name = settings.name
    this.count = settings.count
    this.errors = {}

    this.scopedPermissionSet =  settings.scopedPermissionSet
    this.unscopedPermissionSet =  settings.unscopedPermissionSet
    this.otherUnscopedPermissionSet =  settings.otherUnscopedPermissionSet

    this.permissionSets = [
      this.scopedPermissionSet,
      this.unscopedPermissionSet,
      this.otherUnscopedPermissionSet
    ]
  }

  toJSON(){
    // Combine unscoped permissions
    const unscopedPermissions = this.unscopedPermissionSet.permissions.concat(this.otherUnscopedPermissionSet.permissions)

    return JSON.stringify({
      policy: {
        name: this.name,
        scoped_permissions: this.scopedPermissionSet.permissions.map(perm => perm.toJSON()),
        unscoped_permissions: unscopedPermissions.map(perm => perm.toJSON())
      }
    })
  }

  // Shim Methods
  groupPermissionSet(){
    return this.scopedPermissionSet
  }

  baselinePermissionSet(){
    return this.unscopedPermissionSet
  }

  otherPermissionSet(){
    return this.otherUnscopedPermissionSet
  }

  static build(options) {
    const permissions = new PolicyUnmarshaller(options.permission_sets)

    return new ManagedAccessPolicy({
      id: options.id,
      name: options.name,
      count: options.count,
      scopedPermissionSet: permissions.scopedPermissionSet,
      unscopedPermissionSet: permissions.unscopedPermissionSet,
      otherUnscopedPermissionSet: permissions.otherUnscopedPermissionSet
    })
  }
}

export default ManagedAccessPolicy
