var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "SlideoutPanel",
    {
      ref: "DocumentSubmissionSlideout",
      attrs: {
        largeModal: true,
        size: "xl",
        title: _vm.title,
        watchPageContextVariable: "DocumentSubmissionSlideout",
      },
      on: { modalHidden: _vm.handleModalHide },
    },
    [
      _vm.isLoading
        ? _c("Spinner", { ref: "Loader" })
        : [
            _vm.hasSufficientData
              ? _c("DocumentSubmission", {
                  ref: "DocumentSubmission",
                  attrs: {
                    submission: _vm.submission,
                    employee: _vm.employee,
                    rejectionReasonModalKey: _vm.rejectionReasonModalKey,
                    allowDownload: _vm.allowDownload,
                  },
                  on: { reviewed: _vm.handleReviewed },
                })
              : _c("div", { staticClass: "alert" }, [
                  _vm._v("\n      Could not load submission\n    "),
                ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }