<template>
  <EditAccountSettingsContainer
    ref="editAccountSettingsContainer"
    :onboardingSmsEnabled="pageContext.onboardingSmsEnabled"
    :ri9SmsEnabled="pageContext.ri9SmsEnabled"
    :settings="pageContext.accountSettings"
    :employeeTypes="pageContext.employeeTypes"
    :notificationTypes="pageContext.notificationTypes"
    :everifyEnabled="pageContext.everifyEnabled"
    :embedded="pageContext.embedded"
  />
</template>

<script>
  import EditAccountSettingsContainer from 'components/admin/account_settings/EditAccountSettingsContainer'
  import { mapGetters } from 'vuex'

  export default {
    name: 'the-edit-account-settings-root',

    components: {
      EditAccountSettingsContainer
    },

    computed: {
      ...mapGetters(['pageContext']),
    },
  }
</script>
