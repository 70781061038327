// displays the first step for I9 completion
// Displays all information, with those that are optional.
// User can add other names, if needed.

<template>
  <div>
    <h2>Personal Information</h2>
    <p>
      The following profile information will be entered into your Form I-9 as you have indicated. If any of this information is incorrect, please
      <span v-if="embedded" ref="embedded-instructions">contact your employer to update it.</span>
      <span v-else ref="instructions"><strong><a :href="editProfilePath">edit your profile</a></strong> now and then return here when you are done.</span>
    </p>
    <form class="simple_form form-horizontal">
      <FormFieldReadOnlyText
        label="Name:"
        :value="employeeName"
      />
      <FormFieldReadOnlyText
        label="Address:"
        :value="employeeAddress"
      />
      <FormFieldReadOnlyText
        label="Birthdate:"
        :value="birthdate"
      />
      <FormFieldReadOnlyText
        v-if="showNonExcludeableSsn"
        label="Social Security Number:"
        :value="ssnValue"
        ref="ssn"
      />
      <FormFieldI9Excludeable
        v-else
        label="Social Security Number:"
        name="ssn"
        :isExcluded="isSsnExcluded"
        :value="maskedSsn"
        @excludeToggle="handleSsnExclusion"
        ref="ssn-excludeable"
      />
      <FormFieldI9Excludeable
        label="Email"
        name="email"
        :isExcluded="isEmailExcluded"
        :value="employee.email"
        @excludeToggle="handleEmailExclusion"
      />
      <FormFieldI9Excludeable
        label="Phone Number"
        name="phone"
        :isExcluded="isPhoneExcluded"
        :value="employee.phone"
        @excludeToggle="handlePhoneExclusion"
      />
      <!-- @input calls back to update model via Backbone -->
      <FormFieldInput
        :helpPopoverText="helpText"
        label="Other Last Names Used (if Any):"
        name="i9_submission[other_names]"
        orientation='horizontal'
        placeholder="N/A"
        :value="this.employee.other_names"
        type="text"
        @input="updateOtherNamesModelCallback"
      />
    </form>
  </div>
</template>

<script>
  import FormFieldInput from 'components/common/FormFieldInput'
  import FormFieldReadOnlyText from 'components/common/FormFieldReadOnlyText'
  import FormFieldI9Excludeable from 'components/i9/FormFieldI9Excludeable'
  import DateTimeUtil from 'lib/util/datetime'
  import EmployeeHelper from 'lib/employee'
  import Locale from 'lib/locale'
  import masks from 'lib/util/masks'
  const EXCLUDED_VALUES = {
    ssn: '',
    email: 'N/A',
    phone: 'N/A'
  }

  export default {
    name: 'i9-personal-step-container',
    components: {
      FormFieldInput,
      FormFieldReadOnlyText,
      FormFieldI9Excludeable
    },

    props: {
      editProfilePath: {
        required: true,
        type: String
      },

      employee: {
        required: true,
        type: Object
      },

      embedded: {
        required: true,
        type: Boolean
      },

      employeeAppliedForSsn: {
        required: true,
        type: Boolean
      },

      everify: {
        required: true,
        type: Boolean
      },

      modelChangeCallback: {
        required: true,
        type: Function
      },

      updateOtherNamesModelCallback: {
        required: true,
        type: Function
      }
    },

    data() {
      return {
        helpText: Locale.t('staff.i9.personalInfo.help_text.other_names_input'),
        isSsnExcluded: false,
        isEmailExcluded: false,
        isPhoneExcluded: false
      }
    },

    computed: {
      birthdate() {
        return DateTimeUtil.formatDate(this.employee.birthdate)
      },
      email () {
        return this.employee._email
      },
      employeeAddress() {
        return EmployeeHelper.employeeAddressWithApartmentNum(this.employee)
      },
      employeeName() {
        return EmployeeHelper.fullNameWithMiddleInitial(this.employee)
      },
      phone () {
        return this.employee._phone
      },
      ssn () {
        return this.employee._ssn
      },
      maskedSsn () {
        const ssn = this.ssn
        return masks.ssn_last4(ssn)
      },
      ssnValue () {
        return this.employeeAppliedForSsn ? this.$locale.t('employeeProfile.applied_for_ssn') : this.maskedSsn
      },
      showNonExcludeableSsn () {
        return this.everify || this.employeeAppliedForSsn
      }
    },
    created () {
      this.setExclusions()
    },
    methods: {
      setExclusions () {
        this.isSsnExcluded = this.employee.ssn === EXCLUDED_VALUES.ssn
        this.isEmailExcluded = this.employee.email === EXCLUDED_VALUES.email
        this.isPhoneExcluded = this.employee.phone === EXCLUDED_VALUES.phone
      },
      handleSsnExclusion (isExcluded) {
        const newVal = isExcluded ? EXCLUDED_VALUES.ssn : this.ssn
        this.modelChangeCallback('ssn', newVal)
        this.isSsnExcluded = isExcluded
      },
      handleEmailExclusion (isExcluded) {
        const newVal = isExcluded ? EXCLUDED_VALUES.email : this.email
        this.modelChangeCallback('email', newVal)
        this.isEmailExcluded = isExcluded
      },
      handlePhoneExclusion (isExcluded) {
        const newVal = isExcluded ? EXCLUDED_VALUES.phone : this.phone
        this.modelChangeCallback('phone', newVal)
        this.isPhoneExcluded = isExcluded
      }
    }
  }
</script>
