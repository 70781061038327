var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "list-group-item wb-list__item" }, [
    _c(
      "div",
      {
        staticClass: "wb-list__row",
        class: { selectable: _vm.selectable, selected: _vm.selected },
      },
      [
        _vm.selectable
          ? _c("div", { staticClass: "wb-list__column-selector" }, [
              _c(
                "div",
                {
                  staticClass: "wb-list__checkbox",
                  on: { click: _vm.toggleSelected },
                },
                [
                  _c("input", {
                    ref: "checkbox",
                    attrs: { type: "checkbox", disabled: _vm.disabled },
                    domProps: { checked: _vm.selected },
                  }),
                ]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "wb-list__container",
            attrs: { href: _vm.href },
            on: { click: _vm.handleClick },
          },
          [_vm._t("default")],
          2
        ),
        _vm._v(" "),
        _vm.secondaryActions
          ? _c(
              "div",
              {
                staticClass:
                  "wb-list__column-actions dropdown dropdown-secondary-actions",
              },
              [
                _c("button", {
                  staticClass: "btn btn-sm dropdown-toggle",
                  attrs: { "data-toggle": "dropdown", role: "menu" },
                }),
                _vm._v(" "),
                _c(
                  "ul",
                  { staticClass: "dropdown-menu dropdown-menu-right" },
                  [_vm._t("secondaryActionsMenuItems")],
                  2
                ),
              ]
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }