export default {
  // Arguments
  //  - val (string): the raw SSN
  //  - maskChar (string): single character that will be the mask
  // Returns masked SSN as string
  ssn_last4: function (val, maskChar) {
    maskChar = maskChar || 'X'

    if (!val || typeof(maskChar) != 'string') return null

    // Remove spaces/hyphens
    const rawSSN = val.replace(/\s|-/g, '')

    // Append last 4 characters of SSN to mask
    return Array(4).join(maskChar[0]) + "-" + Array(3).join(maskChar[0]) + "-" + rawSSN.substring(rawSSN.length - 4)
  }
}
