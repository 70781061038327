<template>
  <ul class="nav navbar-nav navbar-right">
    <li class="dropdown">
      <button class="btn btn-link navbar-btn" data-toggle="dropdown">
        <WbIcon type="user-circle" class="fa-fw" />
        <span class="block-translate">{{userFirstName}}</span>
        <WbIcon type="caret-down" class="fa-fw" />
      </button>
      <ul class="dropdown-menu dropdown-menu-pointy">
        <li class="dropdown-header">
          Signed in as
          <strong class="block-translate">{{currentUser.name}}</strong>
        </li>
        <li class="dropdown-header block-translate">
          {{currentUser.email}}
        </li>
        <li class="divider" />
        <SecondaryNavItem
          v-if="profileItem"
          :item="profileItem"
          ref="profileItem"
        >
          <WbIcon type="user" class="fa-fw" slot="icon" />
        </SecondaryNavItem>
        <li class="divider" />
        <SecondaryNavItem
          v-if="switchAccountItem"
          :item="switchAccountItem"
          ref="switchAccountItem"
        >
          <WbIcon type="exchange" class="fa-fw" slot="icon" />
        </SecondaryNavItem>
        <li>
          <AppSignoutLink />
        </li>
      </ul>
    </li>
  </ul>
</template>
<script>
  import AppSignoutLink from 'components/navbar/AppSignoutLink'
  import SecondaryNavItem from 'components/navbar/SecondaryNavItem'
  import WbIcon from 'components/common/WbIcon'

  export default {
    name: 'staff-navbar-nav',
    components: {
      AppSignoutLink,
      SecondaryNavItem,
      WbIcon
    },
    computed: {
      profileItem () {
        return this.items.profile
      },
      switchAccountItem () {
        return this.items.switch_organization
      },
      userFirstName () {
        return this.currentUser.first_name
      }
    },
    props: {
      currentUser: {
        type: Object
      },
      items: {
        type: Object,
        default: {}
      }
    }
  }
</script>
