import * as BaseCollectionModule from 'vuex/mixins/base_vuex_collection_module'
import PubSubUtil from 'lib/pub_sub_wrapped_action'
import { getRoute } from 'lib/routes'
import { ajax } from 'jquery'

function state() {
  return {
    collection: {},
    pdfProccessing: false
  }
}

const getters = {
  ...BaseCollectionModule.getters,

  pdfProccessing: state => state.pdfProccessing
}

export const mutationTypes = {
  ...BaseCollectionModule.mutationTypes,
}

const mutations = {
  ...BaseCollectionModule.mutations,
  SET_PDF_PROCESSING: (state, isProcessing) => {
    state.pdfProccessing = isProcessing
  }
}

const actions = {
  // Fetch all document submissions for an employee
  fetchAll: ({ dispatch }, { employeeId }) => {
    return new Promise((resolve, reject) => {
      $.ajax({
        method: 'GET',
        url: getRoute('Api.employee_submissions_path', { id: employeeId }),
      }).then(submissions => {
        dispatch('document_submissions/loadInitialData', submissions, { root: true })
        resolve(submissions)
      }).fail(xhr => reject(xhr))
    })
  },

  // Fetch a Submission by id
  fetchSubmission: ({ commit }, submissionId) => {
    return new Promise((resolve, reject) => {
      $.ajax({
        method: 'GET',
        url: getRoute('Api.submission_path', { id: submissionId }),
        wbGenericFailureMsg: "Sorry, we could not fetch the submission.",
        progressBar: false,
      }).done(submission => {
        commit(mutationTypes.SET_RECORD, submission)
        resolve(submission)
      }).fail(xhr => reject(xhr))
    })
  },

  // Fetch Historical Submissions
  //
  // data:
  //  employeeId
  //  documentAssignmentId
  fetchHistoricalSubmissions: ({ commit }, { employeeId, documentAssignmentId }) => {
    return new Promise((resolve, reject) => {
      ajax({
        method: 'GET',
        url: getRoute('Api.historical_employee_document_assignment_path', { id: documentAssignmentId, employee_id: employeeId }),
        wbGenericFailureMsg: "Sorry, we could not fetch prior submissions.",
        progressBar: false,
      }).done(responseData => {
        for (const documentSubmission of responseData) {
          commit(mutationTypes.SET_RECORD, documentSubmission)
        }

        const submissionIds = responseData.map(s => { return s.id })
        resolve(submissionIds)
      }).fail(xhr => reject(xhr))
    })
  },

  fetchSupplementBFormResponse: ({ commit }, data) => {
    const onSubscribeSuccess = ({ submissionId, formOptions, form, channelName}) => {
      return ajax({
        method: 'PATCH',
        type: 'JSON',
        url: getRoute('update_supplement_b_i9_submission_path', { id: submissionId }),
        data: {
          pubsub_channel: channelName,
          id: submissionId,
          form_options: formOptions,
          form: form
        }
      })
    }

    return new Promise((resolve, reject)=>{
      PubSubUtil.pubSubWrappedAction(
        'i9_pdf_update',
        (channelName) => { // onSubscribeSuccess
          onSubscribeSuccess({...data, channelName: channelName})
            .then(()=>{
              commit('SET_PDF_PROCESSING', true)
              resolve()
            })
            .fail((xhr) => {
              reject(xhr)
            })
        },
        () => {  // onComplete
          commit('SET_PDF_PROCESSING', false)
        }
      )
    })
  },

  cancelReverify: (_, { id }) => {
    return new Promise((resolve, reject) => {
      ajax({
        method: 'PATCH',
        type: 'JSON',
        url: getRoute('cancel_reverify_i9_submission_path', { id }),
      }).success((_responseData, _status, xhr) => {
        resolve(xhr)
      }).fail(xhr => {
        reject(xhr)
      })
    })
  },

  beginReverify: (_, { id }) => {
    return new Promise((resolve, reject) => {
      ajax({
        method: 'PATCH',
        type: 'JSON',
        url: getRoute('begin_reverify_i9_submission_path', { id }),
      }).success((_responseData, _status, xhr) => {
        resolve(xhr)
      }).fail(xhr => {
        reject(xhr)
      })
    })
  },

  patchDuplicateReason: (_, { documentSubmissionId, reason, otherExplanation }) => {
    return new Promise((resolve, reject) => {
      ajax({
        method: 'PATCH',
        type: 'JSON',
        url: getRoute('update_duplicate_continue_reason_i9_submission_path', { id: documentSubmissionId }),
        data: {
          duplicate_continue_reason: {
            duplicate_continue_reason_code: reason,
            duplicate_continue_reason_other: otherExplanation
          }
        }
      }).success((_responseData, _status, xhr) => {
        resolve(xhr)
      }).fail(xhr => {
        reject(xhr)
      })
    })
  },

  ...BaseCollectionModule.actions
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
