import AsyncModalItemView from 'views/lib/async_modal_itemview'
import KioskPasskeyModal from 'components/kiosk/KioskPasskeyModal'
import { pureOrphanVue } from 'lib/vue/pureOrphanVue'

export default AsyncModalItemView.extend({
  onRender: function() {
    setTimeout(() => {
      pureOrphanVue(KioskPasskeyModal, 'component#kiosk-passkey-modal', {
        props: {
          command: this.options.command,
          exit: this.options.exit
        }
      })
    }, 100)
  }
})
