var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table", { staticClass: "table table-striped" }, [
    _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("\n        Name\n      ")]),
        _vm._v(" "),
        !_vm.isPendingAdmin
          ? _c("th", { ref: "staffHeader" }, [
              _vm._v("\n        Staff Member File\n      "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("th", [_vm._v("\n        Email\n      ")]),
        _vm._v(" "),
        _vm.isPendingAdmin
          ? _c("th", { ref: "createdHeader" }, [
              _vm._v("\n        Created From\n      "),
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.isPendingAdmin
          ? _c("th", { ref: "accessLevelHeader" }, [
              _vm._v("\n        " + _vm._s(_vm.accessLevelTitle) + "\n      "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("th"),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "tbody",
      _vm._l(_vm.users, function (admin, index) {
        return _c("AdminUsersTableRow", {
          key: index,
          attrs: {
            isPendingAdmin: _vm.isPendingAdmin,
            user: admin,
            managedPermissionsEnabled: _vm.managedPermissionsEnabled,
          },
          on: { update: _vm.handleUpdate },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }