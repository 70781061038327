var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "policy-generator" },
    [
      _c(
        "WbWizard",
        {
          attrs: { loading: _vm.isLoading, nextLabel: _vm.nextLabel },
          on: { previous: _vm.handlePrevStep, next: _vm.nextStep },
        },
        [
          _c(
            "template",
            { slot: "steps" },
            [
              _c(
                "WbOrderedList",
                _vm._l(_vm.stepList, function (step, index) {
                  return _c(
                    "WbOrderedListItem",
                    { key: index, attrs: { active: step.active } },
                    [_vm._v("\n          " + _vm._s(step.label) + "\n        ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "body" },
            [
              _c(_vm.currentStepComponentName, {
                ref: "currentStep",
                tag: "component",
                attrs: {
                  "nav-direction": _vm.navDirection,
                  policy: _vm.localPolicy,
                  firstName: _vm.name,
                },
                on: { skip: _vm.skipStep },
              }),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }