var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "margin-top": "-25px" } },
    [
      _c("FormFieldRadio", {
        staticClass: "ml-4",
        attrs: {
          label: " ",
          required: false,
          options: _vm.formsGroupsOptions,
          errors: _vm.formErrors,
          name: "forms_groups",
          orientation: "vertical",
        },
        model: {
          value: _vm.changeGroupsValue,
          callback: function ($$v) {
            _vm.changeGroupsValue = $$v
          },
          expression: "changeGroupsValue",
        },
      }),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "assignment-root",
          class: {
            hidden: this.changeGroupsValue && this.changeGroupsValue == "keep",
          },
        },
        [
          _c(
            "v-wait",
            { ref: "wait", attrs: { for: _vm.loadingName } },
            [
              _c("Spinner", {
                ref: "spinner",
                attrs: { slot: "waiting", showLongWaitMessage: true },
                slot: "waiting",
              }),
              _vm._v(" "),
              _vm.dataLoaded && _vm.canChangeGroups
                ? _c(
                    "div",
                    { ref: "groupsFilter", staticClass: "groups-filter" },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-4" }, [
                          _c(
                            "h4",
                            { staticClass: "text-strong" },
                            [
                              _vm._v(
                                "\n              Filter Forms By Groups\n              "
                              ),
                              _c("PopoverIcon", {
                                attrs: {
                                  title: "Groups",
                                  message:
                                    "Selected are the Groups that this worker already belongs to. You can add or remove groups to this worker here.",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-sm-6" },
                          [
                            _c("WbMultiselect", {
                              attrs: {
                                id: "select-groups-dropdown",
                                selectOptions: _vm.allGroupsOptions,
                                value: _vm.selectedGroupIds,
                                enableSearchField: true,
                              },
                              on: {
                                input: function (values) {
                                  return _vm.setSelectedGroups(values)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-2" }, [
                          _vm.haveGroupsChanged
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-info cancel-action",
                                  on: { click: _vm.handleClickClearFilters },
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-times-circle",
                                  }),
                                  _vm._v("\n              Reset\n            "),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "wb-list assignment-list" }, [
                _c(
                  "p",
                  { staticClass: "mt-2" },
                  [
                    _c("span", { staticClass: "lead" }, [
                      _vm._v("\n            New Forms\n          "),
                    ]),
                    _vm._v(" "),
                    _c("PopoverIcon", {
                      attrs: {
                        title: "New Forms",
                        message:
                          "These are all of the forms in the group(s) you added above. The staff member will be requested to complete all of these forms.",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "new-forms-toggle",
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function ($event) {
                        return _vm.toggleNewAssignments()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.pluralizedNewForms) +
                        " will be requested\n          "
                    ),
                    !_vm.showNewAssignments
                      ? _c("i", { staticClass: "fa fa-caret-right ml-1" })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showNewAssignments
                      ? _c("i", { staticClass: "fa fa-caret-down ml-1" })
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _vm.showNewAssignments
                  ? _c(
                      "ul",
                      { ref: "new-forms", staticClass: "list-group" },
                      _vm._l(_vm.newAssignments, function (assignment) {
                        return _c("DocumentAssignmentListItem", {
                          key: assignment.id,
                          attrs: {
                            assignment: assignment,
                            groups: _vm.allGroupsOptions,
                            selectedGroupIds: _vm.selectedGroupIds,
                            collectionViewName: _vm.collectionViewName,
                            selectable: true,
                            disabled: true,
                            defaultSelected: true,
                            includeSecondaryActionMenu: false,
                            includeGroupsColumn: true,
                            clickableRow: false,
                            pendingStatus: "assignment_pending",
                          },
                        })
                      }),
                      1
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.dataLoaded
                ? _c(
                    "div",
                    { staticClass: "wb-list assignment-list" },
                    [
                      _c(
                        "p",
                        { staticClass: "mb-0" },
                        [
                          _c("span", { staticClass: "lead" }, [
                            _vm._v("\n            Current Forms\n          "),
                          ]),
                          _vm._v(" "),
                          _c("PopoverIcon", {
                            attrs: {
                              title: "Current Forms",
                              message:
                                "Checked forms below indicate those that will be requested as a resubmission when Rehired, based on the configuration at the Form level. You can add or remove any form. If you check or uncheck a form, you are electing to override the configuration (at the Form level) for this particular worker.",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.canChangeGroups
                        ? _c("div", [
                            _c("small", [
                              _vm._v(
                                "\n            Showing all forms in all groups selected above.\n          "
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "WbListHeader",
                        {
                          attrs: {
                            filteredRowIds: _vm.filteredReassignmentIds,
                            availableSelections: _vm.filteredReassignments,
                            collectionViewName: _vm.collectionViewName,
                            searchPlaceholder: "Search Form Name",
                            selectable: true,
                          },
                        },
                        [
                          _c("template", { slot: "bulkActions" }, [
                            _vm._v(
                              "\n            New submissions will be requested for the selected forms\n          "
                            ),
                          ]),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm.reassignments.length > 0 &&
                      _vm.filteredReassignments.length == 0
                        ? _c("p", { staticClass: "m-4" }, [
                            _vm._v(
                              "\n          No matching assigned forms\n        "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.reassignments.length == 0
                        ? _c("p", { staticClass: "m-4" }, [
                            _vm._v(
                              "\n          No assigned forms exist for this staff member\n        "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "ul",
                        { ref: "current-forms", staticClass: "list-group" },
                        _vm._l(
                          _vm.filteredReassignments,
                          function (assignment) {
                            return _c("DocumentAssignmentListItem", {
                              key: assignment.id,
                              attrs: {
                                assignment: assignment,
                                groups: _vm.allGroupsOptions,
                                selectedGroupIds: _vm.selectedGroupIds,
                                collectionViewName: _vm.collectionViewName,
                                selectable: true,
                                includeSecondaryActionMenu: false,
                                includeGroupsColumn: true,
                                clickableRow: false,
                              },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }