export default Backbone.Model.extend({

  // List all field labels associated with this Employee
  //
  // Returns array of strings
  // ['First Name', 'Last Name', 'Email', 'Employee Type', ...]
  fieldLabels: function(){
    return _.map(this.get('fields'), function(f){return f.label})
  }
})
