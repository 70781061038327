var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "alert alert-danger" },
    [
      _vm._v(
        "\n  Please make sure that all required staff information is saved before scheduling notifications.\n  "
      ),
      _vm.hasErrors
        ? _vm._l(Object.entries(_vm.errors), function (ref) {
            var errorSource = ref[0]
            var error = ref[1]
            return _c(
              "dl",
              {
                key: errorSource,
                ref: "errors",
                refInFor: true,
                staticClass: "mt-1 mb-0",
              },
              [
                _c("dt", [_vm._v(_vm._s(errorSource))]),
                _vm._v(" "),
                _c("dd", [
                  _c(
                    "ul",
                    { staticClass: "list-unstyled" },
                    _vm._l(error, function (errorItem, index) {
                      return _c("li", { key: index }, [
                        _vm._v(
                          "\n            " + _vm._s(errorItem) + "\n          "
                        ),
                      ])
                    }),
                    0
                  ),
                ]),
              ]
            )
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }