var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AlertMessage",
    { staticClass: "mt-3", attrs: { variant: "danger" } },
    [
      _c("h4", [_vm._v("Close Case")]),
      _vm._v(" "),
      _vm.hasClosureReasons
        ? _c(
            "div",
            { ref: "closeUi" },
            [
              _vm.instructions
                ? _c("p", {
                    ref: "instructions",
                    domProps: { innerHTML: _vm._s(_vm.instructions) },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("FormFieldDropdown", {
                    ref: "closeCaseReason",
                    staticClass: "d-inline-block",
                    attrs: {
                      name: "close_case_reason",
                      label: "Close Case Reason",
                      options: _vm.caseCloseReasonOptions,
                      errors: _vm.errors,
                      placeholder: "Reason",
                    },
                    model: {
                      value: _vm.close_case_reason.case_closure_reason_code,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.close_case_reason,
                          "case_closure_reason_code",
                          $$v
                        )
                      },
                      expression: "close_case_reason.case_closure_reason_code",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.otherReasonSelected
                ? _c(
                    "div",
                    [
                      _c("FormFieldTextArea", {
                        ref: "otherReason",
                        staticClass: "d-inline-block",
                        attrs: {
                          name: "other_reason",
                          label: "Other Reason",
                          type: "text",
                          autoFocus: true,
                          errors: _vm.errors,
                          placeholder: "Reason for case closure",
                        },
                        model: {
                          value: _vm.close_case_reason.other_reason_description,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.close_case_reason,
                              "other_reason_description",
                              $$v
                            )
                          },
                          expression:
                            "close_case_reason.other_reason_description",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("WbButton", {
                ref: "closeCaseButton",
                staticClass: "mt-2",
                attrs: {
                  context: "default",
                  label: "Close Case",
                  isLoading: _vm.isCloseCaseLoading,
                },
                on: { click: _vm.handleCloseCase },
              }),
            ],
            1
          )
        : _c("p", { ref: "cantCloseMessage" }, [
            _c("em", [_vm._v("Case cannot be manually closed at this time.")]),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }