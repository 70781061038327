var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("schedule-onboarding-modal", {
        attrs: { employee: _vm.employee, employment: _vm.employment },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "panel panel-simple" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "panel-body" }, [
          _c("p", { ref: "status-message" }, [
            _vm._v("\n        " + _vm._s(_vm.statusMessage) + "\n      "),
          ]),
        ]),
        _vm._v(" "),
        _c("table", { staticClass: "table info-table panel-info-table" }, [
          _c("tbody", [
            _c("tr", { staticClass: "info-item" }, [
              _c("td", { staticClass: "col-md-5" }, [
                _vm._v("\n            Notification Start Date:\n          "),
              ]),
              _vm._v(" "),
              _c(
                "td",
                { staticClass: "col-md-7" },
                [
                  !_vm.isOnboardingStartDateRestricted
                    ? _c("wb-simple-date-time", {
                        ref: "notification-start",
                        attrs: {
                          value: _vm.employment.onboarding_start_date || "",
                          invalidMessage: "Unscheduled",
                          format: _vm.$constants.DATE_FORMAT,
                        },
                      })
                    : _c("blocked-content-icon"),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "float-right" },
                    [
                      _c(
                        "wb-button",
                        {
                          ref: "scheduleButton",
                          attrs: {
                            label: "Schedule",
                            context: "primary",
                            permissionModel: "employee_seasons",
                            permissionRecordId: _vm.employment.id,
                            permissionAction: "manage",
                          },
                          on: { click: _vm.schedule },
                        },
                        [
                          _vm._v(
                            "\n                Schedule Notifications\n              "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("tr", { staticClass: "info-item" }, [
              _c("td", { staticClass: "col-md-5" }, [
                _vm._v(
                  "\n            Initial Notification Sent At:\n          "
                ),
              ]),
              _vm._v(" "),
              _c(
                "td",
                { staticClass: "col-md-7" },
                [
                  _c("wb-simple-date-time", {
                    ref: "notification-sent",
                    attrs: {
                      value: _vm.employment.onboarding_email_sent_at || "",
                      invalidMessage: _vm.emailNotSent,
                      format: _vm.$constants.DATE_FORMAT,
                    },
                  }),
                  _vm._v(" "),
                  _c("PopoverIcon", {
                    attrs: {
                      title: "Initial Notification Sent At",
                      message:
                        "This is the date when the first notification is sent to the staff member. It is set when the first notification is sent and does not change unless the staff member is deactivated or their notifications are turned off.",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("tr", { staticClass: "info-item" }, [
              _c("td", { staticClass: "col-md-5" }, [
                _vm._v(
                  "\n            Currently Receiving Notifications:\n          "
                ),
              ]),
              _vm._v(" "),
              _c(
                "td",
                { ref: "receiving-notifications", staticClass: "col-md-7" },
                [
                  _vm.active
                    ? _c("div", [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.employment.onboarding_now ? "Yes" : "Not Yet"
                            ) +
                            "\n            "
                        ),
                      ])
                    : _c("div", [_vm._v("\n              No\n            ")]),
                ]
              ),
            ]),
            _vm._v(" "),
            _vm.shouldShowRI9NotificationMethodsToggle
              ? _c(
                  "tr",
                  { ref: "ri9-notification-methods", staticClass: "info-item" },
                  [
                    _c("td", { staticClass: "col-md-5" }, [
                      _vm._v(
                        "\n            Remote I-9 Notification Method:\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "col-md-7" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          _c("EmployeeNotificationMethodsToggle", {
                            attrs: {
                              disabled: _vm.isLoadingRI9NotificationPreferences,
                              type: "ri9",
                              value: _vm.employeeRI9NotificationMethods,
                            },
                            on: { input: _vm.handleRI9NotificationChange },
                          }),
                          _vm._v(" "),
                          !_vm.hasPhoneNumber
                            ? _c("PopoverIcon", {
                                staticClass: "pt-1",
                                attrs: {
                                  title: "No Staff Phone Number",
                                  type: "danger",
                                  message: _vm.$locale.t(
                                    "employees.notificationMethods.popoverText"
                                  ),
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.shouldShowOnboardingNotificationMethodsToggle
              ? _c(
                  "tr",
                  {
                    ref: "onboarding-notification-methods",
                    staticClass: "info-item",
                  },
                  [
                    _c("td", { staticClass: "col-md-5" }, [
                      _vm._v(
                        "\n            Onboarding Notification Method:\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "col-md-7" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          _c("EmployeeNotificationMethodsToggle", {
                            attrs: {
                              disabled:
                                _vm.isLoadingOnboardingNotificationPreferences,
                              type: "onboarding",
                              value: _vm.employeeOnboardingNotificationMethods,
                            },
                            on: {
                              input: _vm.handleOnboardingNotificationChange,
                            },
                          }),
                          _vm._v(" "),
                          !_vm.hasPhoneNumber
                            ? _c("PopoverIcon", {
                                staticClass: "pt-1",
                                attrs: {
                                  title: "No Staff Phone Number",
                                  type: "danger",
                                  message: _vm.$locale.t(
                                    "employees.notificationMethods.popoverText"
                                  ),
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.hasValidSmsStatus
              ? _c("tr", { ref: "sms-status", staticClass: "info-item" }, [
                  _c("td", { staticClass: "col-md-5" }, [
                    _vm._v("\n            Text Messaging Status:\n          "),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "col-md-7" }, [
                    _vm._v(
                      "\n            " + _vm._s(_vm.smsStatus) + "\n          "
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("tr", { staticClass: "info-item" }, [
              _c("td", { staticClass: "col-md-5" }, [
                _vm._v("\n            Last Sign In:\n          "),
              ]),
              _vm._v(" "),
              _c(
                "td",
                { staticClass: "col-md-7" },
                [
                  _c("wb-simple-date-time", {
                    ref: "last-sign-in",
                    attrs: {
                      value: _vm.currentSignInAt || "",
                      invalidMessage: "Never signed in",
                      format: _vm.$constants.DATE_FORMAT,
                    },
                  }),
                  _vm._v(" "),
                  _vm.allowResend
                    ? _c(
                        "div",
                        { staticClass: "float-right" },
                        [
                          _c(
                            "ladda-button",
                            { ref: "resendButton", on: { click: _vm.resend } },
                            [
                              _vm._v(
                                "\n                Resend Welcome " +
                                  _vm._s(_vm.preferredNotificationName) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel-heading with-action-buttons" }, [
      _c("h3", { staticClass: "panel-title" }, [
        _vm._v("\n        Notification Status\n      "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }