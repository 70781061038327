var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "WbModal",
    {
      ref: "modal",
      attrs: {
        title: _vm.title,
        watchPageContextVariable: "i9_submission_id",
        largeModal: true,
      },
      on: { modalShown: _vm.onOpen, modalHidden: _vm.onClose },
    },
    [
      _vm._t("default", [
        _vm.supplementBFull
          ? _c("p", { ref: "errorMsg", staticClass: "alert alert-warning" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.sectionName) +
                  " for this Form I-9 has already been " +
                  _vm._s(_vm.updatedText) +
                  ". If you need to make additional updates, please request the staff to submit a new Form I-9.\n    "
              ),
            ])
          : _c("div", [
              _c(
                "div",
                [
                  _c("p", [
                    _vm._v(
                      _vm._s(_vm.sectionName) +
                        " allows you to reverify the documentation of an Form I-9 submission when that documentation expires or a staff member is rehired. Select which options you would like below:"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group mt-3" }, [
                    _c("div", { staticClass: "btn-group" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-default",
                          class: { active: _vm.isReverifying },
                          on: {
                            click: function ($event) {
                              _vm.isReverifying = !_vm.isReverifying
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n              Reverify documentation\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-default",
                          class: { active: _vm.isRehiring },
                          on: {
                            click: function ($event) {
                              _vm.isRehiring = !_vm.isRehiring
                            },
                          },
                        },
                        [_vm._v("\n              Rehire staff\n            ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-default",
                          class: { active: _vm.isNameChange },
                          on: {
                            click: function ($event) {
                              _vm.isNameChange = !_vm.isNameChange
                            },
                          },
                        },
                        [_vm._v("\n              Change name\n            ")]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  !_vm.shouldShowForm && _vm.submittedWithoutOption
                    ? _c("AlertMessage", {
                        ref: "no_option_selected",
                        attrs: {
                          title: "No Option Selected",
                          message:
                            "Please select one or more options above to proceed",
                          variant: "danger",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.shouldShowForm
                ? _c(
                    "div",
                    { ref: "form" },
                    [
                      _vm.isReverifying
                        ? _c("FormFieldDropdown", {
                            ref: "reverifyDocumentTitle",
                            attrs: {
                              name: "reverify_doc_title",
                              orientation: "vertical",
                              label: "Document Title",
                              required: true,
                              options: _vm.i9DocumentationChoicesOptions,
                              errors: _vm.errors,
                              placeholder: "Document Title",
                            },
                            model: {
                              value: _vm.formData.reverify_doc_title,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "reverify_doc_title",
                                  $$v
                                )
                              },
                              expression: "formData.reverify_doc_title",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isReverifying
                        ? _c("FormFieldInput", {
                            ref: "reverifyDocumentNumber",
                            attrs: {
                              name: "reverify_doc_number",
                              orientation: "vertical",
                              label: "Document Number",
                              type: "text",
                              required: true,
                              errors: _vm.errors,
                              placeholder: "9876543",
                            },
                            model: {
                              value: _vm.formData.reverify_doc_number,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "reverify_doc_number",
                                  $$v
                                )
                              },
                              expression: "formData.reverify_doc_number",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isReverifying
                        ? _c("FormFieldDate", {
                            ref: "reverifyExpirationDate",
                            attrs: {
                              name: "reverify_exp_date",
                              orientation: "vertical",
                              label: "Expiration Date",
                              type: "text",
                              required: true,
                              errors: _vm.errors,
                            },
                            model: {
                              value: _vm.formData.reverify_exp_date,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "reverify_exp_date", $$v)
                              },
                              expression: "formData.reverify_exp_date",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.shouldShowAltProcedureCheckbox
                        ? _c("FormFieldCheckbox", {
                            ref: "reverifyUsedAltProcedure",
                            attrs: {
                              name: "reverify_used_alt_procedure",
                              label:
                                "Check this box if you used an alternative procedure authorized by DHS to examine documents.",
                              errors: _vm.errors,
                              gridColumns: "col-xs-12",
                            },
                            model: {
                              value: _vm.formData.reverify_used_alt_procedure,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "reverify_used_alt_procedure",
                                  $$v
                                )
                              },
                              expression:
                                "formData.reverify_used_alt_procedure",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isRehiring
                        ? _c("FormFieldDate", {
                            ref: "rehireDate",
                            attrs: {
                              name: "rehire_date",
                              orientation: "vertical",
                              label: "Rehire Date",
                              type: "text",
                              required: true,
                              errors: _vm.errors,
                            },
                            model: {
                              value: _vm.formData.rehire_date,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "rehire_date", $$v)
                              },
                              expression: "formData.rehire_date",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "row" },
                        [
                          _vm.isNameChange
                            ? _c("FormFieldInput", {
                                ref: "newFirstName",
                                staticClass: "col-sm-8",
                                attrs: {
                                  name: "new_first_name",
                                  orientation: "vertical",
                                  label: "Updated First Name",
                                  type: "text",
                                  required: true,
                                  errors: _vm.errors,
                                },
                                model: {
                                  value: _vm.formData.new_first_name,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "new_first_name",
                                      $$v
                                    )
                                  },
                                  expression: "formData.new_first_name",
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isNameChange
                            ? _c("FormFieldInput", {
                                ref: "newMiddleInitial",
                                staticClass: "col-sm-4",
                                attrs: {
                                  name: "new_middle_initial",
                                  orientation: "vertical",
                                  label: "Updated Middle Initial",
                                  type: "text",
                                  required: false,
                                  errors: _vm.errors,
                                },
                                model: {
                                  value: _vm.formData.new_middle_initial,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "new_middle_initial",
                                      $$v
                                    )
                                  },
                                  expression: "formData.new_middle_initial",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.isNameChange
                        ? _c("FormFieldInput", {
                            ref: "newLastName",
                            attrs: {
                              name: "new_last_name",
                              orientation: "vertical",
                              label: "Updated Last Name",
                              type: "text",
                              required: true,
                              errors: _vm.errors,
                            },
                            model: {
                              value: _vm.formData.new_last_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "new_last_name", $$v)
                              },
                              expression: "formData.new_last_name",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("FormFieldTextArea", {
                        ref: "additionalInformation",
                        staticClass: "mb-3",
                        attrs: {
                          orientation: "vertical",
                          label: "Additional Information",
                          type: "text",
                          required: false,
                          errors: _vm.errors,
                          placeholder: "Notes for future reference",
                        },
                        model: {
                          value: _vm.formData.additional_info,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "additional_info", $$v)
                          },
                          expression: "formData.additional_info",
                        },
                      }),
                      _vm._v(" "),
                      _c("PerjuryStatement", {
                        attrs: { statement: this.perjuryStatement },
                      }),
                      _vm._v(" "),
                      _c("SignaturePad", {
                        ref: "signaturePad",
                        attrs: {
                          nameField: _vm.formData.adminSignatureNameField,
                          errors: _vm.errors,
                        },
                        model: {
                          value: _vm.formData.adminSignature,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "adminSignature", $$v)
                          },
                          expression: "formData.adminSignature",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "button",
            {
              staticClass: "btn btn-default",
              attrs: { disabled: false },
              on: { click: _vm.onCancelled },
            },
            [_vm._v("\n      " + _vm._s(_vm.closeText) + "\n    ")]
          ),
          _vm._v(" "),
          !_vm.supplementBFull
            ? _c("WbButton", {
                ref: "submitButton",
                attrs: { label: "Submit", isLoading: _vm.isLoading },
                on: { click: _vm.submit },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }