var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _c("label", [
      _c(
        "a",
        {
          directives: [
            {
              name: "popover",
              rawName: "v-popover",
              value: _vm.title,
              expression: "title",
            },
          ],
          staticClass: "info-popover",
          class: _vm.popoverClassName,
          attrs: {
            "data-content": _vm.message,
            role: "button",
            tabindex: "0",
            trigger: "focus",
          },
        },
        [_c("WbIcon", { attrs: { type: _vm.iconType } })],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }