var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", [
    _c("td", [_vm._v(_vm._s(_vm.documentName))]),
    _vm._v(" "),
    _c(
      "td",
      [
        _c("permissions-toggle", {
          attrs: { permission: _vm.viewPermission, label: false },
          on: { permissionChanged: _vm.permissionChanged },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "td",
      _vm._l(_vm.otherPermissions, function (permission, index) {
        return _c(
          "div",
          { key: index },
          [
            _vm.viewPermission.grant
              ? _c("permissions-toggle", {
                  attrs: { permission: permission, label: true },
                  on: { permissionChanged: _vm.permissionChanged },
                })
              : _vm._e(),
          ],
          1
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("td", { staticClass: "text-center" }, [
      _vm.viewPermission.target_id
        ? _c("i", {
            staticClass: "fa fa-times-circle delete-rule",
            on: { click: _vm.remove },
          })
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }