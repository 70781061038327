var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "panel profile mt-0" }, [
    _vm.isFanReviewed
      ? _c("div", [
          _vm.isI9Incorrect
            ? _c("p", { ref: "incorrectI9Message" }, [
                _vm._v(
                  "\n      Your employer will contact you with additional steps to correct your information.\n    "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isI9Correct
            ? _c("p", { ref: "correctI9Message" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$locale.t(
                        "everify.status.pending_referral." +
                          _vm.localEverifyCase.tnc_action
                      )
                    ) +
                    "\n    "
                ),
              ])
            : _vm._e(),
        ])
      : _c(
          "div",
          { ref: "form" },
          [
            _c("h4", [
              _vm._v(
                "Please read and review the document and fill out the information below:"
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "form-input",
                class: { "has-error": _vm.tncReviewHasError },
              },
              [
                _c("p", [_vm._v("The information of my Form I-9 is:")]),
                _vm._v(" "),
                _c("WbMultiselect", {
                  ref: "multiselect",
                  staticClass: "no-wrap",
                  attrs: {
                    name: "tnc_review",
                    selectOptions: _vm.localOptions,
                    value: _vm.tncReview,
                    multiple: true,
                  },
                  model: {
                    value: _vm.tncReview,
                    callback: function ($$v) {
                      _vm.tncReview = $$v
                    },
                    expression: "tncReview",
                  },
                }),
                _vm._v(" "),
                _c("small", { staticClass: "help-block" }, [
                  _vm._v(_vm._s(_vm.tncReviewErrorMessage)),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _vm.incorectOptionsDisabled
              ? _c(
                  "div",
                  {
                    ref: "tncAction",
                    staticClass: "mt-4",
                    class: { "has-error": _vm.tncActionHasError },
                  },
                  [
                    _c("p", [
                      _vm._v(
                        "\n        Decide if you want to resolve your E-Verify case and mark your decision:\n      "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("FormFieldCheckbox", {
                      ref: "tncActionCheckbox",
                      staticClass: "ml-2 mb-0",
                      attrs: {
                        label: "I WILL take action to resolve this mismatch",
                        gridColumns: "col-xs-12",
                        wrapperClass: { checkbox: false },
                      },
                      model: {
                        value: _vm.tncRefer,
                        callback: function ($$v) {
                          _vm.tncRefer = $$v
                        },
                        expression: "tncRefer",
                      },
                    }),
                    _vm._v(" "),
                    _c("FormFieldCheckbox", {
                      ref: "tncNoActionCheckbox",
                      staticClass: "ml-2 mb-0",
                      attrs: {
                        label:
                          "I WILL NOT take action to resolve this mismatch",
                        gridColumns: "col-xs-12",
                        wrapperClass: { checkbox: false },
                      },
                      model: {
                        value: _vm.tncNoAction,
                        callback: function ($$v) {
                          _vm.tncNoAction = $$v
                        },
                        expression: "tncNoAction",
                      },
                    }),
                    _vm._v(" "),
                    _c("small", { staticClass: "help-block" }, [
                      _vm._v(_vm._s(_vm.tncActionErrorMessage)),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("FormFieldCheckbox", {
              ref: "fanReviewedCheckbox",
              attrs: {
                name: "fan_reviewed",
                label: "I have reviewed the Further Action Notice",
                errors: _vm.errors,
                gridColumns: "col-xs-12",
              },
              model: {
                value: _vm.fanReviewed,
                callback: function ($$v) {
                  _vm.fanReviewed = $$v
                },
                expression: "fanReviewed",
              },
            }),
            _vm._v(" "),
            _c("WbButton", {
              ref: "submit",
              attrs: { label: "Submit", isLoading: _vm.isLoading },
              on: { click: _vm.submit },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }