<template>
  <SlideoutPanel
    watchPageContextVariable="show_download_slideout_panel"
  >
    <h3 slot="title" v-if="!!downloadRequest">
      {{downloadRequest.name}}
    </h3>
    <template v-if="isLoading">
      <Spinner ref="loader" />
    </template>
    <template v-else>
      <ul class="list-group" v-if="hasDownloads" ref="download-list">
        <li
          class="list-group-item"
          v-for="(downloadPart, index) in downloadParts"
          :key="index"
        >
          <DownloadPart
            :downloadPart="downloadPart"
            :index="index"
          />
        </li>
      </ul>
      <div v-else ref="default-content">
        <em>No downloads available</em>
      </div>
    </template>
  </SlideoutPanel>
</template>
<script>
  import SlideoutPanel from 'components/common/SlideoutPanel'
  import DownloadPart from 'components/admin/downloads/DownloadPart'
  import Spinner from 'components/common/Spinner'

  import { mapActions, mapGetters } from 'vuex'

  export default {
    components: {
      DownloadPart,
      SlideoutPanel,
      Spinner
    },
    computed: {
      ...mapGetters({
        downloadPartsByRequestId: 'admin_downloads/downloadPartsByRequestId'
      }),
      downloadParts () {
        let val = null
        if(this.downloadRequest) {
          val = this.downloadPartsByRequestId && this.downloadPartsByRequestId[this.downloadRequest.id]
        }
        return val
      },
      downloadRequestId () {
        return this.downloadRequest && this.downloadRequest.id
      },
      hasDownloads () {
        return this.downloadParts && this.downloadParts.length
      }
    },
    created () {
      this.requestDownloadParts()
    },
    data () {
      return {
        isLoading: false
      }
    },
    methods: {
      ...mapActions({
        fetchDownloadPartsForId: 'admin_downloads/fetchDownloadPartsForId'
      }),
      requestDownloadParts () {
        if (this.downloadRequestId) {
          this.isLoading = true
          const promise = this.fetchDownloadPartsForId(this.downloadRequestId)
          promise
            .catch(() => {}) // Fail silently ??
            .finally(() => {
              this.isLoading = false
            })
        }
      }
    },
    props: {
      downloadRequest: {
        type: Object
      }
    },
    watch: {
      downloadRequestId () {
        this.requestDownloadParts()
      }
    }
  }
</script>
