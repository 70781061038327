var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "document-submission-index-root" },
    [
      _c(
        "div",
        { staticClass: "d-flex justify-content-between align-items-center" },
        [
          _c("PageHeader", { attrs: { title: "Inbox" } }),
          _vm._v(" "),
          _c("WbSearchInput", {
            ref: "search",
            staticClass: "pull-right",
            attrs: {
              label: null,
              placeholder: "Search",
              autofocus: true,
              value: _vm.query,
              throttle: true,
            },
            on: { input: _vm.handleSearchInput },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isLoading
        ? _c("Spinner", { ref: "loader" })
        : [
            _vm.hasSufficientData
              ? [
                  _c("DocumentSubmissionsInboxTable", {
                    ref: "inboxTable",
                    attrs: { submissions: _vm.submissions, sort: _vm.sort },
                    on: {
                      sort: _vm.handleSortUpdate,
                      submissionSelected: _vm.handleSubmissionSelected,
                    },
                  }),
                  _vm._v(" "),
                  _c("WbListPaginationFooter", {
                    ref: "pagination",
                    attrs: {
                      previousPage: _vm.previousPage,
                      nextPage: _vm.nextPage,
                      lastPage: _vm.lastPage,
                      totalItems: _vm.totalItems,
                      indexStart: _vm.indexStart,
                      indexEnd: _vm.indexEnd,
                    },
                    on: { pageChange: _vm.handlePageChange },
                  }),
                ]
              : _c("AlertMessage", {
                  ref: "noDataMessage",
                  attrs: {
                    variant: "info",
                    message: "There are currently no items to display",
                  },
                }),
          ],
      _vm._v(" "),
      _c("DocumentSubmissionSlideout", {
        ref: "DocumentSubmissionSlideout",
        attrs: {
          shown: _vm.shouldShowModal,
          submissionId: _vm.selectedSubmissionId,
          allowDownload: _vm.allowDownload,
        },
        on: {
          hidden: _vm.handleModalHiddden,
          reviewed: _vm.openNextSubmission,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }