<template>
  <i
    :class="className"
    role="presentation"
  />
</template>
<script>
  export default {
    name: 'wb-icon',
    computed: {
      className () {
        return `${this.iconSet} ${this.iconSet}-${this.type}`
      }
    },
    props: {
      type: {
        type: String,
        required: true
      },
      iconSet: {
        type: String,
        default: 'fa'
      }
    }
  }
</script>
