var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h4", [
      _vm._v(
        "First, what groups should " +
          _vm._s(_vm.firstName) +
          " have access to?"
      ),
    ]),
    _vm._v(" "),
    _vm.errorMessage
      ? _c("div", { ref: "errorMessage", staticClass: "alert alert-danger" }, [
          _vm._v("\n    " + _vm._s(_vm.errorMessage) + "\n  "),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.noGroups
      ? _c("div", { ref: "noGroups" }, [
          _c("span", [_vm._v("Please")]),
          _vm._v(" "),
          _c("a", { attrs: { href: _vm.$routes.new_employee_group_path } }, [
            _vm._v("create a group"),
          ]),
          _vm._v(" "),
          _c("span", [_vm._v("in order to create permissions.")]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("form", { staticClass: "form-horizontal" }, [
      _c(
        "div",
        { staticClass: "form-group" },
        _vm._l(_vm.selectableGroups, function (selectableGroup, index) {
          return _c("group-select-checkbox", {
            key: index,
            attrs: { group: selectableGroup },
            on: { groupUpdated: _vm.updateScopeSelection },
          })
        }),
        1
      ),
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "breathe above" }, [
      _c("em", [
        _vm._v(
          "You'll be able to define exactly what " +
            _vm._s(_vm.firstName) +
            " can and cannot do within these groups in a moment."
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }