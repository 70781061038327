var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.nonPermittedIds.length
      ? _c(
          "div",
          [
            _c("bulk-action-warning", {
              attrs: {
                message:
                  "You are <strong>not authorized</strong> to perform this action for:",
                collection: _vm.collectionGetBatch(_vm.nonPermittedIds),
                displayNameLookupFn: _vm.displayNameLookupFn,
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.nonActionableIds.length
      ? _c(
          "div",
          [
            _c("bulk-action-warning", {
              attrs: {
                message: _vm.actionErrorMessage,
                collection: _vm.collectionGetBatch(_vm.nonActionableIds),
                displayNameLookupFn: _vm.displayNameLookupFn,
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.allowableActions
      ? _c("div", [_vm._m(0)])
      : _c("div", [
          _c("p", { staticClass: "mt-4" }, [
            _vm._v(
              "\n      None of the selected items are allowed. Select different rows to try again.\n    "
            ),
          ]),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mt-4" }, [
      _c("strong", [
        _vm._v(
          "By choosing Next, all items listed above will be unselected and no action will be performed on them."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }