<template>
  <wb-modal
    title="ADP® Workforce Now Integration"
    watchPageContextVariable="show_adp_integration_modal"
    :locked="$wait.is('show_adp_integration_modal')"
    ref="modal"
    @modalShown="handleModalShown"
    @modalHidden="handleModalHidden"
  >
    <v-wait :for="loadingName">
      <spinner :showLongWaitMessage="true" slot="waiting" />
      <div v-if="hasError" class="alert alert-danger p-3" ref="syncError">
        Something went wrong when syncing data with ADP®
        <hr />
        <div class="text-strong">{{ errorData.title }}</div>
        <p>
          {{ errorData.message }}
        </p>
      </div>
      <div v-else ref="ready">
        <div>
          <form-field-select
            :required="true"
            :options="templatesSelectFormatted"
            v-model="selectedTemplate"
            placeholder="Select a template"
            label="ADP® Onboarding Template"
            fieldWidth="medium-field"
          />

          <form-field-select
            :required="true"
            :options="companyCodesSelectFormatted"
            v-model="selectedCompanyCode"
            placeholder="Select a Company Code"
            label="Company Code"
            fieldWidth="medium-field"
          />

          The following data will be automatically synced for {{employee.first_name}}:
          <div class="alert alert-info">
            <dl>
              <dt>Basic Profile Data</dt>
              <dd>First Name, Last Name, SSN, Birthdate, Address, Email, Phone, Gender</dd>
            </dl>
            <dl>
              <dt>Form W-4 Data</dt>
              <dd>Filing Status, Additional Income, Extra Withholding, Withholding Status, Deductions, Dependents</dd>
            </dl>
            <dl class="mb-0">
              <dt>Payroll Data</dt>
              <dd>Pay Frequency, Pay Rate, Pay Amount</dd>
            </dl>
          </div>

          <hr />

          <div>
            The staff member's Direct Deposit data <strong>will not be automatically synced</strong> until you update them from "In-Progress" to "Employee" in ADP® Workforce Now. Once that happens, we will automatically sync this data:
          </div>
          <div class="alert alert-secondary">
            <dl class="mb-0">
              <dt>Direct Deposit Data</dt>
              <dd>Account Number, Routing Number, Account Type</dd>
            </dl>
          </div>
        </div>
      </div>
    </v-wait>

    <div slot="footer">
      <button
        class="btn btn-default"
        :disabled="$wait.is('show_adp_integration_modal')"
        @click="hide"
        ref="closeButton"
      >
        {{ canSubmit ? 'Cancel' : 'Close' }}
      </button>
      <ladda-button v-if="canSubmit" ref="submit" @click="submit" :disabled="submitDisabled">
        <i class="fa fa-upload" />
        Sync to ADP® Workforce Now
      </ladda-button>
    </div>
  </wb-modal>
</template>

<script>
  import { mapGetters } from 'vuex'
  import LaddaButton from 'components/common/LaddaButton'
  import FormFieldSelect from 'components/common/FormFieldSelect'
  import WbModal from 'components/common/WbModal'
  import Spinner from 'components/common/Spinner'
  import Util from 'lib/util'
  import { ajax } from 'jquery'

  export default {
    name: 'adp-integration-modal',

    components: {
      LaddaButton,
      WbModal,
      Spinner,
      FormFieldSelect
    },

    props: {
      employeeId: {
        requred: true,
        type: Number
      }
    },

    data() {
      return {
        loadingName: 'adp_info_loading',
        errorData: {},
        templates: [],
        companyCodes: [],
        selectedTemplate: null,
        selectedCompanyCode: null
      }
    },

    computed: {
      canSubmit() {
        return !this.$wait.is(this.loadingName) && !this.hasError
      },

      submitDisabled(){
        return !this.selectedTemplate || !this.selectedCompanyCode
      },

      employee() {
        return this.getEmployee(this.employeeId)
      },

      templatesSelectFormatted() {
        return Object.keys(this.templates).map(key => ({ value: key, label: this.templates[key] }))
      },

      companyCodesSelectFormatted() {
        return this.companyCodes.map(({code, name}) => ({value: code, label: this.getCompanyCodeLabel(name, code)}))
      },

      hasError() {
        return this.errorData.error
      },

      ...mapGetters({
        getEmployee: 'employees/get',
      })
    },

    created() {
      this.$wait.start(this.loadingName)
    },

    methods: {
      getCompanyCodeLabel (name, code) {
        return `(${code}) ${name}`
      },

      hide() {
        this.$refs.modal.hide()
      },

      handleModalShown() {
        // Get data that can be sent to ADP
        ajax({
          url: this.$routes.Adp.applicants_status_path,
          method: 'GET',
          data: {
            employee_id: this.employeeId
          }
        }).success((data) => {
          if (data.error) {
            this.errorData = data
          } else {
            this.templates = data.templates
            this.companyCodes = data.companyCodes
          }
        }).done(() => {
          this.$wait.end(this.loadingName)
        }).fail((xhr) => {
          setTimeout(() => {
            this.hide()
          })
          Util.genericAjaxError('An error has occured.', xhr)
        })
      },

      handleModalHidden() {
        this.errorData = {}
        this.$wait.start(this.loadingName)
      },

      submit() {
        this.$wait.start('show_adp_integration_modal')

        ajax({
          url: this.$routes.Adp.applicants_path,
          method: 'POST',
          data: {
            employee_id: this.employeeId,
            template: this.selectedTemplate,
            company_code: this.selectedCompanyCode
          }
        }).success((data) => {
          if (data.error) {
            this.errorData = data
          } else {
            setTimeout(() => {
              this.hide()
            })
            Util.showFlashNotice('Employee synced to ADP® Workforce Now')
          }
        }).done(() => {
          this.$refs.submit.stop()
          this.$wait.end('show_adp_integration_modal')
        }).fail((xhr) => {
          this.$refs.submit.stop()
          this.$wait.end('show_adp_integration_modal')
          setTimeout(() => {
            this.hide()
          })
          Util.genericAjaxError('An error has occured.', xhr)
        })
      },
    },
  }
</script>
