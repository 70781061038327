<template>
  <WbNestedSelect
    :placeholder="label"
    :resetOnSelect="true"
    :tree="representableFieldsTree"
    :value="null"
    valueKey="field_key"
    variant="button"
    @input="handleInput"
  />
</template>
<script>
  import { mapGetters } from 'vuex'
  import WbNestedSelect from 'components/common/WbNestedSelect'

  export default {
    name: 'representable-field-select',

    components: {
      WbNestedSelect
    },

    props: {
      label: {
        type: String,
        default: 'Add a Field'
      },
      tree: {
        type: Array
      }
    },

    computed: {
      ...mapGetters({
        defaultTree: 'representable_fields_tree/tree'
      }),
      representableFieldsTree () {
        return this.tree || this.defaultTree
      }
    },

    methods: {
      handleInput(field) {
        this.$emit('select', field)
      }
    },

    
  }
</script>
