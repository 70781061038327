var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group" }, [
    !!_vm.label
      ? _c("label", { ref: "label", staticClass: "small" }, [
          _vm._v("\n    " + _vm._s(_vm.label) + "\n  "),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "input-group" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("input", {
        ref: "input",
        staticClass: "form-control",
        attrs: {
          type: "search",
          placeholder: _vm.placeholder,
          autofocus: _vm.autofocus,
        },
        domProps: { value: _vm.value },
        on: { input: _vm.handleInput },
      }),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "input-group-addon" }, [
      _c("i", { staticClass: "fa fa-search" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }