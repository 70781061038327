var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", [
    _c("td", [
      _c(
        "a",
        {
          ref: "staffLink",
          attrs: {
            href: _vm.$routes.employee_assignments_path({
              employee_id: _vm.submission.employee_id,
            }),
            target: "_blank",
            title: "View forms for " + _vm.submission.staff_name,
          },
        },
        [_vm._v("\n      " + _vm._s(_vm.submission.staff_name) + "\n    ")]
      ),
    ]),
    _vm._v(" "),
    _c("td", [_vm._v("\n    " + _vm._s(_vm.submission.form_name) + "\n  ")]),
    _vm._v(" "),
    _c(
      "td",
      [
        _c("SubmissionTypeIcon", {
          attrs: { type: _vm.submission.type, name: _vm.submission.form_name },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "td",
      { staticClass: "text-right" },
      [
        _c("WbSimpleDateTime", {
          attrs: { value: _vm.submission.created_at, format: _vm.dateFormat },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("td", { staticClass: "text-right" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          on: {
            click: function ($event) {
              return _vm.handleReviewClick(_vm.submission.id)
            },
          },
        },
        [_vm._v("\n      Review\n    ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }