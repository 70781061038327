var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form-field",
    {
      attrs: {
        label: _vm.label,
        name: _vm.name,
        helpPopoverText: _vm.helpPopoverText,
        orientation: _vm.orientation,
        errors: _vm.errors,
        required: _vm.required,
        hint: _vm.hint,
        min: _vm.min,
      },
    },
    [
      _c(
        "div",
        {
          class: [{ "input-group": _vm.addon || _vm.addonPre }, "short-field"],
        },
        [
          _vm.addonPre
            ? _c("span", { staticClass: "input-group-addon" }, [
                _vm._v("\n      " + _vm._s(_vm.addonPre) + "\n    "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("input", {
            staticClass: "form-control",
            attrs: {
              type: "number",
              name: _vm.name,
              id: _vm.name,
              placeholder: _vm.placeholderValue,
              min: _vm.min,
              disabled: _vm.disabled,
            },
            domProps: { value: _vm.value },
            on: {
              input: function ($event) {
                return _vm.$emit("input", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _vm.addon
            ? _c("span", { staticClass: "input-group-addon" }, [
                _vm._v("\n      " + _vm._s(_vm.addon) + "\n    "),
              ])
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }