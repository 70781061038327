// Returns human-readable citizenship designation
export default function HumanizedCitizenship (citizenship) {
  const citizenshipDesignations = {
    'citizen': 'A citizen of the United States',
    'permanent_resident': 'A lawful permanent resident',
    'alien': 'An alien authorized to work',
    'noncitizen_national': 'A noncitizen national of the United States'
  }

  return citizenshipDesignations[citizenship] || ''
}
