var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Panel", {
    staticClass: "photo-matching-panel",
    attrs: { variant: "snapshot" },
    scopedSlots: _vm._u([
      {
        key: "heading",
        fn: function () {
          return [
            _c("div", { staticClass: "panel-title" }, [
              _c("h3", [_vm._v("Action Required")]),
              _vm._v(" "),
              _c("h4", [_vm._v("Photo Match")]),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "my-3" },
              [
                _c("p", [
                  _vm._v(
                    "\n        Do all the photos on file with WorkBright match the E-Verify photos? Please disregard any shading or lighting differences.\n      "
                  ),
                ]),
                _vm._v(" "),
                _c("WbButton", {
                  ref: "affirmPhotoMatchButton",
                  attrs: {
                    context: "success",
                    label: "Yes, the photos match",
                    isLoading: _vm.isButtonLoading("matching"),
                    disabled: _vm.isLoading,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.submit("matching")
                    },
                  },
                }),
                _vm._v(" "),
                _c("WbButton", {
                  ref: "rejectPhotoMatchButton",
                  attrs: {
                    context: "danger",
                    label: "No, the photos do not match",
                    isLoading: _vm.isButtonLoading("not-matching"),
                    disabled: _vm.isLoading,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.submit("not-matching")
                    },
                  },
                }),
                _vm._v(" "),
                _c("WbButton", {
                  ref: "noImageButton",
                  attrs: {
                    context: "default",
                    label: "Photo not present",
                    isLoading: _vm.isButtonLoading("no-image"),
                    disabled: _vm.isLoading,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.submit("no-image")
                    },
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("EverifyCaseDocumentPreview", { attrs: { caseId: _vm.caseId } }),
            _vm._v(" "),
            _c("EverifyCaseCloseForm", {
              attrs: { everifyCase: _vm.everifyCase },
              on: { closeTimeout: _vm.closeModal },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }