var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("EditAccountSettingsContainer", {
    ref: "editAccountSettingsContainer",
    attrs: {
      onboardingSmsEnabled: _vm.pageContext.onboardingSmsEnabled,
      ri9SmsEnabled: _vm.pageContext.ri9SmsEnabled,
      settings: _vm.pageContext.accountSettings,
      employeeTypes: _vm.pageContext.employeeTypes,
      notificationTypes: _vm.pageContext.notificationTypes,
      everifyEnabled: _vm.pageContext.everifyEnabled,
      embedded: _vm.pageContext.embedded,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }