var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { class: _vm.headerClassName }, [
    _c("h2", [_vm._v(_vm._s(_vm.title))]),
    _vm._v(" "),
    _vm.lead ? _c("h4", [_vm._v(_vm._s(_vm.lead))]) : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }