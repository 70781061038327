<template>
  <div
    class="alert alert-danger"
  >
    Please make sure that all required staff information is saved before scheduling notifications.
    <template v-if="hasErrors">
      <dl

        v-for="[errorSource, error] in Object.entries(errors)" :key="errorSource"
        class="mt-1 mb-0"
        ref="errors"
      >
        <dt>{{errorSource}}</dt>
        <dd>
          <ul class="list-unstyled">
            <li v-for="(errorItem, index) in error" :key="index">
              {{errorItem}}
            </li>
          </ul>
        </dd>
      </dl>
    </template>
  </div>
</template>
<script>
  export default {
    computed: {
      hasErrors () {
        return this.errors && Object.keys(this.errors).length
      }
    },
    props: {
      errors: {
        type: Object
      }
    }
  }
</script>
