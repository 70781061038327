// Mixin to provide shared functionality for slideout componenents

export default {
  props: {
    size: {
      type: String,
      validator: value => ['xl'].includes(value) // more sizes can be added as needed (update SlideoutPanel.css accordingly)
    }
  },

  computed: {
    sizeClass () {
      let className = ''
      if (this.size) {
        className = `slideout-panel-modal-${this.size}`
      }
      return className
    },
  },

  methods: {
    onModalShown() {
      // Hide the modal backdrop so slideout feels like part of the page
      $('.modal-backdrop').addClass('no-bg')
    }
  },
  
  watch: {
    shouldShow () {
      if (this.shouldShow) {
        this.$nextTick(() => {
          this.onModalShown()
        })
      }
    }
  }
}
