<template>
  <div>
    <div v-if="showNotReadyToStart" ref="notReady">
      <p>Sorry, but your I-9 is not ready to be countersigned.</p>
    </div>
    <BrowserConfirmLocation
      v-if="showSubmitGeoLocation"
      :minimumAgeToCountersign="pageContext.i9RemoteCountersign.minimumAgeToCountersign"
      @geolocationSuccess="handleGeolocationSuccess"
      @geolocationError="handleGeolocationError"
      ref="geolocation"
    />
    <div v-if="showSubmitAuthRepPhone" ref="authRepPhone">
      <h4>Step 2.</h4>
      <p>
        When you are with the person who will review your document(s), enter their cell number. It will text them a link. You will both need good cell coverage to proceed.
      </p>
      <form @submit="handleSubmitForm">
        <form-field-input
          placeholder="555-555-5555"
          :required="true"
          label="Document Reviewer Cell Number"
          :errors="formErrors"
          name="auth_rep_phone"
          v-model="formData.auth_rep_phone"
        />
        <hr />
        <ladda-button ref="submitAuthRepPhoneButton" @click="handleClickSubmitAuthRepPhone">
          Send Text Message
        </ladda-button>
      </form>
    </div>
    <template v-if="isQrStep">
      <ScanQrCode
        v-if="employeeScansQrCodeEnabled"
        actor="employee"
        :stepNumber="3"
        :alertMessage="qrCodeAlertMessage"
        :qrCodeTarget="qrCodePayload"
        :remoteId="remoteCountersignId"
        action="countersign"
        ref="scanQrCode"
      >
        <div slot="instructions">
          <p>
            In order to verify you are in the same location as your I-9 reviewer, we must use another method.
            To begin, please have your I-9 reviewer visit the link they were sent via text message.
            <em>
              They may have already done this and are now being shown a QR code on their device.
            </em>
          </p>
          <p>
            Next, click the "Begin QR Code Scan" button below.
            The view from your camera should appear.
            Now, scan the QR code on your I-9 reviewer's device.
          </p>
          <p class="text-em">
            <em>
              If your camera view does not appear, don't worry!
              Simply open your device's camera app or QR code scanner app, and scan the QR code.
            </em>
          </p>
        </div>
      </ScanQrCode>
      <ShowQrCode
        v-else
        actor="employee"
        :stepNumber="3"
        :alertMessage="qrCodeAlertMessage"
        :qrCodePayload="qrCodePayload"
        ref="showQrCode"
      >
        <div slot="instructions">
          <p>
            Have your I-9 reviewer select "Begin QR Code Scan" to open their camera. Allow them to scan the QR code that is displayed below. If they do not have a device with access to a camera, select choose a different person and start over at the bottom of this screen.
          </p>
        </div>
      </ShowQrCode>
    </template>
    <div v-if="showWaitingOnAuthRep" ref="waitingOnAuthRep">
      <p>
        Make sure you have your document(s) and get ready to show them to the person who you have chosen to review them.
      </p>
    </div>
    <div v-if="showReset" ref="resetSection">
      <hr />
      <p>
        If your authorized representative has yet to receive their text, you may use your mobile device's text app to manually nudge them by clicking the button below.
      </p>
      <a class="btn btn-default" :href="smsAppTarget" @click="handleClickNudgeAuthRep" ref="sendText">Send Text Message</a>
      <hr/>
      <a @click="handleClickReset" href="#reset" ref="resetLink">
        Click here if you need to choose a different person and start over.
      </a>
    </div>
    <div v-if="showCountersignAccepted" ref="countersignAccepted">
      <p>
        {{ $locale.t('remoteCountersign.verification_finished') }}
      </p>
      <p v-if="shouldLinkToEmbeddingApp" ref="showingLinkToEmbeddingApp">
        <a :href="currentAccount.embeddedUrl">{{ embeddedRedirectText }}</a>
      </p>
      <p v-else ref="showingLinkToDashboard">
        <a :href="staffDashboardPath">{{ $locale.t("remoteCountersign.return_dashboard") }}</a>
      </p>
    </div>
    <div v-if="showCountersignRejected" ref="countersignRejected">
      <template v-if="allowToStartOver">
        <p>
          I-9 verification could not be completed using this verification process. You may start over if you wish to provide alternate document(s) or modify the information you provided on your previous submission.
        </p>
        <WbButton @click="handleStartOver" class="mb-3 d-block ml-auto" ref="startOverButton">
          Start Over
        </WbButton>
        <p class="mb-4">
          If have any questions or believe this was an error, you may contact your employer. Thank you!
        </p>
      </template>
      <template v-else>
        <p>
          I-9 verification could not be completed using this verification process. If you believe this is an error or wish to provide alternative document(s), you may contact your employer. Thank you, you are finished.
        </p>
      </template>
      <p>
        <a :href="staffDashboardPath">{{ $locale.t("remoteCountersign.return_dashboard") }}</a>
      </p>
    </div>
  </div>
</template>

<script>
  import BrowserSupportMixin from 'components/i9_remote_countersign/common/BrowserSupportMixin'
  import BrowserConfirmLocation from 'components/i9_remote_countersign/steps/BrowserConfirmLocation'
  import ScanQrCode from 'components/i9_remote_countersign/steps/ScanQrCode'
  import ShowQrCode from 'components/i9_remote_countersign/steps/ShowQrCode'
  import I9RemoteCountersignComponentMixin from 'components/i9_remote_countersign/common/I9RemoteCountersignComponentMixin'
  import WbButton from 'components/common/WbButton'
  import { ajax } from 'jquery'
  import { getRoute } from 'lib/routes'

  export default {
    name: 'i9-remote-countersign-employee-container',

    components: {
      BrowserConfirmLocation,
      ScanQrCode,
      ShowQrCode,
      WbButton,
    },

    mixins: [
      BrowserSupportMixin,
      I9RemoteCountersignComponentMixin,
    ],

    props: {
      allowToStartOver: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        phoneAccepted: null,
        authRepSmsMessageBody: null,
        formData: {
          // snake case because this is form data that is sent back to rails
          auth_rep_phone: null,
        },
      }
    },

    computed: {
      embeddedRedirectText(){
        const text = this.currentAccount.embeddedRedirectText
        return text?.trim().length > 0 ? text : 'Return to App'
      },

      shouldLinkToEmbeddingApp(){
        return this.pageContext.i9RemoteCountersign.shouldLinkToEmbeddingApp
      },

      showSubmitGeoLocation(){
        return this.ready && this.uncertified && this.locationNotAccepted && this.qrSecret === null
      },

      showSubmitAuthRepPhone(){
        return this.ready && this.uncertified && (this.locationAccepted || this.qrVerifying) && !this.phoneAccepted
      },

      showWaitingOnAuthRep(){
        return this.remoteVerifying
      },

      showReset(){
        return this.remoteVerifying
      },

      smsAppTarget(){
        return `sms://${this.formData.auth_rep_phone}${this.smsAppTargetDelim}body=${this.authRepSmsMessageBody}`
      },

      smsAppTargetDelim(){
        return this.isAppleDevice ? '&' : '?'
      },

      staffDashboardPath() {
        return this.$routes.staff_dashboard_path
      },

      qrCodeAlertMessage() {
        return "We were unable to access your or your I-9 reviewer's location."
      },

      uncertified(){
        return this.certifyStatus === 'uncertified'
      },
    },

    mounted() {
      this.authRepSmsMessageBody = this.pageContext.i9RemoteCountersign.authRepMessageBody
      this.formData.auth_rep_phone = this.pageContext.i9RemoteCountersign.auth_rep_phone
    },

    methods: {
      // User pressed enter in the SMS input
      handleSubmitForm(event) {
        event.preventDefault()
        this.handleClickSubmitAuthRepPhone()
      },

      handleClickSubmitAuthRepPhone(){
        this.sendI9RemoteCountersignEvent('submit_auth_rep_phone').success( (json) => {
          this.$refs.submitAuthRepPhoneButton.stop()
          if (json.success){
            this.certifyStatus = 'certify_remote_verifying'
            this.phoneAccepted = true
          }
          else {
            this.formErrors = json.errors || {}
          }
        })
      },

      handleClickNudgeAuthRep(_event){
        this.sendI9RemoteCountersignEvent('nudge')
      },

      handleClickReset(){
        this.sendI9RemoteCountersignEvent('reset').success( (json) => {
          if (json.success){
            this.certifyStatus = 'uncertified'
            this.locationAccepted = false
            this.phoneAccepted = false
            this.remoteCountersignId = json.remote_countersign_id
            this.pubSubChannelName = json.channel_name

            this.subscribeToPubSub(this.remoteCountersignId, this.pubSubChannelName)
          }
          else {
            this.certifyStatus = json.certify_status
            this.formErrors = json.errors || {}
          }
        })
      },

      handleStartOver() {
        ajax({
          method: 'GET',
          url: getRoute('start_over_staff_i9_submission_path'),
        }).success(() => {
          window.location.replace(getRoute('new_staff_document_submission_path', {document_id: 'i9'}))
        })
      },

      handleQrCodeMatch (isMatch) {
        this.formData.qr_secret_matched = isMatch
      },
    },
  }
</script>
