var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form-field-number", {
    attrs: {
      label: _vm.label,
      name: _vm.name,
      helpPopoverText: _vm.helpPopoverText,
      orientation: _vm.orientation,
      errors: _vm.errors,
      required: _vm.required,
      value: _vm.value,
      hint: _vm.hint,
      min: _vm.min,
      addonPre: "$",
      disabled: _vm.disabled,
    },
    on: { input: _vm.handleInput },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }