// This code is heavily based on:
// views/document_submissions/i9/document_select_view.js
//
import FormsUtil from 'lib/util/forms'
import AnimUtil from 'lib/util/animations'

// The initial selection step of I9 supplement b attachments.
//
// model - (I9DocumentationSet) the documentation_set of the I9 model (really a collection)
export default Backbone.Marionette.ItemView.extend({
  template: '#i9-supplement-b-document-select-tmpl',
  className: 'i9-documentation',
  ui: {
    form:                 'form',
    listA:                '.document-list-a',
    listB:                '.document-list-b',
    listC:                '.document-list-c',
    documentOption:       '.document-option:not(".disabled")',
    incompleteError:      '.incomplete-documentation',
    listTabLinks:         '#documentation-lists-nav a[data-toggle="tab"]',
    tabContentWrapper:    '.tab-content',
    showAllOptionsLink:   '.link-reveal-options',
  },

  events: {
    'click @ui.documentOption'           : 'selectDocument',
    'click @ui.reset'                    : 'reset',
    'click @ui.showAllOptionsLink'       : 'showListOptions',
    'show.bs.tab @ui.listTabLinks'       : 'onListTabShow',
  },

  initialize: function() {
    this.documentationChoices = this.getOption('documentationChoices')
    this.citizenship = this.getOption('citizenshipDesignation')
    this.alienRegNumber = this.getOption('alienRegNumber')

    // Remove any invalid attachments before the view is rendered and existing choices are auto-selected
    // We do this here and not in I9Submission.js#onChangeCitizenshipDesignation because this view is also used
    // for I-9 review/edit and therefore a citizenship change event does not occur there
    this.model.removeInvalidAttachments(this.citizenship)
  },

  onRender: function(){
    this.renderLists()
    this.updateUI()
  },

  onShow: function () {
    // We must select active tab on show (after onRender is called),
    // otherwise ui attempts to select tab without element being completely loaded
    this.selectActiveTab()
  },

  // Resets all documents and the UI
  reset: function(){
    this.model.reset()
    this.updateUI()
  },

  // Determines which document choices are active
  updateUI: function(){
    this.updateActiveChoices()

    // hide all of the non-selected options to reveal the "Show all options" link
    this.ui.documentOption.filter('.active').each(function(index, el) {
      const $el = $(el)

      const $parentListGroup = $el.closest('.document-list-group')
      AnimUtil.fade($parentListGroup.find("a.document-option:not(.active)"), false, { callback: function() {
        $parentListGroup.addClass('non-selected-hidden')
      }})
    })
  },

  // Render document lists A, B, C
  renderLists: function(){
    // Supplement B only requires a document of List A or List C
    const lists = [ {id: 'A', el: this.ui.listA },
      {id: 'C', el: this.ui.listC }]

    // Build each list
    lists.forEach((list) => {
      const disabledChoicesList = $('<div>')

      _.map(this.documentationChoices.getList(list.id), (choice) => {
        const isSelected = this.model.hasAttachment(choice.get('key'))
        const link = $("<a>").addClass('list-group-item document-option')
          .attr('href', 'javascript:void(0)')
          .toggleClass('active', isSelected)
          .attr('data-key', choice.get('key')) // We must set data-key this way as .data('key', val) does not allow the following filter, `.filter('[data-key=""]')`
          .append($('<span>').text(choice.get('list_title')))

        link.appendTo(list.el)
      })

      // Append disabled items to end of each list for UI purposes
      if (disabledChoicesList.children().length) {
        disabledChoicesList.children().appendTo(list.el)
      }

      // Each list gets a link to reveal all of the options after one is selected
      const showAllOptionsLink = $("<a>").addClass('link-reveal-options text-sm')
        .text(`Unhide all List ${list.id} options.`)
        .attr('href', 'javascript:void(0)')
      showAllOptionsLink.appendTo(list.el)
    })

    // Re-bind `ui` elements so that the choices we just created are picked up
    this.bindUIElements()

    // Initialize popovers
    $.runInitializers(this.$el)
  },

  // Callback for a document selection
  selectDocument: function(e) {
    this.toggleIncompleteError(false)

    const $el = $(e.currentTarget)  // use currentTarget since event bubbles up from <SPAN> to <A>
    const key = $el.data('key')

    this.selectedKey = key
    // Update the model w/ our choice
    const choice = this.documentationChoices.findWhere({ key: key })

    this.model.selectDocument(choice, this.citizenship)

    // adds the .active class:
    this.updateUI()
  },

  // The currently selected option group:
  //
  //  'A' - for List A
  //  'C' - for List C
  //  null - if no documents have been selected
  //
  // returns - String
  selectedOptionGroup: function(){
    if(this.model.isEmpty()) {
      return null
    }

    return this.model.at(0).get('list_key') == 'A' ? 'A' : 'C'
  },

  selectActiveTab: function(){
    const selectedOptionGroup = this.selectedOptionGroup()
    if (selectedOptionGroup != null) {
      if (this.selectedOptionGroup() == 'A') {
        this.ui.listTabLinks.first().tab('show')
      } else {
        this.ui.listTabLinks.last().tab('show')
      }
    }
  },

  // Update the active state on documentation choice list items
  // Loops through each attachment and adds .active class
  updateActiveChoices: function(){
    // Reset state for all
    this.ui.documentOption.removeClass('active')

    this.model.each((attachment) => {
      this.ui.documentOption.filter(`[data-key="${attachment.get('documentation_key')}"]`)
        .addClass('active')
    })
  },

  validate: function(validationCompleteCallback){
    if(this.model_valid()){
      validationCompleteCallback(true)
    } else {
      this.toggleIncompleteError(true)
      validationCompleteCallback(false)
    }
  },

  // Hide/Show incomplete selection error message
  //
  // visible - boolean
  toggleIncompleteError: function(visible){
    if (visible) {
      const selectedOptionGroup = this.selectedOptionGroup()
      let message

      if (selectedOptionGroup == null) {
        message = `Please select a document from List A or List C.`
      }

      FormsUtil.showFormInvalidNotification(this.ui.form, { message: message })
    } else {
      FormsUtil.clearFormInvalidNotification(this.ui.form)
    }
  },

  // Scrolls to the top of the tabbar when a new tab is shown
  onListTabShow: function(e) {
    AnimUtil.scrollTo(this.ui.form)
  },

  // Reveals the hidden list options after one has been selected
  showListOptions: function(e) {
    const $parentListGroup = $(e.target).closest('.document-list-group')
    $parentListGroup.removeClass('non-selected-hidden')
    AnimUtil.fade($parentListGroup.find("a.document-option"), true)
  },

  model_valid: function(){
    const listKeys = _.uniq(this.model.map(function(m){return m.get('list_key')}))

    return (  _.intersection(listKeys, ['A']).length == 1 ||
              _.intersection(listKeys, ['C']).length == 1)
  }
})
