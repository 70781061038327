import { mapMutations } from 'vuex'
import { SET_PAGE_CONTEXT_KEYS }  from 'vuex/mutation_types'
import { ajax } from 'jquery'


export default {
  methods: {
    ...mapMutations({
      setPageContextKeys: SET_PAGE_CONTEXT_KEYS,
    }),

    launchReverifyModal (submissionId) {
      this.setPageContextKeys({ i9_submission_id: submissionId })
      if (this.supplementBFull) {
        this.logSupplementBFullAttempt(submissionId)
      }
    },

    logSupplementBFullAttempt (submissionId) {
      ajax({
        method: 'PATCH',
        type: 'JSON',
        url: this.$routes.supplement_b_requested_when_full_path({ id: submissionId }),
      })
    }
  }
}
