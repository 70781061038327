<template>
  <PageHeader
    title="Bulk Staff Update"
  />
</template>
<script>
  import PageHeader from 'components/common/PageHeader'
  export default {
    name: 'the-user-updates-new-root',
    components: {
      PageHeader,
    },
  }
</script>
