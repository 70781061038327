var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "alert", class: _vm.alertClasses },
    [
      _vm.title
        ? _c("strong", [_vm._v("\n    " + _vm._s(_vm.title) + "\n  ")])
        : _vm._e(),
      _vm._v(" "),
      _vm.message
        ? _c("div", { domProps: { innerHTML: _vm._s(_vm.message) } })
        : _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }