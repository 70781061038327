var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.errorsAlert
      ? _c("div", { staticClass: "alert alert-danger" }, [
          _vm._v(
            "\n    " + _vm._s(_vm.$locale.t("errors.form.generic")) + "\n  "
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "form",
      { staticClass: "form-horizontal", attrs: { id: "supplement-a" } },
      [
        _c("PageHeader", {
          attrs: {
            title: "Preparer & Translator Certification",
            lead: _vm.$locale.t("staff.i9.preparerTranslator.subTitle"),
          },
        }),
        _vm._v(" "),
        _c("FormFieldRadio", {
          ref: "showPreparerForm",
          attrs: {
            options: _vm.preparerOptions,
            value: _vm.include_supplement_a,
            label: "Enter preparer/translator info",
            orientation: "horizontal",
            name: "showPreparerForm",
          },
          on: { input: _vm.handleIncludeUpdate },
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showForm,
                expression: "showForm",
              },
            ],
          },
          [
            _vm._l(_vm.preparers, function (preparer, index) {
              return _c("I9PreparerFormFields", {
                key: index,
                ref: "preparerSection",
                refInFor: true,
                attrs: {
                  preparer: preparer,
                  usStates: _vm.usStates,
                  index: index,
                  showErrors: _vm.showErrors,
                  shouldShowIndex: _vm.supportsMultipleTranslators,
                  shouldShowRemoveLink: _vm.hasMoreThanOnePreparer,
                },
                on: {
                  "update-preparer": function (preparer) {
                    _vm.handleFormUpdate(index, preparer)
                  },
                  "remove-preparer": _vm.handleRemovePreparer,
                },
              })
            }),
            _vm._v(" "),
            _vm.supportsMultipleTranslators
              ? _c("div", { staticClass: "text-center" }, [
                  _vm.canAddPreparer
                    ? _c("div", [
                        _c(
                          "button",
                          {
                            ref: "addPreparer",
                            staticClass: "btn btn-lg btn-link",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.addPreparerSection($event)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            + Add another Preparer/Translator\n          "
                            ),
                          ]
                        ),
                      ])
                    : _c(
                        "div",
                        {
                          staticClass: "alert alert-info visible-inline-block",
                        },
                        [
                          _vm._v(
                            "\n          You have reached the maximum of 4 Preparers/Translators\n        "
                          ),
                        ]
                      ),
                ])
              : _vm._e(),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }