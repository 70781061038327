<!-- // Single checkbox that handles select/unselect all functionality -->

<template>
  <div class="wb-list__checkbox" @click="handleSelectAllClick">
    <input
      ref='bulkSelect'
      type='checkbox'
      :checked="allSelected"
      :disabled="disabled"
      :indeterminate.prop="this.someSelected"
    />
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'select-all',
    props: {
      collectionViewName: {
        type: String,
        required: true
      },
      items: {
        type: Array,
        required: true
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false
      },
    },
    computed: {
      noneSelected() {
        return this.selected.length === 0
      },

      anySelected() {
        return this.selected.length > 0
      },

      someSelected() {
        return this.anySelected &&
          this.selected.length != this.items.length
      },

      allSelected() {
        return this.anySelected &&
          this.selected.length === this.items.length
      },

      ...mapState({
        selected(state) {
          return state[this.collectionViewName].selected
        },
      }),
    },
    methods: {
      handleSelectAllClick() {
        if (this.disabled) { return };

        this.$store.dispatch(`${this.collectionViewName}/selectAll`, this.items)
      },
    }
  }
</script>
