<template>
  <div>
    <PageHeader title="Text Notifications"/>

    <div class="panel gray-bg" v-if="isFeatureEnabled" ref="enabled">
      Text Notifications have been enabled on your account. Visit your
      <a :href="$routes.edit_settings_path" ref="settingsLink">settings page</a>
      to see the default notification method.
    </div>
    <div v-else ref="disabled">
      <div class="row">
        <div class="col-md-7">
          <h3 class="feature-list-section-title">What Are Text Notifications from WorkBright?</h3>
          <p>
            Text Notifications are a more visible and effective method of communicating important information and
            tasks to your onboarding staff. Instead of verbal or email notifications, WorkBright can send text
            messages prompting staff to complete onboarding forms and reminding them of approaching deadlines.
          </p>

          <img :src="$routes.Images.text_notification" class="upsell-image" height="300px"  />

          <h3 class="feature-list-section-title">Why Add Text Notifications?</h3>
          <p>
            In short, they're significantly more effective than email. Industry research shows that text messages
            are five times more likely to be opened than email and have an average response time of just 90 seconds.
            This more direct communication method will increase the speed of hiring throughput and document
            completion rates and make it even easier to hire new staff through WorkBright.
          </p>

          <h3 class="feature-list-section-title">How Does It Work?</h3>
          <p>
            Once enabled, administrators can change the default WorkBright notification method to text message.
            Alternatively, admins can configure notification settings on a per-person basis. If a phone number
            is not found in their staff profile or the staff member opts out of receiving text messages,
            WorkBright will default to email communication.
          </p>

          <h3 class="feature-list-section-title">What Notifications are Sent?</h3>
          <p>
            The message types and cadences are the same as email reminders, just delivered via text message instead.
            This includes common notifications like the welcome message, form due date reminders,
            resubmission requests, form rejections, and password resets.
          </p>

          <h3 class="feature-list-section-title">
            Pricing
          </h3>
          <p>
            Text Notifications are priced as a flat fee based on your number of annual user licenses and WorkBright
            products. Please fill out the form to the right and we will contact you with pricing for your organization.
          </p>
        </div>
        <div class="col-md-5">
          <FeatureRequestForm
            :title="title"
            :description="description"
            :featureName="featureName"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import PageHeader from 'components/common/PageHeader'
  import FeatureRequestForm from 'components/admin/FeatureRequestForm'

  export default {
    name: 'the-text-notifications-root',

    components: {
      PageHeader,
      FeatureRequestForm
    },

    data() {
      return {
        title: 'Text Notification Add-on Request',
        description: 'Enter your email to add Text Notifications to your account. After submitting your email, a WorkBright team member will contact you with pricing and next steps.',
        featureName: 'text_notifications',
      }
    },

    props: {
      isFeatureEnabled: {
        type: Boolean,
        required: true
      }
    },
  }
</script>
