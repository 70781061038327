var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "WbModal",
    {
      ref: "rejectModal",
      attrs: {
        title: _vm.modalTitle,
        largeModal: true,
        watchPageContextVariable: _vm.modalKey,
      },
      on: { modalShown: _vm.onOpen, modalHidden: _vm.onClose },
    },
    [
      _vm._t("default", [
        _vm.showErrorAlert
          ? _c("AlertMessage", {
              ref: "noReasonProvidedAlert",
              attrs: {
                title: "No Reason Provided",
                message: _vm.noReasonProvidedText,
                variant: "danger",
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.hasReasonCheckboxes
          ? _c(
              "div",
              { staticClass: "form-horizontal" },
              [
                _c("FormFieldCheckbox", {
                  ref: "documentsDoNotMatch",
                  attrs: {
                    name: "documents_do_not_match",
                    label:
                      "The document(s) do not match the information provided.",
                    gridColumns: "col-xs-12",
                  },
                  model: {
                    value: _vm.documentsNotMatch,
                    callback: function ($$v) {
                      _vm.documentsNotMatch = $$v
                    },
                    expression: "documentsNotMatch",
                  },
                }),
                _vm._v(" "),
                _c("FormFieldCheckbox", {
                  ref: "documentsNotGenuine",
                  attrs: {
                    name: "documents_not_genuine",
                    label: "The document(s) do not appear genuine.",
                    gridColumns: "col-xs-12",
                  },
                  model: {
                    value: _vm.documentsNotGenuine,
                    callback: function ($$v) {
                      _vm.documentsNotGenuine = $$v
                    },
                    expression: "documentsNotGenuine",
                  },
                }),
                _vm._v(" "),
                _c("FormFieldCheckbox", {
                  ref: "documentsNotRelating",
                  attrs: {
                    name: "documents_not_relating",
                    label:
                      "The document(s) do not appear to be relating to " +
                      _vm.employeeName +
                      ".",
                    gridColumns: "col-xs-12",
                  },
                  model: {
                    value: _vm.documentsNotRelating,
                    callback: function ($$v) {
                      _vm.documentsNotRelating = $$v
                    },
                    expression: "documentsNotRelating",
                  },
                }),
                _vm._v(" "),
                _c("FormFieldCheckbox", {
                  ref: "informationProvidedNotCorrect",
                  attrs: {
                    name: "information_provided_not_correct",
                    label:
                      _vm.employeeName +
                      " has indicated the information provided in Section 1 is incorrect.",
                    gridColumns: "col-xs-12",
                  },
                  model: {
                    value: _vm.informationNotCorrect,
                    callback: function ($$v) {
                      _vm.informationNotCorrect = $$v
                    },
                    expression: "informationNotCorrect",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.showAdditionalDetailsField
          ? _c("FormFieldTextArea", {
              ref: "additionalDetailsTextArea",
              staticClass: "mb-3",
              attrs: {
                orientation: "vertical",
                label: _vm.additionalDetailsLabel,
                type: "text",
                placeholder: "Notes for future reference",
              },
              model: {
                value: _vm.additionalDetails,
                callback: function ($$v) {
                  _vm.additionalDetails = $$v
                },
                expression: "additionalDetails",
              },
            })
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "button",
            {
              staticClass: "btn btn-default",
              attrs: { disabled: false },
              on: { click: _vm.hideModal },
            },
            [_vm._v("\n      Close\n    ")]
          ),
          _vm._v(" "),
          _c("WbButton", {
            attrs: { isLoading: _vm.isLoading, label: "Submit" },
            on: { click: _vm.submit },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }