import Pusher from 'pusher-js'
import Routes from 'lib/routes'

// PubSub JS client
const _PubSubSingleton = {
  _pusherKey: '__NO_PUSHER_KEY__',
  _accountSubdomain: null,
  _userToken: null,
  _pusherCluster: null,
  _useLocalPusherFake: false,
  _authEndPoint: Routes.presubscribe_pub_sub_path,

  // Initializer
  //
  // options
  //  pusherKey - (String) API Key for Pusher
  //  accountSubdomain - (String) subdomain for current tenant
  //  userToken - (String) unique token for subscribing to private user channels
  //  pusherCluster - (String) Name of server cluster from Pusher.com
  //  useLocalPusherFake - (Boolean, default 'false') set to `true` in local development
  //
  init(options) {
    this._userToken = options.userToken
    this._accountSubdomain = options.accountSubdomain
    this._pusherKey = options.pusherKey
    this._pusherCluster = options.pusherCluster
    this._useLocalPusherFake = options.useLocalPusherFake
  },

  // Connects to pubsub infrastructure and subscribes to a private user channel.
  //
  // options
  //  topic - (String, required) the name of the topic
  //  unique - (Boolean, required) whether or not a unique token UID should be appended
  //
  // Returns - Pusher Channel object
  subscribeToUserChannel(options) {
    // Channel Name Syntax:
    // private-<user_token>@<subdomain>;<topic>[.topic_uid]
    const topicUIDSuffix = options.unique ? `.${this._generateUniqueID()}` : ''
    const user_id = gon.current_user.id
    const channelName = `private-${this._userToken}@${this._accountSubdomain};${user_id};${options.topic}${topicUIDSuffix}`

    return this.pusher.subscribe(channelName)
  },

  // Connects to pubsub infrastructure and subscribes to a private user channel.
  //
  // options
  //  remoteCountersignId - (String, required) the UUID associated with a particular remote I9 countersign process
  //  channelName - (String, required) the pusher channel shared by employee and auth rep for their remote I9 countersign process
  //
  // Returns - Pusher Channel object
  subscribeToRemoteCountersignChannel(options) {
    this._authEndPoint = Routes.presubscribe_remote_countersign_pub_sub_path({rc: options.remoteCountersignId})
    return this.pusher.subscribe(options.channelName)
  },

  // Connects to pubsub infrastructure and subscribes to a private user channel.
  //
  // options
  //  remoteReverifyId - (String, required) the UUID associated with a particular remote I9 reverification process
  //  channelName - (String, required) the pusher channel shared by employee and auth rep for their remote I9 reverification process
  //
  // Returns - Pusher Channel object
  subscribeToRemoteReverifyChannel(options) {
    this._authEndPoint = Routes.presubscribe_remote_reverify_pub_sub_path({remote_reverify_id: options.remoteReverifyId})
    return this.pusher.subscribe(options.channelName)
  },

  // Unsubscribe from a channel
  //
  // channel - (Pusher Channel) the instance of the channel
  unsubscribe(channel) {
    this.pusher.unsubscribe(channel.name)

    // If not subscribed to any channels, let's disconnect and remove instance
    if(Object.keys(this.pusher.channels.channels).length == 0) {
      setTimeout(() => this.disconnectAndDetach())
    }
  },

  disconnectAndDetach(){
    this.pusher.disconnect()
    window.Pusher.instances.splice(0,1)
  },

  // Access to Pusher object
  // Returns - Pusher
  get pusher() {
    // use the connection we already have if possible
    //--
    // NOTE: Pusher installs itself to the `window` the first time it's referenced. We explicitly refer to
    // `window.Pusher` here to keep webpack from parsing the word "Pusher" here into a webpack `load` call.
    if (window.Pusher.instances.length) {
      return window.Pusher.instances[0]
    }

    let pusherConfig = {
      authEndpoint: this._authEndPoint,
      encrypted: true,
      cluster: this._pusherCluster,
    }

    if (this._useLocalPusherFake) {
      pusherConfig = Object.assign(pusherConfig, {
        httpHost: 'pusherfake.test',
        httpsPort: 54608,
        wsHost: 'pusherfake.test',
        wssPort: 54607
      })
    }

    return new Pusher(this._pusherKey, pusherConfig)
  },

  /* PRIVATE */

  // 36-character unique identifier for use in channel names
  // Returns - String
  _generateUniqueID: function() {
    return Math.random().toString(36).slice(2)
  },
}

export default _PubSubSingleton
