<template>
  <a
    role="button"
    href="javascript:void(0)"
    ref="supplementBButton"
    class="btn-supplement-b"
    @click="launchReverifyModal(submissionId)"
  >
    <WbIcon
      iconSet="a4s-icon"
      type="signature-pen"
    />
    Complete {{buttonText}}
  </a>
</template>

<script>
  import { mapGetters } from "vuex"
  import WbIcon from 'components/common/WbIcon'
  import OpenSupplementBModalMixin from 'components/mixins/admin/document_submissions/OpenSupplementBModalMixin'


  export default {
    name: 'supplement-b-button',
    components: {
      WbIcon
    },
    
    mixins: [
      OpenSupplementBModalMixin
    ],

    props: {
      submissionId: {
        type: Number,
        required: true
      },
      documentSubmission: {
        type: Object,
        required: true
      }
    },

    computed: {
      ...mapGetters({
        isPdfProcessing: 'document_submissions/pdfProccessing'
      }),
      supplementBFull () {
        return this.documentSubmission.supplement_b_full
      },
      buttonText () {
        return this.documentSubmission.supplement_b_supported ? 'Supplement B' : 'Section 3'
      },
    },

    watch: {
      isPdfProcessing() {
        this.setPdfProcessingStatus(this.isPdfProcessing)
      }
    },

    methods: {
      setPdfProcessingStatus (isProcessing) {
        if(isProcessing) {
          document.body.classList.add('pdf-processing')
        } else {
          // PDF is done processing, reload page to get new version
          window.location.reload()
        }
      }
    },
  }
</script>
