var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tr",
    {
      directives: [
        {
          name: "highlight-on-change",
          rawName: "v-highlight-on-change",
          value: _vm.value,
          expression: "value",
        },
      ],
    },
    [
      _c("td", [_vm._v(_vm._s(_vm.label))]),
      _vm._v(" "),
      _vm.isRestricted
        ? _c(
            "td",
            { staticClass: "info-item" },
            [_c("blocked-content-icon")],
            1
          )
        : _c(
            "td",
            { ref: "valueContent", staticClass: "block-translate" },
            [
              _vm.hasSlot
                ? _vm._t("default")
                : _c("span", [_vm._v(_vm._s(this.value))]),
            ],
            2
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }