import BaseDocumentSubmissionView from 'views/document_submissions/base_document_submission_view'

// For prerendered version, we override a few of the buttons
export default class PrerenderedDocumentSubmissionView extends Marionette.ItemView.extend({
  template: false,
  ...BaseDocumentSubmissionView
}) {
  onRender() {
    this.renderUI()

    this.ui.gotoInboxButton.find('.btn-text').text('Back to Forms')
    this.ui.gotoPrevButton.hide()
    this.ui.gotoNextButton.hide()
    this.ui.acceptButtonDropdown.hide()
    this.ui.rejectButtonDropdown.hide()
    this.ui.acceptButtonMenu.hide()
    this.ui.rejectButtonMenu.hide()
    this.ui.acceptNextButton.find('.btn-title').text('Accept')
    this.ui.rejectNextButton.find('.btn-title').text('Reject')
    this.ui.rejectNextButton.removeClass('js-reject-next').addClass('js-reject')
    this.ui.acceptNextButton.removeClass('js-accept-next').addClass('js-accept')
  }

  onGotoInboxAction() {
    // When we open submissions in a new tab, we don't have any history for the "Back to Forms" button to redirect.
    // Instead, we redirect to the I-9 Inbox. This assumes that only I-9 submissions are being opened in new tabs.
    // If we start opening other submissions in new tabs, we'll need to improve this logic.
    if (history.length == 1) {
      window.location.href = ErbRoutes.onboarding_i9_index_path
    } else {
      // FIXME: this is so that if they come here from the Form Details page, it takes them back there instead
      // of inside the employee folder. However, this is not scalable – when we implemented goto prev/next
      // below, going history.back() might actually take them to a different submission altogether. :-P
      history.back()
    }
  }
}
