import { ajax } from 'jquery'
import Dropzone from 'dropzone'
import DropzoneDefaults from 'lib/dropzone/defaults'
import { getRoute } from 'lib/routes'

export default class DropzoneUploadService {
  constructor({element, options}) {
    this.options = {...DropzoneDefaults.options, ...options}
    this.dropzone = new Dropzone(element, this.options)
  }

  addFile(file) {
    this.dropzone.addFile(file)
  }
  getDefaultValue(key){
    return DropzoneDefaults[key]
  }
  on(eventName, callback) {
    this.dropzone.on(eventName, callback)
  }
  presign(file, callback) {
    ajax({
      type: 'POST',
      url: getRoute('presign_uploads_path'),
      data: {
        content_type: file.type,
        filename: file.name
      }
    }).success((awsSignature) => {
      file.awsSignatureFormData = awsSignature
      callback()
    }).fail(_xhr => {
      callback('presign_failed')
    })
  }
  removeAllFiles(flag) {
    this.dropzone.removeAllFiles(flag)
  }
  removeFile(file) {
    this.dropzone.removeFile(file)
  }
}
