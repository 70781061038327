var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.shouldShow
    ? _c("div", [
        _c(
          "div",
          {
            ref: "panel",
            staticClass: "slideout-panel-modal modal right fade",
            class: _vm.sizeClass,
            attrs: { role: "dialog", tabindex: "-1" },
          },
          [
            _c("div", { staticClass: "modal-dialog" }, [
              _c("div", { staticClass: "modal-content" }, [
                _c("div", { staticClass: "extended-panel" }, [
                  _c(
                    "div",
                    { staticClass: "extended-content" },
                    [_vm._t("extended-content")],
                    2
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "primary-panel panel panel-snapshot" },
                  [
                    _c(
                      "div",
                      { staticClass: "panel-heading" },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "close",
                            attrs: { "aria-label": "Close", type: "button" },
                            on: { click: _vm.cancel },
                          },
                          [
                            _c("span", { attrs: { "aria-hidden": "true" } }, [
                              _vm._v("×"),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _vm._t("primary-title"),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "primary-content panel-body" },
                      [_vm._t("primary-content")],
                      2
                    ),
                  ]
                ),
              ]),
            ]),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }