/*
*
* Radio button field that will display Other option field if exists
*
*/
<template>
  <form-field
    :label="label"
    :name="name"
    :orientation="orientation"
    :required="required"
    :hint="hint"
    :errors="errors"
  >
    <div class="input-wrapper" v-for="(option, index) in radioOptions" v-bind:key="index">
      <span class="radio">
        <label :for="optionId(option)">
          <input
            :value="option.value"
            v-model="radioValue"
            class="radio_buttons required "
            type="radio"
            :name="name"
            :id="optionId(option)"
          />
          <span v-if="option.html" v-html="option.label" />
          <span v-else>
            {{option.label}}
          </span>
          <a class="help-popover"
            ref="popover"
            v-if="option.popoverText"
            href="javascript:void(0)"
            :title="option.popoverTitle"
            :data-content="option.popoverText"
            data-placement="auto top"
            role="button"
            tabIndex="0"
            data-toggle="popover"
            data-trigger="focus"
          >
            <i class="fa fa-question-circle" />
          </a>
          <slot v-if="value == option.value" :name="option.slot" />
        </label>
        <span class="medium-field" v-if="isOther(option.value)">
          <input
            v-model="otherValue"
            :disabled="otherValueDisabled"
            class='ml-4 mt-1 string required form-control'
            placeholder="Please specify"
            type="text"
            :name="nameOther"
            :id="nameOther"
            @input="$emit('input', $event.target.value)"
          />
        </span>
      </span>
    </div>
  </form-field>
</template>

<script>
  import FormField from 'components/common/FormField'
  import FormFieldConcern from 'mixins/FormFieldConcern'

  export default {
    name: 'form-field-radio',
    mixins: [FormFieldConcern],
    components: {
      FormField
    },

    // Props
    //    options: Array of options to display. Each item having:
    //    label: Text to display
    //    value: Value of selection
    //    html: (optional) Displays as HTML, default false
    //    slot: (optional) String name of slot to display
    props: {
      // Array of options to display. Each item having:
      // label: Text to display
      // value: Value of selection
      // html: (optional) Displays as HTML, default false
      // slot: (optional) String name of slot to display      // popover: (optional) Object
      //  popoverText: String text of popover
      //  popoverTitle: String title of popover
      //  FIXME: Are you using this popover? Please consider refactoring where this is used, particuarly the radio buttons for
      //         selecting notification dates, where this was brought in. It seems that this component should be refactored to
      //         use slots in some other kind of radio field component, as it is pretty unique. Consider removing this from
      //         FormFieldRadio.
      options: {
        type: Array,
        required: true
      },

      value: {
        type: [String, Boolean],
        default: null
      }
    },

    data() {
      return {
        radioOptions: this.options,
        radioValue: this.value,
        otherValue: '',
      }
    },

    computed: {
      otherValueDisabled() {
        // Disable if other is not chosen
        return this.radioValue &&
          this.radioValue.toLowerCase && 
          this.radioValue.toLowerCase() != 'other'
      },

      // Dynamically create the Other field name
      nameOther() {
        return `${this.name}_other`
      },

      // Override the following to handle nameOther error
      // Since this is the only field with an Other option, this feels like the right place to do so.
      hasError() {
        if(!this.errors) {
          return
        }

        // Find any errors matching field names in this component
        const errorsFound = [this.name, this.nameOther].filter(fieldName => {
          return this.errors[fieldName] && this.errors[fieldName].length
        })

        return errorsFound.length > 0
      },

      // Returns error message from errors object
      errorMessage() {
        const errorMsg = []
        if(!this.hasError) { return null };

        if(this.errors[this.name]) {
          errorMsg.push(this.errors[this.name])
        }

        if(this.errors[this.nameOther]) {
          errorMsg.push(this.errors[this.nameOther])
        }

        return errorMsg.flatMap((m) => m).join(' ')
      },

      otherSelected() {
        return this.radioOptions.map((o) => o.value).indexOf(this.value) == -1
      }
    },

    methods: {
      isOther(val) {
        return !!val && val.toLowerCase && val.toLowerCase().match('other')
      },

      // Returns unique id for option with/without name attribute
      optionId(option) {
        if (this.name) {
          return `${this.name}-${option.value}`
        } else {
          return option.value
        }
      }
    },

    watch: {
      radioValue: function(val) {
        let emitVal = val
        if (this.isOther(val)) {
          // Switched to 'Other'. Send nothing, as this value will emit via input field
          emitVal = null
        } else {
          // Switched to not 'Other'. Clear otherValue, as this will not be used
          this.otherValue = ''
        }

        this.$emit('input', emitVal)
      }
    },

    mounted() {
      if (!this.$refs.popover) {
        return
      }

      // Initialize help popover
      $(this.$refs.popover).popover({
        html: true,
        title: () => `${this.label} <i class="close fa fa-times"></i>`,
        container: 'body'
      })
    }
  }
</script>
