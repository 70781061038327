<template>
  <div>
    <!-- // Internal Documents -->
    <div v-if="internalDocuments.length" ref="internalForms">
      <p>
        <span class="text-strong" v-if="employees.length > 1">Internal Forms:</span>
        Administrators will be expected to upload new submissions for
        <a v-tooltip.bottom="internalDocumentNames" href="javascript:void(0)">
          {{ pluralizedInternalForms }}.
        </a>
      </p>
    </div>
    <!-- // Notified Staff with Visible Documents -->
    <p v-if="visibleDocuments.length && notifiedEmployees.length" ref="notifiedVisible">
      <span class="text-strong" v-if="employees.length > 1">Will be notified:</span>
      <span v-if="notifiedEmployees.length == 1" ref="notifiedEmployees">
        {{ notifiedEmployeeNames }}
      </span>
      <span v-else>
        <a v-tooltip.bottom="notifiedEmployeeNames" href="javascript:void(0)" ref="notifiedCount">
          {{ pluralizedNotifiedStaffMembers }}
        </a>
      </span>
      will receive a notification informing them that they need to complete
      <a v-tooltip.bottom="visibleDocumentNames" href="javascript:void(0)" ref="notifiedVisibleForms">
        {{ pluralizedForms }}.
      </a>
    </p>
    <!-- // Unnotified Staff Visible Documents -->
    <div v-if="visibleDocuments.length && unnotifiedEmployees.length">
      <p>
        <span class="text-strong" v-if="employees.length > 1">Not notified now:</span>
        <span v-if="unnotifiedEmployees.length == 1" ref="unnotifiedNames">
          {{ unnotifiedEmployeeNames }}
        </span>
        <span v-else ref="unnotifiedCount">
          <a v-tooltip.bottom="unnotifiedEmployeeNames" href="javascript:void(0)">
            {{ pluralizedUnnotifiedStaffMembers }}
          </a>
        </span>
        will not be notified because they are not employed, or their Notification Start Date is blank or set in the future.
      </p>
    </div>
    <!-- // Sweeper Warning -->
    <p v-if="pendingAssignments.length > 0" ref="deleteWarning">
      <i class="fa fa-exclamation-triangle text-danger" />
      {{ pluralizedPendingSubmissions }}
      <a href="javascript:void(0)" v-tooltip.html="'Pending submissions are deleted when new submissions are requested. If you would like to keep these, approve or reject them first.'">
        will be deleted.
      </a>
    </p>
    <!-- // Email Message Form -->
    <div v-if="visibleDocuments.length && notifiedEmployees.length">
      <hr />
      <div class="form-group mt-4">
        <label class="mb-0" for="email-message">
          <strong> Email message (optional)</strong>
        </label>
        <p class="help-block">
          If you would like to enter an optional message to go out with the email, enter it here:
        </p>
        <textarea
          id="email-message"
          class="form-control w-100"
          ref="emailMessageTextarea"
          rows=4
          maxlength=1000
          placeholder="We'd like you to redo these forms because..."
          v-model='emailMessage'
        />
        <p class="help-block">
          Emails are delayed up to 15 minutes before sending.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'
  import StringUtil from 'lib/util/string'

  export default {
    name: 'step-confirm',

    props: {
      // The name of the collection store related metadata for the list
      collectionViewName: {
        type: String,
        required: true
      }
    },

    data() {
      return {
        emailMessage: ''
      }
    },

    computed: {
      pendingAssignments() {
        return this.assignments.filter((a) => a.status == 'pending')
      },

      pluralizedForms() {
        return StringUtil.pluralize(this.visibleDocuments.length, 'form')
      },

      pluralizedPendingSubmissions() {
        return StringUtil.pluralize(this.pendingAssignments.length, 'pending submission')
      },

      pluralizedNotifiedStaffMembers() {
        return StringUtil.pluralize(this.notifiedEmployees.length, 'staff member')
      },

      pluralizedInternalForms() {
        return StringUtil.pluralize(this.internalDocuments.length, 'internal form')
      },

      pluralizedUnnotifiedStaffMembers() {
        return StringUtil.pluralize(this.unnotifiedEmployees.length, 'staff member')
      },

      documents() {
        return this.documentGetBatch(this.assignments.map((a) => a.document_id))
      },

      internalDocuments() {
        return this.documents.filter((d) => d.internal)
      },

      visibleDocuments() {
        return this.documents.filter((d) => !d.internal)
      },

      internalDocumentNames() {
        const names = this.internalDocuments.map((d) => d.name)
        return StringUtil.joinTruncated(names, 4)
      },

      visibleDocumentNames() {
        const names = this.visibleDocuments.map((d) => d.name)
        return StringUtil.joinTruncated(names, 4)
      },

      notifiedEmployees() {
        return this.employees.filter(e => this.employeeNotified(e))
      },

      unnotifiedEmployees() {
        return this.employees.filter(e => !this.employeeNotified(e))
      },

      notifiedEmployeeNames() {
        const names = this.notifiedEmployees.map((emp) => this.$employee.fullName(emp))
        return StringUtil.joinTruncated(names, 4)
      },

      unnotifiedEmployeeNames() {
        const names = this.unnotifiedEmployees.map((emp) => this.$employee.fullName(emp))
        return StringUtil.joinTruncated(names, 4)
      },

      employees() {
        return this.employeeGetBatch(this.assignments.map((a) => a.employee_id))
      },

      employeeNames() {
        return this.employees.map((emp) => this.$employee.fullName(emp)).join(', ')
      },

      ...mapGetters({
        assignmentsGetBatch: 'document_assignments/getBatch',
        documentGetBatch: 'documents/getBatch',
        documentGet: 'documents/get',
        employeeGetBatch: 'employees/getBatch',
        employmentFindBy: 'employments/findBy'
      }),

      ...mapState({
        assignments(state) {
          return this.assignmentsGetBatch(state[this.collectionViewName].selected)
        }
      })
    },

    methods: {
      valid() {
        return true
      },


      beforeAdvance() {
        this.$emit('updateData', { emailMessage: this.emailMessage })
      },

      // Will this employee be notified by email?
      employeeNotified(employee) {
        const employment = this.employmentFindBy({ employee_id: employee.id })[0]
        return employment && employment.onboarding_now
      }
    },
  }
</script>
