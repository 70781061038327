var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "wb-modal",
    {
      ref: "modal",
      attrs: {
        watchPageContextVariable: this.contextVariable,
        title: "Important",
      },
      on: {
        modalShow: _vm.handleModalShow,
        cancelled: function ($event) {
          return _vm.$emit("cancelled")
        },
      },
    },
    [
      _c("bulk-action-list-alert-details", {
        ref: "bulkActionListAlertDetails",
        attrs: {
          collectionModule: _vm.collectionModule,
          collectionViewName: _vm.collectionViewName,
          displayNameLookupFn: _vm.displayNameLookupFn,
          permissionModule: _vm.permissionModule,
          permissionName: _vm.permissionName,
          actionMethod: _vm.actionMethod,
          actionErrorMessage: _vm.actionErrorMessage,
        },
        on: { allowableActions: _vm.handleAllowableActions },
      }),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _vm.allowableActions
          ? _c(
              "div",
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    on: { click: _vm.handleCancel },
                  },
                  [_vm._v("Cancel")]
                ),
                _vm._v(" "),
                _c(
                  "ladda-button",
                  { ref: "submitButton", on: { click: _vm.handleContinue } },
                  [_vm._v("Next")]
                ),
              ],
              1
            )
          : _c("div", [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  on: { click: _vm.handleCancel },
                },
                [_vm._v("Select Different Rows")]
              ),
            ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }