<template>
  <bulk-action-list-alert-details
    ref="bulkActionListAlertDetails"
    collectionModule="document_assignments"
    :collectionViewName="collectionViewName"
    :displayNameLookupFn="displayNameLookupFn"
    permissionModule="document_assignments"
    permissionName="unlock_submission"
    :actionMethod="(a) => a.active"
    :actionErrorMessage="'These submissions <strong>cannot be requested</strong> because the form has not been assigned to these staff members. In order to assign a new form, assign the staff member to a group that includes the desired form.'"
  />
</template>

<script>
  import BulkActionListAlertDetails from 'components/common/BulkActionListAlertDetails'
  import { mapGetters } from 'vuex'

  export default {
    name: 'step-validate',

    components: {
      BulkActionListAlertDetails
    },

    props: {
      // The name of the collection store related metadata for the list
      collectionViewName: {
        type: String,
        required: true
      },
    },

    computed: {
      ...mapGetters({
        getDocument: 'documents/get',
        getEmployee: 'employees/get',
      })
    },

    methods: {
      valid() {
        return true
      },

      beforeAdvance() {
        this.$refs.bulkActionListAlertDetails.filter()
      },

      displayNameLookupFn(assignment) {
        const document = this.getDocument(assignment.document_id)
        const employee = this.getEmployee(assignment.employee_id)

        return `${this.$employee.fullName(employee)}: ${document.name}`
      },
    },

    mounted() {
      // Everything is permitted, nothing to do here.
      if(this.$refs.bulkActionListAlertDetails.allPermittedAndActionable) {
        this.$emit('skip')
      }
    }
  }
</script>
