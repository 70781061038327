<template>
  <tr>
    <td>
      <a
        :href="$routes.employee_assignments_path({ employee_id: submission.employee_id })"
        target="_blank"
        :title="`View forms for ${submission.staff_name}`"
        ref="staffLink"
      >
        {{ submission.staff_name }}
      </a>
    </td>
    <td>
      {{ submission.form_name }}
    </td>
    <td>
      <SubmissionTypeIcon
        :type="submission.type"
        :name="submission.form_name"
      />
    </td>
    <td class="text-right">
      <WbSimpleDateTime
        :value="submission.created_at"
        :format="dateFormat"
      />
    </td>
    <td class="text-right">
      <button
        class="btn btn-primary"
        @click="handleReviewClick(submission.id)"
      >
        Review
      </button>
    </td>
  </tr>
</template>
<script>
  import Constants from 'lib/constants'
  import SubmissionTypeIcon from 'components/common/SubmissionTypeIcon'
  import WbSimpleDateTime from 'components/common/WbSimpleDateTime'

  export default {
    components: {
      SubmissionTypeIcon,
      WbSimpleDateTime
    },
    props: {
      submission: {
        type: Object,
        required: true
      }
    },
    computed: {
      dateFormat () {
        return Constants.DATE_EXT_NO_WEEKDAY_FORMAT
      },
    },
    methods: {
      handleReviewClick (submissionId) {
        this.$emit('submissionSelected', submissionId)
      }
    }
  }
</script>
