import DocumentCollection from 'collections/document_collection'
import DocumentReorderingCollectionView from 'views/documents/document_reordering_collection_view'
import Routes from 'lib/routes'
import Util from 'lib/util'

export default Marionette.LayoutView.extend({
  template: false,

  ui: {
    resetButton: '.js-reset-sort-btn',
    saveButton: '.js-save-btn',
  },

  events: {
    'click @ui.resetButton': 'clickReset',
    'click @ui.saveButton': 'clickSave',
  },

  regions: {
    sortableContainerRegion: "section.sortable-container",
  },

  initialize: function() {
    this.documentsCollection = new DocumentCollection(this.getOption('documents'))
  },

  onRender: function () {
    this.sortableContainerRegion.show(new DocumentReorderingCollectionView({ collection: this.documentsCollection }))
    this.collectionView = this.sortableContainerRegion.currentView

    $(document).on('page:before-change.docs_sort_view', this.unsavedChangesWarning.bind(this))
  },

  onBeforeDestroy: function() {
    $(document).off('page:before-change.docs_sort_view')
  },

  unsavedChangesWarning: function(e) {
    // Don't show confirm modal unless we're dirty
    if (!this.collectionView.isDirty()) {
      return
    }

    var url = e.originalEvent.data.url

    e.stopPropagation()
    e.preventDefault()

    bootbox.confirm({
      title: 'You have unsaved changes.',
      message: "Would you like to save your changes now?",
      className: "modal-info",
      buttons: {
        cancel: {
          label: "Discard changes",
          className: "btn-default",
          value: false,
        },
        confirm: {
          label: "Save changes",
          className: "btn-primary",
          value: true,
        },
      },
      callback: function(result) {
        if (result === true) {
          this._saveChanges(url)
          return
        }

        Turbolinks.visit(url)
      }.bind(this)
    })
  },

  clickReset: function (e) {
    this.collectionView.sortAlphabetically()
  },

  clickSave: function (e) {
    this._saveChanges(Routes.documents_path)
  },

  _saveChanges: function(successRedirectUrl) {
    this.ui.saveButton.data('ladda').start() // Possibly entry from cancel modal- start the ladda just incase
    var newOrder = this.collectionView.getOrderedKeys()

    $.ajax({
      method: 'POST',
      url: Routes.update_positions_documents_path,
      data: JSON.stringify({ order: newOrder }),
      dataType: 'json',
      contentType: 'application/json',
      wbGenericFailureMsg: "Sorry, we couldn't update your form order.",
    })
    .done(function(responseText, textStatus, xhr) {
      // Since the save went through, tell the collectionView that we're no longer dirty (it is showing the
      // legit order after all), which will ensure the navigate call below goes through unimpeded
      this.collectionView.setDirty(false)

      Util.navigateAndShowAjaxFlashNotice(successRedirectUrl, xhr)
    }.bind(this))
    .fail(function(xhr) {
      this.ui.saveButton.data('ladda').stop()
    }.bind(this))
  }
})
