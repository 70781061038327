var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form-field",
    {
      attrs: {
        label: _vm.label,
        name: _vm.name,
        helpPopoverText: _vm.helpPopoverText,
        orientation: _vm.orientation,
        errors: _vm.errors,
        required: _vm.required,
        hint: _vm.hint,
      },
    },
    [
      _c("div", { class: _vm.fieldWidth }, [
        _c("input", {
          directives: [
            {
              name: "mask",
              rawName: "v-mask",
              value: "XXX-XX-XXXX",
              expression: "'XXX-XX-XXXX'",
            },
          ],
          ref: "maskedInput",
          staticClass: "form-control",
          attrs: {
            name: _vm.name + "-mask",
            type: "text",
            placeholder: "XXX-XX-XXXX",
            disabled: _vm.disabled,
          },
          domProps: { value: _vm.displayValue },
          on: {
            input: _vm.handleInput,
            focus: _vm.handleFocus,
            blur: _vm.handleBlur,
          },
        }),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.localValue,
              expression: "localValue",
            },
            {
              name: "mask",
              rawName: "v-mask",
              value: "###-##-####",
              expression: "'###-##-####'",
            },
          ],
          ref: "formInput",
          attrs: { type: "hidden", name: _vm.name, id: _vm.name },
          domProps: { value: _vm.localValue },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.localValue = $event.target.value
            },
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }