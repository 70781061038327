// E-Verify case info displayed on small slideout panel (used with EverifyCasePanelTitle).

<template>
  <div class="snapshot-body">
    <div class="snapshot-row">
      <a
        ref="linkToI9"
        v-if="linkToI9"
        :href="linkToI9"
        target="_blank"
        class="snapshot-link"
      >
        {{ i9LinkName }}
      </a>
    </div>
    <div class="snapshot-row" ref="companyName" v-if="companyName">
      <div class="snapshot-heading">
        Company Name:
      </div>
      <div class="snapshot-info">
        {{ companyName }}
      </div>
    </div>
    <div class="snapshot-row">
      <div class="snapshot-heading">
        Case ID:
      </div>
      <div class="snapshot-info">
        {{ everifyCaseNumber }}
        <div class="mt-4" v-if="duplicateCases.length">
          <div class="panel panel-warning">
            <div class="panel-heading">
              We found other cases for {{ caseEmployee.first_name }}:
            </div>
            <div class="panel-body">
              <ul class="mt-2">
                <li class="mb-2" v-for="(dupCase, i) in duplicateCases" :key="i">
                  Case ID:
                  <span v-if="dupCase.case_id">
                    <strong>{{ dupCase.case_id }}</strong>
                  </span>
                  <span v-else>
                    None
                  </span>
                  <br />
                  Case Status:
                  <strong>{{ caseStatus(dupCase.case_status) }}</strong>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="snapshot-row" v-if="everifyCase.case_created_at">
      <div class="snapshot-heading">
        Case Created:
      </div>
      <div class="snapshot-info">
        <WbSimpleDateTime :value="everifyCase.case_created_at" />
      </div>
    </div>
    <div class="snapshot-row">
      <div class="snapshot-heading">
        Case Status:
      </div>
      <div class="snapshot-info">
        <EverifyCaseStatusIndicator
          :everifyCase="everifyCase"
        />
        <div class="mt-4" v-if="errors && showErrors">
          <p class="mt-4">
            E-Verify reported the following errors for this case:
          </p>
          <div v-for="(error, index) in errors" :key="index">
            <div class="panel panel-default mt-4">
              <div class="panel-body">
                <div class="kerning-sm small">
                  ERROR MESSAGE
                </div>
                <div class="mt-2">
                  <div class="error-txt">
                    {{ error.message }}
                  </div>
                </div>
                <div class="mt-2">
                  <strong>
                    Submitted value: {{ error.value }}
                  </strong>
                </div>
              </div>
            </div>
            <div class="panel-footer">
              <div class="kerning-sm small mb-2">
                WHAT THIS MEANS
              </div>
              <div ref="resubmissionError" v-if="resubmissionError(error.attribute_name)">
                <span>
                  There is an error with the format of
                  <strong>
                    {{ resubmissionErrorName(error.attribute_name) }}.
                  </strong>
                  Please have the staff member update it and resubmit Section 1 of the I-9.
                </span>
              </div>
              <div ref="documentationError" v-else-if="updateDocumentationError(error.attribute_name)">
                <span>
                  There is an error with the format of
                  <strong>
                    {{ updateDocumentationErrorName(error.attribute_name) }}.
                  </strong>
                  You can update this field in Section 2 to conform to the suggested format.
                </span>
                <br />
              </div>
              <div ref="missingDuplicateContinueReasonError" v-else-if="missingDuplicateContinueReasonError(error.attribute_name)">
                <span>
                  This information has been found in one or more cases created within the last 365 days under this same employer account. To continue verifying the employment eligibility for the individual with this information, you must provide a reason for creating a duplicate case.
                </span>
              </div>
              <div ref="referToWBError" v-else-if="referToWorkBrightError(error.attribute_name)">
                <span>
                  Please contact WorkBright Support to resolve
                </span>
                <a href="mailto:support@workbright.com">
                  support@workbright.com
                </a>
              </div>
              <div ref="otherError" v-else>
                <span>
                  Please update Section 2 of this I-9 or ask the staff member to resubmit.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="snapshot-row" v-if="caseDetails">
      <div class="snapshot-heading">
        Case Details:
      </div>
      <div class="snapshot-info">
        {{ caseDetails }}
      </div>
    </div>
    <div class="snapshot-row" v-if="shouldShowDeadline" ref="deadline">
      <div class="snapshot-heading">
        {{deadlineLabel}}
      </div>
      <div class="snapshot-info">
        {{ caseDeadline }}
      </div>
    </div>
    <div class="snapshot-row">
      <div class="snapshot-heading">
        Next Steps:
      </div>
      <div class="snapshot-info">
        {{ nextSteps }}
        <div v-if="resubmitI9Error">
          We suggest you close this case, reject this form, and give the rejection reason(s) above to
          {{ caseEmployee.first_name }}
          for resubmitting Section 1 of the I-9.
          <br />
          <a class="btn btn-primary btn-md mt-4" :href="linkToI9" target="_blank">
            {{ i9LinkName }}
          </a>
        </div>
        <div v-if="shouldShowEditSection2">
          We suggest you edit the Section 2 documentation for this I-9 and resubmit Section 2
          <br />
          <a class="button btn btn-md btn-primary px-2 mt-4" :href="linkToUpdateI9Documentation">
            View I-9 Documentation
          </a>
        </div>
        <div v-else-if="visitEverify">
          <a
            ref="visitEverifyLink"
            :href="$routes.External.everify_cases_url"
            target="_blank"
            class="btn btn-primary px-4 mt-4"
          >
            Visit E-Verify
          </a>
        </div>
        <div v-if="isDeleteable" class="mt-3">
          <WbButton
            context="danger"
            label="Delete Case"
            @click="caseDeletion"
            :isLoading="isDeletingCase"
          />
        </div>
      </div>
    </div>
    <div
      v-if="auditEvents"
      class="snapshot-row">
      <div class="snapshot-heading">
        Audit Events
      </div>
      <div
        class="snapshot-info"
        ref="auditEvents"
      >
        <button
          class="btn btn-link pl-0"
          ref="auditEventsToggle"
          @click="isShowingAuditEvents = !isShowingAuditEvents"
        >
          {{ auditEventsToggleText }}
        </button>
        <EverifyCaseAuditEvents
          :auditEvents="auditEvents"
          v-if="isShowingAuditEvents"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions, mapMutations } from "vuex"
  import * as mutate from "vuex/mutation_types"
  import EverifyCaseAuditEvents from 'components/everify/cases/EverifyCaseAuditLog'
  import EverifyCaseStatusIndicator from "components/everify/cases/EverifyCaseStatusIndicator"
  import WbButton from "components/common/WbButton"
  import LaddaButton from "components/common/LaddaButton"
  import WbSimpleDateTime from "components/common/WbSimpleDateTime"
  import { EVERIFY_STATUSES } from "components/admin/dashboard/EverifyCaseStatusConstants"
  import EverifyCaseDeadlineMixin from 'components/everify/cases/EverifyCaseDeadlineMixin'
  import Util from "lib/util"

  export default {
    name: "everify-case-panel-content",
    components: {
      EverifyCaseAuditEvents,
      EverifyCaseStatusIndicator,
      WbButton,
      LaddaButton,
      WbSimpleDateTime,
    },

    mixins: [
      EverifyCaseDeadlineMixin
    ],

    props: {
      currentId: {
        type: Number,
        required: false
      },
      everifyCase: {
        type: Object,
        required: true
      },
      caseEmployee: {
        type: Object,
        required: false
      },
    },

    data() {
      return {
        duplicateCases: [],
        isShowingAuditEvents: false,
        loaderName: "close_case",
        isDeletingCase: false
      }
    },

    computed: {
      auditEvents () {
        return this.everifyCase.audit_logs
      },

      auditEventsToggleText () {
        return this.isShowingAuditEvents ?
          'Hide Audit Events' :
          'Show Audit Events'
      },

      everifyCaseNumber() {
        return this.everifyCase.case_id
      },

      currentStatus() {
        return this.everifyCase.case_status
      },

      submissionErrorType() {
        return this.everifyCase.submission_error_type
      },

      caseDetails() {
        if (this.everifyCase.raw_response) {
          return this.everifyCase.raw_response.eligibility_statement_details
        }
      },


      /**
       * Retrieves the next steps message based on the current case status,
       * submission error type (if applicable), and whether the case is shown
       * in the extended slide-out or not. All messages are fetched from
       * the locale file.
       */
      nextSteps() {
        const nextStepsKey = this.visitEverify ? 'nextSteps' : 'extendedSlideoutNextSteps'
        const localePath = ['everify', 'status', this.currentStatus]

        if (this.submissionErrorType) { localePath.push(this.submissionErrorType) }
        localePath.push(nextStepsKey)

        return this.$locale.t(localePath.join("."))
      },

      visitEverify() {
        return !(EVERIFY_STATUSES.ACTION_REQUIRED_STATES.includes(this.currentStatus) || EVERIFY_STATUSES.ACTION_REQUIRED_ERRORS.includes(this.submissionErrorType))
      },

      linkToI9() {
        return this.everifyCase.submission_id
          ? this.$routes.admin_employee_submission_path({
            employee_id: this.everifyCase.employee_id,
            id: this.everifyCase.submission_id,
          })
          : void 0
      },

      i9LinkName() {
        if (this.caseEmployee) {
          return `View I-9 for ${this.caseEmployee.first_name}`
        }
      },

      linkToUpdateI9Documentation() {
        return this.everifyCase.submission_id
          ? `${this.$routes.onboarding_i9_review_path}?ids[]=${this.everifyCase.submission_id}`
          : void 0
      },

      everifyAccountId() {
        return this.everifyCase.everify_account_id
      },

      everifyAccount() {
        if (this.everifyAccountId) {
          return this.getEverifyAccount(this.everifyAccountId)
        }
      },

      companyName() {
        if (this.everifyAccount) {
          return this.everifyAccount.company_name
        }
      },

      loading() {
        return this.$wait.is(this.loaderName)
      },

      showErrors() {
        return EVERIFY_STATUSES.RENDER_ERROR_STATES.includes(this.currentStatus)
      },

      errors() {
        return this.everifyCase.errors
      },

      errorFields() {
        if (!this.errors) {
          return
        }
        return this.errors.map((error) => error.attribute_name)
      },

      // Returns boolean representing if any require updating section 2 data
      updateI9Error() {
        if (!this.errorFields) {
          return
        }
        return this.errorFields.some((errField) =>
          this.updateDocumentationError(errField)
        )
      },

      // Returns boolean representing if any are resubmission errors
      resubmitI9Error() {
        if (!this.errorFields) {
          return
        }
        return this.errorFields.some((errField) =>
          this.resubmissionError(errField)
        )
      },

      shouldShowEditSection2() {
        return this.updateI9Error && !this.resubmitI9Error && this.showErrors
      },

      isDeleteable() {
        return this.everifyCase.deleteable
      },

      ...mapGetters({
        getEverifyAccount: "everify_accounts/get",
      }),
    },

    mounted() {
      this.fetchDuplicateCases()
    },

    methods: {
      caseStatus(status) {
        return this.$locale.t(`everify.status.${status}.title`)
      },

      fieldNames(fields) {
        return Object.keys(this.fieldsLookup(fields))
      },

      fieldsLookup(fields) {
        return this.$locale.t(`everify.status.submission_error.fieldNameLookup.${fields}`)
      },

      resubmissionError(field) {
        return this.fieldNames("resubmissionFields").includes(field)
      },

      resubmissionErrorName(field) {
        return this.fieldsLookup(`resubmissionFields.${field}`)
      },

      updateDocumentationError(field) {
        return this.fieldNames("updateDocumentationFields").includes(field)
      },

      updateDocumentationErrorName(field) {
        return this.fieldsLookup(`updateDocumentationFields.${field}`)
      },

      referToWorkBrightError(field) {
        return this.fieldNames("referToWorkBrightFields").includes(field)
      },

      missingDuplicateContinueReasonError(field) {
        return this.fieldNames('duplicateContinueReasonFields').includes(field)
      },

      fetchDuplicateCases() {
        this.getDuplicateCases({
          caseId: this.currentId,
        })
          .then((response) => {
            this.duplicateCases = response.everify_cases
          })
          .catch((xhr) => {
            Util.ajaxErrorDialog(
              "An error occurred while retrieving duplicate cases.",
              xhr
            )
          })
      },

      caseClosure() {
        this.$wait.start(this.loaderName)

        this.closeCase({
          caseId: this.currentId,
          closureReason: {
            case_closure_reason_code: "DUPLICATE_CASE_DATA_EXISTS"
          }
        })
          .then(() => {
          // when a case is closed, it should automatically update with the new status
          })
          .catch((xhr) => {
            Util.ajaxErrorDialog(
              "An error occurred while sending a response to E-Verify.",
              xhr
            )
            this.$refs.caseCloseButton.stop()
          })
        this.$wait.end(this.loaderName)
      },

      async caseDeletion() {
        try {
          this.isDeletingCase = true
          await this.deleteCase({caseId: this.everifyCase.id, errorStatus: true})
          Util.showFlashNotice("The E-Verify case has been deleted")
          this.setRequiresRefresh(true)
        } catch(xhr) {
          Util.genericAjaxError('An error occurred deleting the case.', xhr)
        } finally {
          this.isDeletingCase = false
        }
      },

      ...mapActions({
        getDuplicateCases: "everify_cases/duplicateCases",
        closeCase: "everify_cases/closeCase",
        deleteCase: 'everify_cases/deleteCase'
      }),

      ...mapMutations({
        setRequiresRefresh: 'everify_cases/SET_REQUIRES_REFRESH'
      })
    },
  }
</script>
