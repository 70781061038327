var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-lg-10" },
      [
        _c(
          "WbPanel",
          {
            attrs: {
              title: "SCIM Provisioning Settings",
              formResponse: _vm.formResponse,
            },
          },
          [
            _c("p", [
              _vm._v(
                "\n        Once configured, this optional feature automatically creates employees in WorkBright that exist in your IdP.\n        Additionally, this feature automatically revokes access to WorkBright users that are removed from your IdP.\n      "
              ),
            ]),
            _vm._v(" "),
            _c(
              "form",
              { staticClass: "form-horizontal pt-4" },
              [
                _c(
                  "FormField",
                  {
                    attrs: {
                      label: "User Provisioning",
                      orientation: "horizontal",
                    },
                  },
                  [
                    _c("Toggle", {
                      attrs: { value: _vm.isActive },
                      on: { toggleChanged: _vm.statusChanged },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.isActive
                  ? _c(
                      "div",
                      { ref: "settings" },
                      [
                        _c("FormFieldReadOnlyText", {
                          attrs: { label: "SCIM url", value: _vm.scimPath },
                        }),
                        _vm._v(" "),
                        _c("FormFieldReadOnlyText", {
                          attrs: {
                            label: "Bearer Token",
                            value: _vm.currentToken,
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "FormField",
                          { attrs: { label: " ", orientation: "horizontal" } },
                          [
                            _c(
                              "a",
                              {
                                ref: "activateButton",
                                staticClass: "btn btn-default",
                                attrs: { href: "#" },
                                on: { click: _vm.activate },
                              },
                              [
                                _vm._v(
                                  "\n              Generate New Token\n            "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("i", [
              _vm._v(
                "\n        Activate SCIM provisioning and then copy the settings into your Identity Provider application.\n      "
              ),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }