
import PubSub from 'lib/pub_sub'

const evtNameSubSucceeded = 'pusher:subscription_succeeded'
const evtNameSubError = 'pusher:subscription_error'
const evtNameFailed = 'failed'
const evtNameComplete = 'complete'

// Facilitates workflow of making and API request, and waiting for a PubSub response.
// Commonly used with bulk actions.
//
// - Subscribe to a topic on a PubSub channel
// - Subscription succeeds
//   - onSubscribeSuccess callback fired, allows making request to API
// - Pubsub 'complete' event
//   - onComplete callback fired, allows handling response and cleanup
//
//
// topic: String, topic name of channel
// onSubscribeSuccess: Function, (topic String, reject), Callback when subscription succeeds
// onComplete: Function, (response Object, resolve, reject), Callback on 'complete' event
function pubSubWrappedAction(topic, onSubscribeSuccess, onComplete) {
  const channel = PubSub.subscribeToUserChannel({
    topic: topic,
    unique: true
  })

  return new Promise((resolve, reject) => {
    channel.bind(evtNameSubSucceeded, () => {
      channel.unbind(evtNameSubSucceeded)

      // Don't kick off request until PubSub has subscribed to the channel, or we may not receive the response.
      onSubscribeSuccess(channel.name, reject)
    })

    channel.bind(evtNameSubError, () => {
      channel.unbind(evtNameSubError)
      reject()
    })

    // response: { error: }
    channel.bind(evtNameFailed, (_response) => {
      channel.unbind(evtNameFailed)
      reject()

      PubSub.unsubscribe(channel)
    })

    // response: {}
    channel.bind(evtNameComplete, (response) => {
      channel.unbind(evtNameComplete)
      onComplete(response, resolve, reject)

      // We are done with the channel
      PubSub.unsubscribe(channel)
    })

    // Unhandled Pusher events: 'progress', 'started'
  })
}

export default { pubSubWrappedAction }
