import BrowserSupport from 'lib/browser_support'

export default {
  data() {
    return {
      browserSupport: null,
    }
  },

  computed: {
    isAppleDevice(){
      return ["Mac OS", "iOS"].includes(this.browserSupport._parserResult.os.name)
    }
  },

  created() {
    this.browserSupport = new BrowserSupport()
  }
}
