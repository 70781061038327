/* global gon */
import PubSub from 'lib/pub_sub'

// PubSub Initializer
// Sets configuration based on window.gon values
//
$(document).on('ready', () => {
  const rails_env = gon.global.rails_env

  PubSub.init({
    useLocalPusherFake: (rails_env.indexOf('development') >= 0 || rails_env.indexOf('test') >= 0),
    pusherKey: gon.global.JS_ENV.PUSHER_KEY,
    pusherCluster: gon.global.JS_ENV.PUSHER_CLUSTER,
    accountSubdomain: gon.current_account.subdomain,
    userToken: gon.current_user.pubsub_token,
  })
})
