export default Backbone.Model.extend({

  // Total number of employees included in this import
  //
  // Returns integer
  employeeCount: function(){
    return this.get('users').length
  },

  // Errors are present in this import
  //
  // Returns true if errors are present
  errors: function(){
    return this.get('state') == 'validation_errors'
  }
})
