var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col-xs-12 col-md-10 col-lg-9 col-centered" },
    [
      _c(
        "form",
        {
          staticClass:
            "form-horizontal simple_form w4-form fv-form fv-form-bootstrap",
        },
        [
          _c("FormAlerts", {
            attrs: {
              success: _vm.success,
              errors: _vm.formErrors,
              msgSuccess: _vm.successMessage,
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "panel gray-bg" }, [
            _c("h3", [_vm._v("Step 1: Enter Personal Information")]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("FormFieldInput", {
                  attrs: {
                    orientation: "horizontal",
                    required: true,
                    label: "First Name & Middle Initial",
                    errors: _vm.formErrors,
                    name: "first_name_and_initial",
                    type: "text",
                  },
                  model: {
                    value: _vm.formData.firstNameAndInitial,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "firstNameAndInitial", $$v)
                    },
                    expression: "formData.firstNameAndInitial",
                  },
                }),
                _vm._v(" "),
                _c("FormFieldInput", {
                  attrs: {
                    orientation: "horizontal",
                    required: true,
                    label: "Last Name",
                    errors: _vm.formErrors,
                    name: "last_name",
                    type: "text",
                  },
                  model: {
                    value: _vm.formData.lastName,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "lastName", $$v)
                    },
                    expression: "formData.lastName",
                  },
                }),
                _vm._v(" "),
                _c("FormFieldCheckbox", {
                  attrs: {
                    label:
                      "Check here if your name differs from that shown on your social security card and contact SSA at 800-772-1213 to ensure you get credit for your earnings.",
                    name: "last_name_differs_from_ss_card",
                    value: "false",
                  },
                  model: {
                    value: _vm.formData.nameDifferentOnSsCard,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "nameDifferentOnSsCard", $$v)
                    },
                    expression: "formData.nameDifferentOnSsCard",
                  },
                }),
                _vm._v(" "),
                _c("FormFieldInput", {
                  attrs: {
                    orientation: "horizontal",
                    required: true,
                    label: "Address",
                    errors: _vm.formErrors,
                    name: "street",
                    type: "text",
                  },
                  model: {
                    value: _vm.formData.addressStreet,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "addressStreet", $$v)
                    },
                    expression: "formData.addressStreet",
                  },
                }),
                _vm._v(" "),
                _c("FormFieldInput", {
                  attrs: {
                    orientation: "horizontal",
                    required: false,
                    label: " ",
                    errors: _vm.formErrors,
                    name: "apt",
                    type: "text",
                  },
                  model: {
                    value: _vm.formData.addressApartment,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "addressApartment", $$v)
                    },
                    expression: "formData.addressApartment",
                  },
                }),
                _vm._v(" "),
                _c("FormFieldInput", {
                  attrs: {
                    orientation: "horizontal",
                    required: true,
                    label: "City",
                    errors: _vm.formErrors,
                    name: "city",
                    type: "text",
                  },
                  model: {
                    value: _vm.formData.addressCity,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "addressCity", $$v)
                    },
                    expression: "formData.addressCity",
                  },
                }),
                _vm._v(" "),
                _c("FormFieldSelect", {
                  attrs: {
                    orientation: "horizontal",
                    required: true,
                    label: "State",
                    errors: _vm.formErrors,
                    name: "state",
                    options: _vm.states,
                  },
                  model: {
                    value: _vm.formData.addressState,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "addressState", $$v)
                    },
                    expression: "formData.addressState",
                  },
                }),
                _vm._v(" "),
                _c("FormFieldInput", {
                  attrs: {
                    orientation: "horizontal",
                    required: true,
                    label: "Zip Code",
                    errors: _vm.formErrors,
                    name: "zip",
                    type: "text",
                  },
                  model: {
                    value: _vm.formData.addressZip,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "addressZip", $$v)
                    },
                    expression: "formData.addressZip",
                  },
                }),
                _vm._v(" "),
                _c("FormFieldSsn", {
                  attrs: {
                    orientation: "horizontal",
                    required: true,
                    label: "Social Security #",
                    errors: _vm.formErrors,
                    name: "ssn",
                    type: "text",
                  },
                  model: {
                    value: _vm.formData.ssn,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "ssn", $$v)
                    },
                    expression: "formData.ssn",
                  },
                }),
                _vm._v(" "),
                _c("FormFieldRadio", {
                  attrs: {
                    label: "Filing Status",
                    required: true,
                    options: _vm.radioOptionsFilingStatus,
                    errors: _vm.formErrors,
                    name: "filing_status",
                    hint: _vm.formData.filingStatusHint,
                    orientation: "horizontal",
                  },
                  model: {
                    value: _vm.formData.filingStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "filingStatus", $$v)
                    },
                    expression: "formData.filingStatus",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "panel gray-bg" }, [
            _c("h3", [_vm._v("Exempt Status:")]),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _vm._m(1),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "pb-3" },
              [
                _vm._m(2),
                _vm._v(" "),
                _c("FormFieldInput", {
                  attrs: {
                    orientation: "horizontal",
                    required: false,
                    label:
                      "If you meet both conditions, write the word EXEMPT in all capital letters here:",
                    errors: _vm.formErrors,
                    name: "exempt_status",
                    type: "text",
                  },
                  model: {
                    value: _vm.exemptStatus,
                    callback: function ($$v) {
                      _vm.exemptStatus = $$v
                    },
                    expression: "exemptStatus",
                  },
                }),
                _vm._v(" "),
                _vm.isExempt
                  ? _c(
                      "div",
                      {
                        ref: "skipToStepFiveAlert",
                        staticClass: "alert alert-info",
                      },
                      [_c("strong", [_vm._v("Please skip to Step 5.")])]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          !_vm.isExempt
            ? _c("div", { ref: "stepTwo", staticClass: "panel gray-bg" }, [
                _c("h3", [_vm._v("Step 2: Multiple Jobs or Spouse Works")]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("FormFieldRadio", {
                      ref: "numberOfJobsInput",
                      attrs: {
                        label: _vm.numberOfJobsLabel,
                        required: false,
                        options: _vm.radioOptionsNumberOfJobs,
                        errors: _vm.formErrors,
                        name: "number_of_jobs",
                        orientation: "horizontal",
                      },
                      model: {
                        value: _vm.formData.numberOfJobs,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "numberOfJobs", $$v)
                        },
                        expression: "formData.numberOfJobs",
                      },
                    }),
                    _vm._v(" "),
                    _vm.jobCount === 1
                      ? _c("div", { staticClass: "alert alert-info" }, [
                          _vm._v(
                            "\n          Please continue to Step 3.\n        "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.jobCount === 2
                      ? _c("FormFieldRadio", {
                          attrs: {
                            label: "Is the pay similar for both jobs?",
                            required: false,
                            options: _vm.radioOptionsYesNo,
                            errors: _vm.formErrors,
                            name: "two_jobs_similar_pay",
                            orientation: "horizontal",
                          },
                          model: {
                            value: _vm.formData.twoJobsSimilarPay,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "twoJobsSimilarPay", $$v)
                            },
                            expression: "formData.twoJobsSimilarPay",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.twoJobsSimilarPayValue === 0 || _vm.jobCount === 3
                      ? _c("MultipleJobsWorksheet", {
                          ref: "multipleJobsWorksheet",
                          attrs: {
                            jobs: _vm.jobCount,
                            status: _vm.filingStatus,
                            errors: _vm.formErrors,
                            name: "multiple_jobs_worksheet",
                          },
                          on: { updateFromMjw: _vm.handleMjwChange },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.twoJobsSimilarPayValue === 1
                      ? _c(
                          "div",
                          {
                            ref: "continueToStep3Message",
                            staticClass: "alert alert-info",
                          },
                          [
                            _vm._v(
                              "\n          Please continue to Step 3.\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.jobCount === 4
                      ? _c(
                          "div",
                          {
                            ref: "irsWithholdingEstimatorMessage",
                            staticClass: "alert alert-info",
                          },
                          [
                            _vm._v("\n          Please use the\n          "),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.irsTaxWithholdingEstimatorUrl,
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  "\n            IRS Tax Withholding Estimator\n          "
                                ),
                              ]
                            ),
                            _vm._v(
                              "\n          to calculate your withholding. Once finished enter the results from\n          the IRS Tax Withholding Estimator into the corresponding steps here.\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.isExempt
            ? _c("div", { ref: "stepThree", staticClass: "panel gray-bg" }, [
                _c("h3", [_vm._v("Step 3: Claim Dependents")]),
                _vm._v(" "),
                _c("div", [
                  _c("p", [
                    _vm._v(
                      "\n          If your income will be $200,000 or less ($400,000 or less if married filing jointly):\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("FormFieldNumber", {
                        ref: "qualifyingChildrenInput",
                        attrs: {
                          orientation: "horizontal",
                          required: false,
                          label:
                            "How many qualifying children under age 17 do you have?",
                          errors: _vm.formErrors,
                          name: "qualifying_children",
                          min: "0",
                        },
                        model: {
                          value: _vm.formData.qualifyingChildren,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "qualifyingChildren", $$v)
                          },
                          expression: "formData.qualifyingChildren",
                        },
                      }),
                      _vm._v(" "),
                      _c("FormFieldNumber", {
                        ref: "otherDependentsInput",
                        attrs: {
                          orientation: "horizontal",
                          required: false,
                          label: "How many other dependents do you have?",
                          errors: _vm.formErrors,
                          name: "other_dependents",
                          min: "0",
                        },
                        model: {
                          value: _vm.formData.otherDependents,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "otherDependents", $$v)
                          },
                          expression: "formData.otherDependents",
                        },
                      }),
                      _vm._v(" "),
                      _c("FormFieldCurrency", {
                        ref: "otherTaxCreditsInput",
                        attrs: {
                          orientation: "horizontal",
                          required: false,
                          label: "Other tax credits for dependents",
                          errors: _vm.formErrors,
                          name: "other_tax_credits",
                          min: "0",
                        },
                        model: {
                          value: _vm.formData.otherTaxCredits,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "otherTaxCredits", $$v)
                          },
                          expression: "formData.otherTaxCredits",
                        },
                      }),
                      _vm._v(" "),
                      _c("FormFieldCurrency", {
                        attrs: {
                          orientation: "horizontal",
                          required: false,
                          errors: _vm.formErrors,
                          label: "Total withholding for dependents",
                          name: "total_dependents_withholding",
                          type: "text",
                          value: _vm.totalDependentsWithholding,
                          disabled: "true",
                          hint: "This field is calculated using the numbers of dependents entered above and cannot be edited.",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.isExempt
            ? _c("div", { ref: "stepFour", staticClass: "panel gray-bg" }, [
                _c("h3", [_vm._v("Step 4 (optional): Other Adjustments")]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("FormFieldCurrency", {
                      attrs: {
                        orientation: "horizontal",
                        required: false,
                        label: "(a) Other income",
                        errors: _vm.formErrors,
                        name: "other_income",
                        min: "0",
                        helpPopoverText:
                          "If you want tax withheld for other income you expect this year that won't\n                          have withholding, enter the amount of other income here. This may include interest,\n                          dividends, and retirement income. You should not include income from any jobs.",
                      },
                      model: {
                        value: _vm.formData.otherIncome,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "otherIncome", $$v)
                        },
                        expression: "formData.otherIncome",
                      },
                    }),
                    _vm._v(" "),
                    _c("hr"),
                    _vm._v(" "),
                    !_vm.showDeductionsCalculator
                      ? _c(
                          "div",
                          {
                            ref: "deductionsCalculatorInfo",
                            staticClass: "alert alert-info",
                          },
                          [
                            _vm._v(
                              "\n          If you expect to claim deductions other than the standard deduction\n          and want to reduce your withholding, please use the Deductions worksheet.\n          "
                            ),
                            _c(
                              "div",
                              { staticClass: "mt-2" },
                              [
                                !_vm.showDeductionsCalculator
                                  ? _c(
                                      "WbButton",
                                      {
                                        staticClass: "btn-sm",
                                        on: {
                                          click: _vm.toggleDeductionsCalculator,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n              Show Deductions Worksheet\n            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showDeductionsCalculator
                      ? _c(
                          "div",
                          {
                            ref: "deductionsCalculator",
                            staticClass: "well pb-2 clearfix",
                          },
                          [
                            _c("h4", [_vm._v("Deductions Worksheet")]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                "\n            If you expect to claim deductions other than the standard deduction\n            and want to reduce your withholding, please use the Deductions worksheet below.\n          "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("FormFieldCurrency", {
                              attrs: {
                                orientation: "horizontal",
                                required: false,
                                label:
                                  "Estimate of your 2024 itemized deductions",
                                errors: _vm.formErrors,
                                name: "deductions_calculator_itemized",
                                min: "0",
                                hint: "From Schedule A (Form 1040 or 1040-SR). Such deductions may include qualifying home mortgage interest, charitable contributions, state and local taxes (up to $10,000), and medical expenses in excess of 7.5% of your income.",
                              },
                              model: {
                                value:
                                  _vm.formData.deductionsCalculatorItemized,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData,
                                    "deductionsCalculatorItemized",
                                    $$v
                                  )
                                },
                                expression:
                                  "formData.deductionsCalculatorItemized",
                              },
                            }),
                            _vm._v(" "),
                            _c("FormFieldCurrency", {
                              attrs: {
                                orientation: "horizontal",
                                required: false,
                                label:
                                  "Estimate of student loan interest, deductible IRA contributions, other",
                                errors: _vm.formErrors,
                                name: "deductions_calculator_other",
                                min: "0",
                                hint: "Enter an estimate of your student loan interest, deductible IRA contributions, and certain other adjustments from Schedule 1 (Form 1040 or 1040-SR). See Pub. 505 for more information",
                              },
                              model: {
                                value: _vm.formData.deductionsCalculatorOther,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData,
                                    "deductionsCalculatorOther",
                                    $$v
                                  )
                                },
                                expression:
                                  "formData.deductionsCalculatorOther",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("FormFieldCurrency", {
                      attrs: {
                        orientation: "horizontal",
                        required: false,
                        label: "(b) Deductions",
                        errors: _vm.formErrors,
                        name: "deductions",
                        type: "number",
                        min: "0",
                        helpPopoverText:
                          "If you expect to claim deductions other than the standard deduction\n                          and want to reduce your withholding, use the Deductions Calculator below.",
                        hint: "This field may have been calculated using the Deductions Calculator but can be adjusted.",
                      },
                      model: {
                        value: _vm.formData.deductions,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "deductions", $$v)
                        },
                        expression: "formData.deductions",
                      },
                    }),
                    _vm._v(" "),
                    _c("FormFieldCurrency", {
                      attrs: {
                        orientation: "horizontal",
                        required: false,
                        label: "(c) Extra withholding",
                        errors: _vm.formErrors,
                        name: "extra_withholding",
                        min: "0",
                        helpPopoverText:
                          "If you used the Multiple Jobs Worksheet in Step 2, the result is automatically entered here.\n                          Otherwise, enter any additional tax you want withheld each pay period.",
                        hint: "This field is calculated using the Multiple Jobs Worksheet in Step 2, please adjust as necessary.",
                      },
                      model: {
                        value: _vm.formData.extraWithholding,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "extraWithholding", $$v)
                        },
                        expression: "formData.extraWithholding",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "panel gray-bg" },
            [
              _c("h3", [_vm._v("Step 5:")]),
              _vm._v(" "),
              _c("SignaturePad", {
                attrs: { errors: _vm.formErrors },
                model: {
                  value: _vm.formData.signature,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "signature", $$v)
                  },
                  expression: "formData.signature",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("FormAlerts", {
            attrs: {
              success: _vm.success,
              errors: _vm.formErrors,
              msgSuccess: _vm.successMessage,
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "text-right" },
            [
              _c(
                "WbButton",
                {
                  ref: "submitButton",
                  attrs: { isLoading: _vm.isSubmitting },
                  on: { click: _vm.handleSubmit },
                },
                [_vm._v("\n        Submit\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [
        _vm._v(
          "\n          Do not fill out this field unless you intend to claim exemption.\n          If you claim exemption no taxes will be withheld\n          and you will be responsible for any tax owed at the end of the year.\n          If you do not wish to claim exemption please leave this field blank and continue to Step 2.\n        "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n        I claim exemption from withholding for 2024,\n        and I certify that I meet "
      ),
      _c("strong", [_vm._v("both")]),
      _vm._v("\n        of the following conditions for exemption.\n      "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _vm._v("\n            Last year I had a right to refund of "),
        _c("strong", [_vm._v("all")]),
        _vm._v("\n            federal tax withheld because I had "),
        _c("strong", [_vm._v("no")]),
        _vm._v("\n            tax liability, "),
        _c("strong", [_vm._v("and")]),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v("\n            This year I expect a refund of "),
        _c("strong", [_vm._v("all")]),
        _vm._v(
          "\n            federal income tax withheld because I expect to have "
        ),
        _c("strong", [_vm._v("no")]),
        _vm._v("\n            tax liability\n          "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }