var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "WbCard",
    { ref: "form", staticClass: "form-horizontal" },
    [
      _c(
        "div",
        { staticClass: "required" },
        [
          _c("FormFieldInput", {
            ref: "name",
            attrs: {
              placeholder: "Staff submissions",
              name: "name",
              orientation: "horizontal",
              label: "Name",
              required: true,
              errors: _vm.errors,
            },
            model: {
              value: _vm.formData.name,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "name", $$v)
              },
              expression: "formData.name",
            },
          }),
          _vm._v(" "),
          _c("FormFieldInput", {
            ref: "url",
            attrs: {
              placeholder: "https://website.com/webhooks",
              name: "url",
              orientation: "horizontal",
              label: "URL",
              required: true,
              errors: _vm.errors,
            },
            model: {
              value: _vm.formData.url,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "url", $$v)
              },
              expression: "formData.url",
            },
          }),
          _vm._v(" "),
          _c("FormFieldRadio", {
            ref: "enabledFieldOptions",
            attrs: {
              name: "enabled",
              orientation: "horizontal",
              label: "Enabled",
              required: true,
              errors: _vm.errors,
              options: _vm.enabledOptions,
            },
            model: {
              value: _vm.formData.enabled,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "enabled", $$v)
              },
              expression: "formData.enabled",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("h4", { staticClass: "text-strong" }, [
            _vm._v("\n      Authentication (optional)\n    "),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "help-block" }, [
            _vm._v(
              "\n      Add additional security to your webhook with a username and password.\n    "
            ),
          ]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("FormFieldInput", {
            ref: "authUsername",
            attrs: {
              name: "auth_username",
              orientation: "horizontal",
              label: "Username",
              errors: _vm.errors,
            },
            model: {
              value: _vm.formData.auth_username,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "auth_username", $$v)
              },
              expression: "formData.auth_username",
            },
          }),
          _vm._v(" "),
          _c("FormFieldInput", {
            ref: "authPassword",
            attrs: {
              name: "auth_password",
              type: "password",
              orientation: "horizontal",
              label: "Password",
              errors: _vm.errors,
            },
            model: {
              value: _vm.formData.auth_password,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "auth_password", $$v)
              },
              expression: "formData.auth_password",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", [
        _c("h4", { staticClass: "text-strong" }, [
          _vm._v("\n      Event Notifications\n    "),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "help-block" }, [
          _vm._v(
            "\n      Select the event notifications you would like your webhook endpoint to receive by selecting from the checkboxes below:\n    "
          ),
        ]),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c(
          "div",
          { ref: "events", staticClass: "form-group" },
          [
            _c("FormLabel", {
              attrs: { label: "Events", orientation: "horizontal" },
            }),
            _vm._v(" "),
            _vm._l(_vm.systemEventsOptions, function (option) {
              return _c("FormFieldCheckbox", {
                key: option.value,
                attrs: {
                  name: "events",
                  label: option.label,
                  errors: _vm.errors,
                  id: option.value,
                  value: _vm.isEventChecked(option),
                },
                on: {
                  input: function ($event) {
                    return _vm.handleCheckboxChange(option)
                  },
                },
              })
            }),
            _vm._v(" "),
            _vm.submittedFormEventSelected
              ? _c("FormFieldDropdown", {
                  attrs: {
                    name: "form_submitted_scope",
                    errors: _vm.errors,
                    options: _vm.activeFormOptions,
                    label: "Employee Submitted a Form: Select a form",
                    orientation: "horizontal",
                  },
                  model: {
                    value: _vm.formData.form_submitted_scope,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "form_submitted_scope", $$v)
                    },
                    expression: "formData.form_submitted_scope",
                  },
                })
              : _vm._e(),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("Toolbar", { attrs: { reverse: "" } }, [
        _c(
          "div",
          { staticClass: "btn-sequence" },
          [
            _c("WbButton", {
              ref: "saveButton",
              attrs: { label: "Save", isLoading: _vm.isLoading },
              on: { click: _vm.handleSubmit },
            }),
            _vm._v(" "),
            _c("WbButton", {
              ref: "cancelButton",
              attrs: { context: "default", label: "Cancel" },
              on: { click: _vm.handleCancel },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }