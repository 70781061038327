import * as BaseCollectionModule from 'vuex/mixins/base_vuex_collection_module'
import Routes from 'lib/routes'
import ErrorService from 'lib/error_service'
import { ajax } from 'jquery'

function state() {
  return {
    collection: [],
    pagination: null,
    requiresRefresh: false
  }
};

const getters = {
  ...BaseCollectionModule.getters,
  collection: state => state.collection,
  pagination: state => state.pagination,
  requiresRefresh: state => state.requiresRefresh,
}

export const mutationTypes = {
  ...BaseCollectionModule.mutationTypes,
  SET_COLLECTION: 'SET_COLLECTION',
  SET_PAGINATION: 'SET_PAGINATION',
  SET_REQUIRES_REFRESH: 'SET_REQUIRES_REFRESH'
}

export const mutations = {
  ...BaseCollectionModule.mutations,
  [mutationTypes.SET_PAGINATION]: (state, pagination) => {
    state.pagination = pagination
  },
  [mutationTypes.SET_COLLECTION]: (state, cases) => {
    state.collection = cases
  },
  [mutationTypes.SET_REQUIRES_REFRESH]: (state, requiresRefresh) => {
    state.requiresRefresh = requiresRefresh
  }
}

const actions = {
  ...BaseCollectionModule.actions,

  // Fetches all (permitted) E-Verify cases with pagination page number and then fetches the employee's name
  //
  // Acceptable filters: sort, search, case_status, page, employment_status
  //    - sort (optional): sorting property ('name' or 'date'); default value is 'date' which sorts by case_created_at
  //    - search (optional): search term used for filtering: applied to employee's first and last name
  //    - case_status (optional): list of case statuses used for filtering
  //    - page (optional): page number of results; default value is 1
  //    - employment_status (optional): employment status of case's employee; default value is 'active'
  //
  // returns a paginated list of everifyCases:
  //    - everifyCases: list of everifyCases [Array]
  //    - nextPage: page number of next page, if there is one
  //    - previousPage: page number of previous page, if there is one
  //    - lastPage: page number of next page, if there is one
  //    - totalItems: total number of cases across all pages
  //    - indexStart: index number for first item on this page (if this is page two and page one had 10 results, this number would be 11)
  //    - indexEnd: index number for last item on this page (if this is page one and it has 10 results, this number would be 10)
  fetchAll: ({ commit, dispatch }, { filters }) => {
    return new Promise((resolve, reject) => {
      ajax({
        method: 'GET',
        url: Routes.Api.everify_cases_path,
        data: filters
      })
        .then(response => {
          const everifyCases = response.everify_cases
          const employeeIds = everifyCases.map((c) => c.employee_id)

          commit(mutationTypes.SET_COLLECTION, everifyCases)
          commit(mutationTypes.SET_REQUIRES_REFRESH, false)
          commit(mutationTypes.SET_PAGINATION, {
            nextPage: findNextPage(response),
            previousPage: findPreviousPage(response),
            lastPage: findLastPage(response),
            totalItems: response._total_items,
            indexStart: response._index_start,
            indexEnd: response._index_end
          })
          dispatch(
            'employees/bulkShow',
            { employeeIds: employeeIds },
            { root: true }
          ).then(() => {
            resolve()
          }).catch(error => {
            reject(error)
          })
        })
        .fail(xhr => reject(xhr))
    })
  },

  fetchById: ({ _commit }, { id }) => {
    return new Promise((resolve, reject) => {
      ajax({
        method: 'GET',
        url: Routes.Api.everify_case_path({id: id})
      })
        .then(response => resolve(response) )
        .fail(xhr => reject(xhr))
    })
  },

  // Triggers refresh of all EverifyCases in this account
  refreshAll: () => {
    ajax({
      method: 'POST',
      url: Routes.Api.refresh_all_everify_cases_path,
      data: {}
    }).fail(xhr => {
      new ErrorService("Failed to refresh EverifyCases", "EverifyRefreshError").report(xhr)
    })
  },

  // Confirm whether photos match or not
  //
  // caseId       - [Number] E-Verify case ID
  // confirmation - [Boolean] true/false (do photos match?)
  //
  // returns Promise
  photoConfirmation: ({ _commit }, { caseId, confirmation }) => {
    return new Promise((resolve, reject) => {
      ajax({
        method: 'POST',
        url: Routes.Api.everify_photo_matching_confirmation_path({ id: caseId }),
        data: { photo_confirmation: confirmation },
      }).then((response) => {
        resolve(response)
      }).fail((xhr) => reject(xhr))
    })
  },

  // Sends currently uploaded document
  //
  // caseId       - [Number] E-Verify case ID
  //
  // returns Promise
  submitDocuments: ({ _commit }, { caseId }) => {
    return new Promise((resolve, reject) => {
      ajax({
        method: 'POST',
        url: Routes.Api.everify_submit_documents_path({ id: caseId })
      }).then((response) => {
        resolve(response)
      }).fail((xhr) => reject(xhr))
    })
  },

  // fetch photo urls for photo-matching panel
  fetchPhotoUrls: ({ _commit }, { caseId }) => {
    return new Promise((resolve, reject) => {
      ajax({
        method: 'GET',
        url: Routes.Api.photo_match_images_path({ id: caseId })
      }).then((response) => {
        resolve(response)
      }).fail((xhr) => reject(xhr))
    })
  },

  closeCase: ({ _commit }, { caseId, closureReason }) => {
    return new Promise((resolve, reject) => {
      ajax({
        method: 'POST',
        url: Routes.Api.close_everify_case_path({ id: caseId }),
        data: { closure_reason: closureReason  },

      }).then((response) => {
        resolve(response)
      }).fail((xhr) => reject(xhr))
    })
  },

  duplicateCases: ({ _commit }, { caseId }) => {
    return new Promise((resolve, reject) => {
      ajax({
        method: 'GET',
        url: Routes.Api.duplicate_cases_path({ id: caseId })
      }).then((response) => {
        resolve(response)
      }).fail((xhr) => reject(xhr))
    })
  },

  dataConfirmation: ({ _commit }, { caseId, caseConfirmedData }) => {
    return new Promise((resolve, reject) => {
      ajax({
        method: 'POST',
        url: Routes.Api.everify_data_confirmation_path({ id: caseId }),
        data: { confirmed_data: caseConfirmedData },
      })
        .then(response => resolve(response))
        .fail(xhr => reject(xhr))
    })
  },

  submitTNCNoAction: ({ _commit }, { caseId, employeeNotified, actor }) => {
    return new Promise((resolve, reject) => {
      ajax({
        method: 'POST',
        url: Routes.Api.everify_tnc_no_action_path({ id: caseId }),
        data: {
          employee_notified: employeeNotified,
          actor
        },
      })
        .then(response => resolve(response))
        .fail(xhr => reject(xhr))
    })
  },

  submitTNCReferCase: ({ _commit }, { caseId, employeeNotified, actor }) => {
    return new Promise((resolve, reject) => {
      ajax({
        method: 'POST',
        url: Routes.Api.everify_tnc_refer_case_path({ id: caseId }),
        data: {
          employee_notified: employeeNotified,
          actor
        },
      })
        .then(response => resolve(response))
        .fail(xhr => reject(xhr))
    })
  },

  // Only used on staging/development for quick deletion of case
  deleteCase: (_commit, { caseId, errorStatus = false}) => {
    return new Promise((resolve, reject) => {
      ajax({
        method: 'DELETE',
        url: Routes.Api.everify_delete_path({ id: caseId }),
        data: JSON.stringify({ error_status: errorStatus }),
        dataType: 'json',
        contentType: 'application/json'
      })
        .then(response => resolve(response) )
        .fail(xhr => reject(xhr))
    })
  },
}

function findNextPage(apiResponse) {
  const nextPageLink = apiResponse._links.next
  if (nextPageLink) { return parsePageNum(nextPageLink) }
}

function findPreviousPage(apiResponse) {
  const previousPageLink = apiResponse._links.previous
  if (previousPageLink) { return parsePageNum(previousPageLink) }
}

function findLastPage(apiResponse) {
  const lastPageLink = apiResponse._links.last
  if (lastPageLink) { return parsePageNum(lastPageLink) }
}

// Uses a regex to parse a url and extract the page number
//
// Example url: '/api/everify/cases?sort=name&status=closed&page=5&search=john
// The regex divides the url into four groups:
//   - group 1: /api/everify/cases?sort=name&status=closed
//   - group 2: page=
//   - group 3: 5
//   - group 4: &search=john
// The number in the third group is returned.
function parsePageNum(link) {
  const pageNum = link.href.match(/(\/api\/everify\/cases.*)(page=)(\d*)(.*)/)[3]
  return parseInt(pageNum, 10)
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
