var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("RadioToggle", {
    staticClass: "d-inline-block",
    attrs: {
      value: _vm.radioValue,
      options: _vm.toggleOptions,
      disabled: _vm.isLoading,
    },
    on: { selected: _vm.handleChange },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }