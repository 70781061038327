<template>
  <div>
    <div>
      <label class="small">
        {{ filter.title }}
      </label>
      <span v-if="filter.help_text">
        <a
          class="help-popover ml-1"
          v-tooltip.html="filter.help_text"
          href="javascript:void(0)"
        >
          <i class="fa fa-exclamation-circle" />
        </a>
      </span>
    </div>
    <select class='form-control' v-model="value">
      <option
        v-for="(option, index) in filter.options"
        :value="option.value"
        :key="index"
      >
        {{option.label}}
      </option>
    </select>
  </div>
</template>

<script>
  import BaseFilter from 'components/common/collection_view_filters/BaseFilter'

  export default {
    extends: BaseFilter,
    name: 'select-filter'
  }
</script>
