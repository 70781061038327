var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "wb-card--alert__section" }, [
      _vm.categoryName
        ? _c("div", { staticClass: "wb-card--alert__category" }, [
            _c(
              "span",
              { ref: "category-name", staticClass: "label label-plain mr-2" },
              [_vm._v("\n        " + _vm._s(_vm.categoryName) + "\n      ")]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "wb-card--alert__content" },
        _vm._l(_vm.countsData, function (value, name) {
          return _c(
            "div",
            { key: name, staticClass: "wb-card--alert__content__row" },
            [
              _vm.alertsMap[name]
                ? _c("a", { attrs: { href: _vm.alertsMap[name].path } }, [
                    _c("strong", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.getPluralizedCount(
                              _vm.countsData[name],
                              _vm.alertsMap[name].model
                            )
                          ) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.alertsMap[name].message) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
            ]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }