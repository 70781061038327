import Constants from 'lib/constants'

export default {
  // Browsers do not provide a consistent way to know when a transition ends (which is useful for chaining animations
  // together). We rely on Bootstrap's transition.js inside this method, but we abstract away that dependency by having
  // our app call here instead of into Bootstrap directly.
  //
  // $el - (jQuery) element to which animation is being applied
  // expectedDuration - Integer equal animation-duration set in CSS
  // callbackFn - function to fire when transition is done
  //
  // See: https://github.com/twbs/bootstrap-sass/blob/master/assets/javascripts/bootstrap/transition.js
  onTransitionEnd: function($el, expectedDuration, callbackFn) {
    const TRANSITION_END_EVENT = 'bsTransitionEnd'
    $el.one(TRANSITION_END_EVENT, callbackFn).emulateTransitionEnd(expectedDuration)
  },

  slideInLTR: function($el, visible, options) {
    const SLIDE_DURATION = 500 /*ms*/
    if (visible) {
      if ($el.hasClass('in'))
        return

      this.onTransitionEnd($el, SLIDE_DURATION, function() {
        $el.addClass('in')
        if (options && options.callback)
          options.callback.call()
      })

      $el.addClass('anim-slide-in-ltr').show()
    } else {
      this.onTransitionEnd($el, SLIDE_DURATION, function() {
        $el.hide()
        $el.removeClass('anim-slide-out-rtl')
        if (options && options.callback)
          options.callback.call()
      })

      $el.addClass('anim-slide-out-rtl')
      $el.removeClass('anim-slide-in-ltr').removeClass('in')
    }
  },

  scale: function($el, visible, options) {
    const SCALE_DURATION = 500 /*ms*/

    if (!$el.hasClass('anim-scale')) $el.addClass('anim-scale')

    this.onTransitionEnd($el, SCALE_DURATION, function() {
      if (!visible) $el.css('height', '0px')

      if (options && options.callback) options.callback.call()
    })
    if (visible) $el.removeAttr('style')
    $el.toggleClass('in', visible)
  },

  fade: function($el, visible, options) {
    const FADE_DURATION = 150 /*ms*/

    if (!$el.hasClass('fade')) {
      $el.addClass('fade')
    }

    this.onTransitionEnd($el, FADE_DURATION, function() {
      if (!visible) {
        $el.hide()

        if (options && options.callback) {
          options.callback.call()
        }
      }
    })

    if (visible) {
      $el.removeAttr('style')
    }

    $el.toggleClass('in', visible)
  },

  // Scrolls to an element. If the element is inside the modal, the modal itself is animated. Otherwise, the
  // HTML/BODY tags are animated.
  //
  // $el - (jQuery) the element to scroll to
  // options -
  //   offset - (Integer, default: 25) scrolls to an offset from the given element. Useful for putting a little bit of
  //            padding between the top of the viewport and the element; otherwise, the element is butted right up
  //            against the viewport top and looks odd.
  //   speed - (Integer, default: 250) scroll animation speed
  scrollTo: function($el, options) {
    options = _.extend({ offset: 25, speed: 250 }, options)

    const currentPosition = $(window).scrollTop()
    const targetPosition = $el.offset().top - options.offset
    if (targetPosition < currentPosition) {
      const $parentEl = $el.closest('.modal').length > 0 ? $el.closest('.modal') : $('html, body')
      $parentEl.animate({ scrollTop: targetPosition }, options.speed)
    }
  },

  // Highlight an element for a short duration
  //
  // $el - Element to highlight
  temporaryHighlight: function($el) {
    // Idempotency: if class already has a highlight applied, don't do anything
    if ($el.hasClass('anim-temporary-highlight')) {
      return
    }

    // Apply the highlight
    $el.addClass('anim-temporary-highlight')

    // After the specified time, add the 'out' class which begins the fadeout animation. After that transition
    // completes, remove all classes.
    setTimeout(() => {
      $el.addClass('out')

      this.onTransitionEnd($el, Constants.FADE_OUT_DURATION, () => {
        $el.removeClass('anim-temporary-highlight')
          .removeClass('out')
      })
    }, Constants.LONG_HIGHLIGHT_TIME)
  },
}
