var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wb-multiselect wb-multiselect-search" },
    [
      _c(
        "Multiselect",
        {
          attrs: {
            "internal-search": false,
            label: "label",
            "track-by": "value",
            loading: _vm.isLoading,
            placeholder: _vm.placeholder,
            searchable: true,
            options: _vm.selectOptions,
            preserveSearch: true,
          },
          on: {
            "search-change": _vm.throttleSetResults,
            input: _vm.handleInput,
          },
          scopedSlots: _vm._u([
            {
              key: "option",
              fn: function (props) {
                return [
                  _c("div", [
                    _c("div", { staticClass: "text-strong" }, [
                      _vm._v(_vm._s(props.option.label)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "small" }, [
                      _vm._v(_vm._s(props.option.desc)),
                    ]),
                  ]),
                ]
              },
            },
          ]),
          model: {
            value: _vm.localValue,
            callback: function ($$v) {
              _vm.localValue = $$v
            },
            expression: "localValue",
          },
        },
        [
          _vm._v(" "),
          _c("span", { attrs: { slot: "noResult" }, slot: "noResult" }, [
            _vm._v("No results found"),
          ]),
          _vm._v(" "),
          _c("span", { attrs: { slot: "noOptions" }, slot: "noOptions" }, [
            _vm._v("Please enter 3 or more characters"),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }