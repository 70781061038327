var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "wb-modal",
    {
      ref: "modal",
      attrs: {
        title: "ADP® Workforce Now Integration",
        watchPageContextVariable: "show_adp_integration_modal",
        locked: _vm.$wait.is("show_adp_integration_modal"),
      },
      on: {
        modalShown: _vm.handleModalShown,
        modalHidden: _vm.handleModalHidden,
      },
    },
    [
      _c(
        "v-wait",
        { attrs: { for: _vm.loadingName } },
        [
          _c("spinner", {
            attrs: { slot: "waiting", showLongWaitMessage: true },
            slot: "waiting",
          }),
          _vm._v(" "),
          _vm.hasError
            ? _c(
                "div",
                { ref: "syncError", staticClass: "alert alert-danger p-3" },
                [
                  _vm._v(
                    "\n      Something went wrong when syncing data with ADP®\n      "
                  ),
                  _c("hr"),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-strong" }, [
                    _vm._v(_vm._s(_vm.errorData.title)),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n        " + _vm._s(_vm.errorData.message) + "\n      "
                    ),
                  ]),
                ]
              )
            : _c("div", { ref: "ready" }, [
                _c(
                  "div",
                  [
                    _c("form-field-select", {
                      attrs: {
                        required: true,
                        options: _vm.templatesSelectFormatted,
                        placeholder: "Select a template",
                        label: "ADP® Onboarding Template",
                        fieldWidth: "medium-field",
                      },
                      model: {
                        value: _vm.selectedTemplate,
                        callback: function ($$v) {
                          _vm.selectedTemplate = $$v
                        },
                        expression: "selectedTemplate",
                      },
                    }),
                    _vm._v(" "),
                    _c("form-field-select", {
                      attrs: {
                        required: true,
                        options: _vm.companyCodesSelectFormatted,
                        placeholder: "Select a Company Code",
                        label: "Company Code",
                        fieldWidth: "medium-field",
                      },
                      model: {
                        value: _vm.selectedCompanyCode,
                        callback: function ($$v) {
                          _vm.selectedCompanyCode = $$v
                        },
                        expression: "selectedCompanyCode",
                      },
                    }),
                    _vm._v(
                      "\n\n        The following data will be automatically synced for " +
                        _vm._s(_vm.employee.first_name) +
                        ":\n        "
                    ),
                    _c("div", { staticClass: "alert alert-info" }, [
                      _c("dl", [
                        _c("dt", [_vm._v("Basic Profile Data")]),
                        _vm._v(" "),
                        _c("dd", [
                          _vm._v(
                            "First Name, Last Name, SSN, Birthdate, Address, Email, Phone, Gender"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("dl", [
                        _c("dt", [_vm._v("Form W-4 Data")]),
                        _vm._v(" "),
                        _c("dd", [
                          _vm._v(
                            "Filing Status, Additional Income, Extra Withholding, Withholding Status, Deductions, Dependents"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("dl", { staticClass: "mb-0" }, [
                        _c("dt", [_vm._v("Payroll Data")]),
                        _vm._v(" "),
                        _c("dd", [
                          _vm._v("Pay Frequency, Pay Rate, Pay Amount"),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("hr"),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "\n          The staff member's Direct Deposit data "
                      ),
                      _c("strong", [
                        _vm._v("will not be automatically synced"),
                      ]),
                      _vm._v(
                        ' until you update them from "In-Progress" to "Employee" in ADP® Workforce Now. Once that happens, we will automatically sync this data:\n        '
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "alert alert-secondary" }, [
                      _c("dl", { staticClass: "mb-0" }, [
                        _c("dt", [_vm._v("Direct Deposit Data")]),
                        _vm._v(" "),
                        _c("dd", [
                          _vm._v(
                            "Account Number, Routing Number, Account Type"
                          ),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
              ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "button",
            {
              ref: "closeButton",
              staticClass: "btn btn-default",
              attrs: { disabled: _vm.$wait.is("show_adp_integration_modal") },
              on: { click: _vm.hide },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.canSubmit ? "Cancel" : "Close") +
                  "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _vm.canSubmit
            ? _c(
                "ladda-button",
                {
                  ref: "submit",
                  attrs: { disabled: _vm.submitDisabled },
                  on: { click: _vm.submit },
                },
                [
                  _c("i", { staticClass: "fa fa-upload" }),
                  _vm._v("\n      Sync to ADP® Workforce Now\n    "),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }