var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeader", { attrs: { title: "Enhanced Document Verification" } }),
      _vm._v(" "),
      _vm.isFeatureEnabled
        ? _c("div", { ref: "enabled", staticClass: "panel gray-bg" }, [
            _vm._v(
              "\n    Enhanced Document Verification has been enabled on your account.\n  "
            ),
          ])
        : _c("div", { ref: "disabled" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-7" }, [
                _c("h3", { staticClass: "feature-list-section-title" }, [
                  _vm._v(
                    "\n          Powerful Document Verification features are available across WorkBright I-9's pricing tiers, helping your business prevent common errors, improve compliance, and reduce time spent correcting document issues.\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("h4", { staticClass: "feature-list-section-title" }, [
                  _vm._v("What’s included in each tier: "),
                ]),
                _vm._v(" "),
                _c("img", {
                  ref: "documentVerificationsImage",
                  staticClass: "upsell-image w-100 my-3",
                  attrs: { src: _vm.documentVerificationsImageUrl },
                }),
                _vm._v(" "),
                _c(
                  "h4",
                  {
                    ref: "growthTierTitle",
                    staticClass: "feature-list-section-title",
                  },
                  [_vm._v("Growth tier features:")]
                ),
                _vm._v(" "),
                _c("ul", { ref: "growthTierList" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _vm._m(1),
                  _vm._v(" "),
                  _vm._m(2),
                ]),
                _vm._v(" "),
                _c(
                  "h4",
                  {
                    ref: "premiumTierTitle",
                    staticClass: "feature-list-section-title",
                  },
                  [_vm._v("Premium tier features:")]
                ),
                _vm._v(" "),
                _c("ul", { ref: "premiumTierList" }, [
                  _vm._m(3),
                  _vm._v(" "),
                  _vm._m(4),
                  _vm._v(" "),
                  _vm._m(5),
                  _vm._v(" "),
                  _vm._m(6),
                ]),
                _vm._v(" "),
                _c(
                  "h4",
                  {
                    ref: "whyDocVerificationTitle",
                    staticClass: "feature-list-section-title",
                  },
                  [_vm._v("Why use enhanced document verification? ")]
                ),
                _vm._v(" "),
                _c("ul", { ref: "whyDocVerificationList" }, [
                  _vm._m(7),
                  _vm._v(" "),
                  _vm._m(8),
                  _vm._v(" "),
                  _vm._m(9),
                  _vm._v(" "),
                  _vm._m(10),
                ]),
                _vm._v(" "),
                _c(
                  "h4",
                  {
                    ref: "howDoIKnowTitle",
                    staticClass: "feature-list-section-title",
                  },
                  [
                    _vm._v(
                      "How do I know what tier I’m in and what’s the cost? "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("p", { ref: "howDoIKnowContent" }, [
                  _vm._v(
                    "\n          These enhancements are included in our Growth and Premium tiers at no additional cost, as detailed above. Contact your Account Manager at "
                  ),
                  _c(
                    "a",
                    {
                      ref: "contactAdLink",
                      attrs: { href: "mailto:ad@workbright.com" },
                    },
                    [_vm._v("ad@workbright.com")]
                  ),
                  _vm._v(
                    " to confirm your current tier and included features.\n        "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "h4",
                  {
                    ref: "getStartedTitle",
                    staticClass: "feature-list-section-title",
                  },
                  [_vm._v("How do I get started?")]
                ),
                _vm._v(" "),
                _c("p", { ref: "getStartedContent" }, [
                  _vm._v(
                    "\n          Fill out the form on this page to get these features activated. \n        "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "h4",
                  {
                    ref: "moreInfoTitle",
                    staticClass: "feature-list-section-title",
                  },
                  [_vm._v("Need more information?")]
                ),
                _vm._v(" "),
                _c("p", { ref: "moreInfoContent" }, [
                  _c(
                    "a",
                    {
                      ref: "knowledgeBaseLink",
                      attrs: {
                        href: "https://workbright.my.site.com/admins/s/article/Document-Verification",
                      },
                    },
                    [_vm._v("Check out the knowledge base")]
                  ),
                  _vm._v(
                    " to learn more about Document Verification features and workflows.\n        "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _vm._m(11),
            ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("strong", [
        _vm._v("Enhanced Camera with Optical Character Recognition:"),
      ]),
      _vm._v(
        "\n            Like depositing a check in a banking app, the upgraded I-9 photo experience guides employees to take well-lit, in-focus, and properly framed photos of their supporting documents. Document information is then automatically transcribed to reduce errors.\n          "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("strong", [_vm._v("Type Match:")]),
      _vm._v(
        " WorkBright ensures the document uploaded matches the type the employee selected. For example, if they choose a U.S. passport as their Type A document but upload a state ID, they’ll be prompted to update the type or upload a different photo.\n          "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("strong", [_vm._v("Image Quality Detection:")]),
      _vm._v(
        " Ensures work authorization document images are clear, legible, and fully in frame.\n          "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("strong", [_vm._v("Everything listed in the Growth tier above")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("strong", [_vm._v("Valid for Work Authorization Verification:")]),
      _vm._v(
        " Confirms that work authorization documents are unexpired and don’t contain restrictive language (e.g., Permanent Resident Card restrictions)."
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("strong", [_vm._v("Original Document Verification:")]),
      _vm._v(
        " Verifies that documents presented are original physical documents, not screenshots or replicas."
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("strong", [_vm._v("Document Fraud Detection:")]),
      _vm._v(
        " Uses metadata, color analysis, and modification detection to flag altered or potentially fraudulent documents."
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("strong", [_vm._v("Improved new hire experience:")]),
      _vm._v(
        " Onboarding staff get a guided camera experience and real-time confirmation of valid document submissions. Expanded OCR support also helps more employees have their documents auto-transcribed during Section 1."
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("strong", [_vm._v("More efficient administration:")]),
      _vm._v(
        " Document verification flags documents that don’t meet compliance standards, enabling admins to review issues—like validity or incorrect formatting—before final submission. This added review step prevents re-work by catching potential issues early, ensuring informed, fair hiring decisions."
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("strong", [_vm._v("Fraud prevention:")]),
      _vm._v(
        " Advanced screening flags irregularities or tampering, reducing the risk of onboarding unauthorized employees as employment scams increase."
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("strong", [_vm._v("Expert document validation:")]),
      _vm._v(
        " Continuously updated screening criteria serve as a permanent document expert, adapting to changes across hundreds of formats to keep your organization compliant."
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-5" }, [
      _c("div", { staticClass: "panel gray-bg" }, [
        _c("iframe", {
          attrs: {
            src: "https://workbrightsupport.com/document-verification-add-on-request/",
            width: "100%",
            height: "400",
            frameBorder: "0",
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }