import moment from 'moment'

// See https://momentjs.com/docs/#/customization/long-date-formats/
moment.updateLocale('en', {
  calendar : {
    sameDay  : 'LT',          // h:mm A
    sameWeek : 'ddd h:mm A',  // Mon 10:08 AM
    sameYear : 'LL',          // see below
    sameElse : 'L'            // MM/DD/YYYY
  },
  longDateFormat : {
    LL: 'MMM D',              // Apr 8
  }
})

// Switch the tokens we use for `moment.calendar()`. This should match the Ruby side InterfaceHelper#simpletime (except
// without some of the extra options). These keys are then interpolated against the locale, rendering the following:
//
//    Today:       "10:08 AM"
//    This week:   "Mon 10:08 AM"
//    This year:   "Apr 8"
//    Other years: "12/03/1985"
//
// Returns [String] - Key used by moment to look up format on moment config (`locale` -> `calendar`)
moment.calendarFormat = function(myMoment, now) {
  const daysDiff = myMoment.diff(now, 'days')

  const sameDay = daysDiff == 0
  const sameWeek = moment(myMoment).startOf('week').isSame(moment(now).startOf('week'))
  const sameYear = myMoment.year() == now.year()

  return sameDay ? 'sameDay' :
    sameWeek ? 'sameWeek' :
      sameYear ? 'sameYear' :
        'sameElse'
}

// momentjs exposes the original input as creationData().input; however, in the case of "" or null, it returns NaN
// instead of the actual original input. This utility method provides a better implementation that we can use in our
// integrations.
//
// Returns - original input String or null in the case of ""
moment.fn.getOriginalInput = function() {
  return this.creationData().input || null
}

// NOTE: This diverges a bit from the built-in `toISOString()` implementation which returns 'Invalid date' rather than
// the original input if parsing fails.
//
// Returns - ISO 8601 format without timestamp
moment.fn.toISODateString = function() {
  if (!this.isValid()) {
    return this.getOriginalInput()
  }

  return this.format('YYYY-MM-DD')
}

export default moment
