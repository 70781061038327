var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.display
    ? _c("div", { staticClass: "table-group-fields" }, [
        _c("table", { staticClass: "table table-bordered table-condensed" }, [
          _c(
            "tbody",
            [
              _c(
                "tr",
                [
                  _c("th", { attrs: { width: "30%" } }, [
                    _vm._v("\n          " + _vm._s(this.name) + "\n        "),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.actions, function (action, index) {
                    return _c(
                      "th",
                      { key: index, attrs: { width: _vm.actionsWidth } },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.actionName(action)) +
                            "\n        "
                        ),
                      ]
                    )
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _vm._l(_vm.permissionGroups, function (group) {
                return _c(
                  "tr",
                  { key: group.key },
                  [
                    _c("td", [
                      _vm._v(
                        "\n          " +
                          _vm._s(group.permissions[0].targetName(_vm.$store)) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.actions, function (action, index) {
                      return _c("grant-cell", {
                        key: index,
                        attrs: {
                          "target-id": group.target_id,
                          width: _vm.actionsWidth,
                          action: action,
                          permissions: group.permissions,
                        },
                      })
                    }),
                  ],
                  2
                )
              }),
            ],
            2
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }