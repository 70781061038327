export default Marionette.ItemView.extend({
  template: '#template-upload-view',
  onShow: function(){
    var form = this.$el.find('form')
    form.dzoperator({
      fileTypeErrorMsg: "Sorry, you can only upload CSV or Excel files (.csv, .xlsx) at this time.",
      dzOptions: {
        acceptedFiles: ".csv, .xlsx",
        presignURL: '/uploads/presign',
        previewTemplate: $("#dropzone-template").html()
      }
    })

    $.runInitializers(this.$el)
  }
})
