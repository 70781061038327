var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "WbButton",
        {
          ref: "adminRejectButton",
          attrs: { context: "danger" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.handleClick($event)
            },
          },
        },
        [_c("WbIcon", { attrs: { type: "ban" } }), _vm._v("\n  Reject\n")],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }