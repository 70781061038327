var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeader", {
        attrs: {
          title: "Usage Reports",
          lead: "Available to download for 30 days",
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "panel profile" },
        [
          _vm.isLoading
            ? _c("Spinner")
            : _vm.hasUsageReports
            ? _c("div", { ref: "usage-reports-content" }, [
                _c(
                  "ul",
                  { staticClass: "list-group" },
                  _vm._l(
                    _vm.downloadRequests,
                    function (downloadRequest, index) {
                      return _c(
                        "li",
                        { key: index, staticClass: "list-group-item" },
                        [
                          _c("DownloadRequest", {
                            attrs: { downloadRequest: downloadRequest },
                            on: { download: _vm.handleDownload },
                          }),
                        ],
                        1
                      )
                    }
                  ),
                  0
                ),
              ])
            : _c(
                "div",
                { ref: "default-content", staticClass: "text-center" },
                [
                  _c("em", [
                    _vm._v(
                      "You currently do not have any usage reports available."
                    ),
                  ]),
                ]
              ),
        ],
        1
      ),
      _vm._v(" "),
      _c("DownloadSlideoutPanel", {
        attrs: { downloadRequest: _vm.selectedDownloadRequest },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }