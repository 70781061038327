import { mutationTypes as baseCollectMutationTypes } from 'vuex/mixins/base_vuex_collection_module'
import { backboneFlatten } from 'lib/backbone_flatten'
import OnboardingStatusPanel from 'components/employees/OnboardingStatusPanel'
import I9SettingsPanel from 'components/employees/I9SettingsPanel'
import ActiveSeasonInfoPanelItemView from 'src/views/employees/employee_seasons/active_season_info_panel_item_view'
import UnemployedInfoPanelItemView from 'views/employees/employee_seasons/unemployed_info_panel_item_view'
import TenureInfoPanelItemView from 'views/employees/employee_seasons/tenure_info_panel_item_view'
import PayrollFieldsPanel from 'components/employees/payroll/PayrollFieldsPanel'
import Util from 'lib/util'
import { pureOrphanVue } from 'lib/vue/pureOrphanVue'

export default Marionette.LayoutView.extend({
  template: false,
  regions: {
    activeSeasonInfoPanel: "#active-season-info-panel",
    tenureInfoPanel: "#tenure-info-panel"
  },

  behaviors: {
    RailsRemoteFormCallbacks: {},
  },

  ui: {
    i9ToggleForm: 'form',
    i9RemoteCountersignToggleButton: "#employment_employee_document_options_i9_remote_countersign_authorized",
  },

  triggers: {
    'change @ui.i9RemoteCountersignToggleButton': 'changeDocumentOptionsToggle'
  },

  initialize: function() {

    // Vue transition: Marionette is expecting employee as a Backbone model, Vue is expecting an employee in Vuex.
    // Bind to change in Backbone and update Marionette
    const updateVuex = () => {
      window.vuexStore.commit(`employees/${baseCollectMutationTypes.SET_RECORD}`, backboneFlatten(this.getOption('employee')))
      window.vuexStore.commit(`employments/${baseCollectMutationTypes.SET_RECORD}`, backboneFlatten(gon.activeSeasonEmployment))
      window.vuexStore.commit('payroll_fields/setEmployeePayrollFields', this.getOption('payrollFields'))
    }
    this.listenTo(this.getOption('employee'), 'change', updateVuex)
    this.listenTo(gon.activeSeasonEmployment, 'change', updateVuex)
    updateVuex() // initialize
  },

  onRender: function() {
    let activeSeasonPanelView
    if (this.getOption('activeSeasonEmployment').id) {
      activeSeasonPanelView = new ActiveSeasonInfoPanelItemView({
        el: this.activeSeasonInfoPanel.el,
        model: this.getOption('employee'),
        employment: this.getOption('activeSeasonEmployment'),
      })
    } else {
      activeSeasonPanelView = new UnemployedInfoPanelItemView({
        el: this.activeSeasonInfoPanel.el,
        model: this.getOption('employee')
      })
    }
    activeSeasonPanelView.render()
    this.activeSeasonInfoPanel.attachView(activeSeasonPanelView)

    const tenureView = new TenureInfoPanelItemView({
      el: this.tenureInfoPanel.el,
      model: this.getOption('employee'),
    })
    tenureView.render()
    this.tenureInfoPanel.attachView(tenureView)

    const userEmployer = this.getOption('userEmployer')
    if(userEmployer) {
      pureOrphanVue(OnboardingStatusPanel, `component#vue-onboarding-status-panel`, {
        props: {
          employeeId: this.getOption('employee').get('id'),
          currentSignInAt: userEmployer.current_sign_in_at
        }
      })
    }

    // I-9 Settings Panel
    const employment = this.getOption('employee').get('employment')
    pureOrphanVue(I9SettingsPanel, 'component#vue-i9-settings-panel', {
      props: {
        everifyCase: this.getOption('everifyCase'),
        shouldShowRemoteReverification: window.gon.i9_remote_reverification_toggle_visible,
        shouldShowRemoteVerification: window.gon.is_i9rc_toggle_visible,
        employmentId: String(employment.id)
      }
    })

    // Payroll data
    if ($('#payroll-fields-panel').length) {
      pureOrphanVue(PayrollFieldsPanel, 'component#payroll-fields-panel', {
        props: {
          employeeId: this.getOption('employee').get('id'),
          adpIntegrationActive: this.getOption('adpIntegrationActive'),
          adpInternalFieldsMapped: this.getOption('adpInternalFieldsMapped'),
          adpStaffFieldsMapped: this.getOption('adpStaffFieldsMapped'),
          adpSyncStatus: this.getOption('adpSyncStatus'),
        }
      })
    }
  },

  onChangeDocumentOptionsToggle: function () {
    this.ui.i9ToggleForm.submit()
  },

  onRemoteFormSuccess: function(e, data, status, xhr) {
    if (!data) { return }

    Util.showAjaxFlashNotice(xhr)
  },
})
