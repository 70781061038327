import Locale from 'lib/locale'

export default Marionette.Behavior.extend({
  defaults: {
  },

  ui: {
    rehireWizardBtn: '.js-rehire-staff',
    rehireWizardBtnWrapper: '.js-rehire-staff-wrapper'
  },

  triggers: {
    'click @ui.rehireWizardBtn': 'rehireStaff:action',
  },

  initialize: function () {
    this.collectionView = this.view.options.collectionView
    this.listenTo(this.collectionView, 'rows:selection:changed', this.updateRehireButtonState)
  },

  onRender: function () {
    if (this.ui.rehireWizardBtn.length > 0) {
      this.updateRehireButtonState()

      // Prevent rehire if DocumentAssignments are processing in the background.
      if(gon.da_background_worker_in_progress) {
        this.ui.rehireWizardBtnWrapper.tooltip({
          'title': Locale.t('assignments.processing', 'Rehire Employees')
        })
      }
    }

    $.runInitializers(this.$el)
  },

  updateRehireButtonState: function() {
    this.ui.rehireWizardBtn.toggleClass('disabled', this._noneSelected() || gon.da_background_worker_in_progress)
  },

  onRehireStaffAction: function() {
    var ids = this.collectionView.getSelectionIDs()
    if (ids && ids.length > 0) {
      App.vent.trigger('employees:rehire', ids)
    } else {
      // Should not happen because the button is disabled when there are no selections, but just in case...
      bootbox.alert({
        title: "No Staff Selected",
        message: "In order to add to active staff, first check the box corresponding to each staff member you'd like to add.",
        className: "modal-info",
      })
    }
  },
  _noneSelected: function () {
    return this.collectionView.collection.length == 0 || this.collectionView.getSelectionCount() == 0
  }


})
