<template>
  <div class="container-lg">
    <PageHeader
      :title="$locale.t('reclaimEmail.title')"
      :lead="$locale.t('reclaimEmail.description')"
      variant="bordered"
      ref="pageHeader"
    />

    <div class="row breathe deep">
      <div class="col-sm-6 col-md-5">
        <FormFieldInput
          ref="emailAddress"
          label="Email Address"
          name="email"
          type="text"
          v-model="formData.email"
          :errors="errors.searchQuery"
          placeholder="employee@acme.com"
          @input="clearResults"
        />

        <div>
          <LaddaButton
            ref="submit"
            @click="submitQuery"
          >
            Search
          </LaddaButton>
          <button class="btn btn-default"
            @click="handleReset"
          >
            Reset
          </button>
        </div>
      </div>
    </div>

    <div class="list-group" v-if="user" ref="user">
      <div class="list-group-item shaded">
        <div class="d-flex align-items-center justify-content-between">
          <div class="pr-2">
            <h4 class="list-group-item-heading">
              {{ user.full_name }}
            </h4>
            <p class="list-group-item-text">
              {{ user.email }}
            </p>
            <div class="mt-1 small">
              Last sign in
              <WbSimpleDateTime
                :value="lastSignIn"
                :format="$constants.DATE_TIME_EXT_FORMAT"
                invalidMessage="--"
              />
            </div>
          </div>
          <div v-if="accounts.length" class="pr-2">
            <h4 class="list-group-item-heading">
              Accounts
            </h4>
            <li
              v-for="account in accounts"
              v-bind:key="account.id"
              class="list-group-item-text"
            >
              {{ account.name }}
              <abbr title="subdomain">
                {{ account.subdomain }}
              </abbr>
            </li>
          </div>
          <div v-else>
            <div class="text-muted">
              No accounts found
            </div>
          </div>
          <button
            class="btn btn-primary"
            :disabled="reclaimed"
            @click="handleReclaimEmail"
          >
            Reclaim
          </button>
        </div>
      </div>
    </div>

    <AlertMessage v-if="errorMessage"
      title="No changes made"
      :message="errorMessage"
      variant="warning"
      ref="reclaimEmailWarning"
    />
    <AlertMessage v-if="successMessage"
      title="Success!"
      :message="successMessage"
      variant="success"
      ref="reclaimEmailSuccess"
    />
  </div>
</template>

<script>
  import PageHeader from 'components/common/PageHeader'
  import FormFieldInput from 'components/common/FormFieldInput'
  import WbSimpleDateTime from 'components/common/WbSimpleDateTime'
  import LaddaButton from 'components/common/LaddaButton'
  import AlertMessage from 'components/common/AlertMessage'
  import Util from 'lib/util'

  export default {
    name: 'the-reclaim-user-email-root',

    components: {
      PageHeader,
      FormFieldInput,
      WbSimpleDateTime,
      LaddaButton,
      AlertMessage,
    },

    data() {
      return {
        formData: {
          user: null,
          success: null
        },
        reclaimed: false,
        errors: {},
        validationError: {}
      }
    },

    computed: {
      user() {
        return this.formData.user
      },

      accounts() {
        return this.user._embedded.accounts || []
      },

      lastSignIn() {
        return this.user.current_sign_in_at || ''
      },

      successMessage() {
        return this.formData.success
      },

      errorMessage() {
        return this.errors.email
      },

      searchUsersPath() {
        return this.$routes.superadmin_search_users_path
      },

      reclaimEmailPath() {
        return this.$routes.superadmin_reclaim_email_path
      },
    },

    methods: {
      submitQuery() {
        this.clearResults()
        if(!this.formData.email) {
          this.blankSearchQuery()
          return false
        }

        $.ajax({
          method: 'GET',
          url: this.searchUsersPath,
          data: {
            query: this.formData.email
          }
        }).success((responseData, _status, xhr) => {
          if(xhr.responseJSON && xhr.responseJSON.errors) {
            this.errors = xhr.responseJSON.errors || {}
          } else {
            this.formData.user = responseData
          }
        }).fail((xhr) => {
          Util.genericAjaxError("An error occurred.", xhr)
        }).always(() => this.$refs.submit && this.$refs.submit.stop())
      },

      handleReclaimEmail() {
        this.reclaimed = true // prevent button from being clicked multiple times

        $.ajax({
          method: 'PATCH',
          url: this.reclaimEmailPath,
          data: {
            query: this.formData.email
          }
        }).success((responseData, _status, xhr) => {
          if(xhr.responseJSON && xhr.responseJSON.errors) {
            this.errors = xhr.responseJSON.errors || {}
          } else {
            this.formData.success = responseData.success
          }
        }).fail((xhr) => {
          Util.genericAjaxError("An error occurred.", xhr)
        }).always(() => this.$refs.submit && this.$refs.submit.stop())
      },

      clearInput() {
        this.formData.email = null
      },

      clearResults() {
        this.errors = {}
        this.formData.success = null
        this.formData.user = null
        this.reclaimed = false
      },

      handleReset() {
        this.clearInput()
        this.clearResults()
      },

      blankSearchQuery() {
        this.errors.searchQuery = { email: ["Enter an email to search for"] }
        this.$refs.submit.stop()
      }
    }
  }
</script>
