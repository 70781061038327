var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "blocked-content" }, [
      _c("i", { staticClass: "fa fa-ban" }),
      _vm._v("\n  Restricted\n"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }