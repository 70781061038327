var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ul", { staticClass: "nav navbar-nav navbar-right" }, [
    _c("li", [
      _c(
        "button",
        { staticClass: "js-exit-kiosk-mode btn btn-link" },
        [
          _c("WbIcon", { attrs: { type: "sign-out" } }),
          _vm._v("\n      Exit Kiosk Mode\n    "),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }