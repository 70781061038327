var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-wait",
        { attrs: { for: "permissions_resources" } },
        [
          _c("spinner", { attrs: { slot: "waiting" }, slot: "waiting" }),
          _vm._v(" "),
          _c("h2", [_vm._v("Permissions for " + _vm._s(_vm.firstName))]),
          _vm._v(" "),
          _c("permissions-summary", {
            attrs: { "permission-sets": this.policy.permissionSets },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }