<template>
  <span>
    <WbIcon
      :type="icon"
      v-tooltip.bottom="message">
    </WbIcon>
    <span class="sr-only">{{ message }}</span>
  </span>
</template>

<script>
  import WbIcon from 'components/common/WbIcon'
  export default {
    name: 'tooltip-icon',

    components: {
      WbIcon
    },

    props: {
      icon: {
        required: true,
        type: String
      },
      message: {
        required: true,
        type: String
      }
    }

  }
</script>
