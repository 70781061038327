var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "slideout-panel",
    {
      attrs: { watchPageContextVariable: "show_staff_list_slideout" },
      on: { modalHidden: _vm.clearViewSelections },
    },
    [
      _vm.employee
        ? _c(
            "h3",
            {
              staticClass: "modal-title",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _vm._v("\n    " + _vm._s(_vm.employeeFullName) + "\n    "),
              _c("br"),
              _vm._v(" "),
              !_vm.emailBlocked
                ? _c("small", { staticClass: "email" }, [
                    _vm._v("\n      " + _vm._s(_vm.employee.email) + "\n    "),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.emailBlocked
                ? _c("small", {
                    domProps: { innerHTML: _vm._s(_vm.blockedContent) },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("small", [
                _c("a", { attrs: { href: _vm.employeePath } }, [
                  _vm._v("View Staff Profile"),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.finishedLoading
        ? _c(
            "div",
            [
              _c("p", { staticClass: "text-center" }, [
                _vm._v("Loading Employee Data."),
              ]),
              _vm._v(" "),
              _c("spinner", { attrs: { showLongWaitMessage: true } }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.finishedLoading
        ? _c(
            "div",
            [
              _c("radio-toggle", {
                attrs: {
                  title: _vm.filter.title,
                  value: _vm.currentSlideoutView,
                  options: _vm.filter.options,
                  buttonSize: "btn-sm",
                },
                on: { selected: _vm.handleSlideoutViewSelection },
              }),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _vm.displayGroups
                ? _c(
                    "div",
                    [
                      _c("staff-list-slideout-groups-container", {
                        attrs: { groups: _vm.groups },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.displayForms
                ? _c(
                    "div",
                    [
                      _vm.displayNonInternalForms
                        ? _c(
                            "staff-list-slideout-document-assignments-container",
                            {
                              attrs: {
                                title: "Staff Forms",
                                initialStatusView: _vm.initialFormsStatusView,
                                documentAssignments:
                                  _vm.nonInternalDocumentAssignments,
                              },
                            }
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayNonInternalForms && _vm.displayInternalForms
                        ? _c("hr")
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayInternalForms
                        ? _c(
                            "staff-list-slideout-document-assignments-container",
                            {
                              attrs: {
                                title: "Admin Forms",
                                initialStatusView: _vm.initialFormsStatusView,
                                documentAssignments:
                                  _vm.internalDocumentAssignments,
                              },
                            }
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }