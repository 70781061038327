<template>
  <td class="text-center" :class="cellClass" :width="width">
    <i class="fa" :class="iconClass" v-if="!grantNA" />
    <span v-if="grantNA">
      N/A
    </span>
  </td>
</template>

<script>
  export default {
    name: 'grant-cell',

    props: {
      action: {
        type: String,
        required: true
      },

      permissions: {
        type: Array,
        required: true
      },

      targetId: {
        required: true
      },


      width: {
        type: String,
        required: false
      }
    },

    computed: {
      permission() {
        return this.permissions.find((p) => p.action == this.action)
      },

      grant() {
        return this.permission ? this.permission.grant : false
      },

      grantNA() {
        // Special handling for I9 Countersigning
        // Make this more generic if other permissions can be N/A, or other Documents become countersignable
        return this.action == 'countersign_submission' && this.targetId != 'i9'
      },

      cellClass() {
        return this.grant ? 'success' : (this.grantNA ? 'warning' : 'danger')
      },

      iconClass() {
        return this.grant ? 'fa-check' : 'fa-times'
      },


    }
  }
</script>
