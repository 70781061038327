import PubSubUtil from 'lib/pub_sub_wrapped_action'
import Routes from 'lib/routes'
import { ajax } from 'jquery'

import * as BaseCollectionModule from 'vuex/mixins/base_vuex_collection_module'

function state() {
  return {
    collection: {}
  }
};

const getters = {
  ...BaseCollectionModule.getters
}

const mutationTypes = {
  ...BaseCollectionModule.mutationTypes
}

const mutations = {
  ...BaseCollectionModule.mutations
}

const actions = {
  ...BaseCollectionModule.actions,

  // Bulk Find
  // Fetch a set of employments (seasons) from the API, update collection on success
  //
  // data
  //   employeesIds [Array] - employee ids to fetch
  //
  // returns Promise
  //          resolve -> employments
  //          reject -> xhr
  bulkFind: ({ commit }, { employeeIds }) => {
    return new Promise((resolve, reject) => {
      ajax({
        method: 'POST',
        url: Routes.Api.bulk_find_employments_path,
        data: {
          employee_ids: employeeIds
        },
        // FIXME: Find a new method for sending down all this data.
        timeout: 60000
      }).then((employments) => {
        employments.forEach((employment) => { commit(mutationTypes.SET_RECORD, employment) })
        resolve(employments)
      }).fail((xhr) => {
        reject(xhr)
      })
    })
  },

  // Bulk Update
  // Posts to API, and receives response back on PubSub channel, as this is a long-running bulk operation
  //
  // data
  //   employeeIds [Array] - employees that need updated employment dates
  //   dateChanges [Object] - employment dates to update: { startDate: '2018-12-31', endDate: '2018-12-31', onboardingStartDate: '2018-12-31', hireDate: '2018-12-31' }
  bulkUpdate: ({ dispatch }, { employeeIds, dateChanges }) => {
    return PubSubUtil.pubSubWrappedAction(
      'employments_update',
      (channelName, reject) => {
        ajax({
          method: 'PATCH',
          url: Routes.Api.bulk_update_employments_path,
          data: {
            employee_ids: employeeIds,
            date_changes: dateChanges,
            pubsub_channel: channelName
          },
          wbGenericFailureMsg: 'Sorry, we could not update these employment dates.',
          progressBar: false,
        }).fail(xhr => reject(xhr))
      },
      (_response, resolve, reject) => {
        dispatch(
          'employments/bulkFind',
          { employeeIds: employeeIds },
          { root: true }
        )
          .then(() => resolve() )
          .catch((xhr) => reject(xhr) )
      }
    )
  },

  // Update an individual employments
  //
  // employment - [Object] employment
  // data - [Object] employment data
  //
  // returns Promise
  update: ({ commit }, { employment, data }) => {
    return new Promise((resolve, reject) => {
      ajax({
        url: Routes.Api.employee_employment_path({ employee_id: employment.employee_id, id: employment.id }),
        method: 'PATCH',
        data: { employment: data }
      }).then((updatedEmployment) => {
        commit(mutationTypes.SET_RECORD, updatedEmployment)
        resolve(updatedEmployment)
      }).fail((xhr) => reject(xhr))
    })
  }
}


export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
