var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "well" },
    [
      _c("h4", [_vm._v("Multiple Jobs Worksheet")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "This will calculate the value to enter in Step 4(c), Extra withholding."
        ),
      ]),
      _vm._v(" "),
      _c("FormFieldCurrency", {
        attrs: {
          orientation: "horizontal",
          required: false,
          label: "Job 1 annual income",
          errors: _vm.errors,
          name: "mjw_job_1",
          min: "0",
        },
        model: {
          value: _vm.formData.job1,
          callback: function ($$v) {
            _vm.$set(_vm.formData, "job1", $$v)
          },
          expression: "formData.job1",
        },
      }),
      _vm._v(" "),
      _c("FormFieldCurrency", {
        attrs: {
          orientation: "horizontal",
          required: false,
          label: "Job 2 annual income",
          errors: _vm.errors,
          name: "mjw_job_2",
          min: "0",
        },
        model: {
          value: _vm.formData.job2,
          callback: function ($$v) {
            _vm.$set(_vm.formData, "job2", $$v)
          },
          expression: "formData.job2",
        },
      }),
      _vm._v(" "),
      _vm.jobs === 3
        ? _c("FormFieldCurrency", {
            attrs: {
              orientation: "horizontal",
              required: false,
              label: "Job 3 annual income",
              errors: _vm.errors,
              name: "mjw_job_3",
              min: "0",
            },
            model: {
              value: _vm.formData.job3,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "job3", $$v)
              },
              expression: "formData.job3",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("FormFieldRadio", {
        attrs: {
          label: "For the highest paying job, how often do you get paid?",
          required: false,
          options: _vm.payFrequencyOptions,
          errors: _vm.errors,
          name: "pay_frequency",
          orientation: "horizontal",
        },
        model: {
          value: _vm.formData.payFrequency,
          callback: function ($$v) {
            _vm.$set(_vm.formData, "payFrequency", $$v)
          },
          expression: "formData.payFrequency",
        },
      }),
      _vm._v(" "),
      _vm.isCustomPay
        ? _c("FormFieldNumber", {
            ref: "customPayFrequency",
            attrs: {
              orientation: "horizontal",
              required: false,
              label: "How many times a year are you paid?",
              errors: _vm.errors,
              name: "custom_pay_frequency",
              min: "0",
            },
            model: {
              value: _vm.formData.customPayFrequency,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "customPayFrequency", $$v)
              },
              expression: "formData.customPayFrequency",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }