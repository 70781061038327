<template>
  <li>
    <a :href="item.href" :target="target">
      <slot name="icon" />
      {{ item.label }}
    </a>
  </li>
</template>
<script>
  export default {
    props: {
      item: {
        type: Object,
        required: true
      },
      target: {
        type: String,
        validator: value => {
          return ['_blank', '_self', '_parent', '_top'].includes(value)
        }
      }
    }
  }
</script>
