var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.unauthenticatedAccounts, function (account, index) {
        return _c(
          "div",
          { key: index },
          [
            _c("AlertMessage", {
              ref: "authFailure",
              refInFor: true,
              attrs: {
                title: "Authentication Failure",
                message: _vm.$locale.t(
                  "everify_account.authFailure",
                  _vm.companyName(account),
                  _vm.helpDeskLink
                ),
              },
            }),
          ],
          1
        )
      }),
      _vm._v(" "),
      _c("h2", { staticClass: "page" }, [_vm._v("E-Verify Cases")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n    WorkBright periodically refreshes E-Verify case data but the latest changes may not be displayed. If you've made recent changes on the E-Verify site and don't see them here, please refresh to update. Only cases created with WorkBright will be displayed here.\n  "
        ),
      ]),
      _vm._v(" "),
      _vm.pendingReferralSelected
        ? _c("AlertMessage", {
            ref: "adverse-action-alert",
            attrs: { variant: "warning", message: _vm.adverseActionAlert },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }