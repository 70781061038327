<template>
  <div>
    <h4 v-if="!documentAssignments.length"> No {{title}}</h4>
    <div v-else>
      <div v-for="(status, index) in documentAssignmentsStatuses" v-bind:key="index">
        <h4 class="mt-4"> {{statusHeaderTitle(status)}} {{title}}</h4>
        <div class="list-group">
          <div
            class="list-group-item"
            v-for="assignment in filteredDocumentAssignments(status)"
            :key="assignment.id"
          >
            <a
              v-if="status !== 'missing' && checkAssignmentLink(assignment)"
              :href="getAssignmentRoute(assignment)"
            >
              {{assignment.document_name}}
            </a>
            <span v-else>
              {{assignment.document_name}}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  import StringUtil from 'lib/util/string'

  export default {
    name: 'staff-list-slideout-document-assignments-container',

    props: {
      title: {
        type: String,
        required: true
      },

      documentAssignments: {
        type: Array,
        required: true
      },

      initialStatusView: {
        type: String,
        default: "all"
      }
    },

    data() {
      return {
        selectedStatusView: null,
      }
    },

    computed: {
      currentStatusView(){
        return this.selectedStatusView || this.initialStatusView
      },

      uniqueDocumentAssignmentsStatuses(){
        return Array.from(new Set(this.documentAssignments.map(da => da.status)))
      },

      documentAssignmentsStatuses(){
        if (this.currentStatusView == "all")
          return this.uniqueDocumentAssignmentsStatuses
        else {
          if (this.filteredDocumentAssignments(this.currentStatusView).length > 0)
            return [this.currentStatusView]
          // otherwise
          this.selectedStatusView = "all"
          return this.documentAssignmentsStatuses
        }

      },

      showSlideoutNav(){
        return this.uniqueDocumentAssignmentsStatuses.length > 1
      },

      navLinks(){
        const existingStatusNavLinks = this.uniqueDocumentAssignmentsStatuses.map(s => { return {value: s, label: StringUtil.titleize(s)} })
        return [{ value: "all", label: "All" }, ...existingStatusNavLinks]
      }
    },

    methods: {
      statusHeaderTitle(status){
        return StringUtil.titleize(status.split("_").join(" "))
      },

      filteredDocumentAssignments(status){
        return this.documentAssignments.filter(da => da.status == status)
      },

      handleStatusViewSelection({selection}){
        this.selectedStatusView = selection
      },

      checkAssignmentLink (assignment) {
        return !!(assignment && assignment.current_submission_id && assignment.employee_id)
      },

      getAssignmentRoute(assignment) {
        return this.$routes.admin_employee_submission_path({
          employee_id: assignment.employee_id,
          id: assignment.current_submission_id
        })
      }
    }
  }
</script>
