import UserImportField from 'models/user_import/user_import_fields'

const CollectionType = Backbone.Collection.extend({
    url: '/api/_private/fields/importable',
    model: UserImportField,

    // List of required Fields that have not yet been matched to a spreadsheet column header
    //
    // Returns UserImportFieldCollection
    unmatchedRequired: function(){
      var filtered = this.filter(function (column) {
          return !column.isMatched() && column.isRequired()
      })
      return new CollectionType(filtered)
    },

    // List of required Fields (i.e. Workbright fields that must be present for an Employee to exist)
    //
    // Returns UserImportFieldCollection
    required: function(){
      var filtered = this.filter(function (column) {
          return column.isRequired()
      })
      return new CollectionType(filtered)
    },

    // Sorts list of Fields alphabetically, with required columns first
    //
    // Email      (required)
    // First Name (required)
    // Last Name  (required)
    // Address    (optional)
    // Phone      (optional)
    // Zip        (optional)
    comparator: function(item) {
      var compStr = item.get('label')
      if(item.isRequired()){
        compStr = '!' + compStr
      }
      return compStr
    }
})
export default CollectionType
