import NewSupplementalFileModalView from 'src/views/supplemental_files/new_item_view'
import ListTableCollectionView from 'src/views/supplemental_files/table_collection_view'
import SupplementalFile from 'models/supplemental_file'
import DateTime from 'lib/util/datetime'
import Animations from 'lib/util/animations'

export default Marionette.LayoutView.extend({
  template: '#supplemental-files-tmpl',
  ui: {
    table: '.supplemental-files-table',
    uploader: 'form',
    newSupplementalFile: '.js-new-supp-files',
  },

  triggers: {
    'click @ui.newSupplementalFile': 'click:new',
  },

  initialize: function() {
    this.modalView = new NewSupplementalFileModalView({
      employee_id: this.getOption('employee_id'),
      addedCallback: this.fileAdded.bind(this)
    })
  },

  fileAdded: function(data){
    const newFileRow = this.ui.table.DataTable().row.add({
      name:             data.name,
      file_path:        data.file_path,
      file_extension:   data.file_extension,
      uploaded_at:      data.uploaded_at,
      uploaded_by:      _.escape(data.uploaded_by)
    }).draw(false).node()

    // Add to collection so it can be managed by Marionette
    const file = new SupplementalFile(data)
    App.Employees.tableCollectionView.collection.add(file)
    const rowView = App.Employees.tableCollectionView.children.findByModel(file)
    rowView.setElement(newFileRow)

    this.modalView.$el.find('.modal').modal('hide')
    Animations.temporaryHighlight($(newFileRow))
  },

  onRender: function() {
    this.$tableEl = this.ui.table
    this.initDataTable()

    App.Employees.tableCollectionView = new ListTableCollectionView({})
    App.Employees.tableCollectionView.attachToTableWithCollection(this.$tableEl, this.collection)

    this.addRegion("toolbarRegion", ".dt-toolbar")
    const toolbarView = new Marionette.ItemView({ template: "#supplemental-files-toolbar-tmpl" })
    this.toolbarRegion.show(toolbarView)
    toolbarView.prerendered = true
  },

  onDestroy: function () {
    // Destroy the modal view as well
    this.modalView.destroy()
  },

  onBeforeDestroy: function () {
    Marionette.TemplateCache.clear("#supplemental-files-toolbar-tmpl")

    if (App.Employees.tableCollectionView) {
      App.Employees.tableCollectionView.destroy()
      delete App.Employees.tableCollectionView
    }
  },

  initDataTable: function () {
    this.$tableEl.DataTable({
      retrieve: true,
      destroy: true,
      data: this.collection.map( f => {
        return {
          name:             f.get('name'),
          file_extension:   f.get('file_extension'),
          file_path:        f.get('file_path'),
          uploaded_at:      f.get('uploaded_at'),
          uploaded_by:      _.escape(f.get('uploaded_by')),
          employee_id:      f.get('employee_id'),
        }}
      ),
      columns: [
        { title: 'File Name', data: 'name', render: (data, type, row) => {
          _.extend(row, {name: data})
          return this._showLinkHTML(row)
        }
        },
        { title: 'File Type', data: 'file_extension', render: (data, type, row) => {
          return data.toUpperCase()
        }
        },
        { title: 'Upload Date', data: 'uploaded_at', type: 'date', render: function(data) {
          return DateTime.formatDateTime(data)
        }
        },
        { title: 'Uploaded By', data: 'uploaded_by' },
        { title: '', orderable: false, data: 'actions', render: function () {
          return $('#action-links-tmpl').html()
        }
        },
      ],
      language: {
        emptyTable: '<div class="text-center"><h4>No supplemental files to show.</h4>Click here<a href="javascript:void(0)" class="js-new-supp-files"> to upload a file.</a></div>',
      },
    })
  },

  onClickNew: function () {
    this.modalView.render()
  },

  _showLinkHTML: function (data) {
    const link = $('<a/>').attr('href', 'javascript:void(0)')
    link.addClass('js-view')
    link.text(data.name)

    return link[0].outerHTML
  }
})
