<template>
  <Panel
    class="photo-matching-panel"
    variant="snapshot"
  >
    <template v-slot:heading>
      <div class="panel-title">
        <h3>Action Required</h3>
        <h4>Photo Match</h4>
      </div>
    </template>
    <template v-slot:body>
      <div class="my-3">
        <p>
          Do all the photos on file with WorkBright match the E-Verify photos? Please disregard any shading or lighting differences.
        </p>
        <WbButton
          ref="affirmPhotoMatchButton"
          context="success"
          label="Yes, the photos match"
          :isLoading="isButtonLoading('matching')"
          :disabled="isLoading"
          @click="submit('matching')"
        />
        <WbButton
          ref="rejectPhotoMatchButton"
          context="danger"
          label="No, the photos do not match"
          :isLoading="isButtonLoading('not-matching')"
          :disabled="isLoading"
          @click="submit('not-matching')"
        />
        <WbButton
          ref="noImageButton"
          context="default"
          label="Photo not present"
          :isLoading="isButtonLoading('no-image')"
          :disabled="isLoading"
          @click="submit('no-image')"
        />
      </div>
      <EverifyCaseDocumentPreview :caseId="caseId" />
      <EverifyCaseCloseForm
        :everifyCase="everifyCase"
        @closeTimeout="closeModal"
      />
    </template>
  </Panel>
</template>

<script>
  import { mapActions } from 'vuex'
  import EverifyCaseDocumentPreview from 'components/everify/cases/EverifyCaseDocumentPreview'
  import EverifyCaseCloseForm from 'components/everify/cases/EverifyCaseCloseForm'
  import FormFieldRadio from 'components/common/FormFieldRadio'
  import Panel from 'components/common/Panel'
  import Util from 'lib/util'
  import WbButton from 'components/common/WbButton'

  export default {
    name: 'photo-matching-panel',
    components: {
      EverifyCaseCloseForm,
      EverifyCaseDocumentPreview,
      FormFieldRadio,
      Panel,
      WbButton,
    },

    props: {
      everifyCase: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        isLoading: false,
        status: null
      }
    },

    computed: {
      caseId () {
        return this.everifyCase.id
      },
      submissionId () {
        return this.everifyCase.submission_id
      }
    },

    beforeDestroy() {
      this.isLoading = false
    },

    methods: {
      isButtonLoading (status) {
        return this.isLoading && this.status === status
      },

      submit(status) {
        this.isLoading = true
        this.status = status

        this.photoConfirmation({
          caseId: this.caseId,
          confirmation: status
        }).then(() => {
          this.closeModal()
          Util.showFlashNotice("Request is processing, case will automatically update when the request has finished.")
        }).catch((xhr) => {
          Util.ajaxErrorDialog("An error occurred while sending a response to E-Verify.", xhr)
        }).finally(() => {
          this.isLoading = false
        })
      },

      closeModal() {
        this.$emit('close')
      },

      ...mapActions({
        photoConfirmation: 'everify_cases/photoConfirmation'
      })
    },
  }
</script>
