export default class BaseSystemEvent {
  get key() {
    throw new Error('Not Implemented')
  }

  payload() {
    return {
      event_key: this.key
    }
  }
}
