export default {

  // Creates and returns (String) representing a URL with correctly encoded params
  // Arguments:
  //   - basePath (String) the base route that will be prefixed to the final string
  //   - params (Object) representing the URL params that will be appended to the end
  urlWithParams: (basePath, params = {}) => {
    
    // jQuery#param already uses `encodeURIComponent` to encode the param so we don't have to
    // but let's decode just to be safe, otherwise we get the following, which doesn't always work
    // i.e "case_status%5B%5D=unsubmitted_receipt&page=1"
    return `${basePath}?${decodeURIComponent($.param(params))}`
  }
}
