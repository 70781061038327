var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "wb-modal",
        {
          ref: "modal",
          attrs: {
            watchPageContextVariable: "update_groups_employees_id",
            title: _vm.isAdding
              ? "Add Employees to Group"
              : "Remove Employees from Group",
            locked: _vm.$wait.is("employees_update_groups"),
          },
          on: { modalHidden: _vm.handleModalHidden },
        },
        [
          _c(
            "v-wait",
            { attrs: { for: "employees_update_groups" } },
            [
              _c("spinner", {
                attrs: { slot: "waiting", showLongWaitMessage: true },
                slot: "waiting",
              }),
              _vm._v(" "),
              _vm.groupOptions.length > 0
                ? _c("div", [
                    _c("p", { ref: "notification" }, [
                      _vm._v(
                        _vm._s(_vm.pluralizedEmployees) +
                          " will be " +
                          _vm._s(_vm.isAdding ? "added to" : "removed from") +
                          " the group."
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "input-wrapper long-field" },
                      [
                        _c("WbMultiselect", {
                          attrs: {
                            selectOptions: _vm.groupOptions,
                            enableSearchField: true,
                            multiple: false,
                            placeholder: "Select a group",
                          },
                          model: {
                            value: _vm.groupId,
                            callback: function ($$v) {
                              _vm.groupId = $$v
                            },
                            expression: "groupId",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _c("div", { staticClass: "alert alert-danger" }, [
                    _vm._v(
                      "\n        No Employee Groups have been set up yet.\n      "
                    ),
                  ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  attrs: { disabled: _vm.$wait.is("employees_update_groups") },
                  on: { click: _vm.hide },
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "ladda-button",
                {
                  ref: "submitButton",
                  attrs: { disabled: !_vm.group },
                  on: { click: _vm.submit },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.isAdding ? "Add to group" : "Remove from group"
                      ) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("bulk-action-list-alert", {
        ref: "alert",
        attrs: {
          actionMethod: function (employee) {
            return true
          },
          permissionName: "manage",
          permissionModule: "employee_group_memberships",
          collectionModule: "employees",
          actionErrorMessage: _vm.changeGroupMessage,
          displayNameLookupFn: function (employee) {
            return employee.first_name + " " + employee.last_name
          },
          collectionViewName: this.collectionViewName,
        },
        on: {
          continue: _vm.openModal,
          cancelled: function ($event) {
            return _vm.$emit("hidden")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }