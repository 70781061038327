export default Marionette.ItemView.extend({
  template: '#template-employee-view',
  tagName: 'tr',

  // Build the table row after render
  onRender: function(){
    this.$el.html(this.buildRow())
  },

  // Build the table row
  //
  // Returns HTML for row
  buildRow: function(){
    var dataStr = ''

    // Add cell for row number
    dataStr += this.buildRowNumberCell()

    // Add cell for error count
    if(this.errorCount())
      dataStr += this.buildCellError()

    // Add cells for each data field
    _.each(this.model.get('fields'), function(f){
      dataStr += this.buildCellData(f)
    }.bind(this))
    return dataStr
  },

  // Build cell for a employee field
  //
  // field: Employee data field object
  //
  // Returns HTML for cell
  buildCellData: function(field){
    var classes = []
    var hasErrors = field.errors && field.errors.length > 0

    // Make this cell red
    if(hasErrors){
      classes.push('validation-error')
    }

    // Cell content - if there are errors, add a popover
    var content = field.normalized_value || "" // return empty string if null
    var popoverMessage = null
    if(hasErrors){
      content += this.errorIcon()
      popoverMessage = field.errors.join(' ')
    } else if(field.normalized){
      content = content + this.normalizeIcon()
      popoverMessage = this.normalizedMessage(field)
    }
    return this.buildCellGeneric(content, classes, popoverMessage)
  },

  // Build cell for employee spreadsheet row number
  //
  // Returns HTML for cell
  buildRowNumberCell: function(){
    return this.buildCellGeneric(this.model.get('row_idx'))
  },

  // Build cell for the number of errors
  //
  // Returns HTML for cell
  buildCellError: function(){
    var content = '<span class=\'error-count\'>' + this.errorCount() + '</span>'
    return this.buildCellGeneric(content, ['text-center'], 'Total number of errors in this row')
  },

  // Build a generic cell
  //
  // content: HTML string content for cell
  // classes: Array of classes to apply to cell
  //
  // Returns HTML string for cell
  buildCellGeneric: function(content, classes, popoverMessage){
    var cellStr = '<td'
    if(classes && classes.length > 0){
      cellStr += ' class=\'' + classes.join(' ') + '\''
    }
    if(popoverMessage){
      cellStr += ' ' + this.popoverStr(popoverMessage)
    }
    cellStr += '>'
    cellStr += content
    cellStr += '</td>'

    return cellStr
  },

  // Total count of fields with errors for this employee
  //
  // Returns integer
  errorCount: function(){
    return _.reduce(this.model.get('fields'), function(total, field){
      var fieldErrors = field.errors ? field.errors.length : 0
      return total + fieldErrors
    }, 0)
  },

  // Icon HTML with to indicate that the field has been normalized
  //
  // Returns HTML
  normalizeIcon: function(){
    return '<i class="fa fa-magic text-info"></i>'
  },

  // Icon HTML with to indicate that the field has an error
  //
  // Returns HTML
  errorIcon: function(){
    return '<i class="fa fa-exclamation-triangle text-danger"></i>'
  },

  // Icon HTML with to indicate that the field has been normalized
  //
  // field: field data object with raw_value
  //
  // Returns HTML
  normalizedMessage: function(field){
    var message = 'We converted the data in this cell. Double-check that the converted value matches what you intended.<br/>'
    var originalValue = field.raw_value
    if (!originalValue) {
      originalValue = '(blank)'
    }

    message += '<strong>Original:</strong> ' + originalValue
    return message
  },

  // Build popover attributes
  //
  // content: Message to display in popover
  //
  // Returns HTML string of attributes
  popoverStr: function(content){
    var str = 'data-container=\'body\' data-toggle=\'popover\' data-trigger=\'hover\' data-placement=\'top\' data-html=\'true\' data-content=\''
    str += content
    str += '\''
    return str
  }
})
