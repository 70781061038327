var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-lg" },
    [
      _c("PageHeader", {
        ref: "pageHeader",
        attrs: {
          title: _vm.$locale.t("reclaimEmail.title"),
          lead: _vm.$locale.t("reclaimEmail.description"),
          variant: "bordered",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "row breathe deep" }, [
        _c(
          "div",
          { staticClass: "col-sm-6 col-md-5" },
          [
            _c("FormFieldInput", {
              ref: "emailAddress",
              attrs: {
                label: "Email Address",
                name: "email",
                type: "text",
                errors: _vm.errors.searchQuery,
                placeholder: "employee@acme.com",
              },
              on: { input: _vm.clearResults },
              model: {
                value: _vm.formData.email,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "email", $$v)
                },
                expression: "formData.email",
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              [
                _c(
                  "LaddaButton",
                  { ref: "submit", on: { click: _vm.submitQuery } },
                  [_vm._v("\n          Search\n        ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    on: { click: _vm.handleReset },
                  },
                  [_vm._v("\n          Reset\n        ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.user
        ? _c("div", { ref: "user", staticClass: "list-group" }, [
            _c("div", { staticClass: "list-group-item shaded" }, [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-between",
                },
                [
                  _c("div", { staticClass: "pr-2" }, [
                    _c("h4", { staticClass: "list-group-item-heading" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.user.full_name) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "list-group-item-text" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.user.email) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "mt-1 small" },
                      [
                        _vm._v("\n            Last sign in\n            "),
                        _c("WbSimpleDateTime", {
                          attrs: {
                            value: _vm.lastSignIn,
                            format: _vm.$constants.DATE_TIME_EXT_FORMAT,
                            invalidMessage: "--",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.accounts.length
                    ? _c(
                        "div",
                        { staticClass: "pr-2" },
                        [
                          _c("h4", { staticClass: "list-group-item-heading" }, [
                            _vm._v("\n            Accounts\n          "),
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.accounts, function (account) {
                            return _c(
                              "li",
                              {
                                key: account.id,
                                staticClass: "list-group-item-text",
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(account.name) +
                                    "\n            "
                                ),
                                _c("abbr", { attrs: { title: "subdomain" } }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(account.subdomain) +
                                      "\n            "
                                  ),
                                ]),
                              ]
                            )
                          }),
                        ],
                        2
                      )
                    : _c("div", [
                        _c("div", { staticClass: "text-muted" }, [
                          _vm._v("\n            No accounts found\n          "),
                        ]),
                      ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { disabled: _vm.reclaimed },
                      on: { click: _vm.handleReclaimEmail },
                    },
                    [_vm._v("\n          Reclaim\n        ")]
                  ),
                ]
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.errorMessage
        ? _c("AlertMessage", {
            ref: "reclaimEmailWarning",
            attrs: {
              title: "No changes made",
              message: _vm.errorMessage,
              variant: "warning",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.successMessage
        ? _c("AlertMessage", {
            ref: "reclaimEmailSuccess",
            attrs: {
              title: "Success!",
              message: _vm.successMessage,
              variant: "success",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }