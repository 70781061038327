<template>
  <div class="i9-documentation">
    <AlertMessage
      v-if="verificationFallbackMode"
      variant="warning"
      ref="verificationFallbackAlert"
    >
      <p>
        We were unable to verify your document but this does not mean it is not a valid document for work authorization. If you believe you have selected the wrong type of document, please use the previous button to select a new document type and attempt verification again. OR if this is a receipt for a lost, stolen, or damaged document, please indicate that here and complete the manual entry of information.
      </p>
      <p>
        If you have selected the right type and you believe the current document you are submitting to be a valid document to prove your identity and/or work authorization, you can proceed with completing your Form I-9 by manually entering the information on this page. Your employer will review the documentation you are providing and follow up if any further action is needed.
      </p>
    </AlertMessage>
    <h4>{{ attachmentName }}</h4>
    <p
      v-if="instructions"
      class="mt-3"
      ref="instructions"
      v-html="instructions"
    />
    <div class="row">
      <div class="col-md-6" v-if="!verificationFallbackMode">
        <template v-if="hasAttachments">
          <ImageUpload
            v-if="hasFront"
            label="Document Front"
            required
            :accountTakePhotoOnDesktopEnabled="currentAccount.takePhotoOnDesktopEnabled"
            :error="errorMessage('file_front')"
            :existingFileUrl="formData.file_front_url"
            :help="frontFileHint"
            :uploadServiceClass="uploadServiceClass"
            ref="uploadFront"
            @success="handleUploadSuccess('front', $event)"
            @remove="handleUploadRemove('front')"
          />
          <ImageUpload
            v-if="hasBack"
            label="Document Back"
            required
            :accountTakePhotoOnDesktopEnabled="currentAccount.takePhotoOnDesktopEnabled"
            :error="errorMessage('file_back')"
            :existingFileUrl="formData.file_back_url"
            :help="backFileHint"
            :uploadServiceClass="uploadServiceClass"
            ref="uploadBack"
            @success="handleUploadSuccess('back', $event)"
            @remove="handleUploadRemove('back')"
          />
        </template>
        <AlertMessage
          v-else
          variant="info"
          ref="noAttachments"
        >
          No file upload is required for this document option.
        </AlertMessage>
      </div>
      <div class="col-md-6">
        <FormFieldSelect
          v-if="hasMultipleDocumentTitles"
          fieldWidth=""
          label="Document Title"
          name="document_title"
          :options="documentTitleOptions"
          v-model="documentTitleIndex"
          :errors="errors"
          ref="docTitleSelect"
          required
        />
        <FormFieldInput
          v-else
          label="Document Title"
          name="document_title"
          ref="docTitle"
          :disabled="shouldDisableDocumentTitle"
          :errors="errors"
          required
          v-model="formData.document_title"
        />
        <FormFieldCheckbox
          v-if="!isOtherAcceptableReceipt"
          class="row"
          gridColumns="col-xs-12"
          label="My document was lost, stolen, or damaged and this is a receipt for a replacement."
          name="receipt"
          ref="receipt"
          v-model="formData.receipt"
          :errors="errors"
          :helpPopoverText="receiptPopoverText"
        />
        <FormFieldSelect
          v-if="hasCountriesAsIssuingAuthority || hasMultipleIssuingAuthorities"
          fieldWidth=""
          :hint="issuingAuthorityHint"
          label="Issuing Authority"
          name="issuing_authority"
          :options="issuingAuthorityOptions"
          v-model="formData.issuing_authority"
          :errors="errors"
          ref="issuingAuthorityCountrySelect"
          required
        />
        <FormFieldInput
          v-else
          :hint="issuingAuthorityHint"
          label="Issuing Authority"
          name="issuing_authority"
          ref="issuingAuthorityInput"
          v-model="formData.issuing_authority"
          :disabled="shouldDisableIssuingAuthority"
          :errors="errors"
          required
          :value="issuingAuthority"
        />
        <div
          v-if="shouldShowOcrFields"
          class="remote-countersign-ocr-fields"
          ref="ocrFields"
        >
          <FormFieldInput
            :errors="errors"
            :hint="documentNumberHint"
            label="Document Number"
            name="document_number"
            ref="docNumber"
            v-model="formData.document_number"
            required
            :mask="documentNumberMask"
          />
          <FormFieldDate
            :errors="errors"
            :hint="expirationHelpText"
            label="Expiration Date"
            :minDate="expirationMinDate"
            name="expiration_date"
            v-model="formData.expiration_date"
            required
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  const EDITABLE_DOC_TITLE_KEYS = [
    'employment_auth_by_dhs',
    'other_acceptable_receipt',
  ]
  const EXP_HELP_TEXT_RECEIPT = 'Enter the date the receipt expires. If the receipt does not show an expiration date, enter 90 days from the staff member\'s Start Date.'
  const EXP_HELP_TEXT = 'This document must be UNEXPIRED unless extended by USCIS. Enter N/A if the documentation does not have an expiration date.'
  const RECEIPT_PREFIX = 'Receipt - '

  import AlertMessage from 'components/common/AlertMessage'
  import FormFieldCheckbox from 'components/common/FormFieldCheckbox'
  import FormFieldInput from 'components/common/FormFieldInput'
  import FormFieldSelect from 'components/common/FormFieldSelect'
  import FormFieldDate from 'components/common/FormFieldDate'
  import ImageUpload from 'components/forms/ImageUpload'
  import countries from 'data/countries'

  export default {
    components: {
      AlertMessage,
      FormFieldCheckbox,
      FormFieldDate,
      FormFieldInput,
      FormFieldSelect,
      ImageUpload,
    },
    props: {
      document: {
        type: Object,
      },
      attachmentAttributes: {
        type: Object,
      },
      errors: {
        type: Object,
        default: () => ({}),
      },
      currentAccount: {
        type: Object,
        default: () => ({}),
      },
      uploadServiceClass: {
        type: Function,
      },
      verificationFallbackMode: {
        type: Boolean,
        default: false,
      },
    },
    emits: [
      'update',
    ],
    data () {
      return {
        documentTitleIndex: 0,
        formData: {
          document_title: null,
          receipt: null,
        },
        resetBackFile: false,
      }
    },
    computed: {
      attachmentName () {
        return this.document.view_title ||
          this.document.list_title ||
          this.document.document_title
      },
      backFileHint () {
        return this.document.hints?.file_back
      },
      canOcrDocument () {
        return this.document.validation_enabled === true &&
          this.currentAccount.remoteCountersignOn &&
          !this.attachmentAttributes.skip_document_verify
      },
      countries () {
        return Object.values(countries).map(country => ({
          label: country.name,
          value: country.code,
        }))
      },
      documentNumberHasErrors () {
        return !!this.errors.document_number
      },
      documentNumberHint () {
        return this.document.hints?.document_number
      },
      documentTitle () {
        return this.getDocumentTitle(
          this.formData.document_title,
          this.document.document_title,
          this.isReceipt,
        )
      },
      documentTitleOptions () {
        return this.document.document_title.map((option, index) => ({
          label: this.isReceipt ? `${RECEIPT_PREFIX} ${option}` : option,
          value: index,
        }))
      },
      expirationMinDate () {
        return new Date(new Date().setFullYear(new Date().getFullYear() - 10))
      },
      expirationHasErrors () {
        return !!this.errors.expiration_date
      },
      expirationHelpText () {
        return this.isReceipt ?
          EXP_HELP_TEXT_RECEIPT :
          EXP_HELP_TEXT
      },
      frontFileHint () {
        return this.document.hints?.file_front
      },
      hasCountriesAsIssuingAuthority () {
        return this.document.countries_as_issuing_authority === true
      },
      hasMultipleDocumentTitles () {
        return Array.isArray(this.document.document_title)
      },
      hasMultipleIssuingAuthorities () {
        return Array.isArray(this.issuingAuthority)
      },
      hasAttachments () {
        return this.hasFront || this.hasBack
      },
      hasFront () {
        return this.document.front_file !== false
      },
      hasBack () {
        return this.document.back_file !== false
      },
      isReceipt () {
        return !!this.formData.receipt
      },
      instructions () {
        return this.document.instructions
      },
      issuingAuthority () {
        return this.document.issuing_authority
      },
      issuingAuthorityHint () {
        return this.document.hints?.issuing_authority
      },
      issuingAuthorityOptions () {
        return this.hasCountriesAsIssuingAuthority ?
          this.countries :
          this.issuingAuthority.map((option) => ({
            label: option,
            value: option,
          }))
      },
      isUnder18None () {
        return this.document.key === 'under18_none'
      },
      receiptPopoverText () {
        return `You may use a receipt as proof that you can provide a physical document at a later date.
          If you are using a receipt in place of one of the documents listed below, please select
          the title of that document to proceed. When requested to upload a photo of the document,
          instead upload a photo of your receipt. For Document Title, put the word "Receipt" in
          front of the Document Title (For example, "Receipt- Drivers License"). A list of eligible
          receipts and the timeframe to enter for Expiration Date can be found <a href="https://www.uscis.gov/i-9-central/form-i-9-acceptable-documents/receipts" target="_blank">here</a>.`
      },
      shouldDisableDocumentTitle () {
        const key = this.document.key
        const isEditableType = EDITABLE_DOC_TITLE_KEYS.includes(key)

        return this.isUnder18None || !isEditableType
      },
      shouldDisableIssuingAuthority () {
        return this.isUnder18None
      },
      shouldShowOcrFields () {
        return !this.canOcrDocument || this.documentNumberHasErrors || this.expirationHasErrors || this.verificationFallbackMode
      },
      isOtherAcceptableReceipt () {
        return this.document.key === 'other_acceptable_receipt'
      },
      documentNumberMask() {
        return this.document?.document_number_format?.v_input_mask
      },
    },
    watch: {
      formData: {
        handler(newValue, _oldValue) {
          this.handleInput(newValue)
        },
        deep: true,
      },
      isReceipt () {
        this.setDocumentTitle()
      },
      document (newValue, oldValue) {
        if (oldValue.back_file != newValue.back_file) {
          this.resetBackFile = false
        } else {
          this.resetBackFile = true
        }
      },
      attachmentName () {
        this.resetImageData()
        this.setInitialFormData()
        this.setImageData()
      },
    },
    created () {
      this.setInitialFormData()
    },
    methods: {
      getDocumentTitle (currentTitle, defaultTitle, isReceipt) {
        const title = (currentTitle || defaultTitle || '').replace(RECEIPT_PREFIX, '')
        let val = title
        if (isReceipt && !this.isOtherAcceptableReceipt) {
          val = `${RECEIPT_PREFIX}${title}`
        }
        return val
      },
      errorMessage (name) {
        if (!this.errors[name]?.length) { return null }
        return this.errors[name].join(' ')
      },
      handleInput (value) {
        this.$emit('update', value)
      },
      handleUploadSuccess (fileFace, {url, key, contentType}) {
        this.formData[`file_${fileFace}_url`] = url
        this.formData[`file_${fileFace}_cache`] = key
        this.formData[`file_${fileFace}_content_type`] = contentType
        this.handleInput(this.formData)
      },
      handleUploadRemove (fileFace){
        this.formData[`file_${fileFace}_url`] = null
        this.formData[`file_${fileFace}_cache`] = null
        this.formData[`file_${fileFace}_content_type`] = null
        this.handleInput(this.formData)
      },
      setDocumentTitle () {
        if (this.hasMultipleDocumentTitles) {
          this.formData.document_title = this.documentTitleOptions[this.documentTitleIndex].label

          // Force re-render of select with new options
          const docTitleIndex = this.documentTitleIndex
          this.documentTitleIndex = 0
          this.$nextTick(() => {
            this.documentTitleIndex = docTitleIndex
          })
        } else {
          this.formData.document_title = this.documentTitle
        }
      },
      setInitialFormData () {
        this.formData = {
          document_title: null,
          receipt: this.isOtherAcceptableReceipt ? true : null,
          ...this.attachmentAttributes,
        }
        this.setDocumentTitle()
      },
      resetImageData () {
        if (this.hasFront) {
          this.$refs.uploadFront.handleRemoveFile()
        }
        if (this.hasBack && this.resetBackFile) {
          this.$refs.uploadBack.handleRemoveFile()
        }
      },
      setImageData () {
        if (this.hasFront) {
          this.$refs.uploadFront.loadExistingImage(this.formData.file_front_url)
        }
        if (this.hasBack && this.resetBackFile) {
          this.$refs.uploadBack.loadExistingImage(this.formData.file_back_url)
        }
      },
    },
  }
</script>
