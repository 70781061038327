<template>
  <div>
    <hr />
    <h4>{{ title }}</h4>
    <div
      class="panel gray-bg"
      v-if="isReviewing"
      ref="reviewPanel"
    >
      <p>
        Document Number:
        <strong class="block-translate"> {{ docInformation.documentNumber }}</strong>
      </p>
      <p>
        Issuing Authority:
        <strong>{{ docInformation.issuingAuthority }}</strong>
      </p>
      <p>
        Expiration Date:
        <strong>{{ docInformation.expirationDate }}</strong>
      </p>
      <div>
        <div class="mb-3 file-front-view-container">
          <FileViewer
            :fileUrl="frontUrl"
            v-if="frontUrl"
            ref="frontUrl"
          />
        </div>
        <div class="mb-3 file-back-view-container">
          <FileViewer
            :fileUrl="backUrl"
            v-if="backUrl"
            ref="backUrl"
          />
        </div>
      </div>
      <p ref="receiptInfo">
        What if a receipt for a document is shown?
        <PopoverIcon
          title="Document Receipts"
          :message="receiptInstructions"
        />
      </p>
      <p
        ref="reviewInstructions"
        v-if="reviewInstructions"
        v-html="reviewInstructions"
      />
      <div
        class="row mb-3"
        ref="rejectionReasons"
        :key="index"
        v-for="(fieldButtonProps, index) in buttonReviewOptions"
      >
        <FormFieldButtonGroup
          :ref="fieldButtonProps.ref"
          :name="fieldButtonProps.name"
          :label="fieldButtonProps.label(employeeFullName)"
          title=""
          :options="fieldButtonProps.buttonOptions"
          :required="true"
          orientation="horizontal"
          :value="fieldButtonProps.buttonSelectionValue"
          @selected="(res) => handleFormButton(res, index)"
        />
      </div>
      <div>
        <WbButton
          context="primary"
          @click="handleVerifyClick()"
          ref="continueButton"
          class="mr-3"
          :disabled="!allFieldsAnswered"
        >
          Continue
        </WbButton>
      </div>
    </div>
    <div
      class="row"
      ref="reviewSummary"
      v-else
    >
      <div class="col-sm-3">
        <WbButton
          :context="isStarted ? 'default' : 'primary'"
          @click="handleReviewClick"
          ref="reviewButton"
          class="mr-3"
        >
          {{ isStarted ? 'Review Again' : 'Start Review' }}
        </WbButton>
      </div>
      <div
        v-if="isStarted"
        class="col-sm-9"
      >
        <div
          class="alert"
          context="default"
          :class="isMatch ? 'alert-success' : 'alert-warning'"
          ref="matchAlert"
        >
          {{ isMatch ? 'You verified that this document matched' : 'You indicated that this document did not match' }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import FileViewer from 'components/common/FileViewer'
  import FormFieldButtonGroup from 'components/common/FormFieldButtonGroup'
  import PopoverIcon from 'components/common/PopoverIcon'
  import WbButton from 'components/common/WbButton'

  const FORM_BUTTON_VALUES = [
    { label: 'No', value: 'No' },
    { label: 'Yes', value: 'Yes' },
  ]

  export default {
    components: {
      PopoverIcon,
      FileViewer,
      WbButton,
      FormFieldButtonGroup,
    },

    props: {
      document: {
        type: Object,
        required: true,
      },
      employeeFullName: {
        type: String,
        required: true,
      },
      receiptInstructions: {
        type: String,
      },
      reviewInstructions: {
        type: String,
      },
    },

    data() {
      return {
        buttonReviewOptions: [
          {
            buttonOptions: [...FORM_BUTTON_VALUES],
            buttonSelectionValue: '',
            label: () => 'Matches the information provided',
            name: 'form_not_information_match',
            ref: 'informationMatch',
            title: '',
          },
          {
            buttonOptions: [...FORM_BUTTON_VALUES],
            buttonSelectionValue: '',
            label: () => 'Appears to be genuine',
            name: 'form_not_genuine_information',
            ref: 'informationGenuine',
            title: '',
          },
          {
            buttonOptions: [...FORM_BUTTON_VALUES],
            buttonSelectionValue: '',
            label: (employeeFullName) => `Appears to relate to ${employeeFullName}`,
            name: 'form_not_relating_to_employee',
            ref: 'informationRelatesToEmployee',
            title: '',
          },
        ],
      }
    },

    computed: {
      allFieldsAnswered () {
        return this.buttonReviewOptions.every((option) => !!option.buttonSelectionValue)
      },
      backUrl () {
        return this.document.data.file_back_url
      },
      docInformation () {
        return {
          documentNumber: this.document.data.document_number,
          issuingAuthority: this.document.data.issuing_authority,
          expirationDate: this.document.data.expiration_date,
        }
      },
      frontUrl () {
        return this.document.data.file_front_url
      },
      isMatch () {
        return this.document.match === true
      },
      isReviewing () {
        return this.document.reviewing
      },
      isStarted () {
        return this.document.match !== null
      },
      title () {
        return this.document.data.document_title
      },
    },

    methods: {
      handleFormButton (buttonValue, index) {
        this.buttonReviewOptions[index].buttonSelectionValue = buttonValue
      },
      handleReviewClick () {
        this.buttonReviewOptions.forEach((option) => option.buttonSelectionValue = '')

        this.isStarted ? this.$emit('reviewReset') : this.$emit('reviewStart')
      },
      handleVerifyClick () {
        let allMatch = true
        const mismatchReasons = []
        this.buttonReviewOptions.forEach(({ buttonSelectionValue, name }) => {
          if (buttonSelectionValue !== 'Yes') {
            mismatchReasons.push(name)
            allMatch = false
          }
        })

        this.$emit('verify', allMatch, mismatchReasons)
      },
    },
  }
</script>
