import Constants from 'lib/constants'

(function($) {
  FormValidation.Validator.wbRemote = {
    /**
     * Placeholder validator that can be used to attach error messages from the server. 
     *
     *      fv.updateMessage(field, 'wbRemote', errorMessage);
     *      fv.updateStatus(field, 'INVALID', 'wbRemote');
     *
     * @param {FormValidation.Base} validator The validator plugin instance
     * @param {jQuery} $field Field element
     * @param {Object} options Can consist of the following keys:
     * - message: The invalid message
     * @returns {Boolean}
     */
    validate: function(validator, $field, options, validatorName) {
      return true
    }
  }
}(jQuery))
