var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "panel minimal gray-bg" },
    [
      _vm.formResponse
        ? _c("form-alerts", {
            attrs: {
              success: _vm.formResponse.success,
              errors: _vm.formResponse.errors,
              msgSuccess: _vm.formResponse.msgSuccess,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "panel-heading no-border" }, [
        _vm.title
          ? _c("div", { staticClass: "panel-title" }, [
              _c("h3", [_vm._v("\n        " + _vm._s(_vm.title) + "\n      ")]),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }