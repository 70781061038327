import { render, staticRenderFns } from "./HistoricalSubmissionsList.vue?vue&type=template&id=0dc081a6&"
import script from "./HistoricalSubmissionsList.vue?vue&type=script&lang=js&"
export * from "./HistoricalSubmissionsList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/workbright/shared/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0dc081a6')) {
      api.createRecord('0dc081a6', component.options)
    } else {
      api.reload('0dc081a6', component.options)
    }
    module.hot.accept("./HistoricalSubmissionsList.vue?vue&type=template&id=0dc081a6&", function () {
      api.rerender('0dc081a6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/assets/javascripts/src/components/employees/document_assignments/HistoricalSubmissionsList.vue"
export default component.exports