import * as mutationTypes from '../modules/collection_view/mutation_types'
import Vue from 'vue'

// Represents and manages metadata about a collection of items, including:
// - Selected items
// - Filter values
export default {
  namespaced: true,
  state: function() {
    return {
      selected: [],       // Array of selected items, by id. NOTE: Would prefer to use Set or similar, but not supported by Vue
      filter_values: {},  // Filters applied to the collection
      sort_order: null,   // Sorting applied to the collection
      page: null          // page number of results in collection
    }
  },

  getters: {
    // Returns boolean
    isSelected: (state) => (item) => {
      return state.selected.indexOf(item) != -1
    },

    // Returns array of selected item IDs
    selected: (state) => {
      return state.selected
    },

    // Returns object with filter values
    //
    // {
    //   filter_key: 'some value',
    //   filter_key_2: 'some value'
    // }
    filterValues: (state) => {
      return state.filter_values
    },

    // Returns sort ordering value
    sortOrder: (state) => {
      return state.sort_order
    },

    // Are any filters set?
    // returns - true if state.filter_values has any values set
    filtersActive: (state) => {
      const activeFilters = Object.values(state.filter_values).map((filter) => {
        switch(typeof filter) {
        case 'object':
          return Object.keys(filter).length > 0
        case 'string':
          return filter.length > 0
        case 'boolean':
          return true // if a boolean filter is set, mark it as active
        }
      })

      return !!activeFilters.length && activeFilters.some(x => x == true)
    },

    // Returns page number
    page: (state) => {
      return state.page || 1
    }
  },
  mutations: {
    // Selects/Unselects single row
    [mutationTypes.SET_ROW_SELECTION](state, payload) {
      const selectedIdx = state.selected.indexOf(payload.item)
      if(selectedIdx == -1) {
        // Item does not exist already, let's select it
        state.selected.push(payload.item)
      } else {
        // Item exists, let's remove it
        state.selected.splice(selectedIdx, 1)
      }
    },

    // Selects all rows in payload
    [mutationTypes.SET_MULTIPLE_ITEMS_SELECTION](state, payload) {
      state.selected = payload.items
    },

    // Selects/Unselects all rows
    [mutationTypes.SET_ALL_ITEMS_SELECTION](state, payload) {
      const itemsToAdd = []
      payload.items.forEach((item) => {
        if(state.selected.indexOf(item) == -1) {
          // If not already selected, add item
          itemsToAdd.push(item)
        }
      })

      if(itemsToAdd.length == 0) {
        // Unselect All
        state.selected = []
      } else {
        // Select all
        state.selected = state.selected.concat(itemsToAdd)
      }
    },

    // Clears all rows
    [mutationTypes.CLEAR_SELECTIONS](state) {
      state.selected = []
    },

    // Sets a filter value
    //
    // payload: {
    //   filter_key: 'some_key',
    //   value: 'some value'
    // }
    [mutationTypes.SET_FILTER_VALUE](state, payload) {
      if(payload.value == null) {
        Vue.delete(state.filter_values, payload.filter_key)
      } else {
        Vue.set(state.filter_values, payload.filter_key, payload.value)
      }
      state.page = 1 // reset page number whenever a filter is selected/deselected
    },

    // Sets a list of filter values
    //
    // payload: [
    //   { filter_key: 'first_key',  value: 'first value' }
    //   { filter_key: 'second_key', value: 'second value' }
    //   { filter_key: 'third_key',  value: 'third value' }
    // ]
    [mutationTypes.SET_FILTER_VALUES](state, payload) {
      if(payload.length) {
        payload.forEach((filter) => {
          if(filter.value) {
            Vue.set(state.filter_values, filter.filter_key, filter.value)
          } else {
            Vue.delete(state.filter_values, filter.filter_key)
          }
        })
        state.page = 1 // reset page number whenever filters are selected/deselected
      }
    },

    // Clears all filter values
    [mutationTypes.CLEAR_FILTER_VALUES](state) {
      state.filter_values = {}
      state.page = 1
    },

    // Set collection sort order
    //
    // payload: { sortOrder: 'date' }
    [mutationTypes.SET_SORT_ORDER](state, payload) {
      state.sort_order = payload.sortOrder
      state.page = 1
    },

    // Set collection page number
    //
    // payload: { page: 3 }
    [mutationTypes.SET_PAGE_NUMBER](state, payload) {
      state.page = payload.page
    }
  },
  actions: {
    select({ commit }, id) {
      commit(mutationTypes.SET_ROW_SELECTION, { item: id })
    },

    selectList({ commit }, ids) {
      commit(mutationTypes.SET_MULTIPLE_ITEMS_SELECTION, { items: ids })
    },

    selectAll({ commit }, ids) {
      commit(mutationTypes.SET_ALL_ITEMS_SELECTION, { items: ids })
    },

    reset({ commit }) {
      commit(mutationTypes.CLEAR_SELECTIONS)
      commit(mutationTypes.CLEAR_FILTER_VALUES)
    },

    setFilterValue({ commit }, filter) {
      commit(mutationTypes.SET_FILTER_VALUE, filter)
      commit(mutationTypes.CLEAR_SELECTIONS)
    },

    setFilterValues({ commit }, filters) {
      commit(mutationTypes.SET_FILTER_VALUES, filters)
      commit(mutationTypes.CLEAR_SELECTIONS)
    },

    setSortOrder({ commit }, sortOrder) {
      commit(mutationTypes.SET_SORT_ORDER, sortOrder)
      commit(mutationTypes.CLEAR_SELECTIONS)
    },

    setPageNumber({ commit }, page) {
      commit(mutationTypes.SET_PAGE_NUMBER, page)
      commit(mutationTypes.CLEAR_SELECTIONS)
    }
  }
}
