var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasSufficientData
    ? _c("div", { staticClass: "mt-4" }, [
        _c(
          "ul",
          { ref: "logContent", staticClass: "list-group wb-list" },
          [
            _c("ListItem", { attrs: { secondaryActions: false } }, [
              _c("div", { staticClass: "wb-list__content" }, [
                _c("div", { staticClass: "wb-list__title-slideout" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("WbSimpleDateTime", {
                        ref: "logDate",
                        attrs: { value: _vm.resolvedDate },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", [
                    _c("strong", { ref: "logStatus" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.verificationStatus) +
                          "\n            "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.adminName
                    ? _c("div", { ref: "adminName" }, [
                        _vm._v(
                          "\n            by " +
                            _vm._s(_vm.adminName) +
                            "\n          "
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }