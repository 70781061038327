var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    [
      _vm.hasStandardFields
        ? [
            _c("p", [
              _vm._v(
                _vm._s(_vm.$locale.t("standard_fields.mapping.instructions"))
              ),
            ]),
            _vm._v(" "),
            _vm.directDepositIncluded
              ? _c("p", [
                  _c("em", [
                    _vm._v(
                      _vm._s(
                        _vm.$locale.t(
                          "standard_fields.mapping.single_direct_deposit_note"
                        )
                      )
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "table",
              { ref: "fieldsTable", staticClass: "table table-striped" },
              [
                _c("thead", [
                  _c("tr", [
                    _c("th", [
                      _vm._v(_vm._s(_vm.$locale.t("standard_fields.field"))),
                    ]),
                    _vm._v(" "),
                    _c("th", [
                      _vm._v(
                        _vm._s(_vm.$locale.t("standard_fields.description"))
                      ),
                    ]),
                    _vm._v(" "),
                    _c("th", { staticClass: "text-center" }, [
                      _vm._v(
                        _vm._s(_vm.$locale.t("standard_fields.internal")) + "?"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("th", { staticClass: "text-center" }, [
                      _vm._v(
                        _vm._s(_vm.$locale.t("standard_fields.mapped")) + "?"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("th", { attrs: { role: "presentation" } }),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.standardFields, function (field, index) {
                    return _c("tr", { key: index }, [
                      _c("td", [_vm._v(_vm._s(field.label))]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(field.description))]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-center" },
                        [
                          field.internal
                            ? _c("i", { staticClass: "fa fa-check" })
                            : [_vm._v("--")],
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-center" },
                        [
                          _vm.getFieldMappingStatus(field)
                            ? _c("i", { staticClass: "fa fa-check" })
                            : [_vm._v("--")],
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-link",
                            on: {
                              click: function ($event) {
                                return _vm.openMapperModal(field)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.getMappingActionText(field)) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]),
                    ])
                  }),
                  0
                ),
              ]
            ),
            _vm._v(" "),
            _vm.activeField
              ? _c("FieldMapper", { attrs: { standardField: _vm.activeField } })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mt-3 text-right" },
              [
                _vm.shouldShowErrors && _vm.hasErrors
                  ? _c(
                      "p",
                      { ref: "helpText", staticClass: "help-block error" },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm.$locale.t(
                                "standard_fields.mapping.error_message"
                              )
                            ) +
                            "\n      "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "ladda-button",
                  {
                    ref: "finishButton",
                    staticClass: "btn btn-primary",
                    on: { click: _vm.handleFinishClick },
                  },
                  [_vm._v("\n        Finish\n      ")]
                ),
              ],
              1
            ),
          ]
        : _c("div", { ref: "noData", staticClass: "alert alert-info" }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm.$locale.t("standard_fields.account_no_fields")) +
                "\n  "
            ),
          ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }