var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h4", [_vm._v(_vm._s(_vm.stepHeader))]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-horizontal" },
      _vm._l(_vm.generalPermissions, function (permission, index) {
        return _c(
          "div",
          { key: index, staticClass: "form-group clearfix" },
          [
            _c("permissions-toggle-wide", {
              attrs: { permission: permission, label: true },
              on: { permissionChanged: _vm.permissionChanged },
            }),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }