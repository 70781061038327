var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Panel", {
    staticClass: "close-case-and-resubmit-panel",
    attrs: { variant: "snapshot" },
    scopedSlots: _vm._u([
      {
        key: "heading",
        fn: function () {
          return [
            _c("div", { staticClass: "panel-title" }, [
              _c("h3", [_vm._v("Action Required")]),
              _vm._v(" "),
              _c("h4", [
                _vm._v(
                  _vm._s(
                    _vm.$locale.t(
                      "everify.status.close_case_and_resubmit.title"
                    )
                  )
                ),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c("p", [_vm._v("\n      " + _vm._s(_vm.instructions) + "\n    ")]),
            _vm._v(" "),
            _c("EverifyCaseCloseForm", {
              attrs: { everifyCase: _vm.everifyCase },
              on: { closeTimeout: _vm.closeModal },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }