var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.success
      ? _c("div", { staticClass: "alert alert-success" }, [
          _vm._v("\n    " + _vm._s(_vm.msgSuccess) + "\n  "),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.hasError
      ? _c("div", { staticClass: "alert alert-danger" }, [
          _vm._v("\n    " + _vm._s(_vm.errorMessage) + "\n  "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }