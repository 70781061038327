<template>
  <form-field
    :label="label"
    :name="name"
    :helpPopoverText="helpPopoverText"
    :orientation="orientation"
    :errors="errors"
    :required="required"
    :hint="hint"
  >
    <div :class="fieldWidth">
      <input class="form-control"
        :name="`${name}-mask`"
        type="text"
        placeholder="XXX-XX-XXXX"
        :value="displayValue"
        :disabled="disabled"
        v-mask="'XXX-XX-XXXX'"
        @input="handleInput"
        @focus="handleFocus"
        @blur="handleBlur"
        ref="maskedInput"
      />
      <input
        type="hidden"
        :name="name"
        :id="name"
        v-model="localValue"
        v-mask="'###-##-####'"
        ref="formInput"
      />
    </div>
  </form-field>
</template>

<script>
  import FormField from 'components/common/FormField'
  import FormFieldConcern from 'mixins/FormFieldConcern'
  import masks from 'lib/util/masks'

  export default {
    name: 'form-field-ssn',
    mixins: [FormFieldConcern],
    components: {
      FormField
    },
    computed: {
      displayValue () {
        return this.isFocussed ? this.localValue : this.maskedValue
      },
      maskedValue () {
        const ssn = this.localValue || ''
        return masks.ssn_last4(ssn)
      }
    },
    created () {
      this.localValue = this.value
    },
    data () {
      return {
        localValue: null,
        isFocussed: false
      }
    },
    methods: {
      handleBlur () {
        this.isFocussed = false
      },
      handleFocus () {
        this.isFocussed = true
      },
      handleInput (event) {
        if (event.isTrusted) {
          this.localValue = event.target.value
        }
      }
    },
    props: {
      value: {
        type: [String, Number],
        required: false
      },

      disabled: {
        required: false,
        default: false
      },

      fieldWidth: {
        type: String,
        default: 'short-field',
        validator: value => {
          return [
            '', // full-width of container
            'short-field',
            'medium-field',
            'long-field'
          ].includes(value)
        }
      }
    },
    watch: {
      localValue () {
        this.$emit('input', this.localValue)
      }
    }
  }
</script>
