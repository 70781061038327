var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "WbModal",
    {
      ref: "modal",
      attrs: {
        title: _vm.standardField && _vm.standardField.label,
        locked: false,
        watchPageContextVariable: "standard_field_id",
      },
    },
    [
      _vm._t("default", [
        [
          _c(
            "v-wait",
            { attrs: { visible: _vm.isLoading } },
            [
              _c("Spinner", {
                attrs: { slot: "waiting", showLongWaitMessage: true },
                slot: "waiting",
              }),
              _vm._v(" "),
              _vm.hasMappableFields
                ? _c(
                    "div",
                    { ref: "mappingForm", staticClass: "form-horizontal" },
                    [
                      _vm.hasErrors && _vm.shouldShowErrors
                        ? _c("div", { staticClass: "alert alert-danger" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$locale.t("errors.form.generic")) +
                                "\n          "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "row",
                          class: {
                            "has-error": _vm.hasErrors && !_vm.mappedField,
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-sm-7 col-sm-offset-4 col-md-offset-3",
                            },
                            [
                              _c("p", { staticClass: "help-block" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$locale.t(
                                        "standard_fields.choose_field",
                                        _vm.standardField.label
                                      )
                                    ) +
                                    "\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("WbNestedSelect", {
                                staticClass: "mb-3 no-wrap",
                                attrs: {
                                  tree: _vm.mappableFieldsTree,
                                  enableSearchField: false,
                                  value: _vm.mappedFieldKey,
                                  valueKey: "field_key",
                                },
                                on: {
                                  select: _vm.handleFieldSelected,
                                  input: _vm.handleFieldSelected,
                                },
                              }),
                              _vm._v(" "),
                              _c("div", [
                                _vm.mappedField
                                  ? _c(
                                      "button",
                                      {
                                        ref: "deleteMapping",
                                        staticClass: "btn btn-link pl-0",
                                        on: { click: _vm.handleDeleteClick },
                                      },
                                      [
                                        _c("i", { staticClass: "fa fa-close" }),
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.$locale.t(
                                                "standard_fields.delete_mapping"
                                              )
                                            ) +
                                            "\n                "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                              _vm._v(" "),
                              _vm.errors &&
                              _vm.errors.reference_field_key &&
                              _vm.errors.reference_field_key.length
                                ? _c(
                                    "div",
                                    {
                                      ref: "fieldFeedback",
                                      staticClass: "help-block error",
                                    },
                                    _vm._l(
                                      _vm.errors.reference_field_key,
                                      function (error, index) {
                                        return _c("p", { key: index }, [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(error) +
                                              "\n                "
                                          ),
                                        ])
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.mappedField && _vm.shouldMapOptions
                        ? _c("OptionsMapper", {
                            ref: "optionsMapper",
                            staticClass: "my-3",
                            attrs: {
                              mappedField: _vm.mappedField,
                              standardField: _vm.standardField,
                              shouldShowErrors: _vm.shouldShowErrors,
                            },
                            on: {
                              change: _vm.handleOptionsUpdate,
                              validate: _vm.handleOptionsMapperValidate,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _c(
                    "div",
                    { ref: "defaultContent", staticClass: "alert alert-info" },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$locale.t(
                              "standard_fields.no_fields",
                              _vm.standardField.label
                            )
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
            ],
            1
          ),
        ],
      ]),
      _vm._v(" "),
      _c("template", { slot: "footer" }, [
        _c(
          "button",
          {
            ref: "saveButton",
            staticClass: "btn btn-primary mr-1",
            on: { click: _vm.handleSaveClick },
          },
          [_vm._v("\n      " + _vm._s(_vm.$locale.t("forms.save")) + "\n    ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "btn btn-default", on: { click: _vm.cancelMapping } },
          [
            _vm._v(
              "\n      " + _vm._s(_vm.$locale.t("forms.cancel")) + "\n    "
            ),
          ]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }