var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", { attrs: { "data-id": _vm.user.id } }, [
    _c("td", [
      _vm._v("\n    " + _vm._s(_vm.user.name) + "\n    "),
      _vm.user.is_cs
        ? _c(
            "span",
            {
              ref: "csLabel",
              staticClass: "label label-default",
              attrs: {
                title:
                  "You may delete WorkBright customer success admin accounts any time after your account setup is complete.",
              },
            },
            [_vm._v("\n      WorkBright Customer Success\n    ")]
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    !_vm.isPendingAdmin
      ? _c("td", { ref: "staffRecord" }, [
          _vm.user.is_superadmin
            ? _c("span", [_vm._v("\n      --\n    ")])
            : _vm.user.is_employee
            ? _c(
                "a",
                {
                  staticClass: "btn btn-default btn-xs",
                  attrs: { href: _vm.employeePath },
                },
                [
                  _c("WbIcon", { attrs: { type: "user" } }),
                  _vm._v("\n      Open\n    "),
                ],
                1
              )
            : _c(
                "a",
                {
                  attrs: {
                    role: "button",
                    disabled: !_vm.isAddingEmployeeRecord,
                  },
                  on: { click: _vm.handleCreateStaffMemberClick },
                },
                [_vm._v("\n      Create Staff Member Record\n    ")]
              ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("td", { ref: "email" }, [
      _vm._v(
        "\n    " + _vm._s(_vm.shouldShowEmail ? _vm.user.email : "--") + "\n  "
      ),
    ]),
    _vm._v(" "),
    _vm.isPendingAdmin
      ? _c("td", { ref: "createdFrom" }, [
          _vm._v("\n    " + _vm._s(_vm.user.created_from) + "\n  "),
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.isPendingAdmin
      ? _c("td", { ref: "accessLevel" }, [
          _vm._v("\n    " + _vm._s(_vm.accessLevelOrPolicy) + "\n  "),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("td", { staticClass: "text-right" }, [
      _c(
        "div",
        { staticClass: "dropdown" },
        [
          _c(
            "WbButton",
            {
              staticClass: "btn-sm dropdown-toggle",
              attrs: { context: "default", "data-toggle": "dropdown" },
            },
            [
              _c("WbIcon", {
                staticClass: "fa-fw right",
                attrs: { type: "caret-down" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "ul",
            {
              staticClass: "dropdown-menu dropdown-menu-right",
              attrs: { role: "menu" },
            },
            [
              _vm.canEditAdmin
                ? _c("li", [
                    _c(
                      "a",
                      {
                        ref: "editAdmin",
                        attrs: {
                          href: _vm.$routes.edit_admin_user_path({
                            id: _vm.user.id,
                          }),
                        },
                      },
                      [_vm._v("\n            Edit Admin\n          ")]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.user.is_confirmed
                ? _c("li", [
                    _c(
                      "a",
                      {
                        ref: "resendWelcomeEmail",
                        attrs: {
                          role: "button",
                          disabled: _vm.isResendingWelcomeEmail,
                        },
                        on: { click: _vm.handleResendWelcomeEmail },
                      },
                      [_vm._v("\n            Resend Welcome Email\n          ")]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.user.is_employee
                ? _c("li", [
                    _c(
                      "a",
                      {
                        ref: "removeAdminAccess",
                        staticClass: "danger",
                        attrs: { role: "button" },
                        on: { click: _vm.handleRevokeAdminClick },
                      },
                      [_vm._v("\n            Remove Admin Access\n          ")]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.user.is_employee
                ? _c("li", [
                    _c(
                      "a",
                      {
                        ref: "deleteAdmin",
                        staticClass: "danger",
                        attrs: { role: "button" },
                        on: { click: _vm.handleDeleteAdminClick },
                      },
                      [_vm._v("\n            Delete Admin\n          ")]
                    ),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "text-left" },
            [
              _c("DeleteAdminModal", {
                attrs: { user: _vm.user },
                on: { adminUpdate: _vm.handleAdminUpdate },
              }),
              _vm._v(" "),
              _c("RevokeAdminAccessModal", {
                attrs: { user: _vm.user },
                on: { adminUpdate: _vm.handleAdminUpdate },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }