import PubSubUtil from 'lib/pub_sub_wrapped_action'
import Routes from 'lib/routes'

import * as BaseCollectionModule from 'vuex/mixins/base_vuex_collection_module'

function state() {
  return {
    collection: {}
  }
};

const getters = {
  // Fetch all groups in the store
  // Returns - Array
  all: (state) => {
    return _(state.collection)
  },

  ...BaseCollectionModule.getters
}

const mutations = {
  ...BaseCollectionModule.mutations
}

const actions = {
  ...BaseCollectionModule.actions,

  // Add Employees to Group
  // Posts to API, and receives response back on PubSub channel, as this is a long-running bulk operation
  //
  // data
  //   employees [Array] - Employees to add
  //   group [Object] - Group
  addEmployeesToGroup: ({ dispatch }, { employees, group }) => {
    return PubSubUtil.pubSubWrappedAction(
      'bulk_add_employees_to_group',
      (channelName, reject) => {
        $.ajax({
          method: 'POST',
          url: Routes.add_to_group_bulk_actions_employee_group_memberships_path,
          wbGenericFailureMsg: "Sorry, we couldn't add an employee to the group.",
          progressBar: false,
          data: {
            employee_ids: employees.map((e) => e.id),
            employee_group_id: group.id,
            pubsub_channel: channelName
          }
        }).fail(xhr => reject(xhr))
      },
      (_response, resolve, reject) => {
        dispatch(
          'employees/bulkShow',
          { employeeIds: employees.map((e) => e.id) },
          { root: true }
        )
          .then(() => resolve() )
          .catch((xhr) => reject(xhr) )
      }
    )
  },

  // Remove Employees from Group
  // Posts to API, and receives response back on PubSub channel, as this is a long-running bulk operation
  //
  // data
  //   employees [Array] - Employees to remove
  //   group [Object] - Group
  removeEmployeesFromGroup: ({ dispatch }, { employees, group }) => {
    return PubSubUtil.pubSubWrappedAction(
      'bulk_remove_employees_from_group',
      (channelName, reject) => {
        $.ajax({
          method: 'POST',
          url: Routes.remove_from_group_bulk_actions_employee_group_memberships_path,
          wbGenericFailureMsg: "Sorry, we couldn't remove an employee from the group.",
          progressBar: false,
          data: {
            employee_ids: employees.map((e) => e.id),
            employee_group_id: group.id,
            pubsub_channel: channelName
          }
        }).fail(xhr => reject(xhr))
      },
      (_response, resolve, reject) => {
        dispatch(
          'employees/bulkShow',
          { employeeIds: employees.map((e) => e.id) },
          { root: true }
        )
          .then(() => resolve() )
          .catch((xhr) => reject(xhr) )
      }
    )
  },

  // Retrieve all groups for an employee and save them in the store.
  fetchAll: ({ dispatch } ) => {
    return new Promise((resolve, reject) => {
      $.ajax({
        method: 'GET',
        url: Routes.Api.employee_groups_path,
        dataType: 'json',
        contentType: 'application/json'
      }).then((groups) => {
        dispatch(`groups/loadInitialData`, groups, { root: true })
        resolve()
      }).fail((xhr) => {
        reject(xhr)
      })
    })
  }
}


export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
