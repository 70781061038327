<template>
  <div>
    <div>
      <label class="small">
        {{ filter.title }}
      </label>
      <span v-if="filter.help_text">
        <a
          class="help-popover ml-1"
          v-tooltip.html="filter.help_text"
          href="javascript:void(0)"
        >
          <i class="fa fa-exclamation-circle" />
        </a>
      </span>
    </div>
    <radio-toggle class="mb-1"
      v-if="filterToggle"
      :title="filterToggle.title"
      :value="filterToggleValue || filterToggle.defaultValue"
      :options="filterToggle.options"
      :disabled="toggleDisabled"
      :helpText="filterToggle.helpText"
      buttonSize="btn-sm"
      @selected="selection => setFilterToggle(selection)"
    />
    <WbMultiselect
      :value="valueWithDefault"
      :selectOptions="filter.options"
      :enableSearchField="filter.enableSearchField"
      @input="values => setFilterValue(values)"
    />
  </div>
</template>

<script>
  import BaseFilter from 'components/common/collection_view_filters/BaseFilter'
  import WbMultiselect from 'components/common/WbMultiselect'
  import RadioToggle from 'components/common/RadioToggle'

  export default {
    extends: BaseFilter,
    name: 'multi-select-filter',
    components: {
      WbMultiselect,
      RadioToggle,
    },

    computed: {
      valueWithDefault() {
        return this.value || []
      },

      filterToggle() {
        return this.filter.toggle
      },

      toggleDisabled(){
        return this.value === null || this.value.length == 0
      },

      filterToggleValue: {
        get() {
          const filterValues = this.$store.getters[`${this.collectionViewName}/filterValues`]
          let value = filterValues[this.filterToggle.filter_key]
          if(value == undefined) { value = null };
          return value
        },

        set(value) {
          this.setFilterValue(value)
        }
      }
    },

    methods: {
      setFilterToggle(value) {
        this.$store.dispatch(
          `${this.collectionViewName}/setFilterValue`,
          {
            filter_key: this.filterToggle.filter_key,
            value: value
          }
        )
      }
    }
  }
</script>
