<template>
  <div>
    <h2>Form I-9 In Person Reverification</h2>
    <hr />
    <i9-remote-reverify-employee-container />
  </div>
</template>

<script>
  import I9RemoteReverifyEmployeeContainer from "components/i9_remote_reverify/I9RemoteReverifyEmployeeContainer"

  export default {
    name: "the-i9-remote-reverify-employee-root",
    components: {
      I9RemoteReverifyEmployeeContainer
    }
  }
</script>
