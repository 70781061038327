<template>
  <div>
    <p v-if="!managedPolicy" class="breathe">
      <i class="fa fa-info text-info fa-fw" />
      Groups selected: {{groupNames.join(', ')}}
    </p>
    <h4>
      Add each form you wish to customize. For any forms you don&apos;t add, we&apos;ll use the permissions you set on All other forms.
    </h4>
    <div class="breathe">
      <DocumentSelect v-on:select="add" />
    </div>
    <table class="table">
      <thead>
        <tr>
          <th width="35%">Field</th>
          <th width="20%">View &amp; Download</th>
          <th width="40%">Other Actions</th>
          <th width="5%">Remove</th>
        </tr>
      </thead>
      <tbody class="rule-table">
        <PermissionsCustomizeDocumentRow
          v-for="documentPermissions in groupedPermissions"
          :key="documentPermissions.key"
          :permission-set="groupPermissionSet"
          :document-id="documentPermissions.key"
          :permissions="documentPermissions.permissions"
          v-on:remove="remove"
        />
      </tbody>
    </table>
    <p>
      <i class="fa fa-info text-info fa-fw" />
      You must give View access before you can configure any Other Actions.
    </p>
  </div>
</template>

<script>
  import BaseStep from 'components/permissions/steps/BaseStep'
  import DocumentSelect from 'components/common/DocumentSelect'
  import PermissionsCustomizeDocumentRow from 'components/permissions/PermissionsCustomizeDocumentRow'

  import StepDocumentsCustomizePresenter from 'presenters/policy_generator/step_documents_customize_presenter'

  export default {
    name: 'step-documents-customize',

    components: {
      PermissionsCustomizeDocumentRow,
      DocumentSelect
    },
    extends: BaseStep,

    props: {
      managedPolicy: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        groupPermissionSet: null,
        presenter: null
      }
    },

    computed: {
      groupedPermissions() {
        return this.presenter.groupedPermissions()
      }
    },

    created() {
      this.groupPermissionSet = this.policy.groupPermissionSet()
      this.presenter = new StepDocumentsCustomizePresenter(this.groupPermissionSet)
    },

    mounted() {
      const matchingTemplate = this.presenter.matchingTemplate()
      if(matchingTemplate) { this.$emit('skip') }
    },

    methods: {
      add(document) {
        this.presenter.addPermission(document)
      },

      remove(documentId) {
        if (documentId) {
          this.presenter.removePermission(documentId)
        }
      }
    }
  }
</script>
