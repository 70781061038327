import Vue from 'vue'

function initPopover(el, binding) {
  const $el = $(el)

  $el.popover('destroy') // Remove existing tooltip

  if (!binding.value || binding.value == '') { return } // Don't show a popover without content

  const placement = (() => {
    if (binding.modifiers.bottom) { return 'bottom' }
    else if (binding.modifiers.left) { return 'left' }
    else if (binding.modifiers.right) { return 'right' }
    else { return 'top' }
  })()

  const html = binding.modifiers.html ? 'html' : null

  $el.popover({
    container: 'body',
    title: binding.value,
    placement: placement,
    html: html,
    trigger: 'focus'
  })
}

export default Vue.directive('popover', {
  inserted: (el, binding) => {
    initPopover(el, binding)
  },

  componentUpdated: (el, binding) => {
    initPopover(el, binding)
  }
})
