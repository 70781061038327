<template>
  <div>
    <h5>Rehire Summary For {{ employeeFullName }}</h5>
    <p class="summary-groups">
      <span v-if="haveGroupsChanged" ref="groupsChange">
        <div class="submission-status-indicator status-accepted" style="margin-left: 20px;">
          <i class="fa fa-fw status-icon" />
          <span style="color: #414545;">
            <strong>Groups:</strong>
            <span>
              The staff member will be added to {{ pluralizedAddedGroups }} and removed from {{ pluralizedRemovedGroups }}
            </span>
          </span>
        </div>
      </span>
      <span v-else ref="groupsNoChange">
        <div class="submission-status-indicator status-missing" style="margin-left: 20px; color: #aaaaaa;">
          <i class="fa fa-fw status-icon" />
          <span>
            <strong>Groups:</strong>
            <span>No change</span>
          </span>
        </div>
      </span>
    </p>
    <p class="summary-forms">
      <span v-if="haveFormsChanged" ref="formsChange">
        <div class="submission-status-indicator status-accepted" style="margin-left: 20px;">
          <i class="fa fa-f status-icon" />
          <span style="color: #414545;">
            <strong>Forms:</strong>
            <span>
              {{ employeeFirstName }} will be asked to submit {{ pluralizedNewForms }} and resubmit {{ pluralizedReassignedForms }}.
            </span>
            <span v-if="reassignedInternalForms.length">
              Administrators will be asked to resubmit {{ pluralizedInternalForms }}.
            </span>
          </span>
        </div>
      </span>
      <span v-else  ref="formsNoChange">
        <div class="submission-status-indicator status-missing" style="margin-left: 20px; color: #aaaaaa;">
          <i class="fa fa-fw status-icon" />
          <span>
            <strong>Forms:</strong>
            <span>No change</span>
          </span>
        </div>
      </span>
    </p>
    <p class="summary-employment-dates">
      <span v-if="haveDatesBeenSet" ref="datesSet">
        <div class="submission-status-indicator status-accepted" style="margin-left: 20px;">
          <i class="fa fa-fw status-icon" />
          <span style="color: #414545;">
            <strong>Employment Dates:</strong>
            <span>
              The
              <span style="text-transform: capitalize;">{{ changedDates.join(', ') }}</span>
              will be updated.
            </span>
          </span>
        </div>
      </span>
      <span v-else ref="datesNotSet">
        <div class="submission-status-indicator status-missing" style="margin-left: 20px; color: #aaaaaa;">
          <i class="fa fa-fw status-icon" />
          <span>
            <strong>Employment Dates:</strong>
            <span>No change</span>
          </span>
        </div>
      </span>
    </p>
    <h4 style="margin-top: 30px; margin-bottom: 15px;">
      What Happens Next
    </h4>
    <p>
      When you click "Finish", {{ employeeFirstName }} will be activated and their forms will be assigned, though their forms will not be sent until the notification start date that you selected.
    </p>
    <p v-if="notificationDate">
      <ul>
        <li>
          On {{ notificationDate }}, {{ employeeFirstName }} will receive the "Welcome Back!" notification from WorkBright which notifies them of their new forms.
        </li>
        <li>
          After {{ employeeFirstName }} receives the notification and logs in, they'll be prompted to review their profile and make any changes necessary, such as a new address.
        </li>
        <li>
          They will be able to view a list of their required forms and complete any new requests or reassignments.
        </li>
        <li>
          They will continue to receive automated reminder notifications until their forms are complete.
        </li>
      </ul>
    </p>
    <p v-if="!notificationDate">
      <ul>
        <li>
          You have not scheduled notifications for {{ employeeFirstName }} but the selected forms will be assigned to them.
        </li>
        <li>
          When {{ employeeFirstName }} logs in, they'll be prompted to review their profile and make any changes necessary, such as a new address.
        </li>
        <li>
          They'll be able to view a list of their required forms and complete any new requests or reassignments.
        </li>
        <li>
          They will continue to receive automated reminder notifications until their forms are complete.
        </li>
      </ul>
    </p>
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex'
  import CollectionViewStore from 'vuex/modules/collection_view_store'
  import DateTime from 'lib/util/datetime'
  import StringUtil from 'lib/util/string'

  const VUEX_COLLECTION_VIEW_NAME = 'collection_view_document_assignments'

  export default {
    name: 'rehire-summary',

    components: {
      CollectionViewStore,
    },

    props: {
      employeeId: {
        type: Number,
        required: true
      },
    },

    data() {
      return {
        collectionViewName: VUEX_COLLECTION_VIEW_NAME,
      }
    },

    computed: {
      updatedCustomFields() {
        if (this.pageContext.customFields) {
          return this.pageContext.customFields.filter(cf => cf.original_value != cf.current_value)
        } else {
          return []
        }
      },

      // Has the user updated any custom fields?
      haveCustomFieldsChanged() {
        return this.updatedCustomFields.length > 0
      },

      // Has the user changed the original set of groups?
      haveGroupsChanged() {
        return this.addedGroups.length || this.removedGroups.length
      },

      // New groups selected by the user
      addedGroups() {
        return this.selectedGroupIds.filter(g => !this.preselectedGroupIds.includes(g))
      },

      // Existing groups unselected by the user
      removedGroups() {
        return this.preselectedGroupIds.filter(g => !this.selectedGroupIds.includes(g))
      },

      selectedGroupIds() {
        return this.dataLoaded ? this.filterValues.selected_groups : []
      },

      pluralizedAddedGroups() {
        return StringUtil.pluralize(this.addedGroups.length, 'group')
      },

      pluralizedInternalForms() {
        return StringUtil.pluralize(this.reassignedInternalForms.length, 'internal form')
      },

      pluralizedNewForms() {
        return StringUtil.pluralize(this.newForms.length, 'new form')
      },

      pluralizedReassignedForms() {
        return StringUtil.pluralize(this.reassignedEmployeeForms.length, 'previously assigned form')
      },

      pluralizedRemovedGroups() {
        return StringUtil.pluralize(this.removedGroups.length, 'group')
      },

      // These are the "original" groups that an employee belonged to when the rehire process started. We use this list
      // of groups to know if any new groups have been selected and to reset the groups back to their original state.
      // This list should not be modified.
      preselectedGroupIds() {
        if (!!this.pageContext.groups) {
          return this.pageContext.groups.map(g => parseInt(g, 10))
        } else {
          return []
        }
      },

      newForms() {
        return this.dataLoaded ? this.filterValues.new_assignments : []
      },

      reassignedForms() {
        return this.dataLoaded ? this.selected : []
      },

      // Internal reassignments
      reassignedInternalForms() {
        return this.reassignedForms.filter(assignmentId => {
          return this.isInternalForm(assignmentId)
        })
      },

      // Non-internal reassignments: assignments that the employee will be requested to resubmit.
      reassignedEmployeeForms() {
        return this.reassignedForms.filter(assignmentId => {
          return !this.reassignedInternalForms.includes(assignmentId) && this.canRequestAssignment(assignmentId)
        })
      },

      haveFormsChanged() {
        return this.newForms.length || this.reassignedForms.length
      },

      employee() {
        return this.getEmployee(this.employeeId)
      },

      employeeFirstName() {
        return this.employee.first_name
      },

      // The employee.full_name includes the middle name but we just want the first and last names.
      employeeFullName() {
        return this.$employee.fullName(this.employee)
      },

      employmentDates() {
        if (!this.dataLoaded) { return }
        return this.filterValues.employment_dates
      },

      notificationDate() {
        if (!this.dataLoaded || !this.employmentDates) { return }
        const obsd = this.employmentDates.onboarding_start_date
        return obsd ? DateTime.formatDate(obsd) : obsd
      },

      changedDates() {
        if (!this.dataLoaded) { return [] }

        const newDates = this.employmentDates
        if (!newDates) { return [] }

        return Object.entries(newDates).map(([name, date]) => {
          if (name == 'onboarding_start_date') { name = 'notification_start_date' };

          // Convert variable names to separate words: 'start_date' => 'start date'. Capitalization is applied by CSS.
          return !!date ? name.split('_').join(' ') : null
        }).filter(name => name) // remove null values
      },

      haveDatesBeenSet() {
        return this.changedDates.length > 0
      },

      pageStatus() {
        return {
          groupsLoaded: this.pageContext.groupsLoaded,
          documentsLoaded: this.pageContext.documentsLoaded,
          assignmentsLoaded: this.pageContext.assignmentsLoaded,
        }
      },

      dataLoaded() {
        if (!this.pageContext) { return false }
        return Object.values(this.pageStatus).every(v => v)
      },

      ...mapGetters({
        pageContext: 'pageContext',
        allGroups: 'groups/all',
        getEmployee: 'employees/get',
        customFields: 'custom_fields/all',
        getDocument: 'documents/get',
        getAssignment: 'document_assignments/get',
        isPermitted: 'permissions/isPermitted',
        filterValues: `${VUEX_COLLECTION_VIEW_NAME}/filterValues`,
        selected: `${VUEX_COLLECTION_VIEW_NAME}/selected`,
      }),

      ...mapState({
        documents: state => state['documents'].collection,
        documentAssignments: state => state['document_assignments'].collection,
        employments: state => state['employments'].collection,
      }),
    },

    created: function() {
      if(!this.$store._modulesNamespaceMap[`${this.collectionViewName}/`]) {
        this.$store.registerModule(this.collectionViewName, CollectionViewStore)
      }
    },

    methods: {
      isInternalForm(assignmentId) {
        const docId = this.getAssignment(assignmentId).document_id
        return this.getDocument(docId).internal
      },

      // Does the user have permission to request a new submission for the assignment? (Returns true/false)
      canRequestAssignment(assignmentId) {
        return this.isPermitted('document_assignments', assignmentId, 'unlock_submission')
      },
    },
  }
</script>
