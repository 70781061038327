<template>
  <ul class="nav nav-tabs mb-3">
    <li
      v-for="(f, index) in filters"
      :key="index"
      :class="{'active': f.value === filter }"
    >
      <a @click="handleSelection(f.value)">
        {{ f.label }}
      </a>
    </li>
  </ul>
</template>
<script>
  const FILTERS = [
    { value: 'pending_countersign', label: 'Pending Employer Section'},
    { value: 'pending', label: 'Pending Admin Review of Section 1'},
    { value: 'missing_rejected', label: 'Missing or Rejected'},
    { value: 'awaiting_ssn', label: 'Awaiting SSN'},
    { value: 'reverify', label: 'Expiring Docs'},
    { value: 'countersigned', label: 'Completed'},
    { value: 'paper', label: 'Paper I-9\'s'},
  ]
  export default {
    data () {
      return {
        filters: FILTERS
      }
    },
    methods: {
      handleSelection (value) {
        this.$emit('change', value)
      }
    },
    props: {
      filter: {
        type: String,
        validator: value => {
          return FILTERS.map(f => f.value).includes(value)
        }
      }
    }
  }
</script>
