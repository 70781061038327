// Managing the Back Button and History
//
// The wizard works along with the browser's Back button. The 'Previous' button actually fires the history 'Back'
// event. However, there are some complications:
//
// - This history API is really limited. It will only tell you the current state you're on. You can't manage or
//   clear. It won't even tell you the history state you came from.
//
// - We can never show the 'Forward' button. This is because you could potentially invalidate your current step,
//   making 'Forward' illegal. As mentioned above, there's no ability to clear the forward history, so better just
//   to not show it.
//
// This solution ends up with two history states:
// step: 'wizard_start' - Indicates we're on the first step of the wizard.
// step: 'wizard_middle' - Indicates we're somewhere past the first step of the wizard.
//
// 1. At initialization, the state is replaced with 'wizard_start'.
// History: ['origin', 'wizard_start']
//
// 2. As you move forward, we push the 'wizard_middle' state. **We will only push this once!**
// History: ['origin', 'wizard_start']                  <= step 0
// History: ['origin', 'wizard_start', 'wizard_middle'] <= step 1
// History: ['origin', 'wizard_start', 'wizard_middle'] <= step 2
// History: ['origin', 'wizard_start', 'wizard_middle'] <= step 3
//
// 3. If you click Back or Previous, the browser will be back at 'wizard_start'. At this point, we add
// 'wizard_middle' *again*. This keeps us at the top of the history, disabling 'Forward'.
// History: ['origin', 'wizard_start', 'wizard_middle'] <= starts here
// History: ['origin', 'wizard_start']                  <= back button was clicked, 'wizard_middle' is removed as part of popstate
// History: ['origin', 'wizard_start', 'wizard_middle'] <= we add wizard_middle back
//
// 4. If you go all the way back to the start, we'll add another 'wizard_start'. This seems strange, but it
// puts the state back in charge of it's own destiny: 'wizard_middle' is booted, and we're at the top of history
// again.
// History: ['origin', 'wizard_start', 'wizard_start', 'wizard_start']
//
// 5. If you click 'Back' and the wizard is at the start, we continue to fire back until the wizard is destroyed
// and we no longer receive events.
//


// Wizard History
// Back button history management for a Wizard, or any wizard-like Javascript.
//
// currentStepIdxCallback - Function returning the index of the current wizard step
// backCallback - Callback function to return the wizard to the previous step
// body - Body element to hide when leaving page
//
export default function(currentStepIdxCallback, backCallback, body){
  // Replace the current state that identifies as wizard and indicates that popstate events are handled locally.
  // Save the original state so that we can reuse it later when backing out.
  //
  this._originalHistoryState = history.state
  history.replaceState({step: 'wizard_start', pop_handled_locally: true}, null)

  this._popStateListener = function(event){
    // If the start of the wizard was reached from a popstate event, it means that we've backed all the way out.
    // Since we haven't called a state transition yet, it really means that 'Back' was clicked from the first step.
    //
    // Call history.back() to leave the wizard entirely.
    //
    // If the user returned to the first step several times, they may have accumulated several 'wizard_start'
    // history steps. That's OK- it will just keep running back() until we're out. We hide the view so it doesn't
    // look messy.
    if(currentStepIdxCallback() <= 0){
      // Restore the original history state first. This lets us move forward to the wizard again after backing out.
      history.replaceState(this._originalHistoryState, null)
      body.hide()
      history.back()
    }

    // We've returned to the start of the wizard - or will be, once we call prevStep() below.
    // We have to put 'something' on top of the history stack to prevent the 'Forward' button from showing.
    // So, we'll just push up a duplicate of the state we're on.
    if(currentStepIdxCallback() == 1){
      history.pushState(history.state, null)

    // We're past the first step. Push up a new history state if it hasn't been added already. This prevents us
    // from backing all the way out if 'Back' is pressed.
    // This will actually fire *every time* back is pressed from the middle of the wizard. It always returns to
    // 'wizard_start', then appends 'wizard_middle'. This keeps this history stack clean, and prevents us from
    // ever getting the 'Forward' button.
    } else {
      if(currentStepIdxCallback() > 1 && history.state.step == 'wizard_start'){
        history.pushState({step: 'wizard_middle', pop_handled_locally: true}, null)
      }
    }

    backCallback(event)
  }.bind(this)

  setTimeout(function(){ // Safari on iOS 8.1 calling popstate on load
    window.addEventListener('popstate', this._popStateListener)
  }.bind(this))


  // If we're leaving this page, unset 'pop_handled_locally'
  // This lets us come back with the back button
  this._turbolinksPageFetchListener = function() {
    history.replaceState({step: 'wizard_start', pop_handled_locally: false}, null)
  }.bind(this)
  $(document).on('page:fetch', this._turbolinksPageFetchListener)


  // Push the next wizard step onto history, if necessary
  this.pushNextStep = function(){
    if(history.state.step == 'wizard_start'){
      history.pushState({step: 'wizard_middle', pop_handled_locally: true}, null)
    }
  }

  // Trigger a History Back
  // Just wraps browser history
  this.back = function(){
    history.back()
  }

  // Remove event listeners
  this.cleanup = function(){
    window.removeEventListener('popstate', this._popStateListener)
    $(document).unbind('page:fetch', this._turbolinksPageFetchListener)
  }

  return this
}
