export default Marionette.LayoutView.extend({
  template: false,
  triggers: {
    'change input[type="checkbox"].onboarding-deadline-toggle': 'onboardingDeadlineToggle:change',
    'submit form': {
      event: 'formSubmit',
      stopPropagation: false,
      preventDefault: false
    }
  },

  ui: {
    onboardingDeadlineToggleButton: '.bootstrap-toggle',
    onboardingDeadlineToggleInput: 'input[type="checkbox"].onboarding-deadline-toggle',
    flexibleDateAbsolute: '.flexibledate-absolute',
    flexibleDateRelative: '.flexibledate-relative',

    onboardingDeadlineRegularInput: 'input[type="hidden"]#season_onboarding_regular_deadline',
    onboardingDeadlineRegularRelativeInput: '#season_onboarding_regular_deadline_relative',
    onboardingDeadlineRegularAbsoluteInput: '#season_onboarding_regular_deadline_absolute',

    onboardingDeadlineExtendedInput: 'input[type="hidden"]#season_onboarding_extended_deadline',
    onboardingDeadlineExtendedRelativeInput: '#season_onboarding_extended_deadline_relative',
    onboardingDeadlineExtendedAbsoluteInput: '#season_onboarding_extended_deadline_absolute',
  },

  onRender: function() {
    this.onOnboardingDeadlineToggleChange()
  },

  onOnboardingDeadlineToggleChange: function() {
    var absoluteDate = this.isOnboardingDeadlineAbsoluteDate()
    this.ui.flexibleDateRelative.toggleClass('hidden', absoluteDate)
    this.ui.flexibleDateAbsolute.toggleClass('hidden', !absoluteDate)
  },

  onFormSubmit: function() {
    var absoluteDate = this.isOnboardingDeadlineAbsoluteDate()

    // Generate the actual flexibledate string value
    this.ui.onboardingDeadlineRegularInput.val(
      absoluteDate ? 
        this.ui.onboardingDeadlineRegularAbsoluteInput.val() :
        "+" + this.ui.onboardingDeadlineRegularRelativeInput.val())

    this.ui.onboardingDeadlineExtendedInput.val(
      absoluteDate ? 
        this.ui.onboardingDeadlineExtendedAbsoluteInput.val() :
        "+" + this.ui.onboardingDeadlineExtendedRelativeInput.val())
  },

  isOnboardingDeadlineAbsoluteDate: function() {
    var $toggle = this.ui.onboardingDeadlineToggleInput
    return $toggle.is(':checked')
  }
})
