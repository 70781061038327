var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showInstructions
    ? _c(
        "div",
        [
          _vm.isPendingCountersignActive
            ? [
                _vm.is_i9rc_on
                  ? _c(
                      "div",
                      {
                        ref: "warningPendingCounterSignMessage",
                        staticClass: "alert alert-warning",
                      },
                      [
                        _vm._v(
                          "\n      You have I-9 Remote Verification enabled. These forms will be remotely verified by the staff's chosen authorized representative unless you opt-out of remote verification or choose to sign them yourself.\n    "
                        ),
                      ]
                    )
                  : _c("p", { ref: "pendingCounterSignInstructions" }, [
                      _vm._v(
                        "\n      Select the I-9's you wish complete the Employer section for and click the button below to begin.\n    "
                      ),
                    ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.isMissingRejected
            ? [
                _c("p", { ref: "missingRejectedInstructions" }, [
                  _vm._v(
                    "\n      Form I-9s that haven't been submitted by staff and submissions that have been rejected by administrators.\n    "
                  ),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.isReverifyActive
            ? [
                _c("p", { ref: "reverifyInstructions" }, [
                  _vm._v(
                    "\n      Form I-9 submissions with expiring work authorization document(s) that require Section 2 updates.\n    "
                  ),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("hr"),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }