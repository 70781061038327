<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  import * as mutate from 'vuex/mutation_types'

  import WbModal from 'components/common/WbModal'
  import BulkActionListAlert from 'components/common/BulkActionListAlert'
  import Spinner from 'components/common/Spinner'
  import LaddaButton from 'components/common/LaddaButton'
  import Locale from 'lib/locale'
  import Util from 'lib/util'

  export default {
    components: {
      BulkActionListAlert,
      LaddaButton,
      Spinner,
      WbModal
    },

    data() {
      return {
        message: ''
      }
    },

    computed: {
      collectionViewName() {
        return this.pageContext.collectionViewName || ''
      },

      employees() {
        return this.employeesGetBatch(this.pageContext.activate_employee_ids)
      },

      ...mapGetters({
        pageContext: 'pageContext',
        employeesGetBatch: 'employees/getBatch'
      })
    },

    methods: {
      handleModalHidden() {
        // Reset the data to allow dynamic reuse
        Object.assign(this.$data, this.$options.data())

        // Indicate the modal has closed
        this.$emit('hidden')
      },

      openModal(permittedEmployeeIds) {
        this.setPageContextKeys({ activate_employee_ids: permittedEmployeeIds })
      },

      hide() {
        this.$refs.modal.hide()
      },

      submit() {
        this.$wait.start('employees_change_status')

        this.submitImpl(
          (status, message) => { // success callback
            this.$wait.end('employees_change_status')
            Util.showFlashNotice(message)

            this.employees.forEach((e) => {
              App.globalVent.trigger("employee:status_changed", { id: e.id, status: status })
            })

            this.$nextTick(() => {
              this.$refs.modal.hide()
            })
          },
          (xhr) => { // fail callback
            // Generic error modal displayed automatically.
            // NOTE: On error, the API will provide back a full list of specific errors and the employees that failed. We're
            // currently just resetting one employee at a time, so it's not particularly relevant yet. As the bulk case is used,
            // more specific messaging can be displayed.
            Util.genericAjaxError(Locale.t('errors.ajax.fetch', 'users'), xhr)
            this.$refs.submitButton.stop()
            this.$wait.end('employees_change_status')
          }
        )
      },

      ...mapMutations({
        setPageContextKeys: mutate.SET_PAGE_CONTEXT_KEYS,
      }),

      ...mapActions({
        activateEmployees: 'employees/activate',
        deactivateEmployees: 'employees/deactivate'
      })
    }
  }
</script>
