var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "table",
    { ref: "inboxTable", staticClass: "table table-striped" },
    [
      _c("thead", [
        _c("tr", [
          _c(
            "th",
            {
              staticClass: "sortable",
              class: _vm.getSortClassName("staff_member"),
              attrs: { role: "button" },
              on: {
                click: function ($event) {
                  return _vm.handleSortAction("staff_member")
                },
              },
            },
            [_vm._v("\n        Staff Member\n      ")]
          ),
          _vm._v(" "),
          _c(
            "th",
            {
              staticClass: "sortable",
              class: _vm.getSortClassName("form_name"),
              attrs: { role: "button" },
              on: {
                click: function ($event) {
                  return _vm.handleSortAction("form_name")
                },
              },
            },
            [_vm._v("\n        Form Name\n      ")]
          ),
          _vm._v(" "),
          _c(
            "th",
            {
              staticClass: "sortable",
              class: _vm.getSortClassName("type"),
              attrs: { role: "button" },
              on: {
                click: function ($event) {
                  return _vm.handleSortAction("type")
                },
              },
            },
            [_vm._v("\n        Type\n      ")]
          ),
          _vm._v(" "),
          _c(
            "th",
            {
              staticClass: "sortable text-right",
              class: _vm.getSortClassName("received"),
              attrs: { role: "button" },
              on: {
                click: function ($event) {
                  return _vm.handleSortAction("received")
                },
              },
            },
            [_vm._v("\n        Received\n      ")]
          ),
          _vm._v(" "),
          _vm._m(0),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "tbody",
        _vm._l(_vm.submissions, function (submission, index) {
          return _c("DocumentSubmissionsInboxTableRow", {
            key: index,
            attrs: { submission: submission },
            on: { submissionSelected: _vm.handleSubmissionSelected },
          })
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", [
      _c("span", { staticClass: "sr-only" }, [_vm._v("Review Submission")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }