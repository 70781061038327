/*
*
* Radio button field that will display Other option field if exists
*
*/
<template>
  <form-field
    :label="label"
    :name="name"
    :orientation="orientation"
    :required="required"
    :errors="errors"
    :hint="hint"
  >
    <select class="form-control"
      :value="value"
      :disabled="disabled"
      @change="$emit('input', $event.target.value)"
    >
      <option v-for="(option, index) in options" v-bind:value="option.value" v-bind:key="index">
        {{ option.label }}
      </option>
    </select>
  </form-field>
</template>

<script>
  import FormField from 'components/common/FormField'
  import FormFieldConcern from 'mixins/FormFieldConcern'

  export default {
    name: 'form-field-dropdown',
    mixins: [FormFieldConcern],
    components: {
      FormField
    },

    props: {
      value: {
        type: String,
        required: false
      },

      options: {
        type: Array,
        required: true
      },
    }
  }
</script>
