var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "i9ActionButtonsContainer", staticClass: "d-flex pull-left mb-1" },
    [
      _c(
        "WbButton",
        {
          ref: "signSection2Button",
          staticClass: "mr-1",
          attrs: { disabled: _vm.isSection2Disabled },
          on: { click: _vm.signSection2 },
        },
        [
          _c("WbIcon", {
            staticClass: "fa-fw fa-lg",
            attrs: { iconSet: "a4s-icon", type: "signature-pen" },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "btn-title" }, [
            _vm._v("\n      " + _vm._s(_vm.signSection2ButtonText) + "\n    "),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "WbButton",
        {
          ref: "editSection2Button",
          staticClass: "mr-1",
          attrs: { context: "default", disabled: _vm.isSection2Disabled },
          on: { click: _vm.editSection2 },
        },
        [
          _c("WbIcon", { attrs: { iconSet: "fa", type: "cog" } }),
          _vm._v(" "),
          _c("span", { staticClass: "btn-title" }, [
            _vm._v("\n      " + _vm._s(_vm.editSection2ButtonText) + "\n    "),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "WbButton",
        {
          ref: "requestNewI9Button",
          attrs: { context: "default", disabled: _vm.isSection2Disabled },
          on: { click: _vm.requestNewI9 },
        },
        [
          _c("WbIcon", {
            staticClass: "fa-fw fa-lg",
            attrs: { iconSet: "a4s-icon", type: "archived-file" },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "btn-title" }, [
            _vm._v("\n      " + _vm._s(_vm.requestNewI9ButtonText) + "\n    "),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }