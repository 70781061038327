import Auth from 'lib/util/auth'


// Utility methods to deal with DataTables stuff
export default {
  // If it's necessary to check field-level authorization (i.e. in cases where data from the backend could potentially
  // come back as blocked content), override the DataTables column `render` function with a call to this method. 
  // 
  // The arguments here mirror what `columns.render` takes (see https://datatables.net/reference/option/columns.render),
  // so that this can be used as a drop-in replacement, with one additional, optional argument:
  //  
  // displayFormatterFn - (function(value, row, meta)) Callback function which will be called in order to format 
  //                      the value prior to display when the content is not restricted.
  //
  // Returns - String
  renderWithAuth: function(data, type, row, meta, displayFormatterFn) {
    if (Auth.isBlockedContent(data)) {
      if (type === 'display') {
        return Auth.blockedContentTag()
      } else {
        // For non-display needs (i.e. filtering or sorting), give a dummy value to DT
        return '_restricted' 
      }
    } else {
      if (type === 'display' && _.isFunction(displayFormatterFn)) {
        return displayFormatterFn.call(this, data, row, meta)
      } else {
        return data
      }
    }
  },

  // FIXME: Everything below this line has not been reviewed for modularization:
  //------------------------------------

  attachFilterPopoverView: function($dataTable, $filterToolbarTemplate, $filterContainer, options) {
    // Render filter area
    var $filterEl = $dataTable.closest(".dataTables_wrapper").find(".dt-custom-filter")
    $filterEl.html($filterToolbarTemplate.html())

    if (options && Marionette.getOption(options, 'class')) {
      $filterEl.addClass(Marionette.getOption(options, 'class'))
    }

    // Init the popover using the embedded .filter-sheet-tmpl template
    var popoverLink = $filterEl.find(".popover-link")
    popoverLink.popover({
      html: true,
      placement: 'bottom',
      content: $filterContainer,
      trigger: 'click',
      //title: '<a href="javascript:void(0)" data-dismiss="popover" class="close">&times;</a><div class="clearfix"></div>'
    })

    $filterContainer.on('submit', 'form', function(e) {
      e.stopPropagation()
      e.preventDefault()
      $dataTable.DataTable({ retrieve: true }).draw()
      popoverLink.popover('hide')
    })

    $filterContainer.on('reset', 'form', function(e) {
      $(this).find('select.select2').val('')   // Manually clear select2 inputs
      $(this).find("input[type='checkbox']").attr('checked', false)    // Form reset does not immediately unset checked attr

      $dataTable.DataTable({ retrieve: true }).draw()
    })

    $filterContainer.on('click', 'a.close', function(e) {
      popoverLink.popover('hide')
    })

    return popoverLink
  },

  toggleRowChecked: function($row, value) {
    if (value === undefined)
      $row.toggleClass("highlight")
    else
      $row.toggleClass("highlight", value)

    var checked = $row.hasClass("highlight")  // value may be undefined, so use the existance of the class (set above) as indicator
    $row.find("input[type='checkbox']").each(function() {
      $(this).checked = checked
      $(this).prop('checked', checked)
      $(this).prop('value', checked ? "1" : "0")
    })
  },

  isRowChecked: function($row) {
    return $row.find("input[type='checkbox']").is(':checked')
  },

  // FIXME: Remove. This is not necessary. Use CSS. 
  singleLineData: function($row) {
    $($row).addClass('single-line-data')
  },
}
