var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("p", { ref: "accessDetails" }, [
        _vm._v("\n    You are signed in as\n    "),
        _c("strong", [_vm._v(_vm._s(_vm.currentUserEmail))]),
        _vm._v(" "),
        _vm.hasSuperadminRole
          ? _c("span", { staticClass: "label label-success" }, [
              _vm._v("superadmin"),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _vm.searchVisible
        ? _c(
            "div",
            { staticClass: "tenant-search-container breathe above deep" },
            [
              _c("h4", { staticClass: "text-center" }, [
                _vm._v("Search for Organization"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-8 col-sm-offset-2" }, [
                  _c("input", {
                    staticClass: "tenant-search form-control",
                    attrs: {
                      autofocus: "autofocus",
                      type: "text",
                      placeholder: "Organization Name or Subdomain",
                    },
                    on: { input: _vm.setQuery },
                  }),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-wait",
        { attrs: { for: "fetch_associated_accounts" } },
        [
          _c("spinner", { attrs: { slot: "waiting" }, slot: "waiting" }),
          _vm._v(" "),
          _vm.shouldShowNoResultsText
            ? _c(
                "div",
                {
                  ref: "noResults",
                  staticClass: "tenant-search-no-results text-center",
                },
                [_vm._v("\n      No results found\n    ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("tenant-select-results-list", {
        attrs: { "query-results": _vm.results },
      }),
      _vm._v(" "),
      _vm.hasSuperadminRole ? _c("account-access-reason") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }