var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", [
    _c("td", [
      _vm._v("\n    " + _vm._s(_vm.policy.name) + "\n    "),
      _vm.policy.is_default
        ? _c(
            "span",
            { ref: "defaultLabel", staticClass: "label label-default" },
            [_vm._v("\n      Default\n    ")]
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("td", { ref: "count", staticClass: "text-right" }, [
      _vm._v("\n    " + _vm._s(_vm.policyCount) + "\n  "),
    ]),
    _vm._v(" "),
    _c(
      "td",
      { staticClass: "text-right" },
      [
        _c(
          "WbButton",
          {
            staticClass: "btn-sm",
            attrs: { context: "default" },
            on: {
              click: function ($event) {
                return _vm.handlePreviewClick(_vm.policy.id)
              },
            },
          },
          [_vm._v("\n      Preview\n    ")]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("td", [
      _c(
        "div",
        { staticClass: "dropdown" },
        [
          _c(
            "WbButton",
            {
              staticClass: "btn-sm dropdown-toggle",
              attrs: { context: "default", "data-toggle": "dropdown" },
            },
            [
              _c("WbIcon", {
                staticClass: "fa-fw right",
                attrs: { type: "caret-down" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("ul", { staticClass: "dropdown-menu" }, [
            _c("li", [
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.$routes.managed_access_policy_template_edit_path({
                      id: _vm.policy.id,
                    }),
                  },
                },
                [_vm._v("Edit")]
              ),
            ]),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }