var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("strong", { staticClass: "pt-1" }, [_vm._v(_vm._s(_vm.header))]),
    _vm._v(" "),
    _c("div", { staticClass: "w-100 text-center pb-4" }, [
      _c(
        "div",
        [
          _c("strong", { staticClass: "mb-1" }, [_vm._v("Front")]),
          _vm._v(" "),
          _vm.photoFrontUrl
            ? _c("FileViewer", { attrs: { fileUrl: _vm.photoFrontUrl } })
            : _c("div", { staticClass: "img-not-available" }, [
                _vm._v("\n        Not Available\n      "),
              ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.shouldShowBack
        ? _c(
            "div",
            [
              _c("strong", { staticClass: "mb-1" }, [_vm._v("Back")]),
              _vm._v(" "),
              _vm.photoBackUrl
                ? _c("FileViewer", { attrs: { fileUrl: _vm.photoBackUrl } })
                : _c("div", { staticClass: "img-not-available" }, [
                    _vm._v("\n        Not Available\n      "),
                  ]),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }