import ProfileInfoPanelView from 'components/employees/ProfileInfoPanelView'
import PrerenderedItemView from 'views/common/prerendered_item_view'
import EditProfileInfoModalView from 'views/employees/edit_profile_info_modal_view'
import { pureOrphanVue } from 'lib/vue/pureOrphanVue'

export default PrerenderedItemView.extend({
  triggers: {
    'click .js-employee-profile-edit-button': "employee:edit:profile"
  },

  initialize: function() {
    this.stickit()
    $.runInitializers(this.$el) // Tooltip

    pureOrphanVue(ProfileInfoPanelView, "component.vue-employee-profile-info-table")
  },

  onEmployeeEditProfile: function() {
    new EditProfileInfoModalView({
      ajaxOptions: {
        wbGenericFailureMsg: "Sorry, we experienced a problem opening the Edit window.",
      },
      model: this.model,
      _url: `${App.Util.getPageRootPath()}/edit`,
    })
  }
})
