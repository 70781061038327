var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("Spinner", { ref: "spinner" })
        : _vm.hasDeviceAccess
        ? _c("div", { staticClass: "mb-3" }, [
            _c("label", [_vm._v("\n      Video Source:\n    ")]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedDeviceIndex,
                    expression: "selectedDeviceIndex",
                  },
                ],
                ref: "videoSources",
                staticClass: "form-control",
                attrs: { name: "mediaSource" },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.selectedDeviceIndex = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c("option", [_vm._v("Select a device")]),
                _vm._v(" "),
                _vm._l(_vm.inputDevices, function (device, index) {
                  return _c(
                    "option",
                    { key: index, domProps: { value: index } },
                    [_vm._v("\n        " + _vm._s(device.label) + "\n      ")]
                  )
                }),
              ],
              2
            ),
          ])
        : _c("div", { ref: "noDeviceAccess" }, [
            _vm._v(
              "\n    Unable to access your device's camera. Please enable use of your camera within your browser and press Retry or press Cancel to upload an image.\n    "
            ),
            _c(
              "div",
              { staticClass: "mt-3" },
              [
                _c("WbButton", { on: { click: _vm.setInputDevices } }, [
                  _vm._v("\n        Retry\n      "),
                ]),
                _vm._v(" "),
                _c(
                  "WbButton",
                  {
                    attrs: { context: "link" },
                    on: { click: _vm.handleCaptureCancel },
                  },
                  [_vm._v("\n        Cancel\n      ")]
                ),
              ],
              1
            ),
          ]),
      _vm._v(" "),
      _vm.hasSelectedDevice
        ? _c(
            "div",
            { ref: "mediaContainer" },
            [
              _c("canvas", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isPhotoTaken,
                    expression: "isPhotoTaken",
                  },
                ],
                ref: "canvas",
                staticClass: "img-responsive mb-3",
              }),
              _vm._v(" "),
              _c("video", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isPhotoTaken,
                    expression: "!isPhotoTaken",
                  },
                ],
                ref: "camera",
                staticClass: "img-responsive mb-3",
                attrs: { autoplay: "", muted: "", playsinline: "" },
                domProps: { muted: true },
              }),
              _vm._v(" "),
              _c(
                "Toolbar",
                [
                  _c(
                    "div",
                    [
                      _vm.isPhotoTaken
                        ? [
                            _c(
                              "WbButton",
                              {
                                ref: "recapture",
                                attrs: {
                                  context: "default",
                                  size: "sm",
                                  tooltip: "Re-capture Photo",
                                },
                                on: { click: _vm.handleRetakeClick },
                              },
                              [
                                _c("WbIcon", {
                                  staticClass: "mr-0",
                                  attrs: { type: "refresh" },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "WbButton",
                              {
                                ref: "save",
                                attrs: {
                                  context: "success",
                                  size: "sm",
                                  tooltip: "Use this photo",
                                },
                                on: { click: _vm.handleSavePhoto },
                              },
                              [
                                _c("WbIcon", {
                                  staticClass: "mr-0",
                                  attrs: { type: "check" },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _c(
                            "WbButton",
                            {
                              ref: "capture",
                              attrs: {
                                context: "success",
                                size: "sm",
                                tooltip: "Capture Photo",
                              },
                              on: { click: _vm.handleCaptureImage },
                            },
                            [
                              _c("WbIcon", {
                                staticClass: "mr-0",
                                attrs: { type: "camera" },
                              }),
                            ],
                            1
                          ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "WbButton",
                    {
                      ref: "cancel",
                      attrs: {
                        context: "danger",
                        size: "sm",
                        tooltip: "Cancel",
                      },
                      on: { click: _vm.handleCaptureCancel },
                    },
                    [
                      _c("WbIcon", {
                        staticClass: "mr-0",
                        attrs: { type: "close" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }