var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeader", {
        attrs: {
          title: "Admin Permissions",
          lead: "Manage the administrators permission policies for your company's WorkBright account. Create or modify administrator policies to customize access.",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "btn-group my-3" }, [
        _c(
          "a",
          {
            staticClass: "btn btn-primary",
            attrs: {
              href: _vm.$routes.managed_access_policy_template_new_path,
            },
          },
          [
            _c("WbIcon", {
              attrs: { slot: "prepend", type: "plus-circle" },
              slot: "prepend",
            }),
            _vm._v("\n      New Policy\n    "),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      [
        _vm.isLoading
          ? _c("Spinner", { ref: "loader" })
          : [
              _vm.hasSufficientData
                ? [
                    _c("ManagedAccessPolicyTemplatesTable", {
                      attrs: { policies: _vm.policies },
                      on: { policySelected: _vm.handlePolicySelected },
                    }),
                    _vm._v(" "),
                    _c("WbListPaginationFooter", {
                      ref: "pagination",
                      attrs: {
                        previousPage: _vm.pagination.previous_page,
                        nextPage: _vm.pagination.next_page,
                        lastPage: _vm.pagination.total_pages,
                        totalItems: _vm.pagination.total_items,
                        indexStart: _vm.indexStart,
                        indexEnd: _vm.indexEnd,
                      },
                      on: { pageChange: _vm.handlePageChange },
                    }),
                  ]
                : _c("AlertMessage", {
                    ref: "noDataMessage",
                    attrs: {
                      alertType: "info",
                      message: "There are currently no policies to display",
                    },
                  }),
            ],
      ],
      _vm._v(" "),
      _c("ManagedAccessPolicyTemplatesSlideout", {
        ref: "ManagedAccessPolicyTemplatesSlideout",
        attrs: { shown: _vm.shouldShowModal, policy: _vm.selectedPolicy },
        on: { hidden: _vm.handleModalHiddden },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }