var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form-field",
    { attrs: { label: _vm.label, orientation: "horizontal" } },
    [
      _c("div", { staticClass: "form-control-static block-translate" }, [
        _c("strong", [_vm._v("\n      " + _vm._s(_vm.value) + "\n    ")]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }