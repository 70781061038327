var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-md-3" },
      [
        _c("h4", { staticClass: "text-strong" }, [
          _vm._v("\n      Sort by\n    "),
        ]),
        _vm._v(" "),
        _c("radio-toggle", {
          attrs: {
            title: _vm.sortBy.title,
            value: _vm.sortBy.value,
            options: _vm.sortBy.options,
            buttonSize: "btn-sm",
          },
          on: {
            selected: function (selection) {
              this$1.sortBy.value = selection
            },
          },
        }),
        _vm._v(" "),
        _c("list-filter-set", {
          staticClass: "pt-4 mt-4",
          attrs: {
            filters: _vm.filters,
            collectionViewName: _vm.collectionViewName,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-9" }, [
      _c(
        "div",
        { staticClass: "wb-list assignment-list" },
        [
          _c(
            "wb-list-header",
            {
              attrs: {
                filteredRowIds: _vm.filteredAssignmentIdsForBulkActions,
                collectionViewName: _vm.collectionViewName,
                searchPlaceholder: "Search Form Name...",
                selectable: true,
              },
            },
            [
              _c("template", { slot: "bulkActions" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-md btn-primary",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.handleReassign($event)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n            Request New Submission(s)\n          "
                    ),
                  ]
                ),
              ]),
            ],
            2
          ),
          _vm._v(" "),
          _vm.activeAssignments.length > 0 &&
          _vm.filteredAssignments.length == 0
            ? _c("p", { staticClass: "m-4" }, [
                _vm._v("\n        No matching assigned forms\n      "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.activeAssignments.length == 0
            ? _c("p", { staticClass: "m-4" }, [
                _vm._v(
                  "\n        No assigned forms exist for this staff member\n      "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "list-group" },
            _vm._l(_vm.filteredAssignments, function (assignment) {
              return _c("document-assignment-list-item", {
                key: assignment.id,
                attrs: {
                  assignment: assignment,
                  selectable: true,
                  collectionViewName: _vm.collectionViewName,
                },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "my-4" }, [
            _c(
              "p",
              [
                _c("span", { staticClass: "lead" }, [
                  _vm._v("\n            Other Forms on File\n          "),
                ]),
                _vm._v(" "),
                _c("wb-button", {
                  ref: "btnUpload",
                  staticClass: "btn-sm ml-4",
                  attrs: {
                    context: "info",
                    label: "Upload",
                    disabled: !_vm.employed,
                  },
                  on: { click: _vm.clickUploadSubmission },
                }),
                _vm._v(" "),
                !_vm.employed
                  ? _c("PopoverIcon", {
                      attrs: {
                        title: "Past-season staff",
                        message:
                          "Submissions cannot be uploaded for past-season staff",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.otherAssignments.length > 0 &&
          _vm.filteredOtherAssignments.length == 0
            ? _c("p", { staticClass: "m-4" }, [
                _vm._v("\n        No matching other forms\n      "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.otherAssignments.length == 0
            ? _c("p", { staticClass: "m-4" }, [
                _vm._v(
                  "\n        No other forms exist for this staff member\n      "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "list-group" },
            _vm._l(_vm.filteredOtherAssignments, function (assignment) {
              return _c("document-assignment-list-item", {
                key: assignment.id,
                attrs: {
                  assignment: assignment,
                  collectionViewName: _vm.collectionViewName,
                },
              })
            }),
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }