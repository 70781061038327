import Routes from 'lib/routes'
import Dialogs from 'lib/util/dialogs'
import Util from 'lib/util'

// Announcement Table Row
//
// NOTE: This can probably be refactored into a common Backbone DataTable row when this pattern is reused. This could
// encapsulate shared functionality, like building the Action Links and Row Selection
export default Marionette.LayoutView.extend({
  template: false,
  ui: {
    deleteLink: '.js-delete'
  },
  events: {
    'click @ui.deleteLink': 'handleDeleteClick',
    'click @ui.rowSelectCheckbox': 'handleRowSelectToggle'
  },
  regions: {
    actionLinksRegion: '.row-action-links',
  },

  onBeforeDestroy: function() {
    this.actionLinksView.destroy()
  },

  onRender: function(){
    // Init the Action Links
    this.actionLinksView = new (Marionette.ItemView.extend({template: '#announcements-action-links-tmpl'}))({model: this.model})
    this.actionLinksRegion.show(this.actionLinksView)
  },

  handleDeleteClick: function(){
    var options = {
      confirmMessage: 'This will destroy this announcement. Are you sure this is what you want to do?',
      confirmBtnLabel: 'Delete Announcement'
    }

    Dialogs.confirmation(options, () => {
      this.model.destroy({
        wait: true,
        url: Routes.superadmin_announcement_path({id: this.model.get('id')}),
        wbGenericFailureMsg: "Sorry, we couldn't remove this announcement.",
      })
    })
  }
})
