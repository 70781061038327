var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "textarea", class: { "has-error": _vm.hasError } },
    [
      _c("div", { staticClass: "input-wrapper textarea" }, [
        _c(
          "label",
          [
            _vm._t("default"),
            _vm._v("\n      " + _vm._s(_vm.label) + "\n    "),
          ],
          2
        ),
        _vm._v(" "),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.localValue,
              expression: "localValue",
            },
          ],
          ref: "input",
          staticClass: "text form-control",
          attrs: { name: _vm.name, id: _vm.name, placeholder: _vm.placeholder },
          domProps: { value: _vm.localValue },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.localValue = $event.target.value
              },
              function ($event) {
                return _vm.$emit("input", _vm.localValue)
              },
            ],
          },
        }),
      ]),
      _vm._v(" "),
      _vm.hasError
        ? _c("small", { staticClass: "help-block" }, [
            _vm._v("\n    " + _vm._s(_vm.errorMessage) + "\n  "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hint
        ? _c("small", { staticClass: "help-block" }, [
            _vm._v("\n    " + _vm._s(_vm.hint) + "\n  "),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }