import Routes from 'lib/routes'

// Marionette View for building a Backbone Collection-powered DataTable
// DataTable refreshes in response to Backbone Collection updates
//
// Options:
// collection:          Backbone Collection to display
// rowViewType:         Marionette View class to apply to each table row
// dataTableOptions: {
//  columns:            Array, DataTable columns Options
//  ...:                Any other DataTable options
//}
// toolbarView:         (optional) Marionette View for Toolbar region
// emptyMessage:        (optional) Message to display collection is empty
//
export default Marionette.LayoutView.extend({
  template: false,
  ui: {
    table: 'table'
  },
  regions: {
    'toolbarRegion': '.dt-toolbar',
  },

  onRender: function() {
    this.rowViews = [] // keep track of row views to destroy
    this.initDataTable()
    this.initToolbar()
  },

  onBeforeDestroy: function() {
    Marionette.TemplateCache.clear(this.getOption('toolbarTemplate'))
    this.destroyTableRowViews()
  },

  // Destroy any Marionette views we've created for the rows
  destroyTableRowViews: function() {
    this.rowViews.forEach( (actionLinkView) => { actionLinkView.destroy() })
    this.rowViews = []
  },

  // Initialize the Toolbar
  // Applies the toolbar view to the toolbar region, if present
  initToolbar: function() {
    var toolbarView = this.getOption('toolbarView')
    if(toolbarView){
      this.toolbarRegion.show(toolbarView)
      toolbarView.prerendered = true // after showing, turn on prerendered so it doesn't get removed from the view
    }
  },

  // Initialize the DataTable
  // Binds Backbone Collection updates to reload
  initDataTable: function() {
    // Set up the default DataTable config options
    let dataTableOptions = {
      retrieve: true,
      destroy: true,

      // Using DataTables AJAX methods to hook into our Backbone Collection
      ajax: (data, callback, settings) => {
        this.destroyTableRowViews()
        callback({data: this.collection.toJSON()})
      },

      // Row Created Callback - Bind Action Links
      createdRow: (row, data, dataIndex) => {
        // Find the Backbone Model for this row
        let model = this.collection.findWhere({id: parseInt(data.id)})

        // Attach the Row's Marionette ItemView (specific to the table) to hte model, and render it
        const rowViewType = this.getOption('rowViewType')
        const actionViewLinksView = new rowViewType({model: model})
        actionViewLinksView.setElement(row)
        actionViewLinksView.render()

        this.rowViews.push(actionViewLinksView) // Keep track of to destroy later
      },
      order: [],
      language: {
        emptyTable: '<div class="text-center"><h4>' + (this.getOption('emptyMessage') || 'No Items to Show') + '</h4></div>',
      },
    }

    // Add any extra DataTable config options passed in "dataTableOptions"
    // This includes the 'columns' field
    const additionalOptions = this.getOption('dataTableOptions')
    dataTableOptions = $.extend(dataTableOptions, additionalOptions)

    // Build the DataTable
    let dataTable = this.ui.table.DataTable(dataTableOptions)

    // Bind to changes on collection
    // Could add some other bindings here: Add, Change, ...
    this.collection.on('remove', () => { dataTable.ajax.reload() })
  },
})
