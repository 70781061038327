var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "wb-modal",
        {
          ref: "modal",
          attrs: {
            id: "bulk-update-custom-fields-container-modal",
            title: "Update a Custom Field",
            watchPageContextVariable: _vm.collectionViewName,
            locked: _vm.loading,
          },
          on: {
            modalHidden: function ($event) {
              return _vm.$emit("hidden")
            },
          },
        },
        [
          _c(
            "v-wait",
            { attrs: { for: _vm.loadingName } },
            [
              _c("spinner", {
                attrs: { slot: "waiting", showLongWaitMessage: true },
                slot: "waiting",
              }),
              _vm._v(" "),
              _c(_vm.currentStepComponentName, {
                ref: "currentStep",
                tag: "component",
                attrs: {
                  "nav-direction": _vm.navDirection,
                  employeeIds: _vm.employeeIds,
                  collectionViewName: _vm.collectionViewName,
                  permissionName: _vm.permissionName,
                  field: _vm.field,
                  errors: _vm.errors,
                },
                on: {
                  skip: _vm.skipStep,
                  updateData: _vm.handleStepUpdateData,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-xs-4 text-left" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { disabled: _vm.loading },
                    on: { click: _vm.hide },
                  },
                  [_vm._v("Cancel")]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-xs-4" }),
              _vm._v(" "),
              _c("div", { staticClass: "col-xs-4 text-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary next",
                    attrs: {
                      "data-style": "expand-left",
                      "data-disable": "true",
                      disabled: _vm.loading,
                    },
                    on: { click: _vm.nextStep },
                  },
                  [_vm._v(_vm._s(_vm.nextLabel))]
                ),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }