var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    { staticClass: "btn btn-default btn-sm", attrs: { href: _vm.url } },
    [
      _c("i", { staticClass: "fa fa-level-up fa-rotate-270" }),
      _vm._v("\n  " + _vm._s(_vm.label) + "\n"),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }