var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "historical-submissions-list",
      class: { "with-indicators": _vm.hasIndicators },
    },
    _vm._l(_vm.submissionDateGroups, function (submissionDateGroup) {
      return _c(
        "div",
        { key: submissionDateGroup.year, staticClass: "year-group" },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v(_vm._s(submissionDateGroup.year)),
          ]),
          _vm._v(" "),
          submissionDateGroup.submissions.length == 0
            ? _c("div", { staticClass: "text-center" }, [
                _vm._v("\n      No submissions this year\n    "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "list-group wb-list" },
            _vm._l(submissionDateGroup.submissions, function (submission) {
              return _c(
                "list-item",
                {
                  key: submission.id,
                  class: {
                    "currently-viewed":
                      _vm.visibleSubmissionId == submission.id,
                  },
                  attrs: {
                    href: _vm.$routes.admin_employee_submission_path({
                      employee_id: _vm.pageContext.employee.id,
                      id: submission.id,
                    }),
                    item: submission.id,
                    secondaryActions: _vm.pageContext.secondaryActionsEnabled,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "wb-list__content" },
                    [
                      _c("submission-status-indicator", {
                        attrs: { submission: submission },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "wb-list__title-slideout" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.formatDate(submission.submitted_at)) +
                            "\n            "
                        ),
                        _c("br"),
                        _vm._v(" "),
                        _c("small", [
                          _vm._v(
                            "\n              Uploaded by " +
                              _vm._s(submission.uploaded_by_full_name) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("current-submission-badge", {
                              attrs: { submissionId: submission.id },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.visibleSubmissionId == submission.id
                          ? _c("div", [
                              _c(
                                "span",
                                { staticClass: "label label-default" },
                                [
                                  _vm._v(
                                    "\n                Submission you are currently viewing\n              "
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("dropdown-list-item", {
                    attrs: {
                      slot: "secondaryActionsMenuItems",
                      title: "Set as Current Submission",
                      disabled:
                        _vm.isCurrentSubmission(submission) ||
                        submission.status !== "accepted",
                      permissionModel: "documents",
                      permissionRecordId: submission.document_id,
                      permissionAction: "upload_submission",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleSetAsCurrentClick(submission)
                      },
                    },
                    slot: "secondaryActionsMenuItems",
                  }),
                ],
                1
              )
            }),
            1
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }