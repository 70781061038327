// Individual Assignment Status
<template>
  <ListItem
    :class="{ 'submission-missing': !currentSubmission }"
    :href="href"
    :selectable="selectable"
    :collectionViewName="collectionViewName"
    :item="assignment.id"
    :secondaryActions="includeSecondaryActionMenu"
    :defaultSelected="defaultSelected"
    :disabled="disabled"
  >
    <!-- / slot -->
    <div class="wb-list__content">
      <SubmissionStatusIndicator :submission="currentSubmission" :status="assignmentStatus" />
      <div class="wb-list__title mr-auto my-0">
        <span class="name" ref="docName">
          {{ currentDocument.name }}
        </span>
        <span class="label label-default ml-2" v-if="currentDocument.internal" ref="internalLabel">
          Internal
        </span>
        <span class="label label-default ml-2" v-if="assignment.optional">
          Optional
        </span>
      </div>
      <div class="wb-list__column-groups" v-if="includeGroupsColumn">
        <a class="mx-2" v-tooltip.html="groupsTooltip" href="javascript:void(0)" v-if="groupNames.length">
          {{ pluralizedGroupNames }}
        </a>
        <div class="mx-2 text-muted" v-if="!groupNames.length">
          --
        </div>
      </div>
      <div class="wb-list__column-timestamp">
        <WbSimpleDateTime
          :value="currentSubmission.submitted_at"
          :tooltip-options="submittedAtTooltip"
          v-if="currentSubmission && currentSubmission.submitted_at"
          ref="submittedIndicator"
        />
        <em v-if="!currentSubmission">
          No current submission
        </em>
      </div>
    </div>
    <template slot="secondaryActionsMenuItems">
      <DropdownListItem
        title="Upload..."
        :href="newAdminEmployeeDocumentSubmissionPath"
        :permission-record-id="assignment.id"
        permission-model="document_assignments"
        permission-action="upload_submission"
      />
      <li class="divider" />
      <DropdownListItem
        v-if="assignment.active"
        title="Request new submission"
        :disabled="!this.assignment.active"
        @click="handleResetClick()"
        :permission-record-id="assignment.id"
        permission-model="document_assignments"
        permission-action="unlock_submission"
        ref="requestNew"
      />
      <DropdownListItem
        v-if="hasAuditTrail"
        title="View audit events"
        @click="handleAuditLogClick()"
      />
      <DropdownListItem
        :disabled="assignment.historical_submission_count == 0"
        @click="handleViewHistoryClick(assignment)"
        ref="historicalSubmissions"
      >
        <span>View all submissions</span>
        <div class="badge badge-light" v-if="assignment.historical_submission_count > 0">
          {{ assignment.historical_submission_count }}
        </div>
      </DropdownListItem>
    </template>
  </ListItem>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex'
  import * as mutate from 'vuex/mutation_types'
  import CollectionViewStore from 'vuex/modules/collection_view_store'
  import DateTime from 'lib/util/datetime'
  import ListItem from 'components/common/ListItem'
  import SubmissionStatusIndicator from 'components/common/SubmissionStatusIndicator'
  import WbSimpleDateTime from 'components/common/WbSimpleDateTime'
  import DropdownListItem from 'components/common/DropdownListItem'
  import StringUtil from 'lib/util/string'
  import { getRoute } from 'lib/routes'

  export default {
    name: 'document-assignment-list-item',

    components: {
      DropdownListItem,
      ListItem,
      SubmissionStatusIndicator,
      WbSimpleDateTime,
    },
    props: {
      assignment: {
        type: Object,
        required: true,
      },
      selectable: {
        type: Boolean,
        default: false,
      },
      groups: {
        type: Array,
        required: false,
      },
      selectedGroupIds: {
        type: Array,
        required: false,
      },
      collectionViewName: {
        type: String,
        required: false,
      },
      includeSecondaryActionMenu: {
        type: Boolean,
        required: false,
        default: true,
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      defaultSelected: {
        type: Boolean,
        required: false,
        default: false,
      },
      includeGroupsColumn: {
        type: Boolean,
        required: false,
        default: false,
      },
      clickableRow: {
        type: Boolean,
        required: false,
        default: true,
      },
      pendingStatus: {
        type: String,
        required: false,
      },
    },

    computed: {
      newAdminEmployeeDocumentSubmissionPath () {
        return getRoute('new_admin_employee_document_submission_path', { 
          employee_id: this.pageContext.employee.id,
          document_id: this.assignment.document_id,
        })
      },
      href() {
        if (!this.clickableRow) { return void(0) }

        return this.assignment.current_submission_id ?
          getRoute('admin_employee_submission_path', {
            employee_id: this.pageContext.employee.id,
            id: this.assignment.current_submission_id,
          }) :
          void(0)
      },

      pluralizedGroupNames () {
        return StringUtil.pluralize(this.groupNames.length, 'Group')
      },

      submittedAtTooltip() {
        if (!this.currentSubmission) { return {} };

        return {
          title: `Uploaded by ${_.escape(this.currentSubmission.uploaded_by_full_name)} on<br/>
                  ${DateTime.formatDateTimeExt(this.currentSubmission.status_changed_at)}`,
          html: true,
        }
      },

      currentSubmission() {
        if (!this.assignment.current_submission_id) {
          return null
        }

        return this.getSubmission(this.assignment.current_submission_id)
      },

      currentDocument() {
        return this.getDocument(this.assignment.document_id)
      },

      documentGroupIds() {
        return this.currentDocument.group_ids
      },

      groupNames() {
        if (!this.groups) { return }
        return this.groups.filter(g => this.documentGroupIds.includes(g.value) && this.selectedGroupIds.includes(g.value)).map(g => g.label)
      },

      groupsTooltip() {
        return this.groupNames.map(name => {
          return `<li style="text-align: left;">${name}</li>`
        }).join('')
      },

      assignmentStatus() {
        return this.pendingStatus || this.assignment.status
      },

      singleCollectionViewName() {
        return `collection_view_assignment_${this.assignment.id}`
      },

      hasAuditTrail() {
        return this.currentSubmission && this.currentSubmission.audit_logs && this.currentSubmission.audit_logs.length
      },

      ...mapGetters({
        pageContext: 'pageContext',
        getSubmission: 'document_submissions/get',
        getDocument: 'documents/get',
      }),
    },

    beforeDestroy() {
      // Clear selected BEFORE we unregister the dynamically created module
      if (this.singleStoreCreated()) {
        this.$store.dispatch(`${this.singleCollectionViewName}/reset`)
        this.$store.unregisterModule(this.singleCollectionViewName)
      }
    },

    methods: {
      ...mapMutations({
        setPageContextKeys: mutate.SET_PAGE_CONTEXT_KEYS,
      }),

      clickUploadExisting() {
        this.$emit('replaceSubmission', this.assignment.document_id)
      },

      handleViewHistoryClick() {
        this.setPageContextKeys({ show_historical_submissions_assignment_id: this.assignment.id })
      },

      handleAuditLogClick() {
        this.setPageContextKeys({ show_audit_log_submission_id: this.assignment.current_submission_id })
      },

      handleResetClick() {
        // Bulk action functions by adding to a collection view
        // Register a new collection view, add this single item
        if (!this.singleStoreCreated()) {
          this.$store.registerModule(this.singleCollectionViewName, CollectionViewStore)
        }
        this.$store.dispatch(`${this.singleCollectionViewName}/select`, this.assignment.id)

        this.setPageContextKeys({
          bulkResetAssignmentsCollectionName: this.singleCollectionViewName,
        })
      },

      singleStoreCreated() {
        return this.$store._modulesNamespaceMap[`${this.singleCollectionViewName}/`]
      },
    },
  }
</script>
