var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ListGroupItem",
    [
      _vm.date
        ? _c("WbSimpleDateTime", { attrs: { value: _vm.date } })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "h4" }, [_vm._v(_vm._s(_vm.documentName))]),
      _vm._v(" "),
      _c(
        "DefinitionList",
        { attrs: { inline: "" } },
        [
          _c("template", { slot: "term" }, [_vm._v("Status:")]),
          _vm._v(" "),
          _c("template", { slot: "definition" }, [
            _c("span", { staticClass: "text-capitalize" }, [
              _vm._v(_vm._s(_vm.status)),
            ]),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "accordion" },
        [
          _c(
            "div",
            {
              staticClass: "mb-1",
              attrs: { role: "button" },
              on: {
                click: function ($event) {
                  _vm.isExpanded = !_vm.isExpanded
                },
              },
            },
            [
              _c("WbIcon", {
                staticClass: "mr-1",
                attrs: { type: _vm.isExpanded ? "caret-up" : "caret-down" },
              }),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.isExpanded ? "Hide" : "Show") +
                  " Checks\n    "
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.requiredChecks.length
            ? _c(
                "ListGroup",
                { staticClass: "collapse", class: { show: _vm.isExpanded } },
                _vm._l(_vm.requiredChecks, function (check, index) {
                  return _c(
                    "ListGroupItem",
                    { key: index },
                    [
                      _c("WbIcon", {
                        class: _vm.getCheckClassName(check),
                        attrs: { type: _vm.getCheckIcon(check) },
                      }),
                      _vm._v(" "),
                      _c("strong", [_vm._v(_vm._s(_vm.getCheckLabel(check)))]),
                      _vm._v(" "),
                      _c("div", { staticClass: "text-small" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.getCheckDescription(check)) +
                            "\n        "
                        ),
                      ]),
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }