import Constants from 'lib/constants'

// @deprecated. This Marionette View has been superceded by SlideoutPanel Vue component.

/*
*   This layout renders the Slideout Panel Modal and contentView within.
*
*     options:
*      @contentView - The child view for this modal
*      @employees - Collection of selected employee models
*      @panelTitle - (string) the .modal-title for the panel
*
*/
export default Marionette.LayoutView.extend({
  template: '#slideout-panel-view-tmpl',

  regions: {
    'contentRegion': '.slideout-content-region'
  },

  ui: {
    panel: '.slideout-panel-modal',
    closeButton: '.close',
  },

  initialize () {

  },

  onRender () {
    // We append to wrapper so that mmmenu works properly
    this.$el.appendTo('#wrapper')

    // Prevent panel from closing until action is complete or 'X' is clicked
    this.ui.panel.modal({
      backdrop: 'static',
      keyboard: false,
    })
    this.ui.panel.modal('show')

    // Add styling for modal backdrop and append to wrapper instead of body for mmmenu purposes
    $('.modal-backdrop').addClass('no-bg').appendTo('#wrapper')

    this.ui.panel.on('hidden.bs.modal', this.panelHidden.bind(this))
    this.ui.panel.on('shown.bs.modal', this.panelShown.bind(this))

    // Bind to the Enter key, and send to the panel view
    $('#wrapper').parent().on("keypress", (event) => { this.handleEnterKey(event, this.panelView) })
  },

  // Identify Enter key event and send to panelView
  handleEnterKey: function(event, panelView) {
    if(event.keyCode == Constants.KeyCode.KEY_RETURN && panelView) {
      panelView.triggerMethod('keypressEnter', event)
    }
  },

  panelHidden: function () {
    this.destroy()
  },

  panelShown: function () {
    let panelView = this.getOption('contentView')
    this.panelView = new panelView({
      employees: this.getOption('employees'),
      context: this.getOption('context'),
      viewLoadedCallback: this.panelViewReady.bind(this),
    })

    this.listenTo(this.panelView, 'action:finished', this.panelActionComplete.bind(this))
    this.listenTo(this.panelView, 'action:processing', this.panelActionProcessing.bind(this))
    this.listenTo(this.panelView, 'action:failed', this.panelActionFailed.bind(this))
  },

  // Callback that shows the form content after it's fetched
  panelViewReady: function () {
    // Remove the tabIndex -1 so that mmenu can focus on the search input
    // REVIEW: This is dirty, but haven't found a better solution.
    this.ui.panel.attr('tabindex', '')

    this.contentRegion.show(this.panelView)

    $.runInitializers(this.$el)
  },

  templateHelpers: function() {
    return {
      panelTitle: this.getOption('panelTitle'),
    }
  },

  onBeforeDestroy: function () {
    // Remove event listeners and destroy content
    this.ui.panel.off()
    this.panelView.destroy()

    // Unbind Enter key
    $('#wrapper').parent().off('keypress')
  },

  panelActionComplete: function () {
    // Close the slideout
    this.ui.panel.modal('hide')
  },

  panelActionProcessing: function () {
    // Hide the close button
    this.ui.closeButton.hide()
  },

  panelActionFailed: function () {
    // Show the close button
    this.ui.closeButton.show()
  }

})
