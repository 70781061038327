<template>
  <div class="row">
    <div class="col-lg-10">
      <WbPanel title="Configure SSO for SAML" :formResponse="formResponse">
        <p>
          Enter the settings from your SSO Identity Provider (IdP) to configure the connection.
        </p>
        <form
          class="form-horizontal fv-form fv-form-bootstrap pt-4"
          :errors="formErrors"
          @submit.prevent="handleSubmit"
        >
          <FormFieldDropdown
            label="Identity Provider (IdP)"
            name="idp_name"
            :required="true"
            :options="ssoProviderOptions"
            :errors="formErrors"
            v-model="formData.idp_name"
            orientation="horizontal"
            :helpPopoverText="helpText.idpName"
          />

          <FormFieldInput
            label="IdP Entity ID"
            name="idp_entity_id"
            placeholder="http://www.okta.com/ef383598a619f"
            :required="true"
            :errors="formErrors"
            v-model="formData.idp_entity_id"
            orientation="horizontal"
            :helpPopoverText="helpText.entityId"
          />

          <FormFieldInput
            label="SSO Target Url"
            name="idp_sso_target_url"
            placeholder="https://000000.okta.com/home/acmecorp000000/ef383598-619f-4967-afbb"
            :required="true"
            :errors="formErrors.settings"
            v-model="formData.settings.idp_sso_target_url"
            orientation="horizontal"
            :helpPopoverText="helpText.targetUrl"
          />

          <FormFieldInput
            label="IdP Certification Fingerprint"
            name="idp_cert_fingerprint"
            placeholder="17:be:37:d4:b7:5d:d5:ea:12:ea:98:99"
            :required="true"
            :errors="formErrors.settings"
            v-model="formData.settings.idp_cert_fingerprint"
            orientation="horizontal"
            :helpPopoverText="helpText.fingerprint"
          />

          <FormFieldInput
            label="IdP Fingerprint Algorithm"
            name="idp_cert_fingerprint_algorithm"
            placeholder="http://www.w3.org/2000/09/xmldsig#sha256"
            :required="true"
            :errors="formErrors.settings"
            v-model="formData.settings.idp_cert_fingerprint_algorithm"
            orientation="horizontal"
            :helpPopoverText="helpText.fingerprintAlgo"
          />

          <FormFieldInput
            label="Authentication Context"
            name="authn_context"
            placeholder="urn:oasis:names:tc:SAML:2.0:ac:classes:Password"
            :required="false"
            :errors="formErrors.settings"
            v-model="formData.settings.authn_context"
            orientation="horizontal"
            :helpPopoverText="helpText.authnContext"
          />

          <div class="form-group">
            <div class="col-sm-8 col-md-9 col-lg-9 col-sm-offset-4 col-md-offset-3 col-lg-offset-3">
              <WbButton
                class="submit"
                ref="submitButton"
                :isLoading="processingRequest"
                type="submit"
              >
                Submit
              </WbButton>
              <WbButton
                class="btn-form-cancel"
                @click.prevent="handleCancel"
                context="default"
                type="cancel"
              >
                Cancel
              </WbButton>
            </div>
          </div>
        </form>
      </WbPanel>
    </div>
  </div>
</template>

<script>
  import WbPanel from 'components/common/WbPanel'
  import WbButton from 'components/common/WbButton'
  import FormFieldDropdown from 'components/common/FormFieldDropdown'
  import FormFieldInput from 'components/common/FormFieldInput'
  import Locale from 'lib/locale'
  import { ajax } from 'jquery'

  export default {
    name: 'sso-saml-config-form-container',

    components: {
      WbPanel,
      WbButton,
      FormFieldDropdown,
      FormFieldInput
    },

    props: {
      ssoConfig: {
        type: Object,
        required: true
      },
      ssoProviderNames: {
        type: Array,
        required: true
      }
    },

    data() {
      return {
        formData: {},
        formResponse: {
          success: false,
          errors: {},
          msgSuccess: '',
        },
        processingRequest: false,
        helpText: Locale.t('sso_config.help_text'),
      }
    },

    computed: {
      isExistingConfig() {
        return this.formData.id
      },

      ssoProviderOptions() {
        return this.ssoProviderNames.map(providerName => ({value: providerName, label: providerName}))
      },

      formErrors() {
        return this.formResponse.errors
      }
    },

    created() {
      this.formData = {
        ...this.ssoConfig,
        authentication_type: 'SAML', // default setting that should be included when the form is submitted
      }
    },

    methods: {
      handleSubmit() {
        // Bug fix: instead of blank this key/value pair shouldn't be in the settings at all
        if(!this.formData.settings.authn_context) {
          delete this.formData.settings.authn_context
        }

        this.isExistingConfig ? this.updateConfig() : this.createConfig()
      },

      createConfig() {
        this.formResponse.success = false
        this.formResponse.errors = {}
        this.processingRequest = true

        ajax({
          method: 'POST',
          url: this.$routes.sso_configs_path,
          data: {
            sso_config: this.formData
          },
          wbGenericFailureMsg: Locale.t('sso_config.create.fail'),
        }).success( (json) => {
          this.formResponse.msgSuccess = Locale.t('sso_config.create.success')
          this.formResponse.success = true
          this.formData.id = json.id // add id so that repeated submits will use updateConfig method
        }).error( (xhr) => {
          if(xhr && xhr.responseJSON) { this.formResponse.errors = xhr.responseJSON.errors || {} }
        }).always(() => {
          this.processingRequest = false
        })
      },

      updateConfig() {
        this.formResponse.success = false
        this.formResponse.errors = {}
        this.processingRequest = true

        ajax({
          method: 'PATCH',
          url: this.$routes.sso_config_path({id: this.formData.id}),
          data: {
            sso_config: this.formData
          },
          wbGenericFailureMsg: Locale.t('sso_config.update.fail'),
        }).success( () => {
          this.formResponse.msgSuccess = Locale.t('sso_config.update.success')
          this.formResponse.success = true
        }).error( (xhr) => {
          if(xhr && xhr.responseJSON) { this.formResponse.errors = xhr.responseJSON.errors || {} }
        }).always(() => {
          this.processingRequest = false
        })
      },

      handleCancel() {
        // Clear validation errors
        this.formResponse.errors = {}
      },
    }
  }
</script>
