import PrerenderedItemView from 'views/common/prerendered_item_view'
import ChangeStatusListItemView from 'views/employees/employee_seasons/change_status_list_item_view'
import Animations from 'lib/util/animations'
import DateTime from 'lib/util/datetime'
import ApiOperation from 'lib/api_operation'
import Routes from 'lib/routes'
import Locale from 'lib/locale'

export default PrerenderedItemView.extend({
  // stopPropagation: false here to ensure dropdown closes upon click
  triggers: {
    'click .js-delete': {
      event: 'deleteEmployee',
      stopPropagation: false,
      preventDefault: true,
    },
    'click .js-deactivate': {
      event: 'deactivateEmployee',
      stopPropagation: false,
      preventDefault: true,
    },
    'click .js-reactivate': {
      event: 'reactivateEmployee',
      stopPropagation: false,
      preventDefault: true,
    },
    'click .js-rehire': {
      event: 'launchRehireWizard',
      stopPropagation: false,
      preventDefault: true
    }
  },

  events: {
    'click .js-edit-employment-dates': "editEmploymentDates",
  },

  bindings: {
    'span.panel-title-status': {
      observe: 'status',
      onGet: function(val) {
        return (val == 'active') ? "Status: Active" : "Status: Inactive"
      },
      attributes: [{
        name: 'class',
        observe: 'status',
        onGet: function(val) {
          return (val == 'active') ? 'text-success' : 'text-danger'
        }
      }]
    },
    '#employment-active-season .info-hire-date': 'hire_date',
    '#employment-active-season .info-start-date': 'start_date',
    '#employment-active-season .info-end-date': 'end_date',
    '#employment-active-season .info-row-status': {
      observe: 'status',
      visible: function(val) {
        return (val != 'active')
      },
      visibleFn: function($el, visible) {
        if (visible && !$el.hasClass('in')) {
          Animations.temporaryHighlight($('td', $el))
        }

        Animations.slideInLTR($el, visible, {
          callback: function() {
            $el.toggleClass('status-active', !visible)
            $el.toggleClass('status-inactive', visible)
          }
        })
      },
      updateView: false,
    },
    '#employment-active-season .info-item-status-details': {
      observe: ['status', 'status_changed_at', 'status_changed_by', 'status_note'],
      updateMethod: 'html',
      onGet: function(values) {
        return App.Util.underscoreTemplateToHTML("#status-details-tmpl", {
          status: 'Deactivated',
          statusChangedAt: values[1],
          statusChangedAtFormatted: DateTime.formatDateTime(values[1]),
          statusChangedBy: values[2],
          statusNote: values[3]
        })
      },
      highlightUpdates: false, // we only want to highlight when row becomes visible, so we do it manually for .info-row-status
    },

  },

  ui: {
    changeStatusListItem: 'li.change-status-list-item',
    changeStatusDropdownMenu: '.change-status-dropdown-menu',
    changeStatusDropdownMenuToggle: '.change-status-dropdown-menu .dropdown-toggle',
    rehireWizardBtn: '.rehire-wizard-btn',
    rehireWizardDropdownOption: 'li a.js-rehire',
  },

  initialize: function() {
    // Initialize view with employment model so that the bindings can listen for changes to the employment status
    this.stickit(this.options.employment)
    this.changeStatusListItemView = new ChangeStatusListItemView({ el: this.ui.changeStatusListItem, model: this.options.employment })
  },

  onRender: function() {
    this.changeStatusListItemView.render()

    if(gon.da_background_worker_in_progress) {
      this.ui.changeStatusDropdownMenuToggle.addClass('disabled')
      this.ui.changeStatusDropdownMenu.tooltip({
        'title': Locale.t('assignments.processing', 'Change status'),
        'placement': 'top',
        'container': 'body'
      })
    }

    // If the employee hasn't logged in yet, hide the Rehire option
    if (!gon.userEmployer.current_sign_in_at) {
      this.ui.rehireWizardDropdownOption.hide()
    }
  },

  editEmploymentDates: function() {
    App.vent.trigger('employee:employment:edit', this.options.employment)
  },

  onDeleteEmployee: function() {
    bootbox.confirm({
      title:   "Danger Zone",
      message: "Once you delete an employee, ALL of their records (forms, data, etc) will be completely removed from your account. " +
                "There is no way to undo this, so please be sure this is what you want to do.<br/><br/> " +
                "If your intent is to just archive the employee, please hit Cancel and select Deactivate instead.",
      className: "modal-danger",
      callback: $.proxy(function(result) {
                          if (result === false)
                            return
                          App.vent.trigger('employee:delete', this.model)
                        }, this),
      buttons: {
        cancel: {
          label: "Cancel",
          className: "btn-default"
        },
        confirm: {
          label: "Delete this employee",
          className: "btn-danger",
          value: true,
        },
      }
    })
  },

  onDeactivateEmployee: function() {
    bootbox.prompt({
      title: "Reason for Deactivation (optional)",
      message: "<p>This note will NOT be shown to the staff member.</p>",
      inputType: 'textarea',
      callback: (value) => {
        // null means canceled
        if (value === null) return
        this.deactivate(value)
      },
      buttons: {
        confirm: {
          label: "Deactivate",
          className: "btn-primary pull-right",
        },
        cancel: {
          label: "Cancel",
          className: "btn-default pull-left",
        }
      }
    })
  },

  onReactivateEmployee: function() {
    // New API endpoint
    new ApiOperation({
      path: Routes.Api.activate_employee_path({ id: this.model.get('id') }),
      ajaxOptions: {
        wbGenericFailureMsg: "Sorry, we couldn't activate this employee.",
      }
    }).done((responseText, textStatus, xhr) => {
      // Set the attributes which are deep-embedded in the response (cuz this.model ≠ the model of the response JSON)
      //--
      // FIXME UNSEASONALITY: When EmployeeSeason model no longer exists, this will be more straightward
      const attributes = this.model.parse(xhr.responseJSON['employment'])
      this.options.employment.set(attributes)
    })
  },

  deactivate: function(note) {
    // New API endpoint
    new ApiOperation({
      path: Routes.Api.deactivate_employee_path({ id: this.model.get('id') }),
      data: { 'note': note },
      ajaxOptions: {
        wbGenericFailureMsg: "Sorry, we couldn't deactivate this employee."
      }
    }).done((responseText, textStatus, xhr) => {
      // Set the attributes which are deep-embedded in the response (cuz this.model ≠ the model of the response JSON)
      //--
      // FIXME UNSEASONALITY: When EmployeeSeason model no longer exists, this will be more straightward
      const attributes = this.model.parse(xhr.responseJSON['employment'])
      this.options.employment.set(attributes)
    })
  },

  onLaunchRehireWizard: function() {
    App.vent.trigger("employee:rehire", this.model)
  },

  onBeforeDestroy: function() {
    if (this.changeStatusListItemView)
      this.changeStatusListItemView.destroy()
  }
})
