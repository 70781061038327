var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "status-changed-at" },
    [
      _vm.statusChangedAt
        ? _c("wb-simple-date-time", { attrs: { value: _vm.statusChangedAt } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }