import Locale from 'lib/locale'
import PrerenderedItemView from 'views/common/prerendered_item_view'

export default PrerenderedItemView.extend({
  ui: {
    rehireEmployeeBtnWrapper: '.js-rehire-employee-wrapper',
    rehireEmployeeBtn: '.js-rehire-employee'
  },

  onRender: function() {
    if(gon.da_background_worker_in_progress) {
      this.ui.rehireEmployeeBtnWrapper.tooltip({
        'title': Locale.t('assignments.processing', 'Add to Active Staff'),
      })
      this.ui.rehireEmployeeBtn.addClass('disabled')
    }
  },


  triggers: {
    'click @ui.rehireEmployeeBtn': 'rehireEmployee'
  },

  onRehireEmployee: function() {
    if(!gon.da_background_worker_in_progress) {
      App.vent.trigger('employee:rehire', this.model)
    }
  }
})
