var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "alert alert-warning" }, [
      _c("p", {
        staticClass: "mb-4",
        domProps: { innerHTML: _vm._s(_vm.message) },
      }),
      _vm._v(" "),
      _c("ul", [_vm._v(_vm._s(_vm.itemsWithErrors))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }