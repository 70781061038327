var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ListItem",
    {
      class: { "submission-missing": !_vm.currentSubmission },
      attrs: {
        href: _vm.href,
        selectable: _vm.selectable,
        collectionViewName: _vm.collectionViewName,
        item: _vm.assignment.id,
        secondaryActions: _vm.includeSecondaryActionMenu,
        defaultSelected: _vm.defaultSelected,
        disabled: _vm.disabled,
      },
    },
    [
      _c(
        "div",
        { staticClass: "wb-list__content" },
        [
          _c("SubmissionStatusIndicator", {
            attrs: {
              submission: _vm.currentSubmission,
              status: _vm.assignmentStatus,
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "wb-list__title mr-auto my-0" }, [
            _c("span", { ref: "docName", staticClass: "name" }, [
              _vm._v(
                "\n        " + _vm._s(_vm.currentDocument.name) + "\n      "
              ),
            ]),
            _vm._v(" "),
            _vm.currentDocument.internal
              ? _c(
                  "span",
                  {
                    ref: "internalLabel",
                    staticClass: "label label-default ml-2",
                  },
                  [_vm._v("\n        Internal\n      ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.assignment.optional
              ? _c("span", { staticClass: "label label-default ml-2" }, [
                  _vm._v("\n        Optional\n      "),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.includeGroupsColumn
            ? _c("div", { staticClass: "wb-list__column-groups" }, [
                _vm.groupNames.length
                  ? _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip.html",
                            value: _vm.groupsTooltip,
                            expression: "groupsTooltip",
                            modifiers: { html: true },
                          },
                        ],
                        staticClass: "mx-2",
                        attrs: { href: "javascript:void(0)" },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.pluralizedGroupNames) +
                            "\n      "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.groupNames.length
                  ? _c("div", { staticClass: "mx-2 text-muted" }, [
                      _vm._v("\n        --\n      "),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "wb-list__column-timestamp" },
            [
              _vm.currentSubmission && _vm.currentSubmission.submitted_at
                ? _c("WbSimpleDateTime", {
                    ref: "submittedIndicator",
                    attrs: {
                      value: _vm.currentSubmission.submitted_at,
                      "tooltip-options": _vm.submittedAtTooltip,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.currentSubmission
                ? _c("em", [_vm._v("\n        No current submission\n      ")])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "secondaryActionsMenuItems" },
        [
          _c("DropdownListItem", {
            attrs: {
              title: "Upload...",
              href: _vm.newAdminEmployeeDocumentSubmissionPath,
              "permission-record-id": _vm.assignment.id,
              "permission-model": "document_assignments",
              "permission-action": "upload_submission",
            },
          }),
          _vm._v(" "),
          _c("li", { staticClass: "divider" }),
          _vm._v(" "),
          _vm.assignment.active
            ? _c("DropdownListItem", {
                ref: "requestNew",
                attrs: {
                  title: "Request new submission",
                  disabled: !this.assignment.active,
                  "permission-record-id": _vm.assignment.id,
                  "permission-model": "document_assignments",
                  "permission-action": "unlock_submission",
                },
                on: {
                  click: function ($event) {
                    return _vm.handleResetClick()
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.hasAuditTrail
            ? _c("DropdownListItem", {
                attrs: { title: "View audit events" },
                on: {
                  click: function ($event) {
                    return _vm.handleAuditLogClick()
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "DropdownListItem",
            {
              ref: "historicalSubmissions",
              attrs: {
                disabled: _vm.assignment.historical_submission_count == 0,
              },
              on: {
                click: function ($event) {
                  return _vm.handleViewHistoryClick(_vm.assignment)
                },
              },
            },
            [
              _c("span", [_vm._v("View all submissions")]),
              _vm._v(" "),
              _vm.assignment.historical_submission_count > 0
                ? _c("div", { staticClass: "badge badge-light" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.assignment.historical_submission_count) +
                        "\n      "
                    ),
                  ])
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }