// SwitchableInputView dynamically renders the appropriate type of input depending on the field type selected.
// The template is determined by the mapping `_fieldTypeMap`, which can be overridden by subclasses who have unique
// needs.
//
// NOTE: All templates are ID based and should be prerendered on the page (e.g. the Slim template needs to include
// the appropriate `script#xyz-value-input-template` tags based on `_fieldTypeMap` and `templateSuffix`).
export default Marionette.ItemView.extend({
  templateSuffix: "-value-input-template",
  bindings: {
    '.value-control': 'value',
  },
  modelEvents: {
    'change:field': 'render',
  },

  getTemplate: function() {
    this.field = this.model.get('field')

    // If we don't have a field, we can go ahead and say we're template-less. When field changes, we'll re-render
    // ourselves and properly select a template.
    if (!this.field) {
      return false
    }

    let templatePrefix = this._getTemplatePrefix()

    return "#" + templatePrefix + this.templateSuffix
  },

  templateHelpers: {
    // Generates <option> tags for each choice in field `ordered_choices`.
    //
    // Options
    //   includeEmptyOption [Boolean] - creates an empty <option> with blank value (""). defaults to true
    //   indicateArchivedOptions [Boolean] - checks to see if the ordered_choice is archived and outputs a supplementary
    //                                       "Archived" text label in the <option> if so. defaults to false
    //
    // Returns - String
    getFieldChoicesAsOptionsForSelect: function(options) {
      var serializedModel = this
      let html = ""

      _.defaults(options, {
        includeEmptyOption: true,
        indicateArchivedOptions: false,
      })

      if (options.includeEmptyOption && !_.isEmpty(serializedModel.field.get('ordered_choices'))) {
        html += "<option value=''></option>"
      }

      _(serializedModel.field.get('ordered_choices')).each(function(item) {
        const itemIsArray = $.isArray(item)

        const choiceLabel = itemIsArray ? item[0] : item
        const choiceValue = itemIsArray ? item[1] : item

        const archived = itemIsArray && item.length >= 3 && item[2].archived
        const showArchived = archived && options.indicateArchivedOptions

        html += `
          <option value="${_.escape(choiceValue)}">
            ${_.escape(choiceLabel)}${ showArchived ? ' (Archived)' : '' }
          </option>
        `
      })

      return html
    },

    getFieldChoicesAsOptionsForRadio: function(options) {
      let serializedModel = this
      let html = ""

      _.defaults(options, { fieldName: '', includeOtherOption: false})

      _(serializedModel.field.get('ordered_choices')).each(function(item) {
        const itemIsArray = $.isArray(item)
        const choiceLabel = itemIsArray ? item[0] : item
        const choiceValue = itemIsArray ? item[1] : item
        html += `<span class="radio">
                  <label>
                    <input type="radio" name="${options.fieldName}[${serializedModel.field.get('field_key')}]" class="value-control" value="${_.escape(choiceValue)}" />
                    ${_.escape(choiceLabel)}
                  </label>
                </span>`
      })

      if (options.includeOtherOption) {
        html += "<input class='string form-control radio-other-input' placeholder='If other, please specify here' value='' />"
      }

      return html
    }
  },

  onRender: function() {
    this.stickit()

    $.runInitializers(this.$el)
  },

  _getTemplatePrefix: function() {
    const type = this.field.get('type')
    const allTypes = this._fieldTypeMap()

    if (allTypes[type] === undefined) {
      return false
    }

    return allTypes[type]
  },

  _fieldTypeMap: function() {
    return {
      "TextField": "string",
      "MultilineTextField": "textarea",
      "DateField": "date",
      "NumberField": "number",
      "CurrencyField": "currency",
      "DropdownField": "select",
      "RadioField": "radio",
      "CheckboxField": "checkbox",
      "MultiCheckboxField": "multiselect"
    }
  },
})
