export default Marionette.Controller.extend({
  initialize: function(options) {

  },

  deleteWebhook: function(id) {
    var webhook = App.Webhooks.listTableCollectionView.collection.get(id)
    if (!webhook)
      return

    webhook.destroy({
      wait: true,
      wbGenericFailureMsg: "Sorry, we couldn't remove this webhook.",
    }).done(function(data, status, xhr) {
      App.Util.showAjaxFlashNotice(xhr)
    })
  },
})
