var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-lg-10" },
      [
        _c("wb-panel", { attrs: { title: "Service Provider Settings" } }, [
          _c("p", [
            _vm._v(
              '\n        These are the SAML settings for WorkBright (the SSO "Service Provider"). They should be copied into your Identity Provider application.\n      '
            ),
          ]),
          _vm._v(" "),
          _c(
            "form",
            { staticClass: "form-horizontal pt-4" },
            [
              _c("form-field-read-only-text", {
                ref: "identifier",
                attrs: {
                  label: "Identifier (Entity ID)",
                  value: _vm.ssoPaths.identifier,
                },
              }),
              _vm._v(" "),
              _c("form-field-read-only-text", {
                ref: "replyUrl",
                attrs: {
                  label: "Reply URL (Assertion Consumer Service)",
                  value: _vm.ssoPaths.reply,
                },
              }),
              _vm._v(" "),
              _c("form-field-read-only-text", {
                ref: "signInUrl",
                attrs: { label: "Sign in URL", value: _vm.ssoPaths.signIn },
              }),
              _vm._v(" "),
              _c("form-field-read-only-text", {
                ref: "signOutUrl",
                attrs: { label: "Sign out URL", value: _vm.ssoPaths.signOut },
              }),
              _vm._v(" "),
              _c("form-field-read-only-text", {
                ref: "relayState",
                attrs: { label: "Relay State", value: _vm.ssoPaths.root },
              }),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }