var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "ri9_document_set_container" } },
    _vm._l(_vm.documentationSetMeta, function (doc, index) {
      return _c("DocumentReview", {
        key: index,
        attrs: {
          document: doc,
          employeeFullName: _vm.employeeFullName,
          receiptInstructions: _vm.receiptInstructions,
          reviewInstructions: _vm.reviewInstructions,
        },
        on: {
          reviewReset: function () {
            _vm.handleReviewReset(index)
          },
          reviewStart: function () {
            _vm.handleReviewStart(index)
          },
          verify: function (match, mismatchReasons) {
            _vm.handleVerify(index, match, mismatchReasons)
          },
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }