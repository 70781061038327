export default Backbone.Model.extend({

  initialize: function(){
    this.set('stepStores', {})
  },

  // Register a step, get a model for storing data that is maintained
  // Uses existing store if already exists, creates new one if not.
  //
  // stepName - String step name, unique to the step
  //
  // Returns Backbone Model
  register: function(stepName){
    var allStores = this.get('stepStores')

    var store = allStores[stepName]

    if(!store){ // doesn't exist already
      var storeClass = Backbone.Model.extend({})
      store = new storeClass({stepName: stepName})
      allStores[stepName] = store
      this.set('stepStores', allStores)
    }
    return store
  }
})
