var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "wb-modal",
        {
          ref: "modal",
          attrs: {
            title: "Update Employment Dates",
            watchPageContextVariable: "bulk_update_employments",
            locked: _vm.$wait.is("bulk_update_employments_loading"),
          },
          on: { modalHidden: _vm.handleModalHidden },
        },
        [
          _c(
            "v-wait",
            { attrs: { for: _vm.loadingName } },
            [
              _c("spinner", {
                attrs: { slot: "waiting", showLongWaitMessage: true },
                slot: "waiting",
              }),
              _vm._v(" "),
              _c("p", { ref: "updateNotification", staticClass: "pb-3" }, [
                _vm._v(
                  "Update the employment dates for " +
                    _vm._s(_vm.pluralizedStaffMembers) +
                    "."
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-horizontal" },
                [
                  _c("employment-dates", {
                    attrs: {
                      errors: _vm.formErrors,
                      orientation: "horizontal",
                      placeholder: "Unchanged",
                    },
                    model: {
                      value: _vm.dateChanges,
                      callback: function ($$v) {
                        _vm.dateChanges = $$v
                      },
                      expression: "dateChanges",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("schedule-onboarding", {
                attrs: {
                  label:
                    "When would you like the selected staff member(s) to begin receiving notifications?",
                  value: _vm.dateChanges.onboarding_start_date,
                  errors: _vm.formErrors,
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "small" }, [
                _vm._v(
                  "\n        NOTE: Deactivated staff members will not receive ANY communication until they are re-activated. At that time, your selected option will go into effect.\n      "
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  attrs: {
                    disabled: _vm.$wait.is("bulk_update_employments_loading"),
                  },
                  on: { click: _vm.hide },
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "ladda-button",
                {
                  ref: "submitButton",
                  attrs: { disabled: _vm.noDatesSelected },
                  on: { click: _vm.submit },
                },
                [_vm._v("Submit")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("bulk-action-list-alert", {
        ref: "alert",
        attrs: {
          permissionName: "update_employments",
          permissionModule: "employments",
          collectionModule: "employees",
          actionMethod: _vm.hasEmployment,
          actionErrorMessage:
            "The employment dates for these staff members <strong>cannot be updated</strong> because they do not have an employment.",
          displayNameLookupFn: _vm.displayNameLookupFn,
          collectionViewName: _vm.collectionViewName,
        },
        on: {
          continue: _vm.openModal,
          cancelled: function ($event) {
            return _vm.$emit("hidden")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }