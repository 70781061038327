// Utility methods related to changing the UI when user is not authorized for something
export default {
  // Checks `val` to see if it is our standardized marker for restricted content.
  // Returns - Boolean
  isBlockedContent: function(val) {
    return (!!val && typeof val === 'object' && val._wb_blocked === true)
  },

  // Standardized HTML to represent Restricted content
  // Returns - String of HTML
  blockedContentTag: function() {
    var BLOCKED_CONTENT_HTML = '<span class="blocked-content"><i class="fa fa-ban"></i> Restricted</span>'
    return BLOCKED_CONTENT_HTML
  },
}
