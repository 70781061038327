var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-lg-10" },
      [
        _c(
          "WbPanel",
          {
            attrs: {
              title: "Configure SSO for SAML",
              formResponse: _vm.formResponse,
            },
          },
          [
            _c("p", [
              _vm._v(
                "\n        Enter the settings from your SSO Identity Provider (IdP) to configure the connection.\n      "
              ),
            ]),
            _vm._v(" "),
            _c(
              "form",
              {
                staticClass: "form-horizontal fv-form fv-form-bootstrap pt-4",
                attrs: { errors: _vm.formErrors },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.handleSubmit($event)
                  },
                },
              },
              [
                _c("FormFieldDropdown", {
                  attrs: {
                    label: "Identity Provider (IdP)",
                    name: "idp_name",
                    required: true,
                    options: _vm.ssoProviderOptions,
                    errors: _vm.formErrors,
                    orientation: "horizontal",
                    helpPopoverText: _vm.helpText.idpName,
                  },
                  model: {
                    value: _vm.formData.idp_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "idp_name", $$v)
                    },
                    expression: "formData.idp_name",
                  },
                }),
                _vm._v(" "),
                _c("FormFieldInput", {
                  attrs: {
                    label: "IdP Entity ID",
                    name: "idp_entity_id",
                    placeholder: "http://www.okta.com/ef383598a619f",
                    required: true,
                    errors: _vm.formErrors,
                    orientation: "horizontal",
                    helpPopoverText: _vm.helpText.entityId,
                  },
                  model: {
                    value: _vm.formData.idp_entity_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "idp_entity_id", $$v)
                    },
                    expression: "formData.idp_entity_id",
                  },
                }),
                _vm._v(" "),
                _c("FormFieldInput", {
                  attrs: {
                    label: "SSO Target Url",
                    name: "idp_sso_target_url",
                    placeholder:
                      "https://000000.okta.com/home/acmecorp000000/ef383598-619f-4967-afbb",
                    required: true,
                    errors: _vm.formErrors.settings,
                    orientation: "horizontal",
                    helpPopoverText: _vm.helpText.targetUrl,
                  },
                  model: {
                    value: _vm.formData.settings.idp_sso_target_url,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData.settings, "idp_sso_target_url", $$v)
                    },
                    expression: "formData.settings.idp_sso_target_url",
                  },
                }),
                _vm._v(" "),
                _c("FormFieldInput", {
                  attrs: {
                    label: "IdP Certification Fingerprint",
                    name: "idp_cert_fingerprint",
                    placeholder: "17:be:37:d4:b7:5d:d5:ea:12:ea:98:99",
                    required: true,
                    errors: _vm.formErrors.settings,
                    orientation: "horizontal",
                    helpPopoverText: _vm.helpText.fingerprint,
                  },
                  model: {
                    value: _vm.formData.settings.idp_cert_fingerprint,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.formData.settings,
                        "idp_cert_fingerprint",
                        $$v
                      )
                    },
                    expression: "formData.settings.idp_cert_fingerprint",
                  },
                }),
                _vm._v(" "),
                _c("FormFieldInput", {
                  attrs: {
                    label: "IdP Fingerprint Algorithm",
                    name: "idp_cert_fingerprint_algorithm",
                    placeholder: "http://www.w3.org/2000/09/xmldsig#sha256",
                    required: true,
                    errors: _vm.formErrors.settings,
                    orientation: "horizontal",
                    helpPopoverText: _vm.helpText.fingerprintAlgo,
                  },
                  model: {
                    value: _vm.formData.settings.idp_cert_fingerprint_algorithm,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.formData.settings,
                        "idp_cert_fingerprint_algorithm",
                        $$v
                      )
                    },
                    expression:
                      "formData.settings.idp_cert_fingerprint_algorithm",
                  },
                }),
                _vm._v(" "),
                _c("FormFieldInput", {
                  attrs: {
                    label: "Authentication Context",
                    name: "authn_context",
                    placeholder:
                      "urn:oasis:names:tc:SAML:2.0:ac:classes:Password",
                    required: false,
                    errors: _vm.formErrors.settings,
                    orientation: "horizontal",
                    helpPopoverText: _vm.helpText.authnContext,
                  },
                  model: {
                    value: _vm.formData.settings.authn_context,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData.settings, "authn_context", $$v)
                    },
                    expression: "formData.settings.authn_context",
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-sm-8 col-md-9 col-lg-9 col-sm-offset-4 col-md-offset-3 col-lg-offset-3",
                    },
                    [
                      _c(
                        "WbButton",
                        {
                          ref: "submitButton",
                          staticClass: "submit",
                          attrs: {
                            isLoading: _vm.processingRequest,
                            type: "submit",
                          },
                        },
                        [_vm._v("\n              Submit\n            ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "WbButton",
                        {
                          staticClass: "btn-form-cancel",
                          attrs: { context: "default", type: "cancel" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.handleCancel($event)
                            },
                          },
                        },
                        [_vm._v("\n              Cancel\n            ")]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }