// E-Verify case title displayed on small slideout panel (used with EverifyCasePanelContent).

<template>
  <div class="panel-title">
    <h3>E-Verify Case</h3>
    <h4>{{ employeeName }}</h4>
  </div>
</template>

<script>
  export default {
    name: "everify-case-panel-title",

    props: {
      employeeName: {
        type: String,
        required: false
      }
    }
  }
</script>
