var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "WbModal",
    {
      ref: "modal",
      attrs: {
        title: "Remove Admin Access",
        watchPageContextVariable: _vm.modalKey,
      },
    },
    [
      _vm._t("default", [
        _c("p", [
          _vm._v(
            "\n      This will remove admin access from this person. However, because they also have an employee record within WorkBright, they will still be able to login to submit or update their own forms. They will not have access to view any other employees or manage anything else in the system.\n    "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "WbButton",
            {
              attrs: { context: "default", disabled: false },
              on: { click: _vm.hideModal },
            },
            [_vm._v("\n      Cancel\n    ")]
          ),
          _vm._v(" "),
          _c("WbButton", {
            attrs: {
              context: "danger",
              label: "Remove Admin Access",
              isLoading: _vm.isLoading,
            },
            on: { click: _vm.handleRevoke },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }