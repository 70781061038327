var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ListItem",
    { attrs: { item: _vm.event.id, secondaryActions: false } },
    [
      _c("div", { staticClass: "wb-list__content" }, [
        _c(
          "div",
          { staticClass: "wb-list__title-slideout" },
          [
            _c(
              "div",
              { staticClass: "mb-3" },
              [
                _c("WbSimpleDateTime", {
                  attrs: {
                    value: _vm.event.server_timestamp,
                    format: _vm.dateFormat,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("span", { staticClass: "d-block" }, [
              _c("strong", [
                _vm._v("\n          " + _vm._s(_vm.title) + "\n        "),
              ]),
            ]),
            _vm._v(" "),
            _vm.adminName
              ? _c("span", { ref: "adminName", staticClass: "d-block" }, [
                  _vm._v("\n        by " + _vm._s(_vm.adminName) + "\n      "),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("SubmissionAuditLogItemAuthRepSection", {
              attrs: { event: _vm.event },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }