var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "a",
      {
        staticClass: "btn btn-default btn-sm",
        attrs: { href: _vm.employeeSupplementalFilesPath },
      },
      [
        _c("i", { staticClass: "fa fa-level-up fa-rotate-270" }),
        _vm._v("\n    Back to Files\n  "),
      ]
    ),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _c("h3", { staticClass: "page" }, [
      _vm._v("\n    " + _vm._s(_vm.supplementalFileName) + "\n    "),
      _c("small", [
        _c("a", { attrs: { href: _vm.employeePath } }, [
          _vm._v(_vm._s(_vm.employeeFullName)),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "small",
      [
        _vm._v("\n    Uploaded\n    "),
        _c("wb-simple-date-time", {
          attrs: {
            value: _vm.supplementalFileCreatedAt,
            "include-timeago": "true",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("hr"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }