var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "list-item",
    {
      attrs: {
        collectionViewName: _vm.collectionViewName,
        item: _vm.employeeId,
        selectable: true,
        disabled: _vm.disabled,
        secondaryActions: false,
      },
      on: { click: _vm.handleFullItemClick },
    },
    [
      _c(
        "div",
        { staticClass: "wb-list__content" },
        [
          _c("staff-list-progress-indicator", {
            attrs: { onboardingSummary: _vm.onboardingSummary },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "staff-list-name wb-list__column-content-20" },
            [
              _c(
                "div",
                { staticClass: "wb-list__title mr-auto my-0" },
                [
                  !_vm.isActive
                    ? _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.employmentStatus,
                              expression: "employmentStatus",
                            },
                          ],
                        },
                        [
                          _c("strong", { staticClass: "name inactive-staff" }, [
                            _vm._v(_vm._s(_vm.employeeFullName)),
                          ]),
                        ]
                      )
                    : _c("span", [
                        _c("strong", { staticClass: "name" }, [
                          _vm._v(_vm._s(_vm.employeeFullName)),
                        ]),
                      ]),
                  _vm._v(" "),
                  _vm.undeliveredEmail
                    ? _c("TooltipIcon", {
                        ref: "undeliveredEmail",
                        staticClass: "pl-2 text-danger",
                        attrs: {
                          icon: "envelope",
                          message: _vm.undeliveredEmail,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasValidSmsStatus
                    ? _c("TooltipIcon", {
                        ref: "smsStatus",
                        staticClass: "pl-2 text-danger",
                        attrs: { icon: "phone", message: _vm.smsStatus },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "wb-list__description" }, [
                _vm.email
                  ? _c("div", [
                      _c("div", { staticClass: "name" }, [
                        _vm._v(_vm._s(_vm.email)),
                      ]),
                    ])
                  : _vm.email === null
                  ? _c("div")
                  : _c("div", [_c("blocked-content-icon")], 1),
                _vm._v(" "),
                _vm.phone
                  ? _c("span", { staticClass: "mr-1" }, [
                      _vm._v(
                        "\n          " + _vm._s(_vm.phone) + "  ·\n        "
                      ),
                    ])
                  : _vm.phone === null
                  ? _c("div")
                  : _c("div", [_c("blocked-content-icon")], 1),
                _vm._v(" "),
                _c("span", { staticClass: "staff-list-groups" }, [
                  _c("a", { on: { click: _vm.handleGroupsClick } }, [
                    _vm.groupCount
                      ? _c("span", [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.pluralizedGroupCount) +
                              "\n            "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.groupCount
                      ? _c("span", [
                          _vm._v("\n              No Groups\n            "),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "staff-list-employment wb-list__column-content-10" },
            [
              _c("div", { staticClass: "wb-list__column-info" }, [
                _c("div", [
                  _vm._v("\n          Hire date:\n          "),
                  _c(
                    "span",
                    { staticClass: "float-right" },
                    [
                      _vm.hireDate
                        ? _c("wb-simple-date-time", {
                            staticClass: "ml-2",
                            attrs: {
                              value: _vm.hireDate,
                              invalidMessage: "--",
                            },
                          })
                        : _c("blocked-content-icon"),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _vm._v("\n          Start date:\n          "),
                  _c(
                    "span",
                    { staticClass: "float-right" },
                    [
                      _vm.startDate
                        ? _c("wb-simple-date-time", {
                            staticClass: "ml-2",
                            attrs: {
                              value: _vm.startDate,
                              invalidMessage: "--",
                            },
                          })
                        : _c("blocked-content-icon"),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _vm._v("\n          Notification start date:\n          "),
                  _c(
                    "span",
                    { staticClass: "float-right" },
                    [
                      _vm.notificationDate
                        ? _c("wb-simple-date-time", {
                            staticClass: "ml-2",
                            attrs: {
                              value: _vm.notificationDate,
                              invalidMessage: "--",
                            },
                          })
                        : _c("blocked-content-icon"),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _vm._v("\n          Last signed in date:\n          "),
                  _c(
                    "span",
                    { staticClass: "float-right" },
                    [
                      _vm.endDate
                        ? _c("wb-simple-date-time", {
                            staticClass: "ml-2",
                            attrs: {
                              value: _vm.lastLogin,
                              invalidMessage: "--",
                            },
                          })
                        : _c("blocked-content-icon"),
                    ],
                    1
                  ),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "wb-list__column-content-50",
              staticStyle: { "flex-grow": "1" },
              on: { click: _vm.handleProgressBarAreaClick },
            },
            [
              _c(
                "div",
                { staticClass: "wb-list__content" },
                [
                  _vm.statusCounts
                    ? _c("wb-breakdown-chart", {
                        staticClass: "staff-list-collapse-40",
                        attrs: {
                          legendData: _vm.legendData,
                          chartData: _vm.statusCounts,
                          listViewPath: _vm.employeeFormsPath(),
                          includeLegend: false,
                          modelNameHumanized: "assigned form",
                        },
                        on: {
                          "section-click":
                            _vm.handleNonInternalFormProgressSectionClick,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.internalDocsStatusCounts
                    ? _c("wb-breakdown-chart", {
                        staticClass: "staff-list-collapse-40",
                        attrs: {
                          legendData: _vm.legendData,
                          chartData: _vm.internalDocsStatusCounts,
                          listViewPath: _vm.employeeFormsPath(),
                          includeLegend: false,
                          modelNameHumanized: "internal form",
                        },
                        on: {
                          "section-click":
                            _vm.handleInternalFormProgressSectionClick,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }