var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.everifyStagingEnabled
        ? _c("EVerifyStagingAlertMessage", {
            ref: "stagingEverifyInboxAlertMessage",
          })
        : _vm._e(),
      _vm._v(" "),
      _c("EverifyCaseListHeader"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "everify-cases-root" },
        [
          _c("EverifyCaseListContainer"),
          _vm._v(" "),
          _c("EverifyCaseSlideout", {
            attrs: {
              everifyCase: _vm.selectedCase,
              allowDownload: _vm.allowDownload,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }