var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("sort-order", {
        attrs: {
          filter: _vm.sortBy.filter,
          collectionViewName: _vm.collectionViewName,
        },
      }),
      _vm._v(" "),
      _c("list-filter-set", {
        staticClass: "pt-4 mt-4",
        attrs: {
          filters: _vm.filters,
          collectionViewName: _vm.collectionViewName,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }