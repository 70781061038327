var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FormField",
    {
      attrs: {
        label: _vm.label,
        name: _vm.name,
        helpPopoverText: _vm.helpPopoverText,
        orientation: _vm.orientation,
        errors: _vm.errors,
        required: _vm.required,
        hint: _vm.hint,
      },
    },
    [
      _c(
        "div",
        { staticClass: "input-wrapper", class: _vm.fieldWidth },
        [
          _c("WbMultiselect", {
            staticClass: "no-wrap",
            attrs: {
              allowEmpty: false,
              selectOptions: _vm.options,
              placeholder: _vm.placeholderValue,
              value: _vm.value,
              name: _vm.name,
              disabled: _vm.disabled,
              enableSearchField: true,
              multiple: false,
            },
            on: { input: _vm.onChange },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }