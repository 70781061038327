var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.hasEntries
      ? _c(
          "ul",
          { staticClass: "list-group wb-list" },
          _vm._l(_vm.auditEvents, function (event, index) {
            return _c(
              "li",
              { key: index, staticClass: "list-group-item wb-list__item" },
              [_c("EverifyCaseAuditLogItem", { attrs: { event: event } })],
              1
            )
          }),
          0
        )
      : _c("div", { ref: "defaultContent" }, [
          _c("em", [
            _vm._v("There are currently no audit events for this case"),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }