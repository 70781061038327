var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.label
    ? _c(
        "label",
        {
          class: { "control-label col-sm-4 col-md-3 col-lg-3": _vm.horizontal },
          attrs: { for: _vm.name },
        },
        [
          _vm.required
            ? _c(
                "abbr",
                { attrs: { title: "", "data-original-title": "required" } },
                [_vm._v("\n    *\n  ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("span", { domProps: { innerHTML: _vm._s(_vm.label) } }),
          _vm._v(" "),
          _vm.helpPopoverText
            ? _c(
                "a",
                {
                  ref: "popover",
                  staticClass: "help-popover",
                  attrs: {
                    href: "javascript:void(0)",
                    "data-content": _vm.helpPopoverText,
                    "data-placement": "auto top",
                    role: "button",
                    tabIndex: "0",
                    "data-toggle": "popover",
                    "data-trigger": "focus",
                  },
                },
                [_c("i", { staticClass: "fa fa-question-circle" })]
              )
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }