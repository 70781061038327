var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "i9-documentation" },
    [
      !_vm.shouldShowFallbackComponent
        ? _c("h4", { ref: "attachmentName" }, [
            _vm._v(_vm._s(_vm.attachmentName)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.shouldShowAlert
        ? _c("AlertMessage", {
            ref: _vm.verification_status + "Alert",
            attrs: {
              variant: _vm.alertVariant,
              title: _vm.alertTitle,
              message: _vm.alertMessage,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.shouldShowFallbackComponent
        ? _c("UploadDocumentation", {
            ref: "uploadDocumentationFallback",
            attrs: {
              document: _vm.document,
              errors: _vm.errors,
              attachmentAttributes: _vm.attachmentAttributes,
              verificationFallbackMode: true,
            },
            on: { update: _vm.handleInput },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { attrs: { id: "persona-parent" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }