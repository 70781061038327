import TemplatedFieldPermissions from 'models/policy_generator/templated_field_permissions'
import TargetGroupedPermissions from 'models/policy_generator/target_grouped_permissions'
import Permission from 'models/policy_generator/permission'

const TARGET_TYPE = 'RepresentableField'
const VIEW_ACTION = 'view'

class StepProfileFieldsCustomizePresenter {
  constructor(permissionSet) {
    this.permissionSet = permissionSet
  }

  matchingTemplate() {
    const templatedPermissions = new TemplatedFieldPermissions()
    return templatedPermissions.findMatchingTemplate(this.permissionSet.findWhere({ target_type: TARGET_TYPE }))
  }

  groupedPermissions() {
    const gp = new TargetGroupedPermissions(this.permissionSet, { target_type: TARGET_TYPE })
    return gp.groupedPermissions
  }

  addPermission(field) {
    if(this.permissionSet.find(field.field_key, TARGET_TYPE, VIEW_ACTION)) { return } // NOP if already exists

    // TODO: Get this list from somewhere?
    this.permissionSet.appendPermissions([
      new Permission({
        target_id: field.field_key,
        target_type: TARGET_TYPE,
        action: VIEW_ACTION,
        grant: false
      }),
      new Permission({
        target_id: field.field_key,
        target_type: TARGET_TYPE,
        action: 'update',
        grant: false
      }),
    ])
  }

  removePermission(fieldKey) {
    this.permissionSet.removeWhere({ target_type: TARGET_TYPE, target_id: fieldKey })
  }

}

export default StepProfileFieldsCustomizePresenter
