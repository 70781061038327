<template>
  <div class="col-sm-12">
    <div class="checkbox">
      <label>
        <input type="checkbox" ref="checkbox" v-model="group.selected" @click="toggle" />
        {{ group.name }}
      </label>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'group-select-checkbox',

    props: {
      group: {
        type: Object,
        required: true
      }
    },

    methods: {
      toggle() {
        this.group.selected = this.$refs.checkbox.checked
        this.$emit('groupUpdated')
      }
    }
  }
</script>
