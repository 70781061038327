var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wb-list__row" }, [
    _c(
      "div",
      { staticClass: "wb-list__container" },
      [
        _c("WbSimpleDateTime", {
          ref: "date",
          attrs: { value: _vm.date, format: _vm.dateFormat },
        }),
        _vm._v(" "),
        _c("div", { ref: "title", staticClass: "text-strong mt-3" }, [
          _vm._v("\n      " + _vm._s(_vm.title) + "\n    "),
        ]),
        _vm._v(" "),
        _vm.actorName
          ? _c("span", { ref: "actorName" }, [
              _vm._v("\n      by " + _vm._s(_vm.actorName) + "\n    "),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }