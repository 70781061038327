import SelfDestructOnPageFetch from 'lib/vue/mixins/self_destruct_on_page_fetch'
import wait from 'lib/vue/wb_vue_wait'
import Vue from 'vue'

export const pureOrphanVue = function(component, mountPoint, data, options = {}) {
  const store = window.vuexStore
  const app = new Vue({
    store,
    functional: true,
    mixins: [SelfDestructOnPageFetch],
    wait,
    destroyed() {
      if(options.removeElementOnDestroy) {
        this.$el.remove() }
    },
    render: h => h(component, data)
  })
  
  app.$mount(mountPoint)

  return app
}
