<template>
  <div class="panel profile mt-0">
    <div v-if="isFanReviewed">
      <p v-if="isI9Incorrect" ref="incorrectI9Message">
        Your employer will contact you with additional steps to correct your information.
      </p>

      <p v-if="isI9Correct" ref="correctI9Message">
        {{ $locale.t(`everify.status.pending_referral.${localEverifyCase.tnc_action}`) }}
      </p>
    </div>
    <div v-else ref="form">
      <h4>Please read and review the document and fill out the information below:</h4>
      <div
        class="form-input"
        :class="{'has-error' : tncReviewHasError}"
      >
        <p>The information of my Form I-9 is:</p>
        <WbMultiselect
          ref="multiselect"
          name="tnc_review"
          class="no-wrap"
          :selectOptions="localOptions"
          v-model="tncReview"
          :value="tncReview"
          :multiple="true"
        />
        <small class="help-block">{{ tncReviewErrorMessage }}</small>
      </div>
      <div
        v-if="incorectOptionsDisabled"
        ref="tncAction"
        class="mt-4"
        :class="{'has-error' : tncActionHasError}"
      >
        <p>
          Decide if you want to resolve your E-Verify case and mark your decision:
        </p>
        <FormFieldCheckbox
          ref="tncActionCheckbox"
          label="I WILL take action to resolve this mismatch"
          v-model="tncRefer"
          gridColumns="col-xs-12"
          class="ml-2 mb-0"
          :wrapperClass="{'checkbox': false}"
        />
        <FormFieldCheckbox
          ref="tncNoActionCheckbox"
          label="I WILL NOT take action to resolve this mismatch"
          v-model="tncNoAction"
          gridColumns="col-xs-12"
          class="ml-2 mb-0"
          :wrapperClass="{'checkbox': false}"
        />
        <small class="help-block">{{ tncActionErrorMessage }}</small>
      </div>
      <FormFieldCheckbox
        ref="fanReviewedCheckbox"
        name="fan_reviewed"
        label="I have reviewed the Further Action Notice"
        v-model="fanReviewed"
        :errors="errors"
        gridColumns="col-xs-12"
      />
      <WbButton
        ref="submit"
        label="Submit"
        @click="submit"
        :isLoading="isLoading"
      />
    </div>

  </div>
</template>

<script>
  import WbMultiselect from 'components/common/WbMultiselect'
  import FormFieldCheckbox from 'components/common/FormFieldCheckbox'
  import WbButton from 'components/common/WbButton'
  import Util from 'lib/util'
  import { ajax } from 'jquery'

  export default {
    components: {
      FormFieldCheckbox,
      WbButton,
      WbMultiselect,
    },

    props: {
      everifyCase: {
        type: Object,
        required: true
      },
      options: {
        type: Array,
        require: true
      }
    },

    data() {
      return {
        actor: "employee",
        errors: {},
        isLoading: false,
        fanReviewed: false,
        tncReview: [],
        tncRefer: null,
        tncNoAction: null,
        localEverifyCase: this.everifyCase,
      }
    },

    computed: {
      correctValues() {
        return this.options
          .filter((option) => (option.group === "correct"))
          .map((option) => (option.value))
      },

      correctOptionsDisabled() {
        return this.tncReview.some(e => this.incorrectValues.includes(e))
      },

      incorectOptionsDisabled() {
        return this.tncReview.some(e => this.correctValues.includes(e))
      },

      formData() {
        return {
          tnc_review: this.tncReview,
          tnc_action: this.correctOptionsDisabled ? null : this.tncAction,
          fan_reviewed: this.fanReviewed
        }
      },

      incorrectValues() {
        return this.options
          .filter((option) => (option.group === "incorrect"))
          .map((option) => (option.value))
      },

      isI9Correct() {
        return this.localEverifyCase.tnc_i9_correct === true
      },

      isI9Incorrect() {
        return this.localEverifyCase.tnc_i9_correct === false
      },

      isFanReviewed() {
        return this.localEverifyCase.fan_reviewed
      },

      localOptions() {
        return this.options
          .map((option) => {
            const isDisabled = option.group === "correct" ? this.correctOptionsDisabled : this.incorectOptionsDisabled

            return {label: option.label, value: option.value, $isDisabled: isDisabled}
          })
      },

      tncAction() {
        if(this.tncRefer) { return 'refer' }
        if(this.tncNoAction) { return 'no_action' }
        return null
      },

      tncActionErrorMessage() {
        if(!this.tncActionHasError) { return null }
        return this.errors.tnc_action.join(' ')
      },

      tncActionHasError() {
        return this.errors && this.errors.tnc_action && this.errors.tnc_action.length > 0
      },

      tncReviewErrorMessage() {
        if(!this.tncReviewHasError) { return null }
        return this.errors.tnc_review.join(' ')
      },

      tncReviewHasError() {
        return this.errors && this.errors.tnc_review && this.errors.tnc_review.length > 0
      }
    },

    watch: {
      tncRefer(newValue) {
        if(newValue){
          this.tncNoAction = !newValue
        }
      },
      tncNoAction(newValue) {
        if(newValue){
          this.tncRefer = !newValue
        }
      }
    },

    methods: {
      submit() {
        this.isLoading = true

        ajax({
          method: 'POST',
          url: this.$routes.Api.everify_tnc_review_path({id: this.localEverifyCase.id}),
          data: {
            form: this.formData,
            actor: this.actor
          }
        }).then((response) => {

          this.localEverifyCase = response.everifyCase
        }).fail((xhr) => {

          if(xhr.responseJSON && xhr.responseJSON.errors) {
            this.errors = xhr.responseJSON.errors || {}
          } else {
            Util.genericAjaxError("An error occurred promoting.", xhr)
          }
        }).always(() => {

          this.isLoading = false
        })
      }
    }
  }
</script>
