var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h5", [_vm._v("Rehire Summary For " + _vm._s(_vm.employeeFullName))]),
    _vm._v(" "),
    _c("p", { staticClass: "summary-groups" }, [
      _vm.haveGroupsChanged
        ? _c("span", { ref: "groupsChange" }, [
            _c(
              "div",
              {
                staticClass: "submission-status-indicator status-accepted",
                staticStyle: { "margin-left": "20px" },
              },
              [
                _c("i", { staticClass: "fa fa-fw status-icon" }),
                _vm._v(" "),
                _c("span", { staticStyle: { color: "#414545" } }, [
                  _c("strong", [_vm._v("Groups:")]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      "\n            The staff member will be added to " +
                        _vm._s(_vm.pluralizedAddedGroups) +
                        " and removed from " +
                        _vm._s(_vm.pluralizedRemovedGroups) +
                        "\n          "
                    ),
                  ]),
                ]),
              ]
            ),
          ])
        : _c("span", { ref: "groupsNoChange" }, [_vm._m(0)]),
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "summary-forms" }, [
      _vm.haveFormsChanged
        ? _c("span", { ref: "formsChange" }, [
            _c(
              "div",
              {
                staticClass: "submission-status-indicator status-accepted",
                staticStyle: { "margin-left": "20px" },
              },
              [
                _c("i", { staticClass: "fa fa-f status-icon" }),
                _vm._v(" "),
                _c("span", { staticStyle: { color: "#414545" } }, [
                  _c("strong", [_vm._v("Forms:")]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.employeeFirstName) +
                        " will be asked to submit " +
                        _vm._s(_vm.pluralizedNewForms) +
                        " and resubmit " +
                        _vm._s(_vm.pluralizedReassignedForms) +
                        ".\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.reassignedInternalForms.length
                    ? _c("span", [
                        _vm._v(
                          "\n            Administrators will be asked to resubmit " +
                            _vm._s(_vm.pluralizedInternalForms) +
                            ".\n          "
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]
            ),
          ])
        : _c("span", { ref: "formsNoChange" }, [_vm._m(1)]),
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "summary-employment-dates" }, [
      _vm.haveDatesBeenSet
        ? _c("span", { ref: "datesSet" }, [
            _c(
              "div",
              {
                staticClass: "submission-status-indicator status-accepted",
                staticStyle: { "margin-left": "20px" },
              },
              [
                _c("i", { staticClass: "fa fa-fw status-icon" }),
                _vm._v(" "),
                _c("span", { staticStyle: { color: "#414545" } }, [
                  _c("strong", [_vm._v("Employment Dates:")]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v("\n            The\n            "),
                    _c(
                      "span",
                      { staticStyle: { "text-transform": "capitalize" } },
                      [_vm._v(_vm._s(_vm.changedDates.join(", ")))]
                    ),
                    _vm._v("\n            will be updated.\n          "),
                  ]),
                ]),
              ]
            ),
          ])
        : _c("span", { ref: "datesNotSet" }, [_vm._m(2)]),
    ]),
    _vm._v(" "),
    _c(
      "h4",
      { staticStyle: { "margin-top": "30px", "margin-bottom": "15px" } },
      [_vm._v("\n    What Happens Next\n  ")]
    ),
    _vm._v(" "),
    _c("p", [
      _vm._v(
        '\n    When you click "Finish", ' +
          _vm._s(_vm.employeeFirstName) +
          " will be activated and their forms will be assigned, though their forms will not be sent until the notification start date that you selected.\n  "
      ),
    ]),
    _vm._v(" "),
    _vm.notificationDate
      ? _c("p", [
          _c("ul", [
            _c("li", [
              _vm._v(
                "\n        On " +
                  _vm._s(_vm.notificationDate) +
                  ", " +
                  _vm._s(_vm.employeeFirstName) +
                  ' will receive the "Welcome Back!" notification from WorkBright which notifies them of their new forms.\n      '
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "\n        After " +
                  _vm._s(_vm.employeeFirstName) +
                  " receives the notification and logs in, they'll be prompted to review their profile and make any changes necessary, such as a new address.\n      "
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "\n        They will be able to view a list of their required forms and complete any new requests or reassignments.\n      "
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "\n        They will continue to receive automated reminder notifications until their forms are complete.\n      "
              ),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.notificationDate
      ? _c("p", [
          _c("ul", [
            _c("li", [
              _vm._v(
                "\n        You have not scheduled notifications for " +
                  _vm._s(_vm.employeeFirstName) +
                  " but the selected forms will be assigned to them.\n      "
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "\n        When " +
                  _vm._s(_vm.employeeFirstName) +
                  " logs in, they'll be prompted to review their profile and make any changes necessary, such as a new address.\n      "
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "\n        They'll be able to view a list of their required forms and complete any new requests or reassignments.\n      "
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "\n        They will continue to receive automated reminder notifications until their forms are complete.\n      "
              ),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "submission-status-indicator status-missing",
        staticStyle: { "margin-left": "20px", color: "#aaaaaa" },
      },
      [
        _c("i", { staticClass: "fa fa-fw status-icon" }),
        _vm._v(" "),
        _c("span", [
          _c("strong", [_vm._v("Groups:")]),
          _vm._v(" "),
          _c("span", [_vm._v("No change")]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "submission-status-indicator status-missing",
        staticStyle: { "margin-left": "20px", color: "#aaaaaa" },
      },
      [
        _c("i", { staticClass: "fa fa-fw status-icon" }),
        _vm._v(" "),
        _c("span", [
          _c("strong", [_vm._v("Forms:")]),
          _vm._v(" "),
          _c("span", [_vm._v("No change")]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "submission-status-indicator status-missing",
        staticStyle: { "margin-left": "20px", color: "#aaaaaa" },
      },
      [
        _c("i", { staticClass: "fa fa-fw status-icon" }),
        _vm._v(" "),
        _c("span", [
          _c("strong", [_vm._v("Employment Dates:")]),
          _vm._v(" "),
          _c("span", [_vm._v("No change")]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }