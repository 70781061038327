var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FormField",
    {
      attrs: {
        label: _vm.label,
        name: _vm.name,
        orientation: _vm.orientation,
        required: _vm.required,
        errors: _vm.errors,
        hint: _vm.hint,
        helpPopoverText: _vm.helpPopoverText,
      },
    },
    [
      _c("Toggle", {
        attrs: {
          value: _vm.value,
          disabled: _vm.disabled,
          onLabel: _vm.onLabel,
          offLabel: _vm.offLabel,
          onClass: _vm.onClass,
          offClass: _vm.offClass,
        },
        on: { toggleChanged: _vm.toggleChanged },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }