var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "tooltip",
          rawName: "v-tooltip.html",
          value: _vm.tooltipTitle,
          expression: "tooltipTitle",
          modifiers: { html: true },
        },
      ],
      staticClass: "submission-status-indicator",
      class: _vm.baseClass,
    },
    [
      _c("i", { staticClass: "fa fa-fw status-icon" }),
      _vm._v(" "),
      _vm.showStatusText
        ? _c("span", { ref: "status" }, [
            _vm._v("\n    " + _vm._s(_vm.statusText) + "\n  "),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }