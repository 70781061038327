// A global list of AsyncItemViews that are being shown. Used to track async-generated modals and prevent
// duplicates from showing up.
export default {
  openViews: [], // List (strings) of uniquness keys for AsyncItemViews

  // Lock the view, indicating that it is unsafe to redisplay if requested by another event.
  // Works kind of like a slow semaphore check.
  //
  // Returns true if the AsyncItemView subclass does not define 'asyncItemViewUniquenessKey' property.
  //
  // Arguments:
  // view: AsyncItemView
  //
  // Returns - boolean, true if lock is obtained (or ignored if uniquness key is undefined)
  //                    false if already locked
  lockIfAvailable: function(view) {
    let lockObtained = false // assume failure

    const uniquenessKey = view.asyncItemViewUniquenessKey

    if(uniquenessKey) {
      if(!this._isViewLocked(view.asyncItemViewUniquenessKey)) {
        // View is not on the list. Add it.
        this.openViews.push(view.asyncItemViewUniquenessKey)
        lockObtained = true
      }
    } else {
      // No uniqueness key. No lock to obtain.
      lockObtained = true
    }

    return lockObtained
  },

  // Unlock the view, indicating it is safe to reopen if requested.
  // No activity if the view does not defined 'asyncItemViewUniquenessKey' property
  //
  // Arguments:
  // view - AsyncItemView
  //
  unlock: function(view) {
    const uniquenessKey = view.asyncItemViewUniquenessKey
    if(uniquenessKey){
      const viewIdx = this.openViews.indexOf(uniquenessKey)
      if(viewIdx != -1){ this.openViews.splice(viewIdx, 1) }
    }
  },

  _isViewLocked: function(viewName) {
    return this.openViews.indexOf(viewName) != -1
  }
}
