import ApiOperation from 'lib/api_operation'
import Routes from 'lib/routes'

export default Marionette.Controller.extend({
  revokeAdminUser: function(id) {
    const userToRevokeAccess = App.AdminUsers.listTableCollectionView.collection.get(id) ||
      App.AdminUsers.listTableCollectionViewPending.collection.get(id)
    if (!userToRevokeAccess)
      return

    new ApiOperation({
      path: Routes.revoke_admin_user_path({ id: userToRevokeAccess.id }),
      httpMethod: 'PATCH',  // FIXME: Should be a POST
      ajaxOptions: {
        wbGenericFailureMsg: "Sorry, we couldn't remove this admin user.",
      }
    }).done((data, status, jqXHR) => {
      const view = App.AdminUsers.listTableCollectionView.children.findByModel(userToRevokeAccess) ||
        App.AdminUsers.listTableCollectionViewPending.children.findByModel(userToRevokeAccess)
      view.$el.fadeOut(function(){
        if (App.AdminUsers.listTableCollectionView.isDataTable()) {
          App.AdminUsers.listTableCollectionView.getDataTableObject().row(view.$el).remove().draw()
          App.AdminUsers.listTableCollectionViewPending.getDataTableObject().row(view.$el).remove().draw()
        } else {
          view.$el.remove()
        }
      })
      App.Util.showAjaxFlashNotice(jqXHR)
    })
  },

  destroyAdminUser: function(id) {
    const userToDestroy = App.AdminUsers.listTableCollectionView.collection.get(id) ||
      App.AdminUsers.listTableCollectionViewPending.collection.get(id)
    if (!userToDestroy)
      return

    userToDestroy.destroy({
      wait: true,
      wbGenericFailureMsg: "Sorry, we were not able to remove this admin user.",
    }).done((data, status, jqXHR) => {
      App.Util.showAjaxFlashNotice(jqXHR)
    })
  },

  addEmployeeRecordAdminUser: function(id) {
    const adminUser = App.AdminUsers.listTableCollectionView.collection.get(id)
    if (!adminUser)
      return

    new ApiOperation({
      path: Routes.add_employee_record_admin_user_path({ id: adminUser.id }),
      httpMethod: 'PATCH',  // FIXME: Should be a POST
      ajaxOptions: {
        wbGenericFailureMsg: "Sorry, we couldn't create an employee record for this admin.",
      }
    }).done((data, status, jqXHR) => {
      App.Util.navigateAndShowModalNotice(data.location, jqXHR, { title: _.escape(data.modalTitle) })
    })
  },

})
