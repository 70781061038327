var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "dashboard-widget",
    {
      attrs: {
        widgetTitle: _vm.widgetTitle,
        errorMsg: _vm.errorMsg,
        lastUpdated: _vm.lastUpdated,
      },
      on: {
        refresh: function ($event) {
          return _vm.fetch(true)
        },
      },
    },
    [
      _c(
        "v-wait",
        { attrs: { for: _vm.source } },
        [
          _c("spinner", {
            attrs: { slot: "waiting", showLongWaitMessage: true },
            slot: "waiting",
          }),
          _vm._v(" "),
          !_vm.errorMsg.length && _vm.chartData
            ? _c("wb-breakdown-chart", {
                attrs: {
                  legendData: _vm.legendData,
                  chartData: _vm.chartData,
                  listViewPath: this.$routes.onboarding_i9_index_path,
                  modelNameHumanized: "Form I-9",
                },
                on: { "section-click": _vm.handleSectionClick },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }