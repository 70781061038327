<template>
  <WbWorkflowStep
    :stepNumber="stepNumber"
    title="Confirm Location"
  >
    <template>
      <AlertMessage
        class="mt-3"
        variant="warning"
        :message="alertMessage"
        ref="qrCodeAlert"
      />
      <slot name="instructions" />
      <AlertMessage
        v-if="qrMatched === false"
        message="Unfortunately, that scan did not match.  Please try again."
        variant="danger"
        ref="noMatchAlert"
      />
      <WbQrCodeReader
        v-if="scanActive"
        ref="scanQrCode"
        @wbQrCodeFound="handleLocationAttempt"
      />
      <WbButton
        v-else
        ref="scanQrCodeButton"
        @click="handleClickScanQrCode"
      >
        Begin QR Code Scan
      </WbButton>
    </template>
  </WbWorkflowStep>
</template>
<script>
  import AlertMessage from 'components/common/AlertMessage'
  import WbWorkflowStep from 'components/common/WbWorkflowStep'
  import WbButton from 'components/common/WbButton'
  import WbQrCodeReader from 'components/i9_remote_countersign/common/WbQrCodeReader'
  import { submitCountersignQrScan, submitReverifyQrScan } from 'services/submitQrService'
  import Util from 'lib/util'

  export const ACTIONS = ['countersign', 'reverify']

  export default {
    components: {
      AlertMessage,
      WbButton,
      WbQrCodeReader,
      WbWorkflowStep,
    },
    props: {
      actor: {
        type: String,
        require: true,
      },
      stepNumber: {
        type: Number,
      },
      alertMessage: {
        type: String,
      },
      qrCodeTarget: {
        type: String,
        required: true,
      },
      remoteId: {
        type: String,
        required: true,
      },
      action: {
        type: String,
        validator: value => {
          return ACTIONS.includes(value)
        },
      },
    },
    emits: [
      'match',
    ],
    data () {
      return {
        qrMatched: null,
        scanActive: false,
      }
    },
    computed: {
      submitAction () {
        return this.action === 'countersign' ?
          submitCountersignQrScan :
          submitReverifyQrScan
      },
    },
    methods: {
      handleClickScanQrCode () {
        this.scanActive = true
        this.qrMatched = null
      },
      handleLocationAttempt (qrCodeContent) {
        this.scanActive = false
        const isMatch = this.verifyQrCode(qrCodeContent)
        if (isMatch) {
          this.submitAction(this.remoteId, isMatch, this.actor)
            .then(() => { this.handleQrUpdate(isMatch) })
            .fail((xhr) => { Util.genericAjaxError(this.$locale.t('errors.ajax.generic'), xhr) })
        } else {
          this.qrMatched = false
        }
      },
      handleQrUpdate (isMatch) {
        this.$emit('match', isMatch)
      },
      verifyQrCode (qrCode) {
        return this.qrCodeTarget === qrCode
      },
    },
  }
</script>
