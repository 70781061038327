<template>
  <div v-if="showInstructions">
    <template v-if="isPendingCountersignActive">
      <div
        v-if="is_i9rc_on"
        ref="warningPendingCounterSignMessage"
        class="alert alert-warning">
        You have I-9 Remote Verification enabled. These forms will be remotely verified by the staff's chosen authorized representative unless you opt-out of remote verification or choose to sign them yourself.
      </div>
      <p
        v-else
        ref="pendingCounterSignInstructions">
        Select the I-9's you wish complete the Employer section for and click the button below to begin.
      </p>
    </template>
    <template v-if="isMissingRejected">
      <p ref="missingRejectedInstructions">
        Form I-9s that haven't been submitted by staff and submissions that have been rejected by administrators.
      </p>
    </template>
    <template v-if="isReverifyActive">
      <p ref="reverifyInstructions">
        Form I-9 submissions with expiring work authorization document(s) that require Section 2 updates.
      </p>
    </template>
    <hr />
  </div>
</template>

<script>
  const VALID_FILTERS = [
    'pending_countersign',
    'reverify',
    'missing_rejected'
  ]

  export default {
    name: 'i9-employer-instructions',
    props: {
      is_i9rc_on: {
        type: Boolean,
        required: true
      },
      filter: {
        type: String,
        required: true
      }
    },
    computed: {
      showInstructions () {
        return VALID_FILTERS.includes(this.filter)
      },
      isPendingCountersignActive () {
        return this.filter == 'pending_countersign'
      },
      isReverifyActive () {
        return this.filter == 'reverify'
      },
      isMissingRejected () {
        return this.filter == 'missing_rejected'
      }
    }
  }
</script>
