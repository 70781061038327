import XML2JSONParser from 'xml2json-light'

export default function AWSRequestXHRDecorator(xhr) {
  return Object.assign(xhr, {
    // AWS responses come back as XML documents. We use a lightweight parser to convert from XML to JSON. If parsing
    // fails, an empty object `{}` is returned.
    //
    // Returns - Object
    get responseJSON() {
      try {
        return XML2JSONParser.xml2json(xhr.responseText)
      } catch(e) {
        return {}
      }
    },
  })
}
