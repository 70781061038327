var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wb-multiselect wb-nested-select", class: _vm.classNames },
    [
      _c(
        "Multiselect",
        {
          ref: "multiselect",
          attrs: {
            closeOnSelect: false,
            options: _vm.options,
            placeholder: _vm.placeholder,
            searchable: _vm.enableSearchField,
            trackBy: _vm.valueKey,
            label: "label",
          },
          on: { input: _vm.handleInput },
          scopedSlots: _vm._u([
            {
              key: "option",
              fn: function (props) {
                return [
                  props.option.items
                    ? _c("div", { staticClass: "option__desc group-option" }, [
                        _c("span", { staticClass: "option__title" }, [
                          _vm._v(_vm._s(props.option.label)),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "option__items-nav" }, [
                          _c("span", { staticClass: "option__items-count" }, [
                            _vm._v(_vm._s(props.option.items.length)),
                          ]),
                        ]),
                      ])
                    : _c("div", { staticClass: "option__desc" }, [
                        _c("span", { staticClass: "option__title" }, [
                          _vm._v(_vm._s(props.option.label)),
                        ]),
                      ]),
                ]
              },
            },
          ]),
          model: {
            value: _vm.multiSelectValue,
            callback: function ($$v) {
              _vm.multiSelectValue = $$v
            },
            expression: "multiSelectValue",
          },
        },
        [
          _vm.groupLabel
            ? _c(
                "div",
                {
                  staticClass: "group__desc",
                  attrs: { slot: "beforeList" },
                  slot: "beforeList",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "multiselect__option",
                      on: { click: _vm.navigateOut },
                    },
                    [_vm._v("\n        " + _vm._s(_vm.groupLabel) + "\n      ")]
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }