var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("p", [
      _vm._v(
        "Please choose the form you would like these " +
          _vm._s(_vm.employeeIds.length) +
          " staff to re-submit:"
      ),
    ]),
    _vm._v(" "),
    _vm.errorMessage
      ? _c("div", { staticClass: "alert alert-danger" }, [
          _vm._v(_vm._s(_vm.errorMessage)),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "input-wrapper long-field" },
      [
        _c("WbSearch", {
          ref: "select",
          attrs: {
            options: _vm.options,
            placeholder: "Search forms by name",
            responseProcessor: _vm.processResponse,
            route: _vm.$routes.Api.search_documents_path,
          },
          model: {
            value: _vm.documentId,
            callback: function ($$v) {
              _vm.documentId = $$v
            },
            expression: "documentId",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "pt-3" }, [
      _c("small", [
        _vm._v(
          "\n      A new submission will only be requested from the staff members who are\n      "
        ),
        _c("strong", [_vm._v("already assigned to that form.")]),
        _vm._v(
          "\n      If they are not assigned to that form, they will not receive a submission request.\n    "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }