<template>
  <div>
    <p v-if="!managedPolicy" class="breathe">
      <i class="fa fa-info text-info fa-fw" />
      Groups selected: {{groupNames.join(', ')}}
    </p>
    <h4>
      {{ stepHeader }}
    </h4>
    <div class="alert alert-info">
      {{ fieldRestrictionsAlertMsg }}
    </div>
    <form class="form-horizontal">
      <div class="form-group">
        <div class="col-xs-12" v-for="(template, index) in templatedPermissions.templates" :key="index">
          <div class="radio">
            <label>
              <input type="radio" :value="template.name" v-model="selectedTemplate" />
              {{ template.description }}
            </label>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="radio">
            <label>
              <input type="radio" value="customize" v-model="selectedTemplate" />
              Customize
            </label>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
  import BaseStep from 'components/permissions/steps/BaseStep'
  import TemplatedDocumentPermissions from 'models/policy_generator/templated_document_permissions'
  import TemplatedCustomizedPermissions from 'models/policy_generator/templated_customized_permissions'
  import Locale from 'lib/locale'

  export default {
    name: 'step-documents',
    extends: BaseStep,

    props: {
      managedPolicy: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        selectedTemplate: null,
        templatedPermissions: new TemplatedDocumentPermissions(),
        fieldRestrictionsAlertMsg: Locale.t('permissions.documents_step.fieldRestrictionsinPdfs')
      }
    },

    computed: {
      matchingTemplate() {
        const permissionSet = this.policy.groupPermissionSet()
        return this.templatedPermissions.findMatchingTemplate(permissionSet.permissions.filter((p) => p.target_type == 'Document'))
      },

      stepHeader() {
        let headerText = null

        if(this.managedPolicy){
          headerText = this.$locale.t("permissions.documents_step.managed.stepHeader")
        }else{
          headerText = this.$locale.t("permissions.documents_step.stepHeader")
        }

        return headerText
      }
    },

    methods: {
      valid() {
        return !!this.selectedTemplate
      },
    },

    mounted() {
      const permissionSet = this.policy.groupPermissionSet()
      if(this.matchingTemplate) {
        this.selectedTemplate = this.matchingTemplate.name
      } else if(permissionSet.permissions.filter((p) => p.target_type == 'Document').length > 0) {
        this.selectedTemplate = 'customize'
      }
    },

    watch: {
      selectedTemplate: function(selectedTemplate, prevSelectedTemplate) {
        const permissionSet = this.policy.groupPermissionSet()

        if(selectedTemplate == 'customize') {
          if(!prevSelectedTemplate && permissionSet.permissions.filter((p) => p.target_type == 'Document').length == 0 || !!prevSelectedTemplate) {
            permissionSet.removeWhere({target_type: 'Document'})
            const permissions = new TemplatedCustomizedPermissions().permissionsForTemplate('Document')
            permissionSet.appendPermissions(permissions)
          }
        } else {
          const permissions = this.templatedPermissions.permissionsForTemplate(selectedTemplate)
          permissionSet.removeWhere({target_type: 'Document'})
          permissionSet.appendPermissions(permissions)
        }
      }
    }
  }
</script>
