import Vue from 'vue'
import deepmerge from 'deepmerge'
import Routes from 'lib/routes'
import { ajax } from 'jquery'
import { getRoute } from 'lib/routes'

export const mutationTypes = {
  LOAD_BATCH: 'LOAD_BATCH'
}

// Stores model permissions as a hierarchy of models
// {
//   model_name: {
//     1: {
//       action: true,
//       other_action: false
//     },
//     2: {
//       action: false,
//       other_action: false
//     }
//   },
//   model_b: {...}
// }
function state() {
  return {

  }
}

const getters = {
  // Is the user permitted to perform an action?
  // Generated by and generally matches to permissions in Permission Sets used by Pundit authorization.
  // Like the backend, the default of this method is to return `false` if permissions are not explicitly provided (i.e.
  // if model, record, or action are missing from the state tree).
  //
  // modelName: (String) pluralized name of the model (e.g. documents, document_assignments)
  // id: (Integer) record id
  // action: (String) 'confirm'
  //
  // returns: (Boolean) true - permitted to perform action, false - restricted
  isPermitted: (state) => (modelName, id, action) => {
    if(state[modelName] == undefined) { return false }

    const recordPermissions = state[modelName][id]
    if(recordPermissions == undefined) { return false }
    return recordPermissions[action] === true
  }
}

const actions = {
  // Load permissions data
  // Overwrites values for any existing permissions
  //
  // permissions (Object) - see description of `state` for format
  loadInitialData: ({ commit }, permissions) => {
    Object.keys(permissions).forEach((modelName) => {
      commit(mutationTypes.LOAD_BATCH, { modelName: modelName, records: permissions[modelName] })
    })
  },

  bulkAuthorize: ({ dispatch }, { resourceType, resourceIds, actions }) => {
    return new Promise((resolve, reject) => {
      ajax({
        method: 'POST',
        url: Routes.Api.bulk_authorize__private_permissions_path,
        data: JSON.stringify({
          resources: [
            {
              resource_type: resourceType,
              resource_ids: resourceIds,
              actions: actions
            }
          ]
        }),
        dataType: 'json',
        contentType: 'application/json'
      }).then((permissions) => {
        dispatch(
          `permissions/loadInitialData`,
          permissions,
          { root: true }
        )
        resolve()
      }).fail((xhr) => {
        reject(xhr)
      })
    })
  },

  fetchPermissionPolicies: ({ dispatch }) => {
    return new Promise((resolve, reject) => {
      ajax({
        method: 'GET',
        url: getRoute('resources_permissions_path'),
      }).then((data) => {
        for (const moduleName of Object.keys(data)) {
          dispatch(`${moduleName}/loadInitialData`, data[moduleName], { root: true })
          resolve()
        }
      }).fail(response => {
        reject(response)
      })
    })
  }
}

const mutations = {
  // Loads a batch of records for a single kind of model. This will update any existing records.
  //
  // payload -
  //    modelName (String) - pluralized name of model (e.g. documents, document_assignments, etc.)
  //    records (Object) - hash with structure:
  //      {
  //        id: {
  //          actionName: Boolean,
  //          actionName: Boolean,
  //          ...
  //        },
  //        ...
  //      }
  [mutationTypes.LOAD_BATCH]: (state, payload) => {
    Vue.set(
      state,
      payload.modelName,
      deepmerge(state[payload.modelName] || {}, payload.records))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
