<template>
  <WbModal
    title="Delete Admin"
    :watchPageContextVariable="modalKey"
    ref="modal"
  >
    <slot>
      <p>
        This will remove this person from your WorkBright account entirely. Are you sure this is what you want to do?
      </p>
    </slot>
    <div slot="footer">
      <WbButton
        context="default"
        @click="hideModal"
        :disabled="false"
      >
        Cancel
      </WbButton>
      <WbButton
        context="danger"
        @click="handleDelete"
        label="Delete Admin"
        :isLoading="isLoading"
      />
    </div>
  </WbModal>
</template>
<script>
  import Util from 'lib/util'
  import WbButton from 'components/common/WbButton'
  import WbModal from 'components/common/WbModal'
  import { mapActions } from 'vuex'

  export default {
    components: {
      WbButton,
      WbModal
    },
    props: {
      user: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        isLoading: false
      }
    },
    computed: {
      modalKey () {
        return `deleteAdminModal-${this.user.id}`
      },
    },
    methods: {
      ...mapActions({
        fetchDeleteAdmin: 'admin_users/delete'
      }),
      async handleDelete () {
        this.isLoading = true
        
        try {
          await this.fetchDeleteAdmin({ id: this.user.id })
        } catch {
          Util.showFlashNotice('Sorry, we were not able to remove this admin user.')
        } finally {
          this.isLoading = false
          this.hideModal()
          this.$emit('adminUpdate')
        }
      },
      hideModal () {
        this.$refs.modal.hide()
      },
    }
  }
</script>
