var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeader", {
        attrs: {
          title: "Admin Users",
          lead: "Manage the administrators for your company's WorkBright account. Promote staff to admin or create a new admin user.",
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex justify-content-between align-items-end" },
        [
          _c(
            "div",
            { staticClass: "btn-group my-3" },
            [
              _c(
                "a",
                {
                  staticClass: "btn btn-primary",
                  attrs: { href: _vm.$routes.new_admin_user_path },
                },
                [
                  _c("WbIcon", {
                    attrs: { slot: "prepend", type: "plus-circle" },
                    slot: "prepend",
                  }),
                  _vm._v("\n        New Admin\n      "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "WbButton",
                {
                  staticClass: "btn btn-primary dropdown-toggle",
                  attrs: {
                    "data-toggle": "dropdown",
                    "aria-label": "More Options",
                  },
                },
                [
                  _c("WbIcon", {
                    staticClass: "fa-fw right",
                    attrs: { type: "caret-down" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("ul", { staticClass: "dropdown-menu" }, [
                _c("li", [
                  _c(
                    "a",
                    { attrs: { href: _vm.$routes.promote_admin_users_path } },
                    [_vm._v("Promote existing staff member")]
                  ),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("WbSearchInput", {
            ref: "search",
            attrs: {
              label: null,
              placeholder: "Search",
              autofocus: true,
              value: _vm.query,
              throttle: true,
            },
            on: { input: _vm.handleSearchInput },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.shouldShowPendingAdmin
        ? _c("ul", { ref: "adminTypeNav", staticClass: "nav nav-tabs mb-3" }, [
            _c(
              "li",
              {
                staticClass: "nav-item",
                class: { active: _vm.view === "admin" },
              },
              [
                _c(
                  "a",
                  {
                    attrs: { role: "button", id: "adminUsersNav--admin" },
                    on: {
                      click: function ($event) {
                        _vm.view = "admin"
                      },
                    },
                  },
                  [_vm._v("\n        Admin Users\n      ")]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "li",
              {
                staticClass: "nav-item",
                class: { active: _vm.view === "pending" },
              },
              [
                _c(
                  "a",
                  {
                    attrs: { role: "button", id: "adminUsersNav--pending" },
                    on: {
                      click: function ($event) {
                        _vm.view = "pending"
                      },
                    },
                  },
                  [_vm._v("\n        Pending Admin Users\n      ")]
                ),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      [
        _vm.isLoading
          ? _c("Spinner", { ref: "loader" })
          : [
              _vm.hasSufficientData
                ? [
                    _c("AdminUsersTable", {
                      attrs: {
                        users: _vm.users,
                        isPendingAdmin: _vm.view === "pending",
                        id: "admin-users-table",
                      },
                      on: { update: _vm.handleUpdate },
                    }),
                    _vm._v(" "),
                    _c("WbListPaginationFooter", {
                      ref: "pagination",
                      attrs: {
                        previousPage: _vm.pagination.previous_page,
                        nextPage: _vm.pagination.next_page,
                        lastPage: _vm.pagination.total_pages,
                        totalItems: _vm.pagination.total_items,
                        indexStart: _vm.indexStart,
                        indexEnd: _vm.indexEnd,
                      },
                      on: { pageChange: _vm.handlePageChange },
                    }),
                  ]
                : _c("AlertMessage", {
                    ref: "noDataMessage",
                    attrs: {
                      alertType: "info",
                      message: "There are currently no admins to display",
                    },
                  }),
            ],
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }