var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "wb-modal",
    {
      ref: "modal",
      attrs: {
        title: "Schedule Notifications",
        watchPageContextVariable: "schedule_onboarding",
        locked: _vm.$wait.is("schedule_onboarding"),
      },
      on: { cancelled: _vm.resetErrors },
    },
    [
      _vm._t("default", [
        _vm.hasErrors
          ? _c(
              "div",
              { ref: "errors", staticClass: "alert alert-danger" },
              [
                _vm._v(
                  "\n      Please make sure that all required staff information is saved before scheduling notifications.\n      "
                ),
                _vm._l(Object.entries(_vm.errors), function (ref) {
                  var errorSource = ref[0]
                  var error = ref[1]
                  return _c(
                    "dl",
                    { key: errorSource, staticClass: "mt-1 mb-0" },
                    [
                      _c("dt", [_vm._v(_vm._s(errorSource))]),
                      _vm._v(" "),
                      _c("dd", [
                        _c(
                          "ul",
                          { staticClass: "list-unstyled" },
                          _vm._l(error, function (errorItem, index) {
                            return _c("li", { key: index }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(errorItem) +
                                  "\n            "
                              ),
                            ])
                          }),
                          0
                        ),
                      ]),
                    ]
                  )
                }),
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _c("schedule-onboarding", {
          ref: "scheduler",
          attrs: { label: _vm.label },
          model: {
            value: _vm.onboarding,
            callback: function ($$v) {
              _vm.onboarding = $$v
            },
            expression: "onboarding",
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "button",
            {
              staticClass: "btn btn-default",
              attrs: { disabled: _vm.$wait.is("schedule_onboarding") },
              on: { click: _vm.hide },
            },
            [_vm._v("\n      Cancel\n    ")]
          ),
          _vm._v(" "),
          _c(
            "ladda-button",
            {
              ref: "submit",
              attrs: { disabled: !_vm.valid },
              on: { click: _vm.submit },
            },
            [_vm._v("\n      Submit\n    ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }