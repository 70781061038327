var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("EditCompanyProfileContainer", {
    ref: "editProfileContainer",
    attrs: {
      companyProfileData: _vm.pageContext.companyProfileData,
      einFieldVisible: _vm.pageContext.einFieldVisible,
      formErrors: _vm.errors,
      states: _vm.pageContext.states,
      timezones: _vm.pageContext.timezones,
    },
    on: { "wb-save-company-profile": _vm.saveCompanyProfile },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }