// Filterable list of an Employee's groups and document assignments
// Similar to TheEmployeesDocumentAssignmentsIndexRoot but modified for the Rehire workflow.

<template>
  <reassign-forms-container />
</template>

<script>
  import { mapMutations } from 'vuex'
  import * as mutate from 'vuex/mutation_types'
  import ReassignFormsContainer from 'components/employees/reassignments/ReassignFormsContainer'

  export default {
    name: 'the-reassign-forms-root',

    components: {
      ReassignFormsContainer,
    },

    methods: {
      ...mapMutations({
        setPageContextKeys: mutate.SET_PAGE_CONTEXT_KEYS,
      }),
    }
  }
</script>
