<template>
  <div>
    <div class="wb-card--alert__section">
      <div class="wb-card--alert__category" v-if="categoryName">
        <span class="label label-plain mr-2" ref="category-name">
          {{ categoryName }}
        </span>
      </div>
      <div class="wb-card--alert__content">
        <div class="wb-card--alert__content__row" v-for="(value, name) in countsData" :key="name">
          <a :href="alertsMap[name].path" v-if="alertsMap[name]">
            <strong>
              {{ getPluralizedCount(countsData[name], alertsMap[name].model) }}
            </strong>
            {{ alertsMap[name].message }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import StringUtil from 'lib/util/string'

  export default {
    name: 'dashboard-widget-alert-item',

    methods: {
      getPluralizedCount (count, model) {
        return StringUtil.pluralize(count, model)
      }
    },

    props: {
      // An object representing the context of the alerts
      // i.e. { late: { path: '', model: '', message: '' }}
      alertsMap: {
        type: Object,
        required: true,
        validator: value => {
          // Verify all keys exist
          return Object.keys(value).every(key => {
            const obj = value[key]
            return ['path', 'model', 'message'].every(k => obj[k])
          })

        }
      },

      // An object that correlates to the keys in alertsMap but only includes counts
      // i.e. { late: 12, ... }
      countsData: {
        type: Object,
        required: true
      },

      // Optional category name that is displayed next to the alert
      categoryName: {
        type: String,
        required: false,
      }
    },
  }

</script>
