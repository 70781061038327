<template>
  <bulk-action-list-alert-details
    ref="bulkActionListAlertDetails"
    collectionModule="employees"
    :collectionViewName="collectionViewName"
    :displayNameLookupFn="displayNameLookupFn"
    permissionModule="employees"
    :permissionName="permissionName"
    :actionMethod="(f) => true"
    actionErrorMessage=""
  />
</template>

<script>
  import BulkActionListAlertDetails from 'components/common/BulkActionListAlertDetails'
  import { mapGetters } from 'vuex'

  export default {
    name: 'step-validate',

    components: {
      BulkActionListAlertDetails
    },

    props: {
      // The name of the collection store related metadata for the list
      collectionViewName: {
        type: String,
        required: true
      },

      permissionName: {
        type: String,
        required: true
      }
    },

    computed: {
      ...mapGetters({
        getDocument: 'documents/get',
        getEmployee: 'employees/get',
      })
    },

    methods: {
      valid() {
        return true
      },

      beforeAdvance() {
        this.$refs.bulkActionListAlertDetails.filter()
      },

      displayNameLookupFn(employee) {
        return this.$employee.fullName(employee)
      },
    },

    mounted() {
      // Everything is permitted, nothing to do here.
      if(this.$refs.bulkActionListAlertDetails.allPermittedAndActionable) {
        this.$emit('skip')
      }
    }
  }
</script>
