var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.customFields.length > 0
      ? _c("div", [
          _c("p", [
            _vm._v(
              "Please choose the field you would like to update for these " +
                _vm._s(_vm.employeeIds.length) +
                " staff:"
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "input-wrapper long-field" },
            [
              _c("WbMultiselect", {
                attrs: {
                  selectOptions: _vm.fields,
                  enableSearchField: true,
                  multiple: false,
                  placeholder: "Select a Field",
                },
                model: {
                  value: _vm.fieldKey,
                  callback: function ($$v) {
                    _vm.fieldKey = $$v
                  },
                  expression: "fieldKey",
                },
              }),
            ],
            1
          ),
        ])
      : _c("div", [
          _c("div", { staticClass: "alert alert-warning" }, [
            _vm._v("\n      No Custom Fields have been set up yet.\n      "),
            _c("a", { attrs: { href: _vm.$routes.new_custom_field_path } }, [
              _vm._v("Create a Custom Field."),
            ]),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }