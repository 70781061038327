var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "assignment-root" },
    [
      _c("document-assignment-list-container"),
      _vm._v(" "),
      _c("historical-submissions-container"),
      _vm._v(" "),
      _c("bulk-reset-assignments-container"),
      _vm._v(" "),
      _c("submission-audit-log-container"),
      _vm._v(" "),
      _c(
        "slideout-panel",
        {
          attrs: {
            watchPageContextVariable: "show_upload_submission",
            title: "Upload Submissions",
          },
        },
        [_c("upload-submission-select")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }