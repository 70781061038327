export default {
  certify_complete: {
    classNames: ['text-success'],
    icon: 'check-circle',
    label: 'i9SubmissionStatus.certify_complete'
  },
  certify_complete_remote: {
    classNames: ['text-success'],
    icon: 'check-circle',
    label: 'i9SubmissionStatus.certify_complete_remote'
  },
  certify_failed: {
    classNames: ['text-danger'],
    icon: 'exclamation-triangle',
    label: 'i9SubmissionStatus.certify_failed'
  },
  certify_failed_remote: {
    classNames: ['text-danger'],
    icon: 'exclamation-triangle',
    label: 'i9SubmissionStatus.certify_failed_remote'
  },
  certify_not_matched: {
    classNames: ['text-danger'],
    icon: 'exclamation-triangle',
    label: 'i9SubmissionStatus.certify_not_matched'
  },
  certify_not_matched_remote: {
    classNames: ['text-danger'],
    icon: 'exclamation-triangle',
    label: 'i9SubmissionStatus.certify_not_matched_remote'
  },
  certify_remote_verifying: {
    classNames: ['text-info', 'fa-spin'],
    icon: 'refresh',
    label: 'i9SubmissionStatus.certify_remote_verifying'
  },
  certify_remote_verifying_remote: {
    classNames: ['text-info', 'fa-spin'],
    icon: 'refresh',
    label: 'i9SubmissionStatus.certify_remote_verifying_remote'
  },
  certify_processing: {
    classNames: ['text-info', 'fa-spin'],
    icon: 'refresh',
    label: 'i9SubmissionStatus.certify_processing'
  },
  certify_processing_remote: {
    classNames: ['text-info', 'fa-spin'],
    icon: 'refresh',
    label: 'i9SubmissionStatus.certify_processing_remote'
  },
  missing: {
    label: 'i9SubmissionStatus.missing'
  },
  paper: {
    label: 'i9SubmissionStatus.paper'
  },
  pending: {
    label: 'i9SubmissionStatus.pending',
    icon: 'clock-o',
  },
  rejected: {
    classNames: ['text-danger'],
    label: 'i9SubmissionStatus.rejected',
    icon: 'ban'
  },
  uncertified: {
    label: 'i9SubmissionStatus.uncertified',
    icon: 'pencil-square-o',
  },
  uncertified_remote: {
    classNames: ['text-warning-light'],
    label: 'i9SubmissionStatus.uncertified_remote',
    icon: 'circle',
  },
}
