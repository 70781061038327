<template>
  <FeatureRequestForm
    ref="featureRequestForm"
    :title="title"
    :description="description"
    :featureName="featureName"
  />
</template>

<script>
  import FeatureRequestForm from 'components/admin/FeatureRequestForm'

  export default {
    name: 'the-feature-request-root',

    components: {
      FeatureRequestForm
    },

    props: {
      title: {
        type: String,
        required: false
      },
      description: {
        type: String,
        required: false
      },
      featureName: {
        type: String,
        required: true
      }
    },
  }
</script>
