var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "search-select-wrapper" },
      [
        _c("WbSearch", {
          ref: "select",
          attrs: {
            width: "100%",
            route: _vm.route,
            responseProcessor: _vm.processResponse,
            placeholder: "Search Staff Members",
          },
          on: { input: _vm.handleInput },
          model: {
            value: _vm.localValue,
            callback: function ($$v) {
              _vm.localValue = $$v
            },
            expression: "localValue",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }