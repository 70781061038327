export default function(Sector, App, Backbone, Marionette, $, _){

  Sector.payloadUser = null
  Sector.callback = null

  // Listen to a global event to kick off the Policy Generator
  // Loads the policy generator page with Turbolinks
  // Calls the callback with the permissoins when finished
  //
  // user: A well-formed User object, containing a first_name at minimum
  // example:
  // {
  //   employee_profile: {
  //     first_name: 'Jerry'
  //   }
  // }
  //
  // callback: A callback function that accepts one parameter, User, when wizard is finished
  // example:
  // function(user){}
  Sector.listenTo(App.globalVent, 'admin_policy_generator:start', function(user, callback){ 
    Sector.payloadUser = user
    Sector.callback = callback
    Turbolinks.visit(`/admin/permissions/start?user_id=${user.id}&first_name=${encodeURIComponent(user.employee_profile.first_name)}`)
  })

  Sector.addInitializer(function() {
    Sector.listenTo(App.vent, 'admin_policy_generator:wizard_finished', function(permissions){
      var permissionJSON = permissions.toJSON()
      Sector.payloadUser.permission_sets = permissionJSON
      if(_.isFunction(Sector.callback))
        Sector.callback(Sector.payloadUser)
    })

  })
};
