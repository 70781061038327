var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("employment-dates", {
        attrs: { orientation: "horizontal", errors: this.fieldErrors },
        model: {
          value: _vm.employment,
          callback: function ($$v) {
            _vm.employment = $$v
          },
          expression: "employment",
        },
      }),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("schedule-onboarding", {
        attrs: { label: "Schedule Notifications", orientation: "horizontal" },
        model: {
          value: _vm.onboarding,
          callback: function ($$v) {
            _vm.onboarding = $$v
          },
          expression: "onboarding",
        },
      }),
      _vm._v(" "),
      _vm.shouldShowRI9NotificationMethodsToggle
        ? _c(
            "div",
            { ref: "rI9NotificationMethod", staticClass: "form-group" },
            [
              _c("div", { staticClass: "control-label col-sm-4 col-md-3" }, [
                _vm._v("\n      Remote I-9 Notification Method:\n    "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-sm-8 col-md-9" },
                [
                  _c("EmployeeNotificationMethodsToggle", {
                    attrs: {
                      name: _vm.formPrefix + "[ri9_notification_methods]",
                      type: "ri9",
                    },
                    model: {
                      value: _vm.ri9NotificationMethods,
                      callback: function ($$v) {
                        _vm.ri9NotificationMethods = $$v
                      },
                      expression: "ri9NotificationMethods",
                    },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.shouldShowOnboardingNotificationMethodsToggle
        ? _c(
            "div",
            { ref: "onboardingNotificationMethod", staticClass: "form-group" },
            [
              _c("div", { staticClass: "control-label col-sm-4 col-md-3" }, [
                _vm._v("\n      Onboarding Notification Method:\n    "),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-sm-8 col-md-9" },
                [
                  _c("EmployeeNotificationMethodsToggle", {
                    attrs: {
                      name:
                        _vm.formPrefix + "[onboarding_notification_methods]",
                      type: "onboarding",
                    },
                    model: {
                      value: _vm.onboardingNotificationMethods,
                      callback: function ($$v) {
                        _vm.onboardingNotificationMethods = $$v
                      },
                      expression: "onboardingNotificationMethods",
                    },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }