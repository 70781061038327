var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-md-3" },
      [
        _c("SortOrder", {
          ref: "sort-order",
          attrs: {
            filter: _vm.sortBy.filter,
            collectionViewName: _vm.collectionViewName,
          },
        }),
        _vm._v(" "),
        _c("ListFilterSet", {
          ref: "list-filter-set",
          staticClass: "pt-4 mt-4",
          attrs: {
            filters: _vm.filters,
            collectionViewName: _vm.collectionViewName,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-9" }, [
      _c(
        "div",
        { staticClass: "wb-list everify-case-list" },
        [
          _c("WbListHeader", {
            ref: "list-header",
            attrs: {
              filteredRowIds: [],
              collectionViewName: _vm.collectionViewName,
              searchPlaceholder: "Search Staff Name...",
            },
          }),
          _vm._v(" "),
          _c(
            "v-wait",
            { attrs: { for: _vm.loadingName } },
            [
              _c("Spinner", {
                attrs: { slot: "waiting", showLongWaitMessage: true },
                slot: "waiting",
              }),
              _vm._v(" "),
              _vm.everifyCases.length == 0
                ? _c("p", { ref: "no-cases" }, [
                    _vm._v("\n          No matching E-Verify cases\n        "),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "ul",
                { ref: "case-list", staticClass: "list-group" },
                _vm._l(_vm.everifyCases, function (everifyCase) {
                  return _c("EverifyCaseListItem", {
                    key: everifyCase.id,
                    attrs: {
                      everifyCase: everifyCase,
                      collectionViewName: _vm.collectionViewName,
                    },
                    on: { caseDeleted: _vm.handleCaseDeleted },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _vm.displayPagination
                ? _c("WbListPaginationFooter", {
                    ref: "pagination",
                    attrs: {
                      previousPage: _vm.pagination.previousPage,
                      nextPage: _vm.pagination.nextPage,
                      lastPage: _vm.pagination.lastPage,
                      totalItems: _vm.pagination.totalItems,
                      indexStart: _vm.pagination.indexStart,
                      indexEnd: _vm.pagination.indexEnd,
                      collectionViewName: _vm.collectionViewName,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }