var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wb-card" }, [
    _c("div", { staticClass: "wb-card__title" }, [
      _vm._v("\n    " + _vm._s(_vm.widgetTitle) + "\n    "),
      _vm.lastUpdated
        ? _c(
            "p",
            { staticClass: "wb-card__last_updated" },
            [
              _vm._v("\n      Last updated\n      "),
              _c("wb-simple-date-time", {
                attrs: { value: _vm.lastUpdated, includeTimeago: true },
              }),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "ml-3",
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("refresh")
                    },
                  },
                },
                [
                  _vm._v("\n        Refresh\n        "),
                  _c("WbIcon", {
                    staticClass: "ml-1",
                    attrs: { type: "refresh" },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "wb-card__body" },
      [
        _c("p", [
          _vm.errorMsg.length
            ? _c("em", [
                _vm._v("\n        " + _vm._s(_vm.errorMsg) + "\n      "),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _vm._t("default"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }