var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wb-multiselect", class: _vm.classNames },
    [
      _c("Multiselect", {
        attrs: {
          "allow-empty": _vm.allowEmpty,
          options: _vm.options,
          label: "label",
          "track-by": "value",
          multiple: _vm.multiple,
          "close-on-select": !_vm.multiple,
          "clear-on-select": false,
          disabled: _vm.isDisabled,
          "group-values": _vm.groupValues,
          "group-label": "label",
          "group-select": _vm.selectAll,
          searchable: _vm.enableSearchField,
          taggable: false,
          placeholder: _vm.placeholderValue,
        },
        on: { input: _vm.handleInput },
        scopedSlots: _vm._u([
          {
            key: "selection",
            fn: function (slotProps) {
              return [
                slotProps.values.length && !slotProps.isOpen
                  ? _c("span", { staticClass: "multiselect__single" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.getSelectedText(slotProps.values)) +
                          "\n      "
                      ),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
        model: {
          value: _vm.localValue,
          callback: function ($$v) {
            _vm.localValue = $$v
          },
          expression: "localValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }