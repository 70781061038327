// Wizard
//
// Controls wizard internal state, including:
// - list of steps
// - step control
// - completion percentage
//
// steps - Array of WizardSteps
//
import StepStore from 'models/step_store'
import Animations from 'lib/util/animations'


// Constructor
//
// steps - Array of WizardSteps
// model - Backbone Model edit by wizard
// onFinish - Wizard finished callback
export default Marionette.Object.extend({
  initialize: function() {
    this.model = this.getOption('model')  // Model edited by wizard
    this.steps = this.getOption('steps')  // Array of WizardSteps
    this.stepStore = this.getOption('context') || new StepStore()
    this.onFinish = this.getOption('onFinish')

    this._currentStepIdx = 0  // Index of the current active step
    this._currentStep = this.steps[0]  // WizardStep of the current, active step
    this._currentStepView = null  // View instance for the current, active step
  },

  currentStep: function(){
    return this._currentStep
  },

  // Get instance of View for the current step in the wizard
  //
  // Returns - View
  viewForCurrentStep: function() {
    if (this._currentStepView == null) {
      var globalContext = this.stepStore.register('global')
      var stepContext = this.stepStore.register('step_' + this._currentStepIdx)

      stepContext.set(globalContext.attributes)

      this._currentStepView = this._currentStep.createView(stepContext, this.model)
    }

    return this._currentStepView
  },

  // Number of steps in the wizard
  //
  // returns integer number of steps
  stepCount: function(){
    return this.steps.length
  },

  // Is the wizard at the start?
  //
  // returns bool
  isAtStart: function(){
    return this._currentStepIdx == 0
  },

  // Is the wizard at the end?
  //
  // returns bool
  isAtEnd: function(){
    return (this._currentStepIdx + 1) == this.stepCount()
  },

  // Index of the current step
  //
  // returns integer
  currentStepIdx: function(){
    return this._currentStepIdx
  },

  // Transition to the next step
  //
  // callback - function(nextStep) callback function which shows the next view
  //
  // callback - function for rendering the new view, if necessary. Accepts one argument: view
  // el - Wizard element to fade out/in during transition
  //
  // returns - View for the next step
  advanceToNextStep: function(callback, el) {
    var view = null

    // Validate the step before moving forward
    this._currentStep.validate(function validationCompleteCallback(isValid) {
      if (isValid) {
        Animations.fade(el, false, {callback: function(){
            // If the substep is still in process, advance it.
            if(!this._currentStep.isFinished()){
              // Update is handled during the fade out transition
              this._currentStep.advance()
            } else {
              // Not a substep, or at the end. Build a new view.
              this._currentStep.releaseView()
              this._currentStep = this.steps[++this._currentStepIdx]
              this._currentStepView = null
              view = this.viewForCurrentStep()
            }

            callback(view)
            this.triggerMethod('step:change')
            this.emitPercentage()
            Animations.fade(el, true)
            Animations.scrollTo($('body'), {offset: 0})
          }.bind(this)
        })
      } else {
        callback(view)
        this.emitPercentage()
      }
    }.bind(this))
  },

  // Finish the wizard. `onFinish` callback is fired with validationCompleteCallback `data`if present.
  //
  // invalidCallback - Callback if final validation fails
  finish: function(invalidCallback){
    this._currentStep.validate(function validationCompleteCallback(isValid, data) {
      if(isValid && _.isFunction(this.onFinish)) {
        this.onFinish(data)
      } else {
        invalidCallback()
      }
    }.bind(this))
  },

  // Transition to the previous step
  //
  // callback - function for rendering the new view, if necessary. Accepts one argument: view
  // el - Wizard element to fade out/in during transition
  //
  // returns - View for the previous step
  retreatToPrevStep: function(callback, el){
    var view = null

    Animations.fade(el, false, {callback: function(){
        // If the substep is still in process, retreat it
        if(!this._currentStep.isStarting() && this._currentStep.canRetreatToSubStep()){
          // Update is handled during the fade out transition
          this._currentStep.retreat()
        } else {
          // Not a substep, or at the start. Build a new view.
          this._currentStep.releaseView()
          this._currentStepIdx = this._currentStepIdx - this._currentStep.stepsToRetreat()
          this._currentStep = this.steps[this._currentStepIdx]
          this._currentStepView = null
          view = this.viewForCurrentStep()
        }

        callback(view)
        this.triggerMethod('step:change')
        this.emitPercentage()
        Animations.fade(el, true)
        Animations.scrollTo($('body'), {offset: 0})
      }.bind(this)
    })
  },

  // Returns - Boolean
  canAdvance: function() {
    return !(this.isAtEnd() && this._currentStep.isFinished())
  },

  // Returns - Boolean
  canRetreat: function() {
    return !(this.isAtStart() && this._currentStep.isStarting())
  },

  // Returns - Boolean
  canFinish: function() {
    return (this.isAtEnd() && this._currentStep.isFinished())
  },

  // Completion percentage
  //
  // returns integer percentage completed, 0 - 100
  emitPercentage: function(){
    // Total percent complete of all past step
    var pastStepIds = _.range(this._currentStepIdx)
    var stepPercentages = _.map(pastStepIds, function(i){return this.steps[i].percentage()}.bind(this))
    var pastStepPercent = _.reduce(stepPercentages, function(total, step){ return total + step }, 0)

    // Total percent complete in the current step (e.g. substeps)
    // Multiplied by the percentage of the step to get the relative percentage
    // e.g. A step is 50% of the wizard. This step is 25% complete. This amounts to 12.5% of the total wizard.
    var currentStepCompletePercent = this.steps[this._currentStepIdx].completePercentage()
    var currentStepPercent = this.steps[this._currentStepIdx].percentage()
    var relativePercent = parseInt(currentStepCompletePercent * (currentStepPercent / 100))

    var totalPercent = pastStepPercent + relativePercent
    this.triggerMethod('progressChange', totalPercent)
  }

})
