import PrerenderedTableCollectionView from 'src/views/common/prerendered_table_collection_view'
import TableRowView from 'src/views/supplemental_files/row_item_view'
import Routes from 'lib/routes'

export default PrerenderedTableCollectionView.extend({
  childView: TableRowView,

  onChildviewSupplementalFileDownload: function (args) {
    let downloadPath = Routes.download_employee_supplemental_file_path({employee_id: args.model.get('employee_id'), id: args.model.id})
    window.location = downloadPath
  },

  onChildviewSupplementalFileDelete: function (args) {
    var options = {
      confirmMessage: 'This will destroy this file. Are you sure this is what you want to do?',
      confirmBtnLabel: 'Delete File'
    }

    App.Util.Dialogs.confirmation(options, function () {
      App.vent.trigger('employee:supplemental_files:delete', args.model)
    })
  },

  onChildviewSupplementalFileView: function (args) {
    let builtInShowPath = Routes.employee_supplemental_file_path({employee_id: args.model.get('employee_id'), id: args.model.id})
    let download_path = args.model.get('file_path')
    let unsupported = args.model.get('viewer_type') == 'unsupported'

    if (unsupported) {
      window.location.href = download_path
    } else {
      Turbolinks.visit(builtInShowPath)
    }
  },

  onChildviewSupplementalFileEdit: function (args) {
    const editPath = Routes.edit_employee_supplemental_file_path({
      employee_id: args.model.get('employee_id'),
      id: args.model.id
    })
    Turbolinks.visit(editPath)
  },
})
