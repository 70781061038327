<template>
  <div>
    <FormFieldRadio
      ref="radio"
      :label="label"
      :options="options"
      v-model="value.selection"
      :errors="errors"
      @input="updateSelection"
      :orientation="orientation"
    >
      <div class="pt-2 pl-3" slot="select-date">
        <FormFieldDate
          :minDate="minDate"
          v-model="value.date"
        />
      </div>
    </FormFieldRadio>
  </div>
</template>

<script>
  import FormFieldDate from 'components/common/FormFieldDate'
  import FormFieldRadio from 'components/common/FormFieldRadio'
  import DateTime from 'lib/util/datetime'
  import moment from 'moment'

  export default {
    name: 'schedule-onboarding',

    components: {
      FormFieldDate,
      FormFieldRadio
    },

    props: {
      label: {
        type: String,
        required: true
      },

      // Object with two properties:
      // selection: String, option selected by the radio group (never, schedule, now)
      // date: String date, or null
      value: {
        type: Object,
        required: true
      },

      orientation: {
        type: String,
        required: false
      },
    },

    data() {
      return {
        errors: {},

        selection: null,
        date: null,

        options: [
          {
            label: "<strong>Send Notifications Now:</strong> Staff member will immediately receive a welcome notification and begin receiving other notifications.",
            popoverText: this.$locale.t('employments.help_text.onboard_now'),
            popoverTitle: 'Send Notifications Now',
            value: 'now',
            html: true,
          },
          {
            label: "<strong>Send Notifications Later:</strong> Staff member will receive a welcome notification on a future date; no other notifications will be sent until that time.<br/><small>Hint: Select this if you'd like time to prepare their forms and field data.</small>",
            popoverText: this.$locale.t('employments.help_text.onboard_later'),
            popoverTitle: 'Send Notifications Later',
            value: 'schedule',
            slot: 'select-date',
            html: true
          },
          {
            label: "<strong>Don't Send Notifications:</strong> Staff member will never receive a welcome notification or any other notifications.",
            popoverText: this.$locale.t('employments.help_text.onboard_never'),
            popoverTitle: "Don't Send Notifications",
            value: 'never',
            html: true
          }
        ],
      }
    },

    computed: {
      minDate() {
        return moment().add(1, 'days').toDate()
      }
    },

    methods: {
      updateSelection(option) {
        switch(option) {
          case 'now':
            this.value.date = moment.tz(DateTime.timezoneIANA()).format() // convert to date in Mountain (MST) timezone
            break
          case 'select-date':
            // Set by Pikaday
            this.value.date = null
            break
          case 'never':
          default:
            this.value.date = null
            break
        }

      }
    },
  }
</script>
