var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "SlideoutPanelExtended",
    {
      ref: "slideout",
      attrs: { size: "xl", watchPageContextVariable: _vm.slideoutKey },
      on: { modalShow: _vm.handleModalShow },
    },
    [
      _c(
        "template",
        { slot: "extended-content" },
        [
          _c("SupportingDocumentation", {
            attrs: { documentationSet: _vm.attachments },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._t("primary-title", [
        _c(
          "div",
          {
            staticClass: "panel-title",
            attrs: { slot: "primary-title" },
            slot: "primary-title",
          },
          [_c("h3", [_vm._v("Document Verification")])]
        ),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "primary-content" },
        [
          _vm.isLoading
            ? _c("Spinner", { ref: "loader" })
            : _vm.hasSufficientData
            ? [
                _vm.canApproveDecline
                  ? _c(
                      "div",
                      { ref: "approveDeclineButtons", staticClass: "mb-2" },
                      [
                        _c("AlertMessage", {
                          ref: "canApproveMessage",
                          attrs: {
                            message: _vm.canApproveMessage,
                            variant: "warning",
                          },
                        }),
                        _vm._v(" "),
                        _c("WbButton", {
                          attrs: { context: "success", label: "Approve" },
                          on: {
                            click: function ($event) {
                              return _vm.handleApproveDecline(true)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("WbButton", {
                          attrs: { context: "danger", label: "Decline" },
                          on: {
                            click: function ($event) {
                              return _vm.handleApproveDecline(false)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _c("AlertMessage", {
                      ref: "checkMessage",
                      attrs: { message: _vm.checkMessage, variant: "warning" },
                    }),
                _vm._v(" "),
                _vm._l(_vm.attachments, function (attachment, index) {
                  return _c("InquiryAuditLog", {
                    key: index,
                    attrs: {
                      inquiry: attachment.document_verification,
                      documentName: attachment.document_title,
                    },
                  })
                }),
                _vm._v(" "),
                _vm.isVerified
                  ? _c("DocumentVerificationLog", {
                      attrs: { submission: _vm.submission },
                    })
                  : _vm._e(),
              ]
            : _c("AlertMessage", {
                ref: "noDataMessage",
                attrs: { message: "Unable to retrieve verification data" },
              }),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }