var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form-field",
    {
      attrs: {
        label: _vm.label,
        name: _vm.name,
        orientation: _vm.orientation,
        required: _vm.required,
        hint: _vm.hint,
        errors: _vm.errors,
      },
    },
    _vm._l(_vm.radioOptions, function (option, index) {
      return _c("div", { key: index, staticClass: "input-wrapper" }, [
        _c("span", { staticClass: "radio" }, [
          _c(
            "label",
            { attrs: { for: _vm.optionId(option) } },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.radioValue,
                    expression: "radioValue",
                  },
                ],
                staticClass: "radio_buttons required ",
                attrs: {
                  type: "radio",
                  name: _vm.name,
                  id: _vm.optionId(option),
                },
                domProps: {
                  value: option.value,
                  checked: _vm._q(_vm.radioValue, option.value),
                },
                on: {
                  change: function ($event) {
                    _vm.radioValue = option.value
                  },
                },
              }),
              _vm._v(" "),
              option.html
                ? _c("span", { domProps: { innerHTML: _vm._s(option.label) } })
                : _c("span", [
                    _vm._v(
                      "\n          " + _vm._s(option.label) + "\n        "
                    ),
                  ]),
              _vm._v(" "),
              option.popoverText
                ? _c(
                    "a",
                    {
                      ref: "popover",
                      refInFor: true,
                      staticClass: "help-popover",
                      attrs: {
                        href: "javascript:void(0)",
                        title: option.popoverTitle,
                        "data-content": option.popoverText,
                        "data-placement": "auto top",
                        role: "button",
                        tabIndex: "0",
                        "data-toggle": "popover",
                        "data-trigger": "focus",
                      },
                    },
                    [_c("i", { staticClass: "fa fa-question-circle" })]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.value == option.value ? _vm._t(option.slot) : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _vm.isOther(option.value)
            ? _c("span", { staticClass: "medium-field" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.otherValue,
                      expression: "otherValue",
                    },
                  ],
                  staticClass: "ml-4 mt-1 string required form-control",
                  attrs: {
                    disabled: _vm.otherValueDisabled,
                    placeholder: "Please specify",
                    type: "text",
                    name: _vm.nameOther,
                    id: _vm.nameOther,
                  },
                  domProps: { value: _vm.otherValue },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.otherValue = $event.target.value
                      },
                      function ($event) {
                        return _vm.$emit("input", $event.target.value)
                      },
                    ],
                  },
                }),
              ])
            : _vm._e(),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }