var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.inProgress
    ? _c(
        "a",
        {
          ref: "adminCancelReverifyButton",
          attrs: { role: "button", id: "admin-cancel-reverify-button" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.handleCancelReverify($event)
            },
          },
        },
        [
          _c("WbIcon", { attrs: { iconSet: "fa", type: "times" } }),
          _vm._v("\n  Cancel Remote Reverify\n"),
        ],
        1
      )
    : !_vm.supplementBFull && _vm.canRequestReverify
    ? _c(
        "a",
        {
          ref: "adminInitiateReverifyButton",
          staticClass: "admin-initiate-reverify-button",
          attrs: { role: "button" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.handleBeginReverify($event)
            },
          },
        },
        [
          _c("WbIcon", {
            attrs: { iconSet: "a4s-icon", type: "signature-pen" },
          }),
          _vm._v("\n  Request Remote Reverify\n"),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }