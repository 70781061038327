// A single user Permissions, for use with the policy generator
// options:
//   target_id:
//   target_type:
//   action:
//   grant:
class Permission {
  constructor(options = {}) {
    this.dependentOn = null // Does granting this permission require another permission to be granted? (e.g. modify requires view)
    this.dependents = [] // Do other permissions rely on this permission being granted?

    this.target_id = options.target_id
    this.target_type = options.target_type
    this.action = options.action || null
    this.grant = options.grant || false
  }

  // This permission cannot be enabled if a parent permission has not been granted
  //
  // returns - Boolean
  disabled() {
    return !!this.dependentOn && !this.dependentOn.grant
  }

  // A unique identifier for comparisons
  //
  // returns - String
  key() {
    return `${this.target_id}_${this.target_type}_${this.action}_${this.grant}`
  }

  // Appends a dependent permission to the dependentOn list
  //
  // dependent: Permission
  //
  addDependent(dependent) {
    dependent.dependentOn = this
    if(this.dependents.indexOf(dependent) == -1) {
      this.dependents.push(dependent)
    }
  }

  // Build a JSON object
  //
  // returns - Object
  toJSON() {
    return {
      target_id: this.target_id,
      target_type: this.target_type,
      action: this.action,
      grant: this.grant
    }
  }

  // Get the name of the target
  // target_type 'RepresentableField' will return field label from Vuex, or default
  // target_type 'Document' will return document name from Vuex, or default
  //
  // store - Vuex Store
  //
  // returns - String
  //
  // FIXME: Wrap this in some kind of view decorator?
  targetName(store) {
    let name = null

    switch(this.target_type) {
      case 'RepresentableField':
        if(this.target_id) {
          const field = store.getters['representable_fields_tree/get'](this.target_id)
          name = field.label
        } else {
          name = 'All other fields'
        }
        break
      case 'Document':
        if(this.target_id) {
          const document = store.getters['documents/get'](this.target_id)
          name = document.name
        } else {
          name = 'All other forms'
        }
        break
      default:
    }
    return name
  }

  // Return the name of the action
  //
  // returns - String
  //
  // FIXME: Wrap this in some kind of view decorator?
  actionName() {
    const actionNames = {
      RepresentableField: {
        view: 'View',
        update: 'Edit'
      },
      Document: {
        view_submission: 'View & Download',
        update_submission: 'Update',
        unlock_submission: 'Request New Submission',
        accept_submission: 'Accept/Reject',
        upload_submission: 'Set as Current & Upload',
        edit_attachments: 'Edit Images',
        countersign_submission: 'Countersign',
        manage: 'Set Up Forms'
      },
      Employee: {
        view: 'View staff',
        create: 'Add staff',
        destroy: 'Delete staff entirely from WorkBright'
      },
      Report: {
        manage: 'Run and Save Reports'
      },
      EmployeeGroupMembership: {
        manage: 'Change which groups staff belong to'
      },
      EmployeeSeason: {
        manage: 'Re-hire or change employment dates',
        change_status: 'Deactivate (or re-activate) staff'
      },
      SupplementalFile: {
        view: 'View supplemental file attachments',
        manage: 'Add and modify supplemental file attachments'
      },
    }

    return actionNames[this.target_type][this.action]
  }
}

export default Permission
