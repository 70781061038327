/*
 * HEY YOU!
 * Modularizing this file is a WIP! Please see README and consider helping out if you find yourself working on or adding
 * to these lib/utility functions.
 */
import Constants from 'lib/constants'
import WBRequestXHRDecorator from 'decorators/wb_request_xhr_decorator'


export default {
  getPageBodyData: function(key) {
    return $("body").data(key)
  },

  getPageRootPath: function() {
    return this.getPageBodyData("root-path")
  },

  genericAjaxError: function(message, xhr, options) {
    message = message + "<br/><br/>This is likely an error on our end, and we've been notified of the problem. If this " +
                            "continues to happen, please open a <a href='" + Constants.SUPPORT_URL + "' target='_blank'>Support Request</a>."
    return this.ajaxErrorDialog(message, xhr, options)
  },

  ajaxErrorDialog: function(message, xhr, options = {}) {
    const closeButtonTitle = options.closeButtonTitle || 'Close'
    const closeButtonClasses = options.closeButtonClass || 'btn-primary'

    // Force parse the responseText into responseJSON for showing errorDetails
    if (options.forceParseResponse === true) {
      xhr.responseJSON = JSON.parse(xhr.responseText)
    }

    const buttons = _.extend({
      closeButton: {
        label: closeButtonTitle,
        className: closeButtonClasses,
      }
    }, options.buttons)

    const title = options.title || 'Uh oh! Something went wrong.'
    const fullMessage = message + this.ajaxErrorDetails(xhr)

    bootbox.dialog({
      message: fullMessage,
      title: title,
      className: "modal-danger",
      buttons,
    })
  },

  ajaxErrorDetails: function(xhr) {
    let details = ''

    // Flash level error message
    if (xhr.responseURL && xhr.responseURL.indexOf(gon.global.JS_ENV.DOMAIN_NAME) >= 0) {
      const flash = JSON.parse(xhr.getResponseHeader('X-Flash-Messages'))
      if (flash && flash.error) {
        details = _.escape(flash.error) + "<br/>"
      }
    }

    // JSON response level error message
    if (_.has(xhr, "responseJSON") && xhr.responseJSON && _.has(xhr.responseJSON, "errors")) {
      const errors = xhr.responseJSON.errors
      if (Array.isArray(errors)) {
        details += errors.map(error => _.escape(error)).join('<br/>')
      } else if (typeof errors === 'object') {
        details += Object.entries(errors).map(([source, error]) => {
          return `${_.escape(source)}: ${_.escape(error)}`
        }).join('<br/>')
      } else {
        details += _.escape(errors)
      }
    }

    return this.underscoreTemplateToHTML("#ajax-error-tmpl", {
      statusCode: xhr.status,
      statusText: xhr.statusText,
      details
    })
  },

  // DEPRECATED. Use WBRequestXHRDecorator instead.
  getAjaxFlashMessages: function(xhr) {
    return WBRequestXHRDecorator(xhr).getFlashMessages()
  },

  showAjaxFlashInTarget: function($targetEl, message, options) {
    const defaultOptions = {
      alertType: 'info',
      alertDismissable: true,
      replaceTarget: true
    }
    options = _.extend(defaultOptions, options)

    const html = this.underscoreTemplateToHTML("#ajax-flash-unescaped-tmpl",
      { alertType: Marionette.getOption(options, "alertType"),
        message: message,
        alertDismissable : Marionette.getOption(options, "alertDismissable") })

    let $flashContainer
    const replaceTarget = Marionette.getOption(options, "replaceTarget")

    if (replaceTarget) {
      $targetEl.html(html)
      $flashContainer = $targetEl
    } else {
      $targetEl.append('<div class="ajax-flash-container">' + html + '</div>')
      $flashContainer = $targetEl.children(".ajax-flash-container")
    }

    if ($flashContainer.is(":hidden")) {
      $flashContainer.slideDown('fast')
    } else {
      // already visible? clear and reset the fadeout queue.
      if ($flashContainer.data('timer'))
        clearTimeout($flashContainer.stop().data('timer'))
    }

    const optionFadeAfter = Marionette.getOption(options, "fadeAfter")
    if (optionFadeAfter) {
      $flashContainer.data('timer', setTimeout(function() {
        $flashContainer.fadeOut("slow", function() {
          // when we replaceTarget, the entire target becomes the container; otherwise, we want to remove
          // the container upon fadeout so that messages don't just build up in there
          if (!replaceTarget)
            $(this).remove()
        })
      }, optionFadeAfter))
    }
  },

  errorDialog: function(message, title) {
    const buttons = {
      "Close": {
        className: "btn-primary"
      }
    }

    bootbox.dialog({
      message: message,
      title: title,
      className: "modal-danger",
      buttons: buttons
    })
  },

  showAjaxFlashNotice: function(xhr, options) {
    const message = this.getAjaxFlashMessages(xhr).notice
    if (!message || message.length == 0)
      return
    this.showFlashNotice(message, options)
  },

  navigateAndShowAjaxFlashNotice: function(url, xhr, options) {
    const notice = this.getAjaxFlashMessages(xhr, options).notice
    this.navigateAndShowFlashNotice(url, notice, options)
  },

  // Navigate to a different page and show a string as a flash notice
  //
  // url: Destination url
  // notice: String to display as flash
  navigateAndShowFlashNotice: function(url, notice, options) {
    $(document).one("page:change", function() {
      NProgress.done()
      if(notice && notice.length > 0) {
        this.showFlashNotice(notice, options)
      }
      NProgress.remove()
    }.bind(this))
    NProgress.start()
    Turbolinks.visit(url)
  },

  navigateAndCall: function(url, callback) {
    $(document).one("page:change", function(){
      NProgress.done()
      callback()
      NProgress.remove()
    })

    NProgress.start()
    Turbolinks.visit(url)
  },

  navigateAndShowModalNotice: function(url, xhr, options) {
    const message = this.getAjaxFlashMessages(xhr).notice
    options = _.extend({ title: "Alert"}, options)

    $(document).one("page:change", function() {
      NProgress.done()
      bootbox.alert({
        title: options.title,
        message: message,
        className: "modal-info",
      })
      NProgress.remove()
    })
    NProgress.start()
    Turbolinks.visit(url)
  },

  showFlashNotice: function(message, options) {
    options = _.extend({
      fadeAfter: 7000,
    }, options)

    if (App.subheaderRegion.isPresent())
      App.subheaderRegion.showAjaxFlash(message, options)
    else
      this.showAjaxFlashInTarget($("section#page #flashes"), message, _.extend(options, {replaceTarget: false}))
  },

  sanitizeNullDataGeneric: function($cells) {
    $cells.each(function() {
      $(this).toggleClass('text-muted', $(this).text().trim() === '––')
    })
  },

  // Converts an Underscore template to HTML, passing in data for interpolation.
  // +template+ can either be a jQuery object or a general selector passed to $()
  underscoreTemplateToHTML: function(template, data) {
    let html
    if (template instanceof jQuery) {
      html = template.html()
    } else {
      html = $(template).html()
    }

    return _.template(html)(data)
  },
}
