import Routes from 'lib/routes'
import Util from 'lib/util'

function eulaPrompt() {
  bootbox.confirm({
    title: "Our Terms and Conditions have changed",
    message: "I agree to the <a href=\'https://workbright.com/privacy-policy/\', target=\'_blank\'>Privacy Policy</a> and <a href=\'https://workbright.com/end-user-license-agreement/\' target=\'_blank\'>End User License Agreement</a>.",
    callback: (result) => {
      if(result) {
        $.ajax({
          method: 'POST',
          url: Routes.agree_eula_path
        }).fail((xhr) => {
          Util.genericAjaxError("An error occurred.", xhr)
        })
      } else {
        bootbox.alert({
          title: 'Terms and Conditions',
          message: 'You must agree to the Terms and Conditions to continue using WorkBright.',
          callback: eulaPrompt
        })
      }
    },
    buttons: {
      cancel: {
        label: "Decline",
        className: "btn-default"
      },
      confirm: {
        label: "Agree",
        className: "btn-primary",
        value: true,
      },
    }
  })
}

export function EulaCheck() {
  if(!gon.skip_eula_modal &&
     gon.current_user && Object.keys(gon.current_user).length > 0 &&
     !gon.current_user.eula_agreed) {
      eulaPrompt()
  }
}
