var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h4", [_vm._v(_vm._s(_vm.stepHeader))]),
      _vm._v(" "),
      _c("permissions-summary", {
        attrs: { "permission-sets": this.policy.permissionSets },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }