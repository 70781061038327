// E-Verify case title displayed on small slideout panel (used with EverifyCasePanelContent).

<template>
  <div>
    <div class="row column-divider" v-if="isLoading">
      <Spinner />
    </div>
    <div class="row column-divider" ref="everifyDocumentPreview" v-else>
      <div :class="showEverifyPhoto ? 'col-lg-6' : 'col-lg-12'"> 
        <EverifyCasePhoto
          header="WorkBright I-9 Documentation"
          :photoFrontUrl="photoFrontUrl"
          :photoBackUrl="photoBackUrl"
          :shouldShowBack="true"
        />
      </div>
      <div class="col-lg-6" v-if="showEverifyPhoto">
        <EverifyCasePhoto
          header="E-Verify Documentation"
          :photoFrontUrl="everifyPhotoUrl"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import FileViewer from 'components/common/FileViewer'
  import Spinner from 'components/common/Spinner'
  import EverifyCasePhoto from 'components/everify/cases/EverifyCasePhoto'
  import Util from 'lib/util'

  export default {
    name: "everify-case-document-preview",

    components: {
      FileViewer,
      Spinner,
      EverifyCasePhoto
    },

    props: {
      caseId: {
        type: Number,
        required: true
      },
      showEverifyPhoto: {
        type: Boolean,
        default: true
      }
    },

    data() {
      return {
        photoFrontUrl: null,
        photoBackUrl: null,
        everifyPhotoUrl: null,
        isLoading: true
      }
    },

    created() {
      this.getPhotoUrls()
    },

    methods: {
      getPhotoUrls() {
        this.fetchPhotoUrls({
          caseId: this.caseId
        }).then((response) => {
          this.isLoading = false
          this.photoFrontUrl = response.wb_front_url
          this.photoBackUrl = response.wb_back_url
          this.everifyPhotoUrl = response.everify_url
        }).catch((xhr) => {
          this.isLoading = false
          Util.ajaxErrorDialog("An error occurred while fetching photos.", xhr)
        })
      },

      ...mapActions({
        fetchPhotoUrls: 'everify_cases/fetchPhotoUrls'
      })
    }
  }
</script>
