var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeader", {
        attrs: {
          title: "Feature Flags",
          lead: "Manage account-level feature flag settings",
        },
      }),
      _vm._v(" "),
      _vm.isLoading
        ? _c("Spinner", { ref: "loader" })
        : [
            _vm.hasFlags
              ? _c(
                  "div",
                  { ref: "form", staticClass: "form-horizontal" },
                  _vm._l(_vm.featureFlags, function (ref, index) {
                    var flag = ref.flag
                    var enabled = ref.enabled
                    return _c("FormFieldCheckbox", {
                      key: index,
                      attrs: {
                        disabled: _vm.updatingFlags[flag],
                        hint: _vm.getFlagDescription(flag),
                        name: flag,
                        label: _vm.getFlagLabel(flag),
                        value: enabled,
                      },
                      on: {
                        input: function (isEnabled) {
                          return _vm.handleFlagInput(flag, isEnabled)
                        },
                      },
                    })
                  }),
                  1
                )
              : _c("AlertMessage", {
                  ref: "noFlagsAlert",
                  attrs: {
                    message: "Unable to load early access flag information",
                  },
                }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }