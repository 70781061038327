// Entry point for Webpacked app

// Initialization, defaults, etc.
require('init/check_browser_support.js')
require('init/manually_vendored.js')
require('init/bugsnag.js')
require('init/ajax.js')
require('init/ajax_pool.js')
require('init/appender.js')
require('init/bootstrap_validator.js')
require('init/clearable.js')
require('init/eula.js')
require('init/turbolinks.js')
require('init/pubsub_init.js')
require('init/moment.js')
require('init/datatables.js')
require('init/stickit.js')
require('init/vue.js')
require('init/system_events.js')

// Make modularized code available to parts of the application that don't support modules.
require('init/bridge.js')

// Jquery initializers run after loading everything
require('init/jquery_initializers.js')
