var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.usingAuthApp
      ? _c(
          "div",
          [
            _vm._m(0),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n      First, you will need an Authenticator application on your phone. If you already have one, continue to step 2. If not, we recommend Google Authenticator, Microsoft Authenticator, or Authy, which you can download free in the Apple App Store or Google Play Store.\n    "
              ),
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _vm._m(1),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                '\n      Open the authenticator app, tap "Scan QR Code" or "+", and when it asks, point your phone camera at this QR code.\n    '
              ),
            ]),
            _vm._v(" "),
            _c("qrcode-vue", {
              attrs: { value: _vm.provisioningUri, size: "300", level: "H" },
            }),
          ],
          1
        )
      : _c("div", [
          _c("p", [
            _vm._v(
              "\n      You have chosen to configure Multi-Factor Authentication via text message, which is less secure than using an authenticator app. This is because hackers have been successful in tricking phone carriers (AT&T, Verizon, etc.) into porting phone numbers to a new device (SIM-swapping). If you choose this method of MFA in WorkBright, please update your phone carrier settings to require an access code so no changes can be made to your phone carrier account in-person or online, unless that code is provided.\n    "
            ),
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              attrs: { href: "#authapp" },
              on: { click: _vm.handleClickAuthAppConfig },
            },
            [
              _vm._v(
                "\n      Click here if you prefer to configure MFA using an authenticator app.\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _vm._m(2),
          _vm._v(" "),
          _c(
            "form",
            { on: { submit: _vm.handleSubmitSmsForm } },
            [
              _c("form-field-input", {
                attrs: {
                  placeholder: "555-555-5555",
                  required: true,
                  label: "Phone",
                  errors: _vm.formErrors,
                  name: "phone",
                },
                model: {
                  value: _vm.formData.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "phone", $$v)
                  },
                  expression: "formData.phone",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _vm._m(3),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c(
                "ladda-button",
                {
                  ref: "sendSmsButton",
                  on: { click: _vm.handleClickSmsButton },
                },
                [_vm._v("\n        Send Text Message\n      ")]
              ),
            ],
            1
          ),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [_vm._v("Step 1: Get an Authenticator application.")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [
        _vm._v(
          "Step 2: Scan this QR code with your Authenticator application."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [_vm._v("Step 1: Enter your mobile phone number")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [
        _vm._v("Step 2: Request a text message with your one-time password"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }