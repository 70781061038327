<template>
  <div>
    <h4>{{ stepHeader }}</h4>
    <permissions-summary :permission-sets="this.policy.permissionSets" />
  </div>
</template>

<script>
  import BaseStep from 'components/permissions/steps/BaseStep'
  import PermissionsSummary from 'components/permissions/summary/PermissionsSummary'

  export default {
    extends: BaseStep,
    name: 'step-summary',

    components: {
      PermissionsSummary
    },

    props: {
      managedPolicy: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      stepHeader() {
        let headerText = null

        if(this.managedPolicy){
          headerText = this.$locale.t("permissions.summary_step.stepHeader", this.policy.name)
        }else{
          headerText = this.$locale.t("permissions.summary_step.stepHeader", this.firstName)
        }

        return headerText
      }
    }
  }
</script>
