import PersistedPageState from 'lib/persisted_page_state'

// Coordinates saved filters, sorting, and page numbering between a CollectionViewStore and PersistedPageState (url, local store)
// - On creation, applies any persisted keys to the CollectionViewStore
// - On mutation, save any keys to the PersistedPageState
//
// If any filter values have been persisted or are present in the url, they will be added to the CollectionViewStore.
// If a sorting order has been persisted or is present in the url (param name 'sort'), it will be added to the CollectionViewStore.
// If a page number has been persisted or is present in the url (param name 'page'), it will be added to the CollectionViewStore.
export default class PersistedCollectionViewWrapper {

  // Constructor
  //
  // collectionViewName: String, name of CollectionViewStore
  // persistedKeys: Array[String], filter keys to persist
  constructor(store, collectionViewName, persistedKeys) {
    this.store = store
    this.persistedKeys = persistedKeys
    this.collectionViewName = collectionViewName
    this.persistedPageState = new PersistedPageState()

    this._loadPersistedState()

    // Persist filters, sorting, and page number when the collection changes
    this._unsubscribe = this.store.subscribe((mutation, state) => {
      switch (mutation.type) {
      case `${this.collectionViewName}/SET_SORT_ORDER`:
        const sortOrder = state[this.collectionViewName].sort_order
        this.persistedPageState.savePageState('sort', sortOrder)
        this.persistedPageState.savePageState('page', 1) // reset page number whenever collection is resorted
        break
      case `${this.collectionViewName}/SET_PAGE_NUMBER`:
        const currentPage = state[this.collectionViewName].page
        this.persistedPageState.savePageState('page', currentPage)
        break
      case `${this.collectionViewName}/SET_FILTER_VALUE`:
      case `${this.collectionViewName}/CLEAR_FILTER_VALUES`:
        this._persistFilters(state)
        break
      }
    })
  }

  unsubscribe() {
    if(this._unsubscribe) { this._unsubscribe() }
  }

  _loadPersistedState() {
    this._loadPersistedFilters()
    this._loadPersistedSortOrdering()
    this._loadPersistedPageNumber()
  }

  // Apply any filters from the persisted page state on load
  _loadPersistedFilters() {
    const persistedFilters = []
    this.persistedKeys.forEach((key) => {
      const savedValue = this.persistedPageState.fetchPageState(key)
      if(savedValue) {
        persistedFilters.push({ filter_key: key, value: savedValue })
        this.persistedPageState.savePageState(key, savedValue)
      }
    })
    this.store.dispatch(`${this.collectionViewName}/setFilterValues`, persistedFilters)
  }

  // Apply any sorting from the persisted page state on load
  _loadPersistedSortOrdering() {
    const savedSorting = this.persistedPageState.fetchPageState('sort')

    if(savedSorting) {
      this.persistedPageState.savePageState('sort', savedSorting)
      this.store.dispatch(`${this.collectionViewName}/setSortOrder`, { sortOrder: savedSorting })
    }
  }

  // Apply any page numbering from the persisted page state on load
  _loadPersistedPageNumber() {
    const savedPageNum = this.persistedPageState.fetchPageState('page')

    if(savedPageNum) {
      this.persistedPageState.savePageState('page', savedPageNum)
      this.store.dispatch(`${this.collectionViewName}/setPageNumber`, { page: savedPageNum })
    }
  }

  // Persist any filters that have updated
  _persistFilters(state) {
    this.persistedKeys.forEach((key) => {
      const currentValue = state[this.collectionViewName].filter_values[key]
      const savedValue = this.persistedPageState.fetchPageState(key)

      if(currentValue != savedValue) {
        this.persistedPageState.savePageState(key, currentValue)
        this.persistedPageState.savePageState('page', 1) // reset page number whenever filters are updated
      }
    })
  }
}
