var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      attrs: {
        href: _vm.$routes.logout_path,
        "data-method": "delete",
        rel: "nofollow",
      },
    },
    [
      _c("WbIcon", { staticClass: "fa-fw", attrs: { type: "sign-out" } }),
      _vm._v("\n  Sign out\n"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }