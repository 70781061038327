const pluralizeWord = function(quantity, singular, plural) {
  if(plural == undefined) {
    plural = `${singular}s`
  }

  return `${quantity === 1 ? singular : plural}`
}

export default {

  // Title-ize a string
  // Capitalize the first letter of each word
  // e.g. "restricted admin" => "Restricted Admin"
  //
  // input: Input string
  //
  // ouptut: String with first letter of each word capitalized
  titleize: function(input){
    return input.split(' ').map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1)
    }).join(' ')
  },

  // Is an object a String, and does it have a length > 0
  //--
  // FIXME: Rename to isPresent
  present: function(string) {
    return typeof(string) == 'string' && string.length > 0
  },

  // Print a numeric string expressing singular and plural version of a word
  // Adds a 's' to the word (form => forms) unless a different plural version is provided (person => people)
  //
  // quantity - integer, number of items
  // singular - string, singular form of word (e.g. 'Form')
  // plural - (optional) string, plural form of word (e.g. 'Forms')
  pluralize: function(quantity, singular, plural) {
    const word = pluralizeWord(quantity, singular, plural)

    return `${quantity} ${word}`
  },

  pluralizeWord,


  // Join an array of strings together, truncating the list if it's longer than allowed
  //
  // list: Array(String): Strings to join
  // limit: Integer, truncate after this length
  // options:
  //  joinStr: String, Alternate join string, default ', '
  //  truncationMessage: Function, Alternate truncation string, default 'and N more'
  joinTruncated: function(list, limit, options = {}) {
    const shown = list.slice(0, limit)

    const notShownCount = list.length - shown.length
    if(notShownCount) {
      const truncationMessage = options['truncationMessage'] || (count => `and ${count} more`)
      shown.push(truncationMessage(notShownCount))
    }

    const joinStr = options['joinStr'] || ', '
    return shown.join(joinStr)
  },

  /**
   * Converts a string into a human-readable format.
   *
   * @param {string} string - The input string to be humanized.
   * @returns {string} - A human-readable string.
   */
  humanize: function(string) {
    if (!string) { return '' }

    const words = string.match(/[A-Za-z][a-z\d-]*/g) || []
    const wordsWithSpaces = words.join(' ')
    return this.titleize(wordsWithSpaces)
  }
}
