var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { staticClass: "navbar navbar-main", attrs: { role: "navigation" } },
    [
      _c(
        "div",
        { staticClass: "container-fluid" },
        [
          _c("AppNavbarHeader"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "collapse navbar-collapse navbar-main-collapse" },
            [
              _vm.shouldShowEmployeeSearch
                ? _c(
                    "form",
                    {
                      staticClass: "navbar-form navbar-left search-form",
                      attrs: { role: "search" },
                    },
                    [
                      _c("EmployeeSearch", {
                        on: { input: _vm.handleEmployeeSearchInput },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.shouldShowPrimaryNav
                ? _c("AppNavbarNav", { attrs: { items: _vm.navItems } })
                : _vm._e(),
              _vm._v(" "),
              _vm.shouldShowAnnouncements
                ? _c("NavbarAnnouncements")
                : _vm._e(),
              _vm._v(" "),
              _vm.secondaryNavComponent
                ? _c(_vm.secondaryNavComponent, {
                    ref: "secondaryNav",
                    tag: "component",
                    attrs: {
                      currentUser: _vm.currentUser,
                      items: _vm.dropdownItems,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }