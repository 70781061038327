var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "slideout-panel",
    {
      attrs: {
        watchPageContextVariable: "show_historical_submissions_assignment_id",
      },
    },
    [
      _vm.currentAssignmentId && _vm.document
        ? _c(
            "h3",
            {
              staticClass: "modal-title",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c("span", { ref: "documentName" }, [
                _vm._v(_vm._s(_vm.document.name)),
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("small", { ref: "employeeName" }, [
                _vm._v(_vm._s(_vm.pageContext.employee.name)),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-wait",
        { attrs: { visible: _vm.isLoading } },
        [
          _c("spinner", { attrs: { slot: "waiting" }, slot: "waiting" }),
          _vm._v(" "),
          !_vm.hasCurrentSubmission
            ? _c("div", { staticClass: "alert alert-warning" }, [
                _vm._v("No current submission"),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.currentAssignmentId
            ? _c("historical-submissions-list", {
                attrs: {
                  "has-indicators": _vm.hasCurrentSubmission,
                  items: _vm.submissions,
                  "visible-submission-id": _vm.visibleSubmissionId,
                },
                on: { setAsCurrent: _vm.handleSetAsCurrent },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }