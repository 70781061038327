<template>
  <div class="textarea" :class="{'has-error' : hasError }">
    <div class="input-wrapper textarea">
      <label>
        <slot />
        {{ label }}
      </label>
      <textarea
        ref="input"
        class="text form-control"
        :name="name"
        :id="name"
        :placeholder="placeholder"
        @input="$emit('input', localValue)"
        v-model="localValue"
      />
    </div>
    <small class="help-block" v-if="hasError">
      {{ errorMessage }}
    </small>
    <small class="help-block" v-if="hint">
      {{ hint }}
    </small>
  </div>
</template>

<script>
  import FormField from 'components/common/FormField'

  export default {
    name: 'form-field-text-area',
    mixins: [FormField],
    created () {
      this.localValue = this.value
    },
    data () {
      return {
        localValue: undefined
      }
    },
    props: {
      value: {
        type: String,
        default: null
      }
    },
    watch: {
      value () {
        this.localValue = this.value
      }
    }
  }
</script>
