var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "callout-legal" }, [
    _c("strong", [_vm._v(_vm._s(_vm.title))]),
    _vm._v(" "),
    _c("div", { domProps: { textContent: _vm._s(_vm.statement) } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }