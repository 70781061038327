var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "i9-documentation" },
    [
      _vm.verificationFallbackMode
        ? _c(
            "AlertMessage",
            { ref: "verificationFallbackAlert", attrs: { variant: "warning" } },
            [
              _c("p", [
                _vm._v(
                  "\n      We were unable to verify your document but this does not mean it is not a valid document for work authorization. If you believe you have selected the wrong type of document, please use the previous button to select a new document type and attempt verification again. OR if this is a receipt for a lost, stolen, or damaged document, please indicate that here and complete the manual entry of information.\n    "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n      If you have selected the right type and you believe the current document you are submitting to be a valid document to prove your identity and/or work authorization, you can proceed with completing your Form I-9 by manually entering the information on this page. Your employer will review the documentation you are providing and follow up if any further action is needed.\n    "
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("h4", [_vm._v(_vm._s(_vm.attachmentName))]),
      _vm._v(" "),
      _vm.instructions
        ? _c("p", {
            ref: "instructions",
            staticClass: "mt-3",
            domProps: { innerHTML: _vm._s(_vm.instructions) },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        !_vm.verificationFallbackMode
          ? _c(
              "div",
              { staticClass: "col-md-6" },
              [
                _vm.hasAttachments
                  ? [
                      _vm.hasFront
                        ? _c("ImageUpload", {
                            ref: "uploadFront",
                            attrs: {
                              label: "Document Front",
                              required: "",
                              accountTakePhotoOnDesktopEnabled:
                                _vm.currentAccount.takePhotoOnDesktopEnabled,
                              error: _vm.errorMessage("file_front"),
                              existingFileUrl: _vm.formData.file_front_url,
                              help: _vm.frontFileHint,
                              uploadServiceClass: _vm.uploadServiceClass,
                            },
                            on: {
                              success: function ($event) {
                                return _vm.handleUploadSuccess("front", $event)
                              },
                              remove: function ($event) {
                                return _vm.handleUploadRemove("front")
                              },
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasBack
                        ? _c("ImageUpload", {
                            ref: "uploadBack",
                            attrs: {
                              label: "Document Back",
                              required: "",
                              accountTakePhotoOnDesktopEnabled:
                                _vm.currentAccount.takePhotoOnDesktopEnabled,
                              error: _vm.errorMessage("file_back"),
                              existingFileUrl: _vm.formData.file_back_url,
                              help: _vm.backFileHint,
                              uploadServiceClass: _vm.uploadServiceClass,
                            },
                            on: {
                              success: function ($event) {
                                return _vm.handleUploadSuccess("back", $event)
                              },
                              remove: function ($event) {
                                return _vm.handleUploadRemove("back")
                              },
                            },
                          })
                        : _vm._e(),
                    ]
                  : _c(
                      "AlertMessage",
                      { ref: "noAttachments", attrs: { variant: "info" } },
                      [
                        _vm._v(
                          "\n        No file upload is required for this document option.\n      "
                        ),
                      ]
                    ),
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-md-6" },
          [
            _vm.hasMultipleDocumentTitles
              ? _c("FormFieldSelect", {
                  ref: "docTitleSelect",
                  attrs: {
                    fieldWidth: "",
                    label: "Document Title",
                    name: "document_title",
                    options: _vm.documentTitleOptions,
                    errors: _vm.errors,
                    required: "",
                  },
                  model: {
                    value: _vm.documentTitleIndex,
                    callback: function ($$v) {
                      _vm.documentTitleIndex = $$v
                    },
                    expression: "documentTitleIndex",
                  },
                })
              : _c("FormFieldInput", {
                  ref: "docTitle",
                  attrs: {
                    label: "Document Title",
                    name: "document_title",
                    disabled: _vm.shouldDisableDocumentTitle,
                    errors: _vm.errors,
                    required: "",
                  },
                  model: {
                    value: _vm.formData.document_title,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "document_title", $$v)
                    },
                    expression: "formData.document_title",
                  },
                }),
            _vm._v(" "),
            !_vm.isOtherAcceptableReceipt
              ? _c("FormFieldCheckbox", {
                  ref: "receipt",
                  staticClass: "row",
                  attrs: {
                    gridColumns: "col-xs-12",
                    label:
                      "My document was lost, stolen, or damaged and this is a receipt for a replacement.",
                    name: "receipt",
                    errors: _vm.errors,
                    helpPopoverText: _vm.receiptPopoverText,
                  },
                  model: {
                    value: _vm.formData.receipt,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "receipt", $$v)
                    },
                    expression: "formData.receipt",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.hasCountriesAsIssuingAuthority ||
            _vm.hasMultipleIssuingAuthorities
              ? _c("FormFieldSelect", {
                  ref: "issuingAuthorityCountrySelect",
                  attrs: {
                    fieldWidth: "",
                    hint: _vm.issuingAuthorityHint,
                    label: "Issuing Authority",
                    name: "issuing_authority",
                    options: _vm.issuingAuthorityOptions,
                    errors: _vm.errors,
                    required: "",
                  },
                  model: {
                    value: _vm.formData.issuing_authority,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "issuing_authority", $$v)
                    },
                    expression: "formData.issuing_authority",
                  },
                })
              : _c("FormFieldInput", {
                  ref: "issuingAuthorityInput",
                  attrs: {
                    hint: _vm.issuingAuthorityHint,
                    label: "Issuing Authority",
                    name: "issuing_authority",
                    disabled: _vm.shouldDisableIssuingAuthority,
                    errors: _vm.errors,
                    required: "",
                    value: _vm.issuingAuthority,
                  },
                  model: {
                    value: _vm.formData.issuing_authority,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "issuing_authority", $$v)
                    },
                    expression: "formData.issuing_authority",
                  },
                }),
            _vm._v(" "),
            _vm.shouldShowOcrFields
              ? _c(
                  "div",
                  {
                    ref: "ocrFields",
                    staticClass: "remote-countersign-ocr-fields",
                  },
                  [
                    _c("FormFieldInput", {
                      ref: "docNumber",
                      attrs: {
                        errors: _vm.errors,
                        hint: _vm.documentNumberHint,
                        label: "Document Number",
                        name: "document_number",
                        required: "",
                        mask: _vm.documentNumberMask,
                      },
                      model: {
                        value: _vm.formData.document_number,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "document_number", $$v)
                        },
                        expression: "formData.document_number",
                      },
                    }),
                    _vm._v(" "),
                    _c("FormFieldDate", {
                      attrs: {
                        errors: _vm.errors,
                        hint: _vm.expirationHelpText,
                        label: "Expiration Date",
                        minDate: _vm.expirationMinDate,
                        name: "expiration_date",
                        required: "",
                      },
                      model: {
                        value: _vm.formData.expiration_date,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "expiration_date", $$v)
                        },
                        expression: "formData.expiration_date",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }