<!-- // Icon and text label representing a submission status -->
<template>
  <div
    class="submission-status-indicator"
    :class="baseClass"
    v-tooltip.html="tooltipTitle"
  >
    <i class="fa fa-fw status-icon" />
    <span v-if="showStatusText" ref="status">
      {{ statusText }}
    </span>
  </div>
</template>

<script>
  import DateTime from 'lib/util/datetime'
  import moment from 'moment'

  // REVIEW: locale or Constants file
  const PENDING_STATUSES = ['pending', 'employee_review', 'assignment_pending']
  const REVIEWED_STATUSES = ['accepted', 'rejected']
  const STATUS_TABLE = {
    missing: {
      text: 'Missing',
    },
    employee_review: {
      text: 'Staff member has been asked to review for this season',
    },
    pending: {
      text: 'Pending Admin review in the Forms Inbox',
    },
    accepted: {
      text: 'Accepted',
    },
    rejected: {
      text: 'Rejected',
    },
    assignment_pending: {
      text: 'Staff member will be requested to complete this form',
    }
  }

  export default {
    name: 'submission-status-indicator',

    props: {
      submission: {
        type: Object,
        required: false
      },

      showStatusText: {
        type: Boolean,
        required: false,
        default: false
      },

      // Provide this prop if you'd like to use a status from an assignment instead; otherwise the default will load
      // the status from the `submission` itself
      status: {
        type: String,
        required: false,
      },
    },

    computed: {
      // Class applied to the base element of the component; largely drives the CSS of the child elements.
      baseClass() {
        return `status-${this.localStatus}`
      },

      localStatus () {
        return this.status || this.submission?.status || 'missing'
      },

      // The human-readable text corresponding to the status
      statusText() {
        return STATUS_TABLE[this.localStatus].text
      },

      statusChanger() {
        const changer = this.submission && this.submission.status_changed_by ?
          ` by ${_.escape(this.submission.status_changed_by)}` :
          ''
        return this.submission && this.submission.auto_accepted ?
          ' automatically' :
          changer
      },

      submissionUpdateDate() {
        return this.submission && this.submission.status_changed_at
      },

      submissionUpdateDateFormatted() {
        const formattedDate = DateTime.formatDateTimeExt(this.submissionUpdateDate)
        return this.submissionUpdateDate && moment(this.submissionUpdateDate).isValid() ?
          ` on<br/>${formattedDate}` :
          ''
      },

      tooltipTitle() {
        let value = null

        if (this.submission) {
          if (PENDING_STATUSES.includes(this.localStatus)) {
            value = this.statusText
          } else if (REVIEWED_STATUSES.includes(this.localStatus)) {
            value = `${this.statusText}${this.statusChanger}${this.submissionUpdateDateFormatted}`
          }
        }
        return value
      }
    }
  }
</script>
