var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _vm.icon
        ? _c("WbIcon", {
            staticClass: "mr-1",
            class: _vm.classNames,
            attrs: { type: _vm.icon },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("span", { domProps: { innerHTML: _vm._s(_vm.label) } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }