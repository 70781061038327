import SignaturePad from 'signature_pad'

// Wrapper for SignaturePad JS component: https://github.com/szimek/signature_pad
export default Marionette.ItemView.extend({
  template: false,  // prerendered via Ruby

  signaturePadOptions: {
    penColor: 'blue',
  },

  ui: {
    canvas: 'canvas.form-control',
    clearButton: 'button.btn-clear-signature',
    signatureDataUriField: 'input.signature-data-uri-field',
  },

  events: {
    'click @ui.clearButton': 'clearSignature'
  },

  // 2-way bindings only initialized when a `model` (optional) is passed to view
  bindings: {
    'input.signature-data-uri-field': 'signature_data_uri'
  },

  initialize: function() {
    // Update methods to bound functions due to using event listeners
    _.bindAll(this, "_debounceResizeCanvas", "_removeEventListeners")
    window.addEventListener("resize", this._debounceResizeCanvas)
    window.addEventListener("page:before-unload", this._removeEventListeners)
  },

  onRender: function() {
    if (this.model) {
      this.stickit()
    }

    const signaturePadOptions = $.extend(this.getOption('signaturePadOptions'), {
      onBegin: function() { this.triggerMethod('signaturePad:stroke:begin') }.bind(this),
      onEnd: function() { this.triggerMethod('signaturePad:stroke:end') }.bind(this),
    })

    const canvasNode = this.ui.canvas[0]
    this.signaturePad = new SignaturePad(canvasNode, signaturePadOptions)

    this._resizeCanvas()
    this._loadExistingSignatureData()

    // This class is used to facilitate a test. We need some kind of indicator that this view has been rendered
    this.$el.addClass("signature-pad-loaded")
  },

  _removeEventListeners: function() {
    window.removeEventListener("resize", this._debounceResizeCanvas)
    // Use the Turbolinks event because onDestroy never gets called with Turbolinks
    window.removeEventListener("page:before-unload", this._removeEventListeners)
  },

  // Clears the signature from the canvas and all data bindings
  clearSignature: function(e) {
    e.preventDefault()
    e.stopPropagation()

    this.signaturePad.clear()
    this._resizeCanvas()

    this._updateSignatureData()
  },

  // Callback when SignaturePad detects a stroke ending
  onSignaturePadStrokeEnd: function() {
    this._updateSignatureData()
  },

  // Adjust canvas coordinate space taking into account pixel ratio, to make it look crisp on mobile devices.
  // This also causes canvas to be cleared.
  _resizeCanvas: function() {
    const ratio =  window.devicePixelRatio || 1

    const canvasNode = this.ui.canvas[0]

    canvasNode.width = canvasNode.offsetWidth * ratio
    canvasNode.height = canvasNode.offsetHeight * ratio
    canvasNode.getContext("2d").scale(ratio, ratio)
  },

  _debounceResizeCanvas: _.debounce(
    function () {
      this._resizeCanvas()
      this._loadExistingSignatureData()
    },
    200
  ),

  // @private
  // Update the hidden field with our latest signature data
  _updateSignatureData: function() {
    // Return null if the signature pad is empty
    // toDataURL() still provides some data for a blank png
    let imageData = this.signaturePad.isEmpty() ? null : this.signaturePad.toDataURL()

    // Corner case on some browsers which still return incomplete data
    if (imageData == "data:,") {
      imageData = null
    }

    this.ui.signatureDataUriField.val(imageData)

    // trigger onchange (for stickit binding presence)
    this.ui.signatureDataUriField.trigger('change')
  },

  // @private
  // Load existing signature data from the model (if supplied) or the hidden field
  //--
  // REVIEW: if loaded from model, should we set the signatureDataUriField immediately to that value to keep everything
  // in sync? (This would only benefit Marionette Views which load existing signature from model but submit via <FORM>)
  _loadExistingSignatureData: function() {
    let existingSignatureData
    if (this.model) {
      existingSignatureData = this.model.get('signature_data_uri')
    } else {
      existingSignatureData = this.ui.signatureDataUriField.val()
    }

    if (!_.isEmpty(existingSignatureData)) {
      this.signaturePad.fromDataURL(existingSignatureData)
    }
  }
})
