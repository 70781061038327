<template>
  <div>
    <div v-for="(account, index) in unauthenticatedAccounts" :key="index">
      <AlertMessage
        title="Authentication Failure"
        :message="$locale.t('everify_account.authFailure', companyName(account), helpDeskLink)"
        ref="authFailure"
      />
    </div>
    <h2 class="page">E-Verify Cases</h2>
    <p>
      WorkBright periodically refreshes E-Verify case data but the latest changes may not be displayed. If you've made recent changes on the E-Verify site and don't see them here, please refresh to update. Only cases created with WorkBright will be displayed here.
    </p>
    <AlertMessage
      ref="adverse-action-alert"
      variant="warning"
      :message="adverseActionAlert"
      v-if="pendingReferralSelected"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import CollectionViewStore from 'vuex/modules/collection_view_store'
  import PubSub from 'lib/pub_sub'
  import AlertMessage from 'components/common/AlertMessage'

  export default {
    name: 'everify-case-list-header',
    components: {
      AlertMessage,
    },

    computed: {
      adverseActionAlert() {
        return this.$locale.t('everify.status.pending_referral.adverseActionAlert')
      },
      pendingReferralSelected() {
        return this.filterValues.case_status?.includes('pending_referral') || false
      },
      helpDeskLink() {
        return this.$routes.External.everify_web_services_password_helpdesk_url
      },

      // Find E-Verify accounts that don't have valid Web Services credentials (in Fritz)
      unauthenticatedAccounts() {
        return this.everifyAccounts.filter(a => a.everify_authenticated === false)
      },

      ...mapGetters({
        everifyAccounts: 'everify_accounts/all',
        filterValues: 'collection_view_everify_cases/filterValues',
      })
    },

    created() {
      if (!this.$store._modulesNamespaceMap['collection_view_everify_cases/']) {
        this.$store.registerModule('collection_view_everify_cases', CollectionViewStore)
      }
    },

    methods: {
      companyName(account) {
        return account.company_name || 'this account'
      },

      subscribeToPubSubEvents() {
        this.updateChannel = PubSub.subscribeToUserChannel({
          topic: 'everify_account',
          unique: true
        })

        this.updateChannel.bind('update', (data) => {
          this.$store.commit('everify_accounts/MERGE_INTO_RECORD', data)
        })
      },
    },

    mounted() {
      this.subscribeToPubSubEvents()
    }
  }
</script>
