var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-sm-12" }, [
    _c("div", { staticClass: "checkbox" }, [
      _c("label", [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.group.selected,
              expression: "group.selected",
            },
          ],
          ref: "checkbox",
          attrs: { type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.group.selected)
              ? _vm._i(_vm.group.selected, null) > -1
              : _vm.group.selected,
          },
          on: {
            click: _vm.toggle,
            change: function ($event) {
              var $$a = _vm.group.selected,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && _vm.$set(_vm.group, "selected", $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    _vm.$set(
                      _vm.group,
                      "selected",
                      $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                    )
                }
              } else {
                _vm.$set(_vm.group, "selected", $$c)
              }
            },
          },
        }),
        _vm._v("\n      " + _vm._s(_vm.group.name) + "\n    "),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }