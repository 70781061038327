var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "spinner", style: _vm.spinnerStyle }),
      _vm._v(" "),
      _c("transition", { attrs: { name: "vue-fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.longWait,
                expression: "longWait",
              },
            ],
            staticClass: "text-center",
          },
          [_vm._v("This may take a few moments.")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }