var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "SlideoutPanel",
    { attrs: { watchPageContextVariable: "show_download_slideout_panel" } },
    [
      !!_vm.downloadRequest
        ? _c("h3", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v("\n    " + _vm._s(_vm.downloadRequest.name) + "\n  "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isLoading
        ? [_c("Spinner", { ref: "loader" })]
        : [
            _vm.hasDownloads
              ? _c(
                  "ul",
                  { ref: "download-list", staticClass: "list-group" },
                  _vm._l(_vm.downloadParts, function (downloadPart, index) {
                    return _c(
                      "li",
                      { key: index, staticClass: "list-group-item" },
                      [
                        _c("DownloadPart", {
                          attrs: { downloadPart: downloadPart, index: index },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                )
              : _c("div", { ref: "default-content" }, [
                  _c("em", [_vm._v("No downloads available")]),
                ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }