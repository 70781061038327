const SMS_STATUS_DISPLAY = {
  failed: 'Failed to send text',
  opt_out: 'Staff has opted out of text messaging'
}

export default {
  computed: {
    hasValidSmsStatus() {
      return this.employee &&
              this.smsEnabled &&
              this.employee.sms_status &&
              SMS_STATUS_DISPLAY[this.employee.sms_status]
    },
    smsStatus() {
      return SMS_STATUS_DISPLAY[this.employee.sms_status]
    },
    smsEnabled() {
      return this.currentAccount.onboardingSmsEnabled || this.currentAccount.ri9SmsEnabled
    }
  }
}
