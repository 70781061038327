<template>
  <div>
    <slot />
    <WbButton
      class="mt-4"
      @click="handleClickSubmitLocation"
      :isLoading="isLoading"
      :label="buttonText"
    />
  </div>
</template>

<script>
  import WbButton from 'components/common/WbButton'

  export default {
    name: 'wb-geolocation',

    components: {
      WbButton
    },

    data () {
      return {
        isLoading: false,
      }
    },

    props: {
      buttonText: {
        type: String,
        required: false,
        default: "Submit Your Location"
      },
    },

    methods: {
      // see https://developer.mozilla.org/en-US/docs/Web/API/Geolocation_API
      handleClickSubmitLocation(){
        this.isLoading = true

        if (!navigator.geolocation){
          this.isLoading = false
          this.$emit("wbGeolocationError", "Unfortunately, your browser does not support geolocation.")
        }
        else {
          navigator.geolocation.getCurrentPosition(
            (position) => { // Success
              this.isLoading = false
              this.$emit("wbGeolocationSuccess", position)
            },
            () => { // Error
              this.isLoading = false
              this.$emit("wbGeolocationError", "Unfortunately, geolocation is not permitted on your device.")
            }
          )
        }
      }
    }
  }
</script>
