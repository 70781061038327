var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h2", [_vm._v("New Account")]),
    _vm._v(" "),
    _c(
      "form",
      {
        staticClass: "form-horizontal fv-form fv-form-bootstrap",
        attrs: { errors: _vm.formErrors },
      },
      [
        _c("FormFieldSelect", {
          attrs: {
            orientation: "horizontal",
            required: true,
            label: "Account Type",
            errors: _vm.formErrors,
            name: "account-type",
            options: _vm.accountTypes,
            placeholder: "Active",
          },
          model: {
            value: _vm.formData.accountType,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "accountType", $$v)
            },
            expression: "formData.accountType",
          },
        }),
        _vm._v(" "),
        _c("FormFieldInput", {
          attrs: {
            orientation: "horizontal",
            required: true,
            label: "Company Name",
            errors: _vm.formErrors,
            name: "name",
            type: "text",
          },
          model: {
            value: _vm.formData.companyName,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "companyName", $$v)
            },
            expression: "formData.companyName",
          },
        }),
        _vm._v(" "),
        _c("FormFieldInput", {
          attrs: {
            orientation: "horizontal",
            required: true,
            label: "Subdomain",
            errors: _vm.formErrors,
            name: "subdomain",
            type: "text",
            addon: _vm.emptyHostname,
          },
          model: {
            value: _vm.formData.subdomain,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "subdomain", $$v)
            },
            expression: "formData.subdomain",
          },
        }),
        _vm._v(" "),
        _c("FormFieldCheckbox", {
          attrs: {
            label: "User Permissions",
            name: "account[permissions_feature]",
            value: "false",
          },
          model: {
            value: _vm.formData.permissionsFeature,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "permissionsFeature", $$v)
            },
            expression: "formData.permissionsFeature",
          },
        }),
        _vm._v(" "),
        _c("FormFieldCheckbox", {
          attrs: {
            label: "Upsells Enabled",
            name: "account[upsells_enabled]",
            value: "true",
          },
          model: {
            value: _vm.formData.upsellsEnabled,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "upsellsEnabled", $$v)
            },
            expression: "formData.upsellsEnabled",
          },
        }),
        _vm._v(" "),
        _c("FormFieldCheckbox", {
          attrs: {
            label: "Embedded Mode",
            name: "account[embedded_feature]",
            value: "false",
          },
          model: {
            value: _vm.formData.embeddedFeature,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "embeddedFeature", $$v)
            },
            expression: "formData.embeddedFeature",
          },
        }),
        _vm._v(" "),
        _c("FormFieldCheckbox", {
          attrs: {
            label: "E-Verify",
            name: "account[everify_feature]",
            value: "false",
          },
          model: {
            value: _vm.formData.everifyFeature,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "everifyFeature", $$v)
            },
            expression: "formData.everifyFeature",
          },
        }),
        _vm._v(" "),
        _c("FormFieldCheckbox", {
          attrs: {
            label: "Remote I-9 Verification Enabled",
            name: "account[remote_countersign_enabled]",
            value: "false",
          },
          model: {
            value: _vm.formData.remoteCountersignEnabled,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "remoteCountersignEnabled", $$v)
            },
            expression: "formData.remoteCountersignEnabled",
          },
        }),
        _vm._v(" "),
        _c("FormFieldCheckbox", {
          attrs: {
            label: "MFA Required for Admins",
            name: "account[mfa_required_for_admins]",
            value: "false",
          },
          model: {
            value: _vm.formData.mfaRequiredForAdmins,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "mfaRequiredForAdmins", $$v)
            },
            expression: "formData.mfaRequiredForAdmins",
          },
        }),
        _vm._v(" "),
        _c("FormFieldCheckbox", {
          attrs: {
            label: "MFA Required for Staff",
            name: "account[mfa_required_for_staff]",
            value: "false",
          },
          model: {
            value: _vm.formData.mfaRequiredForStaff,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "mfaRequiredForStaff", $$v)
            },
            expression: "formData.mfaRequiredForStaff",
          },
        }),
        _vm._v(" "),
        _c("FormFieldCheckbox", {
          attrs: {
            label: "Single Sign-On (SSO)",
            name: "account[sso_enabled]",
            value: "false",
          },
          model: {
            value: _vm.formData.ssoEnabled,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "ssoEnabled", $$v)
            },
            expression: "formData.ssoEnabled",
          },
        }),
        _vm._v(" "),
        _c("FormFieldCheckbox", {
          attrs: {
            label: "ADP® Workforce Now",
            name: "account[payroll_enabled]",
            value: "false",
          },
          model: {
            value: _vm.formData.payrollEnabled,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "payrollEnabled", $$v)
            },
            expression: "formData.payrollEnabled",
          },
        }),
        _vm._v(" "),
        _c("FormFieldCheckbox", {
          attrs: {
            label: "Remote I-9 Text Feature",
            name: "account[ri9_sms_enabled]",
            value: "false",
          },
          model: {
            value: _vm.formData.ri9SmsEnabled,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "ri9SmsEnabled", $$v)
            },
            expression: "formData.ri9SmsEnabled",
          },
        }),
        _vm._v(" "),
        _c("FormFieldCheckbox", {
          attrs: {
            label: "Onboarding Text Feature",
            name: "account[onboarding_sms_enabled]",
            value: "false",
          },
          model: {
            value: _vm.formData.onboardingSmsEnabled,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "onboardingSmsEnabled", $$v)
            },
            expression: "formData.onboardingSmsEnabled",
          },
        }),
        _vm._v(" "),
        _c("FormFieldCheckbox", {
          attrs: {
            label: "Main Inbox Pagination",
            name: "account[inbox_pagination_enabled]",
            value: "false",
          },
          model: {
            value: _vm.formData.inboxPaginationEnabled,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "inboxPaginationEnabled", $$v)
            },
            expression: "formData.inboxPaginationEnabled",
          },
        }),
        _vm._v(" "),
        _c("FormFieldCheckbox", {
          attrs: {
            label: "I-9 Inbox Pagination",
            name: "account[i9_inbox_pagination_enabled]",
            value: "false",
          },
          model: {
            value: _vm.formData.i9InboxPaginationEnabled,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "i9InboxPaginationEnabled", $$v)
            },
            expression: "formData.i9InboxPaginationEnabled",
          },
        }),
        _vm._v(" "),
        _c("FormFieldInput", {
          attrs: {
            orientation: "horizontal",
            required: true,
            label: "Administrator Email",
            errors: _vm.formErrors,
            name: "settings_administrator_email",
            type: "text",
          },
          model: {
            value: _vm.formData.administratorEmail,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "administratorEmail", $$v)
            },
            expression: "formData.administratorEmail",
          },
        }),
        _vm._v(" "),
        _c("FormFieldCheckbox", {
          attrs: {
            label: "No Administrator Email",
            name: "account[no_administrator_email]",
            value: "false",
          },
          model: {
            value: _vm.formData.noAdministratorEmail,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "noAdministratorEmail", $$v)
            },
            expression: "formData.noAdministratorEmail",
          },
        }),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c("FormFieldInput", {
          attrs: {
            orientation: "horizontal",
            required: true,
            label: "Salesforce ID",
            errors: _vm.formErrors,
            name: "settings_salesforce_id",
            type: "text",
          },
          model: {
            value: _vm.formData.salesforceId,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "salesforceId", $$v)
            },
            expression: "formData.salesforceId",
          },
        }),
        _vm._v(" "),
        _c("FormFieldCheckbox", {
          attrs: {
            label: "No Salesforce Account",
            name: "account[no_salesforce]",
            value: "false",
          },
          model: {
            value: _vm.formData.noSalesforceAccount,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "noSalesforceAccount", $$v)
            },
            expression: "formData.noSalesforceAccount",
          },
        }),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c("FormFieldSelect", {
          ref: "cloneInput",
          attrs: {
            orientation: "horizontal",
            required: false,
            label: "Clone from existing",
            errors: _vm.formErrors,
            name: "clone-account",
            options: _vm.accounts,
            hint: "All documents will be cloned from this existing account",
          },
          model: {
            value: _vm.formData.cloneAccount,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "cloneAccount", $$v)
            },
            expression: "formData.cloneAccount",
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c(
            "div",
            {
              staticClass: "col-sm-8 col-md-9 col-sm-offset-4 col-md-offset-3",
            },
            [
              _c(
                "LaddaButton",
                {
                  ref: "submitButton",
                  staticClass: "submit",
                  on: { click: _vm.handleSubmit },
                },
                [_vm._v("Submit")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn btn-default btn-form-cancel",
                  attrs: { href: _vm.$routes.accounts_path },
                },
                [_vm._v("Cancel")]
              ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }