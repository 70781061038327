<template>
  <SlideoutPanel
    ref="ManagedAccessPolicyTemplatesSlideout"
    :largeModal="true"
    size="xl"
    :title="title"
    watchPageContextVariable="ManagedAccessPolicyTemplatesSlideout"
    @modalHidden="handleModalHide"
  >
    <Spinner
      ref="Loader"
      v-if="isLoading"
    />
    <template
      v-else
    >
      <PermissionsSummary
        ref="PermissionsSummary"
        v-if="hasSufficientData"
        :permission-sets="this.policy.permission_sets"
      />
      <AlertMessage
        v-else
        variant="warning"
        message="Colud not load Policy"
        ref="noDataMessage"
      />
    </template>
  </SlideoutPanel>
</template>
<script>
  import AlertMessage from 'components/common/AlertMessage'
  import Spinner from 'components/common/Spinner'
  import PermissionsSummary from 'components/permissions/summary/PermissionsSummary'
  import SlideoutPanel from 'components/common/SlideoutPanel'

  import {
    mapMutations,
  } from 'vuex'
  import {
    DELETE_PAGE_CONTEXT_KEY,
    SET_PAGE_CONTEXT_KEYS
  } from 'vuex/mutation_types'

  export default {
    components: {
      AlertMessage,
      PermissionsSummary,
      Spinner,
      SlideoutPanel
    },

    props: {
      shown: {
        type: Boolean,
        default: false
      },
      policy: {
        type: Object,
      }
    },

    data () {
      return {
        isLoading: false,
      }
    },

    computed: {
      hasSufficientData () {
        return !!this.policy
      },
      title () {
        return this.policy?.name || ''
      }
    },

    watch: {
      shown () {
        if (this.shown) {
          this.show()
        } else {
          this.hide()
        }
      }
    },

    methods: {
      ...mapMutations({
        setPageContextKeys: SET_PAGE_CONTEXT_KEYS,
        deletePageContextKey: DELETE_PAGE_CONTEXT_KEY,
      }),
      handleModalHide () {
        this.hide()
      },
      hide () {
        this.deletePageContextKey('ManagedAccessPolicyTemplatesSlideout')
        this.$emit('hidden')
      },
      show () {
        this.setPageContextKeys({ManagedAccessPolicyTemplatesSlideout: true})
      },
    },

  }
</script>
