var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wb-card wb-card--upsell" }, [
    _c("div", { staticClass: "wb-card__title" }, [
      _vm._v("\n    Text Notifications are Now Available\n  "),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "wb-card__body mt-3" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "btn btn-primary mt-2",
          attrs: { href: _vm.$routes.text_notifications_accounts_path },
        },
        [_vm._v("\n      Learn More about Text Notifications\n    ")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _vm._v(
          "All WorkBright staff notifications can be sent via text message instead of email"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v("Text messages are 5x more likely to be opened than an email"),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v("Increase your hiring speed, completion rate, and throughput"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }