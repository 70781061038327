<template>
  <div class="row">
    <label class="control-label col-sm-5">
      {{ actionName }}
    </label>
    <div class="col-sm-7">
      <input type="checkbox" data-toggle="toggle" ref="toggle" v-model="permission.grant" />
    </div>
  </div>
</template>

<script>
  import PermissionsToggle from 'components/permissions/common/PermissionsToggle'

  export default {
    extends: PermissionsToggle,
    name: 'permissions-toggle-wide'
  }
</script>
