var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("bulk-action-list-alert-details", {
    ref: "bulkActionListAlertDetails",
    attrs: {
      collectionModule: "document_assignments",
      collectionViewName: _vm.collectionViewName,
      displayNameLookupFn: _vm.displayNameLookupFn,
      permissionModule: "document_assignments",
      permissionName: "unlock_submission",
      actionMethod: function (a) {
        return a.active
      },
      actionErrorMessage:
        "These submissions <strong>cannot be requested</strong> because the form has not been assigned to these staff members. In order to assign a new form, assign the staff member to a group that includes the desired form.",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }