var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _vm.valid
      ? _c("span", [
          _c("time", { ref: "wbsimple", staticClass: "wb-simple" }, [
            _vm._v("\n      " + _vm._s(_vm.dateTimeSimple) + "\n    "),
          ]),
          _vm._v(" "),
          _vm.includeTimeago
            ? _c("span", { staticClass: "text-muted" }, [
                _vm._v("\n      (\n      "),
                _c("time", {
                  ref: "timeago",
                  staticClass: "timeago time-dist",
                  attrs: { datetime: this.value },
                }),
                _vm._v("\n      )\n    "),
              ])
            : _vm._e(),
        ])
      : _c("span", [_vm._v("\n    " + _vm._s(_vm.invalidMessage) + "\n  ")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }