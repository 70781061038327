var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Panel", {
    attrs: { variant: "snapshot" },
    scopedSlots: _vm._u([
      {
        key: "heading",
        fn: function () {
          return [
            _c("div", { staticClass: "panel-title" }, [
              _c("h3", [_vm._v("Action Required")]),
              _vm._v(" "),
              _c("h4", [
                _vm._v(_vm._s(_vm.$locale.t("everify.status.referred.title"))),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c("p", [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$locale.t("everify.status.referred.helpText")) +
                  "\n    "
              ),
            ]),
            _vm._v(" "),
            _vm.shouldRenderLinks
              ? [
                  _c("div", { staticClass: "mb-2" }, [
                    _vm.fanPath
                      ? _c(
                          "a",
                          {
                            ref: "fanLink",
                            staticClass: "btn btn-default",
                            attrs: {
                              href: _vm.fanPath,
                              download: "",
                              target: "_blank",
                            },
                          },
                          [
                            _c("WbIcon", { attrs: { type: "download" } }),
                            _vm._v(
                              "\n          Download Further Action Notice - English\n        "
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mb-2" }, [
                    _vm.fanPathSpanish
                      ? _c(
                          "a",
                          {
                            ref: "fanLinkSpanish",
                            staticClass: "btn btn-default",
                            attrs: {
                              href: _vm.fanPathSpanish,
                              download: "",
                              target: "_blank",
                            },
                          },
                          [
                            _c("WbIcon", { attrs: { type: "download" } }),
                            _vm._v(
                              "\n          Download Further Action Notice - Spanish\n        "
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mb-2" }, [
                    _vm.referralPath
                      ? _c(
                          "a",
                          {
                            ref: "referralLink",
                            staticClass: "btn btn-default",
                            attrs: {
                              download: "",
                              href: _vm.referralPath,
                              target: "_blank",
                            },
                          },
                          [
                            _c("WbIcon", { attrs: { type: "download" } }),
                            _vm._v(
                              "\n          Download Referral Date Confirmation Letter - English\n        "
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm.referralPathSpanish
                      ? _c(
                          "a",
                          {
                            ref: "referralLinkSpanish",
                            staticClass: "btn btn-default",
                            attrs: {
                              download: "",
                              href: _vm.referralPathSpanish,
                              target: "_blank",
                            },
                          },
                          [
                            _c("WbIcon", { attrs: { type: "download" } }),
                            _vm._v(
                              "\n          Download Referral Date Confirmation Letter - Spanish\n        "
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]
              : !_vm.hasSufficientData
              ? _c(
                  "div",
                  { ref: "noLinksAlert", staticClass: "alert alert-warning" },
                  [
                    _vm._v(
                      "\n      " +
                        _vm._s(
                          _vm.$locale.t("everify.status.referred.noData")
                        ) +
                        "\n    "
                    ),
                  ]
                )
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }