<template>
  <WbModal
    :title="title"
    :watchPageContextVariable="modalKey"
    ref="modal"
    @modalHidden="handleModalClose"
  >
    <slot />
    <div slot="footer" class="text-right">
      <WbButton
        context="default"
        @click="handleCancel"
      >
        Cancel
      </WbButton>
      <WbButton
        context="primary"
        @click="handleConfirm"
      >
        Save
      </WbButton>
    </div>
  </WbModal>
</template>
<script>
  import WbModal from 'components/common/WbModal'
  import WbButton from 'components/common/WbButton'
  import { mapMutations } from 'vuex'

  export default {
    components: {
      WbButton,
      WbModal
    },
    props: {
      title: {
        type: String,
        required: true
      },
      open: {
        type: Boolean
      },
    },
    computed: {
      modalKey () {
        return `dialog-${this._uid}`
      }
    },
    watch: {
      open () {
        if(this.open) {
          this.show()
        }
      }
    },
    methods: {
      ...mapMutations({
        deletePageContextKey: 'DELETE_PAGE_CONTEXT_KEY',
        setPageContextKeys: 'SET_PAGE_CONTEXT_KEYS',
      }),
      closeModal () {
        this.deletePageContextKey(this.modalKey)
      },
      show () {
        const keys = {}
        keys[this.modalKey] = true
        this.setPageContextKeys(keys)
      },
      handleCancel () {
        this.closeModal()
        this.handleModalClose()
      },
      handleConfirm () {
        this.closeModal()
        this.$emit('confirmed')
      },
      handleModalClose () {
        this.$emit('cancelled')
      }
    },
  }
</script>
