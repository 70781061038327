import Vue from 'vue'

function initTooltip(el, binding) {
  const $el = $(el)

  $el.tooltip('destroy') // Remove existing tooltip

  if(!binding.value || binding.value == '') { return } // Don't show a tooltip without content

  const placement = (() => {
    if(binding.modifiers.bottom) { return 'bottom' }
    else if(binding.modifiers.left) { return 'left' }
    else if(binding.modifiers.right) { return 'right' }
    else { return 'top' }
  })()

  const html = binding.modifiers.html ? 'html' : null

  $el.tooltip({
    container: 'body',
    title: binding.value,
    placement: placement,
    html: html
  })
}

export default Vue.directive('tooltip', {
  inserted: (el, binding) => {
    initTooltip(el, binding)
  },

  componentUpdated: (el, binding) => {
    initTooltip(el, binding)
  }
})
