import UserImportPageHeader from 'components/user_imports/UserImportPageHeader'
import { pureOrphanVue } from 'lib/vue/pureOrphanVue'

export default Marionette.LayoutView.extend({
  template: '#layout-import',
  regions: {
    statusRegion: '.status'
  },

  onRender: () => {
    pureOrphanVue(UserImportPageHeader, "component.vue-user-import-header", {
      props: {
        wizardStep: 'import'
      },
    })
  },
})
