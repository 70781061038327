import FileView from 'views/common/file_view'
import AttachedFile from 'models/attached_file'


// Viewer for I-9 attachments on existing submissions. Use EditAttachmentView for uploading.
//
// Model - (I9Attachment)
export default Backbone.Marionette.ItemView.extend({
  template: '#i9-view-attachment-tmpl',
  className: 'i9-attachment',

  ui: {
    fileFrontViewContainer: '.file-front-view-container',
    fileBackViewContainer: '.file-back-view-container',
  },

  bindings: {
    '.info-document-title': {
      observe: 'document_title',
      escape: true,
    },
    '.info-issuing-authority' : {
      observe: 'issuing_authority',
      escape: true,
    },
    '.info-document-number': {
      observe: 'document_number',
      escape: true,
    },
    '.info-expiration-date': {
      observe: 'expiration_date',
      escape: true,
    },
  },

  onShow: function() {
    var fileFront = new AttachedFile(this.model.fileFront())
    this._frontFileView = new FileView({ el: this.ui.fileFrontViewContainer, model: fileFront })
    this._frontFileView.render()

    var hasBackFile = this._hasBackFile()
    if (hasBackFile) {
      var fileBack = new AttachedFile(this.model.fileBack())
      this._fileBackView = new FileView({ el: this.ui.fileBackViewContainer, model: fileBack })
      this._fileBackView.render()
    }

    this.ui.fileFrontViewContainer.toggleClass('col-sm-6', hasBackFile)
    this.ui.fileBackViewContainer.toggleClass('col-sm-6', hasBackFile)
  },

  onRender: function() {
    this.stickit()
  },

  onBeforeDestroy: function() {
    if (this._frontFileView) {
      this._frontFileView.destroy()
    }

    if (this._backFileView) {
      this._backFileView.destroy()
    }
  },

  // Whether or not the I9Attachment has a back file
  //
  // Returns - Boolean
  _hasBackFile: function() {
    var fileBackUrl = this.model.get('file_back_url')
    return (!!fileBackUrl && fileBackUrl.length > 0)
  },
})
