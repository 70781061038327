var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showNotReadyToStart
        ? _c("div", { ref: "notReady" }, [
            _c("p", [
              _vm._v("Sorry, but your I-9 is not ready to be countersigned."),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showSubmitGeoLocation
        ? _c("BrowserConfirmLocation", {
            ref: "geolocation",
            attrs: {
              minimumAgeToCountersign:
                _vm.pageContext.i9RemoteCountersign.minimumAgeToCountersign,
            },
            on: {
              geolocationSuccess: _vm.handleGeolocationSuccess,
              geolocationError: _vm.handleGeolocationError,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showSubmitAuthRepPhone
        ? _c("div", { ref: "authRepPhone" }, [
            _c("h4", [_vm._v("Step 2.")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n      When you are with the person who will review your document(s), enter their cell number. It will text them a link. You will both need good cell coverage to proceed.\n    "
              ),
            ]),
            _vm._v(" "),
            _c(
              "form",
              { on: { submit: _vm.handleSubmitForm } },
              [
                _c("form-field-input", {
                  attrs: {
                    placeholder: "555-555-5555",
                    required: true,
                    label: "Document Reviewer Cell Number",
                    errors: _vm.formErrors,
                    name: "auth_rep_phone",
                  },
                  model: {
                    value: _vm.formData.auth_rep_phone,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "auth_rep_phone", $$v)
                    },
                    expression: "formData.auth_rep_phone",
                  },
                }),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c(
                  "ladda-button",
                  {
                    ref: "submitAuthRepPhoneButton",
                    on: { click: _vm.handleClickSubmitAuthRepPhone },
                  },
                  [_vm._v("\n        Send Text Message\n      ")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isQrStep
        ? [
            _vm.employeeScansQrCodeEnabled
              ? _c(
                  "ScanQrCode",
                  {
                    ref: "scanQrCode",
                    attrs: {
                      actor: "employee",
                      stepNumber: 3,
                      alertMessage: _vm.qrCodeAlertMessage,
                      qrCodeTarget: _vm.qrCodePayload,
                      remoteId: _vm.remoteCountersignId,
                      action: "countersign",
                    },
                  },
                  [
                    _c(
                      "div",
                      { attrs: { slot: "instructions" }, slot: "instructions" },
                      [
                        _c("p", [
                          _vm._v(
                            "\n          In order to verify you are in the same location as your I-9 reviewer, we must use another method.\n          To begin, please have your I-9 reviewer visit the link they were sent via text message.\n          "
                          ),
                          _c("em", [
                            _vm._v(
                              "\n            They may have already done this and are now being shown a QR code on their device.\n          "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            '\n          Next, click the "Begin QR Code Scan" button below.\n          The view from your camera should appear.\n          Now, scan the QR code on your I-9 reviewer\'s device.\n        '
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "text-em" }, [
                          _c("em", [
                            _vm._v(
                              "\n            If your camera view does not appear, don't worry!\n            Simply open your device's camera app or QR code scanner app, and scan the QR code.\n          "
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                )
              : _c(
                  "ShowQrCode",
                  {
                    ref: "showQrCode",
                    attrs: {
                      actor: "employee",
                      stepNumber: 3,
                      alertMessage: _vm.qrCodeAlertMessage,
                      qrCodePayload: _vm.qrCodePayload,
                    },
                  },
                  [
                    _c(
                      "div",
                      { attrs: { slot: "instructions" }, slot: "instructions" },
                      [
                        _c("p", [
                          _vm._v(
                            '\n          Have your I-9 reviewer select "Begin QR Code Scan" to open their camera. Allow them to scan the QR code that is displayed below. If they do not have a device with access to a camera, select choose a different person and start over at the bottom of this screen.\n        '
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.showWaitingOnAuthRep
        ? _c("div", { ref: "waitingOnAuthRep" }, [
            _c("p", [
              _vm._v(
                "\n      Make sure you have your document(s) and get ready to show them to the person who you have chosen to review them.\n    "
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showReset
        ? _c("div", { ref: "resetSection" }, [
            _c("hr"),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n      If your authorized representative has yet to receive their text, you may use your mobile device's text app to manually nudge them by clicking the button below.\n    "
              ),
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                ref: "sendText",
                staticClass: "btn btn-default",
                attrs: { href: _vm.smsAppTarget },
                on: { click: _vm.handleClickNudgeAuthRep },
              },
              [_vm._v("Send Text Message")]
            ),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c(
              "a",
              {
                ref: "resetLink",
                attrs: { href: "#reset" },
                on: { click: _vm.handleClickReset },
              },
              [
                _vm._v(
                  "\n      Click here if you need to choose a different person and start over.\n    "
                ),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showCountersignAccepted
        ? _c("div", { ref: "countersignAccepted" }, [
            _c("p", [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.$locale.t("remoteCountersign.verification_finished")
                  ) +
                  "\n    "
              ),
            ]),
            _vm._v(" "),
            _vm.shouldLinkToEmbeddingApp
              ? _c("p", { ref: "showingLinkToEmbeddingApp" }, [
                  _c("a", { attrs: { href: _vm.currentAccount.embeddedUrl } }, [
                    _vm._v(_vm._s(_vm.embeddedRedirectText)),
                  ]),
                ])
              : _c("p", { ref: "showingLinkToDashboard" }, [
                  _c("a", { attrs: { href: _vm.staffDashboardPath } }, [
                    _vm._v(
                      _vm._s(
                        _vm.$locale.t("remoteCountersign.return_dashboard")
                      )
                    ),
                  ]),
                ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showCountersignRejected
        ? _c(
            "div",
            { ref: "countersignRejected" },
            [
              _vm.allowToStartOver
                ? [
                    _c("p", [
                      _vm._v(
                        "\n        I-9 verification could not be completed using this verification process. You may start over if you wish to provide alternate document(s) or modify the information you provided on your previous submission.\n      "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "WbButton",
                      {
                        ref: "startOverButton",
                        staticClass: "mb-3 d-block ml-auto",
                        on: { click: _vm.handleStartOver },
                      },
                      [_vm._v("\n        Start Over\n      ")]
                    ),
                    _vm._v(" "),
                    _c("p", { staticClass: "mb-4" }, [
                      _vm._v(
                        "\n        If have any questions or believe this was an error, you may contact your employer. Thank you!\n      "
                      ),
                    ]),
                  ]
                : [
                    _c("p", [
                      _vm._v(
                        "\n        I-9 verification could not be completed using this verification process. If you believe this is an error or wish to provide alternative document(s), you may contact your employer. Thank you, you are finished.\n      "
                      ),
                    ]),
                  ],
              _vm._v(" "),
              _c("p", [
                _c("a", { attrs: { href: _vm.staffDashboardPath } }, [
                  _vm._v(
                    _vm._s(_vm.$locale.t("remoteCountersign.return_dashboard"))
                  ),
                ]),
              ]),
            ],
            2
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }