var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    { attrs: { href: "javascript:void(0)" }, on: { click: _vm.clicked } },
    [
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.allSelected || _vm.onePageOnly,
              expression: "allSelected || onePageOnly",
            },
          ],
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.totalItemCount) +
              " staff selected. Click to cancel all selections.\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.allSelected,
              expression: "!allSelected",
            },
            {
              name: "tooltip",
              rawName: "v-tooltip.html",
              value: _vm.toolTipMsg,
              expression: "toolTipMsg",
              modifiers: { html: true },
            },
          ],
        },
        [
          _vm._v(
            "\n    Click to select all " +
              _vm._s(_vm.totalItemCount) +
              " staff that match this search/filter.\n  "
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }