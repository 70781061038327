var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "SlideoutPanel",
    {
      ref: "ManagedAccessPolicyTemplatesSlideout",
      attrs: {
        largeModal: true,
        size: "xl",
        title: _vm.title,
        watchPageContextVariable: "ManagedAccessPolicyTemplatesSlideout",
      },
      on: { modalHidden: _vm.handleModalHide },
    },
    [
      _vm.isLoading
        ? _c("Spinner", { ref: "Loader" })
        : [
            _vm.hasSufficientData
              ? _c("PermissionsSummary", {
                  ref: "PermissionsSummary",
                  attrs: { "permission-sets": this.policy.permission_sets },
                })
              : _c("AlertMessage", {
                  ref: "noDataMessage",
                  attrs: {
                    variant: "warning",
                    message: "Colud not load Policy",
                  },
                }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }