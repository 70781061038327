var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "panel gray-bg p-3" },
    [
      _vm.authenticationFailure
        ? _c("AlertMessage", {
            ref: "authFailure",
            attrs: {
              title: "Authentication Failure",
              message: _vm.$locale.t(
                "everify_account.authFailure",
                _vm.companyName,
                _vm.helpDeskLink
              ),
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("h3", [_vm._v("\n    Configure E-Verify\n  ")]),
      _vm._v(" "),
      _c("p", [
        _vm._v("This information can be found on the E-Verify MOU you signed."),
      ]),
      _vm._v(" "),
      _c(
        "form",
        [
          _c("FormFieldInput", {
            attrs: {
              label: "Company ID",
              name: "company_id",
              placeholder: "3048309",
              required: true,
              errors: _vm.formErrors,
            },
            model: {
              value: _vm.formData.company_id,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "company_id", $$v)
              },
              expression: "formData.company_id",
            },
          }),
          _vm._v(" "),
          _c("FormFieldInput", {
            attrs: {
              label: "E-Verify Program Administrator ID",
              name: "program_administrator_id",
              placeholder: "KSDFJ3498",
              required: true,
              errors: _vm.formErrors,
            },
            model: {
              value: _vm.formData.program_administrator_id,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "program_administrator_id", $$v)
              },
              expression: "formData.program_administrator_id",
            },
          }),
          _vm._v(" "),
          _c("FormField", {
            staticClass: "mb-0",
            attrs: {
              label: "E-Verify Program Administrator Name",
              required: true,
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-6" },
              [
                _c("FormFieldInput", {
                  attrs: {
                    name: "program_administrator_first_name",
                    placeholder: "First Name",
                    required: true,
                    errors: _vm.formErrors,
                  },
                  model: {
                    value: _vm.formData.program_administrator_first_name,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.formData,
                        "program_administrator_first_name",
                        $$v
                      )
                    },
                    expression: "formData.program_administrator_first_name",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-6" },
              [
                _c("FormFieldInput", {
                  attrs: {
                    name: "program_administrator_last_name",
                    placeholder: "Last Name",
                    required: true,
                    errors: _vm.formErrors,
                  },
                  model: {
                    value: _vm.formData.program_administrator_last_name,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.formData,
                        "program_administrator_last_name",
                        $$v
                      )
                    },
                    expression: "formData.program_administrator_last_name",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("FormFieldInput", {
            attrs: {
              label: "E-Verify Program Administrator Email",
              name: "program_administrator_email",
              placeholder: "james@smith.com",
              required: true,
              errors: _vm.formErrors,
            },
            model: {
              value: _vm.formData.program_administrator_email,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "program_administrator_email", $$v)
              },
              expression: "formData.program_administrator_email",
            },
          }),
          _vm._v(" "),
          _c("FormFieldInput", {
            attrs: {
              label: "E-Verify Program Administrator Phone",
              name: "program_administrator_phone",
              placeholder: "123-456-7890",
              required: true,
              errors: _vm.formErrors,
            },
            model: {
              value: _vm.formData.program_administrator_phone,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "program_administrator_phone", $$v)
              },
              expression: "formData.program_administrator_phone",
            },
          }),
          _vm._v(" "),
          _c(
            "FormField",
            { attrs: { label: "Web Services Credentials", required: true } },
            [
              _vm.modifyingWSC
                ? _c("div", [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-6" },
                        [
                          _c("FormFieldInput", {
                            attrs: {
                              name: "web_service_username",
                              placeholder: "Username",
                              required: true,
                              errors: _vm.formErrors,
                            },
                            model: {
                              value: _vm.formData.web_service_username,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "web_service_username",
                                  $$v
                                )
                              },
                              expression: "formData.web_service_username",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-6" },
                        [
                          _c("FormFieldInput", {
                            staticClass: "mb-1",
                            attrs: {
                              name: "web_service_password",
                              placeholder: "Password",
                              type: "password",
                              required: true,
                              errors: _vm.formErrors,
                            },
                            model: {
                              value: _vm.formData.web_service_password,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "web_service_password",
                                  $$v
                                )
                              },
                              expression: "formData.web_service_password",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "mx-2" }, [
                            !_vm.isNewAccount
                              ? _c("small", [
                                  _vm._v(
                                    "\n                The existing password will be used until changed.\n              "
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _c("div", [
                    _c("div", { staticClass: "well" }, [
                      _c("strong", [
                        _vm._v(
                          "\n            Web Services are currently set up for user " +
                            _vm._s(_vm.formData.web_service_username) +
                            ".\n            "
                        ),
                        _c("br"),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:void(0)" },
                            on: { click: _vm.modifyCredentials },
                          },
                          [
                            _vm._v(
                              "\n              Update Credentials\n            "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { ref: "startDateMessage", staticClass: "alert alert-info" },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$locale.t("everify_account.startDateMessage")) +
                  "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-group text-right" },
            [
              _c(
                "LaddaButton",
                {
                  ref: "submitButton",
                  staticClass: "submit",
                  on: { click: _vm.handleSubmit },
                },
                [_vm._v("Submit")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }