var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h2", [_vm._v("Edit Company Profile")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-horizontal panel profile" },
      [
        _c("FormFieldInput", {
          ref: "companyName",
          attrs: {
            orientation: "horizontal",
            label: "Company Name",
            errors: _vm.formErrors,
            name: "name",
            type: "text",
            required: true,
          },
          model: {
            value: _vm.formData.name,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "name", $$v)
            },
            expression: "formData.name",
          },
        }),
        _vm._v(" "),
        _c("FormFieldInput", {
          ref: "street",
          attrs: {
            orientation: "horizontal",
            label: "Address",
            errors: _vm.formErrors,
            name: "street",
            type: "text",
          },
          model: {
            value: _vm.formData.address.street,
            callback: function ($$v) {
              _vm.$set(_vm.formData.address, "street", $$v)
            },
            expression: "formData.address.street",
          },
        }),
        _vm._v(" "),
        _c("FormFieldInput", {
          ref: "city",
          attrs: {
            orientation: "horizontal",
            label: "City",
            errors: _vm.formErrors,
            name: "city",
            type: "text",
          },
          model: {
            value: _vm.formData.address.city,
            callback: function ($$v) {
              _vm.$set(_vm.formData.address, "city", $$v)
            },
            expression: "formData.address.city",
          },
        }),
        _vm._v(" "),
        _c("FormFieldSelect", {
          ref: "state",
          attrs: {
            orientation: "horizontal",
            label: "State",
            errors: _vm.formErrors,
            name: "state",
            options: _vm.states,
            value: _vm.formData.address.state,
          },
          model: {
            value: _vm.formData.address.state,
            callback: function ($$v) {
              _vm.$set(_vm.formData.address, "state", $$v)
            },
            expression: "formData.address.state",
          },
        }),
        _vm._v(" "),
        _c("FormFieldInput", {
          ref: "zip",
          attrs: {
            orientation: "horizontal",
            label: "Zip Code",
            errors: _vm.formErrors,
            name: "zip",
            type: "text",
          },
          model: {
            value: _vm.formData.address.zip,
            callback: function ($$v) {
              _vm.$set(_vm.formData.address, "zip", $$v)
            },
            expression: "formData.address.zip",
          },
        }),
        _vm._v(" "),
        _vm.einFieldVisible
          ? _c("FormFieldInput", {
              ref: "ein",
              attrs: {
                orientation: "horizontal",
                label: "EIN",
                errors: _vm.formErrors,
                name: "settings_ein",
                type: "text",
              },
              model: {
                value: _vm.formData.settings_ein,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "settings_ein", $$v)
                },
                expression: "formData.settings_ein",
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("FormFieldSelect", {
          ref: "timezone",
          attrs: {
            orientation: "horizontal",
            label: "Time Zone",
            errors: _vm.formErrors,
            name: "settings_time_zone",
            options: _vm.timezones,
            required: true,
          },
          model: {
            value: _vm.formData.settings_time_zone,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "settings_time_zone", $$v)
            },
            expression: "formData.settings_time_zone",
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-sm-offset-4 col-md-offset-3 col-sm-7" },
            [
              _c(
                "LaddaButton",
                {
                  ref: "submit",
                  staticClass: "form-field",
                  on: { click: _vm.saveButtonClicked },
                },
                [_vm._v("\n          Save\n        ")]
              ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }