var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "documentVerificationDetails",
      staticClass: "document-verification-details-table panel panel-default",
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("table", { staticClass: "table info-table panel-info-table" }, [
        _c("tbody", [
          _c("tr", [
            _c("td", { ref: "documentVerificationStatus" }, [
              _vm._v("\n          Document Verification Status\n          "),
              _c(
                "a",
                {
                  ref: "popover",
                  staticClass: "help-popover",
                  attrs: {
                    href: "javascript:void(0)",
                    "data-content": _vm.tooltipMessage,
                    "data-placement": "auto top",
                    role: "button",
                    tabIndex: "0",
                    "data-toggle": "popover",
                    "data-trigger": "focus",
                  },
                },
                [_c("i", { staticClass: "fa fa-question-circle" })]
              ),
            ]),
            _vm._v(" "),
            _c(
              "td",
              [
                _c("DocumentVerificationBadge", {
                  attrs: { status: _vm.status, submission: _vm.submission },
                  on: { update: _vm.handleUpdate },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel-heading" }, [
      _c("h6", { staticClass: "panel-title" }, [
        _vm._v("Document Verification"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }