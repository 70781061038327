import { render, staticRenderFns } from "./BulkActionsDropdown.vue?vue&type=template&id=2f126aa6&"
import script from "./BulkActionsDropdown.vue?vue&type=script&lang=js&"
export * from "./BulkActionsDropdown.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/workbright/shared/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2f126aa6')) {
      api.createRecord('2f126aa6', component.options)
    } else {
      api.reload('2f126aa6', component.options)
    }
    module.hot.accept("./BulkActionsDropdown.vue?vue&type=template&id=2f126aa6&", function () {
      api.rerender('2f126aa6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/assets/javascripts/src/components/common/BulkActionsDropdown.vue"
export default component.exports