var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasValidFields
    ? _c(
        "table",
        { ref: "container", staticClass: "table info-table panel-info-table" },
        [
          _c(
            "caption",
            { staticClass: "info-sub-header" },
            [_vm._t("caption")],
            2
          ),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.fields, function (field) {
              return _c(
                "tr",
                {
                  key: field.id,
                  staticClass: "info-item anim",
                  class: {
                    "anim-temporary-highlight": _vm.checkHighlightField(field),
                  },
                },
                [
                  _c(
                    "th",
                    { staticClass: "col-md-7", attrs: { scope: "row" } },
                    [_vm._v("\n        " + _vm._s(field.label) + "\n      ")]
                  ),
                  _vm._v(" "),
                  _c("td", { staticClass: "col-md-5 text-right" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.getDisplayValueFromField(field)) +
                        "\n      "
                    ),
                  ]),
                ]
              )
            }),
            0
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }