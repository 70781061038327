var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "WbModal",
        {
          ref: "modal",
          attrs: {
            title: _vm.modalTitle,
            watchPageContextVariable: _vm.collectionViewName,
            locked: _vm.loading,
          },
          on: { modalHidden: _vm.handleModalHidden },
        },
        [
          _c(
            "v-wait",
            { attrs: { for: _vm.loadingName } },
            [
              _c("Spinner", {
                attrs: { slot: "waiting", showLongWaitMessage: true },
                slot: "waiting",
              }),
              _vm._v(" "),
              _c("p", { ref: "download-label", staticClass: "breathe" }, [
                _vm._v(
                  "\n        Download files for " +
                    _vm._s(_vm.pluralizedStaffMembers) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  ref: "formRow",
                  staticClass: "row py-4",
                  class: { "has-error": _vm.hasMissingFormsError },
                },
                [
                  _c("div", { staticClass: "col-xs-3" }, [
                    _c("h4", { staticClass: "mt-2 pull-right" }, [
                      _c(
                        "div",
                        { staticClass: "required-field control-label" },
                        [_vm._v("\n              Forms\n            ")]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-xs-9" },
                    [
                      _vm.documentOptions && _vm.documentOptions.length
                        ? _c("WbMultiselect", {
                            ref: "documentSelect",
                            attrs: {
                              selectOptions: _vm.documentOptions,
                              enableSearchField: true,
                              selectAll: true,
                              selectAllText: _vm.selectAllText,
                            },
                            on: {
                              input: function (values) {
                                return _vm.setSelectedDocuments(values)
                              },
                            },
                          })
                        : _c(
                            "div",
                            {
                              ref: "noDocSelect",
                              staticClass: "alert alert-warning",
                            },
                            [_vm._v(_vm._s(_vm.emptyDocumentsMsg))]
                          ),
                      _vm._v(" "),
                      _vm.hasMissingFormsError
                        ? _c(
                            "small",
                            {
                              ref: "missingFormError",
                              staticClass: "help-block",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.missingFormsError) +
                                  "\n          "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "row breathe" }, [
                _c("div", { staticClass: "col-xs-3" }, [
                  _c("h4", { staticClass: "mt-2 pull-right" }, [
                    _vm._v("\n            Options\n          "),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-xs-9" },
                  [
                    _c("FormFieldRadio", {
                      ref: "previousSubmissions",
                      staticClass: "m-0",
                      attrs: {
                        name: "includePreviousSubmissions",
                        options: _vm.previousSubmissions.options,
                        label: _vm.previousSubmissions.label,
                      },
                      model: {
                        value: _vm.formData.includePreviousSubmissions,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData,
                            "includePreviousSubmissions",
                            $$v
                          )
                        },
                        expression: "formData.includePreviousSubmissions",
                      },
                    }),
                    _vm._v(" "),
                    _c("hr"),
                    _vm._v(" "),
                    _c("FormFieldRadio", {
                      ref: "acceptedSubmissions",
                      staticClass: "m-0",
                      attrs: {
                        name: "acceptedOnly",
                        options: _vm.acceptedSubmissions.options,
                        label: _vm.acceptedSubmissions.label,
                      },
                      model: {
                        value: _vm.formData.acceptedOnly,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "acceptedOnly", $$v)
                        },
                        expression: "formData.acceptedOnly",
                      },
                    }),
                    _vm._v(" "),
                    _c("hr"),
                    _vm._v(" "),
                    _vm.viewSupplementalFilesPermitted
                      ? _c(
                          "div",
                          {
                            ref: "supplimentalFilesInput",
                            staticClass: "input-wrapper checkbox",
                          },
                          [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.formData.supplementalFiles,
                                    expression: "formData.supplementalFiles",
                                  },
                                ],
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.formData.supplementalFiles
                                  )
                                    ? _vm._i(
                                        _vm.formData.supplementalFiles,
                                        null
                                      ) > -1
                                    : _vm.formData.supplementalFiles,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.formData.supplementalFiles,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.formData,
                                            "supplementalFiles",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.formData,
                                            "supplementalFiles",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.formData,
                                        "supplementalFiles",
                                        $$c
                                      )
                                    }
                                  },
                                },
                              }),
                              _vm._v(
                                "\n              Include staff supplemental files\n            "
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "input-wrapper checkbox" }, [
                      _c("label", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formData.deactivationNotes,
                              expression: "formData.deactivationNotes",
                            },
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.formData.deactivationNotes
                            )
                              ? _vm._i(_vm.formData.deactivationNotes, null) >
                                -1
                              : _vm.formData.deactivationNotes,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.formData.deactivationNotes,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.formData,
                                      "deactivationNotes",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.formData,
                                      "deactivationNotes",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.formData, "deactivationNotes", $$c)
                              }
                            },
                          },
                        }),
                        _vm._v(
                          "\n              Include staff deactivation notes\n            "
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "row breathe",
                  class: { "has-error": _vm.hasDescriptionError },
                },
                [
                  _c("div", { staticClass: "col-xs-3" }, [
                    _c("h4", { staticClass: "mt-2 pull-right control-label" }, [
                      _vm._v("\n            Description\n          "),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-xs-9" },
                    [
                      _c("FormFieldInput", {
                        attrs: {
                          errors: _vm.formErrors,
                          name: "description",
                          hint: _vm.descriptionHint,
                          type: "text",
                        },
                        on: { input: _vm.validateDescriptionLength },
                        model: {
                          value: _vm.formData.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "description", $$v)
                          },
                          expression: "formData.description",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", [
                _c("h4", [_vm._v("What Happens Next?")]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n          We will generate a .zip file containing the form submissions (as PDFs) of the selected forms\n          for the selected staff. This will include uploaded photos, such as certifications and licenses.\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n          Downloads can take a few minutes or several hours depending on the size of your request and\n          the volume of current requests. Once the download is complete, you will receive an email and\n          be able to download it from "
                  ),
                  _c(
                    "a",
                    { attrs: { href: _vm.$routes.admin_downloads_path } },
                    [_vm._v("your downloads page")]
                  ),
                  _vm._v(
                    ".\n          Your downloads page is accessible from the navigation helper that opens when you click your\n          name in the top right corner.\n        "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  attrs: { disabled: _vm.loading },
                  on: { click: _vm.hide },
                },
                [_vm._v("\n        Cancel\n      ")]
              ),
              _vm._v(" "),
              _c(
                "LaddaButton",
                {
                  ref: "submitButton",
                  attrs: { disabled: _vm.isButtonDisabled },
                  on: { click: _vm.submit },
                },
                [_vm._v("\n        Request Download\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }