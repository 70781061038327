var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.queryResults.length > 0
    ? _c("div", { staticClass: "tenant-select-list-container" }, [
        _c("h4", { staticClass: "text-center breathe" }, [
          _vm._v("\n    Choose an Organization\n  "),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "list-group" },
          _vm._l(_vm.queryResults, function (account) {
            return _c(
              "a",
              {
                key: account.id,
                staticClass: "list-group-item",
                class: { disabled: _vm.checkIsAccountDisabled(account) },
                attrs: { href: _vm.accountPath(account) },
              },
              [
                _c("div", { staticClass: "d-flex align-items-center" }, [
                  _c("div", { staticClass: "w-100 pr-1" }, [
                    _c("h4", { staticClass: "list-group-item-heading" }, [
                      _vm._v(
                        "\n            " + _vm._s(account.name) + "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "d-sm-flex justify-content-sm-between" },
                      [
                        _c("p", { staticClass: "list-group-item-text" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.getHostname(account)) +
                              "\n            "
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "account-access-metadata mt-1 small" },
                      [
                        account.last_access
                          ? _c(
                              "span",
                              { staticClass: "date-last-access" },
                              [
                                _vm._v(
                                  "\n              Last accessed\n              "
                                ),
                                _c("WbSimpleDateTime", {
                                  attrs: { value: account.last_access },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        account.expires_at
                          ? _c("span", [
                              _vm._v("\n              ·\n              "),
                              account.access
                                ? _c(
                                    "span",
                                    { staticClass: "date-expires" },
                                    [
                                      _vm._v(
                                        "\n                Expires\n                "
                                      ),
                                      _c("WbSimpleDateTime", {
                                        attrs: { value: account.expires_at },
                                      }),
                                    ],
                                    1
                                  )
                                : _c(
                                    "span",
                                    { staticClass: "date-expired" },
                                    [
                                      _vm._v(
                                        "\n                Expired\n                "
                                      ),
                                      _c("WbSimpleDateTime", {
                                        attrs: { value: account.expired_at },
                                      }),
                                    ],
                                    1
                                  ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  account.access
                    ? _c("i", {
                        staticClass: "fa fa-chevron-right fa-lg text-muted",
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isSuperadmin && !account.access
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          on: {
                            click: function ($event) {
                              return _vm.gainAccess(account)
                            },
                          },
                        },
                        [_vm._v("\n          Access\n        ")]
                      )
                    : _vm._e(),
                ]),
              ]
            )
          }),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }