var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.action == "activate"
        ? _c("bulk-activate-container", {
            ref: "activate",
            on: { hidden: _vm.cleanup, success: _vm.handleSuccess },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.action == "deactivate"
        ? _c("bulk-deactivate-container", {
            ref: "deactivate",
            on: { hidden: _vm.cleanup, success: _vm.handleSuccess },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }