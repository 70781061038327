var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.shouldShow
    ? _c(
        "div",
        {
          ref: "component",
          attrs: { id: "active-employment-form-i9-options" },
        },
        [
          _c("div", { staticClass: "panel panel-simple" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "table",
              {
                staticClass: "table info-table panel-info-table",
                attrs: { id: "form-i9-options" },
              },
              [
                _c("tbody", [
                  _vm.shouldShowEverify
                    ? _c("tr", { ref: "everify" }, [
                        _c(
                          "th",
                          { staticClass: "col-md-4", attrs: { scope: "row" } },
                          [_vm._v("E-Verify Case Status")]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass:
                              "col-md-8 info-item everify-case-status",
                          },
                          [
                            _vm.everifyCase
                              ? [
                                  _c("EverifyCaseStatusIndicator", {
                                    attrs: { everifyCase: _vm.everifyCase },
                                  }),
                                  _vm._v(" "),
                                  !_vm.everifyCaseClosed
                                    ? _c(
                                        "div",
                                        { staticClass: "breathe above" },
                                        [
                                          _c(
                                            "a",
                                            {
                                              ref: "everifyCaseLink",
                                              attrs: {
                                                href: _vm.everifyCasePath,
                                              },
                                            },
                                            [_vm._v("View E-Verify Cases")]
                                          ),
                                          _vm._v(
                                            "\n                for details.\n              "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              : [
                                  _vm._v(
                                    "\n              Case not submitted, or not available in WorkBright. E-Verify Case Status is linked to the current Form I-9 submission.\n              This staff may have additional cases from previous Form I-9 submissions. See "
                                  ),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: _vm.everifyCaseOnboardingLinkPath,
                                      },
                                    },
                                    [_vm._v("E-Verify Cases")]
                                  ),
                                  _vm._v(" for the full list.\n            "),
                                ],
                          ],
                          2
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.shouldShowRemoteVerification
                    ? _c("tr", { ref: "ri9" }, [
                        _c(
                          "th",
                          { staticClass: "col-md-4", attrs: { scope: "row" } },
                          [_vm._v("Remote Verification")]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "col-md-8 info-item info-hire-date" },
                          [
                            _c("I9RemoteSettingToggle", {
                              attrs: {
                                setting: "countersign",
                                employmentId: _vm.employmentId,
                              },
                            }),
                            _vm._v(" "),
                            _c("PopoverIcon", {
                              attrs: {
                                title: "I-9 Remote Verification",
                                message: _vm.$locale.t(
                                  "employments.i9_remote_verification"
                                ),
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.shouldShowRemoteReverification
                    ? _c("tr", { ref: "remoteReverification" }, [
                        _c(
                          "th",
                          { staticClass: "col-md-4", attrs: { scope: "row" } },
                          [_vm._v("Remote Reverification")]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "col-md-8 info-item info-hire-date" },
                          [
                            _c("I9RemoteSettingToggle", {
                              attrs: {
                                setting: "reverification",
                                employmentId: _vm.employmentId,
                              },
                            }),
                            _vm._v(" "),
                            _c("PopoverIcon", {
                              attrs: {
                                title:
                                  "I-9 Remote Reverification (Supplement B)",
                                message: _vm.$locale.t(
                                  "employments.i9_remote_reverification"
                                ),
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel-heading" }, [
      _c("h3", { staticClass: "panel-title" }, [_vm._v("Form I-9")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }