var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "dashboard-widget",
    {
      attrs: {
        widgetTitle: _vm.widgetTitle,
        errorMsg: _vm.errorMsg,
        lastUpdated: _vm.lastUpdated,
      },
      on: {
        refresh: function ($event) {
          return _vm.fetch(true)
        },
      },
    },
    [
      _c(
        "v-wait",
        { attrs: { for: _vm.source } },
        [
          _c("Spinner", {
            attrs: { slot: "waiting", showLongWaitMessage: true },
            slot: "waiting",
          }),
          _vm._v(" "),
          _vm.everifyStagingEnabled
            ? _c("EVerifyStagingAlertMessage", {
                ref: "stagingEverifyDashboardAlertMessage",
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.errorMsg.length && _vm.chartData
            ? _c("WbBreakdownChart", {
                attrs: {
                  legendData: _vm.legendData,
                  chartData: _vm.chartData,
                  listViewPath: _vm.allCasesPath,
                  modelNameHumanized: "E-Verify case",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }