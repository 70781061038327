<template>
  <li
    :class="{ active }"
  >
    <slot />
  </li>
</template>
<script>
  export default {
    props: {
      active: {
        type: Boolean
      }
    }
  }
</script>
