var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.collectionViewName
    ? _c("bulk-reset-assignments", {
        attrs: {
          employeeIds: _vm.employeeIds,
          collectionViewName: _vm.collectionViewName,
        },
        on: { hidden: _vm.hide },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }