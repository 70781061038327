import Vue from 'vue'
import wait from 'lib/vue/wb_vue_wait'
import { createStore } from 'vuex/store'
import * as mutate from 'vuex/mutation_types'
import EmployeeHelper from 'lib/vue/plugins/employee_helper'
import WbConstants from 'lib/vue/plugins/wb_constants'
import WbRoutes from 'lib/vue/plugins/wb_routes'
import WbLocale from 'lib/vue/plugins/wb_locale'
import SelfDestructOnPageFetch from 'lib/vue/mixins/self_destruct_on_page_fetch'
import { VueMaskDirective } from 'v-mask'
import rootComponents from 'components/rootComponents'

// Directives
import 'lib/vue/directives/tooltip'
import 'lib/vue/directives/popover'
import 'lib/vue/directives/highlight_on_change'

const store = createStore()

// We publish this to `window` to allow legacy Marionette and Backbone views to dispatch actions and make commits
// on the store.
window.vuexStore = store

// register all available Modules in the Vuex store
for (const moduleName of Object.keys(store.availableModules)) {
  store.registerModule(moduleName, store.availableModules[moduleName])
}

Vue.use(EmployeeHelper)
Vue.use(WbRoutes)
Vue.use(WbLocale)
Vue.use(WbConstants)
Vue.directive('mask', VueMaskDirective)

// Register Root components
Object.entries(rootComponents).forEach(([moduleName, component]) => {
  const componentName = component.name || (
    moduleName
      .replace(/^.+\//, '')
      .replace(/\.\w+$/, '')
  )
  Vue.component(componentName, component)
})

// Creates a new Vue instance and renders the given `component`. A mount point <component> node must exist on the page
// with ID the same as the component name, i.e.:
//
//     <component id="the-superadmin-page-root" />
//
//     startRootVue('the-superadmin-page-root');
//
// component (String) - The name of the component to mount (as declared by the `name` value of the component definition)
// options (Object)
//   pageContext (Object) - data from Rails
//   initialData (Object) - Keys should correlate to Vuex modules; values will be dispatched to the module's
//                      `loadInitialData` action
window.startRootVue = function(component, { pageContext, initialData, propsData }) {
  return (new Vue({
    store,
    functional: true,
    mixins: [SelfDestructOnPageFetch],
    wait,

    // Load initial data
    beforeCreate() {
      this.$store.initializeModules(this.$store)
      for (const moduleName of Object.keys(initialData)) {
        this.$store.dispatch(`${moduleName}/loadInitialData`, initialData[moduleName])
      }

      this.$store.commit(mutate.SET_PAGE_CONTEXT_KEYS, pageContext)
    },

    // Clean up after ourselves - clear all data
    destroyed() {
      // Clear PageContext
      this.$store.commit(mutate.CLEAR_PAGE_CONTEXT)

      // Reset state of all modules
      for (const moduleName of Object.keys(store.availableModules)) {
        Vue.set(this.$store.state, moduleName, store.availableModules[moduleName].state())
      }
    },
    render: h => h(component, { props: propsData })
  })).$mount(`component[id='${component}']`)
}
