var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "WbModal",
    {
      ref: "modal",
      attrs: { title: _vm.title, watchPageContextVariable: _vm.modalKey },
      on: { modalHidden: _vm.handleModalClose },
    },
    [
      _vm._t("default"),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "text-right",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "WbButton",
            { attrs: { context: "default" }, on: { click: _vm.handleCancel } },
            [_vm._v("\n      Cancel\n    ")]
          ),
          _vm._v(" "),
          _c(
            "WbButton",
            { attrs: { context: "primary" }, on: { click: _vm.handleConfirm } },
            [_vm._v("\n      Save\n    ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }