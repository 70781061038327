var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("FormFieldSelect", {
        ref: "accessPolicy",
        staticClass: "policy-select",
        attrs: {
          label: "Permission Policy",
          placeholder: "Select permission policy",
          required: true,
          options: _vm.accessPolicyOptions,
          value: _vm.formData.accessPolicies,
          name: "access_policy",
          errors: _vm.errors,
          orientation: "horizontal",
        },
        on: { input: _vm.handleAccessPolicySelected },
        model: {
          value: _vm.formData.accessPolicy,
          callback: function ($$v) {
            _vm.$set(_vm.formData, "accessPolicy", $$v)
          },
          expression: "formData.accessPolicy",
        },
      }),
      _vm._v(" "),
      _vm.fullAdminNotSelected
        ? _c(
            "div",
            { staticClass: "form-group required groups-select" },
            [
              _c("FormLabel", {
                attrs: {
                  label: "Groups",
                  required: true,
                  orientation: "horizontal",
                  errors: _vm.errors,
                  name: "scope",
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "col-sm-7",
                  class: { "has-error": _vm.scopeHasError },
                },
                [
                  _c("WbMultiselect", {
                    ref: "groups",
                    attrs: {
                      selectOptions: _vm.groupOptions,
                      enableSearchField: true,
                      selectAll: true,
                      selectAllText: _vm.selectAllText,
                      value: _vm.formData.scope,
                      name: "scope",
                      errors: _vm.errors,
                    },
                    on: { input: _vm.handleScopeSelected },
                    model: {
                      value: _vm.formData.scope,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "scope", $$v)
                      },
                      expression: "formData.scope",
                    },
                  }),
                  _vm._v(" "),
                  _vm.scopeHasError
                    ? _c("small", { staticClass: "help-block" }, [
                        _vm._v(_vm._s(_vm.scopeErrorMessage)),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }