var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _vm.showAuthRepData
      ? _c("span", { ref: "authRepData" }, [
          _vm._v("\n    by " + _vm._s(_vm.authRepName) + "\n    "),
          _c("br"),
          _vm._v("\n    IP address: " + _vm._s(_vm.remoteIp) + "\n  "),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.showPhoneNumber
      ? _c("span", { ref: "authRepPhoneNumber" }, [
          _vm._v("\n    Phone: " + _vm._s(_vm.authRepPhone) + "\n  "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }