<template>
  <div>
    <p v-if="!managedPolicy" class="breathe">
      <i class="fa fa-info text-info fa-fw" />
      Groups selected: {{groupNames.join(', ')}}
    </p>
    <h4>{{ stepHeader }}</h4>
    <form class="form-horizontal">
      <div class="form-group">
        <div class="col-xs-12" v-for="(template, index) in templatedPermissions.templates" :key="index">
          <div class="radio">
            <label>
              <input type="radio" :value="template.name" v-model="selectedTemplate" />
              {{ template.description }}
            </label>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
  import BaseStep from 'components/permissions/steps/BaseStep'
  import TemplatedBaselinePermissions from 'models/policy_generator/templated_baseline_permissions'

  export default {
    name: 'step-other-people',
    extends: BaseStep,

    props: {
      managedPolicy: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        selectedTemplate: null,
        templatedPermissions: new TemplatedBaselinePermissions()
      }
    },

    computed: {
      stepHeader() {
        let headerText = null

        if(this.managedPolicy){
          headerText = this.$locale.t('permissions.other_people_step.managed.stepHeader')
        }
        else{
          headerText = this.$locale.t('permissions.other_people_step.stepHeader', this.firstName)
        }

        return headerText
      }
    },

    mounted() {
      const permissionSet = this.policy.baselinePermissionSet()
      const matchingTemplate = this.templatedPermissions.findMatchingTemplate(permissionSet.permissions)
      if(matchingTemplate) {
        this.selectedTemplate = matchingTemplate.name
      }
    },

    watch: {
      selectedTemplate: function(selectedTemplate) {
        const permissionSet = this.policy.baselinePermissionSet()
        const permissions = this.templatedPermissions.permissionsForTemplate(selectedTemplate)
        permissionSet.permissions = permissions
      }
    }
  }
</script>
