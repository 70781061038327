
<template>
  <div>
    <bulk-update-employments
      :collectionViewName="collectionViewName"
      ref='update-employments'
      @hidden="cleanup"
      @success="handleSuccess"
    />
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  import * as mutate from 'vuex/mutation_types'
  import CollectionViewStore from 'vuex/modules/collection_view_store'
  import BulkUpdateEmployments from 'components/employments/BulkUpdateEmployments'
  import Util from 'lib/util'
  import Locale from 'lib/locale'

  // Vuex CollectionViewStore module name, stores metadata about collection (selected, filters, etc)
  const VUEX_COLLECTION_VIEW_NAME = 'collection_view_update_employments'

  export default {
    name: 'bulk-update-employments-orphan-container',

    components: {
      BulkUpdateEmployments
    },

    props: {
      employeeIds: {
        type: Array,
        required: true
      },
    },

    data() {
      return {
        collectionViewName: VUEX_COLLECTION_VIEW_NAME
      }
    },

    computed: {
      employees() {
        return this.employeesGetBatch(this.employeeIds)
      },

      ...mapGetters({
        pageContext: 'pageContext',
        employeesGetBatch: 'employees/getBatch'
      })
    },

    methods: {
      handleSuccess() {
        location.reload()
      },

      cleanup() {
        this.$emit('clear-modal-data')
        this.$destroy()
      },

      ...mapMutations({
        setPageContextKeys: mutate.SET_PAGE_CONTEXT_KEYS
      }),

      ...mapActions({
        employmentsBulkFind: 'employments/bulkFind',
        employeesBulkShow: 'employees/bulkShow',
        permissionsBulkAuthorize: 'permissions/bulkAuthorize'
      })
    },

    created: function(){
      this.$store.registerModule(this.collectionViewName, CollectionViewStore)
    },

    mounted() {
      Promise.all([
        this.employeesBulkShow({
          employeeIds: this.employeeIds
        }),
        this.employmentsBulkFind({
          employeeIds: this.employeeIds
        })
      ]).then(() => {
        this.$store.dispatch(`${this.collectionViewName}/selectAll`, this.employeeIds)
        this.permissionsBulkAuthorize({
          resourceType: 'employments',
          resourceIds: this.employeeIds,
          actions: ['update_employments']
        }).then(() => {
          this.setPageContextKeys({
            bulk_action_alert: true,
            collectionViewName: this.collectionViewName
          })
        }).catch((xhr) => {
          Util.genericAjaxError(Locale.t('errors.ajax.fetch', 'permissions'), xhr)
        })
      }).catch((xhr) => {
        Util.genericAjaxError(Locale.t('errors.ajax.fetch', 'users'), xhr)
      }).finally(() => {
      })
    },

    beforeDestroy() {
      // Clear selected BEFORE we unregister the dynamically created module
      this.$store.dispatch(`${this.collectionViewName}/reset`)
      this.$store.unregisterModule(this.collectionViewName)
    }
  }
</script>
