var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "panel", class: _vm.classNames }, [
    _vm.hasHeading
      ? _c("div", { staticClass: "panel-heading" }, [_vm._t("heading")], 2)
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "panel-body" }, [_vm._t("body")], 2),
    _vm._v(" "),
    _vm.hasFooter
      ? _c("div", { staticClass: "panel-footer" }, [_vm._t("footer")], 2)
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }