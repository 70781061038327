// Represents model for new account creation
//
//  account_type - (string) Enum string (active, trial, demo, inactive, staging, storage, personal, partner, clone, error)
//  name - (string) name of new account
//  subdomain - (string) subdomain of new account
//  clone_account_id - (integer) ID of existing account to clone new account from
//  permissions_feature - (Boolean) enables User Permissions feature if true
// salesforce_id - (string) Account's Salesforce ID
export default Backbone.Model.extend({
  defaults: {
    account_type: 'active',
    name: null,
    subdomain: null,
    clone_account_id: null,
    permissions_feature: false,
    upsells_enabled: true,
    salesforce_id: null,
    no_salesforce: false,
    administrator_email: null
  },
})
