var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h4", [_vm._v("Which form would you like to upload?")]),
      _vm._v(" "),
      _c("wb-search-input", {
        attrs: { label: "Search by Name..." },
        model: {
          value: _vm.searchValue,
          callback: function ($$v) {
            _vm.searchValue = $$v
          },
          expression: "searchValue",
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "list-group" },
        _vm._l(_vm.filteredDocuments, function (document) {
          return _c(
            "a",
            {
              key: document.id,
              staticClass: "list-group-item",
              attrs: {
                href: _vm.$routes.new_admin_employee_document_submission_path({
                  employee_id: _vm.pageContext.employee.id,
                  document_id: document.id,
                }),
              },
            },
            [_vm._v("\n      " + _vm._s(document.name) + "\n    ")]
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.filteredDocuments.length == 0
        ? _c("div", { staticClass: "alert alert-warning" }, [
            _vm._v("\n    No Documents to Upload\n  "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }