import PrerenderedTableCollectionView from 'src/views/common/prerendered_table_collection_view'
import ItemView from 'views/admin_users/admin_list_table_item_view'

export default PrerenderedTableCollectionView.extend({
  childView: ItemView,

  onChildviewAdminUserRevoke: function(args) {
    var options = {
      confirmMessage: 'This will remove admin access from this person. However, because they also have an employee record within WorkBright, they will still be able to login to submit or update their own forms. They will not have access to view any other employees or manage anything else in the system.',
      confirmBtnLabel: 'Remove Admin Access'
    }

    App.Util.Dialogs.confirmation(options, function () {
      App.vent.trigger('admin_users:revoke', args.model.id)
    })
  },

  onChildviewAdminUserDestroy: function(args) {
    var options = {
      confirmMessage: 'This will remove this person from your WorkBright account entirely. Are you sure this is what you want to do?',
      confirmBtnLabel: 'Delete Admin'
    }

    App.Util.Dialogs.confirmation(options, function () {
      App.vent.trigger('admin_users:destroy', args.model.id)
    })
  },

  onChildviewAdminUserAddEmployeeRecord: function(args) {
    App.vent.trigger('admin_users:add_employee_record', args.model.id)
  },
})
