var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeader", {
        attrs: {
          title: _vm.$locale.t("accounts.settings.header.title"),
          lead: _vm.$locale.t("accounts.settings.header.description"),
          variant: "bordered",
        },
      }),
      _vm._v(" "),
      _c(
        "form",
        { staticClass: "form-horizontal" },
        [
          _c("FormFieldSelect", {
            ref: "staffType",
            attrs: {
              name: "default_employee_type",
              label: "Default Staff Type",
              options: _vm.staffTypeOptions,
              errors: _vm.formErrors,
              required: true,
              orientation: "horizontal",
              fieldWidth: "long-field",
            },
            model: {
              value: _vm.formData.default_employee_type,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "default_employee_type", $$v)
              },
              expression: "formData.default_employee_type",
            },
          }),
          _vm._v(" "),
          _c("FormFieldToggle", {
            ref: "startDateRequired",
            attrs: {
              name: "start_date_required",
              label: "Start Date Required for Staff",
              errors: _vm.formErrors,
              required: true,
              hint: _vm.startDateToggleHint,
              helpPopoverText: _vm.startDateHelpText,
              orientation: "horizontal",
              id: "start-date-required-toggle",
            },
            on: { toggleChanged: _vm.handleStartDateToggle },
            model: {
              value: _vm.formData.start_date_required,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "start_date_required", $$v)
              },
              expression: "formData.start_date_required",
            },
          }),
          _vm._v(" "),
          _vm.ri9SmsEnabled
            ? _c("FormFieldButtonGroup", {
                ref: "ri9Notifications",
                attrs: {
                  name: "settings_ri9_notification_methods",
                  label: "Remote I-9 Notification Delivery Method",
                  title: "",
                  options: _vm.notificationOptions,
                  errors: _vm.formErrors,
                  required: true,
                  helpPopoverText: _vm.ri9NotificationsHelpText,
                  orientation: "horizontal",
                },
                on: { selected: _vm.handleRi9NotificationSelection },
                model: {
                  value: _vm.selectedRi9NotificationMethod,
                  callback: function ($$v) {
                    _vm.selectedRi9NotificationMethod = $$v
                  },
                  expression: "selectedRi9NotificationMethod",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.onboardingSmsEnabled
            ? _c("FormFieldButtonGroup", {
                ref: "onboardingNotifications",
                attrs: {
                  name: "settings_onboarding_notification_methods",
                  label: "Onboarding Notification Method",
                  title: "",
                  options: _vm.notificationOptions,
                  errors: _vm.formErrors,
                  required: true,
                  helpPopoverText: _vm.onboardingNotificationHelpText,
                  orientation: "horizontal",
                },
                on: { selected: _vm.handleOnboardingNotificationSelection },
                model: {
                  value: _vm.selectedOnboardingNotificationMethod,
                  callback: function ($$v) {
                    _vm.selectedOnboardingNotificationMethod = $$v
                  },
                  expression: "selectedOnboardingNotificationMethod",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.embedded.enabled
            ? [
                _c("FormFieldInput", {
                  ref: "embeddedUrl",
                  attrs: {
                    label: "Embedded Redirect URL",
                    name: "settings_embedded_redirect_url",
                    errors: _vm.formErrors,
                    required: true,
                    hint: _vm.embeddedUrlHint,
                    placeholder: "myapp://forms-finished",
                    type: "text",
                    orientation: "horizontal",
                  },
                  model: {
                    value: _vm.formData.settings_embedded_redirect_url,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.formData,
                        "settings_embedded_redirect_url",
                        $$v
                      )
                    },
                    expression: "formData.settings_embedded_redirect_url",
                  },
                }),
                _vm._v(" "),
                _c("FormFieldInput", {
                  ref: "embeddedText",
                  attrs: {
                    label: "Embedded Redirect Text",
                    name: "settings_embedded_redirect_text",
                    errors: _vm.formErrors,
                    required: true,
                    hint: _vm.embeddedTextHint,
                    placeholder: "Return to App",
                    type: "text",
                    orientation: "horizontal",
                  },
                  model: {
                    value: _vm.formData.settings_embedded_redirect_text,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.formData,
                        "settings_embedded_redirect_text",
                        $$v
                      )
                    },
                    expression: "formData.settings_embedded_redirect_text",
                  },
                }),
                _vm._v(" "),
                _c("div", { ref: "embeddedPreviewLink", staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-sm-offset-4 col-md-offset-3 col-sm-7" },
                    [
                      _c("small", [
                        _vm.embedded.configured
                          ? _c(
                              "a",
                              {
                                ref: "embeddedPreviewLink",
                                attrs: {
                                  href: _vm.embeddedPreviewLinkPath,
                                  target: "blank",
                                },
                              },
                              [
                                _vm._v(
                                  "\n              Preview 'Forms Complete' link\n            "
                                ),
                              ]
                            )
                          : _c("span", { ref: "embeddedLinkMissing" }, [
                              _vm._v(
                                "\n              'Forms Complete' preview link is available when 'Embedded redirect URL' is configured.\n            "
                              ),
                            ]),
                      ]),
                    ]
                  ),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "row breathe above" }, [
            _c(
              "div",
              { staticClass: "col-sm-offset-4 col-md-offset-3 col-md-7" },
              [
                _c(
                  "ladda-button",
                  {
                    ref: "submit",
                    staticClass: "form-field",
                    on: { click: _vm.saveSettings },
                  },
                  [_vm._v("\n          Save\n        ")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-default btn-form-cancel",
                    attrs: { href: _vm.companyProfilePath },
                  },
                  [_vm._v("\n          Cancel\n        ")]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }