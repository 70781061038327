var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasSufficientData
    ? _c(
        "fieldset",
        { ref: "container" },
        [
          _c("legend", [_vm._t("title")], 2),
          _vm._v(" "),
          _vm._l(_vm.fields, function (field) {
            return _c(
              "div",
              { key: field.id, staticClass: "payroll-field" },
              [
                _c("form-field-variable", {
                  attrs: {
                    field: field,
                    name: field.internal_id,
                    errors: _vm.errors,
                    disabled: _vm.disableFields,
                    required: field.required,
                  },
                  model: {
                    value: field.value,
                    callback: function ($$v) {
                      _vm.$set(field, "value", $$v)
                    },
                    expression: "field.value",
                  },
                }),
              ],
              1
            )
          }),
          _vm._v(" "),
          _vm.disableFields
            ? _c("p", { staticClass: "help-block pb-4" }, [
                _vm._v(
                  "\n    These fields are disabled because they are mapped to other fields.\n    Visit the "
                ),
                _c("a", { attrs: { href: _vm.integrationPath } }, [
                  _vm._v("Integrations page"),
                ]),
                _vm._v(" to change their mapping.\n  "),
              ])
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }