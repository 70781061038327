import Animations from 'lib/util/animations'
import Wizard from 'lib/wizard'

const retreatToPrevStepAnimationCb = function(callback, el) {
  let view
  this._currentStep.retreat()
  this.layout.updateButtons()

  callback(view)

  this.triggerMethod('step:change')
  this.emitPercentage()
  Animations.fade(el, true)
  Animations.scrollTo($('body'), {offset: 0})
}

export default Wizard.extend({
  currentStepIdx() {
    // This wizard has only one step, with two substeps,
    // so it's better to use the subStepIdx here.
    return this._currentStep.subStepIdx
  },

  retreatToPrevStep(callback, el) {
    Animations.fade(el, false, {callback: retreatToPrevStepAnimationCb.bind(this, callback, el)})
  }

})
