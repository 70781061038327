var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row mt-4" }, [
      _c("div", { staticClass: "col-xs-4" }, [
        _c(
          "a",
          {
            staticClass: "past-imports-link",
            attrs: { href: this.$routes.user_imports_path },
          },
          [
            _c("i", { staticClass: "fa fa-long-arrow-left" }),
            _vm._v("\n        View Past Imports\n      "),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm._m(0),
    ]),
    _vm._v(" "),
    _c("ul", { staticClass: "nav-wizard pl-0" }, [
      _c("li", { class: { active: _vm.isActive("upload") } }, [
        _c("span", [_vm._v("\n        1. Upload File\n      ")]),
      ]),
      _vm._v(" "),
      _c("li", { class: { active: _vm.isActive("columns") } }, [
        _c("span", [_vm._v("\n        2. Match Columns\n      ")]),
      ]),
      _vm._v(" "),
      _c(
        "li",
        {
          class: { active: _vm.isActive("review") || _vm.isActive("validate") },
        },
        [_c("span", [_vm._v("\n        3. Review\n      ")])]
      ),
      _vm._v(" "),
      _c("li", { class: { active: _vm.isActive("import") } }, [
        _c("span", [_vm._v("\n        4. Import\n      ")]),
      ]),
      _vm._v(" "),
      _c("li", { class: { active: _vm.isActive("complete") } }, [
        _c("span", [_vm._v("\n        5. Complete\n      ")]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xs-4 text-center" }, [
      _c("h2", { staticClass: "page mt-0" }, [
        _vm._v("\n        Bulk Staff Import\n      "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }