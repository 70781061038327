var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table", { staticClass: "table table-striped" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "tbody",
      _vm._l(_vm.policies, function (policy, index) {
        return _c("ManagedAccessPolicyTemplatesTableRow", {
          key: index,
          attrs: { policy: policy },
          on: { policySelected: _vm.handlePolicySelected },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("\n        Permission Policy\n      ")]),
        _vm._v(" "),
        _c("th", { staticClass: "text-right" }, [
          _vm._v("\n        Admin Users\n      "),
        ]),
        _vm._v(" "),
        _c("th"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }