import { render, staticRenderFns } from "./TheAdminPermissionsShowRoot.vue?vue&type=template&id=67fe8856&"
import script from "./TheAdminPermissionsShowRoot.vue?vue&type=script&lang=js&"
export * from "./TheAdminPermissionsShowRoot.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/workbright/shared/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('67fe8856')) {
      api.createRecord('67fe8856', component.options)
    } else {
      api.reload('67fe8856', component.options)
    }
    module.hot.accept("./TheAdminPermissionsShowRoot.vue?vue&type=template&id=67fe8856&", function () {
      api.rerender('67fe8856', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/assets/javascripts/src/components/permissions/TheAdminPermissionsShowRoot.vue"
export default component.exports